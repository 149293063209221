import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import styles from "./Wishlist.module.css";
import Header from "./Header";
import Swal from "sweetalert2";
import Footer from "../../Footer/Footer";
import { useAuth } from "../../../Context/AuthContext";
import { useNavigate } from "react-router-dom";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

function WishlistPage() {
  const [courseDetails, setCourseDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { user, token } = useAuth();
  const userId = user?._id;

  const courseContainerRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      // Check if user data is available
      if (!userId || !token) {
        setError("User authentication failed. Please log in again.");
        setLoading(false);
        return;
      }

      try {
        // console.log("Fetching wishlist for user:", userId);  // Added logging
        const response = await fetch(
          `https://auth.ssccglpinnacle.com/api/wishlist/${userId}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`Failed to fetch wishlist: ${response.statusText}`);
        }

        const data = await response.json();
        setCourseDetails(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching wishlist:", error);
        setError("Failed to load wishlist. Please try again later.");
        setLoading(false);
      }
    };
    if (userId && token) {
      fetchData();
    }
  }, [userId, token]);

  const handleRemoveFromWishlist = async (courseId) => {
    try {
      const response = await fetch(
        `https://auth.ssccglpinnacle.com/api/wishlist/${userId}/${courseId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        setCourseDetails((prevCourses) =>
          prevCourses.filter((course) => course._id !== courseId)
        );
        Swal.fire({
          title: "Success!",
          text: "Course removed from wishlist",
          icon: "success",
          confirmButtonText: "OK",
        });
      } else {
        const data = await response.json();
        Swal.fire({
          title: "Failed",
          text: data.error || "Failed to remove course from wishlist",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      console.error("Error removing course from wishlist:", error);
      Swal.fire({
        title: "Error",
        text: "An error occurred while removing the course from the wishlist.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
    window.location.reload();
  };

  if (loading) {
    return (
      <div className={styles["loading-div"]}>
        <img
          src="https://d3m4h509gttb94.cloudfront.net/Image/Bubble-Preloader.gif"
          alt="Loading..."
        />
      </div>
    );
  }

  const handleButtonClick = () => {
    navigate("/videos");
  };

  const scroll = (direction) => {
    if (courseContainerRef.current) {
      const { current } = courseContainerRef;
      const scrollAmount = direction === "left" ? -300 : 300;
      current.scrollBy({
        left: scrollAmount,
        behavior: "smooth",
      });
    }
  };

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <>
      <Header />
      <div className={styles.cartContainer}>
        <h2 className={styles.cartHeader}>Shopping Wishlist</h2>
        <p className={styles.cartSubHeader}>
          {courseDetails.length} Courses in your wishlist
        </p>
        {courseDetails.length === 0 ? (
          <div className={styles.emptyWishlist}>
            <img
              src="/shoppingImage.webp"
              alt="Empty Cart"
              className={styles.emptyWishlistImage}
            />
            <p className={styles.emptyWishlistPara}>
              Your wishlist is empty. Keep exploring to find a course!
            </p>
            <button
              className={styles.keepShoppingButton}
              onClick={handleButtonClick}
            >
              Keep exploring
            </button>
          </div>
        ) : (
          <div className={styles.scrollContainer}>
            <button
              className={styles.scrollButton}
              onClick={() => scroll("left")}
            >
              <FaChevronLeft />
            </button>
            <div className={styles.courseContainer} ref={courseContainerRef}>
              {courseDetails.map((course) => (
                <div key={course._id} className={styles.courseCard}>
                  <div className={styles.courseImageContainer}>
                    <img
                      src={course.englishCoverImage}
                      alt="English Cover"
                      className={styles.coverImage}
                    />
                    <img
                      src={course.hindiCoverImage}
                      alt="Hindi Cover"
                      className={styles.coverImage}
                    />
                  </div>
                  <div className={styles.courseDetails}>
                    <h3 className={styles.courseTitle}>{course.courseTitle}</h3>
                    <p className={styles.courseInstructor}>
                      {course.instructorName}
                    </p>
                    <div className={styles.courseMeta}>
                      <span className={styles.rating}>
                        {course.averageRating} stars
                      </span>
                      <span>({course.totalRatings} reviews)</span>
                    </div>
                    <div className={styles.priceSection}>
                      <p className={styles.price}>
                        Price: ₹{course.price.toFixed(2)}
                      </p>
                    </div>
                    <button
                      className={styles.removeButton}
                      onClick={() => handleRemoveFromWishlist(course._id)}
                    >
                      Remove
                    </button>
                  </div>
                </div>
              ))}
            </div>
            <button
              className={styles.scrollButton}
              onClick={() => scroll("right")}
            >
              <FaChevronRight />
            </button>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
}

export default WishlistPage;
