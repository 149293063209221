// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import Cookies from 'js-cookie';
// import Swal from 'sweetalert2';
// import styles from './VideoCourseAccess.module.css';

// const VideoCourseAccess = () => {
//   const [email, setEmail] = useState('');
//   const [courses, setCourses] = useState([]);
//   const [selectedCourse, setSelectedCourse] = useState('');
//   const [message, setMessage] = useState('');
//   const [error, setError] = useState('');

//   // Fetch courses on component mount
//   useEffect(() => {
//     const fetchCourses = async () => {
//       try {
//         const response = await axios.get('https://auth.ssccglpinnacle.com/api/courses');
//         setCourses(response.data);
//       } catch (error) {
//         console.error('Error fetching courses:', error);
//         setError('Failed to fetch courses.');
//       }
//     };

//     fetchCourses();
//   }, []);

//   // Check if the email exists in the student register
//   const handleEmailCheck = async () => {
//     try {
//       const response = await axios.post('https://auth.ssccglpinnacle.com/api/check-email', { email });
//       if (response.data && response.data._id) {
//         setMessage('Student found. You can now add a course.');
//         setError('');
//       } else {
//         setError('Student not found.');
//         setMessage('');
//       }
//     } catch (error) {
//       console.error('Error checking student email:', error);
//       setError('Error checking student email.');
//       setMessage('');
//     }
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     if (!email || !selectedCourse) {
//       setError('Please check the student email and select a course.');
//       return;
//     }

//     try {
//       const purchasedVideo = {
//         productId: selectedCourse,
//         duration: '12 Months',
//         expirationDate: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
//         validity: true
//       };

//       // Retrieve the token from cookies
//       const token = Cookies.get('token');

//       // Check if token is retrieved correctly
//       if (!token) {
//         console.error('No token found in cookies');
//         setError('Authentication failed: No token provided');
//         return;
//       }

//       const response = await axios.put(`https://auth.ssccglpinnacle.com/api/video_purchase`, {
//         purchasedVideos: [purchasedVideo],
//         email_id: email,
//       }, {
//         headers: {
//           'Authorization': `Bearer ${token}`,
//         }
//       });

//       if (response.data.status === 'added') {
//         Swal.fire({
//           icon: 'success',
//           title: 'Course added',
//           text: 'The course has been added successfully!',
//         });
//         setMessage('Course added successfully');
//         setError('');
//       } else if (response.data.status === 'already_purchased') {
//         Swal.fire({
//           icon: 'info',
//           title: 'Course already purchased',
//           text: response.data.message,
//         });
//         setError(response.data.message);
//       } else {
//         Swal.fire({
//           icon: 'error',
//           title: 'Error',
//           text: 'There was an error adding the course.',
//         });
//         setError('Error adding course.');
//       }
//     } catch (error) {
//       console.error('Error adding course:', error);
//       Swal.fire({
//         icon: 'error',
//         title: 'Error',
//         text: 'There was an error adding the course.',
//       });
//       setError('Error adding course.');
//     }
//   };

//   // Reset message and error after 3 seconds
//   useEffect(() => {
//     if (message || error) {
//       const timer = setTimeout(() => {
//         setMessage('');
//         setError('');
//       }, 5000);

//       return () => clearTimeout(timer);
//     }
//   }, [message, error]);

//   return (
//     <>
//       <h2 className={styles.heading}>Provide Video Course Access to Students</h2>
//       <div className={styles.container}>
//         <form onSubmit={handleSubmit}>
//           <div className={styles.formGroup}>
//             <label className={styles.label}>Email:</label>
//             <input
//               type="email"
//               className={styles.input}
//               value={email}
//               onChange={(e) => setEmail(e.target.value)}
//               required
//             />
//             <button type="button" className={styles.button} onClick={handleEmailCheck}>
//               Check Email
//             </button>
//           </div>

//           {message && <p className={`${styles.message} ${styles.successMessage}`}>{message}</p>}
//           {error && <p className={`${styles.message} ${styles.errorMessage}`}>{error}</p>}

//           <div className={styles.formGroup}>
//             <label className={styles.label}>Select Course:</label>
//             <select
//               className={styles.select}
//               value={selectedCourse}
//               onChange={(e) => setSelectedCourse(e.target.value)}
//               required
//             >
//               <option value="" disabled>Select a course</option>
//               {courses.map(course => (
//                 <option key={course._id} value={course._id}>
//                   {course.courseTitle}
//                 </option>
//               ))}
//             </select>
//           </div>

//           <button type="submit" className={styles.button}>Submit</button>
//         </form>
//       </div>
//     </>
//   );
// };

// export default VideoCourseAccess;

import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import styles from "./VideoCourseAccess.module.css";

const VideoCourseAccess = () => {
  const [emailIds, setEmailIds] = useState("");
  const [courses, setCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  // Fetch courses on component mount
  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await axios.get(
          "https://auth.ssccglpinnacle.com/api/courses"
        );
        setCourses(response.data);
      } catch (error) {
        console.error("Error fetching courses:", error);
        setError("Failed to fetch courses.");
      }
    };

    fetchCourses();
  }, []);

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   if (!emailIds || !selectedCourse) {
  //     setError("Please enter email IDs and select a course.");
  //     return;
  //   }

  //   const emailArray = emailIds
  //     .split("\n")
  //     .map((email) => email.trim())
  //     .filter((email) => email);

  //   if (emailArray.length > 1000) {
  //     setError("You can only submit up to 1000 emails at a time.");
  //     return;
  //   }

  //   try {
  //     const purchasedVideo = {
  //       productId: selectedCourse,
  //       duration: "12 Months",
  //       expirationDate: new Date(
  //         new Date().setFullYear(new Date().getFullYear() + 1)
  //       ),
  //       validity: true,
  //     };

  //     const response = await axios.put(
  //       "https://auth.ssccglpinnacle.com/api/video_purchase_bulk",
  //       { emailIds: emailArray, purchasedVideo }
  //     );

  //     if (response.data.status === "added") {
  //       Swal.fire({
  //         icon: "success",
  //         title: "Courses added",
  //         text: "The course has been added successfully for all provided emails!",
  //       });
  //       setMessage("Courses added successfully");
  //       setError("");
  //     } else {
  //       Swal.fire({
  //         icon: "info",
  //         title: "Some courses already purchased",
  //         text: response.data.message,
  //       });
  //       setError(response.data.message);
  //     }
  //   } catch (error) {
  //     console.error("Error adding courses:", error);
  //     Swal.fire({
  //       icon: "error",
  //       title: "Error",
  //       text: "There was an error adding the courses.",
  //     });
  //     setError("Error adding courses.");
  //   }
  // };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!emailIds || !selectedCourse) {
      setError("Please enter email IDs and select a course.");
      return;
    }

    const emailArray = emailIds
      .split("\n")
      .map((email) => email.trim())
      .filter((email) => email);

    if (emailArray.length > 1000) {
      setError("You can only submit up to 1000 emails at a time.");
      return;
    }

    try {
      const purchasedVideo = {
        productId: selectedCourse,
        duration: "12 Months",
        expirationDate: new Date(
          new Date().setFullYear(new Date().getFullYear() + 1)
        ),
        validity: true,
      };

      const response = await axios.put(
        "https://auth.ssccglpinnacle.com/api/video_purchase_bulk",
        { emailIds: emailArray, purchasedVideo }
      );

      if (response.data.status === "added") {
        Swal.fire({
          icon: "success",
          title: "Courses added",
          text: "The course has been added successfully for all provided emails!",
        });
        setMessage("Courses added successfully");
        setError("");
      } else if (
        response.data.alreadyPurchased &&
        response.data.alreadyPurchased.length
      ) {
        const alreadyAccessEmails = response.data.alreadyPurchased.join(", ");
        Swal.fire({
          icon: "info",
          title: "Some courses already purchased",
          text: `The following emails already have access: ${alreadyAccessEmails}`,
        });
        setError(
          `The following emails already have access: ${alreadyAccessEmails}`
        );
      } else {
        setError(response.data.message);
      }
    } catch (error) {
      console.error("Error adding courses:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "There was an error adding the courses.",
      });
      setError("Error adding courses.");
    }
  };

  useEffect(() => {
    if (message || error) {
      const timer = setTimeout(() => {
        setMessage("");
        setError("");
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [message, error]);

  return (
    <>
      <h2 className={styles.heading}>
        Provide Video Course Access to Students
      </h2>
      <div className={styles.container}>
        <form onSubmit={handleSubmit}>
          <div className={styles.formGroup}>
            <label className={styles.label}>
              Email IDs (each on a new line):
            </label>
            <textarea
              className={styles.textarea}
              value={emailIds}
              onChange={(e) => setEmailIds(e.target.value)}
              rows={10}
              placeholder="Enter up to 1000 email addresses, each on a new line"
              required
            />
          </div>

          <div className={styles.formGroup}>
            <label className={styles.label}>Select Course:</label>
            <select
              className={styles.select}
              value={selectedCourse}
              onChange={(e) => setSelectedCourse(e.target.value)}
              required
            >
              <option value="" disabled>
                Select a course
              </option>
              {courses.map((course) => (
                <option key={course._id} value={course._id}>
                  {course.courseTitle}
                </option>
              ))}
            </select>
          </div>

          <button type="submit" className={styles.button}>
            Submit
          </button>

          {message && (
            <p className={`${styles.message} ${styles.successMessage}`}>
              {message}
            </p>
          )}
          {error && (
            <p className={`${styles.message} ${styles.errorMessage}`}>
              {error}
            </p>
          )}
        </form>
      </div>
    </>
  );
};

export default VideoCourseAccess;
