// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PinnacleProfile_Pinnacle-Profile-fullPage__HTzyw{
    display: flex;
    padding: 1rem;
}

.PinnacleProfile_Pinnacle-Profile-Container1__5NEW-{
    width: 49%;
   padding: 1rem;
   display: flex;
   flex-direction: column;
}

.PinnacleProfile_Pinnacle-Profile-Container2__2rhQx{
    width: 49%;
    padding: 1rem;
    display: flex;
    flex-direction: column;

}

.PinnacleProfile_Profile-label__3yzMX{
    font-size: 16px;
    font-weight: bold;
    line-height: 40px;

}

.PinnacleProfile_Profile-input__B6BhT{
    height: 2rem;
    width: 90%;
    font-size: 16px;
    padding: 0.25rem;
}

.PinnacleProfile_Profile-select__HR-M4{
    height: 2rem;
    width:90%;
    font-size: 16px;
    line-height: 40px;
    margin-bottom: 1rem;
}
.PinnacleProfile_save-btn__PpeU4{
    width: 20%;
    height: 2.5rem;
    border: none;
    font-size: 14px;
    font-weight: bold;
    background-color: #020062;
    color: white;
}
.PinnacleProfile_Profile-para__1dRbF{
    font-size: 12px;
    line-height: 30px;
    width: 90%;
}`, "",{"version":3,"sources":["webpack://./src/Profile/PinnacleProfile.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,aAAa;AACjB;;AAEA;IACI,UAAU;GACX,aAAa;GACb,aAAa;GACb,sBAAsB;AACzB;;AAEA;IACI,UAAU;IACV,aAAa;IACb,aAAa;IACb,sBAAsB;;AAE1B;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,iBAAiB;;AAErB;;AAEA;IACI,YAAY;IACZ,UAAU;IACV,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,SAAS;IACT,eAAe;IACf,iBAAiB;IACjB,mBAAmB;AACvB;AACA;IACI,UAAU;IACV,cAAc;IACd,YAAY;IACZ,eAAe;IACf,iBAAiB;IACjB,yBAAyB;IACzB,YAAY;AAChB;AACA;IACI,eAAe;IACf,iBAAiB;IACjB,UAAU;AACd","sourcesContent":[".Pinnacle-Profile-fullPage{\n    display: flex;\n    padding: 1rem;\n}\n\n.Pinnacle-Profile-Container1{\n    width: 49%;\n   padding: 1rem;\n   display: flex;\n   flex-direction: column;\n}\n\n.Pinnacle-Profile-Container2{\n    width: 49%;\n    padding: 1rem;\n    display: flex;\n    flex-direction: column;\n\n}\n\n.Profile-label{\n    font-size: 16px;\n    font-weight: bold;\n    line-height: 40px;\n\n}\n\n.Profile-input{\n    height: 2rem;\n    width: 90%;\n    font-size: 16px;\n    padding: 0.25rem;\n}\n\n.Profile-select{\n    height: 2rem;\n    width:90%;\n    font-size: 16px;\n    line-height: 40px;\n    margin-bottom: 1rem;\n}\n.save-btn{\n    width: 20%;\n    height: 2.5rem;\n    border: none;\n    font-size: 14px;\n    font-weight: bold;\n    background-color: #020062;\n    color: white;\n}\n.Profile-para{\n    font-size: 12px;\n    line-height: 30px;\n    width: 90%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Pinnacle-Profile-fullPage": `PinnacleProfile_Pinnacle-Profile-fullPage__HTzyw`,
	"Pinnacle-Profile-Container1": `PinnacleProfile_Pinnacle-Profile-Container1__5NEW-`,
	"Pinnacle-Profile-Container2": `PinnacleProfile_Pinnacle-Profile-Container2__2rhQx`,
	"Profile-label": `PinnacleProfile_Profile-label__3yzMX`,
	"Profile-input": `PinnacleProfile_Profile-input__B6BhT`,
	"Profile-select": `PinnacleProfile_Profile-select__HR-M4`,
	"save-btn": `PinnacleProfile_save-btn__PpeU4`,
	"Profile-para": `PinnacleProfile_Profile-para__1dRbF`
};
export default ___CSS_LOADER_EXPORT___;
