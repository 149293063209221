import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import classes from './FacultyComponent.module.css';
import { BsFillStarFill, BsPeopleFill } from 'react-icons/bs';
import { FaRegMoneyBillAlt, FaChalkboardTeacher } from 'react-icons/fa';

export default function FacultyComponent() {
   const [instructor, setInstructor] = useState(null);
   
   const { courseId } = useParams();

   useEffect(() => {
      async function fetchInstructorDetails() {
         try {
            const courseResponse = await fetch(`https://auth.ssccglpinnacle.com/course/${courseId}`);
            const courseData = await courseResponse.json();
            const instructorResponse = await fetch(`https://auth.ssccglpinnacle.com/instructors/${courseData.instructorName}`);
            const instructorData = await instructorResponse.json();

            if (instructorData.success) {
               setInstructor(instructorData.instructor);
            }
         } catch (error) {
            console.error('Failed to fetch instructor details:', error);
         }
      }

      fetchInstructorDetails();
   }, [courseId]);

   return (
      <div className={classes["FacultyComponent-fullPage"]}>
         {instructor ? (
            <>
               <div className={classes["FacultyComponent-review-heading"]}>{instructor.instructorName}</div>
               <div className={classes["FacultyComponent-sub-heading"]}>Lead Instructor</div>
               <div className={classes["FacultyComponent-image-section"]}>
                  <img src={instructor.imageUrl} alt="Instructor" className={classes["circularImage"]} />
               </div>
               {/* <div className={classes["FacultyComponent-stats"]}>
                  <div className={classes["FacultyComponent-stat"]}>
                     <BsFillStarFill className={classes["FacultyComponent-stat-icon"]} />
                     4.7 Instructor Rating
                  </div>
                  <div className={classes["FacultyComponent-stat"]}>
                     <BsPeopleFill className={classes["FacultyComponent-stat-icon"]} />
                     {instructor.reviews} Reviews
                  </div>
                  <div className={classes["FacultyComponent-stat"]}>
                     <FaRegMoneyBillAlt className={classes["FacultyComponent-stat-icon"]} />
                     {instructor.students} Students
                  </div>
                  <div className={classes["FacultyComponent-stat"]}>
                     <FaChalkboardTeacher className={classes["FacultyComponent-stat-icon"]} />
                     {instructor.courses} Courses
                  </div>
               </div> */}
               <div
                  className={classes["description-section"]}
                  dangerouslySetInnerHTML={{ __html: instructor.instructorDescription }}
               />
            </>
         ) : (
            <p>Loading...</p>
         )}
      </div>
   );
}


// import React, { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom';
// import classes from './FacultyComponent.module.css';
// import { BsFillStarFill, BsPeopleFill } from 'react-icons/bs';
// import { FaRegMoneyBillAlt, FaChalkboardTeacher } from 'react-icons/fa';

// export default function FacultyComponent() {
//   const [instructor, setInstructor] = useState(null);
//   const params = useParams();

//   useEffect(() => {
//     async function fetchInstructorDetails() {
//       try {
//         const courseResponse = await fetch(`https://auth.ssccglpinnacle.com/course/${params.id}`);
//         const courseData = await courseResponse.json();
//         const instructorResponse = await fetch(`https://auth.ssccglpinnacle.com/instructors/${courseData.instructorName}`);
//         const instructorData = await instructorResponse.json();

//         if (instructorData.success) {
//           setInstructor(instructorData.instructor);
//         }
//       } catch (error) {
//         console.error('Failed to fetch instructor details:', error);
//       }
//     }

//     fetchInstructorDetails();
//   }, [params.id]);

//   return (
//     <div className={classes["FacultyComponent-fullPage"]}>
//       {instructor ? (
//         <>
//           <div className={classes["FacultyComponent-review-heading"]}>{instructor.instructorName}</div>
//           <div className={classes["FacultyComponent-sub-heading"]}>Developer and Lead Instructor</div>
//           <div className={classes["FacultyComponent-stats"]}>
//             <div className={classes["FacultyComponent-stat"]}>
//               <BsFillStarFill className={classes["FacultyComponent-stat-icon"]} />
//               4.7 Instructor Rating
//             </div>
//             <div className={classes["FacultyComponent-stat"]}>
//               <BsPeopleFill className={classes["FacultyComponent-stat-icon"]} />
//               {instructor.reviews} Reviews
//             </div>
//             <div className={classes["FacultyComponent-stat"]}>
//               <FaRegMoneyBillAlt className={classes["FacultyComponent-stat-icon"]} />
//               {instructor.students} Students
//             </div>
//             <div className={classes["FacultyComponent-stat"]}>
//               <FaChalkboardTeacher className={classes["FacultyComponent-stat-icon"]} />
//               {instructor.courses} Courses
//             </div>
//           </div>
//           <div className={classes["FacultyComponent-image-section"]}>
//             <img src={instructor.imageUrl} alt="Instructor" width="200" height="200" />
//           </div>
//           <div
//             className={classes["description-section"]}
//             dangerouslySetInnerHTML={{ __html: instructor.instructorDescription }}
//           />
//         </>
//       ) : (
//         <div><img src="https://d3m4h509gttb94.cloudfront.net/Image/Bubble-Preloader.gif" alt="loading"></img></div>
//       )}
//     </div>
//   );
// }



// import React, { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom';
// import classes from './FacultyComponent.module.css';
// import { BsFillStarFill, BsPeopleFill } from 'react-icons/bs';
// import { FaRegMoneyBillAlt, FaChalkboardTeacher } from 'react-icons/fa';
// import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

// export default function FacultyComponent() {
//   const [instructor, setInstructor] = useState(null);
//   const [showFullDescription, setShowFullDescription] = useState(false);
//   const params = useParams();

//   useEffect(() => {
//     async function fetchInstructorDetails() {
//       try {
//         const courseResponse = await fetch(`https://auth.ssccglpinnacle.com/course/${params.id}`);
//         const courseData = await courseResponse.json();
//         const instructorResponse = await fetch(`https://auth.ssccglpinnacle.com/instructors/${courseData.instructorName}`);
//         const instructorData = await instructorResponse.json();

//         if (instructorData.success) {
//           setInstructor(instructorData.instructor);
//         }
//       } catch (error) {
//         console.error('Failed to fetch instructor details:', error);
//       }
//     }

//     fetchInstructorDetails();
//   }, [params.id]);

//   const toggleDescription = () => {
//     setShowFullDescription(!showFullDescription);
//   };

//   return (
//     <div className={classes["FacultyComponent-fullPage"]}>
//       {instructor ? (
//         <>
//           <div className={classes["FacultyComponent-review-heading"]}>{instructor.instructorName}</div>
//           <div className={classes["FacultyComponent-sub-heading"]}>Developer and Lead Instructor</div>
//           <div className={classes["FacultyComponent-stats"]}>
//             <div className={classes["FacultyComponent-stat"]}>
//               <BsFillStarFill className={classes["FacultyComponent-stat-icon"]} />
//               4.7 Instructor Rating
//             </div>
//             <div className={classes["FacultyComponent-stat"]}>
//               <BsPeopleFill className={classes["FacultyComponent-stat-icon"]} />
//               {instructor.reviews} Reviews
//             </div>
//             <div className={classes["FacultyComponent-stat"]}>
//               <FaRegMoneyBillAlt className={classes["FacultyComponent-stat-icon"]} />
//               {instructor.students} Students
//             </div>
//             <div className={classes["FacultyComponent-stat"]}>
//               <FaChalkboardTeacher className={classes["FacultyComponent-stat-icon"]} />
//               {instructor.courses} Courses
//             </div>
//           </div>
//           <div className={classes["FacultyComponent-image-section"]}>
//             <img src={instructor.imageUrl} alt="Instructor" width="200" height="200" />
//           </div>
//           <div
//             className={classes["description-section"]}
//             style={{ maxHeight: showFullDescription ? 'none' : '130px' }}
//             dangerouslySetInnerHTML={{ __html: instructor.instructorDescription }}
//           />
//           <div className={classes["show-more"]} onClick={toggleDescription}>
//             Show {showFullDescription ? 'less' : 'more'} <IoIosArrowDown className={showFullDescription ? 'hidden' : 'visible'} /><IoIosArrowUp className={showFullDescription ? 'visible' : 'hidden'} />
//           </div>
//         </>
//       ) : (
//         <p>Loading...</p>
//       )}
//     </div>
//   );
// }
