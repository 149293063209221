// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Tools_Tools-fullPage__aMRyc{
    padding:10rem;
}

.Tools_heading__rhml6{
    font-size: 25px;
    font-weight: bold;
    line-height: 60px;
}
.Tools_Main-Body__IofQM{
    display: flex;
    gap: 4rem;
}
.Tools_Body-part-01__kiVp7{
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    width: 20rem;
    height: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;

}

.Tools_tools-Para__SdB63{
    font-size: 12px;
    line-height: 30px;
}`, "",{"version":3,"sources":["webpack://./src/Profile/Tools.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,iBAAiB;AACrB;AACA;IACI,aAAa;IACb,SAAS;AACb;AACA;IACI,uFAAuF;IACvF,YAAY;IACZ,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;;AAEjB;;AAEA;IACI,eAAe;IACf,iBAAiB;AACrB","sourcesContent":[".Tools-fullPage{\n    padding:10rem;\n}\n\n.heading{\n    font-size: 25px;\n    font-weight: bold;\n    line-height: 60px;\n}\n.Main-Body{\n    display: flex;\n    gap: 4rem;\n}\n.Body-part-01{\n    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;\n    width: 20rem;\n    height: 10rem;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    padding: 1rem;\n\n}\n\n.tools-Para{\n    font-size: 12px;\n    line-height: 30px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Tools-fullPage": `Tools_Tools-fullPage__aMRyc`,
	"heading": `Tools_heading__rhml6`,
	"Main-Body": `Tools_Main-Body__IofQM`,
	"Body-part-01": `Tools_Body-part-01__kiVp7`,
	"tools-Para": `Tools_tools-Para__SdB63`
};
export default ___CSS_LOADER_EXPORT___;
