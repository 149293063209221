
// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import parse from 'html-react-parser';
// import Swal from 'sweetalert2';
// import 'react-quill/dist/quill.snow.css';
// import Quill from 'react-quill';
// import { FaEdit, FaTrashAlt } from 'react-icons/fa';
// import styles from './ManageInstructorListing.module.css';

// const FormattedInstructorDescription = ({ description }) => {
//   return <div>{parse(description)}</div>;
// };

// const ManageInstructorList = () => {
//   const [instructors, setInstructors] = useState([]);
//   const [selectedInstructor, setSelectedInstructor] = useState(null);
//   const [quillEditorState, setQuillEditorState] = useState('');
//   const [showModal, setShowModal] = useState(false);
//   const [imagePreview, setImagePreview] = useState(null);

//   useEffect(() => {
//     const fetchInstructors = async () => {
//       try {
//         const response = await axios.get('https://auth.ssccglpinnacle.com/api/instructors');
//         setInstructors(response.data.instructors);
//       } catch (error) {
//         console.error('Error fetching instructors:', error.message);
//       }
//     };

//     fetchInstructors();
//   }, []);

//   const handleSave = async () => {
//     try {
//       const formData = new FormData();
//       formData.append('instructorName', selectedInstructor.instructorName);
//       formData.append('instructorDescription', quillEditorState);
//       if (selectedInstructor.image) {
//         formData.append('image', selectedInstructor.image);
//       }

//       const response = await axios.put(`https://auth.ssccglpinnacle.com/api/instructors/${selectedInstructor._id}`, formData);
//       setInstructors(instructors.map(inst => (inst._id === selectedInstructor._id ? response.data.instructor : inst)));

//       Swal.fire({
//         icon: 'success',
//         title: 'Success!',
//         text: 'Instructor updated successfully!',
//       });

//       setShowModal(false);
//       setSelectedInstructor(null);
//       setImagePreview(null);
//     } catch (error) {
//       Swal.fire({
//         icon: 'error',
//         title: 'Error!',
//         text: 'There was an error saving the instructor. Please try again.',
//       });
//       console.error('Error saving instructor:', error.message);
//     }
//   };

//   const handleEditClick = (instructor) => {
//     setSelectedInstructor(instructor);
//     setQuillEditorState(instructor.instructorDescription);
//     setImagePreview(instructor.imageUrl || null);
//     setShowModal(true);
//   };

//   const handleDeleteInstructor = async (id) => {
//     try {
//       await axios.delete(`https://auth.ssccglpinnacle.com/api/instructors/${id}`);
//       setInstructors(instructors.filter(inst => inst._id !== id));
//       Swal.fire('Deleted!', 'The instructor has been deleted.', 'success');
//     } catch (error) {
//       Swal.fire('Error!', 'There was an error deleting the instructor.', 'error');
//       console.error('Error deleting instructor:', error.message);
//     }
//   };

//   const handleImageChange = (e) => {
//     const file = e.target.files[0];
//     if (file) {
//       setSelectedInstructor({ ...selectedInstructor, image: file });
//       setImagePreview(URL.createObjectURL(file));
//     }
//   };

//   return (
//     <div className={styles.container}>
//       <table className={styles.table}>
//         <thead>
//           <tr>
//             <th>Serial Number</th>
//             <th>Instructor Name</th>
//             <th>Instructor Description</th>
//             <th>Image</th>
//             <th>Timestamp</th>
//             <th>Actions</th>
//           </tr>
//         </thead>
//         <tbody>
//           {instructors.map((instructor, index) => (
//             <tr key={instructor._id}>
//               <td>{index + 1}</td>
//               <td>{instructor.instructorName}</td>
//               <td>
//                 <FormattedInstructorDescription description={instructor.instructorDescription} />
//               </td>
//               <td>
//                 {instructor.imageUrl && (
//                   <img src={instructor.imageUrl} alt={`Instructor ${index + 1}`} className={styles.image} />
//                 )}
//               </td>
//               <td>{new Date(instructor.createdAt).toLocaleString()}</td>
//               <td className={styles.actionIcons}>
//                 <button className={styles.iconButton} onClick={() => handleEditClick(instructor)}>
//                   <FaEdit size={20} color="green" />
//                 </button>
//                 <button className={styles.iconButton} onClick={() => handleDeleteInstructor(instructor._id)}>
//                   <FaTrashAlt size={20} color="red" />
//                 </button>
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>

//       {showModal && (
//         <div className={styles.modal}>
//           <div className={styles.modalContent}>
//             <span className={styles.close} onClick={() => setShowModal(false)}>&times;</span>
//             <h2>Edit Instructor</h2>
//             <div className={styles.inputContainer}>
//               <label>Instructor Name</label>
//               <input
//                 type="text"
//                 value={selectedInstructor.instructorName}
//                 onChange={(e) => setSelectedInstructor({ ...selectedInstructor, instructorName: e.target.value })}
//               />
//             </div>
//             <div className={styles.inputDesContainer}>
//               <label>Description</label>
//               <Quill
//                 value={quillEditorState}
//                 onChange={setQuillEditorState}
//                 theme="snow"
//                 className={styles.quillEditor}
//               />
//             </div>
//             <div className={styles.inputImgContainer}>
//               <label>Image</label>
//               <input type="file" onChange={handleImageChange} />
//               {imagePreview && (
//                 <div className={styles.imagePreviewContainer}>
//                   <img src={imagePreview} alt="Preview" className={styles.imagePreview} />
//                 </div>
//               )}
//             </div>
//             <button className={styles.submitButton} onClick={handleSave}>Save</button>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default ManageInstructorList;


import React, { useState, useEffect } from 'react';
import axios from 'axios';
import parse from 'html-react-parser';
import { MaterialReactTable } from "material-react-table";
import { FaEdit, FaTrashAlt } from 'react-icons/fa';
import {
  Typography,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  CircularProgress,
  Button,
} from "@mui/material";
import Swal from 'sweetalert2';
import 'react-quill/dist/quill.snow.css';
import Quill from 'react-quill';
import styles from './ManageInstructorListing.module.css';

const ManageInstructorList = () => {
  const [instructors, setInstructors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [selectedInstructor, setSelectedInstructor] = useState(null);
  const [quillEditorState, setQuillEditorState] = useState('');
  const [imagePreview, setImagePreview] = useState(null);

  useEffect(() => {
    const fetchInstructors = async () => {
      try {
        const response = await axios.get('https://auth.ssccglpinnacle.com/api/instructors');
        setInstructors(response.data.instructors);
      } catch (error) {
        console.error('Error fetching instructors:', error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchInstructors();
  }, []);

  const handleDeleteInstructor = async (instructorId) => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: "Do you really want to delete this instructor? This action cannot be undone!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel',
    });

    if (result.isConfirmed) {
      try {
        await axios.delete(`https://auth.ssccglpinnacle.com/api/instructors/${instructorId}`);
        setInstructors(instructors.filter(inst => inst._id !== instructorId));
        Swal.fire('Deleted!', 'The instructor has been deleted.', 'success');
      } catch (error) {
        Swal.fire('Error!', 'There was an error deleting the instructor.', 'error');
        console.error('Error deleting instructor:', error.message);
      }
    }
  };

  const handleEditClick = (instructor) => {
    setSelectedInstructor(instructor);
    setQuillEditorState(instructor.instructorDescription);
    setImagePreview(instructor.imageUrl || null);
    setShowModal(true);
  };

  const handleSave = async () => {
    try {
      const formData = new FormData();
      formData.append('instructorName', selectedInstructor.instructorName);
      formData.append('instructorDescription', quillEditorState);
      if (selectedInstructor.image) {
        formData.append('image', selectedInstructor.image);
      }

      const response = await axios.put(`https://auth.ssccglpinnacle.com/api/instructors/${selectedInstructor._id}`, formData);
      setInstructors(instructors.map(inst => (inst._id === selectedInstructor._id ? response.data.instructor : inst)));

      Swal.fire({
        icon: 'success',
        title: 'Success!',
        text: 'Instructor updated successfully!',
      });

      setShowModal(false);
      setSelectedInstructor(null);
      setImagePreview(null);
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'There was an error saving the instructor. Please try again.',
      });
      console.error('Error saving instructor:', error.message);
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedInstructor({ ...selectedInstructor, image: file });
      setImagePreview(URL.createObjectURL(file));
    }
  };

  return (
    <>
      <h2 className={styles.heading}>Manage Instructor</h2>
      <Box className={styles.container}>
        {loading ? (
          <Box display="flex" justifyContent="center" marginTop={2}>
            <CircularProgress />
          </Box>
        ) : (
          <MaterialReactTable
            columns={[
              {
                accessorKey: 'serialNumber',
                header: 'S.No',
                size: 50, // Adjust as needed
              },
              {
                accessorKey: 'instructorName',
                header: 'Instructor Name',
                size: 150, // Adjust as needed
              },
              {
                accessorKey: 'instructorDescription',
                header: 'Instructor Description',
                size: 250, // Adjust as needed
                Cell: ({ cell }) => <div>{parse(cell.getValue())}</div>,
              },
              {
                accessorKey: 'imageUrl',
                header: 'Image',
                size: 100, // Adjust as needed
                Cell: ({ cell }) =>
                  cell.getValue() && (
                    <img src={cell.getValue()} alt="Instructor" className={styles.image} />
                  ),
              },
              {
                accessorKey: 'createdAt',
                header: 'Timestamp',
                size: 150, // Adjust as needed
                Cell: ({ cell }) => new Date(cell.getValue()).toLocaleString(),
              },
              {
                header: 'Actions',
                size: 150, // Adjust as needed
                Cell: ({ row }) => (
                  <div className={styles.actionsContainer}>
                    <Button
                      className={styles.iconButtonEdit}
                      onClick={() => handleEditClick(row.original)}
                    >
                      EDIT
                    </Button>
                    <Button
                      className={styles.iconButtonDelete}
                      onClick={() => handleDeleteInstructor(row.original._id)}
                    >
                      DELETE
                    </Button>
                  </div>
                ),
              },
            ]}
            data={instructors.map((inst, index) => ({
              ...inst,
              serialNumber: index + 1,
            }))}
            enablePagination={false}
          />
        )}

        <Dialog open={showModal} onClose={() => setShowModal(false)}>
          <DialogTitle>Edit Instructor</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="Instructor Name"
              type="text"
              fullWidth
              variant="outlined"
              value={selectedInstructor?.instructorName || ''}
              onChange={(e) =>
                setSelectedInstructor({ ...selectedInstructor, instructorName: e.target.value })
              }
            />
            <Typography variant="body1" gutterBottom>
              Description
            </Typography>
            <Quill
              value={quillEditorState}
              onChange={setQuillEditorState}
              theme="snow"
              className={styles.quillEditor}
            />
            <Typography variant="body1" gutterBottom>
              Image
            </Typography>
            <input type="file" onChange={handleImageChange} />
            {imagePreview && (
              <Box className={styles.imagePreviewContainer}>
                <img src={imagePreview} alt="Preview" className={styles.imagePreview} />
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowModal(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSave} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
};

export default ManageInstructorList;








