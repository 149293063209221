// ProtectedContent.js
import React from 'react';
import { useNavigate } from 'react-router-dom';

const ProtectedContent = ({ isAuthenticated, children }) => {
  const navigate = useNavigate();

  if (isAuthenticated) {
    return <>{children}</>;
  } else {
    // Redirect to the login page using the navigate function
    navigate('/login');
    return null; // This ensures no content is rendered
  }
};

export default ProtectedContent;
