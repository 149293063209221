// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MyLearningMain_content__Pe7pq {
  position: absolute;
  left: 0;
  width: 100%;
  top: 9%;
  z-index: -1;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.MyLearningMain_footer_main__8H-ae {
  position: absolute;
  top: 60vh;
  z-index: 1;
}
.MyLearningMain_skeleton__c1EU\\+ {
  background-color: #e0e0e0;
  border-radius: 4px;
  margin: 10px 0;
}

.MyLearningMain_skeleton-header__PhxcO {
  height: 50px;
  width: 100%;
}

.MyLearningMain_skeleton-nav__s4lit {
  height: 40px;
  width: 100%;
}

.MyLearningMain_skeleton-content__CEmiw {
  height: 200px;
  width: 100%;
}
.MyLearningMain_main_content__U2kkz{
  margin-bottom: 10rem;
}

.MyLearningMain_activeTab__z1PM9{
 
  background-color: aqua;
}

/* Style for active tab */
.MyLearningMain_active__oRmLJ {
  color: #fff;
  border-bottom: 4px solid white; 

}

/* Loading spinner */
.MyLearningMain_loaderContainer__cWb0q {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; 
}
`, "",{"version":3,"sources":["webpack://./src/VIDEOPLAYER COMPONENTS/MyLearningMain.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,OAAO;EACP,WAAW;EACX,OAAO;EACP,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,YAAY;AACd;AACA;EACE,kBAAkB;EAClB,SAAS;EACT,UAAU;AACZ;AACA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,aAAa;EACb,WAAW;AACb;AACA;EACE,oBAAoB;AACtB;;AAEA;;EAEE,sBAAsB;AACxB;;AAEA,yBAAyB;AACzB;EACE,WAAW;EACX,8BAA8B;;AAEhC;;AAEA,oBAAoB;AACpB;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;AACf","sourcesContent":[".content {\n  position: absolute;\n  left: 0;\n  width: 100%;\n  top: 9%;\n  z-index: -1;\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n}\n.footer_main {\n  position: absolute;\n  top: 60vh;\n  z-index: 1;\n}\n.skeleton {\n  background-color: #e0e0e0;\n  border-radius: 4px;\n  margin: 10px 0;\n}\n\n.skeleton-header {\n  height: 50px;\n  width: 100%;\n}\n\n.skeleton-nav {\n  height: 40px;\n  width: 100%;\n}\n\n.skeleton-content {\n  height: 200px;\n  width: 100%;\n}\n.main_content{\n  margin-bottom: 10rem;\n}\n\n.activeTab{\n \n  background-color: aqua;\n}\n\n/* Style for active tab */\n.active {\n  color: #fff;\n  border-bottom: 4px solid white; \n\n}\n\n/* Loading spinner */\n.loaderContainer {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100vh; \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `MyLearningMain_content__Pe7pq`,
	"footer_main": `MyLearningMain_footer_main__8H-ae`,
	"skeleton": `MyLearningMain_skeleton__c1EU+`,
	"skeleton-header": `MyLearningMain_skeleton-header__PhxcO`,
	"skeleton-nav": `MyLearningMain_skeleton-nav__s4lit`,
	"skeleton-content": `MyLearningMain_skeleton-content__CEmiw`,
	"main_content": `MyLearningMain_main_content__U2kkz`,
	"activeTab": `MyLearningMain_activeTab__z1PM9`,
	"active": `MyLearningMain_active__oRmLJ`,
	"loaderContainer": `MyLearningMain_loaderContainer__cWb0q`
};
export default ___CSS_LOADER_EXPORT___;
