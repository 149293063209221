import React, { useState, useEffect } from "react";
import "./ProgressRow3.css";


function ProgressRow3() {

   return ( 
       <>
     <div className = "row3-progress-report"> 
     <div className ="inner1">
     <div className = "inner1-container">
     <div className= "Quiz-ineer1">
      <div className= "Quiz-ineer-row1">
      <h5>Quiz</h5></div>
       <div className= "Quiz-ineer-row2">
       <div className= "Quiz-ineer-row2-col1">
       <p>Test your skill about <b>CSS</b></p>
      <div className="ul-sentence">
  <ul>
    <li>get a point for each % correct score</li>
    <li>earn a star for getting all correct</li>
  </ul>
</div>
<button className ="start-quiz-progress">Start quiz</button>
       </div>
       <div className= "Quiz-ineer-row2-col2">
       <div className = "row2-have-pic"></div>
       </div>
       </div>
     </div>
     <div className= "Answer-ineer1">
     <div className= "Exercise-ineer-row1">
      <h5>Exercises</h5></div>
       <div className= "Quiz-ineer-row2">
       <div className= "Quiz-ineer-row2-col1">
       <p>Test your skill about <b>CSS</b></p>
      <div className="ul-sentence">
  <ul>
    <li>get a point for each % correct score</li>
    <li>earn a star for getting all correct</li>
  </ul>
</div>
<button className ="start-quiz-progress">Start exercises</button>
       </div>
       <div className= "Quiz-ineer-row2-col2">
       <div className = "row2-have-pic"></div>
       </div>
       </div>
     </div></div>
     </div>
     </div>
       
    </>
  )
}

export default ProgressRow3