
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';

const PaymentSuccess = () => {
   const [orderId, setOrderId] = useState(null);

   useEffect(() => {
      const params = new URLSearchParams(window.location.search);
      const orderId = params.get('orderId');
      setOrderId(orderId);


      const checkPaymentStatus = async () => {
         try {
            const response = await fetch(`https://auth.ssccglpinnacle.com/api/book_payment_status?orderId=${orderId}`);
            const data = await response.json();

            if (data.status === 'success') {
               Swal.fire({
                  icon: 'success',
                  title: 'Payment Successful',
                  text: 'Your payment was successful!',
               });
            } else {
               Swal.fire({
                  icon: 'error',
                  title: 'Payment Failed',
                  text: 'Your payment was not successful. Please try again.',
               });
            }
         } catch (error) {
            console.error('Error checking payment status:', error);
            Swal.fire({
               icon: 'error',
               title: 'Error',
               text: 'An error occurred while checking the payment status. Please try again.',
            });
         }
      };

      if (orderId) {
         checkPaymentStatus();
      }
   }, []);

   return (
      <div className="payment-success">
         <h2>Payment Status</h2>
         {orderId ? <p>Checking payment status for order ID: {orderId}</p> : <p>Loading...</p>}
      </div>
   );
};

export default PaymentSuccess;
