import React, { useState, useEffect } from "react";
import "./Row2intro.css";
import { FaRegThumbsUp } from 'react-icons/fa';
import LoadingSpinner from "../LoadingSpinner";
import { useParams } from "react-router-dom";

function Row2intro() {
  const [loading, setLoading] = useState(true);
  const [selectedCardData, setSelectedCardData] = useState([]);
  const [mediaContent, setMediaContent] = useState(null); // Store either image, video, or iframe
  const { cardId } = useParams();
  const [hoveredPoint, setHoveredPoint] = useState(null); // Initialize as null
  let hideTimeout;

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const ebookResponse = await fetch("https://auth.ssccglpinnacle.com/api/booksforactive?active=true");
        if (!ebookResponse.ok) {
          throw new Error(`Failed to fetch ebooks. Status: ${ebookResponse.status}`);
        }
        const ebookData = await ebookResponse.json();

        const filteredCardData = ebookData.filter(item => item._id === cardId);
        setSelectedCardData(filteredCardData);

        if (filteredCardData.length > 0 && filteredCardData[0].whytakecourse) {
          const whytakecourseContent = filteredCardData[0].whytakecourse;

          // Remove img, video, and iframe tags from the content to avoid displaying them in text
          const sanitizedContent = whytakecourseContent.replace(/<img[^>]*>/g, "").replace(/<video[^>]*>/g, "").replace(/<iframe[^>]*>.*?<\/iframe>/g, "");

          // Extract the first iframe, image, or video if available
          const iframeMatch = whytakecourseContent.match(/<iframe[^>]+src="([^">]+)"/);
          const imgMatch = whytakecourseContent.match(/<img[^>]+src="([^">]+)"/);
          const videoMatch = whytakecourseContent.match(/<video[^>]*src="([^">]+)"/) || whytakecourseContent.match(/.html5-video-player[^>]*src="([^">]+)"/);
          
          if (iframeMatch && iframeMatch[1]) {
            setMediaContent({ type: 'iframe', src: iframeMatch[1] });
          } else if (videoMatch && videoMatch[1]) {
            setMediaContent({ type: 'video', src: videoMatch[1] });
          } else if (imgMatch && imgMatch[1]) {
            setMediaContent({ type: 'image', src: imgMatch[1] });
          } else {
            setMediaContent({ type: 'image', src: filteredCardData[0].image }); // Fallback to the main image if no iframe, image, or video in content
          }

          // Set the sanitized content back to selectedCardData
          setSelectedCardData([{ ...filteredCardData[0], sanitizedContent }]);
        }

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [cardId]);

  if (loading) {
    return <LoadingSpinner />;
  }

  const keyPoints = [
    {
      title: "Author’s Insight",
      content: `
        <p><strong>Author’s Insight:</strong> Pinnacle books, look for those that offer detailed explanations, practice questions, and previous years' solved papers. It's also helpful to choose authors who are active in the competitive exam preparation space and have a track record of success in guiding students.</p>
      `,
    },
    {
      title: "Latest Edition",
      content: `
        <p><strong>Latest Edition:</strong> Pinnacle Publications keeps updating its book with time as new syllabus and new pattern of exam comes. This helps students to stay updated on recent exam patterns and questions. Additionally, visiting the publishers’ websites or contacting local bookstores can help confirm the latest releases.</p>
      `,
    },
    {
      title: "User-friendly Presentation",
      content: `
        <p><strong>User-friendly Presentation:</strong> Pinnacle books are best for exam preparation, focusing on well-organized materials with clear diagrams, tables, and illustrations. Here are some points for books that are known for their user-friendly layout and effective presentation:</p>
        <ul>
          <li><strong>Content Quality:</strong> For effective exam preparation, it’s crucial to choose books that not only have a well-organized layout but also provide accurate, up-to-date content aligned with the latest exam patterns.</li>
          <li><strong>Explanation Clarity:</strong> Look for books that provide clear and concise explanations, making complex concepts easier to understand.</li>
          <li><strong>Question Variety:</strong> Opt for government exam preparation books offering a diverse range of practice questions, including previous year papers and mock tests.</li>
        </ul>
      `,
    },
    {
      title: "Content and Explanation",
      content: `
        <p><strong>Content and Explanation:</strong> Pinnacle Publications provides the latest government syllabus and exam patterns. Look for books with clear explanations and step-by-step solutions to concepts and problems. This is crucial for developing a strong understanding of the subject matter.</p>
      `,
    },
  ];
  
  const handleMouseEnter = (content) => {
    clearTimeout(hideTimeout); // Clear any hide timeout
    setHoveredPoint(content);  // Show the modal
  };
  
  const handleMouseLeave = () => {
    hideTimeout = setTimeout(() => setHoveredPoint(null), 200); // Slight delay before hiding
  };

  return (
    <>
      <div className="for-intro-row2-image-introproduct">
        <div className="row2-intro-details">
          <div className="why-this-course">
            <h2 className="para1-row2-intro">Why should you take this book?</h2>
            <div 
              className="intro-para" 
              dangerouslySetInnerHTML={{ __html: selectedCardData.length > 0 ? selectedCardData[0].sanitizedContent : '' }}
            />
          </div> 
        </div>
        <div className="row2-intro-image">
          <div className="image-of-my-learning">
            {mediaContent?.type === 'image' && (
              <img className="img-learning" src={mediaContent.src} alt="Learning Material" />
            )}
            {mediaContent?.type === 'video' && (
              <video className="video-learning" controls>
                <source src={mediaContent.src} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}
            {mediaContent?.type === 'iframe' && (
              <iframe
                className="img-learning"
                src={mediaContent.src}
                frameBorder="0"
                allowFullScreen
                title="Embedded Video"
              ></iframe>
            )}
          </div>
        </div>
      </div>
      
      <div className="thumps-up">
        {keyPoints.map((point, index) => (
          <div
            key={index}
            className="four-key-point-row-box"
            onMouseEnter={() => handleMouseEnter(point.content)}  // Show modal on hover
            onMouseLeave={handleMouseLeave}                      // Hide modal with delay
          >
            <FaRegThumbsUp size={20} />
            <p>{point.title}</p>
          </div>
        ))}

        {/* Transition modal */}
        {hoveredPoint && (
          <div 
            className="transition-modal-book-info"
            onMouseEnter={() => clearTimeout(hideTimeout)} // Prevent hiding when hovering over the modal
            onMouseLeave={handleMouseLeave}               // Hide modal when leaving the modal area
            dangerouslySetInnerHTML={{ __html: hoveredPoint }} 
          />
        )}
      </div>
    </>
  );
}

export default Row2intro;
