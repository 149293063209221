import React, { useState, useEffect } from "react";
import classes from "./Header.module.css";
import Logo from "../../../assests/pinnacleWhiteLogo.svg";
import Playstore from "../../../assests/white playstore.svg";
import { useNavigate, Link } from "react-router-dom";
import HoverCart from "./HoverCart";
import Hoverwishlist from "./Hoverwishlist";
import Account from "./Account";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { useAuth } from "../../../Context/AuthContext";
import { FaSearch, FaHome } from "react-icons/fa";
import { IoLogoGooglePlaystore } from "react-icons/io5";
import { BiSolidCategoryAlt } from "react-icons/bi";
import { PiVideoFill } from "react-icons/pi";
import SearchBar from "./SearchBar";

export default function Header() {
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState({});
  const { isLoggedIn, user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await fetch(
        "https://auth.ssccglpinnacle.com/categories"
      );
      const data = await response.json();
      setCategories(data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const fetchSubcategories = async (categoryId) => {
    if (subcategories[categoryId]) return;

    try {
      const response = await fetch(
        `https://auth.ssccglpinnacle.com/exams/category/${categoryId}`
      );
      const data = await response.json();
      setSubcategories((prevSubcategories) => ({
        ...prevSubcategories,
        [categoryId]: data,
      }));
    } catch (error) {
      console.error("Error fetching subcategories:", error);
    }
  };

  return (
    <header className={classes.Header}>
      <div className={classes.logo_div} onClick={() => navigate("/")}>
        <img src={Logo} alt="Logo" className={classes.logo_img} />
      </div>

      <div className={classes.account_section}>
        <button
          onClick={() => navigate("/videos")}
          className={classes.home_button}
        >
          <FaHome className={classes.home_icon} />
          Home
        </button>

        <nav className={classes.subnav}>
          {/* <button className={classes.subnav_button}>
            <BiSolidCategoryAlt />
            Categories
          </button> */}
          <button
            className={classes.subnav_button}
            onClick={() =>
              navigate("/videos/category/66a8a5518c0f4f53f38de5e1")
            }
          >
            <BiSolidCategoryAlt />
            Categories
          </button>

          <div className={classes.subnav_content}>
            {categories.map((category) => (
              <div className={classes.subnav_content_subnav} key={category._id}>
                <div
                  className={classes.subnavbtn2x}
                  onClick={() => fetchSubcategories(category._id)}
                >
                  <Link
                    className={classes.item}
                    to={`/videos/category/${category._id}`}
                  >
                    {category.categoryTitle}
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </nav>

        <div className={classes.search_container}>
          <SearchBar />
        </div>

        <Link
          to="https://play.google.com/store/apps/details?id=com.ssccgl.pinnacle.testportal"
          target="_blank"
          rel="noopener noreferrer"
          className={classes.playstore_link}
        >
          <IoLogoGooglePlaystore className={classes.playstore_icon} size={20} />
          Google Play
        </Link>

        {isLoggedIn && user ? (
          <>
            <div className={classes.mylearning_div}>
              <Link
                to={`/videos/mylearning`}
                className={classes.myLearning_link}
              >
                <PiVideoFill size={20} /> My learning
              </Link>
            </div>
            <div
              className={classes.wishlist_icon}
              onClick={() => navigate("/videos/wishlist")}
            >
              <Hoverwishlist size={35} userId={user._id} />
            </div>
            <div
              className={classes.cart_icone}
              onClick={() => navigate("/videos/cart")}
            >
              <HoverCart size={35} userId={user._id} />
            </div>
            <Account userDetails={user} />
          </>
        ) : (
          <div className={classes.auth_buttons}>
            <button
              onClick={() =>
                (window.location.href =
                  "https://testportal.ssccglpinnacle.com/login")
              }
              className={classes.login_button}
            >
              Log in
            </button>
            <button
              onClick={() =>
                (window.location.href =
                  "https://testportal.ssccglpinnacle.com/login")
              }
              className={classes.login_button}
            >
              Sign up
            </button>
          </div>
        )}
      </div>
    </header>
  );
}

// import React, { useState, useEffect } from 'react';
// import classes from './Header.module.css';
// import Logo from "../../../assests/Pinnacle_colored_logo.svg";
// import videoCouresHomeIcon from "./homeIconVideoCourse.png"
// import { useNavigate, Link } from 'react-router-dom';
// import SearchBar from './SearchBar';
// import HoverCart from './HoverCart';
// import Hoverwishlist from './Hoverwishlist';
// import Account from './Account';
// import { TbHome } from 'react-icons/tb';
// import { MdOutlineKeyboardArrowRight } from "react-icons/md";
// import { useAuth } from "../../../Context/AuthContext";

// export default function Header() {

//   const [showMoreApps, setShowMoreApp] = useState(false);
//   const { isLoggedIn, user, token } = useAuth();
//   console.log("UserData", user)
//   const navigate = useNavigate();
//   useEffect(() => {
//     console.log("UserData", user);
//   }, [user]);

//   return (
//     <div className={classes.Header_fullPage}>
//       <div className={classes.main}>
//         <div className={classes.logo_div} onClick={() => navigate('/')}>
//           <img src={Logo} alt='Logo' className={classes.logo_img} />
//           </div>
//           {/* <Link to='/videos' className={classes['home-videos']} >
//         <TbHome size={25} />
//         </Link> */}
//         <button onClick={() => navigate('/videos')} className={classes['home-videos']} >Home</button>
//           {/* <div className={classes.logo_div} onClick={() => navigate('/videos')}>
//           <img src={videoCouresHomeIcon} alt='videoCouresHomeIcon' className={classes.logo_img} />
//           </div> */}

//         <div className={classes.subnav}>
//           <div className={classes.subnavbtn} onClick={() => navigate("/videos/SSCCategory")}>Categories</div>
//           <div className={classes.subnav_content}>
//             <div className={classes.subnav_content_subnav}>
//               <div className={classes.subnavbtn2x}>
//                 <Link className={classes.item} to="/videos/SSCCategory">
//                   SSC
//                   <div className={classes.arrw_icon1}>
//                     <MdOutlineKeyboardArrowRight
//                       className={classes.arrow}
//                       size={20}
//                     />
//                   </div>
//                 </Link>
//               </div>
//               {/* <div className={classes.subnav_content2x}>
//                 <Link to="/videos/SSCCategory">SSC CGL (Combined Graduate Level)</Link>
//                 <Link to="/videos/SSCCategory">
//                   SSC CHSL (Combined Higher Secondary Level)
//                 </Link>
//                 <Link to="/videos/SSCCategory">SSC Multitasking (Non-Technical)</Link>
//                 <Link to="/videos/SSCCategory">SSC Stenographers Grade 'C' & 'D'</Link>
//                 <Link to="/videos/SSCCategory">
//                   SSC CPO(Central Police Organization)
//                 </Link>
//                 <Link to="/videos/SSCCategory">SSC JE(Junior Engineer)</Link>
//                 <Link to="/videos/SSCCategory">SSC Junior Hindi Translator</Link>
//               </div> */}
//             </div>
//             <div className={classes.subnav_content_subnav}>
//               <div className={classes.subnavbtn2x}>
//                 <Link className={classes.item} to="/videos/RailwayCategory">
//                   Railway
//                   <div className={classes.arrw_icon2}>
//                     <MdOutlineKeyboardArrowRight
//                       className={classes.arrow}
//                       size={20}
//                     />
//                   </div>
//                 </Link>
//               </div>
//               {/* <div className={classes.subnav_content2x}>
//                 <Link to="/videos/RailwayCategory">RRB NTPC</Link>
//                 <Link to="/videos/RailwayCategory">RRB JE</Link>
//                 <Link to="/videos/RailwayCategory">RRB ALP</Link>
//                 <Link to="/videos/RailwayCategory">
//                 RRB Ministerial and Isolated Category
//                 </Link>
//                 <Link to="/videos/RailwayCategory">RRB Group D</Link>
//                 <Link to="/videos/RailwayCategory">RRB Ministerial </Link>
//                 <Link to="/videos/RailwayCategory">RRB Paramedical</Link>
//                 <Link to="/videos/RailwayCategory">Goods Guard</Link>
//                 <Link to="/videos/RailwayCategory">Junior Engineer</Link>
//                 <Link to="/videos/RailwayCategory">RPF constable</Link>
//                 <Link to="/videos/RailwayCategory">RRB ntcp</Link>
//                 <Link to="/videos/RailwayCategory">Traffic Assistant</Link>
//                 <Link to="/videos/RailwayCategory">Trains Clerk</Link>
//               </div> */}
//             </div>
//             <div className={classes.subnav_content_subnav}>
//               <div className={classes.subnavbtn2x}>
//                 <Link className={classes.item} to="/videos/DelhiPoliceCategory">
//                   Delhi POLICE
//                   <div className={classes.arrw_icon3}>
//                     <MdOutlineKeyboardArrowRight
//                       className={classes.arrow}
//                       size={20}
//                     />
//                   </div>
//                 </Link>
//               </div>
//               {/* <div className={classes.subnav_content2x}>
//                 <Link to="/videos/UpPoliceCategory">Mental Aptitude, I.Q. and Reasoning Ability</Link>
//                 <Link to="/videos/UpPoliceCategory">General Knowledge</Link>
//                 <Link to="/videos/UpPoliceCategory">Numerical and Mental Ability</Link>
//                 <Link to="/videos/UpPoliceCategory">General Hindi</Link>
//               </div> */}
//             </div>
//             <div className={classes.subnav_content_subnav}>
//               <div className={classes.subnavbtn2x}>
//                 <Link className={classes.item} to="/videos/UpPoliceCategory">
//                   UP POLICE
//                   <div className={classes.arrw_icon3}>
//                     <MdOutlineKeyboardArrowRight
//                       className={classes.arrow}
//                       size={20}
//                     />
//                   </div>
//                 </Link>
//               </div>
//               {/* <div className={classes.subnav_content2x}>
//                 <Link to="/videos/UpPoliceCategory">Mental Aptitude, I.Q. and Reasoning Ability</Link>
//                 <Link to="/videos/UpPoliceCategory">General Knowledge</Link>
//                 <Link to="/videos/UpPoliceCategory">Numerical and Mental Ability</Link>
//                 <Link to="/videos/UpPoliceCategory">General Hindi</Link>
//               </div> */}
//             </div>
//             <div className={classes.subnav_content_subnav}>
//               <div className={classes.subnavbtn2x}>
//                 <Link className={classes.item} to="/videos/CtetCategory">
//                   CTET
//                   <div className={classes.arrw_icon4}>
//                     <MdOutlineKeyboardArrowRight
//                       className={classes.arrow}
//                       size={20}
//                     />
//                   </div>
//                 </Link>
//               </div>
//               {/* <div className={classes.subnav_content2x}>
//                 <Link to="/videos/CtetCategory">Child Development and Pedagogy</Link>
//                 <Link to="/videos/CtetCategory">Mathematics</Link>
//                 <Link to="/videos/CtetCategory">Environmental Studies</Link>
//                 <Link to="/videos/CtetCategory">Hindi</Link>
//                 <Link to="/videos/CtetCategory">English</Link>
//               </div> */}
//             </div>
//           </div>
//         </div>
//       </div>
//       <SearchBar />

//       <a href='https://play.google.com/store/search?q=pinnacle&c=apps&hl=en-IN' target='_blank' rel='noopener noreferrer' className={classes['Playstore-a']}>
//         <img src='https://d3m4h509gttb94.cloudfront.net/frontend_images/playstore.png' alt='📱' width='25px' height='25px' className={classes.playStore_image} />
//       </a>

//        {isLoggedIn && user ? (
//         <>

//           <div className={classes['mylearning-div']}>
//             <Link to={`/mylearning/${user._id}`} className={classes['myLearning-icon']}>
//               My learning
//             </Link>
//           </div>
//           <div className={classes['wishlist-icon']} onClick={() => navigate('/videos/wishlist')}>
//             <Hoverwishlist size={35} userId={user._id} />
//           </div>
//           <div className={classes.cart_icone} onClick={() => navigate('/videos/cart')}>
//             <HoverCart size={35} userId={user._id} />
//           </div>
//           <Account userDetails={user} />
//         </>
//       ) : (
//         <div>
//           <button onClick={() => window.location.href = 'https://testportal.ssccglpinnacle.com/login'} className={classes["Vc-login-btn"]}>Log in</button>
//           <button onClick={() => window.location.href = 'https://testportal.ssccglpinnacle.com/login'} className={classes["Vc-login-btn"]}>Sign up</button>
//         </div>
//       )}
//     </div>
//   );
// };

// import React, { useState, useEffect } from "react";
// import classes from "./Header.module.css";
// import Logo from "../../../assests/pinnacleWhiteLogo.svg";
// // import Playstore from "../../../assests/white playstore.svg";
// import { useNavigate, Link } from "react-router-dom";
// import HoverCart from "./HoverCart";
// import Hoverwishlist from "./Hoverwishlist";
// import Account from "./Account";
// // import { MdOutlineKeyboardArrowRight } from "react-icons/md";
// import { useAuth } from "../../../Context/AuthContext";
// // import { FaSearch, FaHome } from "react-icons/fa";
// import { FaBars, FaTimes, FaHome } from "react-icons/fa";
// import { IoLogoGooglePlaystore } from "react-icons/io5";
// import { BiSolidCategoryAlt } from "react-icons/bi";
// import { PiVideoFill } from "react-icons/pi";
// import SearchBar from "./SearchBar";

// export default function Header() {
//   // const [showMoreApps, setShowMoreApp] = useState(false);
//   const [menuOpen, setMenuOpen] = useState(false); // State for hamburger menu
//   const [categories, setCategories] = useState([]);
//   const [subcategories, setSubcategories] = useState({});
//   const { isLoggedIn, user } = useAuth();
//   const navigate = useNavigate();

//   useEffect(() => {
//     fetchCategories();
//   }, []);

//   const fetchCategories = async () => {
//     try {
//       const response = await fetch(
//         "https://auth.ssccglpinnacle.com/categories"
//       );
//       const data = await response.json();
//       setCategories(data);
//     } catch (error) {
//       // console.error("Error fetching categories:", error);
//     }
//   };

//   const fetchSubcategories = async (categoryId) => {
//     if (subcategories[categoryId]) return; // Already fetched

//     try {
//       const response = await fetch(
//         `https://auth.ssccglpinnacle.com/exams/category/${categoryId}`
//       );
//       const data = await response.json();
//       setSubcategories((prevSubcategories) => ({
//         ...prevSubcategories,
//         [categoryId]: data,
//       }));
//     } catch (error) {
//       console.error("Error fetching subcategories:", error);
//     }
//   };

//   // Toggle menu open/close
//   const toggleMenu = () => {
//     setMenuOpen(!menuOpen);
//   };

//   return (
//     <div className={classes.Header}>
//       <div className={classes.logo_div} onClick={() => navigate("/")}>
//         <img src={Logo} alt="Logo" className={classes.logo_img} />
//       </div>

//       <div className={classes.hamburger_icon} onClick={toggleMenu}>
//         {menuOpen ? <FaTimes size={30} /> : <FaBars size={30} />}
//         {/* Toggle icon */}
//       </div>

//       <nav className={`${classes.menu} ${menuOpen ? classes.menu_open : ""}`}>
//         <div className={classes.account_section}>
//           <button
//             onClick={() => navigate("/videos")}
//             className={classes.home_button}
//           >
//             <FaHome className={classes.home_icon} />
//             Home
//           </button>

//           <nav className={classes.subnav}>
//             <button
//               className={classes.subnav_button}
//               // onClick={() => navigate("/videos/SSCCategory")}
//             >
//               <BiSolidCategoryAlt className={classes.category_icon} />
//               Categories
//             </button>
//             <div className={classes.subnav_content}>
//               <div className={classes.subnav_content_subnav}>
//                 <div className={classes.subnavbtn2x}>
//                   <Link className={classes.item} to="/videos/category/SSC">
//                     SSC
//                     <div className={classes.arrw_icon1}>
//                       {/* <MdOutlineKeyboardArrowRight
//                     className={classes.arrow}
//                     size={20}
//                   /> */}
//                     </div>
//                   </Link>
//                 </div>
//                 <div className={classes.subnav_content_subnav}>
//                   <div className={classes.subnavbtn2x}>
//                     <Link
//                       className={classes.item}
//                       to="/videos/category/Railway"
//                     >
//                       Railway
//                       <div className={classes.arrw_icon2}>
//                         {/* <MdOutlineKeyboardArrowRight
//                       className={classes.arrow}
//                       size={20}
//                     /> */}
//                       </div>
//                     </Link>
//                   </div>
//                 </div>
//                 <div className={classes.subnav_content_subnav}>
//                   <div className={classes.subnavbtn2x}>
//                     <Link
//                       className={classes.item}
//                       to="/videos/category/DelhiPolice"
//                     >
//                       Delhi POLICE
//                       <div className={classes.arrw_icon3}>
//                         {/* <MdOutlineKeyboardArrowRight
//                         className={classes.arrow}
//                         size={20}
//                       /> */}
//                       </div>
//                     </Link>
//                   </div>
//                 </div>
//                 <div className={classes.subnav_content_subnav}>
//                   <div className={classes.subnavbtn2x}>
//                     <Link
//                       className={classes.item}
//                       to="/videos/category/UpPolice"
//                     >
//                       {" "}
//                       UP POLICE
//                       <div className={classes.arrw_icon3}>
//                         {/* <MdOutlineKeyboardArrowRight
//                           className={classes.arrow}
//                           size={20}
//                         /> */}
//                       </div>
//                     </Link>
//                   </div>
//                 </div>
//                 {/* <div className={classes.subnav_content2x}>
//                 <Link to="/videos/UpPoliceCategory">Mental Aptitude, I.Q. and Reasoning Ability</Link>
//                 <Link to="/videos/UpPoliceCategory">General Knowledge</Link>
//                 <Link to="/videos/UpPoliceCategory">Numerical and Mental Ability</Link>
//                 <Link to="/videos/UpPoliceCategory">General Hindi</Link>
//               </div> */}

//                 <div className={classes.subnav_content_subnav}>
//                   <div className={classes.subnavbtn2x}>
//                     <Link className={classes.item} to="/videos/category/Ctet">
//                       CTET
//                       <div className={classes.arrw_icon4}>
//                         {/* <MdOutlineKeyboardArrowRight
//                           className={classes.arrow}
//                           size={20}
//                         /> */}
//                       </div>
//                     </Link>
//                   </div>
//                   {/* <div className={classes.subnav_content2x}>
//                 <Link to="/videos/CtetCategory">Child Development and Pedagogy</Link>
//                 <Link to="/videos/CtetCategory">Mathematics</Link>
//                 <Link to="/videos/CtetCategory">Environmental Studies</Link>
//                 <Link to="/videos/CtetCategory">Hindi</Link>
//                 <Link to="/videos/CtetCategory">English</Link>
//               </div> */}
//                 </div>
//               </div>
//             </div>
//           </nav>

//           {!menuOpen && (
//             <div className={classes.search_container}>
//               <SearchBar />
//               {/* <FaSearch className={classes.search_icon} size={20}/>
//           <input
//             type="text"
//             name="product-search"
//             id="product-search"
//             placeholder="Search For Anything"
//             className={classes.search_input}

//           /> */}
//             </div>
//           )}

//           <Link
//             to="https://play.google.com/store/search?q=pinnacle&c=apps&hl=en-IN"
//             target="_blank"
//             rel="noopener noreferrer"
//             className={classes.playstore_link}
//           >
//             <IoLogoGooglePlaystore
//               className={classes.playstore_icon}
//               size={20}
//             />
//             Google Play
//           </Link>

//           {isLoggedIn && user ? (
//             <>
//               <div className={classes.mylearning_div}>
//                 <Link
//                   to={`/mylearning/${user._id}`}
//                   className={classes.myLearning_link}
//                 >
//                   <PiVideoFill className={classes.PiVideoFill_icon} size={20} />{" "}
//                   My learning
//                 </Link>
//               </div>
//               <div
//                 className={classes.wishlist_icon}
//                 onClick={() => navigate("/videos/wishlist")}
//               >
//                 <Hoverwishlist size={35} userId={user._id} />
//               </div>
//               <div
//                 className={classes.cart_icone}
//                 onClick={() => navigate("/videos/cart")}
//               >
//                 <HoverCart size={35} userId={user._id} />
//               </div>
//               <Account userDetails={user} />
//             </>
//           ) : (
//             <div className={classes.auth_buttons}>
//               <button
//                 onClick={() =>
//                   (window.location.href =
//                     "https://testportal.ssccglpinnacle.com/login")
//                 }
//                 className={classes.login_button}
//               >
//                 Log in
//               </button>
//               <button
//                 onClick={() =>
//                   (window.location.href =
//                     "https://testportal.ssccglpinnacle.com/login")
//                 }
//                 className={classes.login_button}
//               >
//                 Sign up
//               </button>
//             </div>
//           )}
//         </div>
//       </nav>

//         <div
//           className={`${classes.hamburger_icon_search_bar} ${classes.humburger_menu_icon_two}`}
//           >
//           <SearchBar />
//         </div>
//           {/* {!menuOpen && (
//       )} */}

//       {/* <div
//         className={`${classes.hamburger_icon_search_bar} ${menuOpen ? classes.search_open : ""}`}
//       >
//         <SearchBar />
//       </div> */}

//     </div>
//   );
// }

// import React, { useState, useEffect } from "react";
// import classes from "./Header.module.css";
// import Logo from "../../../assests/pinnacleWhiteLogo.svg";
// import Playstore from "../../../assests/white playstore.svg";
// import { useNavigate, Link } from "react-router-dom";
// import HoverCart from "./HoverCart";
// import Hoverwishlist from "./Hoverwishlist";
// import Account from "./Account";
// import { FaBars, FaTimes, FaHome } from "react-icons/fa";
// import { IoLogoGooglePlaystore } from "react-icons/io5";
// import { BiSolidCategoryAlt } from "react-icons/bi";
// import { PiVideoFill } from "react-icons/pi";
// import SearchBar from "./SearchBar";
// import { useAuth } from "../../../Context/AuthContext";

// export default function Header() {
//   const [menuOpen, setMenuOpen] = useState(false); // State for hamburger menu
//   const { isLoggedIn, user } = useAuth();
//   const navigate = useNavigate();

//   useEffect(() => {
//     console.log("UserData", user);
//   }, [user]);

//   // Toggle menu open/close
//   const toggleMenu = () => {
//     setMenuOpen(!menuOpen);
//   };

//   return (
//     <div className={classes.Header}>
//       <div className={classes.logo_div} onClick={() => navigate("/")}>
//         <img src={Logo} alt="Logo" className={classes.logo_img} />
//       </div>

//       {/* Hamburger Menu Icon */}
//       <div className={classes.hamburger_icon} onClick={toggleMenu}>
//         {menuOpen ? <FaTimes size={30} /> : <FaBars size={30} />}
//       </div>

//       <nav className={`${classes.menu} ${menuOpen ? classes.menu_open : ""}`}>
//         <div className={classes.account_section}>
//           <button onClick={() => navigate("/videos")} className={classes.home_button}>
//             <FaHome className={classes.home_icon} />
//             Home
//           </button>

//           <nav className={classes.subnav}>
//             <button className={classes.subnav_button}>
//               <BiSolidCategoryAlt />
//               Categories
//             </button>
//             {/* Add more category links here */}
//           </nav>

//           <Link
//             to="https://play.google.com/store/search?q=pinnacle&c=apps&hl=en-IN"
//             target="_blank"
//             rel="noopener noreferrer"
//             className={classes.playstore_link}
//           >
//             <IoLogoGooglePlaystore className={classes.playstore_icon} size={20} />
//             Google Play
//           </Link>

//           {isLoggedIn && user ? (
//             <>
//               <div className={classes.mylearning_div}>
//                 <Link to={`/mylearning/${user._id}`} className={classes.myLearning_link}>
//                   <PiVideoFill className={classes.PiVideoFill_icon} size={20} /> My learning
//                 </Link>
//               </div>
//               <div className={classes.wishlist_icon} onClick={() => navigate("/videos/wishlist")}>
//                 <Hoverwishlist size={35} userId={user._id} />
//               </div>
//               <div className={classes.cart_icone} onClick={() => navigate("/videos/cart")}>
//                 <HoverCart size={35} userId={user._id} />
//               </div>
//               <Account userDetails={user} />
//             </>
//           ) : (
//             <div className={classes.auth_buttons}>
//               <button
//                 onClick={() =>
//                   (window.location.href =
//                     "https://testportal.ssccglpinnacle.com/login")
//                 }
//                 className={classes.login_button}
//               >
//                 Log in
//               </button>
//               <button
//                 onClick={() =>
//                   (window.location.href =
//                     "https://testportal.ssccglpinnacle.com/login")
//                 }
//                 className={classes.login_button}
//               >
//                 Sign up
//               </button>
//             </div>
//           )}
//         </div>
//       </nav>

//       {/* Search Bar - Always inside Header */}
//       <div className={classes.search_container}>
//         <SearchBar />
//       </div>
//     </div>
//   );
// }

// import React, { useState, useEffect } from "react";
// import classes from "./Header.module.css";
// import MoreApps from "./MoreApps";
// import Logo from "../../../assests/Pinnacle_colored_logo.svg";
// import { useNavigate, Link } from "react-router-dom";
// import SearchBar from "./SearchBar";
// import HoverCart from "./HoverCart";
// import Hoverwishlist from "./Hoverwishlist";
// import Account from "./Account";
// import { MdOutlineKeyboardArrowRight } from "react-icons/md";
// import { TbHome } from "react-icons/tb";

// export default function Header() {
//   const [showMoreApps, setShowMoreApp] = useState(false);
//   const navigate = useNavigate();

//   // Retrieve jsonData from local storage and parse it
//   const storedJson = localStorage.getItem('jsonData');
//   const parsedJson = storedJson ? JSON.parse(storedJson) : null;
//   const token = parsedJson ? parsedJson.token_id : null;
//   const userDetails = parsedJson && parsedJson.details ? parsedJson.details[0] : null;
//   const userId = userDetails ? userDetails._id : null;
//   console.log(`token: ${token} and userId: ${userId}`);

//   const MoreAppsHandler = () => {
//     setShowMoreApp(!showMoreApps);
//   };

//   return (
//     <div className={classes.Header_fullPage}>
//       <div className={classes.main}>
//         <div className={classes.logo_div} onClick={() => navigate("/")}>
//           <img src={Logo} alt="Logo" className={classes.logo_img} />
//         </div>
//         <Link to="/videos_hp"className={classes["videoCourse-homediv"]} ><TbHome className={classes["admin-navItems"]} size={20}/></Link>

// <div className={classes.subnav}>
//   <div className={classes.subnavbtn} onClick={() => navigate("/vc/ssc-categorory")}>Categories</div>
//   <div className={classes.subnav_content}>
//     <div className={classes.subnav_content_subnav}>
//       <div className={classes.subnavbtn2x}>
//         <Link className={classes.item} to="/vc/ssc-categorory">
//           SSC
//           <div className={classes.arrw_icon1}>
//             <MdOutlineKeyboardArrowRight
//               className={classes.arrow}
//               size={20}
//             />
//           </div>
//         </Link>
//       </div>
//       <div className={classes.subnav_content2x}>
//         <Link to="/vc/ssc-categorory">SSC CGL (Combined Graduate Level)</Link>
//         <Link to="/vc/ssc-categorory">
//           SSC CHSL (Combined Higher Secondary Level)
//         </Link>
//         <Link to="/vc/ssc-categorory">SSC Multitasking (Non-Technical)</Link>
//         <Link to="/vc/ssc-categorory">SSC Stenographers Grade 'C' & 'D'</Link>
//         <Link to="/vc/ssc-categorory">
//           SSC CPO(Central Police Organization)
//         </Link>
//         <Link to="/vc/ssc-categorory">SSC JE(Junior Engineer)</Link>
//         <Link to="/vc/ssc-categorory">SSC Junior Hindi Translator</Link>
//       </div>
//     </div>
//     <div className={classes.subnav_content_subnav}>
//       <div className={classes.subnavbtn2x}>
//         <Link className={classes.item} to="/vc/railway-categorory">
//           Railway
//           <div className={classes.arrw_icon2}>
//             <MdOutlineKeyboardArrowRight
//               className={classes.arrow}
//               size={20}
//             />
//           </div>
//         </Link>
//       </div>
//       <div className={classes.subnav_content2x}>
//         <Link to="/vc/railway-categorory">RRB NTPC</Link>
//         <Link to="/vc/railway-categorory">RRB JE</Link>
//         <Link to="/vc/railway-categorory">RRB ALP</Link>
//         <Link to="/vc/railway-categorory">
//         RRB Ministerial and Isolated Category
//         </Link>
//         <Link to="/vc/railway-categorory">RRB Group D</Link>
//         <Link to="/vc/railway-categorory">RRB Ministerial </Link>
//         <Link to="/vc/railway-categorory">RRB Paramedical</Link>
//         <Link to="/vc/railway-categorory">Goods Guard</Link>
//         <Link to="/vc/railway-categorory">Junior Engineer</Link>
//         <Link to="/vc/railway-categorory">RPF constable</Link>
//         <Link to="/vc/railway-categorory">RRB ntcp</Link>
//         <Link to="/vc/railway-categorory">Traffic Assistant</Link>
//         <Link to="/vc/railway-categorory">Trains Clerk</Link>
//       </div>
//     </div>
//     <div className={classes.subnav_content_subnav}>
//       <div className={classes.subnavbtn2x}>
//         <Link className={classes.item} to="/vc/upPolice-categorory">
//           UP POLICE
//           <div className={classes.arrw_icon3}>
//             <MdOutlineKeyboardArrowRight
//               className={classes.arrow}
//               size={20}
//             />
//           </div>
//         </Link>
//       </div>
//       <div className={classes.subnav_content2x}>
//         <Link to="/vc/upPolice-categorory">Mental Aptitude, I.Q. and Reasoning Ability</Link>
//         <Link to="/vc/upPolice-categorory">General Knowledge</Link>
//         <Link to="/vc/upPolice-categorory">Numerical and Mental Ability</Link>
//         <Link to="/vc/upPolice-categorory">General Hindi</Link>
//       </div>
//     </div>
//     <div className={classes.subnav_content_subnav}>
//       <div className={classes.subnavbtn2x}>
//         <Link className={classes.item} to="/vc/ctet-categorory">
//           CTET
//           <div className={classes.arrw_icon4}>
//             <MdOutlineKeyboardArrowRight
//               className={classes.arrow}
//               size={20}
//             />
//           </div>
//         </Link>
//       </div>
//       <div className={classes.subnav_content2x}>
//         <Link to="/vc/ctet-categorory">Child Development and Pedagogy</Link>
//         <Link to="/vc/ctet-categorory">Mathematics</Link>
//         <Link to="/vc/ctet-categorory">Environmental Studies</Link>
//         <Link to="/vc/ctet-categorory">Hindi</Link>
//         <Link to="/vc/ctet-categorory">English</Link>
//       </div>
//     </div>
//   </div>
// </div>
// </div>
//       <SearchBar />

//       <div>
//         <a href="https://play.google.com/store/search?q=pinnacle&c=apps&hl=en-IN"
//            target="_blank" rel="noopener noreferrer"
//            className={classes["Playstore-a"]}>
//           <img src="https://d3m4h509gttb94.cloudfront.net/frontend_images/playstore.png" alt="📱" width="25px" height="25px" className={classes.playStore_image} />
//         </a>
//       </div>

//       {/* Conditional rendering based on authentication status */}
//       {token ? (
//         <>
//           <div className={classes["mylearning-div"]}>
//             {userId && <Link to={`/MyLearningPage/${userId}`} className={classes["myLearning-icon"]}>My learning</Link>}
//           </div>
//           <div className={classes["wishlist-icon"]} onClick={() => navigate("/wishlistPage")}>
//             <Hoverwishlist size={35} />
//           </div>
//           <div className={classes.cart_icone} onClick={() => navigate("/cartPage")}>
//             <HoverCart size={35} />
//           </div>
//           <Account className={classes["Account-div"]} />
//         </>
//       ) : (
//         <div>
//           <div >
//             <button  onClick={() => navigate("/login")}>Log in</button>
//           </div>
//           <div>
//             <button  onClick={() => navigate("/signup")}>Sign up</button>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// }

// import React, { useState, useEffect } from "react";
// import classes from "./Header.module.css";
// import MoreApps from "./MoreApps";
// import Logo from "../../../assests/Pinnacle_colored_logo.svg";
// import { MdOutlineKeyboardArrowRight } from "react-icons/md";
// import SearchBar from "./SearchBar";
// import { BsGlobe } from "react-icons/bs";
// import { GrApps } from "react-icons/gr";
// import { useNavigate, Link } from "react-router-dom";
// import HoverCart from "./HoverCart";
// import Hoverwishlist from "./Hoverwishlist";
// import Account from "./Account"

// export default function Header() {
//   // const userId=JSON.parse(localStorage.getItem("user"))._id
//   const [showMoreApps, setShowMoreApp] = useState(false);
//   const [showLanguage, setShowLanguage] = useState(false);
//   const [isMenuOpen, setMenuOpen] = useState(false)
//   const navigate = useNavigate();
//   const auth = localStorage.getItem("user");
//   const getUserId = () => {
//     const user = localStorage.getItem("user");
//     return user ? JSON.parse(user)._id : null;
//   };

//   const userId = getUserId();
//   const MoreAppsHandler = () => {
//     setShowMoreApp(true);
//     setShowLanguage(false);
//     handleMoreApp();
//   };

//   const LanguageHandler = () => {
//     setShowLanguage(true);
//     setShowMoreApp(false);
//     handlelLanguageHandler();
//   };

//   const handleMoreApp = () => {
//     setShowMoreApp(!showMoreApps);
//     setShowLanguage(false);
//   };

//   const handlelLanguageHandler = () => {
//     setShowMoreApp(false);
//     setShowLanguage(!showLanguage);
//   };

//   return (
//     <div className={classes.Header_fullPage}>
//       <div className={classes.main}>
//         <div className={classes.logo_div} onClick={() => navigate("/videos_hp")}>
//           <img src={Logo} alt="Logo" className={classes.logo_img} />
//         </div>
//   <div className={classes.subnav}>
//     <div className={classes.subnavbtn}>Categories</div>
//     <div className={classes.subnav_content}>
//       <div className={classes.subnav_content_subnav}>
//         <div className={classes.subnavbtn2x}>
//           <Link className={classes.item} to="/exploreMore">
//             SSC
//             <div className={classes.arrw_icon1}>
//               <MdOutlineKeyboardArrowRight
//                 className={classes.arrow}
//                 size={20}
//               />
//             </div>
//           </Link>
//         </div>
//         <div className={classes.subnav_content2x}>
//           <Link to="/exploreMore">SSC CGL (Combined Graduate Level)</Link>
//           <Link to="/exploreMore">
//             SSC CHSL (Combined Higher Secondary Level)
//           </Link>
//           <Link to="/exploreMore">SSC Multitasking (Non-Technical)</Link>
//           <Link to="/exploreMore">SSC Stenographers Grade 'C' & 'D'</Link>
//           <Link to="/exploreMore">
//             SSC CPO(Central Police Organization)
//           </Link>
//           <Link to="/exploreMore">SSC JE(Junior Engineer)</Link>
//           <Link to="/exploreMore">SSC Junior Hindi Translator</Link>
//         </div>
//       </div>
//       <div className={classes.subnav_content_subnav}>
//         <div className={classes.subnavbtn2x}>
//           <Link className={classes.item} to="/railwayExploreMore">
//             Railway
//             <div className={classes.arrw_icon2}>
//               <MdOutlineKeyboardArrowRight
//                 className={classes.arrow}
//                 size={20}
//               />
//             </div>
//           </Link>
//         </div>
//         <div className={classes.subnav_content2x}>
//           <Link to="/railwayExploreMore">RRB NTPC</Link>
//           <Link to="/railwayExploreMore">RRB JE</Link>
//           <Link to="/railwayExploreMore">RRB ALP</Link>
//           <Link to="/railwayExploreMore">
//             RRB Ministerial and Isolated Categori
//           </Link>
//           <Link to="/railwayExploreMore">RRB Group D</Link>
//           <Link to="/railwayExploreMore">RRB Ministerial </Link>
//           <Link to="/railwayExploreMore">RRB Paramedical</Link>
//           <Link to="/railwayExploreMore">Goods Guard</Link>
//           <Link to="/railwayExploreMore">Junior Engineer</Link>
//           <Link to="/railwayExploreMore">RPF constable</Link>
//           <Link to="/railwayExploreMore">RRB ntcp</Link>
//           <Link to="/railwayExploreMore">Traffic Assistant</Link>
//           <Link to="/railwayExploreMore">Trains Clerk</Link>
//         </div>
//       </div>
//       <div className={classes.subnav_content_subnav}>
//         <div className={classes.subnavbtn2x}>
//           <Link className={classes.item} to="/railwayExploreMore">
//             Bank
//             <div className={classes.arrw_icon3}>
//               <MdOutlineKeyboardArrowRight
//                 className={classes.arrow}
//                 size={20}
//               />
//             </div>
//           </Link>
//         </div>
//         <div className={classes.subnav_content2x}>
//           <Link to="/">Quantative Aptitude</Link>
//           <Link to="/">General Science</Link>
//           <Link to="/">Computer Science</Link>
//           <Link to="/">Qualitative Aptitude</Link>
//         </div>
//       </div>
//       <div className={classes.subnav_content_subnav}>
//         <div className={classes.subnavbtn2x}>
//           <Link className={classes.item} to="/">
//             Other Exams
//             <div className={classes.arrw_icon4}>
//               <MdOutlineKeyboardArrowRight
//                 className={classes.arrow}
//                 size={20}
//               />
//             </div>
//           </Link>
//         </div>
//         <div className={classes.subnav_content2x}>
//           <Link to="/">Quantative Aptitude</Link>
//           <Link to="/">General Science1</Link>
//           <Link to="/">Computer Science1</Link>
//           <Link to="/">Qualitative Aptitude1</Link>
//         </div>
//       </div>
//     </div>
//   </div>
// </div>
//       <SearchBar />

//       <div>
//       <a href="https://play.google.com/store/search?q=pinnacle&c=apps&hl=en-IN"target="blank"className={classes["Playstore-a"]}>
//       <img
//             src="/image/playstore.png"
//             alt="📱"
//             width="25px"
//             height="25px"
//             className={classes.playStore_image}
//           />
//       </a>
//       </div>

//     <div className={classes.playStore_icone}>
//         <button className={classes.Playstore_btn}>
//         <img
//             src="/image/playstore.png"
//             alt=""
//             width="25px"
//             height="25px"
//             className={classes.playStore_image}
//           />
//         </button>
//         <div className={classes.Playstore_description}>
//           <a
//             href="https://play.google.com/store/search?q=pinnacle&c=apps&hl=en-IN"
//             target="blank"
//             className={classes["Playstore-a"]}
//           >
//             Download Mobile app
//           </a>
//         </div>
//       </div>
//       {auth ? (
//         <>
//           <div className={classes["mylearning-div"]}>
//             <Link to={`/MyLearningPage/${userId}`} className={classes["myLearning-icon"]}>
//               My learning
//             </Link>
//           </div>
//           <div
//             className={classes["wishlist-icon"]}
//             onClick={() => navigate("/wishlistPage")}
//           >
//             <Hoverwishlist size={35} />
//           </div>
//           <div className={classes.cart_icone} onClick={() => navigate("/cartPage")}>
//             <HoverCart size={35} />
//           </div>
//           <Account className={classes["Account-div"]} />
//         </>
//       ) : (
//         <>
//           {/* <div
//             className={classes.cart_icone}
//             onClick={() => navigate("/cart")}
//           ><HoverCart /></div>
//           <div className={classes.globe_icon}>
//             <BsGlobe size={20} onClick={LanguageHandler} />
//           </div> */}
//           {/* <div className={classes.moreapp_icon}>
//             <button onClick={MoreAppsHandler} className={classes["MoreApps-btn"]}>
//               <GrApps size={20} />
//             </button>
//           </div> */}
//           {/* <div className={classes.login}>
//             <button
//               className={classes.login_btn}
//               onClick={() => navigate("/login")}
//             >
//               Log in
//             </button>
//           </div> */}
//           {/* <div className={classes.signup}>
//             <button
//               className={classes.signup_btn}
//               onClick={() => navigate("/signup")}
//             >
//               Sign up
//             </button>
//           </div> */}
//         </>
//       )}
//       <div className={classes.moreapp_icon}>
//         <button onClick={MoreAppsHandler} className={classes["MoreApps-btn"]}>
//           <GrApps size={30} />
//         </button>
//       </div>
//       {/* <div className={classes.Languages_div}>
//         {showLanguage && <Languages />}
//       </div> */}
//       <div className={classes.moreApps_div}>{showMoreApps && <MoreApps />}</div>
//     </div>
//   );
// }
