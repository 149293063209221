const geographyQuizData = [
  {
    question: "What is the capital city of Australia?",
    options: ["Sydney", "Melbourne", "Canberra", "Perth"],
    answerIndex: 2
  },
  {
    question: "Which is the largest desert in the world?",
    options: [
      "Sahara Desert",
      "Gobi Desert",
      "Atacama Desert",
      "Antarctic Desert"
    ],
    answerIndex: 0
  },
  {
    question: "What is the longest river in the world?",
    options: [
      "Nile River",
      "Amazon River",
      "Yangtze River",
      "Mississippi River"
    ],
    answerIndex: 0
  },
  {
    question: "Which country is known as the 'Land of the Rising Sun'?",
    options: ["China", "Japan", "South Korea", "Vietnam"],
    answerIndex: 1
  },
  {
    question: "Which continent is the largest by land area?",
    options: ["Asia", "Africa", "North America", "South America"],
    answerIndex: 0
  }
];

export default geographyQuizData;
