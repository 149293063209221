// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PaymentSteps_PaymentSteps-fullPage__cV6bK {
    /* border: 1px solid red; */
    margin: 0.5rem;
    padding: 0.5rem;
}

.PaymentSteps_PaymentSteps-Heading__pIVlP {

    font-size: 2rem;
    padding: 0.5rem;
    margin: 0.5rem;
    /* border: 1px solid; */

}

.PaymentSteps_PaymentSteps-MainContainer__udG1y {
    /* border: 1px solid navy; */
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 0.5rem;
    padding: 0.5rem;

}
.PaymentSteps_PaymentSteps-image-div__3WLvP{
    /* border: 1px solid red; */
    width: 100%;
    height:25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    font-weight: bold;
}
.PaymentSteps_PaymentSteps-image__RzHBM{
    width: 90%;
    height:85%;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    margin: 0.5rem;
    padding: 0.5rem;
}


.PaymentSteps_heading-div__-d8BD {
    font-size: 16px;
    font-weight: bold;
    line-height: 30px;
    margin-left: 5rem;
    padding: 1rem;
}

.PaymentSteps_para-div__d8ycg {

    font-size: 14px;
    line-height: 30px;
    margin-left: 5rem;
    padding: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/HelpDeskBP/PaymentSteps.module.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,cAAc;IACd,eAAe;AACnB;;AAEA;;IAEI,eAAe;IACf,eAAe;IACf,cAAc;IACd,uBAAuB;;AAE3B;;AAEA;IACI,4BAA4B;IAC5B,aAAa;IACb,sBAAsB;IACtB,eAAe;IACf,cAAc;IACd,eAAe;;AAEnB;AACA;IACI,2BAA2B;IAC3B,WAAW;IACX,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,eAAe;IACf,iBAAiB;AACrB;AACA;IACI,UAAU;IACV,UAAU;IACV,qFAAqF;IACrF,cAAc;IACd,eAAe;AACnB;;;AAGA;IACI,eAAe;IACf,iBAAiB;IACjB,iBAAiB;IACjB,iBAAiB;IACjB,aAAa;AACjB;;AAEA;;IAEI,eAAe;IACf,iBAAiB;IACjB,iBAAiB;IACjB,aAAa;AACjB","sourcesContent":[".PaymentSteps-fullPage {\n    /* border: 1px solid red; */\n    margin: 0.5rem;\n    padding: 0.5rem;\n}\n\n.PaymentSteps-Heading {\n\n    font-size: 2rem;\n    padding: 0.5rem;\n    margin: 0.5rem;\n    /* border: 1px solid; */\n\n}\n\n.PaymentSteps-MainContainer {\n    /* border: 1px solid navy; */\n    display: flex;\n    flex-direction: column;\n    flex-wrap: wrap;\n    margin: 0.5rem;\n    padding: 0.5rem;\n\n}\n.PaymentSteps-image-div{\n    /* border: 1px solid red; */\n    width: 100%;\n    height:25rem;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    font-size: 2rem;\n    font-weight: bold;\n}\n.PaymentSteps-image{\n    width: 90%;\n    height:85%;\n    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;\n    margin: 0.5rem;\n    padding: 0.5rem;\n}\n\n\n.heading-div {\n    font-size: 16px;\n    font-weight: bold;\n    line-height: 30px;\n    margin-left: 5rem;\n    padding: 1rem;\n}\n\n.para-div {\n\n    font-size: 14px;\n    line-height: 30px;\n    margin-left: 5rem;\n    padding: 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PaymentSteps-fullPage": `PaymentSteps_PaymentSteps-fullPage__cV6bK`,
	"PaymentSteps-Heading": `PaymentSteps_PaymentSteps-Heading__pIVlP`,
	"PaymentSteps-MainContainer": `PaymentSteps_PaymentSteps-MainContainer__udG1y`,
	"PaymentSteps-image-div": `PaymentSteps_PaymentSteps-image-div__3WLvP`,
	"PaymentSteps-image": `PaymentSteps_PaymentSteps-image__RzHBM`,
	"heading-div": `PaymentSteps_heading-div__-d8BD`,
	"para-div": `PaymentSteps_para-div__d8ycg`
};
export default ___CSS_LOADER_EXPORT___;
