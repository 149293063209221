

import React from "react";
import ReactApexChart from "react-apexcharts";

const HalfChart = ({ completedChaptersCount, totalpurchasedChaptersCount }) => {
 
  // console.log(totalpurchasedChaptersCount.length)
  const total = totalpurchasedChaptersCount.length-completedChaptersCount;
  const chartData = {
    series: [completedChaptersCount, total, 0], // Replace the first value with completedChaptersCount
    options: {
      chart: {
        type: "donut"
      },
      dataLabels: {
        enabled: false
      },
      fill: {
        colors: ["#94b4e3", "#FFA500", "#fff4a3"]
      },
      plotOptions: {
        pie: {
          donut: {
            size: 91,
            labels: {
              show: false,
              name: {
                show: false
              },
              total: {
                formatter: (w) => {
                  const { seriesTotals = {} } = w.globals;
                  const sum = seriesTotals.reduce((a, b) => a + b, 0);
                  const avg = sum / seriesTotals.length || 0;
                  return `${Math.round(avg)}%`;
                }
              }
            }
          },
          startAngle: -90,
          endAngle: 90,
          offsetY: 10,
          interactivity: {
            mode: "none"
          }
        }
      },
      grid: {
        padding: {
          bottom: -80
        }
      },
      legend: {
        show: false
      },
      labels: ["Completed chapters", "Total Chapters", "Exercise Points"],
      colors: ["black", "black", "black"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: "bottom"
            }
          }
        }
      ]
    }
  };

  return (
    <div className="App-no">
      <ReactApexChart
        options={chartData.options}
        series={chartData.series}
        type="donut"
      />
    </div>
  );
};

export default HalfChart;




