// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Digital-Body{
    margin-bottom: 1%;
    width: 98%;
    display: flex
    ;
  
}
.No-padding{
    padding: 0 !important;
   margin: 0 !important;
}
.Digital-body2{
    height: auto;
}



@media (max-width: 546px) {
    .Digital-body2 {
        height: 45rem!important;
    }
}
@media (min-width: 547px) and (max-width: 642px) {
    .Digital-body2 {
        height: 50rem!important;
    }
}

@media (min-width: 643px) and (max-width: 768px){
    .Digital-body2 {
        height: 50rem !important;
    }
}

@media (min-width: 769px) and (max-width: 980px){
    .Digital-body2 {
        height: 44rem;
    }

}

@media (min-width: 981px) and (max-width: 1124px){
    .Digital-body2 {
        height: 39rem;
    }
}

@media (min-width: 1501px) and (max-width: 1599px) {
    .Digital-Body{
        
        margin-top: 1.5%;
    }
    .Digital-body2 {
        height: 77rem;

      
    }
      
    }


`, "",{"version":3,"sources":["webpack://./src/Ebook/Pages/DigitalContentBody/Body.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,UAAU;IACV;IACA;;AAEJ;AACA;IACI,qBAAqB;GACtB,oBAAoB;AACvB;AACA;IACI,YAAY;AAChB;;;;AAIA;IACI;QACI,uBAAuB;IAC3B;AACJ;AACA;IACI;QACI,uBAAuB;IAC3B;AACJ;;AAEA;IACI;QACI,wBAAwB;IAC5B;AACJ;;AAEA;IACI;QACI,aAAa;IACjB;;AAEJ;;AAEA;IACI;QACI,aAAa;IACjB;AACJ;;AAEA;IACI;;QAEI,gBAAgB;IACpB;IACA;QACI,aAAa;;;IAGjB;;IAEA","sourcesContent":[".Digital-Body{\n    margin-bottom: 1%;\n    width: 98%;\n    display: flex\n    ;\n  \n}\n.No-padding{\n    padding: 0 !important;\n   margin: 0 !important;\n}\n.Digital-body2{\n    height: auto;\n}\n\n\n\n@media (max-width: 546px) {\n    .Digital-body2 {\n        height: 45rem!important;\n    }\n}\n@media (min-width: 547px) and (max-width: 642px) {\n    .Digital-body2 {\n        height: 50rem!important;\n    }\n}\n\n@media (min-width: 643px) and (max-width: 768px){\n    .Digital-body2 {\n        height: 50rem !important;\n    }\n}\n\n@media (min-width: 769px) and (max-width: 980px){\n    .Digital-body2 {\n        height: 44rem;\n    }\n\n}\n\n@media (min-width: 981px) and (max-width: 1124px){\n    .Digital-body2 {\n        height: 39rem;\n    }\n}\n\n@media (min-width: 1501px) and (max-width: 1599px) {\n    .Digital-Body{\n        \n        margin-top: 1.5%;\n    }\n    .Digital-body2 {\n        height: 77rem;\n\n      \n    }\n      \n    }\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
