import React from "react";
import "./OverallResult.css";
import Timer from "./Timer";
import pic2 from './test.jpg';
import { useState, useEffect } from "react";
import QuizResult from "./QuizResult";

const OverallResult = ({
  quizDatas,
  correctAnswer,
  incorrectAnswer,
  totalScores,
  totalTime,
  resetQuiz
}) => {
  const [showNewComponent, setShowNewComponent] = useState(false);
     useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
   const handleClick = () => {
    setShowNewComponent(true);
  };
  
  return (
      <>
     <div className = "body-for-result">
      <div className ="pic-for-quiz">
    <img className="image-offer-quiz" src={pic2} /></div>
    <div className="quiz-result-container">
      <h1>Result:</h1>
    
     <p className="score">
        {totalScores} of {quizDatas.length}
      </p>
      <p className="correct">Number of correctly answered questions: {totalScores}</p>
      <Timer timetaken = {totalTime} />
       <div className = "check-your-answer">
       <button className ="check-answer"  onClick ={handleClick} >Check answer
           </button>
            {showNewComponent && <QuizResult quizData={quizDatas}
          correctAnswers={correctAnswer}
          incorrectAnswers={incorrectAnswer}
          totalScore={totalScores}
          resetQuiz={resetQuiz}
           />}
           </div>
      <div className="center">
    <button className="restart-button" onClick={resetQuiz}>
          Restart Quiz
        </button>
      </div>
      </div>
      </div>
    </>
  )
}

export default OverallResult;