import React from "react";
import styles from "./YoutubeModal.module.css";
import { RiCloseFill } from "react-icons/ri";

const YouTubeModal = ({ videoYoutubeLink, onClose }) => {
  // Convert the regular YouTube URL to an embed URL
  const embedUrl = videoYoutubeLink.replace('youtu.be/', 'www.youtube.com/embed/').replace('watch?v=', 'embed/');

  return (
    <div className={styles.modalOverlay} onClick={onClose}>
      <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
        <button className={styles.closeButton} onClick={onClose}><RiCloseFill size={25}/></button>
        <iframe
          // width="560"
          // height="600"
          src={embedUrl}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="YouTube video"
        ></iframe>
      </div>
    </div>
  );
};

export default YouTubeModal;
