import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styles from './ContactUs.module.css';
import Header from '../component/HeaderFiles/Header';
import Footer from "./Footer"

const ContactUs = () => {
    const [contactContent, setContactContent] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchContactContent = async () => {
            try {
                const response = await axios.get('https://auth.ssccglpinnacle.com/api/footer');
                
                if (response.data && response.data.length > 0) {
                    console.log("Fetched Contact Us Data:", response.data[0].contact_us);
                    setContactContent(response.data[0].contact_us || '');
                } else {
                    setError('Failed to load Contact Us content');
                }
            } catch (error) {
                console.error('Error fetching Contact Us content:', error);
                setError('An error occurred while fetching Contact Us content');
            } finally {
                setLoading(false);
            }
        };

        fetchContactContent();
    }, []);

    return (
        <>
            <Header />
            <div className={styles.contactContainer}>
                {loading && <p>Loading Contact Us content...</p>}
                {error && <p className={styles.errorMessage}>{error}</p>}
                {!loading && !error && (
                    <div dangerouslySetInnerHTML={{ __html: contactContent }} />
                )}
            </div>
            <Footer/>
        </>
    );
};

export default ContactUs;
