// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Wishlist_wishlist_card__h6D5p {
  border: 0.5px #d1d7dc solid;
  padding: 1%;
  width: 25%;
  position: absolute;
  right: 1%;
  z-index: 1;
  top: 5%;
  color: black;
}
.Wishlist_dropdown__\\+m7pq {
  color: white;
  padding: 9px;
  font-size: 16px;
  border: none;
  height: 100%;
}
.Wishlist_dropdown__\\+m7pq:hover .Wishlist_dropdown_content__xu\\+1O {
  display: block;
}
.Wishlist_wishlist_btn__g4jCD {
  font-size: 16px;
  font-weight: 600;
  color: black;
}
.Wishlist_wishlist_explore__h7XZ8 {
  margin-top: 2%;
  font-size: 14px;
  color: #0a90e9;
  text-align: center;
}
.Wishlist_wishlist__s32s2 {
  line-height: 3rem;
  text-align: center;
  font-size: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/VIDEOPLAYER COMPONENTS/components/VideoHeader/Wishlist.module.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,WAAW;EACX,UAAU;EACV,kBAAkB;EAClB,SAAS;EACT,UAAU;EACV,OAAO;EACP,YAAY;AACd;AACA;EACE,YAAY;EACZ,YAAY;EACZ,eAAe;EACf,YAAY;EACZ,YAAY;AACd;AACA;EACE,cAAc;AAChB;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,YAAY;AACd;AACA;EACE,cAAc;EACd,eAAe;EACf,cAAc;EACd,kBAAkB;AACpB;AACA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,eAAe;AACjB","sourcesContent":[".wishlist_card {\n  border: 0.5px #d1d7dc solid;\n  padding: 1%;\n  width: 25%;\n  position: absolute;\n  right: 1%;\n  z-index: 1;\n  top: 5%;\n  color: black;\n}\n.dropdown {\n  color: white;\n  padding: 9px;\n  font-size: 16px;\n  border: none;\n  height: 100%;\n}\n.dropdown:hover .dropdown_content {\n  display: block;\n}\n.wishlist_btn {\n  font-size: 16px;\n  font-weight: 600;\n  color: black;\n}\n.wishlist_explore {\n  margin-top: 2%;\n  font-size: 14px;\n  color: #0a90e9;\n  text-align: center;\n}\n.wishlist {\n  line-height: 3rem;\n  text-align: center;\n  font-size: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wishlist_card": `Wishlist_wishlist_card__h6D5p`,
	"dropdown": `Wishlist_dropdown__+m7pq`,
	"dropdown_content": `Wishlist_dropdown_content__xu+1O`,
	"wishlist_btn": `Wishlist_wishlist_btn__g4jCD`,
	"wishlist_explore": `Wishlist_wishlist_explore__h7XZ8`,
	"wishlist": `Wishlist_wishlist__s32s2`
};
export default ___CSS_LOADER_EXPORT___;
