import React, { useState, useEffect } from 'react';
import '../BuyNow/BuyNow.css';
import { GrClose } from 'react-icons/gr';
import pic from "../i/book.jpg";
import { useNavigate, useLocation } from 'react-router-dom'; 
import { useCart } from "../../Context/CartContext";
import { useAuth } from "../../Context/AuthContext";
import Swal from "sweetalert2";

function BuyNowExpiration({ onClose, onAddToCart }) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const cardId = queryParams.get("cardId");
  const [isAddingToCart, setIsAddingToCart] = useState(false);
  const { isLoggedIn, user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [selectedDuration, setSelectedDuration] = useState('option1');
  const [selectedProduct, setSelectedProduct] = useState('SSC CGL Tier 2 English | 40 Tests');
  const { addToCart } = useCart();
  const navigate = useNavigate();

  useEffect(() => {
    // console.log("Card ID:", cardId);
    // Do something with the cardId, e.g., fetch data based on the cardId
  }, [cardId]);

  const handleDurationChange = (event) => {
    setSelectedDuration(event.target.value);
  };

  let currentOriginalPrice = 50; // Default original price
  let currentDiscountedPrice = 30; // Default discounted price

  if (selectedDuration === 'option2') {
    currentOriginalPrice = 100;
    currentDiscountedPrice = 70;
  } else if (selectedDuration === 'option3') {
    currentOriginalPrice = 150;
    currentDiscountedPrice = 100;
  }

  const handleAddToCart = async (ebookId) => {
    try {
      if (!isLoggedIn || !user || !user._id) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Please log in to add items to your cart.",
        });
        return;
      }
      setIsAddingToCart(true);

      const response = await fetch(`https://auth.ssccglpinnacle.com/api/add-to-cart-ebook/${user.email_id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${user.token}`
        },
        body: JSON.stringify({ ebookId }),
      });

      if (response.ok) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Item added to cart successfully!",
        });
        navigate("/cart");
      } else {
        const errorMessage = await response.json();
        console.error("Error adding to cart:", errorMessage);

        if (errorMessage.error === "Item already in the cart") {
          navigate("/cart");
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `Failed to add item to cart. Error: ${errorMessage.error}`,
          });
        }
      }
    } catch (error) {
      console.error("Error adding to cart:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to add item to cart. Please try again.",
      });
    } finally {
      setIsAddingToCart(false);
    }
  };

  const handleBuyNow = async (ebookId) => {
    try {
      if (!user || !user._id) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Please log in to make a purchase.",
        });
        return;
      }
      const response = await fetch("https://auth.ssccglpinnacle.com/api/purchase-ebook", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${user.token}`
        },
        body: JSON.stringify({ ebookId, userId: user.id, email_id: user.email_id }),
      });

      if (response.ok) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Ebook purchased successfully!",
        });
      } else {
        const errorMessage = await response.json();
        console.error("Error processing purchase:", errorMessage);

        Swal.fire({
          icon: "error",
          title: "Error",
          text: `Failed to complete the purchase. Error: ${errorMessage.error}`,
        });
      }
    } catch (error) {
      console.error("Error processing purchase:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to complete the purchase. Please try again.",
      });
    }
  };

  const handleCloseButtonClick = async () => {
    try {
      if (!user || !user._id) {
        console.error("User not authenticated");
        return;
      }

      const response = await fetch("https://auth.ssccglpinnacle.com/api/updateTryForFree-ebook", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${user.token}`
        },
        body: JSON.stringify({
          userId: user._id,
          cardId: cardId,
          tryForFree: true,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseData = await response.json();
      // console.log(responseData);
    } catch (error) {
      console.error('Error updating try-for-free status:', error);
    }

    onClose();
  };

  if (!isLoggedIn || !user) {
    return <p>User not logged in</p>;
  }




  return (
    <div className="modal-overlay-buy">
    
      <div className="modal-buy">
      
      
        <div className="modal-header-buy">
        <div className = "free-trail-over">
        "Your free trial has ended. Purchase a subscription to continue access."
        </div>
        <button className="close-button-buy" onClick={handleCloseButtonClick}>
            <GrClose />
          </button>
        </div>
       
        <div className="modal-content-buy-container">
          <div className="buy-modal-1">
            <img className="card-title-buy" src={pic} />
          </div>
          <div className="buy-modal-2">
            <div className="product-title">
              <p>SSC CGL Tier 2 English | 40 Tests</p>
            </div>
            <div className="by-pinnacle">
              <p>by pinnacle</p>
            </div>
            <div className="for-buy-price">
              <span className="sale" data-badge-sales="">
                <span data-badge-sales-range="">Sale</span>
              </span>
              <div className="price-details">
                <p className="original-price">₹{currentOriginalPrice}</p>
                <p className="discounted-price"> ₹{currentDiscountedPrice}</p>
              </div>
            </div>
            <div className ="for-duration-buy-modal">
              <select id="selectField" value={selectedDuration} onChange={handleDurationChange}>
                <option value="option1">1 Month</option>
                <option value="option2">3 Months</option>
                <option value="option3">6 Months</option>
              </select>
              <div className ="add-to-cart-buy-modal"  onClick={() => handleAddToCart(cardId)}>
                Add to cart
              </div>
            </div>
            <div className ="buy-it-now-from-buy-model">
              <button id="buyButton"  onClick={() => handleBuyNow(cardId)}>Buy It Now</button>
            </div>
            <div className ="courses-available-from-buy-model">
              <p>Course also available in: </p>
            </div>
            <div className = "course-available-from-buy-model">
              <p className ="buy-model-SSC"> CHSL </p>
              <p className ="buy-model-SSC"> CPO </p>
              <p className ="buy-model-SSC"> MTS</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BuyNowExpiration;
