// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AllApps_Cart_card__b5-TG {
  border: 0.5px #d1d7dc solid;
  width: 24%;
  position: absolute;
  right: 1%;
  z-index: 1;
  top: 5%;
  color: #444746;
  box-sizing: border-box;
  padding: 29px;
  border-radius: 24px;
  margin-bottom: 11px;
  display: flex;
  height: 45%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.AllApps_dropdown__pR-Jm {
  color: white;
  padding: 9px;
  font-size: 14px;
  border: none;
  height: 100%;
}
.AllApps_dropdown__pR-Jm:hover .AllApps_dropdown_content__sfZJg {
  display: block;
}
.AllApps_cart_btn__kV8QE {
  font-size: 16px;
  font-weight: 600;
  color: black;
}
.AllApps_cart__RR9g3 {
  line-height: 3rem;
  text-align: center;
  font-size: 16px;
  color: black;
}
.AllApps_cart_shopping__P6dhE {
  margin-top: 2%;
  font-size: 14px;
  color: #0a90e9;
  text-align: center;
}
.AllApps_cart_app_para__JDwX2 {
  font-size: 14px;
}
.AllApps_Cart_card__b5-TG {
  flex-wrap: wrap;
}
.AllApps_cart_app_container__fnRim {
  width: 33%;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
}
.AllApps_cart_app_image__KWsRg {
  margin-right: 5px;
}

/* Add media query to handle responsiveness */
@media (max-width: 767px) {
  .AllApps_Cart_card__b5-TG {
    width: 90%;
    height: auto;
  }

  .AllApps_cart_app_container__fnRim {
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/VIDEOPLAYER COMPONENTS/components/VideoHeader/AllApps.module.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,UAAU;EACV,kBAAkB;EAClB,SAAS;EACT,UAAU;EACV,OAAO;EACP,cAAc;EACd,sBAAsB;EACtB,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,aAAa;EACb,WAAW;EACX,iDAAiD;AACnD;AACA;EACE,YAAY;EACZ,YAAY;EACZ,eAAe;EACf,YAAY;EACZ,YAAY;AACd;AACA;EACE,cAAc;AAChB;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,YAAY;AACd;AACA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,eAAe;EACf,YAAY;AACd;AACA;EACE,cAAc;EACd,eAAe;EACf,cAAc;EACd,kBAAkB;AACpB;AACA;EACE,eAAe;AACjB;AACA;EACE,eAAe;AACjB;AACA;EACE,UAAU;EACV,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;AACpB;AACA;EACE,iBAAiB;AACnB;;AAEA,6CAA6C;AAC7C;EACE;IACE,UAAU;IACV,YAAY;EACd;;EAEA;IACE,WAAW;EACb;AACF","sourcesContent":[".Cart_card {\n  border: 0.5px #d1d7dc solid;\n  width: 24%;\n  position: absolute;\n  right: 1%;\n  z-index: 1;\n  top: 5%;\n  color: #444746;\n  box-sizing: border-box;\n  padding: 29px;\n  border-radius: 24px;\n  margin-bottom: 11px;\n  display: flex;\n  height: 45%;\n  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;\n}\n.dropdown {\n  color: white;\n  padding: 9px;\n  font-size: 14px;\n  border: none;\n  height: 100%;\n}\n.dropdown:hover .dropdown_content {\n  display: block;\n}\n.cart_btn {\n  font-size: 16px;\n  font-weight: 600;\n  color: black;\n}\n.cart {\n  line-height: 3rem;\n  text-align: center;\n  font-size: 16px;\n  color: black;\n}\n.cart_shopping {\n  margin-top: 2%;\n  font-size: 14px;\n  color: #0a90e9;\n  text-align: center;\n}\n.cart_app_para {\n  font-size: 14px;\n}\n.Cart_card {\n  flex-wrap: wrap;\n}\n.cart_app_container {\n  width: 33%;\n  align-items: center;\n  justify-content: center;\n  margin-bottom: 5px;\n}\n.cart_app_image {\n  margin-right: 5px;\n}\n\n/* Add media query to handle responsiveness */\n@media (max-width: 767px) {\n  .Cart_card {\n    width: 90%;\n    height: auto;\n  }\n\n  .cart_app_container {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Cart_card": `AllApps_Cart_card__b5-TG`,
	"dropdown": `AllApps_dropdown__pR-Jm`,
	"dropdown_content": `AllApps_dropdown_content__sfZJg`,
	"cart_btn": `AllApps_cart_btn__kV8QE`,
	"cart": `AllApps_cart__RR9g3`,
	"cart_shopping": `AllApps_cart_shopping__P6dhE`,
	"cart_app_para": `AllApps_cart_app_para__JDwX2`,
	"cart_app_container": `AllApps_cart_app_container__fnRim`,
	"cart_app_image": `AllApps_cart_app_image__KWsRg`
};
export default ___CSS_LOADER_EXPORT___;
