import React, { useState, useEffect, useMemo } from 'react';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { Button } from '@mui/material';
import Modal from 'react-modal';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../Context/AuthContext';
import './ChapterEdit.css';

const ChapterEdit = () => {
  const { token } = useAuth();
  const [ebookData, setEbookData] = useState([]);
  const [selectedSku, setSelectedSku] = useState('');
  const [selectedEbook, setSelectedEbook] = useState(null);
  const [chapterData, setChapterData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState(false);
  const [deleteConfirmationData, setDeleteConfirmationData] = useState({ chapterId: '', isChapter: false });
  const [editData, setEditData] = useState({ chapterTitle: '', chapterId: '', sequence: '' });
  const [dataUpdated, setDataUpdated] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchEbookData = async () => {
      try {
        const response = await fetch('https://auth.ssccglpinnacle.com/api/ebooks');
        if (response.ok) {
          const ebooks = await response.json();
          setEbookData(ebooks);
        } else {
          console.error('Failed to fetch eBook data');
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchEbookData();
  }, []);

  useEffect(() => {
    const fetchChapters = async () => {
      try {
        if (selectedEbook?._id) {
          const response = await fetch(`https://auth.ssccglpinnacle.com/api/chapters-ebook-admin/${selectedEbook._id}`);
          if (response.ok) {
            const chaptersData = await response.json();
            const sortedChapters = chaptersData.sort((a, b) => a.sequence - b.sequence);
            setChapterData(sortedChapters)
          } else {
            console.error('Failed to fetch chapters');
          }
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchChapters();
  }, [selectedEbook, dataUpdated]);

  const columns = useMemo(
    () => [
      { accessorKey: 'title', header: 'Title' },
      { accessorKey: 'sequence', header: 'Chapter Sequence' },
      {
        id: 'edit',
        header: 'Edit',
        Cell: ({ row }) => (
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleEditClick(row.original)}
          >
            Edit
          </Button>
        ),
      },
      {
        id: 'delete',
        header: 'Delete',
        Cell: ({ row }) => (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleDeleteClick(row.original._id)}
          >
            Delete
          </Button>
        ),
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data: chapterData,
  });

  const handleEditClick = (chapter) => {
    openModal({
      chapterTitle: chapter.title,
      chapterId: chapter._id,
      sequence: chapter.sequence,
    });
  };

  const handleDeleteClick = (chapterId) => {
    openDeleteConfirmationModal(chapterId);
  };

  const openModal = (data) => {
    setIsModalOpen(true);
    setEditData(data);
    document.body.style.overflow = 'hidden';
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setEditData({ chapterTitle: '', chapterId: '', sequence: '' });
    document.body.style.overflow = '';
    setDataUpdated((prev) => !prev); // Toggle state to trigger re-fetch
  };

  const openDeleteConfirmationModal = (chapterId) => {
    setDeleteConfirmationData({ chapterId, isChapter: true });
    setIsDeleteConfirmationModalOpen(true);
  };

  const closeDeleteConfirmationModal = () => {
    setDeleteConfirmationData({ chapterId: '', isChapter: false });
    setIsDeleteConfirmationModalOpen(false);
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    closeModal();

    try {
      const response = await fetch('https://auth.ssccglpinnacle.com/api/updateChapter-ebook', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(editData),
      });

      if (!response.ok) throw new Error('Failed to save edited data');

      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Chapter data updated successfully!',
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred while updating chapter data. Please try again later.',
      });
    }
  };

  const handleDeleteConfirmation = async () => {
    try {
      const response = await fetch(`https://auth.ssccglpinnacle.com/api/chapters-ebook/${deleteConfirmationData.chapterId}`, {
        method: 'DELETE',
      });

      if (response.ok) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Chapter deleted successfully!',
        });
        setDataUpdated((prev) => !prev); // Toggle state to trigger re-fetch
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'An error occurred while deleting chapter. Please try again later.',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred while deleting chapter. Please try again later.',
      });
    }
    closeDeleteConfirmationModal();
  };

  return (
    <>
      <div className="contain-for-chapter-edit">
        <h1 className="add-ebook-form-chapter">Edit Chapter Data</h1>
        <form className="chapter-edit-container">
          <label htmlFor="ebookId" className="label-chapter-edit">SKU:</label>
          {/* <Select
            id="sku"
            value={{ label: selectedSku, value: selectedSku }}
            onChange={(option) => {
              setSelectedSku(option.value);
              const selectedEbook = ebookData.find((ebook) => ebook.sku === option.value);
              setSelectedEbook(selectedEbook);
            }}
            options={ebookData.map((ebook) => ({
              label: ebook.sku,
              value: ebook.sku,
            }))}
            placeholder="Select SKU"
            className="input-chapter-edit"
          /> */}

<Select
  id="sku"
  value={{ label: selectedSku, value: selectedSku }}
  onChange={(option) => {
    setSelectedSku(option.value);
    const selectedEbook = ebookData.find((ebook) => ebook.sku === option.value);
    setSelectedEbook(selectedEbook);
  }}
  options={ebookData.slice().reverse().map((ebook) => ({
    label: ebook.sku,
    value: ebook.sku,
  }))}  // Reversing the ebookData array
  placeholder="Select SKU"
  className="input-chapter-edit"
/>


{selectedEbook && (
          <div className="ebook-card">
            <div className="ebook-card-item">
              <strong>Title:</strong> {selectedEbook.title}
            </div>
            <div className="ebook-card-item">
              <strong>Edition:</strong> {selectedEbook.edition}
            </div>
            <div className="ebook-card-item">
              <strong>Medium:</strong> {selectedEbook.medium}
            </div>
          </div>
          )}
        </form>

        <MaterialReactTable
  columns={columns}
  data={chapterData}
  enablePagination={true} // Ensure pagination is enabled
  initialState={{
    pagination: { pageSize: 200, pageIndex: 0 }, // Set the default page size to 100 and start at page 0
  }}
  muiTablePaginationProps={{
    rowsPerPageOptions: [10, 50, 100, 200], // Allow users to switch between 10, 50, 100, and 200 rows per page
    labelRowsPerPage: 'Rows per page',
  }}
  manualPagination={false} // If you are not handling pagination on the server
/>




      </div>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Edit Modal"
        className="modal-container-for-editing-chapter"
         overlayClassName="modal-overlay"
      >
        <div className="MODAL-open-editing">
          <div className="modal-header-for-editing-chapter">
            <h1 className="edit-chapter-title">Edit Chapter data</h1>
            <button className="modal-close-for-editing-chapter" onClick={closeModal}>&times;</button>
          </div>
          <form className="modal-form-for-editing-chapter" onSubmit={handleEditSubmit}>
            <label htmlFor="chapterTitle">Chapter Title:</label>
            <input
              type="text"
              id="chapterTitle"
              value={editData.chapterTitle}
              onChange={(e) => setEditData((prevData) => ({ ...prevData, chapterTitle: e.target.value }))}
            />
            <br/>
            <label htmlFor="sequence">Chapter sequence:</label>
            <input
              type="text"
              id="sequence"
              value={editData.sequence}
              onChange={(e) => setEditData((prevData) => ({ ...prevData, sequence: e.target.value }))}
            />
            <div className="styling-the-button">
              <Button type="submit" variant="contained" color="primary">
                Save Changes
              </Button>
            </div>
          </form>
        </div>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal
        isOpen={isDeleteConfirmationModalOpen}
        onRequestClose={closeDeleteConfirmationModal}
        contentLabel="Delete Confirmation Modal"
        className="delete-confirmation-modal-container"
        overlayClassName="delete-confirmation-modal-overlay"
      >
        <div className="delete-confirmation-modal-chapter">
          <h2>Confirm Deletion</h2>
          <p>Are you sure you want to delete this chapter?</p>
          <div className="delete-confirmation-buttons">
            <Button variant="contained" color="primary" onClick={handleDeleteConfirmation}>
              Yes
            </Button>
            <Button variant="contained" color="secondary" onClick={closeDeleteConfirmationModal}>
              No
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ChapterEdit;
