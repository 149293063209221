import Row1Digital from './Row1Digital'

import Row2Digital from "./Row2Digital"
import Row4Digital from "./Row4Digital"
import Row5Digital from "./Row5Digital"

import Footer from "../../../FooterMain/Footer"
import "./Body.css";

import React, { useContext, useEffect, useState } from "react";
import BuyNow from '../../BuyNow/BuyNow';
import { useLocation } from "react-router-dom";
import { useNavigate } from 'react-router-dom'; 
import Header from '../../../component/HeaderFilesEbook/Header'


function DigitalBody() {
  const [filteredEbookData, setFilteredEbookData] = useState([]);
  const [showBuyNowModal, setShowBuyNowModal] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  // Define a function to receive filtered ebook data from Row1Digital
  const receiveFilteredEbookData = (data) => {
    setFilteredEbookData(data);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // Check if the page has been reloaded before
    const hasReloaded = localStorage.getItem("hasReloaded");
    if (!hasReloaded) {
      // Perform actions that should only happen once
      // For example, you can trigger a reload
      localStorage.setItem("hasReloaded", "true");
      window.location.reload();
    }
  }, []);

  useEffect(() => {
    // Check if cardId is present in the query parameter
    const queryParams = new URLSearchParams(location.search);
    const queryCardId = queryParams.get("cardId");
  
    if (queryCardId) {
      setShowBuyNowModal(true);
      document.body.style.overflow = 'hidden';
    }
  }, [location]);
  
  const closeModal = () => {
    setShowBuyNowModal(false);
    document.body.style.overflow = '';
    navigate("/digital-catalog")
  };
  return (
    <>
       <div className="main-body-container">
      <Header />
      <div className="Digital-Body ">
        <Row1Digital onDataFiltered={receiveFilteredEbookData}/>
        <Row2Digital filteredEbookData={filteredEbookData}/>
      </div>
      <div className="Digital-body2">
        <Row4Digital filteredEbookData={filteredEbookData}/>
        <Row5Digital filteredEbookData={filteredEbookData}/>
      </div>
      <Footer />
      {showBuyNowModal && <BuyNow onClose={closeModal}  />}
      </div>
    </>
  );
}

export default DigitalBody;



