// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Assignments_Assignmenets-Heading__cVRbl{
    font-size: 21px;
    font-weight: bold;
    line-height: 40px;

}

.Assignments_checkbox-divSection__a5ZJJ{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 2.5rem;
}

.Assignments_Select-Section__HxCOV{
    width: max-content;
    height: 100%;
    margin: 0.25rem;
    padding: 0.25rem;
    font-size: 14px;
}
.Assignments_checkbox-empty-div__yjk3y{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60%;
    margin: auto;
    margin-top: 1rem;


}

.Assignments_emptyCart-imageDiv__RwrrW{
    display: flex;
    flex-direction: column;
    margin: auto;
    justify-content: center;
    align-items: center;
}

.Assignments_emptyCart-image__pCCYX{
    width: 400px;
    height: 200px;
}`, "",{"version":3,"sources":["webpack://./src/Profile/Assignments.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,iBAAiB;IACjB,iBAAiB;;AAErB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,eAAe;IACf,gBAAgB;IAChB,eAAe;AACnB;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,UAAU;IACV,YAAY;IACZ,gBAAgB;;;AAGpB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,aAAa;AACjB","sourcesContent":[".Assignmenets-Heading{\n    font-size: 21px;\n    font-weight: bold;\n    line-height: 40px;\n\n}\n\n.checkbox-divSection{\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    height: 2.5rem;\n}\n\n.Select-Section{\n    width: max-content;\n    height: 100%;\n    margin: 0.25rem;\n    padding: 0.25rem;\n    font-size: 14px;\n}\n.checkbox-empty-div{\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    width: 60%;\n    margin: auto;\n    margin-top: 1rem;\n\n\n}\n\n.emptyCart-imageDiv{\n    display: flex;\n    flex-direction: column;\n    margin: auto;\n    justify-content: center;\n    align-items: center;\n}\n\n.emptyCart-image{\n    width: 400px;\n    height: 200px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Assignmenets-Heading": `Assignments_Assignmenets-Heading__cVRbl`,
	"checkbox-divSection": `Assignments_checkbox-divSection__a5ZJJ`,
	"Select-Section": `Assignments_Select-Section__HxCOV`,
	"checkbox-empty-div": `Assignments_checkbox-empty-div__yjk3y`,
	"emptyCart-imageDiv": `Assignments_emptyCart-imageDiv__RwrrW`,
	"emptyCart-image": `Assignments_emptyCart-image__pCCYX`
};
export default ___CSS_LOADER_EXPORT___;
