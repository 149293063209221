// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .skeletonContainer {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 16px;
  width: 300px;
  margin: 0 auto; 
  margin-bottom: 20px; 
  margin-right: 50%;
} */
.LoadingSkeleton_cardContainer__ZlZrr {
  display: flex; 
  flex-wrap: wrap; 
  gap: 20px; 
  justify-content: flex-start; 
  margin-bottom: 20px; 
}

.LoadingSkeleton_skeletonContainer__8uqjY {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 16px;
  width: calc(85% - 20px); 
  margin-bottom: 20px; 
}

/* ... rest of your CSS ... */


.LoadingSkeleton_imageContainer__9WaSQ {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  gap: 20px;
}

.LoadingSkeleton_skeletonImage__r5oGg {
  background-color: #ccc;
  width: 140px; 
  height: 80px; 
  border-radius: 4px;
}

.LoadingSkeleton_textSkeleton__YPaY5, .LoadingSkeleton_ratingTextSkeleton__DQ0oQ {
  background-color: #ccc;
  height: 20px;
  margin-bottom: 8px;
}

.LoadingSkeleton_textSkeleton__YPaY5 {
  width: 70%; 
  border-radius: 4px;
}

.LoadingSkeleton_ratingTextSkeleton__DQ0oQ {
  width: 30%; 
  margin-top: 8px;
}

.LoadingSkeleton_progressSkeleton__yuKgN {
  background-color: #ccc;
  height: 8px;
  width: 100%;
  margin-bottom: 8px;
  border-radius: 4px;
}

.LoadingSkeleton_starContainer__hp--O {
  display: flex;
  justify-content: flex-end;
  gap: 4px; 
}

.LoadingSkeleton_skeletonStar__LIaa\\+ {
  background-color: #ccc;
  width: 16px; 
  height: 16px; 
  border-radius: 2px;
}
`, "",{"version":3,"sources":["webpack://./src/UI/LoadingSkeleton.module.css"],"names":[],"mappings":"AAAA;;;;;;;;GAQG;AACH;EACE,aAAa;EACb,eAAe;EACf,SAAS;EACT,2BAA2B;EAC3B,mBAAmB;AACrB;;AAEA;EACE,sBAAsB;EACtB,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA,6BAA6B;;;AAG7B;EACE,aAAa;EACb,8BAA8B;EAC9B,kBAAkB;EAClB,SAAS;AACX;;AAEA;EACE,sBAAsB;EACtB,YAAY;EACZ,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,sBAAsB;EACtB,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,UAAU;EACV,kBAAkB;AACpB;;AAEA;EACE,UAAU;EACV,eAAe;AACjB;;AAEA;EACE,sBAAsB;EACtB,WAAW;EACX,WAAW;EACX,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,QAAQ;AACV;;AAEA;EACE,sBAAsB;EACtB,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB","sourcesContent":["/* .skeletonContainer {\n  border: 1px solid #ddd;\n  border-radius: 4px;\n  padding: 16px;\n  width: 300px;\n  margin: 0 auto; \n  margin-bottom: 20px; \n  margin-right: 50%;\n} */\n.cardContainer {\n  display: flex; \n  flex-wrap: wrap; \n  gap: 20px; \n  justify-content: flex-start; \n  margin-bottom: 20px; \n}\n\n.skeletonContainer {\n  border: 1px solid #ddd;\n  border-radius: 4px;\n  padding: 16px;\n  width: calc(85% - 20px); \n  margin-bottom: 20px; \n}\n\n/* ... rest of your CSS ... */\n\n\n.imageContainer {\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 8px;\n  gap: 20px;\n}\n\n.skeletonImage {\n  background-color: #ccc;\n  width: 140px; \n  height: 80px; \n  border-radius: 4px;\n}\n\n.textSkeleton, .ratingTextSkeleton {\n  background-color: #ccc;\n  height: 20px;\n  margin-bottom: 8px;\n}\n\n.textSkeleton {\n  width: 70%; \n  border-radius: 4px;\n}\n\n.ratingTextSkeleton {\n  width: 30%; \n  margin-top: 8px;\n}\n\n.progressSkeleton {\n  background-color: #ccc;\n  height: 8px;\n  width: 100%;\n  margin-bottom: 8px;\n  border-radius: 4px;\n}\n\n.starContainer {\n  display: flex;\n  justify-content: flex-end;\n  gap: 4px; \n}\n\n.skeletonStar {\n  background-color: #ccc;\n  width: 16px; \n  height: 16px; \n  border-radius: 2px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardContainer": `LoadingSkeleton_cardContainer__ZlZrr`,
	"skeletonContainer": `LoadingSkeleton_skeletonContainer__8uqjY`,
	"imageContainer": `LoadingSkeleton_imageContainer__9WaSQ`,
	"skeletonImage": `LoadingSkeleton_skeletonImage__r5oGg`,
	"textSkeleton": `LoadingSkeleton_textSkeleton__YPaY5`,
	"ratingTextSkeleton": `LoadingSkeleton_ratingTextSkeleton__DQ0oQ`,
	"progressSkeleton": `LoadingSkeleton_progressSkeleton__yuKgN`,
	"starContainer": `LoadingSkeleton_starContainer__hp--O`,
	"skeletonStar": `LoadingSkeleton_skeletonStar__LIaa+`
};
export default ___CSS_LOADER_EXPORT___;
