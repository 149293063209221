import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  CircularProgress,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { MaterialReactTable } from "material-react-table";
import Swal from "sweetalert2";
import styles from "./ManageBannerList.module.css";

const VideoBannerList = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectedBanner, setSelectedBanner] = useState(null);
  const [hindiCoverImage, setHindiCoverImage] = useState(null);
  const [englishCoverImage, setEnglishCoverImage] = useState(null);
  const [hindiCoverImagePreview, setHindiCoverImagePreview] = useState(null);
  const [englishCoverImagePreview, setEnglishCoverImagePreview] =
    useState(null);

  useEffect(() => {
    // Fetch the banners from the API
    const fetchBanners = async () => {
      try {
        const response = await fetch(
          "https://auth.ssccglpinnacle.com/api/banners"
        );
        const result = await response.json();
        if (response.ok) {
          setData(result);
        } else {
          console.error("Failed to fetch banners");
        }
      } catch (error) {
        console.error("Error fetching banners:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBanners();
  }, []);

  const handleEdit = (banner) => {
    setSelectedBanner(banner);
    setHindiCoverImagePreview(banner.hindiCoverImage);
    setEnglishCoverImagePreview(banner.englishCoverImage);
    setOpenEditDialog(true);
  };

  const handleDelete = async (bannerId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete this banner?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await fetch(
            `https://auth.ssccglpinnacle.com/api/banner/${bannerId}`,
            {
              method: "DELETE",
            }
          );
          if (response.ok) {
            setData(data.filter((banner) => banner._id !== bannerId));
            Swal.fire("Deleted!", "Your banner has been deleted.", "success");
          } else {
            Swal.fire("Failed!", "Failed to delete the banner.", "error");
          }
        } catch (error) {
          console.error("Error deleting banner:", error);
        }
      }
    });
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("courseTitle", selectedBanner.courseTitle);
    formData.append("order", selectedBanner.order);
    formData.append("active", selectedBanner.active);
    if (hindiCoverImage) {
      formData.append("hindiCoverImage", hindiCoverImage);
    }
    if (englishCoverImage) {
      formData.append("englishCoverImage", englishCoverImage);
    }

    try {
      const response = await fetch(
        `https://auth.ssccglpinnacle.com/api/banner/${selectedBanner._id}`,
        {
          method: "PUT",
          body: formData,
        }
      );
      if (response.ok) {
        const updatedBanner = await response.json();
        setData(
          data.map((banner) =>
            banner._id === updatedBanner._id ? updatedBanner : banner
          )
        );
        Swal.fire("Updated!", "Your banner has been updated.", "success");
        setOpenEditDialog(false);
      } else {
        Swal.fire("Failed!", "Failed to update the banner.", "error");
      }
    } catch (error) {
      console.error("Error updating banner:", error);
    }
  };

  const handleDialogClose = () => {
    setOpenEditDialog(false);
    setSelectedBanner(null);
    setHindiCoverImage(null);
    setEnglishCoverImage(null);
    setHindiCoverImagePreview(null);
    setEnglishCoverImagePreview(null);
  };

  const handleHindiCoverImageChange = (e) => {
    const file = e.target.files[0];
    setHindiCoverImage(file);
    setHindiCoverImagePreview(URL.createObjectURL(file));
  };

  const handleEnglishCoverImageChange = (e) => {
    const file = e.target.files[0];
    setEnglishCoverImage(file);
    setEnglishCoverImagePreview(URL.createObjectURL(file));
  };

  const columns = [
    {
      accessorKey: "course._id",
      header: "Course ID",
      Cell: ({ cell }) => <span>{cell.getValue()}</span>,
    },
    { accessorKey: "courseTitle", header: "Course Title" },
    { accessorKey: "order", header: "Order" },
    {
      accessorKey: "hindiCoverImage",
      header: "Hindi Cover Image",
      Cell: ({ cell }) => (
        <img
          src={cell.getValue()}
          alt="Hindi Cover"
          style={{ width: "100px" }}
        />
      ),
    },
    {
      accessorKey: "englishCoverImage",
      header: "English Cover Image",
      Cell: ({ cell }) => (
        <img
          src={cell.getValue()}
          alt="English Cover"
          style={{ width: "100px" }}
        />
      ),
    },
    // {
    //   accessorKey: "active",
    //   header: "Active",
    //   Cell: ({ cell }) => (cell.getValue() ? "Active" : "Inactive"),
    // },
    {
      header: "Actions",
      Cell: ({ row }) => (
        <Box className={styles.actionButton}>
          <Button
            variant="contained"
            className={styles.editButton}
            onClick={() => handleEdit(row.original)}
            style={{ backgroundColor: "none", color: "#f44336" }}
          >
            Edit
          </Button>
          <Button
            variant="contained"
            className={styles.deleteButton}
            onClick={() => handleDelete(row.original._id)}
            style={{ backgroundColor: "none", color: "#4caf50" }}
          >
            Delete
          </Button>
        </Box>
      ),
    },
  ];

  return (
    <div className={styles.container}>
      <Typography variant="h4" gutterBottom className={styles.heading}>
        Video Promotion Slider Banners
      </Typography>
      {loading ? (
        <div className={styles.progressLoader}>
          <CircularProgress />
        </div>
      ) : (
        <div className={styles.tableContainer}>
          <MaterialReactTable columns={columns} data={data} />
        </div>
      )}

      <Dialog open={openEditDialog} onClose={handleDialogClose}>
        <DialogTitle className={styles.dialogTitle}>Edit Banner</DialogTitle>
        <DialogContent className={styles.dialogContent}>
          <Box component="form" onSubmit={handleEditSubmit}>
            <TextField
              fullWidth
              margin="normal"
              label="Course Title"
              value={selectedBanner?.courseTitle || ""}
              onChange={(e) =>
                setSelectedBanner({
                  ...selectedBanner,
                  courseTitle: e.target.value,
                })
              }
              className={styles.formField}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Order"
              type="number"
              value={selectedBanner?.order || ""}
              onChange={(e) =>
                setSelectedBanner({ ...selectedBanner, order: e.target.value })
              }
              className={styles.formField}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={selectedBanner?.active || false}
                  onChange={(e) =>
                    setSelectedBanner({
                      ...selectedBanner,
                      active: e.target.checked,
                    })
                  }
                  color="primary"
                />
              }
              label="Active"
            />
            <div className={styles.formField}>
              <label>Hindi Cover Image</label>
              <input type="file" onChange={handleHindiCoverImageChange} />
              {hindiCoverImagePreview && (
                <img
                  className={styles.coverImage}
                  src={hindiCoverImagePreview}
                  alt="Hindi Cover"
                />
              )}
            </div>
            <div className={styles.formField}>
              <label>English Cover Image</label>
              <input type="file" onChange={handleEnglishCoverImageChange} />
              {englishCoverImagePreview && (
                <img
                  className={styles.coverImage}
                  src={englishCoverImagePreview}
                  alt="English Cover"
                />
              )}
            </div>
            <DialogActions className={styles.dialogActions}>
              <Button
                onClick={handleDialogClose}
                style={{ backgroundColor: "#f44336", color: "#ffffff" }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                style={{ backgroundColor: "#4caf50", color: "#ffffff" }}
              >
                Save
              </Button>
            </DialogActions>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default VideoBannerList;
