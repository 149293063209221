// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './App';
// import TagManager from 'react-gtm-module'
// // import reportWebVitals from './reportWebVitals';
// import 'bootstrap/dist/css/bootstrap.min.css';

// const tagManagerArgs = {
//   gtmId: process.env.GTM_ID,
// }

// console.log("TAG Manager ID: " , tagManagerArgs);

// TagManager.initialize(tagManagerArgs);

// const root = ReactDOM.createRoot(document.getElementById('root'));


// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// // reportWebVitals();



import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
// import TagManager from 'react-gtm-module';
import 'bootstrap/dist/css/bootstrap.min.css';

// Initialize Google Tag Manager
// const tagManagerArgs = {
//   gtmId: process.env.REACT_APP_GTM_ID, // Use REACT_APP prefix to properly load environment variables
// };

// console.log("GTM ID", process.env.REACT_APP_GTM_ID);

// if (tagManagerArgs.gtmId) {
//   console.log("Initializing Google Tag Manager with ID:", tagManagerArgs.gtmId);
//   TagManager.initialize(tagManagerArgs);
// }

// Create React root and render the application
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);


// Note: reportWebVitals can be enabled for performance monitoring
// To start measuring performance in your app, use the following:
// import reportWebVitals from './reportWebVitals';
// reportWebVitals(console.log);
