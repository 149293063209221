// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SearchBar_searchbox__6Ojsy {
  margin-top: 30px;
  padding-left: 2rem;
  border-radius: 2rem;
  background-color: white;
  width: 35%;
  margin-left: 2rem;
  box-sizing: border-box;
  justify-content: center;
  height: 2.5rem;
}
.SearchBar_productSearchbox__CKJdr {
  border: none;
  display: flex;
  padding-left: 0.6rem;
  margin-top: 0.3rem;
  margin-left: 0.2rem;
}
.SearchBar_productSearch__YOTrv {
  border: none;
}
textarea:focus,
.SearchBar_productSearch__YOTrv:focus {
  outline: none;
}
#SearchBar_input-label__lkKoy {
  display: none;
}
#SearchBar_product-search__5VOYo {
  font-size: 1rem;
  background: white;
  border: none;
  outline: none;
  padding: 10px;
  border-radius: 2rem;
  width: 95%;
  margin-left: 1.5rem;
  text-align: left;
  font-size: 16px;
}
#SearchBar_product-search__5VOYo:hover {
  color: white;
}
#SearchBar_product-search__5VOYo::placeholder {
  color: black;
  opacity: 0.7;
  text-align: left;
}
.SearchBar_fa-times__eq\\+12:hover {
  cursor: pointer;
}
.SearchBar_search_icon__3PWWh {
  color: #5c5470;
  font-size: 1.2em;
  opacity: 0.7;
  margin-top: 4px;
}
.SearchBar_productSearch__YOTrv {
  margin-left: 0.5rem;
  width: 93%;
}
`, "",{"version":3,"sources":["webpack://./src/VIDEOPLAYER COMPONENTS/components/VideoPlayerPage/Navigations Components/SearchBar.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,mBAAmB;EACnB,uBAAuB;EACvB,UAAU;EACV,iBAAiB;EACjB,sBAAsB;EACtB,uBAAuB;EACvB,cAAc;AAChB;AACA;EACE,YAAY;EACZ,aAAa;EACb,oBAAoB;EACpB,kBAAkB;EAClB,mBAAmB;AACrB;AACA;EACE,YAAY;AACd;AACA;;EAEE,aAAa;AACf;AACA;EACE,aAAa;AACf;AACA;EACE,eAAe;EACf,iBAAiB;EACjB,YAAY;EACZ,aAAa;EACb,aAAa;EACb,mBAAmB;EACnB,UAAU;EACV,mBAAmB;EACnB,gBAAgB;EAChB,eAAe;AACjB;AACA;EACE,YAAY;AACd;AACA;EACE,YAAY;EACZ,YAAY;EACZ,gBAAgB;AAClB;AACA;EACE,eAAe;AACjB;AACA;EACE,cAAc;EACd,gBAAgB;EAChB,YAAY;EACZ,eAAe;AACjB;AACA;EACE,mBAAmB;EACnB,UAAU;AACZ","sourcesContent":[".searchbox {\n  margin-top: 30px;\n  padding-left: 2rem;\n  border-radius: 2rem;\n  background-color: white;\n  width: 35%;\n  margin-left: 2rem;\n  box-sizing: border-box;\n  justify-content: center;\n  height: 2.5rem;\n}\n.productSearchbox {\n  border: none;\n  display: flex;\n  padding-left: 0.6rem;\n  margin-top: 0.3rem;\n  margin-left: 0.2rem;\n}\n.productSearch {\n  border: none;\n}\ntextarea:focus,\n.productSearch:focus {\n  outline: none;\n}\n#input-label {\n  display: none;\n}\n#product-search {\n  font-size: 1rem;\n  background: white;\n  border: none;\n  outline: none;\n  padding: 10px;\n  border-radius: 2rem;\n  width: 95%;\n  margin-left: 1.5rem;\n  text-align: left;\n  font-size: 16px;\n}\n#product-search:hover {\n  color: white;\n}\n#product-search::placeholder {\n  color: black;\n  opacity: 0.7;\n  text-align: left;\n}\n.fa-times:hover {\n  cursor: pointer;\n}\n.search_icon {\n  color: #5c5470;\n  font-size: 1.2em;\n  opacity: 0.7;\n  margin-top: 4px;\n}\n.productSearch {\n  margin-left: 0.5rem;\n  width: 93%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchbox": `SearchBar_searchbox__6Ojsy`,
	"productSearchbox": `SearchBar_productSearchbox__CKJdr`,
	"productSearch": `SearchBar_productSearch__YOTrv`,
	"input-label": `SearchBar_input-label__lkKoy`,
	"product-search": `SearchBar_product-search__5VOYo`,
	"fa-times": `SearchBar_fa-times__eq+12`,
	"search_icon": `SearchBar_search_icon__3PWWh`
};
export default ___CSS_LOADER_EXPORT___;
