

import React, { useEffect, useRef, useState } from 'react';
import { fabric } from 'fabric';
import { SketchPicker } from 'react-color';
import { FaCircle, FaSquare, FaPencilAlt, FaTrashAlt, FaDownload, FaSave,FaFilePdf, FaImage, FaArrowRotateRight  , FaTextHeight, FaPalette, FaSlash, FaBezierCurve, FaExpandArrowsAlt, FaCompressArrowsAlt, FaSyncAlt, FaUndoAlt, FaEraser } from 'react-icons/fa';
import jsPDF from 'jspdf';
import { AiOutlineRotateRight,AiOutlineRotateLeft  } from "react-icons/ai";
import { CgEditFlipH,CgEditFlipV  } from "react-icons/cg";
import { MdAnimation } from "react-icons/md";
import './FabricEditor.css';
import { useAuth } from '../../../Context/AuthContext';
import Swal from 'sweetalert2';
import { AiOutlineClose } from 'react-icons/ai';

export default function FabricEditor({ chapterData, cardId, onClose  }) {
  const canvasRef = useRef(null);
  const editorRef = useRef(null);
  const [isToolbarVisible, setIsToolbarVisible] = useState(true);
  const { user } = useAuth();
  const [canvas, setCanvas] = useState(null);
  const [color, setColor] = useState("#000000");
  const [brushSize, setBrushSize] = useState(5);
  const [brushOpacity, setBrushOpacity] = useState(1);
  const [isDrawingMode, setIsDrawingMode] = useState(false);
  const [isEraserMode, setIsEraserMode] = useState(false);
  const [isColorPickerVisible, setIsColorPickerVisible] = useState(false);
  const fileInputRef = useRef(null);
  
  const [position, setPosition] = useState({ top: 100, left: 100 });
  const [isDragging, setIsDragging] = useState(false);
  const offset = useRef({ x: 0, y: 0 });

  useEffect(() => {
    const fabricCanvas = new fabric.Canvas(canvasRef.current, { backgroundColor: '#ffffff', selection: true });
    setCanvas(fabricCanvas);
    fabricCanvas.setWidth(1200);
    fabricCanvas.setHeight(800);

    fabricCanvas.freeDrawingBrush = new fabric.PencilBrush(fabricCanvas);
    fabricCanvas.freeDrawingBrush.color = color;
    fabricCanvas.freeDrawingBrush.width = brushSize;
    fabricCanvas.freeDrawingBrush.opacity = brushOpacity;

    return () => {
      fabricCanvas.dispose();
    };
  }, []);

  useEffect(() => {
    if (canvas) {
      canvas.freeDrawingBrush.color = color;
      canvas.freeDrawingBrush.width = brushSize;
      canvas.freeDrawingBrush.opacity = brushOpacity;
    }
  }, [canvas, color, brushSize, brushOpacity]);

  const toggleDrawMode = () => {
    if (canvas) {
      const newDrawingMode = !isDrawingMode;
      setIsDrawingMode(newDrawingMode);
      setIsEraserMode(false); // Ensure eraser mode is off when drawing mode is activated
      canvas.isDrawingMode = newDrawingMode;
      canvas.freeDrawingBrush.color = color;
      canvas.freeDrawingBrush.width = brushSize;
      canvas.freeDrawingBrush.opacity = brushOpacity;
    }
  };

  const toggleEraserMode = () => {
    if (canvas) {
      const newEraserMode = !isEraserMode;
      setIsEraserMode(newEraserMode);
      setIsDrawingMode(false); // Ensure drawing mode is off when eraser mode is activated
      canvas.isDrawingMode = newEraserMode;

      if (newEraserMode) {
        canvas.freeDrawingBrush.color = '#ffffff'; // White color acts as an eraser
      } else {
        canvas.freeDrawingBrush.color = color; // Switch back to the selected color
      }
      canvas.freeDrawingBrush.width = brushSize;
    }
  };

  const handleBrushSizeChange = (e) => {
    const newSize = parseInt(e.target.value, 10);
    setBrushSize(newSize);
    if (canvas && canvas.isDrawingMode) {
      canvas.freeDrawingBrush.width = newSize;
    }
  };

  const addShape = (shape) => {
    let newShape;
    switch (shape) {
      case "circle":
        newShape = new fabric.Circle({ radius: 30, fill: 'transparent', stroke: color, strokeWidth: 2, left: 100, top: 100 });
        break;
      case "rectangle":
        newShape = new fabric.Rect({ width: 60, height: 60, fill: 'transparent', stroke: color, strokeWidth: 2, left: 100, top: 100 });
        break;
      case "triangle":
        newShape = new fabric.Triangle({ width: 60, height: 60, fill: 'transparent', stroke: color, strokeWidth: 2, left: 100, top: 100 });
        break;
      case "line":
        newShape = new fabric.Line([50, 100, 200, 100], { stroke: color, strokeWidth: 2 });
        break;
      default:
        break;
    }
    canvas.add(newShape);
  };

  const addText = () => {
    const text = new fabric.IText('Enter text', {
      left: 100, top: 100, fill: color, fontSize: 20, fontFamily: 'Arial', editable: true,
    });
    canvas.add(text);
    canvas.setActiveObject(text);
  };

  const importImage = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        fabric.Image.fromURL(e.target.result, (img) => {
          img.scaleToWidth(200);
          img.scaleToHeight(200);
          canvas.add(img);
          canvas.renderAll();
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const clearCanvas = () => {
    if (canvas) canvas.clear();
  };

  const removeObject = () => {
    const activeObject = canvas.getActiveObject();
    if (activeObject) {
      canvas.remove(activeObject);
      canvas.discardActiveObject().renderAll();
    }
  };

  const rotateObject = (angle) => {
    const activeObject = canvas.getActiveObject();
    if (activeObject) {
      activeObject.rotate((activeObject.angle || 0) + angle).setCoords();
      canvas.renderAll();
    }
  };

  const scaleObject = (factor) => {
    const activeObject = canvas.getActiveObject();
    if (activeObject) {
      activeObject.scale((activeObject.scaleX || 1) * factor).setCoords();
      canvas.renderAll();
    }
  };

  const flipObject = (axis) => {
    const activeObject = canvas.getActiveObject();
    if (activeObject) {
      if (axis === "X") activeObject.flipX = !activeObject.flipX;
      if (axis === "Y") activeObject.flipY = !activeObject.flipY;
      canvas.renderAll();
    }
  };

  const animateObject = () => {
    const activeObject = canvas.getActiveObject();
    if (activeObject) {
      fabric.util.animate({
        startValue: activeObject.left,
        endValue: activeObject.left + 100,
        duration: 1000,
        onChange: (value) => {
          activeObject.left = value;
          canvas.renderAll();
        },
      });
    }
  };

  const downloadCanvas = (format) => {
    if (!canvas) return;
    const fileName = `canvas_${Date.now()}`;

    if (format === "PNG") {
      const link = document.createElement('a');
      link.href = canvas.toDataURL('image/png');
      link.download = `${fileName}.png`;
      link.click();
    } else if (format === "SVG") {
      const link = document.createElement('a');
      link.href = `data:image/svg+xml;utf8,${encodeURIComponent(canvas.toSVG())}`;
      link.download = `${fileName}.svg`;
      link.click();
    } else if (format === "PDF") {
      const pdf = new jsPDF('landscape', 'px', [canvas.width, canvas.height]);
      pdf.addImage(canvas.toDataURL('image/jpeg'), 'JPEG', 0, 0, canvas.width, canvas.height);
      pdf.save(`${fileName}.pdf`);
    }
  };

  const toggleColorPicker = () => {
    setIsColorPickerVisible(!isColorPickerVisible);
  };

  const startDragging = (e) => {
    if (e.target.closest('.canvas-wrapper')) return;
    setIsDragging(true);
    offset.current = {
      x: e.clientX - position.left,
      y: e.clientY - position.top,
    };
  };

  const onDragging = (e) => {
    if (isDragging) {
      setPosition({
        left: e.clientX - offset.current.x,
        top: e.clientY - offset.current.y,
      });
    }
  };

  const stopDragging = () => {
    setIsDragging(false);
  };

  // const saveNotes = async () => {
  //   const svgContent = canvas.toSVG();
  //   try {
  //     const response = await fetch('http://localhost:5000/api/saveNotes', {
  //       method: 'POST',
  //       headers: { 'Content-Type': 'application/json' },
  //       body: JSON.stringify({
  //         userId: user._id,
  //         email: user.email_id,
  //         chapterId: chapterData._id,
  //         cardId: cardId,
  //         notesContent: svgContent,
  //       }),
  //     });

  //     if (response.ok) console.log('Notes saved successfully');
  //     else console.error('Failed to save notes');
  //   } catch (error) {
  //     console.error('Error saving notes:', error);
  //   }
  // };



  const saveNotes = async () => {
    const svgContent = canvas.toSVG();
    try {
      const response = await fetch('https://auth.ssccglpinnacle.com/api/saveNotes', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          userId: user._id,
          email: user.email_id,
          chapterId: chapterData._id,
          cardId: cardId,
          notesContent: svgContent,
        }),
      });
  
      if (response.ok) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Notes saved successfully!',
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Failed to save notes',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `Error saving notes: ${error.message}`,
      });
    }
  };
  
  const toggleToolbar = () => {
    setIsToolbarVisible(!isToolbarVisible);
  };

  return (
    <div
      className="fabric-editor"
      ref={editorRef}
      style={{ position: 'absolute', top: `${position.top}px`, left: `${position.left}px`, cursor: isDragging ? 'grabbing' : 'grab' }}
      onMouseDown={startDragging}
      onMouseMove={onDragging}
      onMouseUp={stopDragging}
      onMouseLeave={stopDragging}
    > <div
    className="fabric-editor-titel"><p>{chapterData.title}</p>

            <button onClick={toggleToolbar} className="toggle-toolbar-btn-fabric" style={{ marginBottom: '10px' }}>
        {isToolbarVisible ? 'Hide Toolbar' : 'Show Toolbar'}
      </button>
     

<button onClick={onClose} className="close-notes-btn">
  <AiOutlineClose /> {/* Add the icon */}
 
</button>

      </div>
      {isToolbarVisible && (
        <>
<div className="toolbar">
  <button className="btn-draw-mode" onClick={toggleDrawMode} title="Draw Mode">
    <FaPencilAlt color={isDrawingMode ? 'red' : 'black'} />
  </button>
  <button className="btn-eraser-mode" onClick={toggleEraserMode} title="Eraser Mode">
    <FaEraser color={isEraserMode ? 'red' : 'black'} />
  </button>
  <button className="btn-add-circle" onClick={() => addShape("circle")} title="Add Circle"><FaCircle /></button>
  <button className="btn-add-rectangle" onClick={() => addShape("rectangle")} title="Add Rectangle"><FaSquare /></button>
  <button className="btn-add-triangle" onClick={() => addShape("triangle")} title="Add Triangle"><FaBezierCurve /></button>
  <button className="btn-add-line" onClick={() => addShape("line")} title="Add Line"><FaSlash /></button>
  <button className="btn-add-text" onClick={addText} title="Add Text"><FaTextHeight /></button>
  <button className="btn-clear-canvas" onClick={clearCanvas} title="Delete all"><FaTrashAlt /></button>
  <button className="btn-remove-object" onClick={removeObject} title="Remove select Object"><FaTrashAlt /></button>
  <button className="btn-color-picker" onClick={toggleColorPicker} title="Color Picker"><FaPalette /></button>
  {isColorPickerVisible && (
              <div className="color-picker-wrapper">
                <SketchPicker color={color} onChange={(newColor) => setColor(newColor.hex)} />
              </div>
            )}
  <button className="btn-import-image" onClick={() => fileInputRef.current.click()} title="Import Image"><FaImage /></button>
  <input type="file" accept="image/*" ref={fileInputRef} onChange={importImage} style={{ display: "none" }} />
  <button className="btn-save-png" onClick={() => downloadCanvas("PNG")} title="Save as PNG"><FaDownload /></button>
  <button className="btn-save-svg" onClick={() => downloadCanvas("SVG")} title="Export as SVG"><FaDownload /></button>
  <button className="btn-save-pdf" onClick={() => downloadCanvas("PDF")} title="Export as PDF"><FaFilePdf /></button>
  <button className="btn-rotate-right" onClick={() => rotateObject(15)} title="Rotate Right"><AiOutlineRotateRight/></button>
  <button className="btn-rotate-left" onClick={() => rotateObject(-15)} title="Rotate Left"><AiOutlineRotateLeft  /></button>
  <button className="btn-scale-up" onClick={() => scaleObject(1.1)} title="Scale Up"><FaExpandArrowsAlt /></button>
  <button className="btn-scale-down" onClick={() => scaleObject(0.9)} title="Scale Down"><FaCompressArrowsAlt /></button>
  <button className="btn-flip-horizontal" onClick={() => flipObject("X")} title="Flip Horizontally"><CgEditFlipH /></button>
  <button className="btn-flip-vertical" onClick={() => flipObject("Y")} title="Flip Vertically"><CgEditFlipV  /></button>
  <button className="btn-animate" onClick={animateObject} title="Animate Object"> <MdAnimation  /></button>
  <button className="btn-save-notes" onClick={saveNotes} title="Save Notes"><FaSave />Save</button>

</div>
      <div style={{ marginTop: '10px' }}>
        <label>Brush Size:</label>
        <input type="range" min="1" max="50" value={brushSize} onChange={handleBrushSizeChange} />
      </div>
      </>
   )}
      <div className="canvas-wrapper" style={{ width: '800px', height: '600px', overflowY: 'auto', overflowX: 'hidden', border: '1px solid #ccc' }}>
        <canvas ref={canvasRef} width="800" height="1200" />
      </div>
    </div>
  );
}




