import React from 'react';
import styles from './LoginModal.module.css';

const LoginModal = ({ show, handleClose }) => {
  if (!show) {
    return null;
  }

  const handleBackdropClick = (e) => {
    if (e.target.className.includes(styles.modalBackdrop)) {
      handleClose();
    }
  };

  return (
    <>
      <div className={styles.modalBackdrop} onClick={handleBackdropClick} />
      <div className={styles.modal}>
        <div className={styles.modalHeader}>
          <h5 className={styles.modalTitle}>Login Required</h5>
          {/* <button type="button" className={styles.modalClose} onClick={handleClose}>
            &times;
          </button> */}
        </div>
        <div className={styles.modalBody}>
          <p>Please log in or register to add this course.</p>
        </div>
        <div className={styles.modalFooter}>
          {/* <button type="button" className={`${styles.btn} ${styles.btnSecondary}`} onClick={handleClose}>
            Close
          </button> */}
          <button
            type="button"
            className={`${styles.btn} ${styles.btnPrimary}`}
            onClick={() => {
              window.location.href = 'https://testportal.ssccglpinnacle.com/login';
            }}
          >
            Register
          </button>
          <button
            type="button"
            className={`${styles.btn} ${styles.btnPrimary}`}
            onClick={() => {
              window.location.href = 'https://testportal.ssccglpinnacle.com/login';
            }}
          >
            Sign In
          </button>
        </div>
      </div>
    </>
  );
};

export default LoginModal;
