// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navigation {
   position: fixed;
    top: 15%;
    right: 2%;
    width: 13%;
    height: 100vh;
    background-color: #f2f2f2;
    padding-top: 3%;}


.dark-theme .navigation {
    background-color:#29353e;
  color: white;
 
}


.navigation ul {
 list-style-type: none;
    padding: 0;
    margin-top: 10px;
    /* text-align: center; */

    border-left: solid 2px #cdcdcd;
    color: #4e4e4e;
}

.navigation li {
  margin-bottom: 10px;
}

.navigation button {
 border: none;
    background: none;
    cursor: pointer;
    font-size: 0.833rem;
    padding: 0.5rem 1rem;
    color: #4e4e4e;
    font-weight: 600;
    width: 100%;
    text-align: left;
}

.navigation button:hover {
  text-decoration: none;
  background-color: #ff2a5130;
  border-left: 2px solid #d30038;
}


.dark-theme .navigation li button {
color: #cdcdcd;
}

@media (min-width: 1500px) {
.navigation button {
    
 
    font-size: 1.1rem;

}
}


























`, "",{"version":3,"sources":["webpack://./src/Ebook/DigitalContentPages/Ebook-Page/Navigation.css"],"names":[],"mappings":"AAAA;GACG,eAAe;IACd,QAAQ;IACR,SAAS;IACT,UAAU;IACV,aAAa;IACb,yBAAyB;IACzB,eAAe,CAAC;;;AAGpB;IACI,wBAAwB;EAC1B,YAAY;;AAEd;;;AAGA;CACC,qBAAqB;IAClB,UAAU;IACV,gBAAgB;IAChB,wBAAwB;;IAExB,8BAA8B;IAC9B,cAAc;AAClB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;CACC,YAAY;IACT,gBAAgB;IAChB,eAAe;IACf,mBAAmB;IACnB,oBAAoB;IACpB,cAAc;IACd,gBAAgB;IAChB,WAAW;IACX,gBAAgB;AACpB;;AAEA;EACE,qBAAqB;EACrB,2BAA2B;EAC3B,8BAA8B;AAChC;;;AAGA;AACA,cAAc;AACd;;AAEA;AACA;;;IAGI,iBAAiB;;AAErB;AACA","sourcesContent":[".navigation {\n   position: fixed;\n    top: 15%;\n    right: 2%;\n    width: 13%;\n    height: 100vh;\n    background-color: #f2f2f2;\n    padding-top: 3%;}\n\n\n.dark-theme .navigation {\n    background-color:#29353e;\n  color: white;\n \n}\n\n\n.navigation ul {\n list-style-type: none;\n    padding: 0;\n    margin-top: 10px;\n    /* text-align: center; */\n\n    border-left: solid 2px #cdcdcd;\n    color: #4e4e4e;\n}\n\n.navigation li {\n  margin-bottom: 10px;\n}\n\n.navigation button {\n border: none;\n    background: none;\n    cursor: pointer;\n    font-size: 0.833rem;\n    padding: 0.5rem 1rem;\n    color: #4e4e4e;\n    font-weight: 600;\n    width: 100%;\n    text-align: left;\n}\n\n.navigation button:hover {\n  text-decoration: none;\n  background-color: #ff2a5130;\n  border-left: 2px solid #d30038;\n}\n\n\n.dark-theme .navigation li button {\ncolor: #cdcdcd;\n}\n\n@media (min-width: 1500px) {\n.navigation button {\n    \n \n    font-size: 1.1rem;\n\n}\n}\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
