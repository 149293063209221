// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SideMenu_menu_main_container__F1ReB {
  width: 16%;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  margin: 3% 0% 0% 2%;
  height: 38.6rem;
}

.SideMenu_account_btn__dmnBW {
  font-size: 16px;
  font-weight: 600;
  color: black;
  margin-left: 37%;
}

.SideMenu_account_icon__S5zRj {
  background-color: black;
  color: white;
  border-radius: 50%;
  width: 25%;
  text-align: center;
  padding-top: 3%;
  margin-right: 10%;
}
.SideMenu_account_icon_head__DbTlC {
  background-color: black;
  color: white;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  text-align: center;
  padding-top: 3%;
  margin-right: 10%;
  margin-top: 8%;
}
.SideMenu_account_para1__Q69s5 {
  font-size: 17px;
  margin: 24%;
}
.SideMenu_unordered_list__szyqY {
  display: block;
  padding: 0.4rem 1.6rem;
  color: #1c1d1f;
}
.SideMenu_unordered_list__szyqY li {
  list-style: none;
  font-size: 15px;
  line-height: 1.8rem;
  margin-top: 3%;
  cursor: pointer;
}
.SideMenu_list_name__kwzXp {
  font-weight: 700;
  padding-left: 30%;
}
.SideMenu_container2__6wy3\\+ {
  padding: 1rem;
  position: absolute;
  top: 2;
  top: 9px;
  left: 16%;
}
`, "",{"version":3,"sources":["webpack://./src/VIDEOPLAYER COMPONENTS/components/VideoPlayerPage/AccountPage/SideMenu.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV;0CACwC;EACxC,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,uBAAuB;EACvB,YAAY;EACZ,kBAAkB;EAClB,UAAU;EACV,kBAAkB;EAClB,eAAe;EACf,iBAAiB;AACnB;AACA;EACE,uBAAuB;EACvB,YAAY;EACZ,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,iBAAiB;EACjB,cAAc;AAChB;AACA;EACE,eAAe;EACf,WAAW;AACb;AACA;EACE,cAAc;EACd,sBAAsB;EACtB,cAAc;AAChB;AACA;EACE,gBAAgB;EAChB,eAAe;EACf,mBAAmB;EACnB,cAAc;EACd,eAAe;AACjB;AACA;EACE,gBAAgB;EAChB,iBAAiB;AACnB;AACA;EACE,aAAa;EACb,kBAAkB;EAClB,MAAM;EACN,QAAQ;EACR,SAAS;AACX","sourcesContent":[".menu_main_container {\n  width: 16%;\n  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,\n    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;\n  margin: 3% 0% 0% 2%;\n  height: 38.6rem;\n}\n\n.account_btn {\n  font-size: 16px;\n  font-weight: 600;\n  color: black;\n  margin-left: 37%;\n}\n\n.account_icon {\n  background-color: black;\n  color: white;\n  border-radius: 50%;\n  width: 25%;\n  text-align: center;\n  padding-top: 3%;\n  margin-right: 10%;\n}\n.account_icon_head {\n  background-color: black;\n  color: white;\n  border-radius: 50%;\n  width: 3rem;\n  height: 3rem;\n  text-align: center;\n  padding-top: 3%;\n  margin-right: 10%;\n  margin-top: 8%;\n}\n.account_para1 {\n  font-size: 17px;\n  margin: 24%;\n}\n.unordered_list {\n  display: block;\n  padding: 0.4rem 1.6rem;\n  color: #1c1d1f;\n}\n.unordered_list li {\n  list-style: none;\n  font-size: 15px;\n  line-height: 1.8rem;\n  margin-top: 3%;\n  cursor: pointer;\n}\n.list_name {\n  font-weight: 700;\n  padding-left: 30%;\n}\n.container2 {\n  padding: 1rem;\n  position: absolute;\n  top: 2;\n  top: 9px;\n  left: 16%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menu_main_container": `SideMenu_menu_main_container__F1ReB`,
	"account_btn": `SideMenu_account_btn__dmnBW`,
	"account_icon": `SideMenu_account_icon__S5zRj`,
	"account_icon_head": `SideMenu_account_icon_head__DbTlC`,
	"account_para1": `SideMenu_account_para1__Q69s5`,
	"unordered_list": `SideMenu_unordered_list__szyqY`,
	"list_name": `SideMenu_list_name__kwzXp`,
	"container2": `SideMenu_container2__6wy3+`
};
export default ___CSS_LOADER_EXPORT___;
