import React from 'react'

import classes from './Requirements.module.css'

export default function Requirements() {
  return (
    <div className={classes['requirements-full-page']}>
      <ul className={classes['requirements-ul']}><h3 className={classes['requirements-heading']}>Requirements</h3>
      <li className={classes['requirements-li']}>This course is from basics to advance level, no prior competition exams exposure required.</li>
      <li className={classes['requirements-li']}>Good internet connection is required so that you can watch videos smoothly</li>
      <li className={classes['requirements-li']}>Step by step subject is covered in details. So you will not required any other coaching.</li>
      <li className={classes['requirements-li']}>Students are advised to purchase Pinnacle SSC maths 6800 TCS MCQ book.</li>

      </ul>
        

      
    </div>
  )
}
