import React, { useState, useRef, useEffect } from 'react';
import pic from "../../Ebook/i/book.jpg";
import "./Row4.css";
import { AiOutlineLeft } from "react-icons/ai";
import { AiOutlineRight } from "react-icons/ai";
import { Link } from 'react-router-dom';
import gsap from "gsap";

function Row4() {

  const [cardData, setCardData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://onlineexam.ssccglpinnacle.com/testpass');
        const data = await response.json();
        
        // Ensure data is set correctly
        setCardData(data); 
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, []);

  let scrl = useRef(null);
  const [scrollX, setscrollX] = useState(10);
  const [scrolEnd, setscrolEnd] = useState(false);

  const slide = (shiftMultiplier) => {
    const cardWidth = scrl.current.querySelector('.card-for-test').offsetWidth; // Width of one card
    const shift = cardWidth * 4 * shiftMultiplier; // Calculate the scroll distance for 4 cards
    let targetScroll = scrl.current.scrollLeft + shift;
    
    // Calculate the maximum scrollable position
    const maxScroll = scrl.current.scrollWidth - scrl.current.offsetWidth;
    
    // Clamp the target scroll to not exceed the maximum
    if (targetScroll > maxScroll) {
      targetScroll = maxScroll;
    }
    
    if (targetScroll < 0) {
      targetScroll = 0;
    }
    
    // Apply GSAP animation to scroll to the target position smoothly
    gsap.to(scrl.current, {
      scrollLeft: targetScroll,
      duration: 0.6,
      ease: "power2.inOut",
      onComplete: () => {
        setscrollX(scrl.current.scrollLeft);
    
        // Update the state to determine if the scroll has reached the end
        if (scrl.current.scrollLeft >= maxScroll) {
          setscrolEnd(true);
        } else {
          setscrolEnd(false);
        }
      },
    });
  };
  
  const scrollCheck = () => {
    setscrollX(scrl.current.scrollLeft);
  
    // Check if the scroll has reached the end
    const maxScroll = scrl.current.scrollWidth - scrl.current.offsetWidth;
    if (Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <= scrl.current.offsetWidth) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  
    // Adjust visibility of scroll buttons based on the scroll position
    if (scrl.current.scrollLeft === 0) {
      setscrolEnd(false);
    } else if (scrl.current.scrollLeft >= maxScroll) {
      setscrolEnd(true);
    }
  };
  




  return (
    <>
      <section className="section-suggest-test">
        <div className="container-fluid-test">
          <div className="foruppersection">
            <div className="col-md-8 section-suggest-test-col-8">
              <p className="col-md-8 section-suggest-test-p">
                Suggested for You: Test Pass
              </p>
            </div>
          </div>
          <div className="container-for-button">
            {scrollX !== 5 && (
             <button className="prev" onClick={() => slide(-1)}>
             <AiOutlineLeft color="black" fontSize="1.5em" />
           </button>
            )}
            <div className="forcontainertest">
              <ul className="cards-for-test" ref={scrl} onScroll={scrollCheck}>
                {cardData.map((card, index) => (
                  <li className="card-for-test" key={index}>
                    <div>
                      <div className="card-title-image1">
                        <img className="card-title1" src="https://images.indianexpress.com/2018/12/SSClogo759.jpg" />
                        <span className="p-04 subscribe-batch">
                          <img src="https://ssccglpinnacle.com/myaccount/images/trending-icon.png" />
                          &nbsp;1.5K Students
                        </span>
                      </div>
                      <div className="card-content1">
                        <p className="heading-test">{card.Product_title}</p>
                        <div className="Link-for-test">
                          <p className="total-test">{card.totalTests} TOTAL TEST</p>
                          <p className="free-test">| 1 free test</p>
                        </div>
                        <a className="test" href ={`https://testportal.ssccglpinnacle.com/individualexamtestpasssection/${card.id}`}>
                          {/* <p className="test">{card.testLink}</p> */}
                        </a>
                      </div>
                      <a href={`https://testportal.ssccglpinnacle.com/individualexamtestpasssection/${card.id}`}>
  <div className="text-center">
    <button className="btn btn-sm button-for-test">
      View Test Series
    </button>
  </div>
</a>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            {!scrolEnd && (
               <button className="next" onClick={() => slide(1)}>
               <AiOutlineRight color="black" fontSize="1.5em" />
             </button>
            )}
          </div>
        </div>
      </section>
    </>
  );
}

export default Row4;
