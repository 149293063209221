import React from "react";
import classes from "./ContentDetails.module.css";
import { VscDebugBreakpointData } from "react-icons/vsc";
import { MdOndemandVideo } from "react-icons/md";
import { BiMobile } from "react-icons/bi";
import { BsFileEarmarkPdf } from "react-icons/bs";
import { MdOutlineClosedCaptionOff } from "react-icons/md";

export default function ContentDetails() {
  return (
    <div className={classes["ContentDetails-fullpage"]}>
      <div className={classes["ContentDetails-heading"]}>
        What you will learn
      </div>
      <div className={classes["What-learn-div"]}>
        <ul className={classes["What-learn-list"]}>
          <li>
            <VscDebugBreakpointData />
            &nbsp;Video solution of the book
          </li>
          <li>
            <VscDebugBreakpointData />
            &nbsp;Class notes (PDF) of each video
          </li>
          <li>
            <VscDebugBreakpointData />
            &nbsp;Chapter wise arranged videos
          </li>
          <li>
            <VscDebugBreakpointData />
            &nbsp;Day wise schedule and videos accordingly
          </li>
        </ul>
        <ul className={classes["What-learn-list"]}>
          <li>
            <VscDebugBreakpointData />
            &nbsp;Exam oriented videos by expert faculty
          </li>
          <li>
            <VscDebugBreakpointData />
            &nbsp;Concepts clarity: basic to advance concepts.
          </li>
        </ul>
      </div>
      <div className={classes["ContentDetails-heading"]}>
        This course includes:
      </div>
      <div className={classes["This-course-includes"]}>
        <ul className={classes["includes-list"]}>
          <li>
            <MdOndemandVideo className={classes["icon-home-page"]} />
            &nbsp;150 Hours on-demand videos
          </li>
          <li>
            <BiMobile className={classes["icon-home-page"]} />
            &nbsp;Access on mobile, laptop, and TV
          </li>
        </ul>
        <ul className={classes["includes-list"]}>
          <li>
            <BsFileEarmarkPdf className={classes["icon-home-page"]} />
            &nbsp;150 Class notes (PDF)
          </li>
          <li>
            <MdOutlineClosedCaptionOff className={classes["icon-home-page"]} />
            &nbsp;Captions in regional languages
          </li>
        </ul>
      </div>
    </div>
  );
}
