import React, { useState } from "react";
import { MdStarRate } from "react-icons/md";
import "./StarRating.css"; // Create a new CSS file for styling if needed

const StarRating1 = ({ StarData }) => {
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);
  const [arr, setArr] = useState([]);

  function set() {
    setArr([...arr, rating]);
  }
  const countStarRatingOccurrences = (ratings) => {
    const starCount = {};

    ratings.forEach((rating) => {
      const starRating = rating.starRate;
      if (starCount[starRating]) {
        starCount[starRating]++;
      } else {
        starCount[starRating] = 1;
      }
    });

    return starCount;
  };

  const starCount = countStarRatingOccurrences(StarData);

  var filled1 = starCount[1] || 0;
  var filled2 = starCount[2] || 0;
  var filled3 = starCount[3] || 0;
  var filled4 = starCount[4] || 0;
  var filled5 = starCount[5] || 0;

  var wholecount = filled1 + filled2 + filled3 + filled4 + filled5;
  var totalAverage = (1 * filled1 + 2 * filled2 + 3 * filled3 + 4 * filled4 + 5 * filled5) / wholecount || 0;

  return (
    <div className="forStar">
      <div className="star-rating-book">
        {[0, 1, 2, 3, 4].map((i, id) => (
          <button
            key={id}
            type="button"
            className={i + 1 <= (hover || [rating]) ? "on" : "off"}
            onClick={() => {
              setRating(i + 1);
              set();
            }}
            onMouseEnter={() => setHover(i + 1)}
            onMouseLeave={() => setHover(rating)}
            onDoubleClick={() => {
              setRating(0);
              setHover(0);
            }}
          >
            <span className="star">&#9733; </span>
          </button>
        ))}
      </div>
      <div className="ad444">
        <div className="container-for-rating444">
          <div className="cards-for-star-rating444">
            <div className="card-content-for-star-rating-444">
              <h2>{totalAverage.toFixed(1)}</h2>
              <div className="For-Star444">
                <MdStarRate className="Star-Rating-average-444" fontSize="1.5em" />
              </div>
            </div>
            <div className="Total-rating-444">({wholecount}) Ratings</div>
          </div>
          <div className="cards-for-star-rating-2">
            <ul className="Progress">
              {[5, 4, 3, 2, 1].map((num) => (
                <li className="list-star" key={num}>
                  <div className="list-star-numb">
                    {num}
                    <MdStarRate className="list-star-icon-2" fontSize="12px" />
                  </div>
                  <div className="progressbar-list">
                    <div
                      className="progress-span"
                      style={{
                        height: "62%",
                        width: `${starCount[num] || 0}%`,
                        backgroundColor: num >= 4 ? "#388e3c" : num === 3 ? "#FFAC1C" : "#FF5F1F",
                        marginTop: "2px",
                        borderRadius: "10px",
                        animation: `progressBar${num} 0.6s ease-in-out`,
                        animationFillMode: "both",
                        transition: "width 2.8s",
                      }}
                    ></div>
                  </div>
                  <div className="progressPercent">{starCount[num] || 0}</div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StarRating1;
