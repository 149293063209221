import React, { useState, useEffect } from 'react';
import "./UpdatePDFForm.css"
import Swal from 'sweetalert2';
import { Link, useNavigate } from "react-router-dom";
import UpdateAnswerpdf from "./UpdateAnswerpdf";
import UpdateSolutionPdf from "./Updatesolutionpdf";

const UpdatePDFForm = () => {
  const [selectedEbookId, setSelectedEbookId] = useState('');
  const [selectedChapterId, setSelectedChapterId] = useState('');
  const [selectedPdfId, setSelectedPdfId] = useState('');
  const [pdfFile, setPdfFile] = useState(null);
  const [error, setError] = useState(null);
  const [ebooks, setEbooks] = useState([]);
  const [chapters, setChapters] = useState([]);
  const [pdfs, setPdfs] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEbooks = async () => {
      try {
        const response = await fetch('https://auth.ssccglpinnacle.com/api/ebooks');

        if (response.ok) {
          const ebooksData = await response.json();
          setEbooks(ebooksData);
        } else {
          console.error('Failed to fetch ebooks');
        }
      } catch (error) {
        console.error('Error fetching ebooks:', error);
      }
    };

    fetchEbooks();
  }, []);

  useEffect(() => {
    const fetchChapters = async () => {
      try {
        const response = await fetch(`https://auth.ssccglpinnacle.com/api/chapters-ebook-admin/${selectedEbookId}`);

        if (response.ok) {
          const chaptersData = await response.json();
          setChapters(chaptersData);
        } else {
          console.error('Failed to fetch chapters');
        }
      } catch (error) {
        console.error('Error fetching chapters:', error);
      }
    };

    if (selectedEbookId) {
      fetchChapters();
    }
  }, [selectedEbookId]);

  useEffect(() => {
    const fetchPDFs = async () => {
      try {
        const response = await fetch(`https://auth.ssccglpinnacle.com/api/pdfs-ebook/${selectedChapterId}`);

        if (response.ok) {
          const pdfsData = await response.json();
          setPdfs(pdfsData);
        } else {
          console.error('Failed to fetch PDFs');
        }
      } catch (error) {
        console.error('Error fetching PDFs:', error);
      }
    };

    if (selectedChapterId) {
      fetchPDFs();
    }
  }, [selectedChapterId]);

  const handleEbookChange = (e) => {
    setSelectedEbookId(e.target.value);
    setSelectedChapterId('');
    setSelectedPdfId('');
  };

  const handleChapterChange = (e) => {
    setSelectedChapterId(e.target.value);
    setSelectedPdfId('');
  };

  const handleNewPdfChange = (e) => {
    setPdfFile(e.target.files[0]);
  };

  const handleUpdatePdf = async () => {
    try {
      const formData = new FormData();
      formData.append('pdf', pdfFile);
      formData.append('pdfId', selectedPdfId);

      const response = await fetch('https://auth.ssccglpinnacle.com/api/updatePdf-ebook', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        const result = await response.json();
        console.log('Update successful:', result);

        // Reset form state
        setSelectedPdfId('');
        setPdfFile(null);

        // Show success message with SweetAlert
        Swal.fire({
          icon: 'success',
          title: 'Update Successful!',
          text: 'Your PDF has been updated successfully.',
        });
      } else {
        console.error('Failed to update PDF');
        setError('Failed to update PDF. Please try again.');

        // Show error message with SweetAlert
        Swal.fire({
          icon: 'error',
          title: 'Update Failed',
          text: 'There was an error updating the PDF. Please try again.',
        });
      }
    } catch (error) {
      console.error('Error updating PDF:', error);
      setError('Error updating PDF. Please try again.');

      // Show error message with SweetAlert
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An unexpected error occurred. Please try again later.',
      });
    }
  };

  const handleDeletePdf = () => {
    // Show a confirmation modal before proceeding with the delete
    Swal.fire({
      title: 'Are you sure?',
      text: 'You are about to delete this PDF. This action cannot be undone.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel',
    }).then((result) => {
      if (result.isConfirmed) {
        // Perform the delete operation here
        performDeletePdf();
      }
    });
  };

  const performDeletePdf = async () => {
    console.log('Deleting PDF with ID:', selectedPdfId);
    try {
      const response = await fetch(`https://auth.ssccglpinnacle.com/api/deletePdf-ebook/${selectedPdfId}`, {
        method: 'DELETE',
      });
      if (response.ok) {
        const result = await response.json();
        console.log('Delete successful:', result);

        // Reset form state
        setSelectedPdfId('');
        setPdfFile(null);

        // Show success message with SweetAlert
        Swal.fire({
          icon: 'success',
          title: 'Delete Successful!',
          text: 'Your PDF has been deleted successfully.',
        });
      } else {
        console.error('Failed to delete PDF');
        setError('Failed to delete PDF. Please try again.');

        // Show error message with SweetAlert
        Swal.fire({
          icon: 'error',
          title: 'Delete Failed',
          text: 'There was an error deleting the PDF. Please try again.',
        });
      }
    } catch (error) {
      console.error('Error deleting PDF:', error);
      setError('Error deleting PDF. Please try again.');

      // Show error message with SweetAlert
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An unexpected error occurred. Please try again later.',
      });
    }
  };



  return (
    <>
       
      <h1 className="add-ebook-form-pdf">Update PDF</h1>
      <div className="update-pdf-form-container">
        <label htmlFor="ebookId">Select SKU:</label>
        <select
  id="ebookId"
  value={selectedEbookId}
  onChange={handleEbookChange}
  required
  className="pdf-select"
>
  <option value="" disabled>
    Select SKU
  </option>
  {ebooks.slice().reverse().map((ebook) => (
    <option key={ebook._id} value={ebook._id}>
      {ebook.sku}
    </option>
  ))}
</select>

        <br />
  
        {selectedEbookId && (
          <>
            <label htmlFor="title">Title:</label>
            <input
              type="text"
              id="title"
              value={ebooks.find((ebook) => ebook._id === selectedEbookId)?.title || ''}
              readOnly
              className="pdf-input"
            />
            <br />
            <label htmlFor="edition">Edition:</label>
            <input
              type="text"
              id="edition"
              value={ebooks.find((ebook) => ebook._id === selectedEbookId)?.edition || ''}
              readOnly
              className="pdf-input"
            />
            <br />
            <label htmlFor="medium">Medium:</label>
            <input
              type="text"
              id="medium"
              value={ebooks.find((ebook) => ebook._id === selectedEbookId)?.medium || ''}
              readOnly
              className="pdf-input"
            />
            <br />
          </>
        )}
        <table className="pdf-table">
          <thead>
            <tr>
              <th>Chapter</th>
              <th>Existing PDF</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <select
                  value={selectedChapterId}
                  onChange={(e) => setSelectedChapterId(e.target.value)}
                >
                  <option value="" disabled>
                    Select Chapter
                  </option>
                  {chapters.map((chapter) => (
                    <option key={chapter._id} value={chapter._id}>
                      {chapter.title}
                    </option>
                  ))}
                </select>
              </td>
              <td>
                <select
                  value={selectedPdfId}
                  onChange={(e) => setSelectedPdfId(e.target.value)}
                >
                  <option value="" disabled>
                    Select PDF
                  </option>
                  {pdfs.map((pdf) => (
                    <option key={pdf._id} value={pdf._id}>
                      {pdf.s3Key}
                    </option>
                  ))}
                </select>
              </td>
              <td>
                <label htmlFor="newPdfFile">Upload New PDF:</label>
                <input type="file" id="newPdfFile" onChange={handleNewPdfChange} accept=".pdf" required className="pdf-input" />
                {error && <p className="error-message">{error}</p>}
                <button type="button" onClick={handleUpdatePdf} className="pdf-update">
                  Update PDF
                </button>
              </td>
  
              <td>
                {/* Add delete button and confirmation modal */}
                <button type="button" onClick={handleDeletePdf} className="pdf-delete">
                  Delete PDF
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
  
      <UpdateAnswerpdf />
  
      <UpdateSolutionPdf />
    </>
  );
                  }  
  
  export default UpdatePDFForm;