// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}
.on {
  color: #b4690e;
}
.off {
  color: #ababab;
}
li {
  list-style-type: none;
}

.forStar {
  display: inline-block;
  margin-top: 5%;
  width: 100%;
}
.fullStar {
  margin-top: 5%;
  margin-left: 23%;
}
.star-rating {
  display: flex;
  padding: 1%;
  width: 20%;
}
.heding {
  margin-left: 1%;
  font-family: Helvetica, Arial, sans-serif, color symbol;
  font-weight: 500;
  line-height: 1.2;
  font-size: 2rem;
}
.rfpage {
  margin-top: 2%;
  margin-left: 10%;
  margin-right: 20%;
}
.reviewHeading {
  margin-top: 6%;
  margin-left: 2%;
  font-family: Helvetica, Arial, sans-serif, color symbol;
  font-weight: 500;
  line-height: 1.2;
  font-size: 1.5rem;
}

.filter-search {
  margin-left: 1%;
  border: 1px solid black;
  height: 2rem;
  width: 60%;
}

.rating-filter {
  margin-left: 5%;
  height: 2rem;
}
.searchBtn {
  background-color: black;
  color: white;
  height: 2rem;
  margin-left: -4%;
  border: 2px solid black;
  border-bottom: 5px solid black;
}

.filterrationgHeading {
  margin-left: 70%;
}
`, "",{"version":3,"sources":["webpack://./src/YOUTUBE VIDEO PLAYER/YT_Navigation Components/Review/styles.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,YAAY;EACZ,aAAa;EACb,eAAe;AACjB;AACA;EACE,cAAc;AAChB;AACA;EACE,cAAc;AAChB;AACA;EACE,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;EACrB,cAAc;EACd,WAAW;AACb;AACA;EACE,cAAc;EACd,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,WAAW;EACX,UAAU;AACZ;AACA;EACE,eAAe;EACf,uDAAuD;EACvD,gBAAgB;EAChB,gBAAgB;EAChB,eAAe;AACjB;AACA;EACE,cAAc;EACd,gBAAgB;EAChB,iBAAiB;AACnB;AACA;EACE,cAAc;EACd,eAAe;EACf,uDAAuD;EACvD,gBAAgB;EAChB,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,uBAAuB;EACvB,YAAY;EACZ,UAAU;AACZ;;AAEA;EACE,eAAe;EACf,YAAY;AACd;AACA;EACE,uBAAuB;EACvB,YAAY;EACZ,YAAY;EACZ,gBAAgB;EAChB,uBAAuB;EACvB,8BAA8B;AAChC;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":["button {\n  background-color: transparent;\n  border: none;\n  outline: none;\n  cursor: pointer;\n}\n.on {\n  color: #b4690e;\n}\n.off {\n  color: #ababab;\n}\nli {\n  list-style-type: none;\n}\n\n.forStar {\n  display: inline-block;\n  margin-top: 5%;\n  width: 100%;\n}\n.fullStar {\n  margin-top: 5%;\n  margin-left: 23%;\n}\n.star-rating {\n  display: flex;\n  padding: 1%;\n  width: 20%;\n}\n.heding {\n  margin-left: 1%;\n  font-family: Helvetica, Arial, sans-serif, color symbol;\n  font-weight: 500;\n  line-height: 1.2;\n  font-size: 2rem;\n}\n.rfpage {\n  margin-top: 2%;\n  margin-left: 10%;\n  margin-right: 20%;\n}\n.reviewHeading {\n  margin-top: 6%;\n  margin-left: 2%;\n  font-family: Helvetica, Arial, sans-serif, color symbol;\n  font-weight: 500;\n  line-height: 1.2;\n  font-size: 1.5rem;\n}\n\n.filter-search {\n  margin-left: 1%;\n  border: 1px solid black;\n  height: 2rem;\n  width: 60%;\n}\n\n.rating-filter {\n  margin-left: 5%;\n  height: 2rem;\n}\n.searchBtn {\n  background-color: black;\n  color: white;\n  height: 2rem;\n  margin-left: -4%;\n  border: 2px solid black;\n  border-bottom: 5px solid black;\n}\n\n.filterrationgHeading {\n  margin-left: 70%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
