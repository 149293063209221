import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./BookTransactionModal.module.css";
import { useAuth } from "../Context/AuthContext";

const BookTransactionModal = () => {
  const [transactionData, setTransactionData] = useState(null);
  const [countdown, setCountdown] = useState(8); // 8-second countdown
  const [loading, setLoading] = useState(true); // Loading state
  const navigate = useNavigate();
  const { orderId } = useParams();
  console.log(orderId);
  const { isLoggedIn, user } = useAuth();
  const userId = user?._id; // Safely access userId from user object

  console.log("USER", user);
  console.log("USER_ID", userId);

  const closeModal = () => {
    navigate(-1);
  };

  const redirectToMyBooks = () => {
    navigate(`/purchase-book`);
  };

  useEffect(() => {
    if (orderId) {
      fetch(`https://auth.ssccglpinnacle.com/api/book-transaction/${orderId}`)
        .then((response) => response.json())
        .then((data) => {
          setTransactionData(data);
          setLoading(false); // Stop loading after data is fetched
        })
        .catch((error) => {
          console.error("Error fetching transaction:", error);
          setLoading(false); // Stop loading even if there's an error
        });
    }
  }, [orderId]);

  useEffect(() => {
    if (transactionData?.paymentStatus === "Completed") {
      const timer = setInterval(() => {
        setCountdown((prev) => {
          if (prev === 1) {
            clearInterval(timer);
            redirectToMyBooks(); // Redirect to My Books when countdown ends
          }
          return prev - 1;
        });
      }, 1000); // 1-second interval

      return () => clearInterval(timer); // Cleanup on unmount
    }
  }, [transactionData]);
  useEffect(() => {
    if (transactionData?.paymentStatus === "Pending") {
      const timer = setInterval(() => {
        setCountdown((prev) => {
          if (prev === 1) {
            clearInterval(timer);
            redirectToHome(); // Redirect to My Books when countdown ends
          }
          return prev - 1;
        });
      }, 1000); // 1-second interval

      return () => clearInterval(timer); // Cleanup on unmount
    }
  }, [transactionData]);

  if (loading) {
    return (
      <div className={styles.modalOverlay}>
        <div className={styles.modalContent}>
          <h2>Loading transaction details...</h2>
        </div>
      </div>
    );
  }

  if (!orderId) return null;

  const paymentStatus = transactionData?.paymentStatus;
  const redirectToHome = () => {
    navigate(`/books-catalog`);
  };

  // Determine what to display based on payment status
  const isPaymentSuccessful = paymentStatus === "Completed";
  const isPaymentPending = paymentStatus === "Pending";
  const isPaymentFailed = paymentStatus === "Failed";

  return (
    <div className={styles.modalOverlay} onClick={closeModal}>
      <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
        <div className={styles.iconWrapper}>
          <div
            className={
              isPaymentSuccessful ? styles.successIcon : styles.failedIcon // Use failed icon for pending or failed status
            }
          >
            <span className={styles.checkMark}>
              {isPaymentSuccessful ? "✓" : "✗"}
            </span>
          </div>
        </div>
        <h2 className={styles.statusText}>
          {isPaymentSuccessful
            ? "Payment Successful"
            : isPaymentPending
            ? "Payment Failed"
            : isPaymentFailed
            ? "Payment Failed"
            : ""}
        </h2>
        {transactionData && isPaymentSuccessful ? (
          <>
            <p className={styles.amount}>₹{transactionData.amountPaid}/-</p>
            <p className={styles.accountInfo}>
              Book SKU: {transactionData.bookSKU}
            </p>
            {/* <p className={styles.accountInfo}>
              Reference ID: {transactionData.orderId}
            </p> */}
            <p className={styles.redirectMessage}>
              Redirecting to My Books in {countdown} seconds...
            </p>
          </>
        ) : (
          <p className={styles.failedMessage}>
            {isPaymentPending
              ? "Your payment is Failed. Please try again."
              : "Payment failed. Please try again."}
          </p>
        )}
        {isPaymentSuccessful && (
          <button className={styles.redirectButton} onClick={redirectToMyBooks}>
            Go to My Books
          </button>
        )}
        {!isPaymentSuccessful && (
          <div>
            <p className={styles.redirectMessage}>
              Redirecting to Home in {countdown} seconds...
            </p>
            <div className={styles.spinner}></div>
            <button className={styles.redirectButton} onClick={redirectToHome}>
              Go back to Home
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default BookTransactionModal;
