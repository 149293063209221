// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AddPdfForm_container__vdcmV {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  .AddPdfForm_heading__46t-7 {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
    font-size: 26px;
    font-weight: 800;
    text-align: center;
    margin: 33px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    background-color: #ffff0066;
    padding: 10px;
    /* font-family: cursive; */
  }
  .AddPdfForm_formTitle__DMsVL {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .AddPdfForm_courseSelect__TyX7x, .AddPdfForm_fileInput__etbvC {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .AddPdfForm_uploadButton__uB80i {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .AddPdfForm_uploadButton__uB80i:disabled {
    background-color: #cccccc;
  }
  
  .AddPdfForm_container__vdcmV label {
    display: flex;
    align-items: center;
    margin: 10px 0;
  }
  
  .AddPdfForm_container__vdcmV input[type="checkbox"] {
    margin-right: 10px;
  }
  `, "",{"version":3,"sources":["webpack://./src/VideoCourse_AdminPanel/AddPdfForm.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,yBAAyB;IACzB,kBAAkB;IAClB,wCAAwC;EAC1C;EACA;IACE,kBAAkB;IAClB,eAAe;IACf,mBAAmB;IACnB,eAAe;IACf,gBAAgB;IAChB,kBAAkB;IAClB,YAAY;IACZ,mFAAmF;IACnF,2BAA2B;IAC3B,aAAa;IACb,0BAA0B;EAC5B;EACA;IACE,eAAe;IACf,mBAAmB;IACnB,kBAAkB;EACpB;;EAEA;IACE,WAAW;IACX,aAAa;IACb,cAAc;IACd,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,WAAW;IACX,aAAa;IACb,cAAc;IACd,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,cAAc;EAChB;;EAEA;IACE,kBAAkB;EACpB","sourcesContent":[".container {\n    padding: 20px;\n    background-color: #f9f9f9;\n    border-radius: 8px;\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n  }\n  .heading {\n    text-align: center;\n    font-size: 24px;\n    margin-bottom: 20px;\n    font-size: 26px;\n    font-weight: 800;\n    text-align: center;\n    margin: 33px;\n    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;\n    background-color: #ffff0066;\n    padding: 10px;\n    /* font-family: cursive; */\n  }\n  .formTitle {\n    font-size: 24px;\n    margin-bottom: 20px;\n    text-align: center;\n  }\n  \n  .courseSelect, .fileInput {\n    width: 100%;\n    padding: 10px;\n    margin: 10px 0;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n  }\n  \n  .uploadButton {\n    width: 100%;\n    padding: 10px;\n    margin: 10px 0;\n    background-color: #007bff;\n    color: white;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n  }\n  \n  .uploadButton:disabled {\n    background-color: #cccccc;\n  }\n  \n  .container label {\n    display: flex;\n    align-items: center;\n    margin: 10px 0;\n  }\n  \n  .container input[type=\"checkbox\"] {\n    margin-right: 10px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `AddPdfForm_container__vdcmV`,
	"heading": `AddPdfForm_heading__46t-7`,
	"formTitle": `AddPdfForm_formTitle__DMsVL`,
	"courseSelect": `AddPdfForm_courseSelect__TyX7x`,
	"fileInput": `AddPdfForm_fileInput__etbvC`,
	"uploadButton": `AddPdfForm_uploadButton__uB80i`
};
export default ___CSS_LOADER_EXPORT___;
