import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import styles from "./Cart.module.css";
import Header from "../../HomePage01/Header/Header";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../Context/AuthContext";
import Footer from "../../Footer/Footer";
import VideoBuyButton from "./VideosBuyButton";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

function CartPage() {
  const [courseDetails, setCourseDetails] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [addToWishlist, setIsAddedToWishlist] = useState(false);
  const [recommendedCourses, setRecommendedCourses] = useState([]);
  const navigate = useNavigate();
  const scrollRef = useRef(null);
  const { user, token } = useAuth();
  const userId = user?._id;

  // console.log("my user data", user);

  useEffect(() => {
    const fetchData = async () => {
      // Check if user data is available
      if (!userId || !token) {
        setError("User authentication failed. Please log in again.");
        setLoading(false);
        return;
      }

      try {
        setLoading(true);
        // Fetch cart data and recommended courses simultaneously
        const [cartResponse, recommendedResponse] = await Promise.all([
          axios.get(`https://auth.ssccglpinnacle.com/api/cart/${userId}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
          axios.get(`https://auth.ssccglpinnacle.com/api/recent-courses`),
        ]);

        // Handle cart data
        if (
          cartResponse.status === 200 &&
          Array.isArray(cartResponse.data) &&
          cartResponse.data.length > 0
        ) {
          // If cart data exists, set it to state
          setCourseDetails(cartResponse.data);
          calculateTotalPrice(cartResponse.data);
        } else {
          // If no cart data exists, handle empty cart case
          setCourseDetails([]);
          calculateTotalPrice([]);
        }

        // Handle recommended courses
        if (
          recommendedResponse.status === 200 &&
          Array.isArray(recommendedResponse.data)
        ) {
          setRecommendedCourses(recommendedResponse.data);
        } else {
          console.error("Failed to fetch recommended courses.");
        }
      } catch (error) {
        console.error("Failed to fetch data:", error);
        setError("Failed to load data. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    // Fetch the data if userId and token are available
    if (userId && token) {
      fetchData();
    }
  }, [userId, token]);

  const calculateTotalPrice = (courses) => {
    const total = courses.reduce((acc, course) => {
      if (course && course.price !== undefined && course.price !== null) {
        return acc + course.price;
      }
      return acc;
    }, 0);
    setTotalPrice(Number(total.toFixed(2)));
  };

  const handleRemove = async (courseId) => {
    try {
      await axios.delete(
        `https://auth.ssccglpinnacle.com/api/cart/${userId}/${courseId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const updatedCourses = courseDetails.filter(
        (course) => course.courseId !== courseId
      );
      setCourseDetails(updatedCourses);
      calculateTotalPrice(updatedCourses);
    } catch (err) {
      console.error("Failed to remove course from cart:", err);
      Swal.fire({
        title: "Error",
        text: "Failed to remove course from cart.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
    window.location.reload();
  };

  const wishlistHandler = async (courseId) => {
    if (!userId) {
      return;
    }

    try {
      const response = await fetch(
        `https://auth.ssccglpinnacle.com/api/wishlist/${userId}/${courseId}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      if (response.status === 200 && data.info) {
        Swal.fire({
          title: "Info",
          text: data.info,
          icon: "info",
          confirmButtonText: "OK",
        });
      } else if (response.ok) {
        setIsAddedToWishlist(true);
        Swal.fire({
          title: "Success!",
          text: "Added to Wishlist",
          icon: "success",
          confirmButtonText: "OK",
        });

        handleRemove(courseId);
      } else {
        Swal.fire({
          title: "Failed",
          text: data.error || "Failed to add course to wishlist",
          icon: "error",
          confirmButtonText: "OK",
        });
        console.error("Failed to add course to wishlist", data);
      }
    } catch (error) {
      console.error("Error adding course to wishlist:", error);
    }
  };

  const addToCartHandler = async (course) => {
    if (!userId) {
      return;
    }

    if (!course._id) {
      console.error("Course ID is undefined");
      Swal.fire({
        title: "Error",
        text: "Invalid course. Please try again.",
        icon: "error",
        confirmButtonText: "OK",
      });
      return;
    }

    try {
      const response = await fetch(
        `https://auth.ssccglpinnacle.com/api/cart/${userId}/${course._id}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = await response.json();

      if (response.status === 200 && data.info) {
        Swal.fire({
          title: "Info",
          text: data.info,
          icon: "info",
          confirmButtonText: "OK",
        });
      } else if (response.ok) {
        const updatedCourse = { ...course, courseId: course._id || course._id };
        const updatedCourses = [...courseDetails, updatedCourse];
        setCourseDetails(updatedCourses);
        calculateTotalPrice(updatedCourses);
        Swal.fire({
          title: "Success!",
          text: "Added to Cart",
          icon: "success",
          confirmButtonText: "OK",
        });
      } else {
        Swal.fire({
          title: "Failed",
          text: data.error || "Failed to add course to cart",
          icon: "error",
          confirmButtonText: "OK",
        });
        console.error("Failed to add course to cart", data);
      }
    } catch (error) {
      console.error("Error adding course to cart:", error);
      Swal.fire({
        title: "Error",
        text: "An error occurred while adding the course to the cart",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  const handleButtonClick = () => {
    navigate("/videos");
  };

  if (loading) {
    return (
      <div className={styles["loading-div"]}>
        <img
          src="https://d3m4h509gttb94.cloudfront.net/Image/Bubble-Preloader.gif"
          alt="loading"
        />
      </div>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  const validProductIds = courseDetails
    .map((course) => course.courseId)
    .filter((id) => id !== undefined);

  const scroll = (direction) => {
    if (direction === "left") {
      scrollRef.current.scrollLeft -= 300;
    } else {
      scrollRef.current.scrollLeft += 300;
    }
  };
  return (
    <>
      <Header />
      <div className={styles.cartContainer}>
        <h2 className={styles.cartHeader}>Shopping Cart</h2>
        <p className={styles.cartSubHeader}>
          {courseDetails.length} Courses in Cart
        </p>
        {courseDetails.length === 0 ? (
          <div className={styles.emptyCart}>
            <img
              src="/shoppingImage.webp"
              alt="Empty Cart"
              className={styles.emptyCartImage}
            />
            <p className={styles.emptyCartPara}>
              Your cart is empty. Keep shopping to find a course!
            </p>
            <button
              className={styles.keepShoppingButton}
              onClick={handleButtonClick}
            >
              Keep shopping
            </button>
          </div>
        ) : (
          <div className={styles.mainContent}>
            <div className={styles.courseContainer}>
              {courseDetails.map((course) => (
                <div key={course.courseId} className={styles.courseCard}>
                  <div className={styles.courseImageContainer}>
                    <img
                      src={course.hindiCoverImage}
                      alt="Hindi Cover"
                      className={styles.coverImage}
                    />
                    <img
                      src={course.englishCoverImage}
                      alt="English Cover"
                      className={styles.coverImage}
                    />
                  </div>
                  <div className={styles.courseDetails}>
                    <h3 className={styles.courseTitle}>{course.courseTitle}</h3>
                    <p className={styles.courseInstructor}>
                      By {course.instructorName}
                    </p>
                    <div className={styles.courseMeta}>
                      <span className={styles.rating}>{course.rating} ★</span>
                      <span>({course.reviewCount} ratings)</span>
                    </div>
                    <div className={styles.courseActions}>
                      <button
                        onClick={() => handleRemove(course.courseId)}
                        className={styles.removeLink}
                      >
                        Remove
                      </button>
                      <a
                        href="#"
                        className={styles.wishlistLink}
                        onClick={(e) => {
                          e.preventDefault();
                          wishlistHandler(course.courseId);
                        }}
                      >
                        Move to Wishlist
                      </a>
                    </div>
                  </div>
                  <div className={styles.coursePrice}>
                    <p>₹{course.price.toFixed(2)}</p>
                    <p className={styles.oldPrice}>₹{course.mrp.toFixed(2)}</p>
                  </div>
                </div>
              ))}
            </div>
            <div className={styles.checkoutSection}>
              <div className={styles.totalSection}>
                <h3>Total:</h3>
                <p className={styles.totalPrice}>₹{totalPrice}</p>
                <p className={styles.duration}>Duration: 12 Months</p>
                <p className={styles.line1}>18% GST has been included.</p>
                <p className={styles.line2}>
                  Your cart does not require shipping.
                </p>
                <VideoBuyButton
                  productId={validProductIds}
                  userDetails={user}
                  buttonText="Proceed To Pay"
                  duration="12 Months"
                />
              </div>
            </div>
          </div>
        )}
        <div className={styles.recommendationSection}>
          <h3 className={styles.recommendationSectionheader}>
            You might also like
          </h3>
          <div className={styles.scrollContainer}>
            <button
              className={styles.scrollButton}
              onClick={() => scroll("left")}
            >
              <FaChevronLeft />
            </button>
            <div className={styles.recommendationContainer} ref={scrollRef}>
              {recommendedCourses.map((course) => (
                <div key={course._id} className={styles.recommendationCard}>
                  <div className={styles.recommendationImageContainer}>
                    <img
                      src={course.hindiCoverImage}
                      alt={course.title}
                      className={styles.recommendationImage}
                    />
                    <img
                      src={course.englishCoverImage}
                      alt={course.title}
                      className={styles.recommendationImage}
                    />
                  </div>
                  <div className={styles.recommendationDetails}>
                    <h4 className={styles.recommendationTitle}>
                      {course.courseTitle}
                    </h4>
                    <p className={styles.recommendationInstructor}>
                      By {course.instructorName}
                    </p>
                    <div className={styles.recommendationMeta}>
                      <span className={styles.rating}>{course.rating} ★</span>
                      <span>({course.reviewCount} ratings)</span>
                    </div>
                    <div className={styles.recommendationPriceSection}>
                      <p className={styles.recommendationPrice}>
                        ₹{course.price}
                      </p>
                      <p className={styles.recommendationOldPrice}>
                        ₹{course.mrp}
                      </p>
                    </div>
                    <button
                      className={styles.addToCartButton}
                      onClick={() => addToCartHandler(course)}
                    >
                      Add to cart
                    </button>
                  </div>
                </div>
              ))}
            </div>
            <button
              className={styles.scrollButton}
              onClick={() => scroll("right")}
            >
              <FaChevronRight />
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default CartPage;

// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import Swal from "sweetalert2";
// import styles from "./Cart.module.css";
// import Header from "../../HomePage01/Header/Header";
// import { useNavigate } from "react-router-dom";
// import { useAuth } from "../../../Context/AuthContext";
// import Footer from "../../Footer/Footer";
// import VideoBuyButton from "./VideosBuyButton";

// function CartPage() {
//   const [courseDetails, setCourseDetails] = useState([]);
//   const [totalPrice, setTotalPrice] = useState(0);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState("");
//   const [recommendedCourses, setRecommendedCourses] = useState([]);
//   const navigate = useNavigate();

//   const { user, token } = useAuth();
//   const userId = user?._id;

//   useEffect(() => {
//     // Only proceed if userId and token are available
//     if (!userId || !token) {
//       setError("User ID or token is missing");
//       setLoading(false);
//       return;
//     }

//     const fetchCartData = async () => {
//       try {
//         const response = await axios.get(`https://auth.ssccglpinnacle.com/api/cart/${userId}`, {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         });

//         if (response.status === 200 && Array.isArray(response.data) && response.data.length > 0) {
//           setCourseDetails(response.data);
//           calculateTotalPrice(response.data);
//         } else {
//           setCourseDetails([]);
//           calculateTotalPrice([]);
//         }
//       } catch (error) {
//         console.error("Failed to fetch cart data:", error);
//         setError("Failed to load cart data. Please try again.");
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchCartData();
//   }, [userId, token]);

//   const calculateTotalPrice = (courses) => {
//     const total = courses.reduce((acc, course) => {
//       if (course && course.price !== undefined && course.price !== null) {
//         return acc + course.price;
//       }
//       return acc;
//     }, 0);
//     setTotalPrice(Number(total.toFixed(2)));
//   };

//   const handleRemove = async (courseId) => {
//     try {
//       await axios.delete(`https://auth.ssccglpinnacle.com/api/cart/${userId}/${courseId}`, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       });

//       const updatedCourses = courseDetails.filter((course) => course.courseId !== courseId);
//       setCourseDetails(updatedCourses);
//       calculateTotalPrice(updatedCourses);
//     } catch (err) {
//       console.error("Failed to remove course from cart:", err);
//       Swal.fire({
//         title: "Error",
//         text: "Failed to remove course from cart.",
//         icon: "error",
//         confirmButtonText: "OK",
//       });
//     }
//   };

//   const handleButtonClick = () => {
//     navigate("/videos");
//   };

//   if (!userId || !token) {
//     return (
//       <div className={styles["error-message"]}>
//         <p>User ID or token is missing. Please log in again.</p>
//       </div>
//     );
//   }

//   if (loading) {
//     return (
//       <div className={styles["loading-div"]}>
//         <img src="https://d3m4h509gttb94.cloudfront.net/Image/loading-walk.gif" alt="Loading..." />
//       </div>
//     );
//   }

//   if (error) {
//     return <div>{error}</div>;
//   }

//   const validProductIds = courseDetails.map((course) => course.courseId).filter((id) => id !== undefined);

//   return (
//     <>
//       <Header />
//       <div className={styles.cartContainer}>
//         <h2 className={styles.cartHeader}>Shopping Cart</h2>
//         <p className={styles.cartSubHeader}>{courseDetails.length} Courses in Cart</p>
//         {courseDetails.length === 0 ? (
//           <div className={styles.emptyCart}>
//             <img src="/shoppingImage.webp" alt="Empty Cart" className={styles.emptyCartImage} />
//             <p className={styles.emptyCartPara}>Your cart is empty. Keep shopping to find a course!</p>
//             <button className={styles.keepShoppingButton} onClick={handleButtonClick}>
//               Keep shopping
//             </button>
//           </div>
//         ) : (
//           <div className={styles.mainContent}>
//             <div className={styles.courseContainer}>
//               {courseDetails.map((course) => (
//                 <div key={course.courseId} className={styles.courseCard}>
//                   <div className={styles.courseImageContainer}>
//                     <img src={course.hindiCoverImage} alt="Hindi Cover" className={styles.coverImage} />
//                     <img src={course.englishCoverImage} alt="English Cover" className={styles.coverImage} />
//                   </div>
//                   <div className={styles.courseDetails}>
//                     <h3 className={styles.courseTitle}>{course.courseTitle}</h3>
//                     <p className={styles.courseInstructor}>By {course.instructorName}</p>
//                     <div className={styles.courseMeta}>
//                       <span className={styles.rating}>{course.rating} ★</span>
//                       <span>({course.reviewCount} ratings)</span>
//                     </div>
//                     <div className={styles.courseActions}>
//                       <button onClick={() => handleRemove(course.courseId)} className={styles.removeLink}>
//                         Remove
//                       </button>
//                     </div>
//                   </div>
//                   <div className={styles.coursePrice}>
//                     <p>₹{course.price.toFixed(2)}</p>
//                     <p className={styles.oldPrice}>₹{course.mrp.toFixed(2)}</p>
//                   </div>
//                 </div>
//               ))}
//             </div>
//             <div className={styles.checkoutSection}>
//               <div className={styles.totalSection}>
//                 <h3>Total:</h3>
//                 <p className={styles.totalPrice}>₹{totalPrice}</p>
//                 <p className={styles.duration}>Duration: 12 Months</p>
//                 <p className={styles.line1}>Tax calculated at checkout</p>
//                 <p className={styles.line2}>Your cart does not require shipping.</p>
//                 <VideoBuyButton
//                   productId={validProductIds}
//                   userDetails={user}
//                   buttonText="Checkout"
//                   duration="12 Months"
//                 />
//               </div>
//             </div>
//           </div>
//         )}
//         <div className={styles.recommendationSection}>
//           <h3 className={styles.recommendationSectionheader}>You might also like</h3>
//           <div className={styles.recommendationContainer}>
//             {/* Recommendations can be added here */}
//           </div>
//         </div>
//       </div>
//       <Footer />
//     </>
//   );
// }

// export default CartPage;
