// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* HTML: <div class="loader"></div> */
.loader {
  width: 40px;
  aspect-ratio: 1;
  border-radius: 50%;
  background:
    radial-gradient(farthest-side,#ED303C 94%,#0000),
    radial-gradient(farthest-side,#3B8183 94%,#0000),
    radial-gradient(farthest-side,#FAD089 94%,#0000),
    radial-gradient(farthest-side,#FF9C5B 94%,#0000),
    #ED303C;
  background-size: 105% 105%;
  background-repeat: no-repeat;
  animation: l5 2s infinite; 
}
@keyframes l5 {
  0%  {background-position: 50% -50px,-40px 50%, 50% calc(100% + 50px),calc(100% + 50px) 50%}
  20%,
  25% {background-position: 50% -50px,-50px 50%, 50% calc(100% + 50px),50% 50%}
  45%,
  50% {background-position: 50% -50px,-50px 50%, 50% 50%              ,50% 50%}
  75%,
  75% {background-position: 50% -50px, 50%  50%, 50% 50%              ,50% 50%}
  95%,
  100%{background-position: 50%  50% , 50%  50%, 50% 50%              ,50% 50%}
}`, "",{"version":3,"sources":["webpack://./src/Ebook/LoadingSpinner.css"],"names":[],"mappings":"AAAA,qCAAqC;AACrC;EACE,WAAW;EACX,eAAe;EACf,kBAAkB;EAClB;;;;;WAKS;EACT,0BAA0B;EAC1B,4BAA4B;EAC5B,yBAAyB;AAC3B;AACA;EACE,KAAK,qFAAqF;EAC1F;OACK,uEAAuE;EAC5E;OACK,uEAAuE;EAC5E;OACK,uEAAuE;EAC5E;OACK,uEAAuE;AAC9E","sourcesContent":["/* HTML: <div class=\"loader\"></div> */\n.loader {\n  width: 40px;\n  aspect-ratio: 1;\n  border-radius: 50%;\n  background:\n    radial-gradient(farthest-side,#ED303C 94%,#0000),\n    radial-gradient(farthest-side,#3B8183 94%,#0000),\n    radial-gradient(farthest-side,#FAD089 94%,#0000),\n    radial-gradient(farthest-side,#FF9C5B 94%,#0000),\n    #ED303C;\n  background-size: 105% 105%;\n  background-repeat: no-repeat;\n  animation: l5 2s infinite; \n}\n@keyframes l5 {\n  0%  {background-position: 50% -50px,-40px 50%, 50% calc(100% + 50px),calc(100% + 50px) 50%}\n  20%,\n  25% {background-position: 50% -50px,-50px 50%, 50% calc(100% + 50px),50% 50%}\n  45%,\n  50% {background-position: 50% -50px,-50px 50%, 50% 50%              ,50% 50%}\n  75%,\n  75% {background-position: 50% -50px, 50%  50%, 50% 50%              ,50% 50%}\n  95%,\n  100%{background-position: 50%  50% , 50%  50%, 50% 50%              ,50% 50%}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
