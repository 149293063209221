// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AccountSecurity_enable_btn__qhkOm {
  width: 3rem;
  height: 2rem;
  background-color: black;
}
.AccountSecurity_account_container__-\\+c2F {
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  /* margin: 1%; */
  margin-left: 3%;
  margin-top: 8.5%;
}
.AccountSecurity_heading_para__8FwV- {
  padding-left: 20%;
  padding-top: 1%;
}
.AccountSecurity_account_heading__9KVlc {
  padding-left: 40%;
  padding-top: 1%;
}
.AccountSecurity_email_container__KJ1zw {
  padding: 2% 0% 2% 10%;
}
.AccountSecurity_email_input__tJmbG {
  padding: 1%;
  width: 78%;
}
.AccountSecurity_new_password_input__8bsq9 {
  padding: 1%;
  margin-top: 4%;
  width: 20%;
}
.AccountSecurity_new_password_input__8bsq9 {
  padding: 1%;
  width: 78%;
  margin-top: 4%;
}
.AccountSecurity_new_password_input__8bsq9 {
  padding: 1%;
  width: 78%;
  margin-top: 3%;
}
.AccountSecurity_password_input__rW2Fh {
  padding: 1%;
  width: 78%;
  margin-top: 3%;
}
.AccountSecurity_email_btn__I-ARA {
  width: 3rem;
  height: 2.1rem;
  background-color: black;
  margin-left: 1%;
  margin-top: 2%;
}
.AccountSecurity_password_container__lsp3U {
  margin-left: 10%;
}
.AccountSecurity_MFA_container__NpEp5 {
  padding: 4%;
}
.AccountSecurity_MFA_para__IXyMC {
  font-size: 14px;
  margin-top: 2%;
}
.AccountSecurity_enable_btn__qhkOm {
  margin-top: 2%;
}
`, "",{"version":3,"sources":["webpack://./src/VIDEOPLAYER COMPONENTS/components/VideoPlayerPage/AccountPage/AccountSecurity.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,uBAAuB;AACzB;AACA;EACE;0CACwC;EACxC,gBAAgB;EAChB,eAAe;EACf,gBAAgB;AAClB;AACA;EACE,iBAAiB;EACjB,eAAe;AACjB;AACA;EACE,iBAAiB;EACjB,eAAe;AACjB;AACA;EACE,qBAAqB;AACvB;AACA;EACE,WAAW;EACX,UAAU;AACZ;AACA;EACE,WAAW;EACX,cAAc;EACd,UAAU;AACZ;AACA;EACE,WAAW;EACX,UAAU;EACV,cAAc;AAChB;AACA;EACE,WAAW;EACX,UAAU;EACV,cAAc;AAChB;AACA;EACE,WAAW;EACX,UAAU;EACV,cAAc;AAChB;AACA;EACE,WAAW;EACX,cAAc;EACd,uBAAuB;EACvB,eAAe;EACf,cAAc;AAChB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,WAAW;AACb;AACA;EACE,eAAe;EACf,cAAc;AAChB;AACA;EACE,cAAc;AAChB","sourcesContent":[".enable_btn {\n  width: 3rem;\n  height: 2rem;\n  background-color: black;\n}\n.account_container {\n  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,\n    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;\n  /* margin: 1%; */\n  margin-left: 3%;\n  margin-top: 8.5%;\n}\n.heading_para {\n  padding-left: 20%;\n  padding-top: 1%;\n}\n.account_heading {\n  padding-left: 40%;\n  padding-top: 1%;\n}\n.email_container {\n  padding: 2% 0% 2% 10%;\n}\n.email_input {\n  padding: 1%;\n  width: 78%;\n}\n.new_password_input {\n  padding: 1%;\n  margin-top: 4%;\n  width: 20%;\n}\n.new_password_input {\n  padding: 1%;\n  width: 78%;\n  margin-top: 4%;\n}\n.new_password_input {\n  padding: 1%;\n  width: 78%;\n  margin-top: 3%;\n}\n.password_input {\n  padding: 1%;\n  width: 78%;\n  margin-top: 3%;\n}\n.email_btn {\n  width: 3rem;\n  height: 2.1rem;\n  background-color: black;\n  margin-left: 1%;\n  margin-top: 2%;\n}\n.password_container {\n  margin-left: 10%;\n}\n.MFA_container {\n  padding: 4%;\n}\n.MFA_para {\n  font-size: 14px;\n  margin-top: 2%;\n}\n.enable_btn {\n  margin-top: 2%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"enable_btn": `AccountSecurity_enable_btn__qhkOm`,
	"account_container": `AccountSecurity_account_container__-+c2F`,
	"heading_para": `AccountSecurity_heading_para__8FwV-`,
	"account_heading": `AccountSecurity_account_heading__9KVlc`,
	"email_container": `AccountSecurity_email_container__KJ1zw`,
	"email_input": `AccountSecurity_email_input__tJmbG`,
	"new_password_input": `AccountSecurity_new_password_input__8bsq9`,
	"password_input": `AccountSecurity_password_input__rW2Fh`,
	"email_btn": `AccountSecurity_email_btn__I-ARA`,
	"password_container": `AccountSecurity_password_container__lsp3U`,
	"MFA_container": `AccountSecurity_MFA_container__NpEp5`,
	"MFA_para": `AccountSecurity_MFA_para__IXyMC`
};
export default ___CSS_LOADER_EXPORT___;
