// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ListExams_list-container__vo\\+vS {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .ListExams_exam-list__R7JqM {
    list-style-type: none;
    padding: 0;
  }
  
  .ListExams_exam-item__wtujO {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f9f9f9;
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 4px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  }
  
  .ListExams_edit-btn__\\+Gp6h,
  .ListExams_delete-btn__BCjjJ {
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 10px;
  }
  
  .ListExams_edit-btn__\\+Gp6h {
    background-color: #28a745;
    color: white;
  }
  
  .ListExams_delete-btn__BCjjJ {
    background-color: #dc3545;
    color: white;
  }
  
  .ListExams_edit-btn__\\+Gp6h:hover {
    background-color: #218838;
  }
  
  .ListExams_delete-btn__BCjjJ:hover {
    background-color: #c82333;
  }
  `, "",{"version":3,"sources":["webpack://./src/VideoCourse_AdminPanel/ListExams.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,cAAc;EAChB;;EAEA;IACE,qBAAqB;IACrB,UAAU;EACZ;;EAEA;IACE,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,yBAAyB;IACzB,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,sCAAsC;EACxC;;EAEA;;IAEE,iBAAiB;IACjB,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,iBAAiB;EACnB;;EAEA;IACE,yBAAyB;IACzB,YAAY;EACd;;EAEA;IACE,yBAAyB;IACzB,YAAY;EACd;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":[".list-container {\n    max-width: 800px;\n    margin: 0 auto;\n  }\n  \n  .exam-list {\n    list-style-type: none;\n    padding: 0;\n  }\n  \n  .exam-item {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    background-color: #f9f9f9;\n    padding: 15px;\n    margin-bottom: 10px;\n    border-radius: 4px;\n    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);\n  }\n  \n  .edit-btn,\n  .delete-btn {\n    padding: 8px 12px;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n    margin-left: 10px;\n  }\n  \n  .edit-btn {\n    background-color: #28a745;\n    color: white;\n  }\n  \n  .delete-btn {\n    background-color: #dc3545;\n    color: white;\n  }\n  \n  .edit-btn:hover {\n    background-color: #218838;\n  }\n  \n  .delete-btn:hover {\n    background-color: #c82333;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list-container": `ListExams_list-container__vo+vS`,
	"exam-list": `ListExams_exam-list__R7JqM`,
	"exam-item": `ListExams_exam-item__wtujO`,
	"edit-btn": `ListExams_edit-btn__+Gp6h`,
	"delete-btn": `ListExams_delete-btn__BCjjJ`
};
export default ___CSS_LOADER_EXPORT___;
