import React, { createContext, useContext, useState, useEffect } from "react";
import { useCookies } from "react-cookie";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [cookies, setCookie, removeCookie] = useCookies(["token", "email_id"]);
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(cookies.token);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [error, setError] = useState(null);
  const [accessTypingStatus, setAccessTypingStatus] = useState(null);

  // const login = (username, token) => {
  //   setUser(username);
  //   setToken(token);
  //   setIsLoggedIn(true);
  // };
  // console.log("username", user);
  // console.log("token", token);
  // console.log("cookies", cookies);

  useEffect(() => {
    const fetchUser = async () => {
        try {
            if (cookies.token) {
                const response = await fetch(
                    "https://auth.ssccglpinnacle.com/api/user-ebook", 
                    {
                        headers: {
                            Authorization: `Bearer ${cookies.token}`,
                        },
                    }
                );

                if (response.ok) {
                    const userData = await response.json();
                    setUser(userData);
                    setIsLoggedIn(true); // Token is valid, set isLoggedIn true
                } else {
                    setIsLoggedIn(false); // Token is invalid, set isLoggedIn false
                    setError("Failed to fetch ebook user data");
                }
            } else {
                setIsLoggedIn(false); // No token found, set isLoggedIn false
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
            setIsLoggedIn(false); // Error means token validation failed
            setError("An error occurred while fetching user data");
        }
    };

    fetchUser();
}, [cookies.token]);

  

  const logout = async () => {
    try {
      const logoutRequests = [
        fetch("https://auth.ssccglpinnacle.com/api/logout-ebook", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ id: user.id }),
        }),
        fetch("https://auth.ssccglpinnacle.com/api/logout-videocourse", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ id: user.id }),
        }),
      ];

      const responses = await Promise.all(logoutRequests);

      responses.forEach(async (response, index) => {
        if (!response.ok) {
          const errorResponse = await response.json();
          throw new Error(
            errorResponse.error ||
              `Failed to logout from ${index === 0 ? "ebook" : "video course"}`
          );
        }
      });

      // Remove cookies from the specified domain and path
      removeCookie("token", { path: "/", domain: "ssccglpinnacle.com" });
      removeCookie("userData", { path: "/", domain: "ssccglpinnacle.com" });

      setUser(null);
      setToken(null);
      setIsLoggedIn(false);

      // console.log("Logout successful from both ebook and video course");
    } catch (error) {
      // console.error("Logout failed:", error);
    }
  };

  const updateAccessStatus = async (token) => {
    try {
      const response = await fetch(
        "https://auth.ssccglpinnacle.com/api/user-videocourse",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update video course access status");
      }
    } catch (error) {
      // console.error("Error updating video course access status:", error);
      setError("An error occurred while updating access status");
    }
  };

  const checkAccessTyping = async () => {
    try {
      const response = await fetch(
        "https://auth.ssccglpinnacle.com/api/checkAccessTyping-portal",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setAccessTypingStatus(data.AccessTyping);
      } else {
        // console.error("Failed to check Access Typing status");
      }
    } catch (error) {
      // console.error("Error checking Access Typing status:", error);
    }
  };

  useEffect(() => {
    const fetchUser = async () => {
      try {
        if (cookies.token) {
          const response = await fetch(
            "https://auth.ssccglpinnacle.com/api/user-ebook",
            {
              headers: {
                Authorization: `Bearer ${cookies.token}`,
              },
            }
          );

          if (response.ok) {
            const userData = await response.json();
            setUser(userData);
          } else {
            setError("Failed to fetch ebook user data");
          }
        }
      } catch (error) {
        // console.error("Error fetching user data:", error);
        setError("An error occurred while fetching user data");
      }
    };

    fetchUser();
  }, [cookies.token]);

  useEffect(() => {
    if (token) {
      updateAccessStatus(token);
      checkAccessTyping();
    }
  }, [token]);

  useEffect(() => {
    if (user) {
      // console.log("Ebook user data fetched successfully:", user);
    } else {
      // console.log("No ebook user data available");
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      // console.log("Video user data fetched successfully:", user);
    } else {
      // console.log("No video user data available");
    }
  }, [user]);

  return (
    <AuthContext.Provider
      value={{
        user,
        token,
        // login,
        logout,
        isLoggedIn,
        error,
        accessTypingStatus,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
