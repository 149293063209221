import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useCookies } from "react-cookie";
import SidebarEbook from "./SidebarEbook";
import AdminHeader from "./AdminHeader";
import EbookNewForm from "../Forms/EbookNewForm";
import EditEbook from "../Forms/EditEbook";
import ChapterForm from "../Forms/ChapterForm/ChapterForm";
import ChapterEdit from "../Forms/ChapterEdit/ChapterEdit";
import ChapterEditWithPDFManagement from "../Forms/ChapterEdit/ChapterEditWithPDFManagement";
import AddPDFForm from "../Forms/AddPDFForm";
import UpdatePDFForm from "../Forms/UpdatePDFForm";
import EbookList from "../Forms/EbookList";
import ReorderEbooks from "../Forms/Reorder/ReorderEbooks";
import BookNewForm from "../../FormsBook/BookNewFormBook";
import MultiPDFUploadForm from "../Forms/MultiPDFUploadForm";

import EditBook from "../../FormsBook/EditBook";
import BookList from "../../FormsBook/BookList";
import BlogForm from "../Forms/BlogForm";
import StudentAccessEbookForm from "../Forms/StudentAccessEbookForm";
import "./AdminEbook.css";
import BulkTypingAccessForm from "../Forms/BulkTypingAccessForm"
import EditedBlog from "../Forms/EditedBlog";
import AddTypingParagraph from "../Forms/AddTypingParagraph";
import EditTypingParagraph from "../Forms/EditTypingParagraph";
import AddCourseForm from "../../VideoCourse_AdminPanel/AddCourseForm";
import ManagePDFs from "../../VideoCourse_AdminPanel/ManagePdfListing";
import ManageCourseList from "../../VideoCourse_AdminPanel/ManageCourseList";
import AddCourseChapters from "../../VideoCourse_AdminPanel/AddCourseChapters";
import AddVideoPdfForm from "../../VideoCourse_AdminPanel/AddPdfForm";
import NewStudentAccessForm from "../Forms/NewStudentAccessForm";
import AddAnnouncementForm from "../../VideoCourse_AdminPanel/AddAnnouncementForm";
import ManageChapter from "../../VideoCourse_AdminPanel/ManageChapter";
import ManageInstructorList from "../../VideoCourse_AdminPanel/ManageInstructorListing";
import VideoCourseAccess from "../../VideoCourse_AdminPanel/VideoCourseAccess";
import AddInstructorForm from "../../VideoCourse_AdminPanel/AddInstructorForm";
import Category from "../../VideoCourse_AdminPanel/Category";
import AnnouncementList from "../../VideoCourse_AdminPanel/AnnouncementList";
import ReorderBooks from "../../FormsBook/ReorderBooks";
import StudentTable from "../Forms/Student/StudentTable";
import VideoBanner from "../../VideoCourse_AdminPanel/VideoBanner";
import VideoBannerList from "../../VideoCourse_AdminPanel/ManageBannerList";
import ListExams from "../../VideoCourse_AdminPanel/ListExam";
import AddExamForm from "../../VideoCourse_AdminPanel/AddExamForm";

const AdminEbook = () => {
  const [activeComponent, setActiveComponent] = useState(
    localStorage.getItem("activeComponent") || "EbookNewForm"
  );
  const [sidebarVisible, setSidebarVisible] = useState(true);
  const navigate = useNavigate();
  const [cookies] = useCookies(["myadmin"]);

  useEffect(() => {
    const checkAccess = async () => {
      try {
        const tokenAdmin = cookies.myadmin;
        if (!tokenAdmin) {
          throw new Error("No token found");
        }
        const response = await fetch(
          "https://auth.ssccglpinnacle.com/api/checkaccessadmin",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${tokenAdmin}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Access denied");
        }

        const result = await response.json();
        if (result.message !== "Access granted") {
          navigate("/login-admin-ebook");
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Access Denied",
          text: "You do not have permission to access this page.",
        });
        navigate("/login-admin-ebook");
      }
    };

    checkAccess();
  }, [cookies, navigate]);

  useEffect(() => {
    localStorage.setItem("activeComponent", activeComponent);
  }, [activeComponent]);

  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  const renderComponent = () => {
    switch (activeComponent) {
      case "EbookNewForm":
        return <EbookNewForm />;
      case "EditEbook":
        return <EditEbook />;
      case "EbookList":
        return <EbookList />;
      case "ChapterEdit":
        return <ChapterEdit />;
      case "ChapterForm":
        return <ChapterForm />;
      case "ReorderEbooks":
        return <ReorderEbooks />;
      case "AddPDFForm":
        return <AddPDFForm />;
      case "UpdatePDFForm":
        return <UpdatePDFForm />;
      case "BookNewForm":
        return <BookNewForm />;
      case "EditBook":
        return <EditBook />;
      case "BookList":
        return <BookList />;
      case "ReorderBooks":
        return <ReorderBooks />;
      case "BlogForm":
        return <BlogForm />;
      case "EditedBlog":
        return <EditedBlog />;
      case "AddTypingParagraph":
        return <AddTypingParagraph />;
      case "EditTypingParagraph":
        return <EditTypingParagraph />;
        case "BulkTypingAccessForm":
          return <BulkTypingAccessForm />;
      case "NewStudentAccessForm":
        return <NewStudentAccessForm />;
      case "StudentAccessEbookForm":
        return <StudentAccessEbookForm />;
        case "ChapterEditWithPDFManagement":
          return <ChapterEditWithPDFManagement />;
      case "MultiPDFUploadForm":
        return <MultiPDFUploadForm />;
      case "AddCourseForm":
        return <AddCourseForm />;
      case "ManageCourseList":
        return <ManageCourseList />;
      case "AddPdfForm":
        return <AddVideoPdfForm />;
      case "ManagePDFs":
        return <ManagePDFs />;
      case "AddCourseChapters":
        return <AddCourseChapters />;
      case "ManageChapter":
        return <ManageChapter />;
      case "AddInstructorForm":
        return <AddInstructorForm />;
      case "ManageInstructorListing":
        return <ManageInstructorList />;
      case "AddAnnouncementForm":
        return <AddAnnouncementForm />;
      case "AnnouncementList":
        return <AnnouncementList />;
      case "Category":
        return <Category />;
      case "Banner":
        return <VideoBanner />;
      case "BannerList":
        return <VideoBannerList />;
      case "VideoCourseAccess":
        return <VideoCourseAccess />;
      case "StudentTable":
        return <StudentTable />;
      case "Add Exam":
        return <AddExamForm />;
      case "Exam List":
        return <ListExams />;

      default:
        return <EbookNewForm />;
    }
  };

  return (
    <div className="admin-ebook">
      <AdminHeader
        toggleSidebar={toggleSidebar}
        sidebarVisible={sidebarVisible}
      />
      <div
        className={`admin-ebook__container ${
          sidebarVisible ? "" : "sidebar-hidden"
        }`}
      >
        {sidebarVisible && <SidebarEbook setComponent={setActiveComponent} />}
        <div className="admin-ebook__main-content">{renderComponent()}</div>
      </div>
    </div>
  );
};

export default AdminEbook;
