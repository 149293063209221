import React, { useState, useEffect } from 'react';
import './ReviewCard.css';
import CustomStarRating from './CustomStarRating';
import { useParams } from 'react-router-dom';

const ReviewCard = ({ filterOption }) => {
  const { cardId } = useParams();
  const [reviewData, setReviewData] = useState([]);
  const [visibleReviews, setVisibleReviews] = useState(6); // Initially display 6 reviews
  const [filteredReviews, setFilteredReviews] = useState([]);
  const [transformedReviews, setTransformedReviews] = useState([]); // Declare here

  useEffect(() => {
    const fetchReviews = async (cardId) => {
      try {
        const response = await fetch(`https://auth.ssccglpinnacle.com/api/reviews-ebook/${cardId}`);
        if (!response.ok) {
          throw new Error(`Failed to fetch reviews. Status: ${response.status}`);
        }
        const data = await response.json();
        setReviewData(data.ratings);
      } catch (error) {
        console.error('Error fetching reviews:', error);
      }
    };

    fetchReviews(cardId);
  }, [cardId]);

  useEffect(() => {
    const transformedReviews = reviewData.map((review) => ({
      userName: `${review.firstName} ${review.lastName}`,
      reviewDate: new Date(review.ratingDate).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }),
      starRating: review.starRate,
      reviewCourse: 'SSC Aptitude Book',
      reviewText: review.writtenReview,
    }));

    setTransformedReviews(transformedReviews);
    setFilteredReviews(transformedReviews);
  }, [reviewData]);

  useEffect(() => {
    filterReviews(filterOption);
  }, [filterOption]);

  const filterReviews = (option) => {
    let filteredReviewsCopy = [...transformedReviews];

    if (option === 'Newest') {
      filteredReviewsCopy.sort((a, b) => new Date(b.reviewDate) - new Date(a.reviewDate));
    } else if (option === 'Highest Rating') {
      filteredReviewsCopy.sort((a, b) => b.starRating - a.starRating);
    } else if (option === 'Lowest Rating') {
      filteredReviewsCopy.sort((a, b) => a.starRating - b.starRating);
    } else if (option === 'Featured') {
      const featuredReviews = transformedReviews.filter((review) => review.starRating === 5);
      filteredReviewsCopy = [...featuredReviews];
    }

    setFilteredReviews(filteredReviewsCopy);
  };

  const handleShowMore = () => {
    // Increase the number of visible reviews by 2 (or any other desired increment)
    setVisibleReviews((prevVisibleReviews) => prevVisibleReviews + 2);
  };

  return (
    <div className="app-for">
     {filteredReviews.slice(0, 8 + visibleReviews).map((review, index) => (
        <div className="review-card" key={index}>
          <div className="contain">
            <div className="user-review-name"><p>{review.userName}</p></div>
            <div className="user-review-date"><p>{review.reviewDate}</p></div>
            <div className="user-review-star-rate">
              <CustomStarRating rating={review.starRating} />
            </div>
            <div className="user-given-reviews"><p>{review.reviewText}</p></div>
          </div>
        </div>
      ))}
      {visibleReviews < filteredReviews.length && (
        <button className="show-more-button" onClick={handleShowMore}>
          Show More
        </button>
      )}
    </div>
  );
};

export default ReviewCard;
