// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PageNotFound_Error-fullPage__hQxVv{
    background-color: aliceblue;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.PageNotFound_Error-heading__UQOzo{
font-size: 2.5rem;
}
.PageNotFound_Error-Image__xQ8lr{
    width: 500px;
    height: 300px;
}

.PageNotFound_last-Para-div__OdFEH{
    margin-bottom: 2rem;
}

.PageNotFound_para__lFswe{
    font-size: 1.5rem;
    line-height: 50px;
}

`, "",{"version":3,"sources":["webpack://./src/PageNotFound/PageNotFound.module.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,WAAW;IACX,kBAAkB;IAClB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;AACA,iBAAiB;AACjB;AACA;IACI,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,iBAAiB;AACrB","sourcesContent":[".Error-fullPage{\n    background-color: aliceblue;\n    width: 100%;\n    text-align: center;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.Error-heading{\nfont-size: 2.5rem;\n}\n.Error-Image{\n    width: 500px;\n    height: 300px;\n}\n\n.last-Para-div{\n    margin-bottom: 2rem;\n}\n\n.para{\n    font-size: 1.5rem;\n    line-height: 50px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Error-fullPage": `PageNotFound_Error-fullPage__hQxVv`,
	"Error-heading": `PageNotFound_Error-heading__UQOzo`,
	"Error-Image": `PageNotFound_Error-Image__xQ8lr`,
	"last-Para-div": `PageNotFound_last-Para-div__OdFEH`,
	"para": `PageNotFound_para__lFswe`
};
export default ___CSS_LOADER_EXPORT___;
