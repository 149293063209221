import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './ViewAllCourses.module.css';
import Header from '../Header/Header';

export default function ViewAllCourses() {
  const location = useLocation();
  const navigate = useNavigate();
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const category = location.state?.category || 'All Courses';

  useEffect(() => {
    const categoryUrls = {
      "SSC": "https://auth.ssccglpinnacle.com/api/recent-courses/ssc",
      "Railway": "https://auth.ssccglpinnacle.com/api/recent-courses/railway",
      "Delhi Police": "https://auth.ssccglpinnacle.com/api/recent-courses/delhiPolice",
      "UP Police": "https://auth.ssccglpinnacle.com/api/recent-courses/upPolice",
      "CTET": "https://auth.ssccglpinnacle.com/api/recent-courses/ctet"
    };

    const defaultUrl = "https://auth.ssccglpinnacle.com/api/recent-courses";

    const url = categoryUrls[category] || defaultUrl;

    setLoading(true);
    fetch(url)
      .then(response => response.json())
      .then(data => {
        setCourses(Array.isArray(data) ? data : []);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching courses:', error);
        setLoading(false);
      });
  }, [category]);

  return (
    <>
      <Header />
      <div className={styles['scroll-container']}>
        <h5>{category}</h5> {/* Display the category as heading */}
        {loading ? (
          <div className={styles['loading-container']}>
            <img
              src="https://d3m4h509gttb94.cloudfront.net/Image/Bubble-Preloader.gif"
              alt="Loading"
              className={styles['loading-image']}
            />
          </div>
        ) : (
          <div className={styles['card-wrapper']}>
            {courses.map(course => (
              <div key={course._id} className={styles['card']} onClick={() => navigate(`/course/description/${course._id}`)}>
                <div className={styles['Image-section']}>
                  <img className={styles.imagecard} src={course.hindiCoverImage} alt="Hindi Cover" />
                  <img className={styles.imagecard} src={course.englishCoverImage} alt="English Cover" />
                </div>
                <div className={styles['description-section']}>
                  <div className={styles['title']}>{course.courseTitle}</div>
                  <div className={styles['gst']}>18% GST included</div>
                  <div className={styles["price-fprice-div"]}>
                    <div className={styles["price"]}>₹ {course.price}</div>&nbsp;&nbsp;&nbsp;&nbsp;
                    <div className={styles["mrp"]}><s>₹{course.mrp}</s></div>
                    <div className={styles["savings"]}>
                      Save{" "}
                      {Math.round(((course.mrp - course.price) / course.mrp) * 100)}%
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
}


// // ViewAllCourses.js
// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import styles from './ViewAllCourses.module.css';
// import Header from '../Header/Header';
// export default function ViewAllCourses({ category }) {
//   const navigate = useNavigate();
//   const [courses, setCourses] = useState([]);
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const categoryUrls = {
//       "SSC": "https://auth.ssccglpinnacle.com/api/recent-courses/ssc",
//       "Railway": "https://auth.ssccglpinnacle.com/api/recent-courses/railway",
//       "Delhi Police": "https://auth.ssccglpinnacle.com/api/recent-courses/delhiPolice",
//       "UP Police": "https://auth.ssccglpinnacle.com/api/recent-courses/upPolice",
//       "CTET": "https://auth.ssccglpinnacle.com/api/recent-courses/ctet"
//     };

//     const defaultUrl = "https://auth.ssccglpinnacle.com/api/recent-courses";

//     const url = category ? categoryUrls[category] : defaultUrl;

//     setLoading(true);
//     fetch(url)
//       .then(response => response.json())
//       .then(data => {
//         setCourses(Array.isArray(data) ? data : []);
//         setLoading(false);
//       })
//       .catch(error => {
//         console.error('Error fetching courses:', error);
//         setLoading(false);
//       });
//   }, [category]);

//   return (
//     <>
//     <Header/>
//     <div className={styles['scroll-container']}>
//       {loading ? (
//                 <div className={styles['loading-container']}>
//                 <img
//                   src="https://d3m4h509gttb94.cloudfront.net/Image/Bubble-Preloader.gif"
//                   alt="Loading"
//                   className={styles['loading-image']}
//                 />
//               </div>
//       ) : (
//         <div className={styles['card-wrapper']}>
//           {courses.map(course => (
//             <div key={course._id} className={styles['card']} onClick={() => navigate(`/course/description/${course._id}`)}>
//               <div className={styles['Image-section']}>
//                 <img className={styles.imagecard} src={course.hindiCoverImage} alt="Hindi Cover" />
//                 <img className={styles.imagecard} src={course.englishCoverImage} alt="English Cover" />
//               </div>
//               <div className={styles['description-section']}>
//                 <div className={styles['title']}>{course.courseTitle}</div>
//                 <div className={styles['gst']}>18% GST included</div>
//                 <div className={styles["price-fprice-div"]}>
//                   <div className={styles["price"]}>₹ {course.price}</div>&nbsp;&nbsp;&nbsp;&nbsp;
//                   <div className={styles["mrp"]}><s>₹{course.mrp}</s></div>
//                   <div className={styles["savings"]}>
//                     Save{" "}
//                     {Math.round(((course.mrp - course.price) / course.mrp) * 100)}%
//                   </div>
//                 </div>
//               </div>
//             </div>
//           ))}
//         </div>
//       )}
//     </div>
//     </>
//   );
// }
