import React, { useState, useEffect, useMemo } from 'react';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { Button } from '@mui/material';
import Modal from 'react-modal';
import Swal from 'sweetalert2';
import './EditTypingParagraph.css';

const EditTypingParagraph = () => {
  const [typingParagraphs, setTypingParagraphs] = useState([]);
  const [editData, setEditData] = useState({
    id: '',
    exam: '',
    type: '',
    date: '',
    paper_code: '',
    title: '',
    paragraph: '',
    time: '',
    status: '1'
  });
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchTypingParagraphs = async () => {
      try {
        const response = await fetch('https://auth.ssccglpinnacle.com/api/typingParagraphs');
        if (response.ok) {
          const paragraphs = await response.json();
          setTypingParagraphs(paragraphs);
        } else {
          console.error('Failed to fetch typing paragraphs');
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchTypingParagraphs();
  }, []);

  const columns = useMemo(() => [
    { accessorKey: 'exam', header: 'Exam' },
    { accessorKey: 'type', header: 'Type' },
    { accessorKey: 'date', header: 'Date' },
    { accessorKey: 'paper_code', header: 'Paper Code' },
    { accessorKey: 'title', header: 'Title' },

    {
      id: 'edit',
      header: 'Edit',
      Cell: ({ row }) => (
        <Button variant="contained" color="primary" onClick={() => handleEditClick(row.original)}>
          Edit
        </Button>
      ),
    },
  ], []);

  const table = useMaterialReactTable({
    columns,
    data: typingParagraphs,
  });

  const handleEditClick = (paragraph) => {
    setEditData(paragraph);
    setIsModalOpen(true);
    document.body.style.overflow = 'hidden';
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setEditData({
      id: '',
      exam: '',
      type: '',
      date: '',
      paper_code: '',
      title: '',
      paragraph: '',
      time: '',
      status: '1'
    });
    document.body.style.overflow = '';
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://auth.ssccglpinnacle.com/api/updateTypingParagraph', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(editData),
      });

      if (response.ok) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Typing paragraph updated successfully!',
        });
        setTypingParagraphs((prev) =>
          prev.map((item) => (item.id === editData.id ? editData : item))
        );
        closeModal();
      } else {
        throw new Error('Failed to update typing paragraph');
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred while updating typing paragraph. Please try again later.',
      });
    }
  };

  const handleDelete = async () => {
    try {
      const response = await fetch(`https://auth.ssccglpinnacle.com/api/deleteTypingParagraph/${editData.id}`, {
        method: 'DELETE',
      });

      if (response.ok) {
        Swal.fire({
          icon: 'success',
          title: 'Deleted',
          text: 'Typing paragraph deleted successfully!',
        });
        setTypingParagraphs(typingParagraphs.filter((item) => item.id !== editData.id));
        closeModal();
      } else {
        throw new Error('Failed to delete typing paragraph');
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred while deleting typing paragraph. Please try again later.',
      });
    }
  };

  return (
    <>
      <div className="container-for-edit-typing-paragraph">
        <h1 className="edit-typing-paragraph-title">Edit Typing Paragraph</h1>
        <MaterialReactTable
          table={table}
          columns={columns}
          data={typingParagraphs}
        />
      </div>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Edit Typing Paragraph Modal"
        className="modal-container-for-editing-typing-paragraph"
        overlayClassName="modal-overlay"
      >
        <div className="modal-header-for-editing-typing-paragraph">
          <h1>Edit Typing Paragraph</h1>
          <button className="modal-close" onClick={closeModal}>&times;</button>
        </div>
        <form className="modal-form-for-editing-typing-paragraph" onSubmit={handleEditSubmit}>
          <label htmlFor="exam">Exam:</label>
          <input
            type="text"
            id="exam"
            value={editData.exam}
            onChange={(e) => setEditData({ ...editData, exam: e.target.value })}
            required
          />
          <label htmlFor="type">Type:</label>
          <input
            type="text"
            id="type"
            value={editData.type}
            onChange={(e) => setEditData({ ...editData, type: e.target.value })}
            required
          />
          <label htmlFor="date">Date:</label>
          <input
            type="text"
            id="date"
            value={editData.date}
            onChange={(e) => setEditData({ ...editData, date: e.target.value })}
            required
          />
          <label htmlFor="paper_code">Paper Code:</label>
          <input
            type="text"
            id="paper_code"
            value={editData.paper_code}
            onChange={(e) => setEditData({ ...editData, paper_code: e.target.value })}
            required
          />
          <label htmlFor="title">Title:</label>
          <input
            type="text"
            id="title"
            value={editData.title}
            onChange={(e) => setEditData({ ...editData, title: e.target.value })}
            required
          />
          <label htmlFor="paragraph">Paragraph:</label>
          <textarea
            id="paragraph"
            value={editData.paragraph}
            onChange={(e) => setEditData({ ...editData, paragraph: e.target.value })}
            required
          />
          <label htmlFor="time">Time:</label>
          <input
            type="number"
            id="time"
            value={editData.time}
            onChange={(e) => setEditData({ ...editData, time: e.target.value })}
            required
          />
          <label htmlFor="status">Status:</label>
          <input
            type="number"
            id="status"
            value={editData.status}
            onChange={(e) => setEditData({ ...editData, status: e.target.value })}
            required
          />
          <div className="styling-the-button">
            <Button type="submit" variant="contained" color="primary">
              Save Changes
            </Button>
            <Button variant="contained" color="secondary" onClick={handleDelete} style={{ marginLeft: '10px' }}>
              Delete
            </Button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default EditTypingParagraph;
