// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.YT_Learning_layoutContainer__bgARa {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.YT_Learning_contentContainer__1Jx8T {
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "video sidebar"
    "navbar sidebar";
  height: 100%;
  overflow-y: auto;
  margin-top: 0.8%;
}

.YT_Learning_videoContainer__7tB1T {
  grid-area: video;
  display: flex;
  flex-direction: column;
  margin-top: 9px;
}

.YT_Learning_sidebarContainer__89ED0 {
  grid-area: sidebar;
  position: sticky;
  top: 0;
  height: 100vh;
  overflow-y: auto;
  margin-top: 10px;
  width: 62vh;
}

.YT_Learning_videoHeader__mgfSo {
  height: 50px;
}

.YT_Learning_videoNavbar__JjP1y {
  grid-area: navbar;
}

.YT_Learning_loaderContainer__aWvIK {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
  width: 100%; /* Full width */
  position: fixed; /* Fix it at the center of the page */
  top: 0;
  left: 0;
  background-color: rgba(
    255,
    255,
    255,
    0.9
  ); /* Optional: Add a semi-transparent background */
  z-index: 1000; /* Ensure it is on top of other content */
}
`, "",{"version":3,"sources":["webpack://./src/YOUTUBE VIDEO PLAYER/YT_Layouts/YT_Learning.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,aAAa;AACf;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,4BAA4B;EAC5B;;oBAEkB;EAClB,YAAY;EACZ,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,MAAM;EACN,aAAa;EACb,gBAAgB;EAChB,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa,EAAE,yBAAyB;EACxC,WAAW,EAAE,eAAe;EAC5B,eAAe,EAAE,qCAAqC;EACtD,MAAM;EACN,OAAO;EACP;;;;;GAKC,EAAE,gDAAgD;EACnD,aAAa,EAAE,yCAAyC;AAC1D","sourcesContent":[".layoutContainer {\n  display: flex;\n  flex-direction: column;\n  height: 100vh;\n}\n\n.contentContainer {\n  display: grid;\n  grid-template-columns: 3fr 1fr;\n  grid-template-rows: auto 1fr;\n  grid-template-areas:\n    \"video sidebar\"\n    \"navbar sidebar\";\n  height: 100%;\n  overflow-y: auto;\n  margin-top: 0.8%;\n}\n\n.videoContainer {\n  grid-area: video;\n  display: flex;\n  flex-direction: column;\n  margin-top: 9px;\n}\n\n.sidebarContainer {\n  grid-area: sidebar;\n  position: sticky;\n  top: 0;\n  height: 100vh;\n  overflow-y: auto;\n  margin-top: 10px;\n  width: 62vh;\n}\n\n.videoHeader {\n  height: 50px;\n}\n\n.videoNavbar {\n  grid-area: navbar;\n}\n\n.loaderContainer {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100vh; /* Full viewport height */\n  width: 100%; /* Full width */\n  position: fixed; /* Fix it at the center of the page */\n  top: 0;\n  left: 0;\n  background-color: rgba(\n    255,\n    255,\n    255,\n    0.9\n  ); /* Optional: Add a semi-transparent background */\n  z-index: 1000; /* Ensure it is on top of other content */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layoutContainer": `YT_Learning_layoutContainer__bgARa`,
	"contentContainer": `YT_Learning_contentContainer__1Jx8T`,
	"videoContainer": `YT_Learning_videoContainer__7tB1T`,
	"sidebarContainer": `YT_Learning_sidebarContainer__89ED0`,
	"videoHeader": `YT_Learning_videoHeader__mgfSo`,
	"videoNavbar": `YT_Learning_videoNavbar__JjP1y`,
	"loaderContainer": `YT_Learning_loaderContainer__aWvIK`
};
export default ___CSS_LOADER_EXPORT___;
