// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AccountClose_close_btn__\\+oHqW {
  width: 7rem;
  height: 3rem;
  background-color: black;
}
.AccountClose_warning_text__AR-ZM {
  color: #b32d0f;
}
.AccountClose_header_container__8LSUO {
}
.AccountClose_close_container__JeEpp {
  line-height: 1.7rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  width: 65rem;
  margin-top: 9%;
  margin-left: 3%;
}
.AccountClose_close_header__\\+\\+YHc {
  margin-left: 36%;
}
.AccountClose_header_para__eFXVH {
  margin-left: 28%;
}
.AccountClose_close_body_container__iTPks {
  display: flex;
  padding: 2%;
}
.AccountClose_close_btn_box__utAvP {
  padding: 2%;
}
`, "",{"version":3,"sources":["webpack://./src/VIDEOPLAYER COMPONENTS/components/VideoPlayerPage/AccountPage/AccountClose.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,uBAAuB;AACzB;AACA;EACE,cAAc;AAChB;AACA;AACA;AACA;EACE,mBAAmB;EACnB;0CACwC;EACxC,YAAY;EACZ,cAAc;EACd,eAAe;AACjB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,WAAW;AACb;AACA;EACE,WAAW;AACb","sourcesContent":[".close_btn {\n  width: 7rem;\n  height: 3rem;\n  background-color: black;\n}\n.warning_text {\n  color: #b32d0f;\n}\n.header_container {\n}\n.close_container {\n  line-height: 1.7rem;\n  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,\n    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;\n  width: 65rem;\n  margin-top: 9%;\n  margin-left: 3%;\n}\n.close_header {\n  margin-left: 36%;\n}\n.header_para {\n  margin-left: 28%;\n}\n.close_body_container {\n  display: flex;\n  padding: 2%;\n}\n.close_btn_box {\n  padding: 2%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"close_btn": `AccountClose_close_btn__+oHqW`,
	"warning_text": `AccountClose_warning_text__AR-ZM`,
	"header_container": `AccountClose_header_container__8LSUO`,
	"close_container": `AccountClose_close_container__JeEpp`,
	"close_header": `AccountClose_close_header__++YHc`,
	"header_para": `AccountClose_header_para__eFXVH`,
	"close_body_container": `AccountClose_close_body_container__iTPks`,
	"close_btn_box": `AccountClose_close_btn_box__utAvP`
};
export default ___CSS_LOADER_EXPORT___;
