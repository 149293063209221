import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import styles from "./AddChapterForm.module.css";

const ChapterForm = () => {
   const [chapterTitle, setChapterTitle] = useState('');
   const [courses, setCourses] = useState([]);
   const [selectedCourse, setSelectedCourse] = useState('');
   const [pdfs, setPdfs] = useState([]);
   const [topics, setTopics] = useState([
      { videoTitle: '', videoYoutubeLink: '', pdfTitle: '', selectedPdf: '' }
   ]);

   // Fetch Courses
   useEffect(() => {
      const fetchCourses = async () => {
         try {
            const response = await fetch('https://auth.ssccglpinnacle.com/api/courses');
            const data = await response.json();
            setCourses(data);
         } catch (error) {
            console.error('Error fetching courses:', error);
         }
      };
      fetchCourses();
   }, []);

   // Fetch PDFs based on the selected course
   useEffect(() => {
      if (selectedCourse) {
         const fetchPdfs = async () => {
            try {
               const response = await fetch(`https://auth.ssccglpinnacle.com/api/pdfs/course/${selectedCourse}`);
               const data = await response.json();
               setPdfs(data);
            } catch (error) {
               console.error('Error fetching PDFs:', error);
            }
         };
         fetchPdfs();
      }
   }, [selectedCourse]);

   const handleSubmit = (e) => {
      e.preventDefault();
      const chapterData = {
         chapterTitle,
         course: selectedCourse,
         topics
      };

      fetch('https://auth.ssccglpinnacle.com/api/YouTube/chapters', {
         method: 'POST',
         headers: {
            'Content-Type': 'application/json',
         },
         body: JSON.stringify(chapterData),
      })
         .then(response => response.json())
         .then(data => {
            // Reset the form state
            setChapterTitle('');
            setSelectedCourse('');
            setPdfs([]);
            setTopics([{ videoTitle: '', videoYoutubeLink: '', pdfTitle: '', selectedPdf: '' }]);
            // Display success message with SweetAlert2
            Swal.fire({
               title: 'Success!',
               text: 'Chapter successfully uploaded!',
               icon: 'success',
               confirmButtonText: 'OK'
            });
         })
         .catch((error) => {
            // Display error message with SweetAlert2
            Swal.fire({
               title: 'Failed!',
               text: 'Error posting chapter: ' + error.message,
               icon: 'error',
               confirmButtonText: 'OK'
            });
         });
   };

   const handleTopicChange = (index, field, value) => {
      const newTopics = topics.map((topic, i) => {
         if (i === index) {
            return { ...topic, [field]: value };
         }
         return topic;
      });
      setTopics(newTopics);
   };
   return (
      <form onSubmit={handleSubmit} className={styles.formContainer}>
         <h3 className={styles.formTitle}>Create New Chapter</h3>
         <label htmlFor="course">Select Course:</label>
         <select
            id="course"
            value={selectedCourse}
            onChange={(e) => setSelectedCourse(e.target.value)}
            required
         >
            <option value="">Select a Course</option>
            {courses.map((course) => (
               <option key={course._id} value={course._id}>{course.courseTitle}</option>
            ))}
         </select>

         <label htmlFor="chapterTitle">Chapter Title:</label>
         <input
            type="text"
            id="chapterTitle"
            value={chapterTitle}
            onChange={(e) => setChapterTitle(e.target.value)}
            required
         />

         {topics.map((topic, index) => (
            <div key={index}>
               <label>Video Title:</label>
               <input
                  type="text"
                  value={topic.videoTitle}
                  onChange={(e) => handleTopicChange(index, 'videoTitle', e.target.value)}
                  required
               />

               <label>YouTube Video Link:</label>
               <input
                  type="text"
                  value={topic.videoYoutubeLink}
                  onChange={(e) => handleTopicChange(index, 'videoYoutubeLink', e.target.value)}
                  required
               />

               <label>Pdf Title:</label>
               <input
                  type="text"
                  value={topic.pdfTitle}
                  onChange={(e) => handleTopicChange(index, 'pdfTitle', e.target.value)}
                  required
               />

               <label>Selected PDF:</label>
               <select
                  value={topic.selectedPdf}
                  onChange={(e) => handleTopicChange(index, 'selectedPdf', e.target.value)}
                  required
               >
                  <option value="">Select a PDF</option>
                  {pdfs.map(pdf => (
                     <option key={pdf._id} value={pdf._id}>{pdf.originalname}</option>
                  ))}
               </select>
            </div>
         ))}

         <button type="submit">Submit Chapter</button>
      </form>
   );
};

export default ChapterForm;

// import React, { useState, useEffect } from 'react';
// import Swal from 'sweetalert2';
// import styles from "./AddChapterForm.module.css";

// const ChapterForm = () => {
//    const [chapterTitle, setChapterTitle] = useState('');
//    const [courses, setCourses] = useState([]);
//    const [selectedCourse, setSelectedCourse] = useState('');
//    const [pdfs, setPdfs] = useState([]);
//    const [topics, setTopics] = useState([
//       { videoTitle: '', videoYoutubeLink: '', pdfTitle: '', selectedPdf: '' }
//    ]);

//    // Fetch Courses
//    useEffect(() => {
//       const fetchCourses = async () => {
//          try {
//             const response = await fetch('https://auth.ssccglpinnacle.com/api/courses');
//             const data = await response.json();
//             setCourses(data);
//          } catch (error) {
//             console.error('Error fetching courses:', error);
//          }
//       };
//       fetchCourses();
//    }, []);

//    // Fetch PDFs based on the selected course
//    useEffect(() => {
//       if (selectedCourse) {
//          const fetchPdfs = async () => {
//             try {
//                const response = await fetch(`https://auth.ssccglpinnacle.com/api/pdfs/course/${selectedCourse}`);
//                const data = await response.json();
//                setPdfs(data);
//             } catch (error) {
//                console.error('Error fetching PDFs:', error);
//             }
//          };
//          fetchPdfs();
//       }
//    }, [selectedCourse]);

//    const handleSubmit = (e) => {
//       e.preventDefault();
//       const chapterData = {
//          chapterTitle,
//          course: selectedCourse,
//          topics
//       };

//       fetch('https://auth.ssccglpinnacle.com/api/YouTube/chapters', {
//          method: 'POST',
//          headers: {
//             'Content-Type': 'application/json',
//          },
//          body: JSON.stringify(chapterData),
//       })
//          .then(response => response.json())
//          .then(data => {
//             // Reset the form state
//             setChapterTitle('');
//             setSelectedCourse('');
//             setPdfs([]);
//             setTopics([{ videoTitle: '', videoYoutubeLink: '', pdfTitle: '', selectedPdf: '' }]);
//             // Display success message with SweetAlert2
//             Swal.fire({
//                title: 'Success!',
//                text: 'Chapter successfully uploaded!',
//                icon: 'success',
//                confirmButtonText: 'OK'
//             });
//          })
//          .catch((error) => {
//             // Display error message with SweetAlert2
//             Swal.fire({
//                title: 'Failed!',
//                text: 'Error posting chapter: ' + error.message,
//                icon: 'error',
//                confirmButtonText: 'OK'
//             });
//          });
//    };

//    const handleTopicChange = (index, field, value) => {
//       const newTopics = topics.map((topic, i) => {
//          if (i === index) {
//             return { ...topic, [field]: value };
//          }
//          return topic;
//       });
//       setTopics(newTopics);
//    };

//    return (
//       <form onSubmit={handleSubmit} className={styles.formContainer}>
//          <h3 className={styles.formTitle}>Create New Chapter</h3>
//          <label htmlFor="course">Select Course:</label>
//          <select
//             id="course"
//             value={selectedCourse}
//             onChange={(e) => setSelectedCourse(e.target.value)}
//             required
//          >
//             <option value="">Select a Course</option>
//             {courses.map((course) => (
//                <option key={course._id} value={course._id}>{course.courseTitle}</option>
//             ))}
//          </select>

//          <label htmlFor="chapterTitle">Chapter Title:</label>
//          <input
//             type="text"
//             id="chapterTitle"
//             value={chapterTitle}
//             onChange={(e) => setChapterTitle(e.target.value)}
//             required
//          />

//          {topics.map((topic, index) => (
//             <div key={index} className={styles.topicContainer}>
//                <label>Video Title:</label>
//                <input
//                   type="text"
//                   value={topic.videoTitle}
//                   onChange={(e) => handleTopicChange(index, 'videoTitle', e.target.value)}
//                   required
//                />

//                <label>YouTube Video Link:</label>
//                <input
//                   type="text"
//                   value={topic.videoYoutubeLink}
//                   onChange={(e) => handleTopicChange(index, 'videoYoutubeLink', e.target.value)}
//                   required
//                />

//                <label>Pdf Title:</label>
//                <input
//                   type="text"
//                   value={topic.pdfTitle}
//                   onChange={(e) => handleTopicChange(index, 'pdfTitle', e.target.value)}
//                   required
//                />

//                <label>Selected PDF:</label>
//                <select
//                   value={topic.selectedPdf}
//                   onChange={(e) => handleTopicChange(index, 'selectedPdf', e.target.value)}
//                   required
//                >
//                   <option value="">Select a PDF</option>
//                   {pdfs.map(pdf => (
//                      <option key={pdf._id} value={pdf._id}>{pdf.originalname}</option>
//                   ))}
//                </select>
//             </div>
//          ))}

//          <button type="submit" className={styles.submitButton}>Submit Chapter</button>
//       </form>
//    );
// };

// export default ChapterForm;

// import React, { useState, useEffect } from 'react';
// import Swal from 'sweetalert2';
// import styles from "./AddChapterForm.module.css";

// const ChapterForm = () => {
//    const [chapterTitle, setChapterTitle] = useState('');
//    const [chapterOrder, setChapterOrder] = useState(0);
//    const [courses, setCourses] = useState([]);
//    const [selectedCourse, setSelectedCourse] = useState('');
//    const [pdfs, setPdfs] = useState([]);
//    const [topics, setTopics] = useState([
//       { videoTitle: '', videoYoutubeLink: '', pdfTitle: '', selectedPdf: '', order: 0 }
//    ]);

//    useEffect(() => {
//       const fetchCourses = async () => {
//          try {
//             const response = await fetch('https://auth.ssccglpinnacle.com/api/courses');
//             const data = await response.json();
//             setCourses(data);
//          } catch (error) {
//             console.error('Error fetching courses:', error);
//          }
//       };
//       fetchCourses();
//    }, []);

//    useEffect(() => {
//       if (selectedCourse) {
//          const fetchPdfs = async () => {
//             try {
//                const response = await fetch(`https://auth.ssccglpinnacle.com/api/pdfs/course/${selectedCourse}`);
//                const data = await response.json();
//                setPdfs(data);
//             } catch (error) {
//                console.error('Error fetching PDFs:', error);
//             }
//          };
//          fetchPdfs();
//       }
//    }, [selectedCourse]);

//    const handleSubmit = (e) => {
//       e.preventDefault();
//       const orderedTopics = topics.map((topic, index) => ({
//          ...topic,
//          order: topic.order !== undefined ? topic.order : index
//       }));
//       const chapterData = {
//          chapterTitle,
//          course: selectedCourse,
//          order: chapterOrder,
//          topics: orderedTopics
//       };

//       fetch('https://auth.ssccglpinnacle.com/api/YouTube/chapters', {
//          method: 'POST',
//          headers: {
//             'Content-Type': 'application/json',
//          },
//          body: JSON.stringify(chapterData),
//       })
//          .then(response => response.json())
//          .then(data => {
//             setChapterTitle('');
//             setChapterOrder(0);
//             setSelectedCourse('');
//             setPdfs([]);
//             setTopics([{ videoTitle: '', videoYoutubeLink: '', pdfTitle: '', selectedPdf: '', order: 0 }]);
//             Swal.fire({
//                title: 'Success!',
//                text: 'Chapter successfully uploaded!',
//                icon: 'success',
//                confirmButtonText: 'OK'
//             });
//          })
//          .catch((error) => {
//             Swal.fire({
//                title: 'Failed!',
//                text: 'Error posting chapter: ' + error.message,
//                icon: 'error',
//                confirmButtonText: 'OK'
//             });
//          });
//    };

//    const handleTopicChange = (index, field, value) => {
//       const newTopics = topics.map((topic, i) => {
//          if (i === index) {
//             return { ...topic, [field]: value };
//          }
//          return topic;
//       });
//       setTopics(newTopics);
//    };

//    return (
//       <form onSubmit={handleSubmit} className={styles.formContainer}>
//          <h3 className={styles.formTitle}>Create New Chapter</h3>
//          <label htmlFor="course">Select Course:</label>
//          <select
//             id="course"
//             value={selectedCourse}
//             onChange={(e) => setSelectedCourse(e.target.value)}
//             required
//          >
//             <option value="">Select a Course</option>
//             {courses.map((course) => (
//                <option key={course._id} value={course._id}>{course.courseTitle}</option>
//             ))}
//          </select>

//          <label htmlFor="chapterTitle">Chapter Title:</label>
//          <input
//             type="text"
//             id="chapterTitle"
//             value={chapterTitle}
//             onChange={(e) => setChapterTitle(e.target.value)}
//             required
//          />

//          <label htmlFor="chapterOrder">Chapter Order:</label>
//          <input
//             type="number"
//             id="chapterOrder"
//             value={chapterOrder}
//             onChange={(e) => setChapterOrder(e.target.value)}
//             required
//          />

//          {topics.map((topic, index) => (
//             <div key={index} className={styles.topicContainer}>
//                <label>Video Title:</label>
//                <input
//                   type="text"
//                   value={topic.videoTitle}
//                   onChange={(e) => handleTopicChange(index, 'videoTitle', e.target.value)}
//                   required
//                />

//                <label>YouTube Video Link:</label>
//                <input
//                   type="text"
//                   value={topic.videoYoutubeLink}
//                   onChange={(e) => handleTopicChange(index, 'videoYoutubeLink', e.target.value)}
//                   required
//                />

//                <label>Pdf Title:</label>
//                <input
//                   type="text"
//                   value={topic.pdfTitle}
//                   onChange={(e) => handleTopicChange(index, 'pdfTitle', e.target.value)}
//                   required
//                />

//                <label>Selected PDF:</label>
//                <select
//                   value={topic.selectedPdf}
//                   onChange={(e) => handleTopicChange(index, 'selectedPdf', e.target.value)}
//                   required
//                >
//                   <option value="">Select a PDF</option>
//                   {pdfs.map(pdf => (
//                      <option key={pdf._id} value={pdf._id}>{pdf.originalname}</option>
//                   ))}
//                </select>

//                <label>Topic Order:</label>
//                <input
//                   type="number"
//                   value={topic.order}
//                   onChange={(e) => handleTopicChange(index, 'order', parseInt(e.target.value))}
//                   required
//                />
//             </div>
//          ))}

//          <button type="submit" className={styles.submitButton}>Submit Chapter</button>
//       </form>
//    );
// };

// export default ChapterForm;


// import React, { useState, useEffect } from 'react';
// import Swal from 'sweetalert2';
// import styles from "./AddChapterForm.module.css";

// const AddChapterForm = () => {
//   const [chapterTitle, setChapterTitle] = useState('');
//   const [chapterOrder, setChapterOrder] = useState(0);
//   const [courses, setCourses] = useState([]);
//   const [selectedCourse, setSelectedCourse] = useState('');

//   useEffect(() => {
//     const fetchCourses = async () => {
//       try {
//         const response = await fetch('https://auth.ssccglpinnacle.com/api/courses');
//         const data = await response.json();
//         setCourses(data);
//       } catch (error) {
//         console.error('Error fetching courses:', error);
//       }
//     };
//     fetchCourses();
//   }, []);

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     const chapterData = {
//       chapterTitle,
//       course: selectedCourse,
//       order: chapterOrder,
//       topics: []
//     };

//     fetch('https://auth.ssccglpinnacle.com/api/YouTube/chapters', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify(chapterData),
//     })
//       .then(response => response.json())
//       .then(data => {
//         setChapterTitle('');
//         setChapterOrder(0);
//         setSelectedCourse('');
//         Swal.fire({
//           title: 'Success!',
//           text: 'Chapter successfully uploaded!',
//           icon: 'success',
//           confirmButtonText: 'OK'
//         });
//       })
//       .catch((error) => {
//         Swal.fire({
//           title: 'Failed!',
//           text: 'Error posting chapter: ' + error.message,
//           icon: 'error',
//           confirmButtonText: 'OK'
//         });
//       });
//   };

//   return (
//     <form onSubmit={handleSubmit} className={styles.formContainer}>
//       <h3 className={styles.formTitle}>Create New Chapter</h3>
//       <label htmlFor="course">Select Course:</label>
//       <select
//         id="course"
//         value={selectedCourse}
//         onChange={(e) => setSelectedCourse(e.target.value)}
//         required
//       >
//         <option value="">Select a Course</option>
//         {courses.map((course) => (
//           <option key={course._id} value={course._id}>{course.courseTitle}</option>
//         ))}
//       </select>

//       <label htmlFor="chapterTitle">Chapter Title:</label>
//       <input
//         type="text"
//         id="chapterTitle"
//         value={chapterTitle}
//         onChange={(e) => setChapterTitle(e.target.value)}
//         required
//       />

//       <label htmlFor="chapterOrder">Chapter Order:</label>
//       <input
//         type="number"
//         id="chapterOrder"
//         value={chapterOrder}
//         onChange={(e) => setChapterOrder(e.target.value)}
//         required
//       />

//       <button type="submit" className={styles.submitButton}>Submit Chapter</button>
//     </form>
//   );
// };

// export default AddChapterForm;
