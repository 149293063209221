import React, { useState, useEffect } from "react";
import axios from "axios";
import { MaterialReactTable } from "material-react-table";
import {
  Button,
  Modal,
  Box,
  Typography,
  TextField,
  Grid,
} from "@mui/material";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom"; // Import useNavigate for navigation
import styles from "./ManageChapter.module.css"; // Import your CSS

const ManageChapter = () => {
  const [courses, setCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState("");
  const [chapters, setChapters] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentChapter, setCurrentChapter] = useState(null);
  const [currentTopic, setCurrentTopic] = useState(null);
  const [newTitle, setNewTitle] = useState("");
  const [newVideoData, setNewVideoData] = useState({
    videoTitle: "",
    videoYoutubeLink: "",
    pdfTitle: "",
    selectedPdf: "",
  });

  const navigate = useNavigate(); // For navigation to another component

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await axios.get("https://auth.ssccglpinnacle.com/api/courses");
        setCourses(response.data);

        if (response.data.length > 0) {
          setSelectedCourse(response.data[0]._id);
        }
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };

    fetchCourses();
  }, []);

  useEffect(() => {
    const fetchChapters = async () => {
      try {
        if (selectedCourse) {
          const response = await axios.get(
            `https://auth.ssccglpinnacle.com/api/youtubeChapters/course/${selectedCourse}`
          );
          setChapters(response.data);
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setChapters([]); 
        } else {
          console.error("Error fetching chapters:", error);
        }
      }
    };

    if (selectedCourse) {
      fetchChapters();
    }
  }, [selectedCourse]);

  const handleUpdateChapter = async () => {
    try {
      const response = await axios.put(
        `https://auth.ssccglpinnacle.com/api/vc/chapters/${currentChapter._id}`,
        { chapterTitle: newTitle }
      );

      if (response.status === 200) {
        setChapters((prevChapters) =>
          prevChapters.map((chapter) =>
            chapter._id === currentChapter._id
              ? { ...chapter, chapterTitle: newTitle }
              : chapter
          )
        );
        handleClose();
      }
    } catch (error) {
      console.error("Error updating chapter title:", error);
    }
  };

  const handleDeleteChapter = async (chapterId) => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: "Do you really want to delete this chapter? This action cannot be undone!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel',
    });

    if (result.isConfirmed) {
      try {
        const response = await axios.delete(
          `https://auth.ssccglpinnacle.com/api/chapters/${chapterId}`
        );

        if (response.status === 200) {
          setChapters((prevChapters) =>
            prevChapters.filter((chapter) => chapter._id !== chapterId)
          );
          Swal.fire(
            'Deleted!',
            'The chapter has been deleted.',
            'success'
          );
        }
      } catch (error) {
        console.error("Error deleting chapter:", error);
        Swal.fire(
          'Error!',
          'There was an issue deleting the chapter. Please try again later.',
          'error'
        );
      }
    }
  };

  const handleUpdateTopic = async () => {
    try {
      const response = await axios.put(
        `https://auth.ssccglpinnacle.com/api/vc/chapters/${currentChapter._id}/topics/${currentTopic._id}`,
        newVideoData
      );

      if (response.status === 200) {
        setChapters((prevChapters) =>
          prevChapters.map((chapter) =>
            chapter._id === currentChapter._id
              ? {
                  ...chapter,
                  topics: chapter.topics.map((topic) =>
                    topic._id === currentTopic._id
                      ? { ...topic, ...newVideoData }
                      : topic
                  ),
                }
              : chapter
          )
        );
        handleClose();
      }
    } catch (error) {
      console.error("Error updating topic:", error);
    }
  };

  const handleDeleteTopic = async (chapterId, topicId) => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: "Do you really want to delete this topic? This action cannot be undone!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel',
    });

    if (result.isConfirmed) {
      try {
        const response = await axios.delete(
          `https://auth.ssccglpinnacle.com/api/videos/chapters/${chapterId}/topics/${topicId}`
        );

        if (response.status === 200) {
          setChapters((prevChapters) =>
            prevChapters.map((chapter) =>
              chapter._id === chapterId
                ? {
                    ...chapter,
                    topics: chapter.topics.filter(
                      (topic) => topic._id !== topicId
                    ),
                  }
                : chapter
            )
          );
          Swal.fire(
            'Deleted!',
            'The topic has been deleted.',
            'success'
          );
        }
      } catch (error) {
        console.error("Error deleting topic:", error);
        Swal.fire(
          'Error!',
          'There was an issue deleting the topic. Please try again later.',
          'error'
        );
      }
    }
  };

  const handleOpen = (chapter, topic = null) => {
    setCurrentChapter(chapter);
    setCurrentTopic(topic);
    if (topic) {
      setNewVideoData({
        videoTitle: topic.videoTitle,
        videoYoutubeLink: topic.videoYoutubeLink,
        pdfTitle: topic.pdfTitle,
        selectedPdf: topic.selectedPdf,
      });
    } else {
      setNewTitle(chapter.chapterTitle);
    }
    setOpen(true);
  };

  const handleUpdateTopicOrder = (chapter) => {
    // Navigate to a separate page for updating the topic order, passing the chapter ID
    navigate(`/updateTopicOrder/${chapter._id}`);
  };
  

  const handleClose = () => {
    setOpen(false);
    setNewTitle("");
    setNewVideoData({
      videoTitle: "",
      videoYoutubeLink: "",
      pdfTitle: "",
      selectedPdf: "",
    });
  };

  const handleUpdateChapterOrder = () => {
    navigate(`/updateChapterOrder/${selectedCourse}`);
  };

  const columns = [
    {
      accessorKey: "chapterTitle",
      header: "Chapter Title",
      Cell: ({ cell }) => (
        <span>{cell.getValue()}</span>
      ),
    },
    {
      accessorKey: "topics",
      header: "Topics",
      Cell: ({ cell }) => (
        <ul>
          {cell.row.original.topics.map((topic) => (
            <li key={topic._id}>
              <strong>Topic Title:</strong> {topic.videoTitle}
              <div>
                <strong>Video URL:</strong> {topic.videoYoutubeLink}
              </div>
              <div>
                <strong>PDF Title:</strong> {topic.pdfTitle}
              </div>
              <div>
                <strong>PDF URL:</strong> {topic.selectedPdf}
              </div>
              <div>
                <Button
                  onClick={() => handleOpen(cell.row.original, topic)}
                  sx={{  color: 'green', marginRight: '5px' ,fontFamily:"Cursive", fontWeight:"700"}}
                  variant="text"
                >
                  Edit Topic
                </Button>
                <Button
                  onClick={() => handleDeleteTopic(cell.row.original._id, topic._id)}
                  sx={{ color: 'red',marginRight: '5px' ,fontFamily:"Cursive", fontWeight:"700"}}
                  variant="text"
                >
                  Delete Topic
                </Button>
              </div>

            </li>
          ))}
        </ul>
      ),
    },
    {
      header: "Actions",
      Cell: ({ cell }) => (
        <>
          <Button
            onClick={() => handleOpen(cell.row.original)}
            variant="text"
            sx={{
              backgroundColor: '#4CAF50',
              color: 'white',
              marginRight: '5px',
              '&:hover': {
                backgroundColor: '#4CAF50',
                color: 'white',
              },
            }}
          >
            Edit Chapter
          </Button>
          <Button
            sx={{
              backgroundColor: '#F44336',
              color: 'white',
              '&:hover': {
                backgroundColor: '#F44336',
                color: 'white',
              },
            }}
            variant="text"
            onClick={() => handleDeleteChapter(cell.row.original._id)}
          >
            Delete Chapter
          </Button>
        </>
      ),
    },
  ];

  const totalChapters = chapters.length;

  return (
    <>
      <h2 className={styles.heading}>Manage Chapters</h2>
      <div className={styles.container}>
        <label className={styles.selectLabel}>
          Select Course:
          <select
            value={selectedCourse}
            onChange={(e) => setSelectedCourse(e.target.value)}
            className={styles.selectInput}
          >
            {courses.map((course) => (
              <option key={course._id} value={course._id}>
                {course.courseTitle}
              </option>
            ))}
          </select>
        </label>

        {chapters.length > 0 ? (
          <>
            <Typography variant="h6" className={styles.totalChapters}>
              Total Chapters: {totalChapters}
            </Typography>
            <Button
              onClick={handleUpdateChapterOrder}
              variant="contained"
              className={styles.updateOrderButton}
            >
              Update Chapter Order
            </Button>
            <MaterialReactTable
              columns={columns}
              data={chapters}
              options={{
                showRowNumbers: true,
                enableRowSelection: false,
              }}
            />
          </>
        ) : (
          <div className={styles.noChapters}>No chapters available</div>
        )}

        <Modal open={open} onClose={handleClose}>
          <Box className={styles.modalBox} sx={{ width: '600px', padding: '20px' }}>
            <Typography variant="h6">
              {currentTopic ? "Edit Topic" : "Edit Chapter"}
            </Typography>
            {currentTopic ? (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label="Video Title"
                    fullWidth
                    value={newVideoData.videoTitle}
                    onChange={(e) =>
                      setNewVideoData({ ...newVideoData, videoTitle: e.target.value })
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Video URL"
                    fullWidth
                    value={newVideoData.videoYoutubeLink}
                    onChange={(e) =>
                      setNewVideoData({
                        ...newVideoData,
                        videoYoutubeLink: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="PDF Title"
                    fullWidth
                    value={newVideoData.pdfTitle}
                    onChange={(e) =>
                      setNewVideoData({
                        ...newVideoData,
                        pdfTitle: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="PDF URL"
                    fullWidth
                    value={newVideoData.selectedPdf}
                    onChange={(e) =>
                      setNewVideoData({
                        ...newVideoData,
                        selectedPdf: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    onClick={handleUpdateTopic}
                    sx={{ backgroundColor: '#4CAF50', color: 'white', marginRight: '5px' }}
                  >
                    Update
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <>
                <TextField
                  label="Chapter Title"
                  fullWidth
                  value={newTitle}
                  onChange={(e) => setNewTitle(e.target.value)}
                  sx={{ marginBottom: '20px' }}
                />
                <Button
                  variant="contained"
                  onClick={handleUpdateChapter}
                  sx={{ backgroundColor: '#4CAF50', color: 'white', marginRight: '5px' }}
                >
                  Update
                </Button>
              </>
            )}
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default ManageChapter;



// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import styles from "./ManageChapter.module.css";
// import { BsYoutube } from "react-icons/bs";

// const ManageChapter = () => {
//   const [courses, setCourses] = useState([]);
//   const [selectedCourse, setSelectedCourse] = useState("");
//   const [chapters, setChapters] = useState([]);

//   useEffect(() => {
//     const fetchCourses = async () => {
//       try {
//         const response = await axios.get("https://auth.ssccglpinnacle.com/api/courses");
//         setCourses(response.data);

//         if (response.data.length > 0) {
//           setSelectedCourse(response.data[0]._id);
//         }
//       } catch (error) {
//         console.error("Error fetching courses:", error);
//       }
//     };

//     fetchCourses();
//   }, []);

//   useEffect(() => {
//     const fetchChapters = async () => {
//       try {
//         if (selectedCourse) {
//           const response = await axios.get(
//             `https://auth.ssccglpinnacle.com/api/youtubeChapters/course/${selectedCourse}`
//           );
//           setChapters(response.data);
//         }
//       } catch (error) {
//         console.error("Error fetching chapters:", error);
//       }
//     };

//     if (selectedCourse) {
//       fetchChapters();
//     }
//   }, [selectedCourse]);

//   const handleUpdateChapter = async (chapterId, newTitle) => {
//     try {
//       const response = await axios.put(
//         `https://auth.ssccglpinnacle.com/api/vc/chapters/${chapterId}`,
//         { chapterTitle: newTitle }
//       );

//       if (response.status === 200) {
//         setChapters((prevChapters) =>
//           prevChapters.map((chapter) =>
//             chapter._id === chapterId
//               ? { ...chapter, chapterTitle: newTitle }
//               : chapter
//           )
//         );
//       }
//     } catch (error) {
//       console.error("Error updating chapter title:", error);
//     }
//   };

//   const handleUpdateTopic = async (chapterId, topicId, newData) => {
//     try {
//       const response = await axios.put(
//         `https://auth.ssccglpinnacle.com/api/vc/chapters/${chapterId}/topics/${topicId}`,
//         newData
//       );

//       if (response.status === 200) {
//         setChapters((prevChapters) =>
//           prevChapters.map((chapter) =>
//             chapter._id === chapterId
//               ? {
//                   ...chapter,
//                   topics: chapter.topics.map((topic) =>
//                     topic._id === topicId ? { ...topic, ...newData } : topic
//                   ),
//                 }
//               : chapter
//           )
//         );
//       }
//     } catch (error) {
//       console.error("Error updating topic:", error);
//     }
//   };

//   const handleDeleteTopic = async (chapterId, topicId) => {
//     try {
//       const response = await axios.delete(
//         `https://auth.ssccglpinnacle.com/api/videos/chapters/${chapterId}/topics/${topicId}`
//       );

//       if (response.status === 200) {
//         setChapters((prevChapters) =>
//           prevChapters.map((chapter) =>
//             chapter._id === chapterId
//               ? {
//                   ...chapter,
//                   topics: chapter.topics.filter(
//                     (topic) => topic._id !== topicId
//                   ),
//                 }
//               : chapter
//           )
//         );
//       }
//     } catch (error) {
//       console.error("Error deleting topic:", error);
//     }
//   };

//   return (
//     <div className={styles["Show-container"]}>
//       <label className={styles.selectLabel}>
//         Select Course:
//         <select
//           value={selectedCourse}
//           onChange={(e) => setSelectedCourse(e.target.value)}
//           className={styles.selectInput}
//         >
//           {courses.map((course) => (
//             <option key={course._id} value={course._id}>
//               {course.courseTitle}
//             </option>
//           ))}
//         </select>
//       </label>

//       {chapters.length > 0 && (
//         <div className={styles.chapterContainer}>
//           {chapters.map((chapter) => (
//             <div key={chapter._id} className={styles["Show-chapter"]}>
//               <div className={styles["Show-chapterTitle"]}>
//                 Chapter Title: {chapter.chapterTitle}
//                 <button
//                   className={styles["Show-editLink"]}
//                   onClick={() => {
//                     const newTitle = prompt(
//                       "Enter new chapter title:",
//                       chapter.chapterTitle
//                     );
//                     if (newTitle) {
//                       handleUpdateChapter(chapter._id, newTitle);
//                     }
//                   }}
//                 >
//                   Update Chapter
//                 </button>
//               </div>
//               <ul className={styles.topicList}>
//                 {chapter.topics.map((topic) => (
//                   <li key={topic._id} className={styles.topicItem}>
//                     <div className={styles.topicTitle}>
//                       <strong>Topic Title:</strong> {topic.videoTitle}
//                       <button
//                         className={styles["Show-editLink"]}
//                         onClick={() => {
//                           const newData = {
//                             videoTitle: prompt(
//                               "Enter new video title:",
//                               topic.videoTitle
//                             ),

//                             videoYoutubeLink: prompt(
//                               "Enter new video URL:",
//                               topic. videoYoutubeLink
//                             ),
//                             pdfTitle: prompt(
//                               "Enter new PDF title:",
//                               topic.pdfTitle
//                             ),
//                             selectedPdf: prompt(
//                               "Enter new PDF URL:",
//                               topic.selectedPdf
//                             ),
//                           };

//                           if (
//                             newData.videoTitle &&
//                             newData. videoYoutubeLink &&
//                             newData.pdfTitle &&
//                             newData.selectedPdf
//                           ) {
//                             handleUpdateTopic(chapter._id, topic._id, newData);
//                           }
//                         }}
//                       >
//                         Update Topic
//                       </button>
//                       <button
//                         className={styles["Show-DeleteBTN"]}
//                         onClick={() => {
//                           if (
//                             window.confirm(
//                               "Are you sure you want to delete this topic?"
//                             )
//                           ) {
//                             handleDeleteTopic(chapter._id, topic._id);
//                           }
//                         }}
//                       >
//                         Delete Topic
//                       </button>
//                     </div>
//                     <div>
//                       <strong>Video URL:</strong> {topic. videoYoutubeLink}
//                     </div>
//                     <div>
//                       <strong>PDF Title:</strong> {topic.pdfTitle}
//                     </div>
//                     <div>
//                       <strong>PDF URL:</strong> {topic.selectedPdf}
//                     </div>
//                   </li>
//                 ))}
//               </ul>
//             </div>
//           ))}
//         </div>
//       )}
//     </div>
//   );
// };

// export default ManageChapter;

// working code on 30.09.2024
// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { MaterialReactTable } from "material-react-table";
// import {
//   Button,
//   Modal,
//   Box,
//   Typography,
//   TextField,
//   Grid,
// } from "@mui/material";
// import Swal from "sweetalert2";
// import styles from "./ManageChapter.module.css";

// const ManageChapter = () => {
//   const [courses, setCourses] = useState([]);
//   const [selectedCourse, setSelectedCourse] = useState("");
//   const [chapters, setChapters] = useState([]);
//   const [open, setOpen] = useState(false);
//   const [currentChapter, setCurrentChapter] = useState(null);
//   const [currentTopic, setCurrentTopic] = useState(null);
//   const [newTitle, setNewTitle] = useState("");
//   const [newVideoData, setNewVideoData] = useState({
//     videoTitle: "",
//     videoYoutubeLink: "",
//     pdfTitle: "",
//     selectedPdf: "",
//   });

//   useEffect(() => {
//     const fetchCourses = async () => {
//       try {
//         const response = await axios.get("https://auth.ssccglpinnacle.com/api/courses");
//         setCourses(response.data);

//         if (response.data.length > 0) {
//           setSelectedCourse(response.data[0]._id);
//         }
//       } catch (error) {
//         console.error("Error fetching courses:", error);
//       }
//     };

//     fetchCourses();
//   }, []);

//   useEffect(() => {
//     const fetchChapters = async () => {
//       try {
//         if (selectedCourse) {
//           const response = await axios.get(
//             `https://auth.ssccglpinnacle.com/api/youtubeChapters/course/${selectedCourse}`
//           );
//           setChapters(response.data);
//         }
//       } catch (error) {
//         if (error.response && error.response.status === 404) {
//           setChapters([]); 
//         } else {
//           console.error("Error fetching chapters:", error);
//         }
//       }
//     };

//     if (selectedCourse) {
//       fetchChapters();
//     }
//   }, [selectedCourse]);

//   const handleUpdateChapter = async () => {
//     try {
//       const response = await axios.put(
//         `https://auth.ssccglpinnacle.com/api/vc/chapters/${currentChapter._id}`,
//         { chapterTitle: newTitle }
//       );

//       if (response.status === 200) {
//         setChapters((prevChapters) =>
//           prevChapters.map((chapter) =>
//             chapter._id === currentChapter._id
//               ? { ...chapter, chapterTitle: newTitle }
//               : chapter
//           )
//         );
//         handleClose();
//       }
//     } catch (error) {
//       console.error("Error updating chapter title:", error);
//     }
//   };

//   const handleDeleteChapter = async (chapterId) => {
//     const result = await Swal.fire({
//       title: 'Are you sure?',
//       text: "Do you really want to delete this chapter? This action cannot be undone!",
//       icon: 'warning',
//       showCancelButton: true,
//       confirmButtonColor: '#3085d6',
//       cancelButtonColor: '#d33',
//       confirmButtonText: 'Yes, delete it!',
//       cancelButtonText: 'Cancel',
//     });

//     if (result.isConfirmed) {
//       try {
//         const response = await axios.delete(
//           `https://auth.ssccglpinnacle.com/api/chapters/${chapterId}`
//         );

//         if (response.status === 200) {
//           setChapters((prevChapters) =>
//             prevChapters.filter((chapter) => chapter._id !== chapterId)
//           );
//           Swal.fire(
//             'Deleted!',
//             'The chapter has been deleted.',
//             'success'
//           );
//         }
//       } catch (error) {
//         console.error("Error deleting chapter:", error);
//         Swal.fire(
//           'Error!',
//           'There was an issue deleting the chapter. Please try again later.',
//           'error'
//         );
//       }
//     }
//   };

//   const handleUpdateTopic = async () => {
//     try {
//       const response = await axios.put(
//         `https://auth.ssccglpinnacle.com/api/vc/chapters/${currentChapter._id}/topics/${currentTopic._id}`,
//         newVideoData
//       );

//       if (response.status === 200) {
//         setChapters((prevChapters) =>
//           prevChapters.map((chapter) =>
//             chapter._id === currentChapter._id
//               ? {
//                   ...chapter,
//                   topics: chapter.topics.map((topic) =>
//                     topic._id === currentTopic._id
//                       ? { ...topic, ...newVideoData }
//                       : topic
//                   ),
//                 }
//               : chapter
//           )
//         );
//         handleClose();
//       }
//     } catch (error) {
//       console.error("Error updating topic:", error);
//     }
//   };

//   const handleDeleteTopic = async (chapterId, topicId) => {
//     const result = await Swal.fire({
//       title: 'Are you sure?',
//       text: "Do you really want to delete this topic? This action cannot be undone!",
//       icon: 'warning',
//       showCancelButton: true,
//       confirmButtonColor: '#3085d6',
//       cancelButtonColor: '#d33',
//       confirmButtonText: 'Yes, delete it!',
//       cancelButtonText: 'Cancel',
//     });

//     if (result.isConfirmed) {
//       try {
//         const response = await axios.delete(
//           `https://auth.ssccglpinnacle.com/api/videos/chapters/${chapterId}/topics/${topicId}`
//         );

//         if (response.status === 200) {
//           setChapters((prevChapters) =>
//             prevChapters.map((chapter) =>
//               chapter._id === chapterId
//                 ? {
//                     ...chapter,
//                     topics: chapter.topics.filter(
//                       (topic) => topic._id !== topicId
//                     ),
//                   }
//                 : chapter
//             )
//           );
//           Swal.fire(
//             'Deleted!',
//             'The topic has been deleted.',
//             'success'
//           );
//         }
//       } catch (error) {
//         console.error("Error deleting topic:", error);
//         Swal.fire(
//           'Error!',
//           'There was an issue deleting the topic. Please try again later.',
//           'error'
//         );
//       }
//     }
//   };

//   const handleOpen = (chapter, topic = null) => {
//     setCurrentChapter(chapter);
//     setCurrentTopic(topic);
//     if (topic) {
//       setNewVideoData({
//         videoTitle: topic.videoTitle,
//         videoYoutubeLink: topic.videoYoutubeLink,
//         pdfTitle: topic.pdfTitle,
//         selectedPdf: topic.selectedPdf,
//       });
//     } else {
//       setNewTitle(chapter.chapterTitle);
//     }
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//     setNewTitle("");
//     setNewVideoData({
//       videoTitle: "",
//       videoYoutubeLink: "",
//       pdfTitle: "",
//       selectedPdf: "",
//     });
//   };

//   const columns = [
//     {
//       accessorKey: "chapterTitle",
//       header: "Chapter Title",
//       Cell: ({ cell }) => (
//         <span>{cell.getValue()}</span>
//       ),
//     },
//     {
//       accessorKey: "topics",
//       header: "Topics",
//       Cell: ({ cell }) => (
//         <ul>
//           {cell.row.original.topics.map((topic) => (
//             <li key={topic._id}>
//               <strong>Topic Title:</strong> {topic.videoTitle}
//               <div>
//                 <strong>Video URL:</strong> {topic.videoYoutubeLink}
//               </div>
//               <div>
//                 <strong>PDF Title:</strong> {topic.pdfTitle}
//               </div>
//               <div>
//                 <strong>PDF URL:</strong> {topic.selectedPdf}
//               </div>
//               <div>
//                 <Button
//                   onClick={() => handleOpen(cell.row.original, topic)}
//                   sx={{  color: 'green', marginRight: '5px' ,fontFamily:"Cursive", fontweight:"700"}}
//                   variant="text"
//                 >
//                   Edit Topic
//                 </Button>
//                 <Button
//                   onClick={() => handleDeleteTopic(cell.row.original._id, topic._id)}
//                   sx={{ color: 'red',marginRight: '5px' ,fontFamily:"Cursive", fontweight:"700"}}
//                   variant="text"
//                 >
//                   Delete Topic
//                 </Button>
//               </div>
//             </li>
//           ))}
//         </ul>
//       ),
//     },
//     {
//       header: "Actions",
//       Cell: ({ cell }) => (
//         <>
//           <Button
//   onClick={() => handleOpen(cell.row.original)}
//   variant="text"
//   sx={{
//     backgroundColor: '#4CAF50',
//     color: 'white',
//     marginRight: '5px',
//     '&:hover': {
//       backgroundColor: '#4CAF50', 
//       color: 'white', 
//     },
//   }}
// >
//   Edit Chapter
// </Button>
// <Button
//   sx={{
//     backgroundColor: '#F44336',
//     color: 'white',
//     '&:hover': {
//       backgroundColor: '#F44336', // Same as default background color
//       color: 'white', // Same as default text color
//     },
//   }}
//   variant="text"
//   onClick={() => handleDeleteChapter(cell.row.original._id)}
// >
//   Delete Chapter
// </Button>

//         </>
//       ),
//     },
//   ];

//   const totalChapters = chapters.length;

//   return (
//     <>
//       <h2 className={styles.heading}>Manage Chapters</h2>
//       <div className={styles.container}>
//         <label className={styles.selectLabel}>
//           Select Course:
//           <select
//             value={selectedCourse}
//             onChange={(e) => setSelectedCourse(e.target.value)}
//             className={styles.selectInput}
//           >
//             {courses.map((course) => (
//               <option key={course._id} value={course._id}>
//                 {course.courseTitle}
//               </option>
//             ))}
//           </select>
//         </label>

//         {chapters.length > 0 ? (
//           <>
//             <Typography variant="h6" className={styles.totalChapters}>
//               Total Chapters: {totalChapters}
//             </Typography>
//             <MaterialReactTable
//               columns={columns}
//               data={chapters}
//               options={{
//                 showRowNumbers: true,
//                 enableRowSelection: false,
//               }}
//             />
//           </>
//         ) : (
//           <div className={styles.noChapters}>No chapters available</div>
//         )}

// <Modal open={open} onClose={handleClose}>
//   <Box className={styles.modalBox} sx={{ width: '600px', padding: '20px' }}>
//     <Typography variant="h6">
//       {currentTopic ? "Edit Topic" : "Edit Chapter"}
//     </Typography>
//     {currentTopic ? (
//       <Grid container spacing={2}>
//         <Grid item xs={12}>
//           <TextField
//             label="Video Title"
//             fullWidth
//             value={newVideoData.videoTitle}
//             onChange={(e) =>
//               setNewVideoData({ ...newVideoData, videoTitle: e.target.value })
//             }
//           />
//         </Grid>
//         <Grid item xs={12}>
//           <TextField
//             label="Video URL"
//             fullWidth
//             value={newVideoData.videoYoutubeLink}
//             onChange={(e) =>
//               setNewVideoData({
//                 ...newVideoData,
//                 videoYoutubeLink: e.target.value,
//               })
//             }
//           />
//         </Grid>
//         <Grid item xs={12}>
//           <TextField
//             label="PDF Title"
//             fullWidth
//             value={newVideoData.pdfTitle}
//             onChange={(e) =>
//               setNewVideoData({
//                 ...newVideoData,
//                 pdfTitle: e.target.value,
//               })
//             }
//           />
//         </Grid>
//         <Grid item xs={12}>
//           <TextField
//             label="PDF URL"
//             fullWidth
//             value={newVideoData.selectedPdf}
//             onChange={(e) =>
//               setNewVideoData({
//                 ...newVideoData,
//                 selectedPdf: e.target.value,
//               })
//             }
//           />
//         </Grid>
//         <Grid item xs={12}>
//           <Button
//             variant="contained"
//             onClick={handleUpdateTopic}
//             sx={{ backgroundColor: '#4CAF50', color: 'white', marginRight: '5px' }}
//           >
//             Update
//           </Button>
//         </Grid>
//       </Grid>
//     ) : (
//       <>
//         <TextField
//           label="Chapter Title"
//           fullWidth
//           value={newTitle}
//           onChange={(e) => setNewTitle(e.target.value)}
//           sx={{ marginBottom: '20px' }}
//         />
//         <Button
//           variant="contained"
//           onClick={handleUpdateChapter}
//           sx={{ backgroundColor: '#4CAF50', color: 'white', marginRight: '5px' }}
//         >
//           Update
//         </Button>
//       </>
//     )}
//   </Box>
// </Modal>

//       </div>
//     </>
//   );
// };

// export default ManageChapter;

  




