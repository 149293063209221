import { useLocation, useParams, useNavigate } from "react-router-dom";
import "./MylearningRow2.css"
import MylearningRow2 from "./MylearningRow2"
import MylearningRow4 from "./MylearningRow4";
import Mylearning from "./Mylearning";
import React, { useState, useEffect } from "react";
import Header from "../../component/DigitalcontentHeader/Header";


function Mylearn() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { cardId } = useParams();

   return ( 
       <>
            <div className="main-body-container-mylearning">
       <Header/>
       <Mylearning cardId = {cardId}/>
       <MylearningRow2/>
       <MylearningRow4/>
       </div>
       
    </>
  )  
}

export default Mylearn