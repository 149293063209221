import React, { useState } from "react";
import "./ProgressRow2.css";
import { FiChevronRight, FiChevronDown } from 'react-icons/fi';
import { BsCheckLg } from 'react-icons/bs';
import { useAuth } from "../../../Context/AuthContext";
import Swal from "sweetalert2";
import { useParams, Navigate, useNavigate } from "react-router-dom";

const ProgressRow2 = ({ chaptersForEbook }) => {
  const { cardId } = useParams();
  const { user, token } = useAuth();
  const currentUser = user ? user._id : null; // Add a null check here
  const [activeIndex, setActiveIndex] = useState(null);
  const navigate = useNavigate();

  const toggleAccordion = (index) => {
    if (index === activeIndex) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  const resetProgress = async () => {
    if (!currentUser) return;

    try {
      const response = await fetch(`https://auth.ssccglpinnacle.com/api/reset-progress/${currentUser}`, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
      });

      if (response.ok) {
        Swal.fire(
          'Reset Successful!',
          'Your progress has been reset.',
          'success'
        );
        window.location.reload(); // Refresh the page to reflect changes
      } else {
        Swal.fire(
          'Reset Failed!',
          'Failed to reset your progress.',
          'error'
        );
      }
    } catch (error) {
      console.error("Error resetting progress:", error);
      Swal.fire(
        'Error!',
        'An error occurred while resetting your progress.',
        'error'
      );
    }
  };

  const confirmReset = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: "Do you really want to reset your progress?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, reset it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.isConfirmed) {
        resetProgress();
      }
    });
  };

  
  const navigateToOtherPage1 = () => {
    navigate(`/ebook/${cardId}/digitalPage1`);
  };

  return (
    <div className="progress-topics">
      <div className="progress-topics-header">
        <h2>Your Progress</h2>
        <h6 onClick={confirmReset} style={{ cursor: 'pointer', color: 'blue' }}>Reset your progress</h6>
      </div>
      <div className="accordion-container-progress">
        {chaptersForEbook.map((chapter, index) => {
          const isChapterCompleted = chapter.completed.find(
            completion => completion.user === currentUser
          );

          const completionStatus = isChapterCompleted
            ? isChapterCompleted.userCompleted
            : null;

          return (
            <div
              key={index}
              className={`accordion-card-progress ${index === activeIndex ? "expanded" : ""}`}
            >
              <div className="container-for-accordian">
                <div
                  className="accordion-header-progress"
                  onClick={() => navigateToOtherPage1()}  
                >
                  <button className="accoridan-button-progress">
                    <div className="card-progress-inner-header">
                      <div
                        className="circle-completed"
                        style={{
                          backgroundColor: isChapterCompleted
                            ? 'rgb(217, 238, 225)'
                            : '#fff'
                        }}
                      >
                        {isChapterCompleted && completionStatus === 'complete' && (
                          <BsCheckLg size="20px" className="checkmark-icon" />
                        )}
                      </div>
                      <div className="chapter-name-progress">
                        {index + 1}.{chapter.title}
                      </div>
                      <div className="inprogress">
                        <span
                          className={`showing-progress ${
                            completionStatus === 'complete'
                              ? 'completed-ebook'
                              : completionStatus === 'inprogress'
                              ? 'inprogress1'
                              : 'not-started'
                          }`}
                        >
                          {completionStatus === 'complete'
                            ? "DONE"
                            : completionStatus === 'inprogress'
                            ? "IN PROGRESS"
                            : "NOT STARTED"}
                        </span>
                      </div>
                      <div className="chapter-topic-completed">
                        {chapter.completed.filter(
                          completion =>
                            completion.user === currentUser &&
                            completion.userCompleted
                        ).length}{' '}
                        of {chapter.length} lessons completed
                      </div>
                      <div className="icon-changed">
                        {index === activeIndex ? (
                          <FiChevronDown
                            className="icon-for-accordian"
                            size="20px"
                          />
                        ) : (
                          <FiChevronRight
                            className="icon-for-accordian"
                            size="20px"
                          />
                        )}
                      </div>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ProgressRow2;
