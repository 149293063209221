// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RefundPolicy_refundContainer__Uhwom {
    padding:6rem;
    max-width: 800px;
    margin: 0 auto;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-top:80px;
    
}

.RefundPolicy_refundContainer__Uhwom h2 {
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
}

.RefundPolicy_refundContainer__Uhwom p {
    font-size: 16px;
    line-height: 1.6;
    color: #555;
}

.RefundPolicy_errorMessage__NO15X {
    color: #d9534f;
    font-weight: bold;
    text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/FooterMain/RefundPolicy.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,gBAAgB;IAChB,cAAc;IACd,yBAAyB;IACzB,sBAAsB;IACtB,kBAAkB;IAClB,eAAe;;AAEnB;;AAEA;IACI,eAAe;IACf,WAAW;IACX,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,WAAW;AACf;;AAEA;IACI,cAAc;IACd,iBAAiB;IACjB,kBAAkB;AACtB","sourcesContent":[".refundContainer {\n    padding:6rem;\n    max-width: 800px;\n    margin: 0 auto;\n    background-color: #f9f9f9;\n    border: 1px solid #ddd;\n    border-radius: 8px;\n    margin-top:80px;\n    \n}\n\n.refundContainer h2 {\n    font-size: 24px;\n    color: #333;\n    margin-bottom: 20px;\n    text-align: center;\n}\n\n.refundContainer p {\n    font-size: 16px;\n    line-height: 1.6;\n    color: #555;\n}\n\n.errorMessage {\n    color: #d9534f;\n    font-weight: bold;\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"refundContainer": `RefundPolicy_refundContainer__Uhwom`,
	"errorMessage": `RefundPolicy_errorMessage__NO15X`
};
export default ___CSS_LOADER_EXPORT___;
