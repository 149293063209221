// Cartheader.js
import { BsCart2, BsList } from "react-icons/bs";
import { FaListUl } from "react-icons/fa6";
import React, { useState, useEffect } from 'react';
import './Cartheader.css';
import Searchcart from "./SearchcartBOOK";
import lightImage  from "../HeaderFilesBook/images/pinnaclelogo.png";
import { Link, useNavigate } from 'react-router-dom';
import { BsTrophy } from "react-icons/bs";
import { useAuth } from "../../Context/AuthContext";

const Cartheader = () => {
  const [isNav2Visible, setNav2Visible] = useState(true); // Initially visible
  const [isScrolled, setScrolled] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [subtotal, setSubtotal] = useState(0);
  const { isLoggedIn, user } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    const userId = user?._id; // Replace with the actual user ID
    if (userId) {
      // Fetch cart data from the API
      fetch(`https://auth.ssccglpinnacle.com/api/cart-book/${userId}`)
        .then((response) => response.json())
        .then((data) => {
          setCartItems(data); // Update cartItems in the state
          // Calculate subtotal based on the updated cartItems
          const newSubtotal = data.reduce((total, item) => total + item.product.bookSellingPrice, 0);
          setSubtotal(newSubtotal);
         
        })
        .catch((error) => console.error("Error fetching cart data:", error));
    }
  }, [user]);

  const toggleNav2 = () => {
    setNav2Visible(!isNav2Visible);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;

      if (scrollY > 20) {
        setScrolled(true);
        setNav2Visible(false);
      } else {
        setScrolled(false);
        setNav2Visible(true);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const info = () => {
    navigate("/books-catalog");
  };
  const info2 = () => {
    navigate("/cart-book");
  };

  return (
    <div className={`cartheader ${isScrolled ? 'scrolled' : ''}`}>
        <div className="nav-icon" onClick={toggleNav2}>
        <FaListUl size={28}/>
      </div>
     <div className="headerimage-wishlist">
           <img src={lightImage} alt="Component Image" />
           </div>
      <div className={`nav2 ${isNav2Visible ? '' : 'nav2-hidden'}`}>
        <div className = "element-of-the-cart-header"><div className ="books-cart-header">Books</div></div>
        <div className = "element-of-the-cart-header"><div className ="books-cart-header">Videos</div></div>
        <div className = "element-of-the-cart-header" onClick={info}><div className ="books-cart-header">Digital ebooks</div></div>
        <div className = "element-of-the-cart-header"><div className ="books-cart-header">Test pass</div></div>
        <div className = "element-of-the-cart-header"><div className ="books-cart-header">Railway kit</div></div>
      </div>
     <Searchcart/>
     <div className="cart-icon-header-get-started" onClick={info}>
        <BsTrophy className="cart-on-header-get-started" size={28} />
       
        <p className="get-started">Get started</p>
      </div>
      <div className="cart-icon-header-wish"onClick={info2}>
            <BsCart2 className="cart-on-header-wish" size={28} />
            <div className="circle-wish">{cartItems.length}</div>
          </div>
    </div>
  );
};

export default Cartheader;
