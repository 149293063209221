import React, { useState } from "react";
import styles from "./EditListModal.module.css";
import axios from "axios";

function EditListModal({ list, onClose, onEditSubmit }) {
  const [listTitle, setListTitle] = useState(list.listTitle);
  const [description, setDescription] = useState(list.description);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const updatedList = {
        ...list,
        listTitle,
        description,
      };

      // Call the API to update the list
      const response = await axios.put(
        `https://auth.ssccglpinnacle.com/api/userCourseList/${list._id}`,
        updatedList
      );

      if (response.status === 200) {
        onEditSubmit(response.data.updatedList);
        onClose(); // Close the modal after successful edit
      }
    } catch (error) {
      console.error("Failed to update the list:", error);
    }
  };

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modal}>
        <h2>Edit your list</h2>
        <form onSubmit={handleSubmit}>
          <div className={styles.formGroup}>
            <input
              type="text"
              value={listTitle}
              onChange={(e) => setListTitle(e.target.value)}
              maxLength="60"
              required
            />
            <div className={styles.charCount}>{60 - listTitle.length}</div>
          </div>
          <div className={styles.formGroup}>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              maxLength="200"
              required
            ></textarea>
            <div className={styles.charCount}>{200 - description.length}</div>
          </div>
          <div className={styles.actions}>
            <button type="button" onClick={onClose} className={styles.cancelButton}>
              Cancel
            </button>
            <button type="submit" className={styles.saveButton}>
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default EditListModal;
