import React from "react";
import "./Footer.css";
import Col from "react-bootstrap/Col";
import facebook from "./footerimg/facebook.svg";
import gmail from "./footerimg/gmail.svg";
import insta from "./footerimg/insta.svg";
import telegram from "./footerimg/telegram.svg";
import twitter from "./footerimg/twitter.svg";
import youtube from "./footerimg/youtube.svg";
import play from "./footerimg/play.svg";
import whatsapp from "./footerimg/whatsapp.svg";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
      <div className="site-footer">
        <div className="row">
          <Col className="footer-container" xs={6} md={3}>
            <h5>
              {/* <a href="/">About Us</a> */}
              <Link to="/aboutus">About Us</Link>
            </h5>
            <ul className="footer-links">
              <li>
            <Link to="/privacyPolicy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/footer/Terms-and-Condition">Terms &amp; Conditions</Link>
              </li>
              <li>
                <Link to="/footer/Refund-Policy">Refund Policy</Link>
              </li>
              <li>
              <Link to="/footer/Contact-Us">Contact Us</Link>
              </li>
              <li>
                <img className="gmail-img" src={gmail} />
                <a href="/">support@ssccglpinnacle.com</a>
              </li>
            </ul>
          </Col>

          <Col className="footer-container" xs={6} md={3}>
            <h5>Explore</h5>
            <ul className="footer-links">
            <li>
            <a href="https://testportal.ssccglpinnacle.com">Test Portal</a>
              </li>
            <li>
                <Link to="/typingexamselection"> Typing</Link>
              </li>
              <li>
                <Link to="/books-catalog"> Books</Link>
              </li>
              <li>
                <Link to="/videos"> Videos</Link>
              </li>
              <li>
                <Link to="/digital-catalog">E-Books</Link>
              </li>
              <li>
                <Link to="/blog">Blogs</Link>
              </li>
            </ul>
          </Col>

          <Col className="footer-container" xs={6} md={3}>
            <h5 className="connect">Connect</h5>
            <ul className="footer-links">
            <li>
                <img className="telegram-img" src={youtube} />
                <a href="https://www.youtube.com/channel/UCn3aOx3FoeRUzVhOHL5U5Mw" target="_blank" rel="noopener noreferrer">YouTube</a>
              </li>
              <li>
                <img className="telegram-img" src={telegram} />
                <a href="https://t.me/ssccglpinnacleonline" target="_blank" rel="noopener noreferrer">Telegram Channel</a>
              </li>
              <li>
                <img className="whatsapp-img" src={whatsapp} />
                <a href="https://www.whatsapp.com/channel/0029VaFZ5QTC6ZvcTFgbMe2J" target="_blank" rel="noopener noreferrer">Whatsapp</a>
              </li>
              {/* <li>
                <img className="facebook-img" src={facebook} />
                <a href="/">Facebook</a>
              </li> */}
              {/* <li>
                <img className="insta-img" src={insta} />
                <a href="/">Instagram</a>
              </li> */}
              {/* <li>
                <img className="twitter-img" src={twitter} />
                <a href="/">Twitter</a>
              </li> */}
              <li>
                <img className="play-img" src={play} />
                <a
                href="https://play.google.com/store/apps/details?id=com.ssccgl.pinnacle.testportal"
                target="_blank"
                rel="noopener noreferrer"
              >
                Download App
              </a>
              </li>
            </ul>
          </Col>
        </div>
      </div>
    </>
  );
}

export default Footer;
