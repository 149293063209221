// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Cart_Cart_card__ruH-D {
  border: 0.5px #d1d7dc solid;
  padding: 1%;
  width: 25%;
  position: absolute;
  right: 1%;
  z-index: 1;
  top: 5%;
}
.Cart_dropdown__KPszY {
  color: white;
  padding: 9px;
  font-size: 16px;
  border: none;
  height: 100%;
}
.Cart_dropdown__KPszY:hover .Cart_dropdown_content__sjyZB {
  display: block;
}
.Cart_cart_btn__TW08W {
  font-size: 16px;
  font-weight: 600;
  color: black;
}
.Cart_cart__tm2z\\+ {
  line-height: 3rem;
  text-align: center;
  font-size: 16px;
  color: black;
}
.Cart_cart_shopping__fE74c {
  margin-top: 2%;
  font-size: 14px;
  color: #0a90e9;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/VIDEOPLAYER COMPONENTS/components/VideoHeader/Cart.module.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,WAAW;EACX,UAAU;EACV,kBAAkB;EAClB,SAAS;EACT,UAAU;EACV,OAAO;AACT;AACA;EACE,YAAY;EACZ,YAAY;EACZ,eAAe;EACf,YAAY;EACZ,YAAY;AACd;AACA;EACE,cAAc;AAChB;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,YAAY;AACd;AACA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,eAAe;EACf,YAAY;AACd;AACA;EACE,cAAc;EACd,eAAe;EACf,cAAc;EACd,kBAAkB;AACpB","sourcesContent":[".Cart_card {\n  border: 0.5px #d1d7dc solid;\n  padding: 1%;\n  width: 25%;\n  position: absolute;\n  right: 1%;\n  z-index: 1;\n  top: 5%;\n}\n.dropdown {\n  color: white;\n  padding: 9px;\n  font-size: 16px;\n  border: none;\n  height: 100%;\n}\n.dropdown:hover .dropdown_content {\n  display: block;\n}\n.cart_btn {\n  font-size: 16px;\n  font-weight: 600;\n  color: black;\n}\n.cart {\n  line-height: 3rem;\n  text-align: center;\n  font-size: 16px;\n  color: black;\n}\n.cart_shopping {\n  margin-top: 2%;\n  font-size: 14px;\n  color: #0a90e9;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Cart_card": `Cart_Cart_card__ruH-D`,
	"dropdown": `Cart_dropdown__KPszY`,
	"dropdown_content": `Cart_dropdown_content__sjyZB`,
	"cart_btn": `Cart_cart_btn__TW08W`,
	"cart": `Cart_cart__tm2z+`,
	"cart_shopping": `Cart_cart_shopping__fE74c`
};
export default ___CSS_LOADER_EXPORT___;
