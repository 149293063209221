import React, { useEffect, useRef } from 'react';
import classes from "./YT_VideoPlayer.module.css";

const YTVideoPlayer = ({ videoId, onVideoWatched }) => {
  const playerRef = useRef(null);

  useEffect(() => {
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    window.onYouTubeIframeAPIReady = function () {
      initializePlayer();
    };

    return () => {
      if (playerRef.current && playerRef.current.destroy) {
        playerRef.current.destroy();
      }
    };
  }, []);

  useEffect(() => {
    if (playerRef.current && playerRef.current.loadVideoById) {
      playerRef.current.loadVideoById(videoId);
    } else {
      const interval = setInterval(() => {
        if (window.YT && window.YT.Player) {
          initializePlayer();
          clearInterval(interval);
        }
      }, 500);
    }
  }, [videoId]);

  const initializePlayer = () => {
    if (playerRef.current) {
      playerRef.current.destroy();
    }

    playerRef.current = new window.YT.Player('player', {
      height: '900',
      width: '1200',
      videoId: videoId,
      playerVars: {
        autoplay: 0,
        controls: 1,
        modestbranding: 1,
        rel: 0,
        enablejsapi: 1,
        pictureInPicture: 1,
        webShare: 0,
      },
      events: {
        'onStateChange': onPlayerStateChange,
        'onReady': onPlayerReady
      }
    });
  };

  const onPlayerReady = (event) => {
    const totalDuration = event.target.getDuration();
    let interval = setInterval(() => {
      const currentTime = event.target.getCurrentTime();
      if (currentTime / totalDuration >= 0.9) {
        onVideoWatched(videoId);
        clearInterval(interval);
      }
    }, 1000);
  };

  const onPlayerStateChange = (event) => {
    if (event.data === window.YT.PlayerState.ENDED) {
      console.log('Video has ended');
    }
  };

  return (
    <div className={classes.videoContainer}>
      <div id="player"></div>
    </div>
  );
};

export default YTVideoPlayer;
