// import React, { useState, useEffect } from 'react';
// import Swal from "sweetalert2";
// import styles from "./AddTopicForm.module.css";

// const AddTopicForm = () => {
//   const [courses, setCourses] = useState([]);
//   const [selectedCourse, setSelectedCourse] = useState('');
//   const [chapters, setChapters] = useState([]);
//   const [selectedChapter, setSelectedChapter] = useState('');
//   const [videoTitle, setVideoTitle] = useState('');
//   const [videoYoutubeLink, setVideoYoutubeLink] = useState('');
//   const [pdfTitle, setPdfTitle] = useState('');
//   const [selectedPdf, setSelectedPdf] = useState('');
//   const [pdfs, setPdfs] = useState([]);

//   // Fetch Courses
//   useEffect(() => {
//     const fetchCourses = async () => {
//       try {
//         const response = await fetch('https://auth.ssccglpinnacle.com/api/courses');
//         const data = await response.json();
//         setCourses(Array.isArray(data) ? data : []);
//         setSelectedCourse(data[0]?._id || '');
//       } catch (error) {
//         console.error('Error fetching courses:', error);
//       }
//     };
//     fetchCourses();
//   }, []);

//   // Fetch Chapters according to selected course
//   useEffect(() => {
//     const fetchChapters = async () => {
//       if (!selectedCourse) return;
//       try {
//         const response = await fetch(`https://auth.ssccglpinnacle.com/api/youtubeChapters/course/${selectedCourse}`);
//         const data = await response.json();
//         setChapters(Array.isArray(data) ? data : []);
//         setSelectedChapter(data[0]?._id || '');
//       } catch (error) {
//         console.error('Error fetching chapters:', error);
//       }
//     };
//     fetchChapters();
//   }, [selectedCourse]);

//   // Fetch PDFs based on the selected course
//   useEffect(() => {
//     if (selectedCourse) {
//       const fetchPdfs = async () => {
//         try {
//           const response = await fetch(`https://auth.ssccglpinnacle.com/api/pdfs/course/${selectedCourse}`);
//           const data = await response.json();
//           setPdfs(data);
//         } catch (error) {
//           console.error('Error fetching PDFs:', error);
//         }
//       };
//       fetchPdfs();
//     }
//   }, [selectedCourse]);

//   // Handle Add Topic form submission
//   const handleAddTopic = async () => {
//     if (!selectedChapter || !videoTitle || !videoYoutubeLink || !pdfTitle || !selectedPdf) {
//       Swal.fire({
//         title: 'Warning!',
//         text: 'Please fill in all required fields.',
//         icon: 'warning',
//         confirmButtonText: 'OK'
//       });
//       return;
//     }

//     const newTopic = {
//       videoTitle,
//       videoYoutubeLink,
//       pdfTitle,
//       selectedPdf,
//     };

//     try {
//       const response = await fetch(`https://auth.ssccglpinnacle.com/api/videos/chapters/${selectedChapter}/add-topics`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(newTopic),
//       });

//       if (!response.ok) {
//         throw new Error('Failed to add topic');
//       }
//       // Clear inputs after submission
//       setVideoTitle('');
//       setVideoYoutubeLink('');
//       setPdfTitle('');
//       setSelectedPdf('');
//       Swal.fire({
//         title: 'Success!',
//         text: 'Topic successfully added!',
//         icon: 'success',
//         confirmButtonText: 'OK'
//       });
//     } catch (error) {
//       Swal.fire({
//         title: 'Failed!',
//         text: 'Error adding topic: ' + error.message,
//         icon: 'error',
//         confirmButtonText: 'OK'
//       });
//     }
//   };

//   return (
//     <div className={styles['topic-form']}>
//       <h3 className={styles.formTitle}>Add New Topic</h3>

//       {/* Course selection */}
//       <label className={styles.label}>Select Course:</label>
//       <select
//         name="course"
//         value={selectedCourse}
//         className={styles.selectField}
//         onChange={(e) => setSelectedCourse(e.target.value)}>
//         <option value="" disabled>Select the course</option>
//         {courses.map((course) => (
//           <option key={course._id} value={course._id}>{course.courseTitle}</option>
//         ))}
//       </select>

//       {/* Chapter selection */}
//       <label className={styles.label}>Select Chapter:</label>
    
//       <select
//         name="chapter"
//         value={selectedChapter}
//         className={styles.selectField}
//         onChange={(e) => setSelectedChapter(e.target.value)}>
//        <option value="" disabled>Select a chapter</option>
//         {chapters.map((chapter) => (
         
//           <option key={chapter._id} value={chapter._id}>{chapter.chapterTitle}</option>
//         ))}
//       </select>

//       {/* Video title input */}
//       <label className={styles.label}>Video Title:</label>
//       <input
//         type="text"
//         value={videoTitle}
//         className={styles.inputField}
//         onChange={(e) => setVideoTitle(e.target.value)}
//         required
//       />

//       {/* YouTube link input */}
//       <label className={styles.label}>YouTube Video Link:</label>
//       <input
//         type="text"
//         value={videoYoutubeLink}
//         className={styles.inputField}
//         onChange={(e) => setVideoYoutubeLink(e.target.value)}
//         required
//       />

//       {/* PDF title input */}
//       <label className={styles.label}>PDF Title:</label>
//       <input
//         type="text"
//         value={pdfTitle}
//         className={styles.inputField}
//         onChange={(e) => setPdfTitle(e.target.value)}
//       />

//       {/* PDF selection */}
//       <label className={styles.label}>Select PDF:</label>
//       <select
//         value={selectedPdf}
//         className={styles.selectField}
//         onChange={(e) => setSelectedPdf(e.target.value)}>
//         <option value="" disabled>Select a PDF</option>
//         {pdfs.length > 0 ? (
//           pdfs.map(pdf => (
//             <option key={pdf._id} value={pdf._id}>{pdf.originalname}</option>
//           ))
//         ) : (
//           <option value="" disabled>No PDFs available</option>
//         )}
//       </select>

//       <button type="button" onClick={handleAddTopic} className={styles.submitButton}>Add Topic</button>
//     </div>
//   );
// };

// export default AddTopicForm;


import React, { useState, useEffect } from 'react';
import Swal from "sweetalert2";
import styles from "./AddTopicForm.module.css";

const AddTopicForm = () => {
  const [courses, setCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState('');
  const [chapters, setChapters] = useState([]);
  const [selectedChapter, setSelectedChapter] = useState('');
  const [videoTitle, setVideoTitle] = useState('');
  const [videoYoutubeLink, setVideoYoutubeLink] = useState('');
  const [pdfTitle, setPdfTitle] = useState('');
  const [selectedPdf, setSelectedPdf] = useState('');
  const [pdfs, setPdfs] = useState([]);

  // Fetch Courses
  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await fetch('https://auth.ssccglpinnacle.com/api/courses');
        const data = await response.json();
        setCourses(Array.isArray(data) ? data : []);
        setSelectedCourse(data[0]?._id || '');
      } catch (error) {
        console.error('Error fetching courses:', error);
      }
    };
    fetchCourses();
  }, []);

  // Fetch Chapters according to selected course
  useEffect(() => {
    const fetchChapters = async () => {
      if (!selectedCourse) return;
      try {
        const response = await fetch(`https://auth.ssccglpinnacle.com/api/youtubeChapters/course/${selectedCourse}`);
        const data = await response.json();
        setChapters(Array.isArray(data) ? data : []);
        setSelectedChapter(data[0]?._id || '');
      } catch (error) {
        console.error('Error fetching chapters:', error);
        setChapters([]); // Ensure chapters is reset if there's an error
      }
    };
    fetchChapters();
  }, [selectedCourse]);

  // Fetch PDFs based on the selected course
  useEffect(() => {
    if (selectedCourse) {
      const fetchPdfs = async () => {
        try {
          const response = await fetch(`https://auth.ssccglpinnacle.com/api/pdfs/course/${selectedCourse}`);
          const data = await response.json();
          setPdfs(data);
        } catch (error) {
          console.error('Error fetching PDFs:', error);
        }
      };
      fetchPdfs();
    }
  }, [selectedCourse]);

  // Handle Add Topic form submission
  const handleAddTopic = async () => {
    if (!selectedChapter || !videoTitle || !videoYoutubeLink || !pdfTitle || !selectedPdf) {
      Swal.fire({
        title: 'Warning!',
        text: 'Please fill in all required fields.',
        icon: 'warning',
        confirmButtonText: 'OK'
      });
      return;
    }

    const newTopic = {
      videoTitle,
      videoYoutubeLink,
      pdfTitle,
      selectedPdf,
    };

    try {
      const response = await fetch(`https://auth.ssccglpinnacle.com/api/videos/chapters/${selectedChapter}/add-topics`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newTopic),
      });

      if (!response.ok) {
        throw new Error('Failed to add topic');
      }
      // Clear inputs after submission
      setVideoTitle('');
      setVideoYoutubeLink('');
      setPdfTitle('');
      setSelectedPdf('');
      Swal.fire({
        title: 'Success!',
        text: 'Topic successfully added!',
        icon: 'success',
        confirmButtonText: 'OK'
      });
    } catch (error) {
      Swal.fire({
        title: 'Failed!',
        text: 'Error adding topic: ' + error.message,
        icon: 'error',
        confirmButtonText: 'OK'
      });
    }
  };

  return (
    <div className={styles['topic-form']}>
      <h3 className={styles.formTitle}>Add New Topic</h3>

      {/* Course selection */}
      <label className={styles.label}>Select Course:</label>
      <select
        name="course"
        value={selectedCourse}
        className={styles.selectField}
        onChange={(e) => setSelectedCourse(e.target.value)}>
        <option value="" disabled>Select the course</option>
        {courses.map((course) => (
          <option key={course._id} value={course._id}>{course.courseTitle}</option>
        ))}
      </select>

      {/* Chapter selection */}
      <label className={styles.label}>Select Chapter:</label>
      <select
        name="chapter"
        value={selectedChapter}
        className={styles.selectField}
        onChange={(e) => setSelectedChapter(e.target.value)}
        disabled={!chapters.length}
      >
        <option value="" disabled>Select a chapter</option>
        {chapters.length > 0 ? (
          chapters.map((chapter) => (
            <option key={chapter._id} value={chapter._id}>{chapter.chapterTitle}</option>
          ))
        ) : (
          <option value="" disabled>No chapters available</option>
        )}
      </select>

      {/* Video title input */}
      <label className={styles.label}>Video Title:</label>
      <input
        type="text"
        value={videoTitle}
        className={styles.inputField}
        onChange={(e) => setVideoTitle(e.target.value)}
        required
      />

      {/* YouTube link input */}
      <label className={styles.label}>YouTube Video Link:</label>
      <input
        type="text"
        value={videoYoutubeLink}
        className={styles.inputField}
        onChange={(e) => setVideoYoutubeLink(e.target.value)}
        required
      />

      {/* PDF title input */}
      <label className={styles.label}>PDF Title:</label>
      <input
        type="text"
        value={pdfTitle}
        className={styles.inputField}
        onChange={(e) => setPdfTitle(e.target.value)}
      />

      {/* PDF selection */}
      <label className={styles.label}>Select PDF:</label>
      <select
        value={selectedPdf}
        className={styles.selectField}
        onChange={(e) => setSelectedPdf(e.target.value)}>
        <option value="" disabled>Select a PDF</option>
        {pdfs.length > 0 ? (
          pdfs.map(pdf => (
            <option key={pdf._id} value={pdf._id}>{pdf.originalname}</option>
          ))
        ) : (
          <option value="" disabled>No PDFs available</option>
        )}
      </select>

      <button type="button" onClick={handleAddTopic} className={styles.submitButton}>Add Topic</button>
    </div>
  );
};

export default AddTopicForm;

