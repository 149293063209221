import React from 'react';
import { FormGroup, Button, Modal } from 'react-bootstrap';
import Dropzone from "react-dropzone";
import ReactQuill, { Quill } from 'react-quill';
import { FaEye } from 'react-icons/fa'; // Import the icon from react-icons
import { ImageResize } from './ImageResize';
import { Video } from './quill-video-resize';
import './quill-video-resize.css';
import './in.css';
import ReactDOM from 'react-dom';

Quill.register('modules/imageResize', ImageResize);
Quill.register({ 'formats/video': Video });

const Font = Quill.import('formats/font');
Font.whitelist = ['arial', 'times-new-roman', 'courier', 'comic-sans', 'georgia', 'impact', 'verdana'];
Quill.register(Font, true);



const imageMaxSize = 1000000000; // bytes
const acceptedFileTypes = 'image/x-png, image/png, image/jpg, image/jpeg, image/gif';
const acceptedFileTypesArray = acceptedFileTypes.split(",").map((item) => item.trim());

class ReviewForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      previewContent: ''
    };
  }

  componentDidMount() {
    this.addPreview();
  }

  handleChange = (value) => {
    this.props.onContentChange(value);
  };

  verifyFiles = (files) => {
    if (files && files.length > 0) {
      let currentFile = files[0];
      let currentFileType = currentFile.type;
      let currentFileSize = currentFile.size;

      if (currentFileSize > imageMaxSize) {
        return false;
      }

      if (!acceptedFileTypesArray.includes(currentFileType)) {
        return false;
      }
    }
    return true;
  };

  onFileDropped = (acceptedFiles, rejectedFiles) => {
    if (this.verifyFiles(acceptedFiles)) {
      let currentFile = acceptedFiles[0];
      let reader = new FileReader();
      reader.addEventListener("load", () => {
        this.props.onImageUpload(reader.result);
      });
      reader.readAsDataURL(currentFile);
    }
  };

  renderDropZone(fileName) {
    return (
      <Dropzone
        onDrop={this.onFileDropped}
        accept={acceptedFileTypes}
        multiple={false}
      >
        <div>{fileName}</div>
      </Dropzone>
    );
  }

  toggleModal = () => {
    this.setState((prevState) => ({
      showModal: !prevState.showModal,
      previewContent: prevState.showModal ? '' : this.props.content
    }));
  };
  

  addPreview = () => {
    const button = document.querySelector('.ql-preview');
    if (button) {
      const icon = document.createElement('span');
      icon.classList.add('fa-eye-icon'); // Add a class for styling if needed
      ReactDOM.render(<FaEye />, icon);  // Use ReactDOM.render to render the FaEye icon
      button.innerHTML = '';
      button.appendChild(icon);
    }
  };


  render() {
    const modulesQuill = {
      toolbar: {
        container: [
          [{ 'font': [] }],
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
          [{ 'size': ['small', false, 'large', 'huge'] }],  // Add specific sizes
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': 'check' },
          { 'indent': '-1' }, { 'indent': '+1' }],
          ['link', 'image', 'video'],
          [{ 'align': [] }],
          [{ 'color': [] }, { 'background': [] }],  // Add color and background options
          ['clean'],
          ['preview']  // Add custom preview button
        ],
        handlers: {
          'preview': this.toggleModal
        }
      },
      clipboard: {
        matchVisual: false,
      },
      history: {
        delay: 1000,
        maxStack: 50,
        userOnly: false
      },
      imageResize: {
        displayStyles: {
          backgroundColor: 'black',
          border: 'none',
          color: 'white'
        },
        modules: ['Resize', 'DisplaySize', 'Toolbar']
      },
    };

    const formats = [
      'header', 'font', 'size',
      'bold', 'italic', 'underline', 'strike', 'blockquote',
      'list', 'bullet', 'indent',
      'link', 'image', 'video',
      'color', 'background',
      'preview'  // Include preview
    ];

    return (
      <div>
        <FormGroup controlId="editor">
          <ReactQuill
            ref={(el) => { this.reactQuill = el; }}
            value={this.props.content}
            onChange={this.handleChange}
            modules={modulesQuill}
            formats={formats}
            placeholder={"Enter new content here..."}
            style={{ height: "300px" }}
          />
        </FormGroup>

        <Modal className='show-preview' show={this.state.showModal} onHide={this.toggleModal}>
          <Modal.Header closeButton>
            <Modal.Title>Preview</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div dangerouslySetInnerHTML={{ __html: this.state.previewContent }} />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.toggleModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default ReviewForm;
