// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EditListModal_modalOverlay__ku7E- {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .EditListModal_modal__3eBKz {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    max-width: 100%;
  }
  
  .EditListModal_modal__3eBKz h2 {
    margin-bottom: 20px;
    font-size: 20px;
  }
  
  .EditListModal_formGroup__PcwnI {
    margin-bottom: 15px;
  }
  
  .EditListModal_formGroup__PcwnI input,
  .EditListModal_formGroup__PcwnI textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  textarea {
    height: 100px;
    resize: none;
  }
  
  .EditListModal_charCount__99y8j {
    text-align: right;
    font-size: 12px;
    color: #666;
  }
  
  .EditListModal_actions__fS2Y1 {
    display: flex;
    justify-content: space-between;
  }
  
  .EditListModal_cancelButton__fKcgW {
    background: none;
    border: none;
    color: #666;
    cursor: pointer;
  }
  
  .EditListModal_saveButton__WrHpw {
    background: #333;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .EditListModal_saveButton__WrHpw:hover {
    background: #444;
  }
  
  .EditListModal_cancelButton__fKcgW:hover {
    color: #000;
  }
  `, "",{"version":3,"sources":["webpack://./src/VIDEOPLAYER COMPONENTS/components/MyLearningPage/EditListModal.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,8BAA8B;IAC9B,aAAa;IACb,uBAAuB;IACvB,mBAAmB;EACrB;;EAEA;IACE,iBAAiB;IACjB,aAAa;IACb,kBAAkB;IAClB,YAAY;IACZ,eAAe;EACjB;;EAEA;IACE,mBAAmB;IACnB,eAAe;EACjB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;;IAEE,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,aAAa;IACb,YAAY;EACd;;EAEA;IACE,iBAAiB;IACjB,eAAe;IACf,WAAW;EACb;;EAEA;IACE,aAAa;IACb,8BAA8B;EAChC;;EAEA;IACE,gBAAgB;IAChB,YAAY;IACZ,WAAW;IACX,eAAe;EACjB;;EAEA;IACE,gBAAgB;IAChB,YAAY;IACZ,kBAAkB;IAClB,YAAY;IACZ,eAAe;IACf,kBAAkB;EACpB;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,WAAW;EACb","sourcesContent":[".modalOverlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    background: rgba(0, 0, 0, 0.5);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n  \n  .modal {\n    background: white;\n    padding: 20px;\n    border-radius: 8px;\n    width: 400px;\n    max-width: 100%;\n  }\n  \n  .modal h2 {\n    margin-bottom: 20px;\n    font-size: 20px;\n  }\n  \n  .formGroup {\n    margin-bottom: 15px;\n  }\n  \n  .formGroup input,\n  .formGroup textarea {\n    width: 100%;\n    padding: 10px;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n  }\n  \n  textarea {\n    height: 100px;\n    resize: none;\n  }\n  \n  .charCount {\n    text-align: right;\n    font-size: 12px;\n    color: #666;\n  }\n  \n  .actions {\n    display: flex;\n    justify-content: space-between;\n  }\n  \n  .cancelButton {\n    background: none;\n    border: none;\n    color: #666;\n    cursor: pointer;\n  }\n  \n  .saveButton {\n    background: #333;\n    color: white;\n    padding: 10px 20px;\n    border: none;\n    cursor: pointer;\n    border-radius: 4px;\n  }\n  \n  .saveButton:hover {\n    background: #444;\n  }\n  \n  .cancelButton:hover {\n    color: #000;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalOverlay": `EditListModal_modalOverlay__ku7E-`,
	"modal": `EditListModal_modal__3eBKz`,
	"formGroup": `EditListModal_formGroup__PcwnI`,
	"charCount": `EditListModal_charCount__99y8j`,
	"actions": `EditListModal_actions__fS2Y1`,
	"cancelButton": `EditListModal_cancelButton__fKcgW`,
	"saveButton": `EditListModal_saveButton__WrHpw`
};
export default ___CSS_LOADER_EXPORT___;
