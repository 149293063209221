import React, { useEffect, useState } from 'react';

const A = ({url}) => {

  const [oEmbedData, setOEmbedData] = useState(null);
  // Your flipbook URL

  // Use the URL passed as a prop
  const pdfLink = url;
  // console.log("url",url);

  const apiKey = '017cf0ecd8cdff8a'; // Your Heyzine API key
  useEffect(() => {
    const convertToFlipbook = async () => {
      try {
        // Convert PDF to flipbook using the Heyzine API
        const convertResponse = await fetch(`https://heyzine.com/api1/rest?pdf=${encodeURIComponent(pdfLink)}&k=${apiKey}`);
        if (!convertResponse.ok) {
          throw new Error('Conversion failed');
        }
        const convertData = await convertResponse.json();

        // Fetch oEmbed data for the converted flipbook
        const response = await fetch(
          `https://heyzine.com/api1/oembed?url=${encodeURIComponent(
            convertData.url
          )}&format=json`
        );
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setOEmbedData(data);
      } catch (error) {
        console.error('Error fetching oEmbed data:', error);
      }
    };

    convertToFlipbook();
  }, [pdfLink, apiKey]);

  return (
    <div>
      {oEmbedData && (
        <div>
          {/* Render embedded flipbook */}
          <div dangerouslySetInnerHTML={{ __html: oEmbedData.html }} />
          {/* Additional iframe */}
          {/* <iframe allowFullScreen="allowfullscreen" scrolling="no" className="fp-iframe" src={pdfLink} style={{ border: '1px solid lightgray', width: '100%', height: '400px' }}></iframe> */}
        </div>
      )}
    </div>
  );
};

export default A;
