import React, { useState, useEffect } from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { pdfjs } from "react-pdf";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";

const ErrorBoundary = ({ children }) => {
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const handleError = (error, errorInfo) => {
      console.error("Error caught by error boundary:", error, errorInfo);
      setHasError(true);
    };

    window.addEventListener("error", handleError);

    return () => {
      window.removeEventListener("error", handleError);
    };
  }, []);

  if (hasError) {
    return <div>Something went wrong. Please try again later.</div>;
  }

  return children;
};

const PdfViewer = ({ url }) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const handleLoadSuccess = () => {
    // console.log('PDF document loaded successfully');
  };

  const handleError = (error) => {
    console.error("Error while loading PDF document:", error);
  };

  if (!url) {
    console.error("Error: PDF URL is null or undefined");
    return null;
  }

  return (
    <div className="pdf-viewer-container">
      <Worker
        workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}
      >
        <Viewer
          fileUrl={url}
          plugins={[defaultLayoutPluginInstance]}
          onLoadingSuccess={handleLoadSuccess}
          onLoadingError={handleError}
        />
      </Worker>
    </div>
  );
};

const PdfViewerWithErrorBoundary = ({ url }) => {
  return (
    <ErrorBoundary>
      <PdfViewer url={url} />
    </ErrorBoundary>
  );
};

export default PdfViewerWithErrorBoundary;
