import React, { useEffect, useState } from "react";
import styles from "./PramotionSlider.module.css";
import { Link, useNavigate } from "react-router-dom"; // Import useNavigate
import { AiOutlineRight, AiOutlineLeft } from "react-icons/ai";
import { BiShareAlt } from "react-icons/bi";
import VideoTimer from "../component/HomePage01/Header/Timer";

const PramotionSlider = () => {
  const [data, setData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const navigate = useNavigate(); // Initialize navigate

  useEffect(() => {
    const fetchBanners = async () => {
      try {
        const response = await fetch(
          "https://auth.ssccglpinnacle.com/api/banners"
        );
        const result = await response.json();

        // Log the entire result to see its structure
        console.log("Fetched banners:", result);

        if (response.ok) {
          // Check if 'result' is an array or has a 'banners' property
          const bannersData = Array.isArray(result) ? result : result.banners;

          if (bannersData) {
            const sortedData = bannersData.sort((a, b) => a.order - b.order);
            setData(sortedData);
          } else {
            console.error(
              "Banners data is undefined or not in the expected format"
            );
          }
        } else {
          console.error("Failed to fetch banners");
        }
      } catch (error) {
        console.error("Error fetching banners:", error);
      }
    };

    fetchBanners();
  }, []);

  const carouselInfiniteScroll = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === data.length - 1 ? 0 : prevIndex + 1
    );
  };

  useEffect(() => {
    const interval = setInterval(carouselInfiniteScroll, 10000);
    return () => clearInterval(interval);
  }, [data]);

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? data.length - 1 : prevIndex - 1
    );
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === data.length - 1 ? 0 : prevIndex + 1
    );
  };

  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  };

  return (
    <div className={styles["Pramotion-slider-fullpage"]}>
      <div className={styles.slider}>
        <div className={styles["carousel-container"]}>
          <div onClick={goToPrevious} className={styles.leftArrowStyles}>
            <AiOutlineLeft />
          </div>
          <div onClick={goToNext} className={styles.rightArrowStyles}>
            <AiOutlineRight />
          </div>

          <div className={styles["carousel-inner1"]}>
            <div className={styles["carousel-inner"]}>
              {data.map((item, index) => (
                <div
                  className={`${styles["carousel-item"]} ${
                    index === currentIndex ? styles.active : ""
                  }`}
                  key={index}
                  style={{
                    display: index === currentIndex ? "flex" : "none",
                  }}
                >
                  <div className={styles.description}>
                    {/* Clickable Course Title */}
                    <h1
                      onClick={() =>
                        navigate(`/course/description/${item.course._id}`)
                      }
                      style={{ cursor: "pointer" }}
                    >
                      {item.courseTitle}
                    </h1>

                    {/* Clickable Launching Offer Button */}
                    <div className={styles.buttonText1}>
                      <button
                        onClick={() =>
                          navigate(`/course/description/${item.course._id}`)
                        }
                        className={styles.buttonText}
                      >
                        {item.buttonText || "Launching Offer"}
                      </button>
                      <button className={styles["Share-button"]}>
                        <BiShareAlt
                          size={25}
                          className={styles["Share-icon"]}
                        />
                      </button>
                    </div>
                  </div>

                  {/* Clickable Images */}
                  <div className={styles.imagediv}>
                    <img
                      src={item.hindiCoverImage}
                      alt={item.courseTitle}
                      className={styles.image}
                      onClick={() =>
                        navigate(`/course/description/${item.course._id}`)
                      }
                      style={{ cursor: "pointer" }} // Add pointer cursor
                    />
                    <img
                      src={item.englishCoverImage}
                      alt={item.courseTitle}
                      className={styles.image}
                      onClick={() =>
                        navigate(`/course/description/${item.course._id}`)
                      }
                      style={{ cursor: "pointer" }} // Add pointer cursor
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className={styles.dotsContainerStyles}>
            {data.map((_, slideIndex) => (
              <div
                className={`${styles.dotStyles} ${
                  currentIndex === slideIndex ? styles.activeDot : ""
                }`}
                key={slideIndex}
                onClick={() => goToSlide(slideIndex)}
              ></div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PramotionSlider;
