import React, { useState, useEffect, useRef } from 'react';
import styles from './AddPdfForm.module.css';
import Swal from 'sweetalert2';

const AddVideoPdfForm = () => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedCourseId, setSelectedCourseId] = useState('');
  const [courses, setCourses] = useState([]);
  const [downloadable, setDownloadable] = useState(false);
  const fileInputRef = useRef(null);

  useEffect(() => {
    fetch('https://auth.ssccglpinnacle.com/api/courses')
      .then(response => response.json())
      .then(data => setCourses(data))
      .catch(error => {
        console.error('Error fetching courses:', error);
        setCourses([]);
      });
  }, []);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleCourseChange = (event) => {
    setSelectedCourseId(event.target.value);
  };

  const handleUpload = async () => {
    if (file && selectedCourseId) {
      setLoading(true);
      try {
        const formData = new FormData();
        formData.append('pdf', file);
        formData.append('courseId', selectedCourseId);
        formData.append('downloadable', downloadable);

        const response = await fetch('https://auth.ssccglpinnacle.com/api/upload-pdf', {
          method: 'POST',
          body: formData
        });

        if (response.ok) {
          await response.json();
          Swal.fire({
            title: 'Success!',
            text: 'PDF successfully uploaded!',
            icon: 'success',
            confirmButtonText: 'OK'
          });
        } else {
          Swal.fire({
            title: 'Failed!',
            text: 'Failed to upload PDF',
            icon: 'error',
            confirmButtonText: 'OK'
          });
        }
      } catch (error) {
        Swal.fire({
          title: 'Failed!',
          text: `Error posting PDF: ${error.message}`,
          icon: 'error',
          confirmButtonText: 'OK'
        });
      } finally {
        setLoading(false);
        resetFormState();
      }
    } else {
      Swal.fire({
        title: 'Warning!',
        text: 'Please select both a PDF file and a Course.',
        icon: 'warning',
        confirmButtonText: 'OK'
      });
    }
  };

  const resetFormState = () => {
    setFile(null);
    setSelectedCourseId('');
    setDownloadable(false);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  return (
    <>
    <h2 className={styles.heading}>Add New PDF</h2>
    <div className={styles.container}>
     
      <label htmlFor="courseSelect">Select a Course:</label>
      <select
        value={selectedCourseId}
        onChange={handleCourseChange}
        className={styles.courseSelect}
        required
      >
        <option value="">Select a Course</option>
        {courses.map(course => (
          <option key={course._id} value={course._id}>{course.courseTitle}</option>
        ))}
      </select>

      <input
        type="file"
        accept="application/pdf"
        onChange={handleFileChange}
        className={styles.fileInput}
        ref={fileInputRef}
      />

      <label htmlFor="downloadableCheckbox">
        <input
          type="checkbox"
          id="downloadableCheckbox"
          checked={downloadable}
          onChange={() => setDownloadable(!downloadable)}
        />
        Downloadable
      </label>

      <button
        onClick={handleUpload}
        disabled={loading}
        className={styles.uploadButton}
      >
        {loading ? 'Uploading...' : 'Upload PDF'}
      </button>
    </div>
    </>
  );
};

export default AddVideoPdfForm;
