import React, { useState, useEffect } from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { pdfjs } from 'react-pdf';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import "../NewPdfShow1.css";

const PdfViewer = ({ url }) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  return (
    <div className="pdf-viewer-container">
      <Worker workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}>
        <Viewer
          fileUrl={url}
          plugins={[defaultLayoutPluginInstance]}
        />
      </Worker>
    </div>
  );
};

const ForAnswerpdf = ({ chapterData }) => {
  const [pdfUrl, setPdfUrl] = useState(null);

  const disableTextSelection = (event) => {
    event.preventDefault();
  };

  // Function to prevent copy action
  const preventCopy = (event) => {
    event.preventDefault();
    console.log('Copy action prevented');
  };

  useEffect(() => {
    const disableKeyCombinations = (event) => {
      if (event.ctrlKey && event.code === 'KeyP') {
        event.preventDefault();
        console.log('Prevented:', event.code);
      }
    };

    document.addEventListener('keydown', disableKeyCombinations, true);
    document.addEventListener('selectstart', disableTextSelection); // Prevent text selection
    document.addEventListener('copy', preventCopy); // Prevent copying

    return () => {
      document.removeEventListener('keydown', disableKeyCombinations, true);
      document.removeEventListener('selectstart', disableTextSelection); // Remove text selection prevention
      document.removeEventListener('copy', preventCopy); // Remove copy prevention
    };
  }, []);


  useEffect(() => {
    const fetchPdfData = async () => {
      try {
        const response = await fetch('https://auth.ssccglpinnacle.com/api/Answerpdf-ebook');
        const data = await response.json();

        // Ensure that data is filtered correctly
        const filteredPdfData = data.find((pdf) => pdf.chapter === chapterData._id);

        if (filteredPdfData) {
          const url = `https://dzdx39zg243ni.cloudfront.net/${filteredPdfData.s3Key}`;
          setPdfUrl(url);
          console.log('ForAnswerpdf URL:', url); // For debugging
        } else {
          console.error('No matching PDF found for the chapter:', chapterData._id);
        }
      } catch (error) {
        console.error('Error fetching Answer PDF data:', error);
      }
    };

    fetchPdfData();
  }, [chapterData]);

  useEffect(() => {
    const saveScrollPosition = (scrollPosition) => {
      localStorage.setItem(`answerpdfScrollPosition_${chapterData._id}`, scrollPosition);
    };

    const restoreScrollPosition = () => {
      const scrollableElement = document.querySelector('.rpv-core__inner-pages');
      const savedScrollTop = localStorage.getItem(`answerpdfScrollPosition_${chapterData._id}`);
      if (scrollableElement && savedScrollTop) {
        scrollableElement.scrollTop = parseFloat(savedScrollTop);
      }
    };

    const handleScrollWithLogging = () => {
      const scrollableElement = document.querySelector('.rpv-core__inner-pages');
      if (scrollableElement) {
        saveScrollPosition(scrollableElement.scrollTop);
      }
    };

    const observer = new MutationObserver((mutationsList, observer) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
          const scrollableElement = document.querySelector('.rpv-core__inner-pages');
          if (scrollableElement) {
            restoreScrollPosition();
            scrollableElement.addEventListener("scroll", handleScrollWithLogging);
            observer.disconnect();
          }
        }
      }
    });

    observer.observe(document.body, { childList: true, subtree: true });

    return () => {
      const scrollableElement = document.querySelector('.rpv-core__inner-pages');
      if (scrollableElement) {
        scrollableElement.removeEventListener("scroll", handleScrollWithLogging);
      }
      observer.disconnect();
    };
  }, [chapterData]);



  return (
    <div className="pdf-viewer">
      {pdfUrl ? (
        <PdfViewer url={pdfUrl} />
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
};

export default ForAnswerpdf;
