import React, { useEffect, useState, useContext } from "react";
import { MdClose } from "react-icons/md"; // Import the close icon
import StarComponent from "./StarComponentBook";
import ReviewFormComponent from "./ReviewFormComponentBook";
import UserFormComponent from "./UserFormComponentBook";
import "./MyComponent.css";
import ReviewModal from "./ReviewModalBook";
import { useLocation, useParams, Navigate, useNavigate } from "react-router-dom";
import { useAuth } from "../../Context/AuthContext";

import pic from ".././mbook.svg";

const MyComponent = () => {
  const [showModal, setShowModal] = useState(false);
  const { isLoggedIn, user, logout } = useAuth();
  const { cardId } = useParams();
  const [purchaseHistory, setPurchaseHistory] = useState([]);
  const [cardData, setCardData] = useState([]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        if (isLoggedIn && user && user._id) {
          const purchaseHistoryResponse = await fetch(`https://auth.ssccglpinnacle.com/api/purchase-history-book/${user._id}`);
          if (!purchaseHistoryResponse.ok) {
            throw new Error(`Failed to fetch purchase history. Status: ${purchaseHistoryResponse.status}`);
          }

          const purchaseHistoryData = await purchaseHistoryResponse.json();
          setPurchaseHistory(purchaseHistoryData);
          console.log(purchaseHistory);
        }
      } catch (error) {
        console.error("Error fetching purchase history:", error);
      }
    };

    fetchData();
  }, [isLoggedIn, user]);

// console.log(cardId);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

const hasPurchased = purchaseHistory.length > 0 && purchaseHistory.some((purchase) => purchase.booksPurchased.some((book) => book.book?._id === cardId));
// console.log(hasPurchased)

let purchasedBookId;

const purchasedBook = purchaseHistory.some((purchase) => {
  const foundBook = purchase.booksPurchased.find((book) => {
    if (book.book?._id === cardId) {
      purchasedBookId = book.book?._id;
      // console.log(`User has purchased ebook with ebook._id: ${purchasedEbookId}`);
      return true; // Break out of the loop once the ebook is found
    }
    return false;
  });
  return !!foundBook;
});

// console.log(purchasedEbookId);





  return (
    <div>
      <div className="write-review" onClick={handleShowModal}>
        Write a Review
      </div>
      {showModal && (
        <div className="modal-container open">
          <div className="modal-content-review open">
          {hasPurchased ? (
           <ReviewModal purchasedBookId={purchasedBookId} handleCloseModal={handleCloseModal} />
        
            ) : (
              <div className ="you-must">
                 <img src={pic} alt="Component Image" />
                <p>You must buy this book to give a rating and review.</p>
                {/* Optionally, you can add a button or link to navigate to the purchase page */}
              </div>
            )}
            <button className = "for-review-close" onClick={handleCloseModal}>
              <MdClose /> {/* Use the close icon */}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default MyComponent;
