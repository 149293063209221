// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PublicProfile_Pinnacle-Profile-fullPage__3zRh\\+ {
  display: flex;
  padding: 1rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  margin-top: 8.6%;
  margin-left: 2.5%;
}

.PublicProfile_Pinnacle-Profile-Container1__3jiDb {
  width: 49%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
}

.PublicProfile_Pinnacle-Profile-Container2__GMH5K {
  width: 49%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
}

.PublicProfile_Profile-label__aOeaB {
  font-size: 16px;
  font-weight: bold;
  line-height: 40px;
}

.PublicProfile_Profile-input__J\\+9mv {
  height: 2rem;
  width: 90%;
  font-size: 16px;
  padding: 0.25rem;
}

.PublicProfile_Profile-select__-1Ztl {
  height: 2rem;
  width: 90%;
  font-size: 16px;
  line-height: 40px;
  margin-bottom: 1rem;
}
.PublicProfile_save-btn__-eAe3 {
  width: 20%;
  height: 2.5rem;
  border: none;
  font-size: 14px;
  font-weight: bold;
  background-color: #020062;
  color: white;
}
.PublicProfile_Profile-para__9TDqo {
  font-size: 12px;
  line-height: 30px;
  width: 90%;
}
`, "",{"version":3,"sources":["webpack://./src/VIDEOPLAYER COMPONENTS/components/VideoPlayerPage/AccountPage/PublicProfile.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;EACb;0CACwC;EACxC,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,UAAU;EACV,aAAa;EACb,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,UAAU;EACV,aAAa;EACb,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,UAAU;EACV,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,UAAU;EACV,eAAe;EACf,iBAAiB;EACjB,mBAAmB;AACrB;AACA;EACE,UAAU;EACV,cAAc;EACd,YAAY;EACZ,eAAe;EACf,iBAAiB;EACjB,yBAAyB;EACzB,YAAY;AACd;AACA;EACE,eAAe;EACf,iBAAiB;EACjB,UAAU;AACZ","sourcesContent":[".Pinnacle-Profile-fullPage {\n  display: flex;\n  padding: 1rem;\n  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,\n    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;\n  margin-top: 8.6%;\n  margin-left: 2.5%;\n}\n\n.Pinnacle-Profile-Container1 {\n  width: 49%;\n  padding: 1rem;\n  display: flex;\n  flex-direction: column;\n}\n\n.Pinnacle-Profile-Container2 {\n  width: 49%;\n  padding: 1rem;\n  display: flex;\n  flex-direction: column;\n}\n\n.Profile-label {\n  font-size: 16px;\n  font-weight: bold;\n  line-height: 40px;\n}\n\n.Profile-input {\n  height: 2rem;\n  width: 90%;\n  font-size: 16px;\n  padding: 0.25rem;\n}\n\n.Profile-select {\n  height: 2rem;\n  width: 90%;\n  font-size: 16px;\n  line-height: 40px;\n  margin-bottom: 1rem;\n}\n.save-btn {\n  width: 20%;\n  height: 2.5rem;\n  border: none;\n  font-size: 14px;\n  font-weight: bold;\n  background-color: #020062;\n  color: white;\n}\n.Profile-para {\n  font-size: 12px;\n  line-height: 30px;\n  width: 90%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Pinnacle-Profile-fullPage": `PublicProfile_Pinnacle-Profile-fullPage__3zRh+`,
	"Pinnacle-Profile-Container1": `PublicProfile_Pinnacle-Profile-Container1__3jiDb`,
	"Pinnacle-Profile-Container2": `PublicProfile_Pinnacle-Profile-Container2__GMH5K`,
	"Profile-label": `PublicProfile_Profile-label__aOeaB`,
	"Profile-input": `PublicProfile_Profile-input__J+9mv`,
	"Profile-select": `PublicProfile_Profile-select__-1Ztl`,
	"save-btn": `PublicProfile_save-btn__-eAe3`,
	"Profile-para": `PublicProfile_Profile-para__9TDqo`
};
export default ___CSS_LOADER_EXPORT___;
