import React from 'react';
import './FilterSelector.css'; // Import your CSS file for FilterSelector

function FilterSelector({ isOpen, onFilterChange }) {
  const handleOptionClick = (option) => {
    onFilterChange(option);
  };

  return (
    <div className={`filter-selector ${isOpen ? 'open' : ''}`}>
      <div className="selector-options">
        <div className="option" onClick={() => handleOptionClick('Newest')}>
          Newest
        </div>
        <div className="option" onClick={() => handleOptionClick('Highest Rating')}>
          Highest Rating
        </div>
        <div className="option" onClick={() => handleOptionClick('Lowest Rating')}>
          Lowest Rating
        </div>
        <div className="option" onClick={() => handleOptionClick('Featured')}>
          Featured
        </div>
      </div>
    </div>
  );
}

export default FilterSelector;
