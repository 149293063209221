// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Notifications_Notification-fullPage__wZlwK{
    margin: 1rem;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.Notifications_Notification-Heading__R4yII{
    font-size: 2rem;
    line-height: 2.5rem;
    margin: 0.5rem;
    padding: 0.5rem;
}
.Notifications_Instructor-Student-div__YagCw{
    display: flex;
    flex-wrap: wrap;
    margin: 0.5rem;
    padding: 0.5rem;
    align-items: center;

}
.Notifications_InstructorDiv__2-iFO{
    font-size: 16px;
    line-height: 30px;
}

.Notifications_margin__0CUGR{
    margin-left: 2rem;
}

.Notifications_InstructorDiv__2-iFO:hover{
font-weight: bold;
text-decoration: underline;
}

.Notifications_main-notification__lz1mL{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 0.5rem;
    padding: 0.5rem;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.Notifications_Student-message-div__aLLlo{

    display: flex;
    align-items: center;
    margin: 0.5rem;
    padding: 0.5rem;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    gap: 1rem;


}

.Notifications_image-div__T80Yy{
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    background-color: cadetblue;
    border: 1px solid  black;
}

.Notifications_notification-image__YgZ\\+U{
    width:100%;
    height:100%;
}`, "",{"version":3,"sources":["webpack://./src/Notifications/Notifications.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,mBAAmB;IACnB,cAAc;IACd,eAAe;AACnB;AACA;IACI,aAAa;IACb,eAAe;IACf,cAAc;IACd,eAAe;IACf,mBAAmB;;AAEvB;AACA;IACI,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;AACA,iBAAiB;AACjB,0BAA0B;AAC1B;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,eAAe;IACf,cAAc;IACd,eAAe;IACf,uFAAuF;AAC3F;;AAEA;;IAEI,aAAa;IACb,mBAAmB;IACnB,cAAc;IACd,eAAe;IACf,uFAAuF;IACvF,SAAS;;;AAGb;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,2BAA2B;IAC3B,wBAAwB;AAC5B;;AAEA;IACI,UAAU;IACV,WAAW;AACf","sourcesContent":[".Notification-fullPage{\n    margin: 1rem;\n    display: flex;\n    flex-direction: column;\n    flex-wrap: wrap;\n}\n\n.Notification-Heading{\n    font-size: 2rem;\n    line-height: 2.5rem;\n    margin: 0.5rem;\n    padding: 0.5rem;\n}\n.Instructor-Student-div{\n    display: flex;\n    flex-wrap: wrap;\n    margin: 0.5rem;\n    padding: 0.5rem;\n    align-items: center;\n\n}\n.InstructorDiv{\n    font-size: 16px;\n    line-height: 30px;\n}\n\n.margin{\n    margin-left: 2rem;\n}\n\n.InstructorDiv:hover{\nfont-weight: bold;\ntext-decoration: underline;\n}\n\n.main-notification{\n    display: flex;\n    flex-direction: column;\n    flex-wrap: wrap;\n    margin: 0.5rem;\n    padding: 0.5rem;\n    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;\n}\n\n.Student-message-div{\n\n    display: flex;\n    align-items: center;\n    margin: 0.5rem;\n    padding: 0.5rem;\n    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;\n    gap: 1rem;\n\n\n}\n\n.image-div{\n    width: 3rem;\n    height: 3rem;\n    border-radius: 50%;\n    background-color: cadetblue;\n    border: 1px solid  black;\n}\n\n.notification-image{\n    width:100%;\n    height:100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Notification-fullPage": `Notifications_Notification-fullPage__wZlwK`,
	"Notification-Heading": `Notifications_Notification-Heading__R4yII`,
	"Instructor-Student-div": `Notifications_Instructor-Student-div__YagCw`,
	"InstructorDiv": `Notifications_InstructorDiv__2-iFO`,
	"margin": `Notifications_margin__0CUGR`,
	"main-notification": `Notifications_main-notification__lz1mL`,
	"Student-message-div": `Notifications_Student-message-div__aLLlo`,
	"image-div": `Notifications_image-div__T80Yy`,
	"notification-image": `Notifications_notification-image__YgZ+U`
};
export default ___CSS_LOADER_EXPORT___;
