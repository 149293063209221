import React, { useEffect, useState } from "react";
import classes from "./Navbar2.module.css";
import { FaHome, FaUser, FaPencilAlt, FaKeyboard } from "react-icons/fa";
import { BsFillMenuButtonWideFill } from "react-icons/bs";
import { HiOutlineBookOpen } from "react-icons/hi";
import { AiOutlineLineChart, AiOutlineUser } from "react-icons/ai";
import {
  MdOndemandVideo,
  MdOutlineDirectionsRailwayFilled,
} from "react-icons/md";
import { ImBlogger } from "react-icons/im";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../Context/AuthContext";
import { useCookies } from "react-cookie";

function Navbar1(props) {
  const navigate = useNavigate();
  const { user, isLoggedIn, logout } = useAuth();
  const [cookies] = useCookies(["token"]);
  const [hasTypingAccess, setHasTypingAccess] = useState(false);

  useEffect(() => {
    const checkAccessTypingProduct = async () => {
      try {
        const response = await fetch(
          "https://auth.ssccglpinnacle.com/api/checkAccessTypingProduct",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${cookies.token}`,
            },
            body: JSON.stringify({ product_id: "999" }),
          }
        );

        if (response.ok) {
          const { access: productAccess } = await response.json();
          setHasTypingAccess(productAccess === "access");
        } else {
          console.log("Product access check failed");
        }
      } catch (error) {
        console.error("Error checking product access:", error);
      }
    };

    checkAccessTypingProduct();
  }, [cookies.token]);

  const handleUserIconClick = () => {
    if (isLoggedIn) {
      navigate("/mylearning");
    } else {
      window.location.href = "https://testportal.ssccglpinnacle.com/login";
    }
  };

  const info3 = () => {
    navigate("/");
  };

  const book = () => {
    navigate("/books-catalog");
  };

  const blog = () => {
    navigate("/blog");
  };

  return (
    <>
      <nav className={classes.nav2book}>
        <ul className={classes.navlistsbook}>
          <li>
            <div className={classes.videocourse} onClick={info3}>
              <FaHome className={classes.home} color="white" fontSize="1.5em" />
              &nbsp;Home
            </div>
          </li>
          <li
            onClick={() =>
              (window.location.href = "https://testportal.ssccglpinnacle.com/")
            }
          >
            <AiOutlineLineChart
              className={classes.line}
              color="white"
              fontSize="1.5em"
            />
            &nbsp;Test Portal
          </li>

          <li>
            <Link
              className={classes.none_video}
              to={hasTypingAccess ? "/typingexamselection" : "/typing"}
            >
              <FaKeyboard
                className={classes.upcoming}
                color="white"
                fontSize="1.5em"
              />
              &nbsp;Typing
            </Link>
          </li>
          <li onClick={book}>
            <HiOutlineBookOpen
              className={classes.book}
              color="white"
              fontSize="1.5em"
            />
            &nbsp;Books
          </li>

          <li>
            <Link className={classes.none_video} to="/videos">
              <MdOndemandVideo
                className={classes.videoicon}
                color="white"
                fontSize="1.5em"
              />
              &nbsp;Videos{" "}
            </Link>
          </li>
          <li onClick={() => navigate("/digital-catalog")}>
            <BsFillMenuButtonWideFill
              className={classes.digital}
              color="white"
              fontSize="1.5em"
            />
            &nbsp;E-Books
          </li>
          {/* <li>
            <FaPencilAlt className={classes.home} color="white" fontSize="1.5em" />
            &nbsp;SSC Kit
          </li>
          <li>
            <MdOutlineDirectionsRailwayFilled className={classes.home} color="white" fontSize="1.5em" />
            &nbsp;Railway Kit
          </li>
          <li>
            <AiOutlineLineChart className={classes.home} color="white" fontSize="1.5em" />
            &nbsp;Refer and earn
          </li> */}
          <li onClick={blog}>
            <ImBlogger
              className={classes.home}
              color="white"
              fontSize="1.5em"
            />
            &nbsp;Blogs
          </li>
          <li onClick={handleUserIconClick}>
            <AiOutlineUser
              className={classes.home}
              color="white"
              fontSize="1.5em"
            />
            &nbsp;{isLoggedIn ? "My Learning" : "Login"}
          </li>
        </ul>
      </nav>
    </>
  );
}

export default Navbar1;
