// import React from "react"
// import { NavLink } from "react-router-dom"
// import styles from "./MyLearning.module.css"
// import { useNavigate } from "react-router-dom"

// function Mylearning() {

//   return (
//     <div className={styles.container}>
//       <h1 className={styles.heading}>My Learning</h1>
//       <nav className={styles.navbar}>
//         <ul className={styles.unorderedlist}>
//           <li className={styles.list11}>
//             <h2 className={styles.heading2}>All Courses</h2>
//           </li>
//           <li className={styles.list11}>

//             <h2 className={styles.heading2}>My List</h2>

//           </li>
//           <li className={styles.list11}>

//             <h2 className={styles.heading2}>Wishlist </h2>

//           </li>
//           <li className={styles.list11}>

//             <h2 className={styles.heading2}> Archived </h2>

//           </li>
//           <li className={styles.list11}>

//             <h2 className={styles.heading2}>Learning Tools </h2>

//           </li>
//         </ul>
//       </nav>
//     </div>
//   )
// }

// export default Mylearning
import React from "react";
import styles from "./MyLearning.module.css";

function Mylearning({ activeTab, setActiveTab }) {
  return (
    <div className={styles.container}>
      <h1 className={styles.heading}>My Learning</h1>
      <nav className={styles.navbar}>
        <ul className={styles.unorderedlist}>
          <li className={styles.list11}>
            <button
              className={`${styles.heading2} ${
                activeTab === "all-courses" ? styles.active : ""
              }`}
              onClick={() => setActiveTab("all-courses")}
            >
              All Courses
            </button>
          </li>
          <li className={styles.list11}>
            <button
              className={`${styles.heading2} ${
                activeTab === "my-list" ? styles.active : ""
              }`}
              onClick={() => setActiveTab("my-list")}
            >
              My List
            </button>
          </li>
          <li className={styles.list11}>
            <button
              className={`${styles.heading2} ${
                activeTab === "wishlist" ? styles.active : ""
              }`}
              onClick={() => setActiveTab("wishlist")}
            >
              Wishlist
            </button>
          </li>
          <li className={styles.list11}>
            <button
              className={`${styles.heading2} ${
                activeTab === "archived" ? styles.active : ""
              }`}
              onClick={() => setActiveTab("archived")}
            >
              Archived
            </button>
          </li>
          <li className={styles.list11}>
            <button
              className={`${styles.heading2} ${
                activeTab === "learning-tools" ? styles.active : ""
              }`}
              onClick={() => setActiveTab("learning-tools")}
            >
              Learning Tools
            </button>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default Mylearning;
