import React, { useRef, useEffect, useState } from "react";
import styles from "./sscCategory.module.css";
import {
  AiFillLeftCircle,
  AiFillRightCircle,
  AiFillStar,
} from "react-icons/ai";
import gsap from "gsap";
import { useNavigate } from "react-router-dom";
import NavbarVC from "./NavBarVC";
import { FaAnglesDown,FaAnglesUp } from "react-icons/fa6";

export default function ExploreMore() {
  const [cgl, setCgl] = useState(true);
  const [chsl, setChsl] = useState(false);
  const [mts, setMts] = useState(false);
  const [sten, setSten] = useState(false);
  const [cpo, setCpo] = useState(false);
  const [je, setJe] = useState(false);
  const [jht, setJht] = useState(false);

  const navigate = useNavigate();
  const [course, setCourse] = useState([]);
  const scrollRef = useRef(null);
  const courseRef = useRef(null);

  useEffect(() => {
    fetch("https://auth.ssccglpinnacle.com/api/videoCourses/ssc")
      .then((response) => response.json())
      .then((data) => {
        setCourse(Array.isArray(data) ? data : []);
      })
      .catch((error) => console.error("Error fetching courses:", error));
  }, []);

  const scroll = (shift) => {
    const targetScroll = scrollRef.current.scrollLeft + shift;
    gsap.to(scrollRef.current, {
      scrollLeft: targetScroll,
      duration: 0.6,
      ease: "power2.inOut",
    });
  };
  const scrollToCourses = () => {
    courseRef.current.scrollIntoView({ behavior: "smooth" });
  };
    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };
  
    const scrollToBottom = () => {
      window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
    };

  const cglHandler = () => {
    setCgl(true);
    setChsl(false);
    setMts(false);
    setCpo(false);
    setJe(false);
    setJht(false);
    setSten(false);
  };
  const chslHandler = () => {
    setCgl(false);
    setChsl(true);
    setMts(false);
    setCpo(false);
    setJe(false);
    setJht(false);
    setSten(false);
  };
  const mtsHandler = () => {
    setCgl(false);
    setChsl(false);
    setMts(true);
    setCpo(false);
    setJe(false);
    setJht(false);
    setSten(false);
  };
  const stenHandler = () => {
    setCgl(false);
    setChsl(false);
    setMts(false);
    setCpo(false);
    setJe(false);
    setJht(false);
    setSten(true);
  };
  const cpoHandler = () => {
    setCgl(false);
    setChsl(false);
    setMts(false);
    setCpo(true);
    setJe(false);
    setJht(false);
    setSten(false);
  };
  const jeHandler = () => {
    setCgl(false);
    setChsl(false);
    setMts(false);
    setCpo(false);
    setJe(true);
    setJht(false);
    setSten(false);
  };
  const jhtHandler = () => {
    setCgl(false);
    setChsl(false);
    setMts(false);
    setCpo(false);
    setJe(false);
    setJht(true);
    setSten(false);
  };

  return (
    <>
      <NavbarVC />
      <div className={styles["ssc-category-div"]}>
        <div className={styles["EM-header"]}>
          <div className={styles["EM-header-container01"]}>
            <img
              src="https://d3m4h509gttb94.cloudfront.net/Image/ssc-logo.webp"
              alt="sscLogo"
              className={styles["EM-image"]}
            ></img>
          </div>
          <div className={styles["EM-header-container02"]}>
            <div className={styles["EM-header-heading"]}>
              SSC (Staff Selection Commission)
            </div>
            <button onClick={scrollToCourses} className={styles["allCourses-scrollButton"]}>
              Show All SSC Courses
            </button>
            <button onClick={scrollToBottom} className={styles["allCourses-scrollButton"]}>
             < FaAnglesDown /> 
            </button>



            <div className={styles["EM-header-para"]}>
              SSC is a highly competitive exam in India because lakhs of
              students appear for the exam to fulfill thousands of vacancies.
              The level of competition is quite high. hence the preparation
              should be done beforehand to ace the exam.
            </div>
          </div>
        </div>

        <div className={styles["EM-body"]}>
          <div className={styles["EM-body-container1"]}>
            <div className={styles["EM-body-buttons"]}>
              <button
                className={styles["EM-header-indi-butBtn"]}
                onClick={cglHandler}
              >
                SSC CGL (Combined Graduate Level)
              </button>
            </div>
            <div className={styles["EM-body-buttons"]}>
              <button
                className={styles["EM-header-indi-butBtn"]}
                onClick={chslHandler}
              >
                SSC CHSL (Combined Higher Secondary Level)
              </button>
            </div>
            <div className={styles["EM-body-buttons"]}>
              <button
                className={styles["EM-header-indi-butBtn"]}
                onClick={mtsHandler}
              >
                SSC Multitasking (Non-Technical)
              </button>
            </div>
            <div className={styles["EM-body-buttons"]}>
              <button
                className={styles["EM-header-indi-butBtn"]}
                onClick={stenHandler}
              >
                SSC Stenographers Grade 'C' & 'D'
              </button>
            </div>
            <div className={styles["EM-body-buttons"]}>
              <button
                className={styles["EM-header-indi-butBtn"]}
                onClick={cpoHandler}
              >
                SSC CPO(Central Police Organization)
              </button>
            </div>
            <div className={styles["EM-body-buttons"]}>
              <button
                className={styles["EM-header-indi-butBtn"]}
                onClick={jeHandler}
              >
                SSC JE(Junior Engineer)
              </button>
            </div>
            <div className={styles["EM-body-buttons"]}>
              <button
                className={styles["EM-header-indi-butBtn"]}
                onClick={jhtHandler}
              >
                SSC Junior Hindi Translator
              </button>
            </div>
          </div>
          <div className={styles["EM-body-container2"]}>
            {/* cgl handler */}
            {cgl && (
              <div className={styles["EM-header-para"]}>
                SSC CGL (Staff Selection Commission Combined Graduate Level)
                Exam is a national-level examination conducted to recruit
                candidates for various Group "B" and Group "C" posts in
                different Ministries, Departments, and Organizations of the
                Government of India. This exam is one of the most competitive
                exams in the country, offering a great opportunity for graduates
                to secure a Central Government job.
                <div className={styles["EM-header-heading"]}>
                  SSC CGL Recruitment Process
                </div>
                <div className={styles["EM-header-para"]}>
                  a) Tier 1: Preliminary Examination (Online)
                </div>
                <div className={styles["EM-header-para"]}>
                  b) Tier 2: Mains Examination (Online)
                </div>
                <div className={styles["EM-header-para"]}>
                  c) Tier 3: Descriptive Examination (Offline)
                </div>
                <div className={styles["EM-header-para"]}>
                  d) Tier 4: Data Entry Skill Test/Computer Proficiency Test
                </div>
                <div className={styles["EM-header-heading"]}>
                  Key Highlights of SSC CGL Exam:
                </div>
                <div className={styles["EM-header-para"]}>
                  <strong>Eligibility:</strong>
                  <ul>
                    <li>
                      Bachelor's degree in any discipline from a recognized
                      university.
                    </li>
                    <li>
                      Age limit varies between 18 to 32 years depending on the
                      post, with relaxations for reserved categories.
                    </li>
                  </ul>
                </div>
                <div className={styles["EM-header-para"]}>
                  <strong>Exam Pattern and Syllabus:</strong>
                  <ul>
                    <li>
                      <strong>Tier I:</strong> General Intelligence & Reasoning,
                      General Awareness, Quantitative Aptitude, and English
                      Comprehension.
                    </li>
                    <li>
                      <strong>Tier II:</strong> Advanced Mathematics, English
                      Language & Comprehension, Statistics, and General Studies
                      (Finance & Economics).
                    </li>
                    <li>
                      <strong>Tier III:</strong> Descriptive Paper in
                      English/Hindi.
                    </li>
                    <li>
                      <strong>Tier IV:</strong> Data Entry Speed Test/Computer
                      Proficiency Test and Document Verification.
                    </li>
                  </ul>
                </div>
                <div className={styles["EM-header-para"]}>
                  <strong>Application Process:</strong>
                  <ul>
                    <li>Online through the official SSC website.</li>
                    <li>
                      Requires registration, filling out the form, uploading
                      documents, and fee payment.
                    </li>
                  </ul>
                </div>
                <div className={styles["EM-header-para"]}>
                  <strong>Application Fee:</strong> INR 100 for UR/OBC male
                  candidates. SC/ST, differently-abled, ex-servicemen, and
                  female candidates are exempted from the fee.
                </div>
                <div className={styles["EM-header-para"]}>
                  <strong>Salary and Benefits:</strong>
                  <ul>
                    <li>
                      Pay scales vary based on the post, ranging from INR 18,000
                      to INR 1,51,100 as per the 7th Pay Commission.
                    </li>
                    <li>
                      Includes various allowances such as DA, HRA, and TA.
                    </li>
                  </ul>
                </div>
                <div className={styles["EM-header-para"]}>
                  <strong>Admit Card and Result:</strong>
                  <ul>
                    <li>Admit cards are released online before the exam.</li>
                    <li>
                      Results are declared in phases for each tier on the SSC
                      official website.
                    </li>
                  </ul>
                </div>
                <div className={styles["EM-header-para"]}>
                  <strong>Why Apply for SSC CGL?</strong>
                  <ul>
                    <li>
                      Offers job security and a prestigious career in the
                      Central Government.
                    </li>
                    <li>
                      Provides opportunities for promotion and career growth.
                    </li>
                    <li>
                      Diverse roles across multiple government departments and
                      ministries.
                    </li>
                  </ul>
                </div>
              </div>
            )}
            {/* chsl handler */}
            {chsl && (
              <div>
                <div>
                  The SSC CHSL (Staff Selection Commission Combined Higher
                  Secondary Level) Exam is a national-level examination
                  conducted by the Staff Selection Commission (SSC) to recruit
                  eligible 12th-pass candidates for various non-gazetted posts
                  in different ministries, departments, and offices of the
                  Government of India. This exam is a gateway for those seeking
                  a stable government job right after completing their higher
                  secondary education.
                </div>
                <div className={styles["EM-header-heading"]}>Posts Offered</div>
                <div className={styles["EM-header-para"]}>
                  - Lower Division Clerk (LDC)
                </div>
                <div className={styles["EM-header-para"]}>
                  - Junior Secretariat Assistant (JSA)
                </div>
                <div className={styles["EM-header-para"]}>
                  - Data Entry Operator (DEO)
                </div>
                <div className={styles["EM-header-para"]}>
                  - Data Entry Operator, Grade A
                </div>
                <div className={styles["EM-header-para"]}>
                  - Postal Assistant (PA) / Sorting Assistant (SA)
                </div>
                <div className={styles["EM-header-heading"]}>Exam Dates</div>
                <div className={styles["EM-header-para"]}>
                  Tier 1 Exam: Conducted between 1st to 11th July
                </div>
                <div className={styles["EM-header-para"]}>
                  Tier 2 Exam: Dates to be announced
                </div>
                <div className={styles["EM-header-heading"]}>
                  Eligibility Criteria
                </div>
                <div className={styles["EM-header-para"]}>
                  Educational Qualification: Candidates must have passed the
                  12th standard or equivalent examination from a recognized
                  board.
                </div>
                <div className={styles["EM-header-para"]}>
                  Age Limit: Candidates must be between 18 to 27 years of age.
                </div>
                <div className={styles["EM-header-heading"]}>
                  Selection Process
                </div>
                <div className={styles["EM-header-para"]}>
                  The SSC CHSL exam consists of multiple stages to assess the
                  candidates’ suitability for the available posts:
                </div>
                <div className={styles["EM-header-para"]}>
                  Tier-I (Computer-Based Test):
                </div>
                <div className={styles["EM-header-para"]}>
                  Objective type, multiple-choice questions.
                </div>
                <div className={styles["EM-header-para"]}>
                  Subjects include English Language, General Intelligence,
                  Quantitative Aptitude, and General Awareness.
                </div>
                <div className={styles["EM-header-para"]}>
                  Tier-II (Descriptive Paper):
                </div>
                <div className={styles["EM-header-para"]}>
                  This paper is conducted offline and includes writing an essay
                  and letter/application in English or Hindi.
                </div>
                <div className={styles["EM-header-para"]}>
                  Skill Test/Typing Test:
                </div>
                <div className={styles["EM-header-para"]}>
                  For posts like DEO, the skill test is conducted to check data
                  entry speed.
                </div>
                <div className={styles["EM-header-para"]}>
                  For LDC/JSA and Postal Assistant/Sorting Assistant, a typing
                  test is conducted.
                </div>
                <div className={styles["EM-header-para"]}>
                  Document Verification:
                </div>
                <div className={styles["EM-header-para"]}>
                  Candidates who qualify through the above stages are called for
                  document verification.
                </div>
                <div className={styles["EM-header-heading"]}>Exam Pattern</div>
                <div className={styles["EM-header-para"]}>
                  Tier I: Computer-Based Test with objective multiple-choice
                  questions, covering English Language, General Intelligence,
                  Quantitative Aptitude, and General Awareness.
                </div>
                <div className={styles["EM-header-para"]}>
                  Tier II: Descriptive Paper (offline) to test writing skills in
                  English/Hindi.
                </div>
                <div className={styles["EM-header-para"]}>
                  Tier III: Skill Test/Typing Test for certain posts to assess
                  specific skills related to the job.
                </div>
                <div className={styles["EM-header-heading"]}>
                  Application Process
                </div>
                <div className={styles["EM-header-para"]}>
                  Candidates can apply online through the official SSC website.
                  The process involves registering on the website, filling in
                  personal and educational details, uploading necessary
                  documents, and paying the application fee.
                </div>
                <div className={styles["EM-header-heading"]}>
                  Application Fee
                </div>
                <div className={styles["EM-header-para"]}>
                  General Category (Males): INR 100/-
                </div>
                <div className={styles["EM-header-para"]}>
                  Female and Reserved Categories (SC/ST/PwD/Ex-Servicemen):
                  Exempted from fee payment
                </div>
                <div className={styles["EM-header-heading"]}>
                  Salary Structure
                </div>
                <div className={styles["EM-header-para"]}>
                  LDC/JSA: Pay Level-2 (INR 19,900-63,200)
                </div>
                <div className={styles["EM-header-para"]}>
                  Postal Assistant/Sorting Assistant: Pay Level-4 (INR
                  25,500-81,100)
                </div>
                <div className={styles["EM-header-para"]}>
                  Data Entry Operator: Pay Level-4 to Pay Level-5 (INR
                  25,500-92,300)
                </div>
                <div className={styles["EM-header-heading"]}>
                  Preparation Tips
                </div>
                <div className={styles["EM-header-para"]}>
                  Understand the Exam Pattern: Familiarize yourself with the
                  types of questions, marking scheme, and time allocation for
                  each section.
                </div>
                <div className={styles["EM-header-para"]}>
                  Strong Foundation: Build a solid understanding of the syllabus
                  topics using standard textbooks and SSC CHSL guides.
                </div>
                <div className={styles["EM-header-para"]}>
                  Practice Regularly: Solve mock tests, previous years’ papers,
                  and sample papers to improve accuracy and time management.
                </div>
                <div className={styles["EM-header-para"]}>
                  Systematic Revision: Regularly revise important concepts and
                  create concise notes for quick review before the exam.
                </div>
                <div className={styles["EM-header-heading"]}>
                  Why Apply for SSC CHSL?
                </div>
                <div className={styles["EM-header-para"]}>
                  The SSC CHSL exam provides a significant opportunity for
                  candidates who have completed their 12th grade to secure a
                  government job with a good salary and benefits. It offers job
                  security, career growth, and the chance to work in various
                  departments under the Central Government.
                </div>
                <div className={styles["EM-header-heading"]}>
                  Admit Card and Result
                </div>
                <div className={styles["EM-header-para"]}>
                  Admit Card: Released online on the SSC regional websites
                  before the exam. Candidates must carry a printout of the admit
                  card and a valid ID proof to the exam center.
                </div>
                <div className={styles["EM-header-para"]}>
                  Result: Announced online on the SSC official website after the
                  completion of the examination stages. The result includes the
                  list of candidates shortlisted for the next stage or final
                  appointment.
                </div>
                <div className={styles["EM-header-heading"]}>Conclusion</div>
                <div className={styles["EM-header-para"]}>
                  The SSC CHSL exam is an excellent opportunity for young
                  candidates looking to start their careers in the government
                  sector immediately after their higher secondary education.
                  With a structured preparation strategy and understanding of
                  the exam pattern and syllabus, aspirants can enhance their
                  chances of clearing the exam and securing a prestigious
                  government job.
                </div>
              </div>
            )}
            {/* mts handler */}
            {mts && (
              // <div>
              //   <div>
              //     The Staff Selection Commission conducts the MTS exam to
              //     recruit Multi Tasking Staff (MTS), a General Central Service
              //     Group C Non-Gazetted, Non-Ministerial post in various
              //     departments, ministries, and offices of the Government of
              //     India, in different States and Union Territories.
              //   </div>
              //   <div className={styles["EM-header-heading"]}>
              //     SSC Multitasking Recruitment Process
              //   </div>
              //   <div className={styles["EM-header-para"]}>
              //     a) Paper-I (Computer Based Exam, Objective Type)
              //   </div>
              //   <div className={styles["EM-header-para"]}>
              //     b) Paper-II (Descriptive)
              //   </div>
              // </div>

              <div>
                {/* Overview of the SSC MTS Exam */}
                <div>
                  The Staff Selection Commission Multi-Tasking Staff (SSC MTS)
                  Exam is a national-level examination conducted by the Staff
                  Selection Commission (SSC) to recruit eligible candidates for
                  various non-gazetted, non-ministerial General Central Service
                  Group ‘C' positions across different Central Government
                  Ministries, Departments, and Offices. This examination is an
                  excellent opportunity for 10th-pass candidates to secure a
                  stable government job in India.
                </div>
                {/* Eligibility Criteria Section */}
                <div className={styles["EM-header-heading"]}>
                  Eligibility Criteria:
                </div>
                <div className={styles["EM-header-para"]}>
                  <ul>
                    <li>
                      <strong>Age Limit:</strong>
                      <ul>
                        <li>For MTS and Havaldar in CBN: 18 to 25 years.</li>
                        <li>
                          For Havaldar in CBIC and certain MTS posts: 18 to 27
                          years.
                        </li>
                      </ul>
                    </li>
                    <li>
                      <strong>Educational Qualification:</strong> Must have
                      passed the matriculation (Class 10th) or equivalent
                      examination from a recognized board.
                    </li>
                  </ul>
                </div>

                {/* Selection Process Section */}
                <div className={styles["EM-header-heading"]}>
                  Selection Process:
                </div>
                <div className={styles["EM-header-para"]}>
                  <ul>
                    <li>
                      <strong>Computer-Based Test (CBT):</strong> The primary
                      selection stage which tests candidates on various subjects
                      including General Intelligence & Reasoning, Numerical
                      Aptitude, General English, and General Awareness.
                    </li>
                    <li>
                      <strong>
                        Physical Efficiency Test (PET) and Physical Standard
                        Test (PST):
                      </strong>{" "}
                      Applicable only for Havaldar positions to assess physical
                      fitness and stamina.
                    </li>
                    <li>
                      <strong>Document Verification:</strong> Candidates who
                      qualify in the CBT are required to present valid documents
                      for verification to confirm their eligibility.
                    </li>
                  </ul>
                </div>

                {/* Exam Pattern and Syllabus Section */}
                <div className={styles["EM-header-heading"]}>
                  Exam Pattern and Syllabus:
                </div>
                <div className={styles["EM-header-para"]}>
                  <ul>
                    <li>
                      <strong>Exam Stages:</strong> The exam consists of two
                      sessions.
                      <ul>
                        <li>
                          Session I: Covers Numerical and Mathematical Ability
                          and Reasoning Ability and Problem-Solving (45 minutes
                          duration for each section).
                        </li>
                        <li>
                          Session II: Includes General Awareness and English
                          Language and Comprehension (45 minutes duration for
                          each section).
                        </li>
                      </ul>
                    </li>
                    <li>
                      <strong>Marking Scheme:</strong> No negative marking in
                      Session I; negative marking of one mark for each wrong
                      answer in Session II.
                    </li>
                    <li>
                      <strong>Total Duration:</strong> 90 minutes for the entire
                      exam.
                    </li>
                    <li>
                      <strong>Subjects Covered:</strong> Numerical and
                      Mathematical Ability, Reasoning & Problem Solving, General
                      Awareness, and General English.
                    </li>
                  </ul>
                </div>

                {/* Application Process Section */}
                <div className={styles["EM-header-heading"]}>
                  Application Process:
                </div>
                <div className={styles["EM-header-para"]}>
                  Interested candidates can apply online through the official
                  SSC website. The application involves registration, form
                  filling, uploading documents, and fee payment.
                </div>

                {/* Application Fee Section */}
                <div className={styles["EM-header-heading"]}>
                  Application Fee:
                </div>
                <div className={styles["EM-header-para"]}>
                  <ul>
                    <li>
                      <strong>General Category:</strong> INR 100/-
                    </li>
                    <li>
                      <strong>Exemptions:</strong> Women, SC/ST, PwD, and
                      Ex-Servicemen are exempted from paying the fee.
                    </li>
                  </ul>
                </div>

                {/* Salary and Benefits Section */}
                <div className={styles["EM-header-heading"]}>
                  Salary and Benefits:
                </div>
                <div className={styles["EM-header-para"]}>
                  <ul>
                    <li>
                      The SSC MTS position is a Group C non-gazetted post under
                      the 7th Central Pay Commission pay matrix.
                    </li>
                    <li>
                      <strong>Pay Scale:</strong> INR 18,000 – INR 56,900 along
                      with various allowances such as Dearness Allowance (DA),
                      House Rent Allowance (HRA), and Travel Allowance (TA).
                    </li>
                  </ul>
                </div>

                {/* Preparation Tips Section */}
                <div className={styles["EM-header-heading"]}>
                  Preparation Tips:
                </div>
                <div className={styles["EM-header-para"]}>
                  <ul>
                    <li>
                      Understand the exam pattern and syllabus thoroughly.
                    </li>
                    <li>
                      Practice previous years' question papers and take mock
                      tests.
                    </li>
                    <li>
                      Focus on improving weak areas and stay updated with
                      current affairs.
                    </li>
                    <li>
                      Develop a study schedule that allocates sufficient time
                      for each section of the exam.
                    </li>
                  </ul>
                </div>

                {/* Admit Card and Result Section */}
                <div className={styles["EM-header-heading"]}>
                  Admit Card and Result:
                </div>
                <div className={styles["EM-header-para"]}>
                  <ul>
                    <li>
                      <strong>Admit Card:</strong> Available on the SSC official
                      website a few days before the exam. Candidates must carry
                      a printed copy of the admit card and a valid ID proof to
                      the exam center.
                    </li>
                    <li>
                      <strong>Result:</strong> The SSC MTS Result will be
                      declared a few weeks after the examination. The result
                      will be available on the SSC official website in PDF
                      format containing the roll numbers of the shortlisted
                      candidates.
                    </li>
                  </ul>
                </div>

                {/* Conclusion: Why Apply for SSC MTS? */}
                <div className={styles["EM-header-heading"]}>
                  Why Apply for SSC MTS?
                </div>
                <div className={styles["EM-header-para"]}>
                  The SSC MTS exam provides a great opportunity for candidates
                  who have passed the 10th grade to secure a government job with
                  a good salary and benefits. It offers job security, career
                  growth, and the chance to serve in various government
                  departments.
                </div>
              </div>
            )}
            {/* cgl handler */}
            {sten && (
              <div>
                <div>
                  The SSC Stenographer Exam is a national-level competitive
                  examination conducted by the Staff Selection Commission (SSC)
                  to recruit eligible 12th-pass candidates for the posts of
                  Stenographer Grade ‘C’ (Group ‘B’, Non-Gazetted) and
                  Stenographer Grade ‘D’ (Group ‘C’) in various Central
                  Government Ministries, Departments, and Organizations. This
                  exam provides a great opportunity for individuals seeking to
                  work in government jobs immediately after completing their
                  higher secondary education.
                </div>
                <div className={styles["EM-header-heading"]}>
                  SSC Stenographers Grade 'C' & 'D' Recruitment Process
                </div>
                <div className={styles["EM-header-para"]}>
                  a) Computer-Based Test (CBT)
                </div>
                <div className={styles["EM-header-para"]}>
                  b) Skill Test in Stenography
                </div>
                <div className={styles["EM-header-para"]}>
                  c) Document Verification
                </div>
                <div>
                  <div className={styles["EM-header-heading"]}>
                    Key Details of the SSC Stenographer Exam
                  </div>
                  <div className={styles["EM-header-para"]}>
                    Exam Conducting Authority: Staff Selection Commission (SSC)
                  </div>
                  <div className={styles["EM-header-para"]}>
                    Name of the Post: Stenographer Grade 'C' and Grade 'D'
                  </div>
                  <div className={styles["EM-header-para"]}>
                    Total Vacancies: 2006 posts (Subject to change)
                  </div>
                  <div className={styles["EM-header-para"]}>
                    Level of Exam: National
                  </div>
                  <div className={styles["EM-header-para"]}>
                    Frequency of Exam: Annual
                  </div>
                  <div className={styles["EM-header-para"]}>
                    Exam Stages: Computer-Based Test & Skill Test
                  </div>
                  <div className={styles["EM-header-para"]}>
                    Exam Duration: 2 hours
                  </div>
                </div>
                <div>
                  <div className={styles["EM-header-heading"]}>
                    Eligibility Criteria
                  </div>
                  <div className={styles["EM-header-para"]}>
                    Educational Qualification: 12th pass or equivalent from a
                    recognized board
                  </div>
                  <div className={styles["EM-header-para"]}>
                    Age Limit: 18 to 30 years for Stenographer Grade 'C', 18 to
                    27 years for Stenographer Grade 'D'
                  </div>
                </div>
                <div>
                  <div className={styles["EM-header-heading"]}>
                    Application Process
                  </div>
                  <div className={styles["EM-header-para"]}>
                    Candidates can apply online through the official SSC
                    website. The process involves registering on the website,
                    filling in personal and educational details, uploading
                    necessary documents, and paying the application fee.
                  </div>
                </div>
                <div>
                  <div className={styles["EM-header-heading"]}>
                    Application Fee
                  </div>
                  <div className={styles["EM-header-para"]}>
                    General/OBC: INR 100
                  </div>
                  <div className={styles["EM-header-para"]}>
                    SC/ST/PWD/ESM/Women Candidates: No Fee (Exempted)
                  </div>
                </div>
                <div>
                  <div className={styles["EM-header-heading"]}>
                    Exam Pattern
                  </div>
                  <div className={styles["EM-header-para"]}>
                    CBT: Objective-type questions covering General Intelligence
                    & Reasoning, General Awareness, and English Language &
                    Comprehension.
                  </div>
                  <div className={styles["EM-header-para"]}>
                    Skill Test: Transcription of a 10-minute dictation in
                    English or Hindi.
                  </div>
                </div>
                <div>
                  <div className={styles["EM-header-heading"]}>
                    Salary Structure
                  </div>
                  <div className={styles["EM-header-para"]}>
                    Stenographer Grade 'C': Pay scale of INR 9300-34800 with a
                    Grade Pay of INR 4200.
                  </div>
                  <div className={styles["EM-header-para"]}>
                    Stenographer Grade 'D': Pay scale of INR 5200-20200 with a
                    Grade Pay of INR 2400.
                  </div>
                </div>
                <div>
                  <div className={styles["EM-header-heading"]}>
                    Preparation Tips
                  </div>
                  <div className={styles["EM-header-para"]}>
                    Understand the exam pattern and syllabus, practice previous
                    years' question papers, take regular mock tests, and
                    allocate time for revision.
                  </div>
                </div>
                <div>
                  <div className={styles["EM-header-heading"]}>
                    Admit Card and Result
                  </div>
                  <div className={styles["EM-header-para"]}>
                    Admit Card: Available online on the SSC regional websites
                    before the exam. Candidates must carry a printed copy of the
                    admit card along with a valid ID proof to the exam center.
                  </div>
                  <div className={styles["EM-header-para"]}>
                    Result: Announced online on the SSC official website after
                    each stage of the examination process. The result includes
                    the list of candidates shortlisted for the next stage or
                    final appointment.
                  </div>
                </div>
              </div>

              // <div>
              //   <div>
              //     The SSC conducts an Open Competitive Examination every year to
              //     recruit Stenographer Grade' C' (Group 'B', Non Gazetted) and
              //     Stenographer Grade 'D' (Group 'C') for various ministries,
              //     departments, and organizations in the Government of India.
              //   </div>
              //   <div className={styles["EM-header-heading"]}>
              //     SSC Stenographers Grade 'C' & 'D' Recruitment Process
              //   </div>
              //   <div className={styles["EM-header-para"]}>
              //     a) Online Computer Based Exam
              //   </div>
              //   <div className={styles["EM-header-para"]}>b) Skill Test</div>
              // </div>
            )}
            {/* junior Engineer handler */}
            {je && (
              <div>
                <div>
                  The SSC JE Electrical (SSC JE EE) Exam is a national-level
                  competitive examination conducted by the Staff Selection
                  Commission (SSC) to recruit Junior Engineers (Electrical) for
                  various departments under the Indian Government. This
                  examination provides a great opportunity for candidates with
                  an engineering background to secure a stable government job in
                  central government ministries and departments.
                </div>
                <div className={styles["EM-header-heading"]}>
                  Eligibility Criteria:
                </div>
                <div className={styles["EM-header-para"]}>
                  Educational Qualification: Candidates must have a degree or
                  diploma in Electrical Engineering from a recognized university
                  or institution.
                </div>
                <div className={styles["EM-header-para"]}>
                  Age Limit: The age limit for most departments is up to 30
                  years, while for certain vacancies (such as in the CPWD), it
                  is up to 32 years.
                </div>
                <div className={styles["EM-header-heading"]}>
                  Selection Process:
                </div>
                <div className={styles["EM-header-para"]}>
                  The selection process for the SSC JE EE post comprises
                  multiple stages to assess the candidate's suitability:
                </div>
                <div className={styles["EM-header-para"]}>
                  a) Computer-Based Examination (CBE) - Paper I: Objective type,
                  multiple-choice questions covering General Intelligence &
                  Reasoning, General Awareness, and Electrical Engineering
                  subjects. Negative marking of 0.25 marks for each incorrect
                  answer.
                </div>
                <div className={styles["EM-header-para"]}>
                  b) Computer-Based Examination (CBE) - Paper II: Objective
                  type, multiple-choice questions focused on Electrical
                  Engineering subjects. Negative marking of 1 mark for each
                  incorrect answer.
                </div>
                <div className={styles["EM-header-para"]}>
                  c) Document Verification: Verification of all required
                  documents to confirm the eligibility and credentials of the
                  candidates.
                </div>
                <div className={styles["EM-header-para"]}>
                  d) Physical Standard Test/Physical Efficiency Test (for BRO
                  applicants): A test to evaluate the physical fitness of
                  candidates applying for positions in the Border Roads
                  Organization (BRO).
                </div>
                <div className={styles["EM-header-heading"]}>Exam Pattern:</div>
                <div className={styles["EM-header-para"]}>
                  Paper I: Subjects: General Intelligence & Reasoning, General
                  Awareness, and General Engineering (Electrical). Duration: 2
                  hours (2 hours & 40 minutes for candidates eligible for a
                  scribe). Total Marks: 200 marks (50 for each of the first two
                  sections, 100 for Electrical Engineering).
                </div>
                <div className={styles["EM-header-para"]}>
                  Paper II: Subjects: General Engineering (Electrical).
                  Duration: 2 hours (2 hours & 40 minutes for candidates
                  eligible for a scribe). Total Marks: 300 marks.
                </div>
                <div className={styles["EM-header-heading"]}>
                  Application Process:
                </div>
                <div className={styles["EM-header-para"]}>
                  Interested candidates can apply online through the official
                  SSC website by following these steps:
                </div>
                <div className={styles["EM-header-para"]}>
                  Visit the SSC Application Portal and navigate to the 'JE' tab.
                  Click on the "Apply Online For SSC JE" link. Complete the SSC
                  JE EE application form and submit it. Pay the application fee
                  online. Print the application receipt for future reference.
                </div>
                <div className={styles["EM-header-heading"]}>
                  Application Fee:
                </div>
                <div className={styles["EM-header-para"]}>
                  General Category: INR 100/-
                </div>
                <div className={styles["EM-header-para"]}>
                  Women/SC/ST/PH/Ex-Servicemen: Exempted from paying the
                  application fee.
                </div>
                <div className={styles["EM-header-heading"]}>
                  Salary Structure:
                </div>
                <div className={styles["EM-header-para"]}>
                  The SSC JE EE is a Group B Non-Gazetted post. Pay Scale: Pay
                  Matrix Level 6 (INR 35,400 - INR 1,12,400).
                </div>
                <div className={styles["EM-header-heading"]}>
                  Important Documents:
                </div>
                <div className={styles["EM-header-para"]}>
                  Admit Card: Released online before the exam, containing
                  details such as exam date, time, and venue. It is mandatory to
                  carry the admit card to the exam center.
                </div>
                <div className={styles["EM-header-para"]}>
                  Result: The SSC JE EE result is announced on the SSC official
                  website after each stage of the selection process.
                </div>
                <div className={styles["EM-header-heading"]}>
                  Why Apply for SSC JE EE?
                </div>
                <div className={styles["EM-header-para"]}>
                  The SSC JE EE exam is an excellent opportunity for engineering
                  graduates to secure a government job with a good salary and
                  career growth prospects. It offers job security, various
                  allowances, and the chance to work in prestigious central
                  government departments.
                </div>
                <div className={styles["EM-header-heading"]}>
                  Preparation Tips:
                </div>
                <div className={styles["EM-header-para"]}>
                  Understand the Exam Pattern and Syllabus: Familiarize yourself
                  with the types of questions, marking scheme, and the subjects
                  covered in both papers. Practice Regularly: Solve previous
                  years’ question papers and take mock tests to improve speed
                  and accuracy. Focus on Core Subjects: Strengthen your
                  understanding of Electrical Engineering concepts. Revise
                  Thoroughly: Regularly revise important topics to ensure
                  clarity and retention of concepts.
                </div>
              </div>
            )}
            {/* junior hindi Translator handler */}
            {jht && (
              <div>
                <div>
                  The SSC Junior Hindi Translator (SSC JHT) Exam is a
                  national-level competitive examination conducted by the Staff
                  Selection Commission (SSC) to recruit eligible candidates for
                  the posts of Junior Hindi Translator, Junior Translation
                  Officer, Junior Translator, Senior Hindi Translator, and
                  Senior Translator in various Ministries, Departments, and
                  Organizations of the Government of India. This exam offers a
                  great opportunity for individuals with a strong command of
                  Hindi and English to secure a stable government job.
                </div>
                <div className={styles["EM-header-para"]}>
                  a) Junior Hindi Translator
                </div>
                <div className={styles["EM-header-para"]}>
                  b) Junior Translation Officer
                </div>
                <div className={styles["EM-header-para"]}>
                  c) Junior Translator
                </div>
                <div className={styles["EM-header-para"]}>
                  d) Senior Hindi Translator
                </div>
                <div className={styles["EM-header-para"]}>
                  e) Senior Translator
                </div>
                <div className={styles["EM-header-heading"]}>
                  SSC Junior Hindi Translator Recruitment Process
                </div>
                <div className={styles["EM-header-para"]}>
                  a) Paper 1: Computer-Based Examination (Objective Type)
                </div>
                <div className={styles["EM-header-para"]}>
                  b) Paper 2: Translation and Essay (Descriptive Type)
                </div>
                <div className={styles["EM-header-para"]}>
                  c) Document Verification
                </div>
                <div className={styles["EM-header-heading"]}>Posts Offered</div>
                <div className={styles["EM-header-para"]}>
                  Junior Hindi Translator
                </div>
                <div className={styles["EM-header-para"]}>
                  Junior Translation Officer
                </div>
                <div className={styles["EM-header-para"]}>
                  Junior Translator
                </div>
                <div className={styles["EM-header-para"]}>
                  Senior Hindi Translator
                </div>
                <div className={styles["EM-header-para"]}>
                  Senior Translator
                </div>
                <div className={styles["EM-header-heading"]}>
                  Mode of Examination
                </div>
                <div className={styles["EM-header-para"]}>
                  Computer-Based Examination (CBT)
                </div>
                <div className={styles["EM-header-heading"]}>
                  Eligibility Criteria
                </div>
                <div className={styles["EM-header-para"]}>Nationality:</div>
                <div className={styles["EM-header-para"]}>
                  Must be a citizen of India, or a subject of Nepal or Bhutan,
                  or a person of Indian origin who has migrated from specific
                  countries intending to settle permanently in India.
                </div>
                <div className={styles["EM-header-para"]}>
                  Age Limit: Candidates must be between 18 to 30 years of age as
                  of 1 August 2024, with specific relaxations provided for
                  reserved categories.
                </div>
                <div className={styles["EM-header-para"]}>
                  Educational Qualification:
                </div>
                <div className={styles["EM-header-para"]}>
                  For Junior Hindi Translator, Junior Translation Officer, and
                  Junior Translator: A Master’s degree in Hindi or English with
                  English or Hindi as a compulsory or elective subject,
                  respectively, or a Master's degree in any subject with Hindi
                  and English as compulsory or elective subjects.
                </div>
                <div className={styles["EM-header-para"]}>
                  For Senior Hindi Translator and Senior Translator: Similar
                  qualifications with additional experience in translation work.
                </div>
                <div className={styles["EM-header-heading"]}>
                  Selection Process
                </div>
                <div className={styles["EM-header-para"]}>
                  Paper-I (Computer-Based Examination): This objective-type test
                  assesses General Hindi and General English skills. Each
                  section contains 100 questions, with a total of 200 marks.
                  Negative marking of 0.25 marks for each incorrect answer.
                </div>
                <div className={styles["EM-header-para"]}>
                  Paper-II (Descriptive Paper): This paper is designed to test
                  translation skills and the ability to write essays in both
                  Hindi and English. It includes translation passages and essay
                  writing, with a total of 200 marks.
                </div>
                <div className={styles["EM-header-para"]}>
                  Document Verification: Candidates who qualify in both papers
                  are called for document verification to confirm eligibility
                  and other criteria.
                </div>
                <div className={styles["EM-header-heading"]}>Exam Pattern</div>
                <div className={styles["EM-header-para"]}>
                  Paper I: Subjects: General Hindi and General English. Number
                  of Questions: 200 (100 in each subject). Duration: 2 hours.
                  Marking Scheme: 0.25 marks deducted for each incorrect answer.
                </div>
                <div className={styles["EM-header-para"]}>
                  Paper II: Subjects: Translation and Essay Writing. Mode:
                  Descriptive. Total Marks: 200. Duration: 2 hours.
                </div>
                <div className={styles["EM-header-heading"]}>
                  Application Process
                </div>
                <div className={styles["EM-header-para"]}>
                  Stage 1: One-Time Registration on the SSC official website.
                </div>
                <div className={styles["EM-header-para"]}>
                  Stage 2: Filling out the Online Application Form and paying
                  the application fee.
                </div>
                <div className={styles["EM-header-heading"]}>
                  Application Fee
                </div>
                <div className={styles["EM-header-para"]}>
                  General Category: INR 100/-
                </div>
                <div className={styles["EM-header-para"]}>
                  SC/ST/Female/Ex-Servicemen: No fee
                </div>
                <div className={styles["EM-header-heading"]}>
                  Important Dates
                </div>
                <div className={styles["EM-header-para"]}>
                  Notification Release Date: 2 August 2024
                </div>
                <div className={styles["EM-header-para"]}>
                  Application Start Date: 2 August 2024
                </div>
                <div className={styles["EM-header-para"]}>
                  Last Date to Apply: 25 August 2024
                </div>
                <div className={styles["EM-header-para"]}>
                  Correction Window: 4 to 5 September 2024
                </div>
                <div className={styles["EM-header-para"]}>
                  CBT Date (Paper 1): October - November 2024
                </div>
                <div className={styles["EM-header-heading"]}>
                  Salary Structure
                </div>
                <div className={styles["EM-header-para"]}>
                  Junior Hindi Translator and Junior Translation Officer: Pay
                  Level-6 (INR 35,400 – INR 112,400)
                </div>
                <div className={styles["EM-header-para"]}>
                  Senior Hindi Translator and Senior Translator: Pay Level-7
                  (INR 44,900 – INR 142,400)
                </div>
                <div className={styles["EM-header-heading"]}>
                  Why Apply for SSC JHT?
                </div>
                <div className={styles["EM-header-para"]}>
                  The SSC JHT exam is an excellent opportunity for candidates
                  proficient in Hindi and English to secure a government job
                  with a good salary, career growth, and job security. The
                  positions offer various benefits and allow individuals to work
                  in prestigious central government departments.
                </div>
                <div className={styles["EM-header-heading"]}>
                  Preparation Tips
                </div>
                <div className={styles["EM-header-para"]}>
                  Understand the Exam Pattern and Syllabus: Familiarize yourself
                  with the subjects and types of questions to be asked.
                </div>
                <div className={styles["EM-header-para"]}>
                  Practice Regularly: Solve previous years' question papers and
                  take mock tests to improve speed and accuracy.
                </div>
                <div className={styles["EM-header-para"]}>
                  Focus on Language Skills: Enhance your Hindi and English
                  language proficiency, particularly in translation.
                </div>
              </div>
            )}
            {/* cpo handler */}
            {cpo && (
              <div>
                <div>
                  The SSC CPO Exam is conducted by the Staff Selection
                  Commission to recruit candidates for the posts of
                  Sub-Inspector in Delhi Police, Sub-Inspector in Central Armed
                  Police Forces (CAPFs), and Assistant Sub-Inspector in CISF.
                  This exam is a great opportunity for graduates to secure a job
                  in law enforcement under the central government.
                </div>
                <div className={styles["EM-header-heading"]}>
                  SSC CPO Recruitment Process
                </div>
                <div className={styles["EM-header-para"]}>
                  a) Paper I: Computer-Based Examination
                </div>
                <div className={styles["EM-header-para"]}>
                  b) Physical Standard Test (PST) / Physical Endurance Test
                  (PET)
                </div>
                <div className={styles["EM-header-para"]}>
                  c) Paper II: Computer-Based Examination
                </div>
                <div className={styles["EM-header-para"]}>
                  d) Detailed Medical Examination
                </div>
                <div className={styles["EM-header-heading"]}>Posts Offered</div>
                <div className={styles["EM-header-para"]}>
                  Sub-Inspector in Delhi Police (Male/Female)
                </div>
                <div className={styles["EM-header-para"]}>
                  Sub-Inspector in Central Armed Police Forces (CAPFs)
                </div>
                <div className={styles["EM-header-para"]}>
                  Assistant Sub-Inspector in CISF
                </div>
                <div className={styles["EM-header-heading"]}>
                  Eligibility Criteria
                </div>
                <div className={styles["EM-header-para"]}>
                  Nationality: Must be a citizen of India.
                </div>
                <div className={styles["EM-header-para"]}>
                  Age Limit: Candidates must be between 20 to 25 years of age.
                </div>
                <div className={styles["EM-header-para"]}>
                  Educational Qualification: Candidates must have a graduation
                  degree from a recognized university.
                </div>
                <div className={styles["EM-header-heading"]}>
                  Selection Process
                </div>
                <div className={styles["EM-header-para"]}>
                  a) Paper I (Computer-Based Examination): Consists of
                  multiple-choice questions on General Intelligence and
                  Reasoning, General Knowledge and General Awareness,
                  Quantitative Aptitude, and English Comprehension.
                </div>
                <div className={styles["EM-header-para"]}>
                  b) Physical Standard Test (PST) / Physical Endurance Test
                  (PET): Tests candidates' physical fitness levels.
                </div>
                <div className={styles["EM-header-para"]}>
                  c) Paper II (Computer-Based Examination): Focuses on English
                  language and comprehension skills.
                </div>
                <div className={styles["EM-header-para"]}>
                  d) Detailed Medical Examination: Checks the overall health and
                  fitness of the candidates.
                </div>
                <div className={styles["EM-header-heading"]}>
                  Application Process
                </div>
                <div className={styles["EM-header-para"]}>
                  Interested candidates can apply online through the official
                  SSC website by registering and completing the application
                  form, uploading necessary documents, and paying the
                  application fee.
                </div>
                <div className={styles["EM-header-heading"]}>
                  Application Fee
                </div>
                <div className={styles["EM-header-para"]}>
                  SC/ST/Ex-Servicemen/Women: No fee
                </div>
                <div className={styles["EM-header-para"]}>
                  General/Unreserved/Other: INR 100/-
                </div>
                <div className={styles["EM-header-heading"]}>
                  Salary Structure
                </div>
                <div className={styles["EM-header-para"]}>
                  Sub Inspector in Delhi Police (Male/ Female): Pay Level-6, INR
                  35,400-1,12,400/- with a Grade Pay of INR 4200.
                </div>
                <div className={styles["EM-header-para"]}>
                  Sub Inspector in CAPF: Pay Level-6, INR 35,400-1,12,400/- with
                  a Grade Pay of INR 4200.
                </div>
                <div className={styles["EM-header-para"]}>
                  Assistant Sub-Inspector(ASI) in CISF: Pay Level-5, INR
                  29,200-92,300/- with a Grade Pay of INR 2800.
                </div>
                <div className={styles["EM-header-heading"]}>
                  Why Apply for SSC CPO?
                </div>
                <div className={styles["EM-header-para"]}>
                  The SSC CPO exam offers a great opportunity for graduates to
                  join law enforcement agencies under the central government,
                  providing job security, a good salary, and opportunities for
                  career growth.
                </div>
                <div className={styles["EM-header-heading"]}>
                  Preparation Tips
                </div>
                <div className={styles["EM-header-para"]}>
                  Understand the Exam Pattern and Syllabus: Get familiar with
                  the subjects and types of questions to be asked.
                </div>
                <div className={styles["EM-header-para"]}>
                  Practice Regularly: Solve previous years' question papers and
                  take mock tests to improve speed and accuracy.
                </div>
                <div className={styles["EM-header-para"]}>
                  Focus on Physical Fitness: Prepare for the Physical Standard
                  Test and Physical Endurance Test by maintaining a regular
                  fitness routine.
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className={styles["related-course"]}>
        <div className={styles["mostPopular-div"]}>
          <div className={styles["Course_Heading"]}>All SSC Courses</div>
          <div className={styles["scroll-container"]} ref={scrollRef}>
            <div className={styles["card-wrapper"]} ref={courseRef}>
            <div className={styles["card-wrapper"]}>
              {course.map(course => (
                  <div key={course._id} className={styles['card']} onClick={() => navigate(`/course/description/${course._id}`)}>
                    <div className={styles['Image-section']}>
                      <img className={styles.imagecard} src={course.hindiCoverImage} alt="Hindi Cover" />
                      <img className={styles.imagecard} src={course.englishCoverImage} alt="English Cover" />
                    </div>
                    <div className={styles['description-section']}>
                      <div className={styles['title']}>{course.courseTitle}</div>
                  
                      {/* <div className={styles['teacher']}>Mr./Ms. {course.instructorName}</div> */}
                      <div className={styles['gst']}>18% GST included</div>
                      <div className={styles["price-fprice-div"]}>
                    
                    <div  className={styles["price"]}>₹ {course.price}</div>&nbsp;&nbsp;&nbsp;&nbsp;
                      <div className={styles["mrp"]}><s>₹{course.mrp}</s></div>
                      <div className={styles["savings"]}>
                          Save{" "}
                          {Math.round(
                            ((course.mrp - course.price) / course.mrp) * 100
                          )}
                          %
                        </div>
                    </div>
                    </div>
                  </div>
                ))}
            </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles["scroll-buttons"]}><button onClick={scrollToTop} className={styles["allCourses-scrollButton"]}><FaAnglesUp/></button></div>
      
    </>
  );
}
