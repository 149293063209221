import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styles from './ListExams.module.css';
import AddExamForm from './AddExamForm';
import Swal from 'sweetalert2'; 

export default function ListExams() {
  const [exams, setExams] = useState([]); 
  const [filteredExams, setFilteredExams] = useState([]); 
  const [categories, setCategories] = useState([]); 
  const [selectedCategory, setSelectedCategory] = useState(''); 
  const [editingExam, setEditingExam] = useState(null); 
  useEffect(() => {
    fetchCategories();
    fetchExams();
  }, []);
  const fetchCategories = async () => {
    try {
      const response = await axios.get('https://auth.ssccglpinnacle.com/categories');
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };
  const fetchExams = async () => {
    try {
      const response = await axios.get('https://auth.ssccglpinnacle.com/exams');
      setExams(response.data);
      setFilteredExams(response.data); 
    } catch (error) {
      console.error('Error fetching exams:', error);
    }
  };
  const handleCategoryChange = (e) => {
    const categoryId = e.target.value;
    setSelectedCategory(categoryId);

    if (categoryId === '') {
      setFilteredExams(exams);
    } else {
      const filtered = exams.filter(exam => exam.categoryId?._id === categoryId);
      setFilteredExams(filtered);
    }
  };
  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://auth.ssccglpinnacle.com/exams/${id}`);
      fetchExams();
      Swal.fire('Deleted!', 'Exam deleted successfully', 'success');
    } catch (error) {
      console.error('Error deleting exam:', error);
    }
  };
  const handleEdit = (exam) => {
    setEditingExam(exam);
  };
  const handleSave = () => {
    setEditingExam(null); 
    fetchExams(); 
  };

  return (
    <div className={styles['list-container']}>
      {editingExam ? (
        <AddExamForm examData={editingExam} onSave={handleSave} />
      ) : (
        <>
          <h2>All Exams</h2>
          <div className={styles['filter-container']}>
            <label htmlFor="category-select">Filter by Category: </label>
            <select
              id="category-select"
              value={selectedCategory}
              onChange={handleCategoryChange}
              className={styles['category-select']}
            >
              <option value="">All Categories</option>
              {categories.map((category) => (
                <option key={category._id} value={category._id}>
                  {category.categoryTitle}
                </option>
              ))}
            </select>
          </div>
          <ul className={styles['exam-list']}>
            {filteredExams.length > 0 ? (
              filteredExams.map((exam) => (
                <li key={exam._id} className={styles['exam-item']}>
                  <div>
                    <strong>{exam.examName}</strong> 
                
                  </div>
                  <button onClick={() => handleEdit(exam)} className={styles['edit-btn']}>
                    Edit
                  </button>
                  <button onClick={() => handleDelete(exam._id)} className={styles['delete-btn']}>
                    Delete
                  </button>
                </li>
              ))
            ) : (
              <li>No exams found for this category.</li>
            )}
          </ul>
        </>
      )}
    </div>
  );
}
