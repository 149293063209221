import React, { useState, useEffect } from "react";
import { useParams,useNavigate } from "react-router-dom";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { pdfjs } from "react-pdf";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import styles from "./ViewVideoPdf.module.css";
import { FaArrowLeft } from 'react-icons/fa';


const PdfViewer = ({ url }) => {
    const defaultLayoutPluginInstance = defaultLayoutPlugin();


    return (
        <div className="pdf-viewer-container">
            <Worker
                workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}
            >
                <Viewer
                    fileUrl={url}
                    plugins={[defaultLayoutPluginInstance]}
                />
            </Worker>
        </div>
    );
};

const ViewVideoPdf = () => {
    const { pdfId } = useParams();
    const [pdfUrl, setPdfUrl] = useState(null);
    const [originalname, setOriginalName] = useState(null);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchPdfData = async () => {
            try {
                const response = await fetch(
                    `https://auth.ssccglpinnacle.com/api/pdfs/${pdfId}`
                );
                if (!response.ok) {
                    throw new Error("Failed to fetch PDF data");
                }
                const data = await response.json();
                setPdfUrl(data.cloudFrontUrl);
                setOriginalName(data.originalname);
            } catch (error) {
                setError(error.message);
            }
        };

        fetchPdfData();
    }, [pdfId]);

    useEffect(() => {
        // Function to disable right-click context menu
        const disableRightClick = (event) => event.preventDefault();

        // Disable text selection, copying, and printing
        const disableTextSelection = (event) => event.preventDefault();
        const preventCopy = (event) => event.preventDefault();
        const disableKeyCombinations = (event) => {
            if (event.ctrlKey && event.code === "KeyP") {
                event.preventDefault();
                alert("Printing is disabled on this page.");
            }
        };

        // Add event listeners
        document.addEventListener("contextmenu", disableRightClick); // Disable right-click
        document.addEventListener("keydown", disableKeyCombinations, true);
        document.addEventListener("selectstart", disableTextSelection);
        document.addEventListener("copy", preventCopy);

        // Cleanup event listeners on component unmount
        return () => {
            document.removeEventListener("contextmenu", disableRightClick); // Remove right-click disable
            document.removeEventListener("keydown", disableKeyCombinations, true);
            document.removeEventListener("selectstart", disableTextSelection);
            document.removeEventListener("copy", preventCopy);
        };
    }, []); // Empty dependency array ensures this effect runs only on mount/unmount

    if (error) {
        return <div>Error: {error}</div>; // Display an error message if there was a problem fetching the PDF
    }

    const handleBack = () => {
        navigate("/videos");
      };

    return (
        <div className="pdf-viewer">
         
            {originalname && <h1 className={styles.pdfTitle}>   <FaArrowLeft className={styles.backButton} onClick={handleBack} />{originalname}</h1>} 
            
            {pdfUrl ? (
                <PdfViewer url={pdfUrl} /> // Render the PDF viewer with the fetched URL
            ) : (
                <div>Loading...</div> // Show a loading message while the PDF is being fetched
            )}
        </div>
    );
};

export default ViewVideoPdf;


