import React from "react";
import "./quizresult.css";
import Timer from "./Timer";

const QuizResult = ({
  quizData,
  correctAnswers,
  incorrectAnswers,
  totalScore,
  resetQuiz
}) => {
  return (
    <div className="quiz-answer-container">
      <h1>Quiz Results</h1>
      <p className="score-in-answercheck">
        score: {totalScore} of {quizData.length}
      </p>

      {quizData.map((question, index) => {
        const isCorrectAnswer = correctAnswers[index] === question.answerIndex;
        const isIncorrectAnswer = incorrectAnswers.includes(question);

        return (
          <div key={index}>
           <div className ="for_question">
           <h2> Question {index+1}:</h2>
           <h6>{question.question}</h6></div>
            <ul className = "answer_ul">
              {question.options.map((option, optionIndex) => {
                const isCorrectOption = question.answerIndex === optionIndex;
                const isUserAnswer = correctAnswers[index] === optionIndex;
                const isIncorrectOption = isIncorrectAnswer && isUserAnswer;

                let optionClassName = "";
                let optionText = option;
                if (isIncorrectOption) {
                  optionClassName = "incorrect-answer";
                  optionText = ` ${option}  Your answer`;
                } else if (isCorrectOption) {
                  optionClassName = "correct-answer";
                 optionText = `${option}`+"  " +`Correct answer`;
                }

                return (
                  <li className ="answer_li" key={optionIndex}>
                    <span className={optionClassName}>{optionText}</span>
                  </li>
                );
              })}
            </ul>
          </div>
        );
      })}
      <p className="center-answer">
        <button className="restart-button" onClick={resetQuiz}>
          Restart Quiz
        </button>
      </p>
    </div>
  );
};

export default QuizResult;
