// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Announcement_Announcement-empty-fullPage__efmBz{
    

}

.Announcement_Announcement-full-Page__8r7zz{
    margin: 0.25rem;
    padding: 0.25rem;
}

.Announcement_Announcement-heading__PDex0{
    font-size: 26px;
    font-weight: bold;
    line-height:60px ;
}

.Announcement_empty-cart-section__zJQpN{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60%;
    padding: 0.5rem;
    margin: 0.5rem;
    margin: auto;
}

.Announcement_emptyCart-imageDiv__KAhd5{

}

.Announcement_emptyCart-image__MKb4-{
    width: 400px;
    height: 200px;
}

.Announcement_emptyCart-description__gcejC{
    line-height: 30px;
    font-size: 14px;
    margin-top: 1rem;
    

}`, "",{"version":3,"sources":["webpack://./src/Profile/Announcement.module.css"],"names":[],"mappings":"AAAA;;;AAGA;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,UAAU;IACV,eAAe;IACf,cAAc;IACd,YAAY;AAChB;;AAEA;;AAEA;;AAEA;IACI,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,iBAAiB;IACjB,eAAe;IACf,gBAAgB;;;AAGpB","sourcesContent":[".Announcement-empty-fullPage{\n    \n\n}\n\n.Announcement-full-Page{\n    margin: 0.25rem;\n    padding: 0.25rem;\n}\n\n.Announcement-heading{\n    font-size: 26px;\n    font-weight: bold;\n    line-height:60px ;\n}\n\n.empty-cart-section{\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    width: 60%;\n    padding: 0.5rem;\n    margin: 0.5rem;\n    margin: auto;\n}\n\n.emptyCart-imageDiv{\n\n}\n\n.emptyCart-image{\n    width: 400px;\n    height: 200px;\n}\n\n.emptyCart-description{\n    line-height: 30px;\n    font-size: 14px;\n    margin-top: 1rem;\n    \n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Announcement-empty-fullPage": `Announcement_Announcement-empty-fullPage__efmBz`,
	"Announcement-full-Page": `Announcement_Announcement-full-Page__8r7zz`,
	"Announcement-heading": `Announcement_Announcement-heading__PDex0`,
	"empty-cart-section": `Announcement_empty-cart-section__zJQpN`,
	"emptyCart-imageDiv": `Announcement_emptyCart-imageDiv__KAhd5`,
	"emptyCart-image": `Announcement_emptyCart-image__MKb4-`,
	"emptyCart-description": `Announcement_emptyCart-description__gcejC`
};
export default ___CSS_LOADER_EXPORT___;
