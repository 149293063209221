import React, { useState } from 'react';
import './Row4intro.css';

function Row4intro() {
  const accordionData = [
    {
      title: 'Step 1: Enroll in the course',
      content: 'Accordion Content for Step 1 Here',
    },
    {
      title: 'Step 2: Complete the assignments',
      content: 'Accordion Content for Step 2 Here',
    },
    {
      title: 'Step 3: Attend live sessions',
      content: 'Accordion Content for Step 3 Here',
    },
    {
      title: 'Step 4: Take the final exam',
      content: 'Accordion Content for Step 4 Here',
    },
  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    if (activeIndex === index) {
      // Clicking on the same accordion item again should close it
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  return (
      
    <div className="for-intro-row4-image-introproduct">
      <div className="accordion-row1">
        <h2>How does it work?</h2>
        {accordionData.map((step, index) => (
          <div
            key={index}
            className={`accordion222 ${activeIndex === index ? 'open' : ''}`}
          >
            <div className="accordion-header222" onClick={() => toggleAccordion(index)}>
              <span className="accordion-title222">{step.title}</span>
            </div>
            <div className="accordion-content222">
              {activeIndex === index && step.content}
            </div>
          </div>
        ))}
      </div>
    </div>
    
  );
}

export default Row4intro;
