import React, {useState} from 'react';
import "./Row7intro.css";
import { FaRegThumbsUp } from 'react-icons/fa';
import { MdArrowForwardIos } from 'react-icons/md';
import  Row5Digital from "../Pages/DigitalContentBody/Row5Digital"
import Recentlylaunch from "./Recentlylaunch"

function Row7intro() {
    
return (
    <>
    <div className = "for-intro-row7-image-introproduct">
    < Recentlylaunch/>
    </div>
    </>
  );
}

export default Row7intro;
