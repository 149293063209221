import React from 'react'
import "./SearchBar.css";
import { FaSearch } from "react-icons/fa";


function SearchBar() {

    return (
        <div className='container11book'>
            <div className='input-wrap-5book'>
               
               <input
                    type="text"
                    name="product-search"
                    id="product-search-5book"
                    placeholder="Search for products..."
                />
                <div className='searchiconbook'>
                 < FaSearch className = "serch-iconbook" color="white" fontSize="1.2em" opacity="1em" background="#008B8B" /> </div> {/* <i
                    className="fas fa-times"
                ></i> */}
            </div>
        </div>
    );
}

export default SearchBar;
