import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const VisitorsTable = () => {
  const [visitorData, setVisitorData] = useState([]);

  useEffect(() => {
    // Fetch visitor data from the API
    fetch("https://auth.ssccglpinnacle.com/api/visitors")
      .then((response) => response.json())
      .then((data) => processVisitorData(data))
      .catch((error) => console.error("Error fetching visitor data:", error));
  }, []);

  // Process visitor data to group by date and count visits
  const processVisitorData = (data) => {
    const dateMap = {};

    data.forEach((visitor) => {
      const visitDate = new Date(visitor.visitTime).toLocaleDateString(); // Group by date

      if (dateMap[visitDate]) {
        dateMap[visitDate] += 1;
      } else {
        dateMap[visitDate] = 1;
      }
    });

    // Convert dateMap to array of objects for charting
    const processedData = Object.keys(dateMap).map((date) => ({
      date,
      visits: dateMap[date],
    }));

    setVisitorData(processedData);
  };

  return (
    <div>
      <h2>Visitor Logs (Graphical Representation)</h2>
      <ResponsiveContainer width="100%" height={400}>
        <LineChart
          data={visitorData}
          margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line
            type="monotone"
            dataKey="visits"
            stroke="#8884d8"
            activeDot={{ r: 8 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default VisitorsTable;
