import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, Typography, TextField } from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useParams, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import styles from "./UpdateChapterOrder.module.css";

const UpdateChapterOrder = () => {
  const { courseId } = useParams(); // Get courseId from the URL
  const [chapters, setChapters] = useState([]);
  const navigate = useNavigate(); // For navigation

  // Fetch chapters on mount
  useEffect(() => {
    const fetchChapters = async () => {
      try {
        const response = await axios.get(
          `https://auth.ssccglpinnacle.com/api/youtubeChapters/course/${courseId}`
        );
        // console.log("Fetched chapters:", response.data);
        setChapters(response.data);
      } catch (error) {
        // console.error("Error fetching chapters:", error);
      }
    };

    fetchChapters();
  }, [courseId]);

  // Handle the reordering of the chapters by drag-and-drop
  const handleOnDragEnd = (result) => {
    // console.log("Drag result:", result); // Log drag result for debugging

    if (!result.destination) return;

    const reorderedChapters = Array.from(chapters);
    const [reorderedItem] = reorderedChapters.splice(result.source.index, 1);
    reorderedChapters.splice(result.destination.index, 0, reorderedItem);

    // After reordering, update the order field for each chapter based on the new position
    const updatedChapters = reorderedChapters.map((chapter, index) => ({
      ...chapter,
      order: index,
    }));

    setChapters(updatedChapters);
    // console.log("Reordered chapters:", updatedChapters); // Debugging reordered chapters
  };

  // Handle updating the order value of each chapter manually
  const handleOrderChange = (index, newOrder) => {
    const updatedChapters = [...chapters];
    updatedChapters[index].order = newOrder; // Update the order field of the chapter manually
    setChapters(updatedChapters);
  };

  // Handle saving the new order
  const handleSaveOrder = async () => {
    try {
      console.log(
        "Saving chapters with new order:",
        chapters.map((chapter) => ({
          _id: chapter._id,
          order: chapter.order,
          title: chapter.chapterTitle, // Just for debug purposes
        }))
      );

      const response = await axios.put(
        `https://auth.ssccglpinnacle.com/api/youtubeChapters/updateOrder/${courseId}`,
        { chapters: chapters.map((chapter) => ({ _id: chapter._id, order: chapter.order })) }
      );

      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Chapter order updated successfully!",
        });
        navigate(-1); // Go back to the previous page
      }
    } catch (error) {
      console.error("Error updating chapter order:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to update chapter order. Please try again later.",
      });
    }
  };

  return (
    <div className={styles.container}>
      {/* <Typography variant="h5" className={styles.heading}>
        Update Chapter Order for Course ID: {courseId}
      </Typography> */}

      {chapters.length > 0 ? (
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="chapters">
            {(provided) => (
              <ul
                className={styles.chapterList}
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {chapters.map((chapter, index) => (
                  <Draggable
                    key={chapter._id}
                    draggableId={chapter._id.toString()} // Ensure draggableId is a string
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <li
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={`${styles.chapterItem} ${
                          snapshot.isDragging ? styles.dragging : ""
                        }`}
                      >
                        <span>{chapter.chapterTitle}</span>
                        <TextField
                          label="Order"
                          type="number"
                          value={chapter.order}
                          onChange={(e) => handleOrderChange(index, Number(e.target.value))}
                          className={styles.orderInput}
                        />
                      </li>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <Typography className={styles.noChapters}>
          No chapters found for this course.
        </Typography>
      )}

      <Button
        variant="contained"
        onClick={handleSaveOrder}
        className={styles.saveButton}
      >
        Save Order
      </Button>
    </div>
  );
};

export default UpdateChapterOrder;


// //Code for Manual Order Update
// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { Button, Typography, TextField } from "@mui/material";
// import { useParams, useNavigate } from "react-router-dom";
// import Swal from "sweetalert2";
// import styles from "./UpdateChapterOrder.module.css";

// const UpdateChapterOrder = () => {
//   const { courseId } = useParams();
//   const [chapters, setChapters] = useState([]);
//   const navigate = useNavigate();

//   // Fetch chapters on mount
//   useEffect(() => {
//     const fetchChapters = async () => {
//       try {
//         const response = await axios.get(
//           `http://localhost:5000/api/youtubeChapters/course/${courseId}`
//         );
//         console.log("Fetched chapters:", response.data);
//         setChapters(response.data);
//       } catch (error) {
//         console.error("Error fetching chapters:", error);
//       }
//     };

//     fetchChapters();
//   }, [courseId]);

//   // Handle updating the order value of each chapter
//   const handleOrderChange = (index, newOrder) => {
//     const updatedChapters = [...chapters];
//     updatedChapters[index].order = newOrder; // Update the order field of the chapter
//     setChapters(updatedChapters);
//   };

//   // Handle saving the new order
//   const handleSaveOrder = async () => {
//     try {
//       console.log("Saving chapters with new order:", chapters.map(chapter => ({
//         _id: chapter._id,
//         order: chapter.order,
//         title: chapter.chapterTitle, // Just for debug purposes
//       })));

//       const response = await axios.put(
//         `http://localhost:5000/api/youtubeChapters/updateOrder/${courseId}`,
//         { chapters: chapters.map(chapter => ({ _id: chapter._id, order: chapter.order })) }
//       );

//       if (response.status === 200) {
//         Swal.fire({
//           icon: "success",
//           title: "Success",
//           text: "Chapter order updated successfully!",
//         });
//         navigate(-1); // Go back to the previous page
//       }
//     } catch (error) {
//       console.error("Error updating chapter order:", error);
//       Swal.fire({
//         icon: "error",
//         title: "Error",
//         text: "Failed to update chapter order. Please try again later.",
//       });
//     }
//   };

//   return (
//     <div className={styles.container}>
//       <Typography variant="h5" className={styles.heading}>
//         Update Chapter Order for Course ID: {courseId}
//       </Typography>

//       {chapters.length > 0 ? (
//         <ul className={styles.chapterList}>
//           {chapters.map((chapter, index) => (
//             <li key={chapter._id} className={styles.chapterItem}>
//               <span>{chapter.chapterTitle}</span>
//               <span>ID: {chapter._id}</span>
//               <TextField
//                 label="Order"
//                 type="number"
//                 value={chapter.order}
//                 onChange={(e) => handleOrderChange(index, Number(e.target.value))}
//                 className={styles.orderInput}
//               />
//             </li>
//           ))}
//         </ul>
//       ) : (
//         <Typography className={styles.noChapters}>
//           No chapters found for this course.
//         </Typography>
//       )}

//       <Button
//         variant="contained"
//         onClick={handleSaveOrder}
//         className={styles.saveButton}
//       >
//         Save Order
//       </Button>
//     </div>
//   );
// };

// export default UpdateChapterOrder;





// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { Button, Typography } from "@mui/material";
// import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
// import { useParams, useNavigate } from "react-router-dom"; // For retrieving courseId and navigation
// import Swal from "sweetalert2";
// import styles from "./UpdateChapterOrder.module.css";

// const UpdateChapterOrder = () => {
//   const { courseId } = useParams(); // Get courseId from the URL
//   const [chapters, setChapters] = useState([]);
//   const navigate = useNavigate(); // For navigation

//   useEffect(() => {
//     const fetchChapters = async () => {
//       try {
//         const response = await axios.get(
//           `http://localhost:5000/api/youtubeChapters/course/${courseId}`
//         );
//         setChapters(response.data);
//       } catch (error) {
//         console.error("Error fetching chapters:", error);
//       }
//     };

//     fetchChapters();
//   }, [courseId]);

//   const handleOnDragEnd = (result) => {
//     if (!result.destination) return;

//     const items = Array.from(chapters);
//     const [reorderedItem] = items.splice(result.source.index, 1);
//     items.splice(result.destination.index, 0, reorderedItem);

//     setChapters(items);
//   };

//   const handleSaveOrder = async () => {
//     try {
//       const response = await axios.put(
//         `http://localhost:5000/api/youtubeChapters/updateOrder/${courseId}`,
//         { chapters: chapters.map((chapter, index) => ({ _id: chapter._id, order: index })) }
//       );

//       if (response.status === 200) {
//         Swal.fire({
//           icon: "success",
//           title: "Success",
//           text: "Chapter order updated successfully!",
//         });
//         navigate(-1);
//       }
//     } catch (error) {
//       console.error("Error updating chapter order:", error);
//       Swal.fire({
//         icon: "error",
//         title: "Error",
//         text: "Failed to update chapter order. Please try again later.",
//       });
//     }
//   };

//   return (
//     <div className={styles.container}>
//       <Typography variant="h5" className={styles.heading}>
//         Update Chapter Order for Course ID: {courseId}
//       </Typography>

//       {chapters.length > 0 ? (
//         <DragDropContext onDragEnd={handleOnDragEnd}>
//           <Droppable droppableId="chapters">
//             {(provided) => (
//               <ul
//                 className={styles.chapterList}
//                 {...provided.droppableProps}
//                 ref={provided.innerRef}
//               >
//                 {chapters.map((chapter, index) => (
//                   <Draggable
//                     key={chapter._id}
//                     draggableId={chapter._id}
//                     index={index}
//                   >
//                     {(provided) => (
//                       <li
//                         ref={provided.innerRef}
//                         {...provided.draggableProps}
//                         {...provided.dragHandleProps}
//                         className={styles.chapterItem}
//                       >
//                         <span>{chapter.chapterTitle}</span>
//                       </li>
//                     )}
//                   </Draggable>
//                 ))}
//                 {provided.placeholder}
//               </ul>
//             )}
//           </Droppable>
//         </DragDropContext>
//       ) : (
//         <Typography className={styles.noChapters}>
//           No chapters found for this course.
//         </Typography>
//       )}

//       <Button
//         variant="contained"
//         onClick={handleSaveOrder}
//         className={styles.saveButton}
//       >
//         Save Order
//       </Button>
//     </div>
//   );
// };

// export default UpdateChapterOrder;
