import React, { useState } from "react";
import "./HoverBox.css"; // Import your CSS file for styling

function HoverBox({ message, boxClass, pointsClass, learnClass }) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className={`box ${isHovered ? "hovered" : ""} ${boxClass}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
     <p className = "points-calculate">{pointsClass}</p>
      <p className="overall-learn">{learnClass}</p>
     
      <div className="box-content-for-message">
        {isHovered && <div className="message-for-hover">{message}</div>}
      </div>
    </div>
  );
}

export default HoverBox;
