// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btnSubmit {
  max-width: 50px;
  margin: 55px auto 10px;
}

#dropzone-upload-component {
  width: auto !important;
  height: auto !important;
  padding: 16px;
}

.img-preview {
  height: 100px;
  width: 100px;
  margin: 5px
}

.img-inline {
  display:inline-block
}


/* .ql-toolbar .ql-picker.ql-font .ql-picker-label::before, 
.ql-toolbar .ql-picker.ql-font .ql-picker-item::before {
  content: attr(data-label);
  font-family: inherit;
} */

/* .ql-font-mirza { font-family: 'Mirza', sans-serif; }
.ql-font-roboto { font-family: 'Roboto', sans-serif; }
.ql-font-arial { font-family: Arial, sans-serif; }
.ql-font-georgia { font-family: Georgia, serif; }
.ql-font-impact { font-family: Impact, sans-serif; }
.ql-font-tahoma { font-family: Tahoma, sans-serif; }
.ql-font-times-new-roman { font-family: 'Times New Roman', serif; }
.ql-font-verdana { font-family: Verdana, sans-serif; } */
`, "",{"version":3,"sources":["webpack://./src/BlogProject/BlogContainer/in.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,sBAAsB;AACxB;;AAEA;EACE,sBAAsB;EACtB,uBAAuB;EACvB,aAAa;AACf;;AAEA;EACE,aAAa;EACb,YAAY;EACZ;AACF;;AAEA;EACE;AACF;;;AAGA;;;;GAIG;;AAEH;;;;;;;wDAOwD","sourcesContent":[".btnSubmit {\n  max-width: 50px;\n  margin: 55px auto 10px;\n}\n\n#dropzone-upload-component {\n  width: auto !important;\n  height: auto !important;\n  padding: 16px;\n}\n\n.img-preview {\n  height: 100px;\n  width: 100px;\n  margin: 5px\n}\n\n.img-inline {\n  display:inline-block\n}\n\n\n/* .ql-toolbar .ql-picker.ql-font .ql-picker-label::before, \n.ql-toolbar .ql-picker.ql-font .ql-picker-item::before {\n  content: attr(data-label);\n  font-family: inherit;\n} */\n\n/* .ql-font-mirza { font-family: 'Mirza', sans-serif; }\n.ql-font-roboto { font-family: 'Roboto', sans-serif; }\n.ql-font-arial { font-family: Arial, sans-serif; }\n.ql-font-georgia { font-family: Georgia, serif; }\n.ql-font-impact { font-family: Impact, sans-serif; }\n.ql-font-tahoma { font-family: Tahoma, sans-serif; }\n.ql-font-times-new-roman { font-family: 'Times New Roman', serif; }\n.ql-font-verdana { font-family: Verdana, sans-serif; } */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
