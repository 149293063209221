import { useState, useEffect } from "react";
import mathQuizData from "./data/mathQuizData";
import scienceQuizData from "./data/scienceQuizData";
import historyQuizData from "./data/historyQuizData";
import geographyQuizData from "./data/geographyQuizData";
import "./quiz1.css";
import QuizResult from "./QuizResult";
import Sidebar from "./Sidebar";
import React, { useContext } from "react";
import Container from 'react-bootstrap/Container';
import OverallResult from "./OverallResult";
import Footer from '../../../component/Footer/Footer'; // Corrected path
import Header from '../../../component/DigitalcontentHeader/Header'; // Corrected path
import { ThemeContext } from '../../../Context/ThemeContext'; // Corrected path



const Quiz1 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userAnswers, setUserAnswers] = useState([]);
  const [showResult, setShowResult] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState("math");
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [elapsedTime, setElapsedTime] = useState(0);

  const handleAnswerChange = (event) => {
    const updatedAnswers = [...userAnswers];
    updatedAnswers[currentQuestionIndex] = parseInt(event.target.value);
    setUserAnswers(updatedAnswers);
  };

  const quizData =
    selectedSubject === "math"
      ? mathQuizData
      : selectedSubject === "science"
      ? scienceQuizData
      : selectedSubject === "history"
      ? historyQuizData
      : geographyQuizData;

  const handleNextQuestion = () => {
    if (currentQuestionIndex < quizData.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setEndTime(new Date().getTime());
    setShowResult(true);
  };

  const resetQuiz = () => {
    setCurrentQuestionIndex(0);
    setUserAnswers([]);
    setShowResult(false);
    setStartTime(null);
    setEndTime(null);
    setElapsedTime(0);
  };

  const isLastQuestion = currentQuestionIndex === quizData.length - 1;

  const currentQuestion = quizData[currentQuestionIndex];

  const calculateTotalScore = () => {
    let score = 0;
    userAnswers.forEach((answer, index) => {
      if (answer === quizData[index].answerIndex) {
        score++;
      }
    });
    return score;
  };

  const incorrectAnswers = quizData.filter((question, index) => {
    const userAnswerIndex = userAnswers[index];
    return userAnswerIndex !== question.answerIndex;
  });

  const handleSubjectChange = (subject) => {
    setSelectedSubject(subject);
    setCurrentQuestionIndex(0);
    setUserAnswers([]);
    setShowResult(false);
    setStartTime(null);
    setEndTime(null);
    setElapsedTime(0);
  };

  useEffect(() => {
    if (startTime === null) {
      setStartTime(new Date().getTime());
    }
  }, [startTime]);

  useEffect(() => {
    if (startTime !== null && endTime !== null) {
      setElapsedTime(endTime - startTime);
    }
  }, [startTime, endTime]);

  const renderSidebar = () => {
    return (
      <Sidebar
        subjects={[
          { id: "math", name: "Math" },
          { id: "science", name: "Science" },
          { id: "history", name: "History" },
          { id: "geography", name: "Geography" },
          {id:"hindi",name:"Hindi"},
          {id:"a",name:"Hindi"},
          {id:"b",name:"Hindi"},
          {id:"d",name:"Hindi"},
           {id:"e",name:"Hindi"},
            {id:"f",name:"Hindi"},
             {id:"g",name:"Hindi"},
              {id:"h",name:"Hindi"},
               {id:"i",name:"Hindi"},
        ]}
        selectedSubject={selectedSubject}
        onSubjectChange={handleSubjectChange}
      />
    );
  };
  let formattedTime = 0;
  const renderTimer = () => {
    if (startTime === null || endTime !== null) {
      return null;
    }

    const currentTime = new Date().getTime();
    const totalElapsedTime = elapsedTime + (currentTime - startTime);
    const seconds = Math.floor((totalElapsedTime / 1000) % 60);
    const minutes = Math.floor((totalElapsedTime / 1000 / 60) % 60);
    formattedTime = `${minutes}:${seconds < 10 ? "0" + seconds : seconds}`;

   
  };
  const { theme } = useContext(ThemeContext);

  return (
      <>
       <div
      className={`my-component ${
        theme === "dark" ? "dark-theme" : "light-theme"
      }`}
    >
     <Header/>
  
     
    <div className="quiz-container">
      {renderSidebar()}
       <div className= "body-for-quiz"> 
       <div className = "Subject-Name"><h2>Subject Quiz</h2></div>
       <div className="Questions"><h3> Question {currentQuestionIndex+1} of {quizData.length}</h3></div>
      {!showResult ? (
        <form onSubmit={handleSubmit}>
          {renderTimer()}
          <div className="for-quiz-answer">
            <h6>{currentQuestion.question}</h6>
            <ul className= "quiz-question-ul">
              {currentQuestion.options.map((option, optionIndex) => (
                <li key={optionIndex} className = "quiz-question-li">
                  <label className ="Radio-container">
                    <input
                      type="radio"
                      name="answer"
                      value={optionIndex}
                      onChange={handleAnswerChange}
                      checked={
                        userAnswers[currentQuestionIndex] === optionIndex
                      }
                    />
                    {option}
                  </label>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <button className ="button-for-quiz"
              type="button"
              onClick={handleNextQuestion}
              disabled={isLastQuestion}
            >
              Next
            </button>
            {isLastQuestion && <button  className ="button-for-quiz-submit" type="submit">Submit</button>}
          </div>
         <div className="timer">Time: {formattedTime}
         </div>
        </form>
      ) : (
      <OverallResult quizDatas={quizData}
          correctAnswer={userAnswers}
          incorrectAnswer={incorrectAnswers}
          totalScores={calculateTotalScore()}
          resetQuiz={resetQuiz}
          totalTime={formattedTime} />
      )}
    </div>
    </div>
    </div>
    </>
  );
};

export default Quiz1;
