import React, { useState, useEffect } from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { pdfjs } from 'react-pdf';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import './NewPdfShow1.css';

const PdfViewer = ({ url, onDocumentLoad }) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  return (
    <div className="pdf-viewer-container">
      <Worker workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}>
        <Viewer
          fileUrl={url}
          plugins={[defaultLayoutPluginInstance]}
          onDocumentLoad={onDocumentLoad} // Trigger scroll restore when document loads
        />
      </Worker>
    </div>
  );
};

const NewPdfShow1 = ({ chapterData, handleScroll }) => {
  const [pdfUrl, setPdfUrl] = useState(null);


  // console.log("mynew pdf show chapterdata", chapterData)
  // Function to disable text selection
  const disableTextSelection = (event) => {
    event.preventDefault();
  };

  // Function to prevent copy action
  const preventCopy = (event) => {
    event.preventDefault();
    // console.log('Copy action prevented');
  };

  useEffect(() => {
    const disableKeyCombinations = (event) => {
      if (event.ctrlKey && event.code === 'KeyP') {
        event.preventDefault();
        // console.log('Prevented:', event.code);
      }
    };

    document.addEventListener('keydown', disableKeyCombinations, true);
    document.addEventListener('selectstart', disableTextSelection); // Prevent text selection
    document.addEventListener('copy', preventCopy); // Prevent copying

    return () => {
      document.removeEventListener('keydown', disableKeyCombinations, true);
      document.removeEventListener('selectstart', disableTextSelection); // Remove text selection prevention
      document.removeEventListener('copy', preventCopy); // Remove copy prevention
    };
  }, []);

  // Function to save scroll position to localStorage
  const saveScrollPosition = (scrollPosition) => {
    localStorage.setItem(`mainpdfScrollPosition_${chapterData._id}`, scrollPosition);
    // console.log(`Saved scroll position for chapter ${chapterData._id}: ${scrollPosition}`);
  };

  // Function to restore scroll position from localStorage
  const restoreScrollPosition = () => {
    const scrollableElement = document.querySelector('.rpv-core__inner-pages');
    const savedScrollTop = localStorage.getItem(`mainpdfScrollPosition_${chapterData._id}`);
    console.log(`Restoring scroll position for chapter ${chapterData._id}: ${savedScrollTop}`);
    if (scrollableElement && savedScrollTop) {
      scrollableElement.scrollTop = parseFloat(savedScrollTop);
      // console.log(`Scroll position set to: ${scrollableElement.scrollTop}`);
    }
  };

  useEffect(() => {
    const fetchPdfData = async () => {
      try {
        // Use the chapterData._id to fetch the specific data for that chapter
        const response = await fetch(`https://auth.ssccglpinnacle.com/api/pdfs-ebook/${chapterData._id}`);
        
        if (!response.ok) {
          throw new Error('Failed to fetch PDF data');
        }
  
        const data = await response.json();
        // Check if the data array has at least one item
        if (data.length > 0) {
          // Set the PDF URL using the s3Key from the response data
          setPdfUrl(`https://dzdx39zg243ni.cloudfront.net/${data[0].s3Key}`);
          // console.log("PDF Key:",`https://dzdx39zg243ni.cloudfront.net/${data[0].s3Key}`);  // Optional: Log the s3Key for debugging
        } else {
          console.error("No PDF data found for this chapter.");
        }
      } catch (error) {
        console.error('Error fetching PDF data:', error);
      }
    };
  
    // Call the fetch function when chapterData changes
    fetchPdfData();
  }, [chapterData]);
  

 

  useEffect(() => {
    const handleScrollWithLogging = () => {
      const scrollableElement = document.querySelector('.rpv-core__inner-pages');
      if (scrollableElement) {
        console.log('Scroll event:', {
          scrollTop: scrollableElement.scrollTop,
          scrollHeight: scrollableElement.scrollHeight,
          clientHeight: scrollableElement.clientHeight,
        });

        // Save scroll position to localStorage
        saveScrollPosition(scrollableElement.scrollTop);
        handleScroll();
      }
    };

    const observer = new MutationObserver((mutationsList, observer) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
          const scrollableElement = document.querySelector('.rpv-core__inner-pages');
          if (scrollableElement) {
            console.log('Scrollable element found:', scrollableElement);
            scrollableElement.addEventListener("scroll", handleScrollWithLogging);
            observer.disconnect();
          }
        }
      }
    });

    observer.observe(document.body, { childList: true, subtree: true });

    return () => {
      const scrollableElement = document.querySelector('.rpv-core__inner-pages');
      if (scrollableElement) {
        scrollableElement.removeEventListener("scroll", handleScrollWithLogging);
      }
      observer.disconnect();
    };
  }, [handleScroll]);


  return (
    <div className="pdf-viewer">
      {pdfUrl ? (
        <PdfViewer url={pdfUrl} onDocumentLoad={restoreScrollPosition} />
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
};

export default NewPdfShow1;
