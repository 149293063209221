import React, { useState, useEffect, useRef } from "react";
import { GrFormClose } from "react-icons/gr";
import { AiFillStar } from "react-icons/ai";
import styles from "./FeedBackPortal.module.css";
import axios from 'axios';

const FeedbackPortal = ({ isOpen, onClose, onFeedbackSubmit, userId, courseId }) => {
  const [rating, setRating] = useState(0); 
  const [hoverRating, setHoverRating] = useState(0); 
  const [feedback, setFeedback] = useState(""); 
  const [hoveredStarSubheading, setHoveredStarSubheading] = useState(""); 
  const modalRef = useRef(null); 

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, onClose]);

  useEffect(() => {
    const fetchFeedback = async () => {
      if (isOpen) { 
        console.log('Modal is open, fetching feedback data...');

        try {
          const response = await axios.get('https://auth.ssccglpinnacle.com/api/get-feedback', {
            params: { userId, courseId }
          });

          if (response.data) {
            const { rating, feedback } = response.data; 
            setRating(rating);
            setFeedback(feedback);
            setHoveredStarSubheading(getStarSubheading(rating));
          }
        } catch (error) {
          console.error("Error fetching feedback:", error);
        }
      }
    };

    fetchFeedback();
  }, [isOpen, userId, courseId]); 

  // Handle star rating click
  const handleRatingClick = (value) => {
    setRating(value); // Set the selected rating
    setHoveredStarSubheading(getStarSubheading(value)); 
  };

  // Handle hover over star rating
  const handleRatingHover = (value) => {
    setHoverRating(value); 
    setHoveredStarSubheading(getStarSubheading(value)); 
  };

  // Handle feedback text change
  const handleFeedbackChange = (e) => {
    setFeedback(e.target.value); 
  };

  // Submit feedback to the API
  const handleSubmit = async () => {
    try {
      const feedbackData = {
        userId,
        courseId, 
        rating,
        feedback,
      };


      // Submit the feedback data to the backend
      await axios.post('https://auth.ssccglpinnacle.com/api/submit-feedback', feedbackData);

      // Notify the parent component about the submission
      onFeedbackSubmit(rating, feedback);

      // Close the modal after submission
      onClose();
    } catch (error) {
      console.error("Error submitting feedback:", error);
    }
  };

  // Get subheading text based on star value
  const getStarSubheading = (starValue) => {
    if (starValue === 1) return "Awful";
    if (starValue === 2) return "Poor";
    if (starValue === 3) return "OK";
    if (starValue === 4) return "Good";
    if (starValue === 5) return "Awesome";
    return "";
  };

  if (!isOpen) return null; 

  return (
    <div className={styles["modal-backdrop"]}>
      <div className={styles["modal-content"]} ref={modalRef}>
        <button className={styles["close-icon"]} onClick={onClose}>
          <GrFormClose size={20} />
        </button>
        <h2>How would you rate this course?</h2>
        <h5>{hoveredStarSubheading || "Select Rating"}</h5> 
        <div className={styles["rating-stars"]}>
          {[...Array(5)].map((_, index) => {
            const starValue = index + 1;
            const isFilled = starValue <= (hoverRating || rating); 

            return (
              <label
                key={index}
                className={styles.star}
                onMouseEnter={() => handleRatingHover(starValue)} 
                onMouseLeave={() => handleRatingHover(rating)} 
                onClick={() => handleRatingClick(starValue)} 
                data-title={getStarSubheading(starValue)}
              >
                <AiFillStar
                  size={40}
                  color={isFilled ? "#f1c40f" : "#d1d7dc"} 
                />
              </label>
            );
          })}
        </div>
        <textarea
          className={styles["feedback-textarea"]}
          placeholder="Enter your feedback here..."
          value={feedback} 
          onChange={handleFeedbackChange} 
        />
        <button className={styles["submit-button"]} onClick={handleSubmit}>
          Submit
        </button>
      </div>
    </div>
  );
};

export default FeedbackPortal;
