// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* SearchBar.css */

.SearchBar {
    text-align: center;
        width: 14%;
  }
  
  .search-container1 {
      display: flex;
      align-items: center;
      border: 1px solid #0000004f;
      border-radius: 20px;
      overflow: hidden;
      /* align-content: space-between; */
      justify-content: space-between;
  }
  
  .search-container1 input {
    
    font-size: 16px;
    border: none;
    outline: none;
    width: 80%;
    padding-left:10px;/* Adjust the width of the input */
  }
  
  .search-container1 button {
  
    padding:5px;
    color: black;
    border: none;
   /* Adjust border-radius to match the container */
    cursor: pointer;
  }
  
  .search-container1 input::placeholder {
    color: #999; /* Adjust the placeholder text color */
    font-style: italic;
    margin-left:10px;/* Optionally, make the placeholder text italic */
  }
  
  /* Additional styles for specific states, if needed */
  .search-container1 input:focus {
    outline: none;
    border-color: #0056b3; /* Change the border color on focus */
  }
  
  /* If you want to style the button differently on hover */
  
  @media (min-width: 1501px) and (max-width: 1599px) {
  .search-container1 {
    padding: 3px;
}
  }`, "",{"version":3,"sources":["webpack://./src/component/DigitalcontentHeader/SearchBar1.css"],"names":[],"mappings":"AAAA,kBAAkB;;AAElB;IACI,kBAAkB;QACd,UAAU;EAChB;;EAEA;MACI,aAAa;MACb,mBAAmB;MACnB,2BAA2B;MAC3B,mBAAmB;MACnB,gBAAgB;MAChB,kCAAkC;MAClC,8BAA8B;EAClC;;EAEA;;IAEE,eAAe;IACf,YAAY;IACZ,aAAa;IACb,UAAU;IACV,iBAAiB,CAAC,kCAAkC;EACtD;;EAEA;;IAEE,WAAW;IACX,YAAY;IACZ,YAAY;GACb,gDAAgD;IAC/C,eAAe;EACjB;;EAEA;IACE,WAAW,EAAE,sCAAsC;IACnD,kBAAkB;IAClB,gBAAgB,CAAC,iDAAiD;EACpE;;EAEA,qDAAqD;EACrD;IACE,aAAa;IACb,qBAAqB,EAAE,qCAAqC;EAC9D;;EAEA,yDAAyD;;EAEzD;EACA;IACE,YAAY;AAChB;EACE","sourcesContent":["/* SearchBar.css */\n\n.SearchBar {\n    text-align: center;\n        width: 14%;\n  }\n  \n  .search-container1 {\n      display: flex;\n      align-items: center;\n      border: 1px solid #0000004f;\n      border-radius: 20px;\n      overflow: hidden;\n      /* align-content: space-between; */\n      justify-content: space-between;\n  }\n  \n  .search-container1 input {\n    \n    font-size: 16px;\n    border: none;\n    outline: none;\n    width: 80%;\n    padding-left:10px;/* Adjust the width of the input */\n  }\n  \n  .search-container1 button {\n  \n    padding:5px;\n    color: black;\n    border: none;\n   /* Adjust border-radius to match the container */\n    cursor: pointer;\n  }\n  \n  .search-container1 input::placeholder {\n    color: #999; /* Adjust the placeholder text color */\n    font-style: italic;\n    margin-left:10px;/* Optionally, make the placeholder text italic */\n  }\n  \n  /* Additional styles for specific states, if needed */\n  .search-container1 input:focus {\n    outline: none;\n    border-color: #0056b3; /* Change the border color on focus */\n  }\n  \n  /* If you want to style the button differently on hover */\n  \n  @media (min-width: 1501px) and (max-width: 1599px) {\n  .search-container1 {\n    padding: 3px;\n}\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
