import React, { useState, useEffect } from "react";
import styles from "./VideoBanner.module.css";
import Swal from "sweetalert2";

const VideoBanner = () => {
  const [courseTitle, setCourseTitle] = useState("");
  const [order, setOrder] = useState("");
  const [hindiCoverImagePreview, setHindiCoverImagePreview] = useState(null);
  const [englishCoverImagePreview, setEnglishCoverImagePreview] =
    useState(null);
  const [recentCourses, setRecentCourses] = useState([]);
  const [selectedCourseId, setSelectedCourseId] = useState(null); // To store courseId
  const [error, setError] = useState(null);

  // Fetch recent courses
  useEffect(() => {
    const fetchRecentCourses = async () => {
      try {
        console.log("Fetching recent courses...");
        const response = await fetch(
          "https://auth.ssccglpinnacle.com/rc/courses"
        );

        // Check for network errors
        if (!response.ok) {
          throw new Error(
            `Network response was not ok. Status: ${response.status}`
          );
        }

        const data = await response.json();

        console.log("Courses API Response:", data); // Log the data

        // Ensure that the data contains courses with titles
        if (Array.isArray(data) && data.length > 0) {
          setRecentCourses(data.filter((course) => course.courseTitle)); // Filter out courses without titles
        } else {
          setError("No courses found.");
        }
      } catch (error) {
        console.error("Error fetching recent courses:", error);
        setError("Failed to fetch courses.");
      }
    };

    fetchRecentCourses();
  }, []);

  // Handle course selection and update preview images
  const handleCourseChange = (e) => {
    const selectedCourseTitle = e.target.value;
    setCourseTitle(selectedCourseTitle);

    // Find the selected course from recentCourses
    const selectedCourse = recentCourses.find(
      (course) => course.courseTitle === selectedCourseTitle
    );

    if (selectedCourse) {
      setSelectedCourseId(selectedCourse._id); // Store the courseId for the API
      setHindiCoverImagePreview(selectedCourse.hindiCoverImage);
      setEnglishCoverImagePreview(selectedCourse.englishCoverImage);
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Ensure all fields are filled
    if (!selectedCourseId || !courseTitle || !order) {
      Swal.fire({
        title: "Error!",
        text: "Please fill all required fields.",
        icon: "error",
        confirmButtonText: "OK",
      });
      return;
    }

    // Prepare data for API submission
    const newBannerData = {
      course: selectedCourseId, // Send courseId to the backend
      courseTitle,
      order,
      hindiCoverImage: hindiCoverImagePreview,
      englishCoverImage: englishCoverImagePreview,
    };

    try {
      const response = await fetch(
        "https://auth.ssccglpinnacle.com/api/banner",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newBannerData),
        }
      );

      const result = await response.json();

      if (response.ok) {
        Swal.fire({
          title: "Success!",
          text: "Banner created successfully!",
          icon: "success",
          confirmButtonText: "OK",
        });
        // Clear the form fields after successful submission
        setCourseTitle("");
        setOrder("");
        setHindiCoverImagePreview(null);
        setEnglishCoverImagePreview(null);
      } else {
        Swal.fire({
          title: "Error!",
          text: result.message || "Failed to create banner",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      console.error("Error creating banner:", error);
      Swal.fire({
        title: "Error!",
        text: "An error occurred while creating the banner",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  return (
    <>
      <h1 className={styles.heading}>Upload Data to Video Promotion Slider</h1>
      <div className={styles.container}>
        <form onSubmit={handleSubmit}>
          <div className={styles.formGroup}>
            <label htmlFor="courseTitle" className={styles.labelTitle}>
              Course Title
            </label>
            <select
              id="courseTitle"
              name="courseTitle"
              value={courseTitle}
              onChange={handleCourseChange}
              className={styles.inputTitle}
              required
            >
              <option value="" disabled>
                Select a course
              </option>
              {error ? (
                <option value="" disabled>
                  {error}
                </option>
              ) : (
                recentCourses.map((course) => (
                  <option key={course._id} value={course.courseTitle}>
                    {course.courseTitle}
                  </option>
                ))
              )}
            </select>
          </div>

          {/* Preview Section */}
          <div className={styles.previewSection}>
            {hindiCoverImagePreview && (
              <div>
                <h4>Hindi Cover Image Preview:</h4>
                <img
                  src={hindiCoverImagePreview}
                  alt="Hindi Cover Preview"
                  style={{ width: "150px", height: "auto", margin: "10px 0" }}
                />
              </div>
            )}
            {englishCoverImagePreview && (
              <div>
                <h4>English Cover Image Preview:</h4>
                <img
                  src={englishCoverImagePreview}
                  alt="English Cover Preview"
                  style={{ width: "150px", height: "auto", margin: "10px 0" }}
                />
              </div>
            )}
          </div>

          <div className={styles.formGroup}>
            <label htmlFor="order" className={styles.labelTitle}>
              Order
            </label>
            <input
              type="number"
              id="order"
              name="order"
              value={order}
              onChange={(e) => setOrder(e.target.value)}
              className={styles.inputTitle}
              required
            />
          </div>

          <button type="submit" className={styles.buttonUpload}>
            Upload Banner
          </button>
        </form>
      </div>
    </>
  );
};

export default VideoBanner;
