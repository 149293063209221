// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AccountNotification_main_container__Mznnw {
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  margin-left: 3%;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  margin-top: 9.2%;
}

.AccountNotification_save_btn__J0sOG {
  background-color: black;
  width: 3rem;
  height: 2rem;
  margin: 2%;
}
.AccountNotification_notify_title__fL7pc {
  padding: 5%;
}
.AccountNotification_notify_heading__6N-ty {
  margin-left: 35%;
  margin-bottom: 1%;
}
.AccountNotification_notify_para__fRlfX {
  margin-left: 10%;
}
.AccountNotification_notify_heading2__b97lN {
  margin-left: 5%;
}
.AccountNotification_label1__hV5Kc {
  display: flex;
}
.AccountNotification_body_container1__x5lIR,
.AccountNotification_body_container2__4Oizc,
.AccountNotification_body_container3__y8-2M {
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  margin: 4%;
  padding: 3%;
}
.AccountNotification_body_container4__tS1TY {
  margin-bottom: 5%;
  margin-left: 2%;
}
.AccountNotification_label1__hV5Kc,
.AccountNotification_label2__Vqy1z,
.AccountNotification_label3__Vzz4j {
  display: flex;
}
.AccountNotification_heading_label1__w1NJ2,
.AccountNotification_heading_label2__NlvFl,
.AccountNotification_heading_label3__upO7y {
  margin-left: 2%;
}
.AccountNotification_label_para__gEBzl {
  margin-left: 5%;
  font-size: 14px;
  margin-top: 0.5%;
  line-height: 1.2rem;
}
`, "",{"version":3,"sources":["webpack://./src/VIDEOPLAYER COMPONENTS/components/VideoPlayerPage/AccountPage/AccountNotification.module.css"],"names":[],"mappings":"AAAA;EACE;0CACwC;EACxC,eAAe;EACf;0CACwC;EACxC,gBAAgB;AAClB;;AAEA;EACE,uBAAuB;EACvB,WAAW;EACX,YAAY;EACZ,UAAU;AACZ;AACA;EACE,WAAW;AACb;AACA;EACE,gBAAgB;EAChB,iBAAiB;AACnB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,eAAe;AACjB;AACA;EACE,aAAa;AACf;AACA;;;EAGE;0CACwC;EACxC,UAAU;EACV,WAAW;AACb;AACA;EACE,iBAAiB;EACjB,eAAe;AACjB;AACA;;;EAGE,aAAa;AACf;AACA;;;EAGE,eAAe;AACjB;AACA;EACE,eAAe;EACf,eAAe;EACf,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":[".main_container {\n  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,\n    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;\n  margin-left: 3%;\n  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,\n    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;\n  margin-top: 9.2%;\n}\n\n.save_btn {\n  background-color: black;\n  width: 3rem;\n  height: 2rem;\n  margin: 2%;\n}\n.notify_title {\n  padding: 5%;\n}\n.notify_heading {\n  margin-left: 35%;\n  margin-bottom: 1%;\n}\n.notify_para {\n  margin-left: 10%;\n}\n.notify_heading2 {\n  margin-left: 5%;\n}\n.label1 {\n  display: flex;\n}\n.body_container1,\n.body_container2,\n.body_container3 {\n  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,\n    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;\n  margin: 4%;\n  padding: 3%;\n}\n.body_container4 {\n  margin-bottom: 5%;\n  margin-left: 2%;\n}\n.label1,\n.label2,\n.label3 {\n  display: flex;\n}\n.heading_label1,\n.heading_label2,\n.heading_label3 {\n  margin-left: 2%;\n}\n.label_para {\n  margin-left: 5%;\n  font-size: 14px;\n  margin-top: 0.5%;\n  line-height: 1.2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main_container": `AccountNotification_main_container__Mznnw`,
	"save_btn": `AccountNotification_save_btn__J0sOG`,
	"notify_title": `AccountNotification_notify_title__fL7pc`,
	"notify_heading": `AccountNotification_notify_heading__6N-ty`,
	"notify_para": `AccountNotification_notify_para__fRlfX`,
	"notify_heading2": `AccountNotification_notify_heading2__b97lN`,
	"label1": `AccountNotification_label1__hV5Kc`,
	"body_container1": `AccountNotification_body_container1__x5lIR`,
	"body_container2": `AccountNotification_body_container2__4Oizc`,
	"body_container3": `AccountNotification_body_container3__y8-2M`,
	"body_container4": `AccountNotification_body_container4__tS1TY`,
	"label2": `AccountNotification_label2__Vqy1z`,
	"label3": `AccountNotification_label3__Vzz4j`,
	"heading_label1": `AccountNotification_heading_label1__w1NJ2`,
	"heading_label2": `AccountNotification_heading_label2__NlvFl`,
	"heading_label3": `AccountNotification_heading_label3__upO7y`,
	"label_para": `AccountNotification_label_para__gEBzl`
};
export default ___CSS_LOADER_EXPORT___;
