import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import  "./Row2Digital.css";
import Row3Digital from "./Row3Digital";
import pic from '../../i/b.png';
import Time from "./Timer";

function Row2Digital({ filteredEbookData }) {
    
return (
<>
<div className ="For-content ">
 <div className ="Making-your-Study" >
  <h4> Making your study digital : Pinnacle ebook platform</h4></div>
<div className ="For-offer-container " >
  <div className = "For-offer-image " >

   <img className="image-offer" src={pic} />
  </div>
<div className ="Offer-row">
<div><h4><i>Congratulations</i> you have won 60% discount offers !</h4></div>
<div><h5>plus 5% extra instant purchase Offer ends in {<Time/>}</h5></div>
 <div className="try-for-free-offer">
  {/* <button className="button-for-offer button5-offer">Try for free now</button> */}
</div>

</div>
  </div>
  <Row3Digital filteredEbookData={filteredEbookData}/>
</div>
    </>
  )
}

export default Row2Digital;
