import React from 'react';
import Blog1 from './Blog1';
import Blog2 from './Blog2';
import Blog3 from './Blog3';
import Blog4 from './Blog4';
import "./Blog1.css";
import Footer from '../../FooterMain/Footer';

const categories = [
  'SSC',
  'RRB NTPC',
  'Daily current affairs',
  'Topic wise current affairs',
  'Last one year current affairs',
  'Monthly current affairs',
  'Up police',
  'HSSC'
];

const Blogmain = () => {
  return (
    <>
    <div className='blog-main-container'>
      <Blog1 />
      {categories.slice(0, 4).map(category => (
        <Blog2 key={category} category={category} />
      ))}
      <Blog4 />
      {categories.slice(4).map(category => (
        <Blog2 key={category} category={category} />
      ))}
      {/* <Blog3 /> */}
      </div>
      <Footer/>
    </>
  );
};

export default Blogmain;
