import React from "react";
import ProgressBar from "./ProgressBar";
 // Import your CSS file

function LessonProgress( {totalLessons, completedLessons}) {

let completed1 = Math.floor((completedLessons / totalLessons) * 100);

  // console.log(completed1);

  return (
    <> 
      
      <ProgressBar
        completed={completed1}
        bgcolor="black"
      />
    </>
  );
}

export default LessonProgress;
