// import React, { useState, useMemo } from 'react';
// import { MaterialReactTable } from 'material-react-table';
// import './NewStudentAccessForm.css'; // Make sure to import the CSS file

// const NewStudentAccessForm = () => {
//   const [emailIds, setEmailIds] = useState(''); // Handle multiple emails
//   const [ebooks, setEbooks] = useState([]);
//   const [selectedEbooks, setSelectedEbooks] = useState([]);
//   const [notFoundEmails, setNotFoundEmails] = useState([]);
//   const [error, setError] = useState('');
//   const [message, setMessage] = useState('');

//   const handleEmailInputChange = (event) => {
//     setEmailIds(event.target.value);
//   };

//   const handleEbookSelection = (event, ebookId) => {
//     setSelectedEbooks((prevSelected) =>
//       prevSelected.includes(ebookId)
//         ? prevSelected.filter((id) => id !== ebookId)
//         : [...prevSelected, ebookId]
//     );
//   };

//   const handleFetchEbooks = async () => {
//     try {
//       const response = await fetch('https://auth.ssccglpinnacle.com/api/ebooksforactive?active=true');
//       const data = await response.json();

//       if (response.ok) {
//         setEbooks(data);
//         setError('');
//       } else {
//         setError(data.message || 'Error fetching ebooks');
//         setEbooks([]);
//       }
//     } catch (error) {
//       setError('Error fetching ebooks');
//       console.error('Error fetching ebooks:', error);
//       setEbooks([]);
//     }
//   };

//   const handleSubmit = async (event) => {
//     event.preventDefault();

//     const emailArray = emailIds.split('\n').map(email => email.trim()).filter(email => email);

//     if (emailArray.length > 1000) {
//       setError('You can only submit up to 1000 emails at a time.');
//       return;
//     }

//     try {
//       const response = await fetch('https://auth.ssccglpinnacle.com/api/process-bulk-emails', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ emailIds: emailArray, selectedEbooks }),
//       });

//       const data = await response.json();

//       if (response.ok) {
//         setMessage('Ebook access successfully saved.');
//         setError('');
//         setEbooks([]);
//         setSelectedEbooks([]);
//         setEmailIds('');
//         setNotFoundEmails(data.notFoundEmails || []);
//       } else {
//         setError(data.message || 'Error processing ebook access.');
//         setMessage('');
//       }
//     } catch (error) {
//       setError('Error processing ebook access.');
//       console.error('Error processing ebook access:', error);
//     }
//   };

//   const columns = useMemo(
//     () => [
//       {
//         accessorKey: 'title',
//         header: 'Title',
//       },
//       {
//         accessorKey: 'medium',
//         header: 'Medium',
//       },
//       {
//         accessorKey: 'edition',
//         header: 'Edition',
//       },
//       {
//         accessorKey: 'id',
//         header: 'ID',
//       },
//       {
//         id: 'select',
//         header: 'Select',
//         Cell: ({ row }) => (
//           <input
//             type="checkbox"
//             value={row.original.id}
//             checked={selectedEbooks.includes(row.original.id)}
//             onChange={(e) => handleEbookSelection(e, row.original.id)}
//           />
//         ),
//       },
//     ],
//     [selectedEbooks]
//   );

//   return (
//     <> 
//       <h2 className="add-ebook-form">Student Access Form</h2>
//       <div className="student-access-ebook">
//         <form onSubmit={handleSubmit}>
//           <div>
//             <label>Email IDs (each on a new line):</label>
//             <textarea
//               value={emailIds}
//               onChange={handleEmailInputChange}
//               rows={10}
//               required
//               placeholder="Enter up to 1000 email addresses, each on a new line"
//             />
//             <button className="student-access-ebook-button" type="button" onClick={handleFetchEbooks}>
//               Fetch Ebooks
//             </button>
//           </div>
//           {ebooks && ebooks.length > 0 && (
//             <MaterialReactTable
//               columns={columns}
//               data={ebooks}
//               enableRowSelection={false}
//               enableMultiRowSelection={false}
//               getRowId={(row) => row._id}
//             />
//           )}
//           <button className="student-access-ebook-submit" type="submit">Submit</button>
//         </form>
//         {error && <p className="error">{error}</p>}
//         {message && <p className="success">{message}</p>}
//         {notFoundEmails.length > 0 && (
//           <div>
//             <h3>Emails Not Found</h3>
//             <MaterialReactTable
//               columns={[
//                 { accessorKey: 'email', header: 'Email' }
//               ]}
//               data={notFoundEmails.map(email => ({ email }))}
//               enablePagination={true}
//               initialState={{ pagination: { pageSize: 10 } }}
//             />
//           </div>
//         )}
//       </div>
//     </>
//   );
// };

// export default NewStudentAccessForm;


import React, { useState, useMemo } from 'react';
import { MaterialReactTable } from 'material-react-table';
import './NewStudentAccessForm.css';

const NewStudentAccessForm = () => {
  const [emailIds, setEmailIds] = useState('');
  const [ebooks, setEbooks] = useState([]);
  const [selectedEbooks, setSelectedEbooks] = useState([]);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');

  const handleEmailInputChange = (event) => {
    setEmailIds(event.target.value);
  };

  const handleEbookSelection = (event, ebookId) => {
    setSelectedEbooks((prevSelected) =>
      prevSelected.includes(ebookId)
        ? prevSelected.filter((id) => id !== ebookId)
        : [...prevSelected, ebookId]
    );
  };

  const handleFetchEbooks = async () => {
    try {
      const response = await fetch(
        'https://auth.ssccglpinnacle.com/api/ebooksforactive?active=true'
      );
      const data = await response.json();

      if (response.ok) {
        setEbooks(data);
        setError('');
      } else {
        setError(data.message || 'Error fetching ebooks');
        setEbooks([]);
      }
    } catch (error) {
      setError('Error fetching ebooks');
      console.error('Error fetching ebooks:', error);
      setEbooks([]);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const emailArray = emailIds
      .split('\n')
      .map((email) => email.trim())
      .filter((email) => email);

    if (emailArray.length > 1000) {
      setError('You can only submit up to 1000 emails at a time.');
      return;
    }

    try {
      const response = await fetch(
        'https://auth.ssccglpinnacle.com/api/process-bulk-emails',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ emailIds: emailArray, selectedEbooks }),
        }
      );

      const data = await response.json();

      if (response.ok) {
        setMessage('Ebook access successfully saved.');
        setError('');
        setEbooks([]);
        setSelectedEbooks([]);
        setEmailIds('');
      } else {
        setError(data.message || 'Error processing ebook access.');
        setMessage('');
      }
    } catch (error) {
      setError('Error processing ebook access.');
      console.error('Error processing ebook access:', error);
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'title',
        header: 'Title',
      },
      {
        accessorKey: 'medium',
        header: 'Medium',
      },
      {
        accessorKey: 'edition',
        header: 'Edition',
      },
      {
        accessorKey: 'id',
        header: 'ID',
      },
      {
        id: 'select',
        header: 'Select',
        Cell: ({ row }) => (
          <input
            type="checkbox"
            value={row.original.id}
            checked={selectedEbooks.includes(row.original.id)}
            onChange={(e) => handleEbookSelection(e, row.original.id)}
          />
        ),
      },
    ],
    [selectedEbooks]
  );

  return (
    <>
      <h2 className="add-ebook-form">Student Access Form</h2>
      <div className="student-access-ebook">
        <form onSubmit={handleSubmit}>
          <div>
            <label>Email IDs (each on a new line):</label>
            <textarea
              value={emailIds}
              onChange={handleEmailInputChange}
              rows={10}
              required
              placeholder="Enter up to 1000 email addresses, each on a new line"
            />
            <button
              className="student-access-ebook-button"
              type="button"
              onClick={handleFetchEbooks}
            >
              Fetch Ebooks
            </button>
          </div>
          {ebooks && ebooks.length > 0 && (
            <MaterialReactTable
              columns={columns}
              data={ebooks}
              enableRowSelection={false}
              enableMultiRowSelection={false}
              getRowId={(row) => row._id}
            />
          )}
          <button className="student-access-ebook-submit" type="submit">
            Submit
          </button>
        </form>
        {error && <p className="error">{error}</p>}
        {message && <p className="success">{message}</p>}
      </div>
    </>
  );
};

export default NewStudentAccessForm;
