import React, { useState, useEffect } from "react";
import classes from "./description.module.css";
import { useParams } from "react-router-dom";

export default function Description() {
  const [courseDetails, setCourseDetails] = useState(null);
  const { courseId } = useParams(); // Destructure courseId from useParams

  useEffect(() => {
    getCourseDetails();
  }, [courseId]); // Add courseId as a dependency

  const getCourseDetails = async () => {
    try {
      let result = await fetch(`https://auth.ssccglpinnacle.com/course/${courseId}`);
      if (!result.ok) {
        throw new Error("Failed to fetch course details");
      }
      result = await result.json();
      setCourseDetails(result.longDescription);
    } catch (error) {
      console.error("Error fetching course details:", error);
    }
  };

  return (
    <div className={classes["description-fullpage"]}>
      <div className={classes["description-heading"]}>Description</div>
      {courseDetails ? (
        <div
          className={classes["description-content"]}
          dangerouslySetInnerHTML={{ __html: courseDetails }}
        ></div>
      ) : (
        <div className={classes["description-content"]}>
          <img
            src="https://d3m4h509gttb94.cloudfront.net/Image/loading-walk.gif"
            alt="loading"
          />
        </div>
      )}
    </div>
  );
}
