import React, { useState } from "react";
import VideoFeatures from "./VideoFeatures";
import Categories from "./Categories";
import Comments from "./Comments";
import classes from "./Body.module.css";
import RecLunchedCourse from "./RecLunchedCourse";
import UpcomingCourse from "./UpcomingCourse";
import MostPopularCourses from "./MostPopularCourses";

export default function Body() {
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubjects, setSelectedSubjects] = useState([]);

  // console.log("Selected Category:", selectedCategory);
  // console.log("Selected Subjects:", selectedSubjects);

  return (
    <>
      <div className={classes.Body_FullPage}>
        <div className={classes["Categories-VideoFeatures-Comments-div"]}>
          <div className={classes.categories_component}>
            {/* Passing the category and subject state handlers to Categories */}
            <Categories
              onCategorySelect={setSelectedCategory}
              onSubjectSelect={setSelectedSubjects}
            />
          </div>
          <div className={classes.videoAndComments_div}>
            <div>
              <VideoFeatures />
            </div>
            <div className={classes.Comments_div}>
              <Comments />
            </div>
          </div>
        </div>

        <div className={classes.RecLunchedCourse_div}>
          {/* Passing selectedCategory and selectedSubjects to RecLunchedCourse */}
          <RecLunchedCourse
            category={selectedCategory}
            subject={selectedSubjects}
          />
        </div>
        {/* <div className={classes.UpcomingCourse_div}>
          <UpcomingCourse
            category={selectedCategory}
            subject={selectedSubjects}
          />
        </div> */}
        <div className={classes.MostPopularCourses_div}>
          <MostPopularCourses
            category={selectedCategory}
            subject={selectedSubjects}
          />
        </div>
      </div>
    </>
  );
}
