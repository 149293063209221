// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Notification_notify_card__LPHrH {
  border: 0.5px #d1d7dc solid;
  width: 29%;
  padding: 1%;
  position: absolute;
  right: 1%;
  z-index: 1;
  top: 5%;
}
.Notification_dropdown__jiJHu {
  color: white;
  padding: 9px;
  font-size: 16px;
  border: none;
  height: 100%;
}
.Notification_dropdown__jiJHu:hover .Notification_dropdown_content__7tYot {
  display: block;
}
.Notification_notify_btn__RjGJO {
  font-size: 16px;
  font-weight: 600;
  color: black;
}
.Notification_notify_container__tlWMC {
  display: flex;
}
.Notification_notify__wWByn {
  font-size: 16px;
  font-weight: 700;
  color: black;
  margin-right: 55%;
}
.Notification_instructor__ar0CQ {
  font-size: 14px;
  font-weight: 700;
  color: black;
  margin-right: 55%;
}
.Notification_students__jyFMM {
  font-size: 14px;
  font-weight: 600;
  color: rgb(97, 4, 113);
}
.Notification_settings__sKAY6 {
  font-size: 16px;
  font-weight: 600;
  color: rgb(97, 4, 113);
}
.Notification_notify_unorderlist__IiFeB {
  list-style: none;
}
.Notification_motify_item__qU7Nq {
  font-size: 14px;
  text-align: center;
}
.Notification_notify_list__2aBag {
  font-size: 14px;
  line-height: 2rem;
  font-weight: 400;
  text-align: center;
  color: black;
}
.Notification_notification_container__\\+Wcec {
  display: flex;
  padding: 0 5% 0 5%;
  font-size: 12px;
  margin-top: 5%;
}
`, "",{"version":3,"sources":["webpack://./src/VIDEOPLAYER COMPONENTS/components/VideoHeader/Notification.module.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,UAAU;EACV,WAAW;EACX,kBAAkB;EAClB,SAAS;EACT,UAAU;EACV,OAAO;AACT;AACA;EACE,YAAY;EACZ,YAAY;EACZ,eAAe;EACf,YAAY;EACZ,YAAY;AACd;AACA;EACE,cAAc;AAChB;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,YAAY;AACd;AACA;EACE,aAAa;AACf;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,YAAY;EACZ,iBAAiB;AACnB;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,YAAY;EACZ,iBAAiB;AACnB;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,sBAAsB;AACxB;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,sBAAsB;AACxB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,eAAe;EACf,kBAAkB;AACpB;AACA;EACE,eAAe;EACf,iBAAiB;EACjB,gBAAgB;EAChB,kBAAkB;EAClB,YAAY;AACd;AACA;EACE,aAAa;EACb,kBAAkB;EAClB,eAAe;EACf,cAAc;AAChB","sourcesContent":[".notify_card {\n  border: 0.5px #d1d7dc solid;\n  width: 29%;\n  padding: 1%;\n  position: absolute;\n  right: 1%;\n  z-index: 1;\n  top: 5%;\n}\n.dropdown {\n  color: white;\n  padding: 9px;\n  font-size: 16px;\n  border: none;\n  height: 100%;\n}\n.dropdown:hover .dropdown_content {\n  display: block;\n}\n.notify_btn {\n  font-size: 16px;\n  font-weight: 600;\n  color: black;\n}\n.notify_container {\n  display: flex;\n}\n.notify {\n  font-size: 16px;\n  font-weight: 700;\n  color: black;\n  margin-right: 55%;\n}\n.instructor {\n  font-size: 14px;\n  font-weight: 700;\n  color: black;\n  margin-right: 55%;\n}\n.students {\n  font-size: 14px;\n  font-weight: 600;\n  color: rgb(97, 4, 113);\n}\n.settings {\n  font-size: 16px;\n  font-weight: 600;\n  color: rgb(97, 4, 113);\n}\n.notify_unorderlist {\n  list-style: none;\n}\n.motify_item {\n  font-size: 14px;\n  text-align: center;\n}\n.notify_list {\n  font-size: 14px;\n  line-height: 2rem;\n  font-weight: 400;\n  text-align: center;\n  color: black;\n}\n.notification_container {\n  display: flex;\n  padding: 0 5% 0 5%;\n  font-size: 12px;\n  margin-top: 5%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notify_card": `Notification_notify_card__LPHrH`,
	"dropdown": `Notification_dropdown__jiJHu`,
	"dropdown_content": `Notification_dropdown_content__7tYot`,
	"notify_btn": `Notification_notify_btn__RjGJO`,
	"notify_container": `Notification_notify_container__tlWMC`,
	"notify": `Notification_notify__wWByn`,
	"instructor": `Notification_instructor__ar0CQ`,
	"students": `Notification_students__jyFMM`,
	"settings": `Notification_settings__sKAY6`,
	"notify_unorderlist": `Notification_notify_unorderlist__IiFeB`,
	"motify_item": `Notification_motify_item__qU7Nq`,
	"notify_list": `Notification_notify_list__2aBag`,
	"notification_container": `Notification_notification_container__+Wcec`
};
export default ___CSS_LOADER_EXPORT___;
