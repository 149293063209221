

import React, { useState, useEffect, useMemo } from 'react';
import { MaterialReactTable } from 'material-react-table';
import Select from 'react-select';
import PdfViewer from './PdfViewer';
import { Button, CircularProgress, Modal, Box } from '@mui/material'; // Import CircularProgress for loading spinner
import Swal from 'sweetalert2';
import { useAuth } from '../../../Context/AuthContext';
import './PDFUpdate.css';

const ChapterEditWithPDFManagement = () => {
  const { token } = useAuth();
  const [ebookData, setEbookData] = useState([]);
  const [selectedSku, setSelectedSku] = useState('');
  const [selectedEbook, setSelectedEbook] = useState(null);
  const [chapterData, setChapterData] = useState([]);
  const [pdfData, setPdfData] = useState([]);
  const [answerPdfData, setAnswerPdfData] = useState([]);
  const [solutionPdfData, setSolutionPdfData] = useState([]);
  const [selectedPdfs, setSelectedPdfs] = useState([]);
  const [selectAllMainPdfs, setSelectAllMainPdfs] = useState(false);
  const [selectAllAnswerPdfs, setSelectAllAnswerPdfs] = useState(false);
  const [selectAllSolutionPdfs, setSelectAllSolutionPdfs] = useState(false);
  const [loading, setLoading] = useState(false); // Add loading state
  const [openModal, setOpenModal] = useState(false); // Modal state
  const [pdfToShow, setPdfToShow] = useState(null); // Selected PDF to show

  useEffect(() => {
    const fetchEbookData = async () => {
      setLoading(true); // Start loading
      try {
        const response = await fetch('https://auth.ssccglpinnacle.com/api/ebooks');
        if (response.ok) {
          const ebooks = await response.json();
          setEbookData(ebooks);
        } else {
          console.error('Failed to fetch eBook data');
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false); // Stop loading
      }
    };
    fetchEbookData();
  }, []);

  useEffect(() => {
    const fetchChapters = async () => {
      try {
        if (selectedEbook?._id) {
          setLoading(true); // Start loading when fetching chapters
          const response = await fetch(`https://auth.ssccglpinnacle.com/api/chapters-ebook-admin/${selectedEbook._id}`);
          if (response.ok) {
            const chaptersData = await response.json();
            const sortedChapters = chaptersData.sort((a, b) => a.sequence - b.sequence);
            setChapterData(sortedChapters);
            await fetchAllPDFsForChapters(sortedChapters); // Wait for PDFs to load
          } else {
            console.error('Failed to fetch chapters');
          }
          setLoading(false); // Stop loading after chapters and PDFs are fetched
        }
      } catch (error) {
        console.error(error);
        setLoading(false); // Stop loading if there is an error
      }
    };
    fetchChapters();
  }, [selectedEbook]);

  const fetchAllPDFsForChapters = async (chapters) => {
    try {
      const pdfPromises = chapters.map((chapter) =>
        fetch(`https://auth.ssccglpinnacle.com/api/pdfs-ebook/${chapter._id}`)
      );
      const answerPdfPromises = chapters.map((chapter) =>
        fetch(`https://auth.ssccglpinnacle.com/api/answer-ebook/${chapter._id}`)
      );
      const solutionPdfPromises = chapters.map((chapter) =>
        fetch(`https://auth.ssccglpinnacle.com/api/solution-ebook/${chapter._id}`)
      );

      const pdfResponses = await Promise.all(pdfPromises);
      const answerPdfResponses = await Promise.all(answerPdfPromises);
      const solutionPdfResponses = await Promise.all(solutionPdfPromises);

      const pdfDataArray = await Promise.all(pdfResponses.map((res) => res.json()));
      const answerPdfDataArray = await Promise.all(answerPdfResponses.map((res) => res.json()));
      const solutionPdfDataArray = await Promise.all(solutionPdfResponses.map((res) => res.json()));

      const pdfsWithChapters = pdfDataArray.map((pdfArray, index) => ({
        chapterId: chapters[index]._id,
        pdfs: pdfArray,
      }));

      const answerPdfsWithChapters = answerPdfDataArray.map((pdfArray, index) => ({
        chapterId: chapters[index]._id,
        pdfs: pdfArray,
      }));

      const solutionPdfsWithChapters = solutionPdfDataArray.map((pdfArray, index) => ({
        chapterId: chapters[index]._id,
        pdfs: pdfArray,
      }));

      setPdfData(pdfsWithChapters);
      setAnswerPdfData(answerPdfsWithChapters);
      setSolutionPdfData(solutionPdfsWithChapters);
    } catch (error) {
      console.error('Error fetching PDFs:', error);
    }
  };

  const handleSelectAllMainPdfs = (isChecked) => {
    setSelectAllMainPdfs(isChecked);
    
    // Check if pdfGroup.pdfs is an array before calling .map()
    const allMainPdfs = pdfData.flatMap((pdfGroup) => Array.isArray(pdfGroup.pdfs) ? pdfGroup.pdfs.map((pdf) => pdf._id) : []);
    
    if (isChecked) {
      setSelectedPdfs((prevSelected) => [...new Set([...prevSelected, ...allMainPdfs])]);
    } else {
      setSelectedPdfs((prevSelected) => prevSelected.filter((id) => !allMainPdfs.includes(id)));
    }
  };

  const handleSelectAllAnswerPdfs = (isChecked) => {
    setSelectAllAnswerPdfs(isChecked);
    
    const allAnswerPdfs = answerPdfData.flatMap((pdfGroup) => Array.isArray(pdfGroup.pdfs) ? pdfGroup.pdfs.map((pdf) => pdf._id) : []);
    
    if (isChecked) {
      setSelectedPdfs((prevSelected) => [...new Set([...prevSelected, ...allAnswerPdfs])]);
    } else {
      setSelectedPdfs((prevSelected) => prevSelected.filter((id) => !allAnswerPdfs.includes(id)));
    }
  };

  const handleSelectAllSolutionPdfs = (isChecked) => {
    setSelectAllSolutionPdfs(isChecked);
    
    const allSolutionPdfs = solutionPdfData.flatMap((pdfGroup) => Array.isArray(pdfGroup.pdfs) ? pdfGroup.pdfs.map((pdf) => pdf._id) : []);
    
    if (isChecked) {
      setSelectedPdfs((prevSelected) => [...new Set([...prevSelected, ...allSolutionPdfs])]);
    } else {
      setSelectedPdfs((prevSelected) => prevSelected.filter((id) => !allSolutionPdfs.includes(id)));
    }
  };

  const handlePdfSelect = (pdfId, pdfType) => {
    setSelectedPdfs((prevSelected) =>
      prevSelected.includes(pdfId)
        ? prevSelected.filter((id) => id !== pdfId)
        : [...prevSelected, pdfId]
    );

    if (pdfType === 'main') {
      const allMainPdfsSelected = pdfData.flatMap((pdfGroup) => Array.isArray(pdfGroup.pdfs) ? pdfGroup.pdfs.map((pdf) => pdf._id) : [])
        .every((id) => selectedPdfs.includes(id) || id === pdfId);
      setSelectAllMainPdfs(allMainPdfsSelected);
    }

    if (pdfType === 'answer') {
      const allAnswerPdfsSelected = answerPdfData.flatMap((pdfGroup) => Array.isArray(pdfGroup.pdfs) ? pdfGroup.pdfs.map((pdf) => pdf._id) : [])
        .every((id) => selectedPdfs.includes(id) || id === pdfId);
      setSelectAllAnswerPdfs(allAnswerPdfsSelected);
    }

    if (pdfType === 'solution') {
      const allSolutionPdfsSelected = solutionPdfData.flatMap((pdfGroup) => Array.isArray(pdfGroup.pdfs) ? pdfGroup.pdfs.map((pdf) => pdf._id) : [])
        .every((id) => selectedPdfs.includes(id) || id === pdfId);
      setSelectAllSolutionPdfs(allSolutionPdfsSelected);
    }
  };

  const handleUpdateSelected = async () => {
    if (selectedPdfs.length !== 1) {
      Swal.fire('Select One PDF', 'Please select exactly one PDF to update.', 'warning');
      return;
    }

    const selectedPdfId = selectedPdfs[0];
    let updateUrl = '';
    let pdfType = '';

    if (pdfData.some((group) => Array.isArray(group.pdfs) && group.pdfs.some((pdf) => pdf._id === selectedPdfId))) {
      updateUrl = 'https://auth.ssccglpinnacle.com/api/updatePdf-ebook';
      pdfType = 'main';
    } else if (answerPdfData.some((group) => Array.isArray(group.pdfs) && group.pdfs.some((pdf) => pdf._id === selectedPdfId))) {
      updateUrl = 'https://auth.ssccglpinnacle.com/api/updateAnswerPdf-ebook';
      pdfType = 'answer';
    } else if (solutionPdfData.some((group) => Array.isArray(group.pdfs) && group.pdfs.some((pdf) => pdf._id === selectedPdfId))) {
      updateUrl = 'https://auth.ssccglpinnacle.com/api/updateSolutionPdf-ebook';
      pdfType = 'solution';
    }

    if (!updateUrl) {
      Swal.fire('Error', 'Selected PDF not found for update.', 'error');
      return;
    }

    const { value: file } = await Swal.fire({
      title: 'Select new PDF file',
      input: 'file',
      inputAttributes: {
        accept: 'application/pdf',
        'aria-label': 'Upload your new PDF file',
      },
      showCancelButton: true,
    });

    if (!file) {
      Swal.fire('Cancelled', 'No file was selected.', 'info');
      return;
    }

    const formData = new FormData();
    formData.append('pdfId', selectedPdfId);
    formData.append('pdf', file);

    try {
      const response = await fetch(updateUrl, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (response.ok) {
        const result = await response.json();
        Swal.fire('Updated!', `Your ${pdfType} PDF has been updated.`, 'success');
        fetchAllPDFsForChapters(chapterData);
        setSelectedPdfs([]);
      } else {
        const errorData = await response.json();
        Swal.fire('Error', `Failed to update PDF: ${errorData.message}`, 'error');
      }
    } catch (error) {
      console.error('Error updating PDF:', error);
      Swal.fire('Error', 'There was an error updating the PDF.', 'error');
    }
  };

  const handleDeleteSelected = async () => {
    if (selectedPdfs.length === 0) {
      Swal.fire('No PDFs selected', 'Please select PDFs to delete.', 'warning');
      return;
    }

    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'You are about to delete the selected PDFs. This action cannot be undone.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete them!',
      cancelButtonText: 'Cancel',
    });

    if (result.isConfirmed) {
      try {
        const mainPdfIds = [];
        const answerPdfIds = [];
        const solutionPdfIds = [];

        selectedPdfs.forEach((pdfId) => {
          if (pdfData.some((group) => Array.isArray(group.pdfs) && group.pdfs.some((pdf) => pdf._id === pdfId))) {
            mainPdfIds.push(pdfId);
          }
          if (answerPdfData.some((group) => Array.isArray(group.pdfs) && group.pdfs.some((pdf) => pdf._id === pdfId))) {
            answerPdfIds.push(pdfId);
          }
          if (solutionPdfData.some((group) => Array.isArray(group.pdfs) && group.pdfs.some((pdf) => pdf._id === pdfId))) {
            solutionPdfIds.push(pdfId);
          }
        });

        if (mainPdfIds.length > 0) {
          await Promise.all(mainPdfIds.map(async (pdfId) => {
            const response = await fetch(`https://auth.ssccglpinnacle.com/api/deletePdf-ebook/${pdfId}`, {
              method: 'DELETE',
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
            });
          }));
        }

        if (answerPdfIds.length > 0) {
          const response = await fetch(`https://auth.ssccglpinnacle.com/api/deleteMultipleAnswerPdf-ebook`, {
            method: 'DELETE',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ pdfIds: answerPdfIds }),
          });
        }

        if (solutionPdfIds.length > 0) {
          const response = await fetch(`https://auth.ssccglpinnacle.com/api/deleteMultipleSolutionPdf-ebook`, {
            method: 'DELETE',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ pdfIds: solutionPdfIds }),
          });
        }

        Swal.fire('Deleted!', 'Your PDFs have been deleted.', 'success');
        setSelectedPdfs([]);
        setSelectAllMainPdfs(false);
        setSelectAllAnswerPdfs(false);
        setSelectAllSolutionPdfs(false);
        fetchAllPDFsForChapters(chapterData);

      } catch (error) {
        console.error("Error while deleting PDFs:", error);
        Swal.fire('Error', 'There was an error deleting the PDFs.', 'error');
      }
    }
  };

  const getPDFsForChapter = (chapterId, pdfType) => {
    if (pdfType === 'main') {
      const chapterPDFs = pdfData.find((pdfGroup) => pdfGroup.chapterId === chapterId);
      return chapterPDFs ? chapterPDFs.pdfs : [];
    } else if (pdfType === 'answer') {
      const chapterPDFs = answerPdfData.find((pdfGroup) => pdfGroup.chapterId === chapterId);
      return chapterPDFs ? chapterPDFs.pdfs : [];
    } else if (pdfType === 'solution') {
      const chapterPDFs = solutionPdfData.find((pdfGroup) => pdfGroup.chapterId === chapterId);
      return chapterPDFs ? chapterPDFs.pdfs : [];
    }
  };

  const handleOpenModal = (pdfKey) => {
    setPdfToShow([{ s3Key: pdfKey }]); // Send s3Key to PdfViewer
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
    setPdfToShow(null);
  };

  const columns = useMemo(
    () => [
      { accessorKey: 'title', header: 'Title' },
      { accessorKey: 'sequence', header: 'Chapter Sequence' },
      {
        id: 'mainPdf',
        header: (
          <div>
            <input
              type="checkbox"
              checked={selectAllMainPdfs}
              onChange={(e) => handleSelectAllMainPdfs(e.target.checked)}
            />
            Main PDFs
          </div>
        ),
        Cell: ({ row }) => {
          const pdfsForChapter = getPDFsForChapter(row.original._id, 'main');
          if (!Array.isArray(pdfsForChapter) || !pdfsForChapter.length) return <p>No PDFs available</p>;

          return (
            <div className="pdf-list-container">
              {pdfsForChapter.map((pdf) => (
                <div className="pdf-item" key={pdf._id}>
                  <input
                    type="checkbox"
                    checked={selectedPdfs.includes(pdf._id)}
                    onChange={() => handlePdfSelect(pdf._id, 'main')}
                    className="pdf-checkbox"
                  />
                  {/* <a href={`https://dzdx39zg243ni.cloudfront.net/${pdf.s3Key}`} target="_blank" rel="noopener noreferrer">
                    {pdf.s3Key}
                  </a> */}

<div className="pdf-link-ebook" onClick={() => handleOpenModal(pdf.s3Key)}>
                    {pdf.s3Key}
                  </div>
                </div>
              ))}
            </div>
          );
        },
      },
      {
        id: 'answerPdf',
        header: (
          <div>
            <input
              type="checkbox"
              checked={selectAllAnswerPdfs}
              onChange={(e) => handleSelectAllAnswerPdfs(e.target.checked)}
            />
            Answer PDFs
          </div>
        ),
        Cell: ({ row }) => {
          const pdfsForChapter = getPDFsForChapter(row.original._id, 'answer');
          if (!Array.isArray(pdfsForChapter) || !pdfsForChapter.length) return <p>No Answer PDFs available</p>;

          return (
            <div className="pdf-list-container">
              {pdfsForChapter.map((pdf) => (
                <div className="pdf-item" key={pdf._id}>
                  <input
                    type="checkbox"
                    checked={selectedPdfs.includes(pdf._id)}
                    onChange={() => handlePdfSelect(pdf._id, 'answer')}
                    className="pdf-checkbox"
                  />
                 
                  {/* <a href={`https://dzdx39zg243ni.cloudfront.net/${pdf.s3Key}`}>
                    {pdf.s3Key}
                  </a> */}
                   <div className="pdf-link-ebook" onClick={() => handleOpenModal(pdf.s3Key)}>
                    {pdf.s3Key}
                  </div>
                </div>
              ))}
            </div>
          );
        },
      },
      {
        id: 'solutionPdf',
        header: (
          <div>
            <input
              type="checkbox"
              checked={selectAllSolutionPdfs}
              onChange={(e) => handleSelectAllSolutionPdfs(e.target.checked)}
            />
            Solution PDFs
          </div>
        ),
        Cell: ({ row }) => {
          const pdfsForChapter = getPDFsForChapter(row.original._id, 'solution');
          if (!Array.isArray(pdfsForChapter) || !pdfsForChapter.length) return <p>No Solution PDFs available</p>;

          return (
            <div className="pdf-list-container">
              {pdfsForChapter.map((pdf) => (
                <div className="pdf-item" key={pdf._id}>
                  <input
                    type="checkbox"
                    checked={selectedPdfs.includes(pdf._id)}
                    onChange={() => handlePdfSelect(pdf._id, 'solution')}
                    className="pdf-checkbox"
                  />
                  {/* <a href={`https://dzdx39zg243ni.cloudfront.net/${pdf.s3Key}`} target="_blank" rel="noopener noreferrer">
                    {pdf.s3Key}
                  </a> */}
                   <div className="pdf-link-ebook" onClick={() => handleOpenModal(pdf.s3Key)}>
                    {pdf.s3Key}
                  </div>
                </div>
              ))}
            </div>
          );
        },
      },
    ],
    [pdfData, answerPdfData, solutionPdfData, selectedPdfs, selectAllMainPdfs, selectAllAnswerPdfs, selectAllSolutionPdfs]
  );

  const handleEbookChange = (option) => {
    setSelectedSku(option.value);
    const selectedEbook = ebookData.find((ebook) => ebook.sku === option.value);
    setSelectedEbook(selectedEbook);
    setPdfData([]);
    setAnswerPdfData([]);
    setSolutionPdfData([]);
    setSelectedPdfs([]);
    setSelectAllMainPdfs(false);
    setSelectAllAnswerPdfs(false);
    setSelectAllSolutionPdfs(false);
  };

  return (
    <>
    <div className="pdfupdate-wrapper">
      <h1 className="pdfupdate-heading">View and Manage Chapter PDFs</h1>
      <form className="pdfupdate-form">
        <label htmlFor="ebookId" className="pdfupdate-label">SKU:</label>
        {/* <Select
          id="sku"
          value={{ label: selectedSku, value: selectedSku }}
          onChange={handleEbookChange}
          options={ebookData.slice().reverse().map((ebook) => ({
            label: ebook.sku,
            value: ebook.sku,
          }))}
          placeholder="Select SKU"
          className="pdfupdate-input"
        /> */}
        <Select
  id="sku"
  value={selectedSku ? { label: selectedSku, value: selectedSku } : null}
  onChange={(option) => handleEbookChange(option)}
  options={ebookData.slice().reverse().map((ebook) => ({
    label: ebook.sku,
    value: ebook.sku,
  }))}
  placeholder="Select SKU"
  className="pdfupdate-input"
  isSearchable={true}  // Enable search functionality
/>

        {selectedEbook && (
          <div className="pdfupdate-info-card">
            <div className="pdfupdate-info-item">
              <strong>Title:</strong> {selectedEbook.title}
            </div>
            <div className="pdfupdate-info-item">
              <strong>Edition:</strong> {selectedEbook.edition}
            </div>
            <div className="pdfupdate-info-item">
              <strong>Medium:</strong> {selectedEbook.medium}
            </div>
          </div>
        )}
      </form>

      {loading ? ( // Show spinner when loading
        <div className="loading-spinner-container-for">
          <div className="loading-spinner-spiner"></div>
        </div>
      ) : (
        chapterData.length > 0 && (
          <>
            <div className="pdfupdate-action-buttons">
              <Button variant="contained" color="primary" onClick={handleUpdateSelected}>
                Update Selected PDFs
              </Button>
              <Button variant="contained" color="secondary" onClick={handleDeleteSelected}>
                Delete Selected PDFs
              </Button>
            </div>
            <MaterialReactTable
              columns={columns}
              data={chapterData}
              enableBottomToolbar={true} // Ensure bottom toolbar is enabled for pagination
              enablePagination={true}
              initialState={{ pagination: { pageSize: 200, pageIndex: 0 } }} // Sets initial page size to 200 and starts at page 0
              muiTablePaginationProps={{
                rowsPerPageOptions: [10, 50, 100, 200], // Allows users to switch between 10, 50, 100, and 200 rows per page
                labelRowsPerPage: 'Rows per page', // Customize label
                count: chapterData.length, // Ensure the total row count is passed
              }}
            />
          </>
        )
      )}
    </div>
    <Modal open={openModal} onClose={handleCloseModal}>
  <Box
    sx={{
      position: 'relative', // For positioning the close button
      width: '80%',
      height: '80%',
      margin: 'auto',
      marginTop: '5%',
      backgroundColor: 'white',
      padding: '20px',
      overflowY: 'auto', // Add vertical scrolling
      maxHeight: '100%', // Constrain height to allow scrolling
    }}
  >
    {/* Close button (X) */}
    <button
      onClick={handleCloseModal}
      style={{
        position: 'absolute',
        top: 10,
        right: 10,
        background: 'transparent',
        border: 'none',
        fontSize: '24px',
        cursor: 'pointer',
      }}
    >
      ✖
    </button>

    {pdfToShow && <PdfViewer pdfData={pdfToShow} />}
  </Box>
</Modal>


   </>
  );
};

export default ChapterEditWithPDFManagement;

