// ReviewModal.js
import React, { useEffect, useState, useContext } from "react";
import StarComponent from "./StarComponent";
import ReviewFormComponent from "./ReviewFormComponent";
import UserFormComponent from "./UserFormComponent";
import "./ReviewModal.css";
import { useAuth } from "../../../Context/AuthContext";
import { useLocation, useParams, Navigate, useNavigate } from "react-router-dom";

const ReviewModal = ({ purchasedEbookId, handleCloseModal }) => {
  const [step, setStep] = useState(1);
  const [rating, setRating] = useState(0);
  const [review, setReview] = useState("");
  const { isLoggedIn, user, logout } = useAuth();
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });

  const handleNext = () => {
    if (step === 1) {
      setTimeout(() => {
        setStep(2);
      }, 1000);
    } else {
      setStep(step + 1);
       // Call the handleCloseModal function
    }
  };

  const handleBack = () => {
    setStep(step - 1);
  };

  const handleSubmit = () => {
    console.log("Submitted Data:", { rating, review, userData });
  };

  return (
    <div className="modal-for-review">
      <div className="modal-for-review-content">
        {step === 1 && (
          <StarComponent setRating={setRating} handleNext={handleNext} purchasedEbookId={purchasedEbookId} />
        )}
        {step === 2 && (
          <ReviewFormComponent
            rating={rating}
            review={review}
            setReview={setReview}
            handleNext={handleNext}
            handleBack={handleBack}
            purchasedEbookId={purchasedEbookId}
          />
        )}
        {step === 3 && (
          <UserFormComponent
            userData={userData}
            setUserData={setUserData}
            handleBack={handleBack}
            handleSubmit={handleSubmit}
            purchasedEbookId={purchasedEbookId}
            handleCloseModal={handleCloseModal}
          />
        )}
      </div>
    </div>
  );
};

export default ReviewModal;
