// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Card_card__84Z9s {
  background: white;
  border-radius: square;
  width: 260px;
  display: inline-block;
  margin-left: 2rem;
  margin-top: 2.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/component/HomePage01/Header/Card/Card.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,qBAAqB;EACrB,YAAY;EACZ,qBAAqB;EACrB,iBAAiB;EACjB,kBAAkB;AACpB","sourcesContent":[".card {\n  background: white;\n  border-radius: square;\n  width: 260px;\n  display: inline-block;\n  margin-left: 2rem;\n  margin-top: 2.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `Card_card__84Z9s`
};
export default ___CSS_LOADER_EXPORT___;
