import React from "react";
import "./Sidebarquiz.css";

const Sidebar = ({ subjects, selectedSubject, onSubjectChange }) => {
  return (
    <div className="sidebar-for-quiz">
      <h2>Quizzes</h2>
      <ul>
        {subjects.map((subject) => (
          <li
            key={subject.id}
            className = {selectedSubject === subject.id ? "active" : ""} 
            onClick={() => onSubjectChange(subject.id)}
          >
            Quiz {subject.name}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Sidebar;
