import React, { useRef, useEffect, useState } from "react";
import styles from "./sscCategory.module.css";
import {
  AiFillLeftCircle,
  AiFillRightCircle,
  AiFillStar,
} from "react-icons/ai";
import gsap from "gsap";
import { useNavigate } from "react-router-dom";
import NavbarVC from "./NavBarVC";
import { TbPoint } from "react-icons/tb";
import { FaAnglesDown, FaAnglesUp } from "react-icons/fa6";

export default function RailwayCategory() {
  const navigate = useNavigate();
  const [selectedCategory, setSelectedCategory] = useState("RRB NTPC");
  const [course, setCourse] = useState([]);
  const scrollRef = useRef(null);
  const courseRef = useRef(null);

  useEffect(() => {
    fetch("https://auth.ssccglpinnacle.com/api/videoCourses/Railway")
      .then((response) => response.json())
      .then((data) => {
        setCourse(Array.isArray(data) ? data : []);
      })
      .catch((error) => console.error("Error fetching courses:", error));
  }, []);

  const scroll = (shift) => {
    const targetScroll = scrollRef.current.scrollLeft + shift;
    gsap.to(scrollRef.current, {
      scrollLeft: targetScroll,
      duration: 0.6,
      ease: "power2.inOut",
    });
  };
  const scrollToCourses = () => {
    courseRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const scrollToBottom = () => {
    window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
  };

  const categoryHandler = (category) => {
    setSelectedCategory(category);
  };

  return (
    <>
      <NavbarVC />
      <div className={styles["EM-header"]}>
        <div className={styles["EM-header-container01"]}>
          <img
            src="https://d3m4h509gttb94.cloudfront.net/Image/railwayLogo.png"
            alt="Railway Logo"
            className={styles["EM-image"]}
          />
        </div>
        <div className={styles["EM-header-container02"]}>
          <div className={styles["EM-header-heading"]}>Railway</div>
          <button
            onClick={scrollToCourses}
            className={styles["allCourses-scrollButton"]}
          >
            Show All Railway Courses
          </button>
          <button
            onClick={scrollToBottom}
            className={styles["allCourses-scrollButton"]}
          >
            <FaAnglesDown />
          </button>
          <div className={styles["EM-header-para"]}>
            The Railways exam is one of the best career opportunities for an
            aspirant looking for a government job. Railways Recruitment Board
            conducts the railway exams. The Indian Railways will be conducting
            recruitment exams for employment in various departments of Group A,
            B, C, D and other positions. Multiple notifications are released by
            the Indian Railways every year for vacancies. Interested candidates
            must choose their preferred field of career and then follow the
            notifications for that post. RRB will be releasing notifications for
            various regions and also the metro department.
          </div>
        </div>
      </div>

      <div className={styles["EM-body"]}>
        <div className={styles["EM-body-container1"]}>
          {[
            "RRB NTPC",
            "RRB JE",
            "RPF SI",
            "RPF Constable",
            "RRB Junior Stenographer",
            "RRB JE EE",
            "RRB JE CE",
            "RRB JE ME",
            "RRB Junior Translator",
          ].map((cat) => (
            <div key={cat} className={styles["EM-body-buttons"]}>
              <button
                className={styles["EM-header-indi-butBtn"]}
                onClick={() => categoryHandler(cat)}
              >
                {cat.toUpperCase()}
              </button>
            </div>
          ))}
        </div>
        <div className={styles["EM-body-container2"]}>
          {/* RRB NTPC */}

          {selectedCategory === "RRB NTPC" && (
            <div>
              <div className={styles["EM-header-para"]}>
                The RRB NTPC (Non-Technical Popular Categories) Exam is
                conducted by the Railway Recruitment Board (RRB) to recruit
                candidates for various non-technical posts in the Indian
                Railways. This examination offers a great opportunity for
                candidates with a 12th pass certificate or a graduate degree to
                secure a stable government job in the railways.
              </div>
              <div className={styles["EM-header-heading"]}>
                RRB NTPC Recruitment Process
              </div>
              <div className={styles["EM-header-para"]}>
                a) 1st Stage Computer-Based Test (CBT)
              </div>
              <div className={styles["EM-header-para"]}>
                b) 2nd Stage Computer-Based Test (CBT)
              </div>
              <div className={styles["EM-header-para"]}>
                c) Typing Skill Test/Computer-Based Aptitude Test (as
                applicable)
              </div>
              <div className={styles["EM-header-para"]}>
                d) Document Verification
              </div>
              <div className={styles["EM-header-para"]}>
                e) Medical Examination
              </div>
              <div className={styles["EM-header-heading"]}>
                Key Details of the RRB NTPC Exam
              </div>
              <div className={styles["EM-header-para"]}>
                Exam Name: RRB NTPC (Non-Technical Popular Categories)
                Examination, 2024
              </div>
              <div className={styles["EM-header-para"]}>
                Conducting Body: Railway Recruitment Boards (RRBs)
              </div>
              <div className={styles["EM-header-para"]}>
                Total Vacancies: 10,884 posts
              </div>
              <div className={styles["EM-header-heading"]}>Posts Offered</div>
              <div className={styles["EM-header-para"]}>
                Junior Clerk cum Typist, Accounts Clerk cum Typist, Trains
                Clerk, Commercial cum Ticket Clerk, Traffic Assistant, Goods
                Guard, Senior Commercial cum Ticket Clerk, Senior Clerk cum
                Typist, Junior Account Assistant cum Typist, Commercial
                Apprentice, Station Master, etc.
              </div>
              <div className={styles["EM-header-heading"]}>Important Dates</div>
              <div className={styles["EM-header-para"]}>
                Application Start Date: To Be Announced (TBA)
              </div>
              <div className={styles["EM-header-para"]}>
                Application End Date: TBA
              </div>
              <div className={styles["EM-header-para"]}>
                1st Stage CBT Exam Date: TBA
              </div>
              <div className={styles["EM-header-para"]}>
                2nd Stage CBT Exam Date: TBA
              </div>
              <div className={styles["EM-header-heading"]}>
                Eligibility Criteria
              </div>
              <div className={styles["EM-header-para"]}>
                Nationality: Must be a citizen of India.
              </div>
              <div className={styles["EM-header-para"]}>Age Limit:</div>
              <div className={styles["EM-header-para"]}>
                - For Undergraduate posts: 18 to 30 years
              </div>
              <div className={styles["EM-header-para"]}>
                - For Graduate posts: 18 to 33 years
              </div>
              <div className={styles["EM-header-para"]}>
                Educational Qualification:
              </div>
              <div className={styles["EM-header-para"]}>
                - For Undergraduate posts: Must have passed 12th grade from a
                recognized board.
              </div>
              <div className={styles["EM-header-para"]}>
                - For Graduate posts: Must have a graduation degree from a
                recognized university.
              </div>
              <div className={styles["EM-header-heading"]}>
                Application Process
              </div>
              <div className={styles["EM-header-para"]}>
                Interested candidates can apply online through the official RRB
                website by following these steps:
              </div>
              <div className={styles["EM-header-para"]}>
                1. Visit the official RRB website and select the desired
                regional RRB.
              </div>
              <div className={styles["EM-header-para"]}>
                2. Register with necessary details to get a registration number
                and password.
              </div>
              <div className={styles["EM-header-para"]}>
                3. Fill out the application form carefully.
              </div>
              <div className={styles["EM-header-para"]}>
                4. Upload required documents like photograph, signature, and
                certificates.
              </div>
              <div className={styles["EM-header-para"]}>
                5. Pay the application fee as per your category.
              </div>
              <div className={styles["EM-header-para"]}>
                6. Submit the application form and take a printout for future
                reference.
              </div>
              <div className={styles["EM-header-heading"]}>Application Fee</div>
              <div className={styles["EM-header-para"]}>
                General/UR: INR 500/- (with INR 400 refunded upon appearing in
                1st Stage CBT)
              </div>
              <div className={styles["EM-header-para"]}>
                SC/ST/Ex-servicemen/Female/PwD/Transgender/Economically
                Backward: INR 250/- (fully refundable upon appearing in 1st
                Stage CBT)
              </div>
              <div className={styles["EM-header-heading"]}>Exam Pattern</div>
              <div className={styles["EM-header-para"]}>CBT - 1:</div>
              <div className={styles["EM-header-para"]}>
                - Number of Questions: 100
              </div>
              <div className={styles["EM-header-para"]}>
                - Duration: 90 minutes
              </div>
              <div className={styles["EM-header-para"]}>CBT - 2:</div>
              <div className={styles["EM-header-para"]}>
                - Number of Questions: 120
              </div>
              <div className={styles["EM-header-para"]}>
                - Duration: 90 minutes
              </div>
              <div className={styles["EM-header-heading"]}>
                Salary Structure
              </div>
              <div className={styles["EM-header-para"]}>
                The salary for RRB NTPC posts varies by level:
              </div>
              <div className={styles["EM-header-para"]}>
                - Level 2 posts: INR 19,900 – INR 63,200
              </div>
              <div className={styles["EM-header-para"]}>
                - Level 3 posts: INR 21,700 – INR 69,100
              </div>
              <div className={styles["EM-header-para"]}>
                - Graduate level posts (Levels 4-6): INR 25,500 – INR 81,100
                (Level 4) to INR 44,900 – INR 1,42,400 (Level 6)
              </div>
              <div className={styles["EM-header-heading"]}>
                Why Apply for RRB NTPC?
              </div>
              <div className={styles["EM-header-para"]}>
                The RRB NTPC exam provides an excellent opportunity for
                candidates to secure a government job in the railways, offering
                job security, good salary, and various allowances and perks.
              </div>
              <div className={styles["EM-header-heading"]}>
                Preparation Tips
              </div>
              <div className={styles["EM-header-para"]}>
                - Understand the Exam Pattern and Syllabus: Familiarize yourself
                with the subjects and types of questions to be asked.
              </div>
              <div className={styles["EM-header-para"]}>
                - Practice Regularly: Solve previous years' question papers and
                take mock tests to improve speed and accuracy.
              </div>
              <div className={styles["EM-header-para"]}>
                - Focus on Time Management: Practice managing your time
                effectively during exams.
              </div>
            </div>
          )}
          {/* RRB JE */}
          {selectedCategory === "RRB JE" && (
            <div>
              <div>
                The RRB JE (Railway Recruitment Board Junior Engineer) Exam is
                conducted by the Railway Recruitment Board to recruit candidates
                for various engineering posts in the Indian Railways. This
                examination is a great opportunity for candidates with an
                engineering degree or diploma to secure a stable government job
                in the railway sector.
              </div>
              <div className={styles["EM-header-heading"]}>
                RRB JE Recruitment Process
              </div>
              <div className={styles["EM-header-para"]}>
                a) Computer-Based Test (CBT) 1
              </div>
              <div className={styles["EM-header-para"]}>
                b) Computer-Based Test (CBT) 2
              </div>
              <div className={styles["EM-header-para"]}>
                c) Document Verification
              </div>
              <div className={styles["EM-header-para"]}>
                d) Medical Examination
              </div>
              <div className={styles["EM-header-heading"]}>
                Key Details of the RRB JE Exam
              </div>
              <div className={styles["EM-header-para"]}>
                Exam Name: RRB Junior Engineer Examination, 2024
              </div>
              <div className={styles["EM-header-para"]}>
                Conducting Body: Railway Recruitment Board (RRB)
              </div>
              <div className={styles["EM-header-para"]}>
                Total Vacancies: 7,951 posts
              </div>
              <div className={styles["EM-header-para"]}>
                Posts Offered: Junior Engineer, Depot Material Superintendent,
                Chemical & Metallurgical Assistant, Chemical Supervisor
                (Research), and Metallurgical Supervisor (Research)
              </div>
              <div className={styles["EM-header-heading"]}>Important Dates</div>
              <div className={styles["EM-header-para"]}>
                Application Start Date: 30th July 2024
              </div>
              <div className={styles["EM-header-para"]}>
                Application End Date: 29th August 2024
              </div>
              <div className={styles["EM-header-para"]}>
                CBT 1 Exam Date: To be announced
              </div>
              <div className={styles["EM-header-para"]}>
                CBT 2 Exam Date: To be announced
              </div>
              <div className={styles["EM-header-heading"]}>
                Eligibility Criteria
              </div>
              <div className={styles["EM-header-para"]}>
                Nationality: Must be a citizen of India.
              </div>
              <div className={styles["EM-header-para"]}>
                Age Limit: 18 to 36 years
              </div>
              <div className={styles["EM-header-para"]}>
                Educational Qualification: Candidates must have a three-year
                diploma or B.E./B.Tech/B.Sc degree in a relevant engineering
                stream from a recognized institution.
              </div>
              <div className={styles["EM-header-heading"]}>
                Application Process
              </div>
              <div className={styles["EM-header-para"]}>
                Interested candidates can apply online through the official RRB
                website by following these steps:
              </div>
              <div className={styles["EM-header-para"]}>
                1. Visit the official RRB website and select the desired
                regional RRB.
              </div>
              <div className={styles["EM-header-para"]}>
                2. Create an account using a valid email ID and mobile number to
                receive OTPs for verification.
              </div>
              <div className={styles["EM-header-para"]}>
                3. Fill out the application form with personal and educational
                details.
              </div>
              <div className={styles["EM-header-para"]}>
                4. Select post preferences and upload required documents.
              </div>
              <div className={styles["EM-header-para"]}>
                5. Pay the application fee as per your category.
              </div>
              <div className={styles["EM-header-para"]}>
                6. Submit the application form and keep a printout for future
                reference.
              </div>
              <div className={styles["EM-header-heading"]}>Application Fee</div>
              <div className={styles["EM-header-para"]}>
                General/UR: INR 500 (with INR 400 refunded upon appearing in 1st
                Stage CBT)
              </div>
              <div className={styles["EM-header-para"]}>
                SC/ST/Ex-servicemen/Female/Transgender/Minorities/Economically
                Backward: INR 250 (fully refundable upon appearing in 1st Stage
                CBT)
              </div>
              <div className={styles["EM-header-heading"]}>Exam Pattern</div>
              <div className={styles["EM-header-para"]}>CBT 1:</div>
              <div className={styles["EM-header-para"]}>
                - Number of Questions: 100
              </div>
              <div className={styles["EM-header-para"]}>
                - Duration: 90 minutes
              </div>
              <div className={styles["EM-header-para"]}>CBT 2:</div>
              <div className={styles["EM-header-para"]}>
                - Number of Questions: 150
              </div>
              <div className={styles["EM-header-para"]}>
                - Duration: 120 minutes
              </div>
              <div className={styles["EM-header-heading"]}>
                Salary Structure
              </div>
              <div className={styles["EM-header-para"]}>
                The RRB JE Salary for the Junior Engineer (JE) position falls
                under the Level 6 pay scale, with a basic salary of INR 35,400.
                The overall gross salary ranges from INR 42,000 to INR 52,000
                per month, depending on location and other factors.
              </div>
              <div className={styles["EM-header-heading"]}>
                Why Apply for RRB JE?
              </div>
              <div className={styles["EM-header-para"]}>
                The RRB JE exam provides an excellent opportunity for
                engineering graduates to secure a government job in the
                railways, offering job security, good salary, and various
                allowances and perks.
              </div>
              <div className={styles["EM-header-heading"]}>
                Preparation Tips
              </div>
              <div className={styles["EM-header-para"]}>
                - Understand the Exam Pattern and Syllabus: Familiarize yourself
                with the subjects and types of questions to be asked.
              </div>
              <div className={styles["EM-header-para"]}>
                - Practice Regularly: Solve previous years' question papers and
                take mock tests to improve speed and accuracy.
              </div>
              <div className={styles["EM-header-para"]}>
                - Focus on Core Subjects: Strengthen your understanding of
                technical subjects relevant to the exam.
              </div>
              <div className={styles["EM-header-para"]}>
                - Revise Thoroughly: Regularly revise important topics to ensure
                clarity and retention of concepts.
              </div>
            </div>
          )}

          {/* RPF Constable */}
          {selectedCategory === "RPF Constable" && (
            <div>
              <div>
                The RPF Constable Exam is conducted by the Railway Protection
                Force (RPF) to recruit eligible candidates for the post of
                Constable in the Indian Railways. This is a fantastic
                opportunity for 10th-pass candidates to secure a government job
                with the Indian Railways, providing job stability and a good
                salary package.
              </div>
              <div className={styles["EM-header-heading"]}>
                RPF Constable Recruitment Process
              </div>
              <div className={styles["EM-header-para"]}>
                a) Computer-Based Test (CBT)
              </div>
              <div className={styles["EM-header-para"]}>
                b) Physical Efficiency Test (PET)
              </div>
              <div className={styles["EM-header-para"]}>
                c) Physical Measurement Test (PMT)
              </div>
              <div className={styles["EM-header-para"]}>
                d) Document Verification
              </div>
              <div className={styles["EM-header-para"]}>
                e) Medical Examination
              </div>
              <div className={styles["EM-header-heading"]}>
                Eligibility Criteria
              </div>
              <div className={styles["EM-header-para"]}>
                Nationality: Only Indian citizens are eligible to apply.
              </div>
              <div className={styles["EM-header-para"]}>
                Age Limit: 18 to 28 years, with age relaxation for reserved
                categories.
              </div>
              <div className={styles["EM-header-para"]}>
                Educational Qualification: Candidates must have passed the 10th
                standard from a recognized board.
              </div>
              <div className={styles["EM-header-heading"]}>
                Application Process
              </div>
              <div className={styles["EM-header-para"]}>
                Interested candidates can apply online through the official RPF
                website by following these steps:
              </div>
              <div className={styles["EM-header-para"]}>
                1. Visit the official RPF recruitment page.
              </div>
              <div className={styles["EM-header-para"]}>
                2. Click on the application link for RPF Constable Recruitment.
              </div>
              <div className={styles["EM-header-para"]}>
                3. Register by entering your details and receive a registration
                number and password.
              </div>
              <div className={styles["EM-header-para"]}>
                4. Log in using your credentials and fill out the application
                form carefully.
              </div>
              <div className={styles["EM-header-para"]}>
                5. Upload the necessary documents, including your photograph,
                signature, and educational certificates.
              </div>
              <div className={styles["EM-header-para"]}>
                6. Pay the application fee according to your category and submit
                the form.
              </div>
              <div className={styles["EM-header-heading"]}>Application Fee</div>
              <div className={styles["EM-header-para"]}>
                General (UR) Male: INR 500
              </div>
              <div className={styles["EM-header-para"]}>
                OBC/SC/ST Male: INR 250
              </div>
              <div className={styles["EM-header-para"]}>
                All Categories Female: INR 250
              </div>
              <div className={styles["EM-header-heading"]}>Exam Pattern</div>
              <div className={styles["EM-header-para"]}>
                The Computer-Based Test (CBT) will consist of questions from
                three sections: General Awareness, Arithmetic, and General
                Intelligence & Reasoning.
              </div>
              <div className={styles["EM-header-para"]}>
                - Total Questions: 120
              </div>
              <div className={styles["EM-header-para"]}>- Total Marks: 120</div>
              <div className={styles["EM-header-para"]}>
                - Duration: 90 minutes
              </div>
              <div className={styles["EM-header-para"]}>
                - Negative Marking: 1/3 marks will be deducted for each wrong
                answer.
              </div>
              <div className={styles["EM-header-heading"]}>
                Salary Structure
              </div>
              <div className={styles["EM-header-para"]}>
                The starting salary for an RPF Constable is INR 21,700 per month
                (Pay Level 3) as per the 7th Central Pay Commission, along with
                additional allowances such as DA, HRA, and more.
              </div>
              <div className={styles["EM-header-heading"]}>
                Why Apply for RPF Constable?
              </div>
              <div className={styles["EM-header-para"]}>
                The RPF Constable exam provides an excellent opportunity for
                10th-pass candidates to secure a government job with a good
                salary, job security, and various benefits. The role involves
                ensuring the safety and security of railway property and
                passengers, providing a dynamic and rewarding career path.
              </div>
              <div className={styles["EM-header-heading"]}>
                Preparation Tips
              </div>
              <div className={styles["EM-header-para"]}>
                - Understand the Exam Pattern and Syllabus: Familiarize yourself
                with the subjects and types of questions to be asked.
              </div>
              <div className={styles["EM-header-para"]}>
                - Practice Regularly: Solve previous years' question papers and
                take mock tests to improve speed and accuracy.
              </div>
              <div className={styles["EM-header-para"]}>
                - Focus on Core Subjects: Strengthen your understanding of
                General Awareness, Arithmetic, and Reasoning.
              </div>
              <div className={styles["EM-header-para"]}>
                - Revise Thoroughly: Regularly revise important topics to ensure
                clarity and retention of concepts.
              </div>
            </div>
          )}

          {selectedCategory === "sten" && (
            <div>SSC Stenographers content here</div>
          )}
          {selectedCategory === "cpo" && (
            <div>SSC CPO related content here</div>
          )}
          {selectedCategory === "je" && <div>SSC JE related content here</div>}
          {selectedCategory === "jht" && (
            <div>SSC JHT related content here</div>
          )}
          {selectedCategory === "RPF SI" && (
            <div>
              <div>
                The RPF SI (Railway Protection Force Sub Inspector) Exam is
                conducted by the Railway Recruitment Board (RRB) to recruit
                eligible candidates for the post of Sub Inspector in the Railway
                Protection Force. This is a great opportunity for graduates to
                secure a job in the Indian Railways, offering job stability and
                a good salary package.
              </div>
              <div className={styles["EM-header-heading"]}>
                RPF SI Recruitment Process
              </div>
              <div className={styles["EM-header-para"]}>
                a) Computer-Based Test (CBT)
              </div>
              <div className={styles["EM-header-para"]}>
                b) Physical Efficiency Test (PET)
              </div>
              <div className={styles["EM-header-para"]}>
                c) Physical Measurement Test (PMT)
              </div>
              <div className={styles["EM-header-para"]}>
                d) Document Verification
              </div>
              <div className={styles["EM-header-heading"]}>
                Key Details of the RPF SI Exam
              </div>
              <div className={styles["EM-header-para"]}>
                Exam Name: RPF Sub Inspector (SI) Recruitment 2024
              </div>
              <div className={styles["EM-header-para"]}>
                Conducting Body: Railway Recruitment Board (RRB)
              </div>
              <div className={styles["EM-header-para"]}>
                Total Vacancies: 452 posts
              </div>
              <div className={styles["EM-header-para"]}>
                Posts Offered: Sub Inspector (SI) in the Railway Protection
                Force
              </div>
              <div className={styles["EM-header-heading"]}>Important Dates</div>
              <div className={styles["EM-header-para"]}>
                Application Start Date: 15th April 2024
              </div>
              <div className={styles["EM-header-para"]}>
                Last Date to Apply: 14th May 2024
              </div>
              <div className={styles["EM-header-para"]}>
                CBT Exam Date: September 2024 (Tentative)
              </div>
              <div className={styles["EM-header-heading"]}>
                Eligibility Criteria
              </div>
              <div className={styles["EM-header-para"]}>
                Nationality: Only Indian citizens are eligible to apply.
              </div>
              <div className={styles["EM-header-para"]}>
                Age Limit: 20 to 28 years
              </div>
              <div className={styles["EM-header-para"]}>
                Educational Qualification: Candidates must have completed
                graduation from a recognized university/institute.
              </div>
              <div className={styles["EM-header-heading"]}>
                Application Process
              </div>
              <div className={styles["EM-header-para"]}>
                Interested candidates can apply online through the official RPF
                website by following these steps:
              </div>
              <div className={styles["EM-header-para"]}>
                1. Visit the RPF official website and navigate to the
                'Recruitments' page.
              </div>
              <div className={styles["EM-header-para"]}>
                2. Register yourself by entering the necessary details and
                create an account.
              </div>
              <div className={styles["EM-header-para"]}>
                3. Fill out the RPF SI application form with accurate
                information.
              </div>
              <div className={styles["EM-header-para"]}>
                4. Upload the required documents, including your photograph and
                signature.
              </div>
              <div className={styles["EM-header-para"]}>
                5. Pay the application fee and submit the form.
              </div>
              <div className={styles["EM-header-heading"]}>Application Fee</div>
              <div className={styles["EM-header-para"]}>
                General/Unreserved: INR 500 (with INR 400 refunded upon
                appearing in CBT)
              </div>
              <div className={styles["EM-header-para"]}>
                SC/ST/Ex-serviceman/Female/EBC: INR 250 (fully refundable upon
                appearing in CBT)
              </div>
              <div className={styles["EM-header-heading"]}>Exam Pattern</div>
              <div className={styles["EM-header-para"]}>
                The Computer-Based Test (CBT) will consist of questions from
                three sections: General Awareness, Arithmetic, and General
                Intelligence & Reasoning.
              </div>
              <div className={styles["EM-header-para"]}>
                - Total Questions: 120
              </div>
              <div className={styles["EM-header-para"]}>- Total Marks: 120</div>
              <div className={styles["EM-header-para"]}>
                - Duration: 90 minutes
              </div>
              <div className={styles["EM-header-para"]}>
                - Negative Marking: 1/3 marks will be deducted for each wrong
                answer.
              </div>
              <div className={styles["EM-header-heading"]}>
                Salary Structure
              </div>
              <div className={styles["EM-header-para"]}>
                The initial pay for an RPF Sub Inspector is INR 35,400 per
                month, along with other allowances as per the 7th Central Pay
                Commission.
              </div>
              <div className={styles["EM-header-heading"]}>
                Why Apply for RPF SI?
              </div>
              <div className={styles["EM-header-para"]}>
                The RPF SI exam provides an excellent opportunity for graduates
                to secure a government job with a good salary, job security, and
                various benefits. The role involves ensuring the safety and
                security of railway property and passengers, providing a dynamic
                and rewarding career path.
              </div>
              <div className={styles["EM-header-heading"]}>
                Preparation Tips
              </div>
              <div className={styles["EM-header-para"]}>
                - Understand the Exam Pattern and Syllabus: Familiarize yourself
                with the subjects and types of questions to be asked.
              </div>
              <div className={styles["EM-header-para"]}>
                - Practice Regularly: Solve previous years' question papers and
                take mock tests to improve speed and accuracy.
              </div>
              <div className={styles["EM-header-para"]}>
                - Focus on Core Subjects: Strengthen your understanding of
                General Awareness, Arithmetic, and Reasoning.
              </div>
              <div className={styles["EM-header-para"]}>
                - Revise Thoroughly: Regularly revise important topics to ensure
                clarity and retention of concepts.
              </div>
            </div>
          )}
        </div>
      </div>

      <div className={styles["mostPopular-div"]}>
        <button
          onClick={() => scroll(-270)}
          className={styles["sliderButton-left"]}
        >
          <AiFillLeftCircle size={30} />
        </button>
        <div className={styles["Course_Heading"]}>All Railway Courses</div>
        <div className={styles["scroll-container"]} ref={scrollRef}>
          <div className={styles["card-wrapper"]} ref={courseRef}>
            {course.map((course) => (
              <div
                key={course._id}
                className={styles["card"]}
                onClick={() => navigate(`/course/description/${course._id}`)}
              >
                <div className={styles["Image-section"]}>
                  <img
                    className={styles.imagecard}
                    src={course.hindiCoverImage}
                    alt="Hindi Cover"
                  />
                  <img
                    className={styles.imagecard}
                    src={course.englishCoverImage}
                    alt="English Cover"
                  />
                </div>
                <div className={styles["description-section"]}>
                  <div className={styles["title"]}>{course.courseTitle}</div>

                  {/* <div className={styles['teacher']}>Mr./Ms. {course.instructorName}</div> */}
                  <div className={styles["gst"]}>18% GST included</div>
                  <div className={styles["price-fprice-div"]}>
                    <div className={styles["price"]}>₹ {course.price}</div>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <div className={styles["mrp"]}>
                      <s>₹{course.mrp}</s>
                    </div>
                    <div className={styles["savings"]}>
                      Save{" "}
                      {Math.round(
                        ((course.mrp - course.price) / course.mrp) * 100
                      )}
                      %
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* <button onClick={() => scroll(270)} className={styles["sliderButton-right"]}>
          <AiFillRightCircle size={30} />
        </button> */}
      </div>
      <div className={styles["scroll-buttons"]}>
        <button
          onClick={scrollToTop}
          className={styles["allCourses-scrollButton"]}
        >
          <FaAnglesUp />
        </button>
      </div>
    </>
  );
}

// import React, { useRef, useEffect, useState } from 'react';
// import styles from './sscCategory.module.css'; // Ensure this points to your actual CSS file
// import { AiFillLeftCircle, AiFillRightCircle, AiFillStar } from 'react-icons/ai';
// import gsap from 'gsap';
// import { useNavigate } from 'react-router-dom';
// import NavbarVC from './NavBarVC';
// import {TbPoint} from "react-icons/tb";

// export default function RailwayCategory() {
//   const navigate = useNavigate();
//   const [selectedCategory, setSelectedCategory] = useState('RRB NTPC');
//   const [course, setCourse] = useState([]);
//   const scrollRef = useRef(null);

//   useEffect(() => {
//     fetch("https://auth.ssccglpinnacle.com/api/videoCourses/Railway")
//       .then(response => response.json())
//       .then(data => {
//         setCourse(Array.isArray(data) ? data : []);
//       })
//       .catch(error => console.error("Error fetching courses:", error));
//   }, []);

//   const scroll = (shift) => {
//     const targetScroll = scrollRef.current.scrollLeft + shift;
//     gsap.to(scrollRef.current, { scrollLeft: targetScroll, duration: 0.6, ease: 'power2.inOut' });
//   };

//   const categoryHandler = (category) => {
//     setSelectedCategory(category);
//   };

//   return (
//     <>
//       <NavbarVC />
//       <div className={styles["EM-header"]}>
//         <div className={styles["EM-header-container01"]}>
//           <img src='https://d3m4h509gttb94.cloudfront.net/Image/railwayLogo.png' alt="Railway Logo" className={styles["EM-image"]} />
//         </div>
//         <div className={styles["EM-header-container02"]}>
//           <div className={styles["EM-header-heading"]}>Railway</div>
//           <div className={styles["EM-header-para"]}>
//             The Railways exam is one of the best career opportunities for an
//             aspirant looking for a government job. Railways Recruitment Board
//             conducts the railway exams. The Indian Railways will be conducting
//             recruitment exams for employment in various departments of Group A,
//             B, C, D and other positions. Multiple notifications are released by
//             the Indian Railways every year for vacancies. Interested candidates
//             must choose their preferred field of career and then follow the
//             notifications for that post. RRB will be releasing notifications for
//             various regions and also the metro department.
//           </div>
//         </div>
//       </div>

//       <div className={styles["EM-body"]}>
//         <div className={styles["EM-body-container1"]}>
//           {['RRB NTPC', 'RRB JE', 'RPF SI', 'RPF Constable', 'RRB Junior Stenographer', 'RRB JE EE', 'RRB JE CE', 'RRB JE ME', 'RRB Junior Translator'].map(cat => (
//             <div key={cat} className={styles["EM-body-buttons"]}>
//               <button
//                 className={styles["EM-header-indi-butBtn"]}
//                 onClick={() => categoryHandler(cat)}
//               >
//                 {cat.toUpperCase()}
//               </button>
//             </div>
//           ))}
//         </div>
//         <div className={styles["EM-body-container2"]}>

//           {/* RRB NTPC */}

//           {selectedCategory === 'RRB NTPC' && <div>
//   <div>
//     The RRB NTPC (Non-Technical Popular Categories) Exam is conducted by the Railway Recruitment Board (RRB) to recruit candidates for various non-technical posts in the Indian Railways. This examination offers a great opportunity for candidates with a 12th pass certificate or a graduate degree to secure a stable government job in the railways.
//   </div>
//   <div className={styles["EM-header-heading"]}>
//     RRB NTPC Recruitment Process
//   </div>
//   <div className={styles["EM-header-para"]}>
//     a) 1st Stage Computer-Based Test (CBT)
//   </div>
//   <div className={styles["EM-header-para"]}>
//     b) 2nd Stage Computer-Based Test (CBT)
//   </div>
//   <div className={styles["EM-header-para"]}>
//     c) Typing Skill Test/Computer-Based Aptitude Test (as applicable)
//   </div>
//   <div className={styles["EM-header-para"]}>
//     d) Document Verification
//   </div>
//   <div className={styles["EM-header-para"]}>
//     e) Medical Examination
//   </div>
//   <div className={styles["EM-header-heading"]}>
//     Key Details of the RRB NTPC Exam
//   </div>
//   <div className={styles["EM-header-para"]}>
//     Exam Name: RRB NTPC (Non-Technical Popular Categories) Examination, 2024
//   </div>
//   <div className={styles["EM-header-para"]}>
//     Conducting Body: Railway Recruitment Boards (RRBs)
//   </div>
//   <div className={styles["EM-header-para"]}>
//     Total Vacancies: 10,884 posts
//   </div>
//   <div className={styles["EM-header-heading"]}>
//     Posts Offered
//   </div>
//   <div className={styles["EM-header-para"]}>
//     Junior Clerk cum Typist, Accounts Clerk cum Typist, Trains Clerk, Commercial cum Ticket Clerk, Traffic Assistant, Goods Guard, Senior Commercial cum Ticket Clerk, Senior Clerk cum Typist, Junior Account Assistant cum Typist, Commercial Apprentice, Station Master, etc.
//   </div>
//   <div className={styles["EM-header-heading"]}>
//     Important Dates
//   </div>
//   <div className={styles["EM-header-para"]}>
//     Application Start Date: To Be Announced (TBA)
//   </div>
//   <div className={styles["EM-header-para"]}>
//     Application End Date: TBA
//   </div>
//   <div className={styles["EM-header-para"]}>
//     1st Stage CBT Exam Date: TBA
//   </div>
//   <div className={styles["EM-header-para"]}>
//     2nd Stage CBT Exam Date: TBA
//   </div>
//   <div className={styles["EM-header-heading"]}>
//     Eligibility Criteria
//   </div>
//   <div className={styles["EM-header-para"]}>
//     Nationality: Must be a citizen of India.
//   </div>
//   <div className={styles["EM-header-para"]}>
//     Age Limit:
//   </div>
//   <div className={styles["EM-header-para"]}>
//     - For Undergraduate posts: 18 to 30 years
//   </div>
//   <div className={styles["EM-header-para"]}>
//     - For Graduate posts: 18 to 33 years
//   </div>
//   <div className={styles["EM-header-para"]}>
//     Educational Qualification:
//   </div>
//   <div className={styles["EM-header-para"]}>
//     - For Undergraduate posts: Must have passed 12th grade from a recognized board.
//   </div>
//   <div className={styles["EM-header-para"]}>
//     - For Graduate posts: Must have a graduation degree from a recognized university.
//   </div>
//   <div className={styles["EM-header-heading"]}>
//     Application Process
//   </div>
//   <div className={styles["EM-header-para"]}>
//     Interested candidates can apply online through the official RRB website by following these steps:
//   </div>
//   <div className={styles["EM-header-para"]}>
//     1. Visit the official RRB website and select the desired regional RRB.
//   </div>
//   <div className={styles["EM-header-para"]}>
//     2. Register with necessary details to get a registration number and password.
//   </div>
//   <div className={styles["EM-header-para"]}>
//     3. Fill out the application form carefully.
//   </div>
//   <div className={styles["EM-header-para"]}>
//     4. Upload required documents like photograph, signature, and certificates.
//   </div>
//   <div className={styles["EM-header-para"]}>
//     5. Pay the application fee as per your category.
//   </div>
//   <div className={styles["EM-header-para"]}>
//     6. Submit the application form and take a printout for future reference.
//   </div>
//   <div className={styles["EM-header-heading"]}>
//     Application Fee
//   </div>
//   <div className={styles["EM-header-para"]}>
//     General/UR: INR 500/- (with INR 400 refunded upon appearing in 1st Stage CBT)
//   </div>
//   <div className={styles["EM-header-para"]}>
//     SC/ST/Ex-servicemen/Female/PwD/Transgender/Economically Backward: INR 250/- (fully refundable upon appearing in 1st Stage CBT)
//   </div>
//   <div className={styles["EM-header-heading"]}>
//     Exam Pattern
//   </div>
//   <div className={styles["EM-header-para"]}>
//     CBT - 1:
//   </div>
//   <div className={styles["EM-header-para"]}>
//     - Number of Questions: 100
//   </div>
//   <div className={styles["EM-header-para"]}>
//     - Duration: 90 minutes
//   </div>
//   <div className={styles["EM-header-para"]}>
//     CBT - 2:
//   </div>
//   <div className={styles["EM-header-para"]}>
//     - Number of Questions: 120
//   </div>
//   <div className={styles["EM-header-para"]}>
//     - Duration: 90 minutes
//   </div>
//   <div className={styles["EM-header-heading"]}>
//     Salary Structure
//   </div>
//   <div className={styles["EM-header-para"]}>
//     The salary for RRB NTPC posts varies by level:
//   </div>
//   <div className={styles["EM-header-para"]}>
//     - Level 2 posts: INR 19,900 – INR 63,200
//   </div>
//   <div className={styles["EM-header-para"]}>
//     - Level 3 posts: INR 21,700 – INR 69,100
//   </div>
//   <div className={styles["EM-header-para"]}>
//     - Graduate level posts (Levels 4-6): INR 25,500 – INR 81,100 (Level 4) to INR 44,900 – INR 1,42,400 (Level 6)
//   </div>
//   <div className={styles["EM-header-heading"]}>
//     Why Apply for RRB NTPC?
//   </div>
//   <div className={styles["EM-header-para"]}>
//     The RRB NTPC exam provides an excellent opportunity for candidates to secure a government job in the railways, offering job security, good salary, and various allowances and perks.
//   </div>
//   <div className={styles["EM-header-heading"]}>
//     Preparation Tips
//   </div>
//   <div className={styles["EM-header-para"]}>
//     - Understand the Exam Pattern and Syllabus: Familiarize yourself with the subjects and types of questions to be asked.
//   </div>
//   <div className={styles["EM-header-para"]}>
//     - Practice Regularly: Solve previous years' question papers and take mock tests to improve speed and accuracy.
//   </div>
//   <div className={styles["EM-header-para"]}>
//     - Focus on Time Management: Practice managing your time effectively during exams.
//   </div>
// </div>

//           }
//           {/* RRB JE */}
//           {selectedCategory === 'RRB JE' &&
//           <div>
//           <div>
//             The RRB JE (Railway Recruitment Board Junior Engineer) Exam is conducted by the Railway Recruitment Board to recruit candidates for various engineering posts in the Indian Railways. This examination is a great opportunity for candidates with an engineering degree or diploma to secure a stable government job in the railway sector.
//           </div>
//           <div className={styles["EM-header-heading"]}>
//             RRB JE Recruitment Process
//           </div>
//           <div className={styles["EM-header-para"]}>
//             a) Computer-Based Test (CBT) 1
//           </div>
//           <div className={styles["EM-header-para"]}>
//             b) Computer-Based Test (CBT) 2
//           </div>
//           <div className={styles["EM-header-para"]}>
//             c) Document Verification
//           </div>
//           <div className={styles["EM-header-para"]}>
//             d) Medical Examination
//           </div>
//           <div className={styles["EM-header-heading"]}>
//             Key Details of the RRB JE Exam
//           </div>
//           <div className={styles["EM-header-para"]}>
//             Exam Name: RRB Junior Engineer Examination, 2024
//           </div>
//           <div className={styles["EM-header-para"]}>
//             Conducting Body: Railway Recruitment Board (RRB)
//           </div>
//           <div className={styles["EM-header-para"]}>
//             Total Vacancies: 7,951 posts
//           </div>
//           <div className={styles["EM-header-para"]}>
//             Posts Offered: Junior Engineer, Depot Material Superintendent, Chemical & Metallurgical Assistant, Chemical Supervisor (Research), and Metallurgical Supervisor (Research)
//           </div>
//           <div className={styles["EM-header-heading"]}>
//             Important Dates
//           </div>
//           <div className={styles["EM-header-para"]}>
//             Application Start Date: 30th July 2024
//           </div>
//           <div className={styles["EM-header-para"]}>
//             Application End Date: 29th August 2024
//           </div>
//           <div className={styles["EM-header-para"]}>
//             CBT 1 Exam Date: To be announced
//           </div>
//           <div className={styles["EM-header-para"]}>
//             CBT 2 Exam Date: To be announced
//           </div>
//           <div className={styles["EM-header-heading"]}>
//             Eligibility Criteria
//           </div>
//           <div className={styles["EM-header-para"]}>
//             Nationality: Must be a citizen of India.
//           </div>
//           <div className={styles["EM-header-para"]}>
//             Age Limit: 18 to 36 years
//           </div>
//           <div className={styles["EM-header-para"]}>
//             Educational Qualification: Candidates must have a three-year diploma or B.E./B.Tech/B.Sc degree in a relevant engineering stream from a recognized institution.
//           </div>
//           <div className={styles["EM-header-heading"]}>
//             Application Process
//           </div>
//           <div className={styles["EM-header-para"]}>
//             Interested candidates can apply online through the official RRB website by following these steps:
//           </div>
//           <div className={styles["EM-header-para"]}>
//             1. Visit the official RRB website and select the desired regional RRB.
//           </div>
//           <div className={styles["EM-header-para"]}>
//             2. Create an account using a valid email ID and mobile number to receive OTPs for verification.
//           </div>
//           <div className={styles["EM-header-para"]}>
//             3. Fill out the application form with personal and educational details.
//           </div>
//           <div className={styles["EM-header-para"]}>
//             4. Select post preferences and upload required documents.
//           </div>
//           <div className={styles["EM-header-para"]}>
//             5. Pay the application fee as per your category.
//           </div>
//           <div className={styles["EM-header-para"]}>
//             6. Submit the application form and keep a printout for future reference.
//           </div>
//           <div className={styles["EM-header-heading"]}>
//             Application Fee
//           </div>
//           <div className={styles["EM-header-para"]}>
//             General/UR: INR 500 (with INR 400 refunded upon appearing in 1st Stage CBT)
//           </div>
//           <div className={styles["EM-header-para"]}>
//             SC/ST/Ex-servicemen/Female/Transgender/Minorities/Economically Backward: INR 250 (fully refundable upon appearing in 1st Stage CBT)
//           </div>
//           <div className={styles["EM-header-heading"]}>
//             Exam Pattern
//           </div>
//           <div className={styles["EM-header-para"]}>
//             CBT 1:
//           </div>
//           <div className={styles["EM-header-para"]}>
//             - Number of Questions: 100
//           </div>
//           <div className={styles["EM-header-para"]}>
//             - Duration: 90 minutes
//           </div>
//           <div className={styles["EM-header-para"]}>
//             CBT 2:
//           </div>
//           <div className={styles["EM-header-para"]}>
//             - Number of Questions: 150
//           </div>
//           <div className={styles["EM-header-para"]}>
//             - Duration: 120 minutes
//           </div>
//           <div className={styles["EM-header-heading"]}>
//             Salary Structure
//           </div>
//           <div className={styles["EM-header-para"]}>
//             The RRB JE Salary for the Junior Engineer (JE) position falls under the Level 6 pay scale, with a basic salary of INR 35,400. The overall gross salary ranges from INR 42,000 to INR 52,000 per month, depending on location and other factors.
//           </div>
//           <div className={styles["EM-header-heading"]}>
//             Why Apply for RRB JE?
//           </div>
//           <div className={styles["EM-header-para"]}>
//             The RRB JE exam provides an excellent opportunity for engineering graduates to secure a government job in the railways, offering job security, good salary, and various allowances and perks.
//           </div>
//           <div className={styles["EM-header-heading"]}>
//             Preparation Tips
//           </div>
//           <div className={styles["EM-header-para"]}>
//             - Understand the Exam Pattern and Syllabus: Familiarize yourself with the subjects and types of questions to be asked.
//           </div>
//           <div className={styles["EM-header-para"]}>
//             - Practice Regularly: Solve previous years' question papers and take mock tests to improve speed and accuracy.
//           </div>
//           <div className={styles["EM-header-para"]}>
//             - Focus on Core Subjects: Strengthen your understanding of technical subjects relevant to the exam.
//           </div>
//           <div className={styles["EM-header-para"]}>
//             - Revise Thoroughly: Regularly revise important topics to ensure clarity and retention of concepts.
//           </div>
//         </div>

//           }

//          {/* RPF Constable */}
//           {selectedCategory === 'RPF Constable' &&

// <div>
//   <div>
//     The RPF Constable Exam is conducted by the Railway Protection Force (RPF) to recruit eligible candidates for the post of Constable in the Indian Railways. This is a fantastic opportunity for 10th-pass candidates to secure a government job with the Indian Railways, providing job stability and a good salary package.
//   </div>
//   <div className={styles["EM-header-heading"]}>
//     RPF Constable Recruitment Process
//   </div>
//   <div className={styles["EM-header-para"]}>
//     a) Computer-Based Test (CBT)
//   </div>
//   <div className={styles["EM-header-para"]}>
//     b) Physical Efficiency Test (PET)
//   </div>
//   <div className={styles["EM-header-para"]}>
//     c) Physical Measurement Test (PMT)
//   </div>
//   <div className={styles["EM-header-para"]}>
//     d) Document Verification
//   </div>
//   <div className={styles["EM-header-para"]}>
//     e) Medical Examination
//   </div>
//   <div className={styles["EM-header-heading"]}>
//     Eligibility Criteria
//   </div>
//   <div className={styles["EM-header-para"]}>
//     Nationality: Only Indian citizens are eligible to apply.
//   </div>
//   <div className={styles["EM-header-para"]}>
//     Age Limit: 18 to 28 years, with age relaxation for reserved categories.
//   </div>
//   <div className={styles["EM-header-para"]}>
//     Educational Qualification: Candidates must have passed the 10th standard from a recognized board.
//   </div>
//   <div className={styles["EM-header-heading"]}>
//     Application Process
//   </div>
//   <div className={styles["EM-header-para"]}>
//     Interested candidates can apply online through the official RPF website by following these steps:
//   </div>
//   <div className={styles["EM-header-para"]}>
//     1. Visit the official RPF recruitment page.
//   </div>
//   <div className={styles["EM-header-para"]}>
//     2. Click on the application link for RPF Constable Recruitment.
//   </div>
//   <div className={styles["EM-header-para"]}>
//     3. Register by entering your details and receive a registration number and password.
//   </div>
//   <div className={styles["EM-header-para"]}>
//     4. Log in using your credentials and fill out the application form carefully.
//   </div>
//   <div className={styles["EM-header-para"]}>
//     5. Upload the necessary documents, including your photograph, signature, and educational certificates.
//   </div>
//   <div className={styles["EM-header-para"]}>
//     6. Pay the application fee according to your category and submit the form.
//   </div>
//   <div className={styles["EM-header-heading"]}>
//     Application Fee
//   </div>
//   <div className={styles["EM-header-para"]}>
//     General (UR) Male: INR 500
//   </div>
//   <div className={styles["EM-header-para"]}>
//     OBC/SC/ST Male: INR 250
//   </div>
//   <div className={styles["EM-header-para"]}>
//     All Categories Female: INR 250
//   </div>
//   <div className={styles["EM-header-heading"]}>
//     Exam Pattern
//   </div>
//   <div className={styles["EM-header-para"]}>
//     The Computer-Based Test (CBT) will consist of questions from three sections: General Awareness, Arithmetic, and General Intelligence & Reasoning.
//   </div>
//   <div className={styles["EM-header-para"]}>
//     - Total Questions: 120
//   </div>
//   <div className={styles["EM-header-para"]}>
//     - Total Marks: 120
//   </div>
//   <div className={styles["EM-header-para"]}>
//     - Duration: 90 minutes
//   </div>
//   <div className={styles["EM-header-para"]}>
//     - Negative Marking: 1/3 marks will be deducted for each wrong answer.
//   </div>
//   <div className={styles["EM-header-heading"]}>
//     Salary Structure
//   </div>
//   <div className={styles["EM-header-para"]}>
//     The starting salary for an RPF Constable is INR 21,700 per month (Pay Level 3) as per the 7th Central Pay Commission, along with additional allowances such as DA, HRA, and more.
//   </div>
//   <div className={styles["EM-header-heading"]}>
//     Why Apply for RPF Constable?
//   </div>
//   <div className={styles["EM-header-para"]}>
//     The RPF Constable exam provides an excellent opportunity for 10th-pass candidates to secure a government job with a good salary, job security, and various benefits. The role involves ensuring the safety and security of railway property and passengers, providing a dynamic and rewarding career path.
//   </div>
//   <div className={styles["EM-header-heading"]}>
//     Preparation Tips
//   </div>
//   <div className={styles["EM-header-para"]}>
//     - Understand the Exam Pattern and Syllabus: Familiarize yourself with the subjects and types of questions to be asked.
//   </div>
//   <div className={styles["EM-header-para"]}>
//     - Practice Regularly: Solve previous years' question papers and take mock tests to improve speed and accuracy.
//   </div>
//   <div className={styles["EM-header-para"]}>
//     - Focus on Core Subjects: Strengthen your understanding of General Awareness, Arithmetic, and Reasoning.
//   </div>
//   <div className={styles["EM-header-para"]}>
//     - Revise Thoroughly: Regularly revise important topics to ensure clarity and retention of concepts.
//   </div>
// </div>

//           }

//           {selectedCategory === 'sten' && <div>SSC Stenographers content here</div>}
//           {selectedCategory === 'cpo' && <div>SSC CPO related content here</div>}
//           {selectedCategory === 'je' && <div>SSC JE related content here</div>}
//           {selectedCategory === 'jht' && <div>SSC JHT related content here</div>}
//           {selectedCategory === 'RPF SI' &&
//           <div>
//           <div>
//             The RPF SI (Railway Protection Force Sub Inspector) Exam is conducted by the Railway Recruitment Board (RRB) to recruit eligible candidates for the post of Sub Inspector in the Railway Protection Force. This is a great opportunity for graduates to secure a job in the Indian Railways, offering job stability and a good salary package.
//           </div>
//           <div className={styles["EM-header-heading"]}>
//             RPF SI Recruitment Process
//           </div>
//           <div className={styles["EM-header-para"]}>
//             a) Computer-Based Test (CBT)
//           </div>
//           <div className={styles["EM-header-para"]}>
//             b) Physical Efficiency Test (PET)
//           </div>
//           <div className={styles["EM-header-para"]}>
//             c) Physical Measurement Test (PMT)
//           </div>
//           <div className={styles["EM-header-para"]}>
//             d) Document Verification
//           </div>
//           <div className={styles["EM-header-heading"]}>
//             Key Details of the RPF SI Exam
//           </div>
//           <div className={styles["EM-header-para"]}>
//             Exam Name: RPF Sub Inspector (SI) Recruitment 2024
//           </div>
//           <div className={styles["EM-header-para"]}>
//             Conducting Body: Railway Recruitment Board (RRB)
//           </div>
//           <div className={styles["EM-header-para"]}>
//             Total Vacancies: 452 posts
//           </div>
//           <div className={styles["EM-header-para"]}>
//             Posts Offered: Sub Inspector (SI) in the Railway Protection Force
//           </div>
//           <div className={styles["EM-header-heading"]}>
//             Important Dates
//           </div>
//           <div className={styles["EM-header-para"]}>
//             Application Start Date: 15th April 2024
//           </div>
//           <div className={styles["EM-header-para"]}>
//             Last Date to Apply: 14th May 2024
//           </div>
//           <div className={styles["EM-header-para"]}>
//             CBT Exam Date: September 2024 (Tentative)
//           </div>
//           <div className={styles["EM-header-heading"]}>
//             Eligibility Criteria
//           </div>
//           <div className={styles["EM-header-para"]}>
//             Nationality: Only Indian citizens are eligible to apply.
//           </div>
//           <div className={styles["EM-header-para"]}>
//             Age Limit: 20 to 28 years
//           </div>
//           <div className={styles["EM-header-para"]}>
//             Educational Qualification: Candidates must have completed graduation from a recognized university/institute.
//           </div>
//           <div className={styles["EM-header-heading"]}>
//             Application Process
//           </div>
//           <div className={styles["EM-header-para"]}>
//             Interested candidates can apply online through the official RPF website by following these steps:
//           </div>
//           <div className={styles["EM-header-para"]}>
//             1. Visit the RPF official website and navigate to the 'Recruitments' page.
//           </div>
//           <div className={styles["EM-header-para"]}>
//             2. Register yourself by entering the necessary details and create an account.
//           </div>
//           <div className={styles["EM-header-para"]}>
//             3. Fill out the RPF SI application form with accurate information.
//           </div>
//           <div className={styles["EM-header-para"]}>
//             4. Upload the required documents, including your photograph and signature.
//           </div>
//           <div className={styles["EM-header-para"]}>
//             5. Pay the application fee and submit the form.
//           </div>
//           <div className={styles["EM-header-heading"]}>
//             Application Fee
//           </div>
//           <div className={styles["EM-header-para"]}>
//             General/Unreserved: INR 500 (with INR 400 refunded upon appearing in CBT)
//           </div>
//           <div className={styles["EM-header-para"]}>
//             SC/ST/Ex-serviceman/Female/EBC: INR 250 (fully refundable upon appearing in CBT)
//           </div>
//           <div className={styles["EM-header-heading"]}>
//             Exam Pattern
//           </div>
//           <div className={styles["EM-header-para"]}>
//             The Computer-Based Test (CBT) will consist of questions from three sections: General Awareness, Arithmetic, and General Intelligence & Reasoning.
//           </div>
//           <div className={styles["EM-header-para"]}>
//             - Total Questions: 120
//           </div>
//           <div className={styles["EM-header-para"]}>
//             - Total Marks: 120
//           </div>
//           <div className={styles["EM-header-para"]}>
//             - Duration: 90 minutes
//           </div>
//           <div className={styles["EM-header-para"]}>
//             - Negative Marking: 1/3 marks will be deducted for each wrong answer.
//           </div>
//           <div className={styles["EM-header-heading"]}>
//             Salary Structure
//           </div>
//           <div className={styles["EM-header-para"]}>
//             The initial pay for an RPF Sub Inspector is INR 35,400 per month, along with other allowances as per the 7th Central Pay Commission.
//           </div>
//           <div className={styles["EM-header-heading"]}>
//             Why Apply for RPF SI?
//           </div>
//           <div className={styles["EM-header-para"]}>
//             The RPF SI exam provides an excellent opportunity for graduates to secure a government job with a good salary, job security, and various benefits. The role involves ensuring the safety and security of railway property and passengers, providing a dynamic and rewarding career path.
//           </div>
//           <div className={styles["EM-header-heading"]}>
//             Preparation Tips
//           </div>
//           <div className={styles["EM-header-para"]}>
//             - Understand the Exam Pattern and Syllabus: Familiarize yourself with the subjects and types of questions to be asked.
//           </div>
//           <div className={styles["EM-header-para"]}>
//             - Practice Regularly: Solve previous years' question papers and take mock tests to improve speed and accuracy.
//           </div>
//           <div className={styles["EM-header-para"]}>
//             - Focus on Core Subjects: Strengthen your understanding of General Awareness, Arithmetic, and Reasoning.
//           </div>
//           <div className={styles["EM-header-para"]}>
//             - Revise Thoroughly: Regularly revise important topics to ensure clarity and retention of concepts.
//           </div>
//         </div>

//           }
//         </div>
//       </div>

//       <div className={styles["mostPopular-div"]}>
//         <button onClick={() => scroll(-270)} className={styles["sliderButton-left"]}>
//           <AiFillLeftCircle size={30} />
//         </button>
//         <div className={styles["Course_Heading"]}>Courses to get you started</div>
//         <div className={styles["scroll-container"]} ref={scrollRef}>
//           <div className={styles["card-wrapper"]}>
//             {course.map(data => (
//               <div key={data.id} className={styles["card"]} onClick={() => navigate("/course/description/" + data._id)}>
//                 <div className={styles["Upcomming-course-fullPage"]}>
//                   <div className={styles["Image-section"]}>
//                     <img className={styles.imagecard}
//                       src={data.hindiCoverImage}
//                       alt=""
//                       width="90"
//                       height="90"
//                     />
//                     <img className={styles.imagecard}
//                       src={data.englishCoverImage}
//                       alt=""
//                       width="90"
//                       height="90"
//                     />
//                   </div>
//                   <div className={styles["description-section"]}>
//                     <div className={styles["title"]}>{data.courseTitle}</div>
//                     <div className={styles["teacher"]}>Mr./Ms. {data.instructorName}</div>
//                     <div className={styles["try-for-free"]}>
//                       <div className={styles["Rating"]}>
//                         {/* <div className={styles["Star-Rating"]}>
//                           {data.rating}
//                           <div className={styles["For-Star-div"]}>
//                             <AiFillStar
//                               className={styles["Star-Rating-1"]}
//                               color="white"
//                               fontSize="1em"
//                             />
//                           </div>
//                         </div> */}
//                         {/* <div className={styles["Total-Rating"]}>(128)</div> */}
//                       </div>
//                     </div>
//                     <div className={styles["price-fprice-div"]}>
//                       &#8377;{data.price}&nbsp;&nbsp;&nbsp;&nbsp;<s>&#8377;{data.mrp}</s>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             ))}
//           </div>
//         </div>
//         <button onClick={() => scroll(270)} className={styles["sliderButton-right"]}>
//           <AiFillRightCircle size={30} />
//         </button>
//       </div>
//     </>
//   );
// }
