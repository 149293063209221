// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* StarComponent.css */
.star-container {
  font-size: 24px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
.star-review {
    margin: 20px;
    transform-origin: center;
    transform: scale(0.8);
    animation: openStar 0.21s ease forwards;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20%;
}

/* Keyframes for the opening animation */
@keyframes openStar {
  from {
    transform: scale(0.8); /* Start from 80% size */
  }
  to {
    transform: scale(1); /* End at 100% size */
  }
}

.star-container span {
  margin: 0 5px;
}

.star-container span:hover {
  color: #f0a500; /* Change color on hover */
}
.Rate-this-item{
  text-align: center;
}
.dislike-like{
width: 42%;
    /* border: solid; */
    display: flex;
    font-size: 12px;
    justify-content: space-between;
    color: black;
    margin-top: 4px;
}`, "",{"version":3,"sources":["webpack://./src/Ebook/Info/WriteReview/StarComponent.css"],"names":[],"mappings":"AAAA,sBAAsB;AACtB;EACE,eAAe;EACf,eAAe;EACf,aAAa;EACb,uBAAuB;EACvB,gBAAgB;AAClB;AACA;IACI,YAAY;IACZ,wBAAwB;IACxB,qBAAqB;IACrB,uCAAuC;IACvC,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,eAAe;AACnB;;AAEA,wCAAwC;AACxC;EACE;IACE,qBAAqB,EAAE,wBAAwB;EACjD;EACA;IACE,mBAAmB,EAAE,qBAAqB;EAC5C;AACF;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,cAAc,EAAE,0BAA0B;AAC5C;AACA;EACE,kBAAkB;AACpB;AACA;AACA,UAAU;IACN,mBAAmB;IACnB,aAAa;IACb,eAAe;IACf,8BAA8B;IAC9B,YAAY;IACZ,eAAe;AACnB","sourcesContent":["/* StarComponent.css */\n.star-container {\n  font-size: 24px;\n  cursor: pointer;\n  display: flex;\n  justify-content: center;\n  margin-top: 40px;\n}\n.star-review {\n    margin: 20px;\n    transform-origin: center;\n    transform: scale(0.8);\n    animation: openStar 0.21s ease forwards;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    margin-top: 20%;\n}\n\n/* Keyframes for the opening animation */\n@keyframes openStar {\n  from {\n    transform: scale(0.8); /* Start from 80% size */\n  }\n  to {\n    transform: scale(1); /* End at 100% size */\n  }\n}\n\n.star-container span {\n  margin: 0 5px;\n}\n\n.star-container span:hover {\n  color: #f0a500; /* Change color on hover */\n}\n.Rate-this-item{\n  text-align: center;\n}\n.dislike-like{\nwidth: 42%;\n    /* border: solid; */\n    display: flex;\n    font-size: 12px;\n    justify-content: space-between;\n    color: black;\n    margin-top: 4px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
