// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Offer_timer-fullpage__gTLo8{
    display: flex;
    flex-wrap: wrap;
    position: relative;
    
}

.Offer_timer__g0amj {
    
    display: flex;
    width: 100%;
    height: 3.5rem;
    font-weight: 400;
    font-size: 16px;
    background: #ffff00;
    color: #1c1d1f;
    align-items: center;
    
}

.Offer_timer_content__ZAdnN {
    
    margin: 0.5%;
    margin-left: 16%;
    font-weight: 600;
}

.Offer_timer_time__f4oaZ {
    display: flex;
    margin-top: 0.5%;
    font-weight: 700;
    color:#3300FF;
}

.Offer_cross_button__On3Rd {
    /* width: 100%;
    top: 15%;
    position: absolute;
    right: 25px; */
    background: transparent;
    border: 0;

}

.Offer_cross_button_div__lp\\+Ey {
    width: 4%;
    position: absolute;
    right:1rem;
    top:0.5rem;

}
.Offer_semicolonoffer__krZWJ {
    padding-bottom: 5px;

    color: rgb(57, 67, 212);
    font-weight: 400;
    padding-left: 5px;
      padding-right:5px;
}


@media only screen and (min-width: 1240px) and (max-width: 1365px) {
.Offer_timer__g0amj {
    height: 2.8rem;
    font-size: 15px;

}
.Offer_headerimage-wishlist__wOKnN {
    width: 11%; 
}


}`, "",{"version":3,"sources":["webpack://./src/BuyNowBook/Offer.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,kBAAkB;;AAEtB;;AAEA;;IAEI,aAAa;IACb,WAAW;IACX,cAAc;IACd,gBAAgB;IAChB,eAAe;IACf,mBAAmB;IACnB,cAAc;IACd,mBAAmB;;AAEvB;;AAEA;;IAEI,YAAY;IACZ,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,gBAAgB;IAChB,gBAAgB;IAChB,aAAa;AACjB;;AAEA;IACI;;;kBAGc;IACd,uBAAuB;IACvB,SAAS;;AAEb;;AAEA;IACI,SAAS;IACT,kBAAkB;IAClB,UAAU;IACV,UAAU;;AAEd;AACA;IACI,mBAAmB;;IAEnB,uBAAuB;IACvB,gBAAgB;IAChB,iBAAiB;MACf,iBAAiB;AACvB;;;AAGA;AACA;IACI,cAAc;IACd,eAAe;;AAEnB;AACA;IACI,UAAU;AACd;;;AAGA","sourcesContent":[".timer-fullpage{\n    display: flex;\n    flex-wrap: wrap;\n    position: relative;\n    \n}\n\n.timer {\n    \n    display: flex;\n    width: 100%;\n    height: 3.5rem;\n    font-weight: 400;\n    font-size: 16px;\n    background: #ffff00;\n    color: #1c1d1f;\n    align-items: center;\n    \n}\n\n.timer_content {\n    \n    margin: 0.5%;\n    margin-left: 16%;\n    font-weight: 600;\n}\n\n.timer_time {\n    display: flex;\n    margin-top: 0.5%;\n    font-weight: 700;\n    color:#3300FF;\n}\n\n.cross_button {\n    /* width: 100%;\n    top: 15%;\n    position: absolute;\n    right: 25px; */\n    background: transparent;\n    border: 0;\n\n}\n\n.cross_button_div {\n    width: 4%;\n    position: absolute;\n    right:1rem;\n    top:0.5rem;\n\n}\n.semicolonoffer {\n    padding-bottom: 5px;\n\n    color: rgb(57, 67, 212);\n    font-weight: 400;\n    padding-left: 5px;\n      padding-right:5px;\n}\n\n\n@media only screen and (min-width: 1240px) and (max-width: 1365px) {\n.timer {\n    height: 2.8rem;\n    font-size: 15px;\n\n}\n.headerimage-wishlist {\n    width: 11%; \n}\n\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"timer-fullpage": `Offer_timer-fullpage__gTLo8`,
	"timer": `Offer_timer__g0amj`,
	"timer_content": `Offer_timer_content__ZAdnN`,
	"timer_time": `Offer_timer_time__f4oaZ`,
	"cross_button": `Offer_cross_button__On3Rd`,
	"cross_button_div": `Offer_cross_button_div__lp+Ey`,
	"semicolonoffer": `Offer_semicolonoffer__krZWJ`,
	"headerimage-wishlist": `Offer_headerimage-wishlist__wOKnN`
};
export default ___CSS_LOADER_EXPORT___;
