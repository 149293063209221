import React from "react";
import AddChapterForm from "../VideoCourse_AdminPanel/AddChapterForm";
import AddTopicForm from "../VideoCourse_AdminPanel/AddTopicForm";
import styles from "./AddCourseChapters.module.css";

export default function AddCourseChapters() {
  return (
    <>
      <h2 className={styles.heading}>Add Course Content</h2>
      <div className={styles.container}>
        <div className={styles.addChapter}>
          <AddChapterForm />
        </div>
        <div className={styles.addTopic}>
          <AddTopicForm />
        </div>
      </div>
    </>
  );
}
