import React, { createContext, useContext, useState } from 'react';

const CartContext = createContext();

export function useCart() {
  return useContext(CartContext);
}

export function CartProvider({ children }) {
  // Initialize cartCount state
  const [cartCount, setCartCount] = useState(0);

  // Function to update cartCount
  const updateCartCount = (newCount) => {
    setCartCount(newCount);
  };

  const cartValue = {
    cartCount,
    updateCartCount, // Expose the updateCartCount function to the context
  };

  return <CartContext.Provider value={cartValue}>{children}</CartContext.Provider>;
}
