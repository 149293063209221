import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "./Row3intro.css";
import { FaRegThumbsUp } from "react-icons/fa";
import { MdArrowForwardIos } from "react-icons/md";
import Swal from "sweetalert2";
import { useAuth } from "../Context/AuthContext";
import BillingForm from "../PagesBook/DigitalContentBody/BillingForm";


function Row3intro({
  currentOriginalPrice,
  currentDiscountedPrice,
  selectedCardData,
}) {
  let rating = 5;
  let totalStars = 5;
  const [StarData, setStarData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalReviews, setTotalReviews] = useState(0);
  const { cardId } = useParams();
  const [billingInfo, setBillingInfo] = useState(null);
  const [cardData, setCardData] = useState([]);
  const [cardDatanew, setCardDatanew] = useState([]);
  const [showBillingForm, setShowBillingForm] = useState(false);
  const { isLoggedIn, user } = useAuth();
  const navigate = useNavigate();
 

  useEffect(() => {
    const fetchReviews = async (cardId) => {
      try {
        const response = await fetch(
          `https://auth.ssccglpinnacle.com/api/reviews-book/${cardId}`
        );
        if (!response.ok) {
          throw new Error(
            `Failed to fetch reviews. Status: ${response.status}`
          );
        }
        const data = await response.json();
        setStarData(data.ratings);

        const reviewsLength = data.ratings ? data.ratings.length : 0;
        setTotalReviews(reviewsLength);
      } catch (error) {
        console.error("Error fetching reviews:", error);
      }
    };

    fetchReviews(cardId);
  }, [cardId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const ebookResponse = await fetch("https://auth.ssccglpinnacle.com/api/booksforactive?active=true");
        if (!ebookResponse.ok) {
          throw new Error(`Failed to fetch ebooks. Status: ${ebookResponse.status}`);
        }

        const ebookData = await ebookResponse.json();
        setCardData(ebookData);

        const filteredCardData = ebookData.filter(item => item._id === cardId);
        setCardDatanew(filteredCardData)

        // Set the first image as the default selected image
        
    

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [cardId])

  const handleBuyNow = async (bookId) => {
    if (!isLoggedIn) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please log in to make a purchase.",
      });
      return;
    }

    try {
      const response = await fetch(`https://auth.ssccglpinnacle.com/api/bookBilling/${user._id}`);
      if (response.ok) {
        const data = await response.json();
        if (data) {
          // Billing information exists, navigate to VerifyAddress component
          navigate(`/verify-address/${bookId}`);
        } else {
          // Billing information does not exist, show the billing form modal
          setShowBillingForm(true);
          document.body.style.overflow = "hidden"; // Stop background scrolling
        }
      } else {
        // Handle case where fetching billing information fails
        console.error('Failed to fetch billing information');
        setShowBillingForm(true);
        document.body.style.overflow = "hidden"; // Stop background scrolling
      }
    } catch (error) {
      console.error('Error fetching billing information:', error);
      setShowBillingForm(true);
      document.body.style.overflow = "hidden"; // Stop background scrolling
    }
  };

  const calculateStarClass = (starIndex) => {
    if (starIndex + 1 <= rating) {
      return "filled";
    } else if (starIndex < rating) {
      return "partially-filled";
    } else {
      return "";
    }
  };

  const closeBillingForm = () => {
    setShowBillingForm(false);
    document.body.style.overflow = ""; // Enable background scrolling
  };
  
  // console.log(StarData)

  return (
    <>
      <div className="for-intro-row3-image-introproduct">
      <h2 className="enrolled">
      Explore Pinnacle's Diverse Learning Platforms
    </h2>

        <div className="valid-rating-grid">
          <div className="valid-rating-grid-left">
            {StarData.filter((review) => review.starRate === 5).slice(0, 2).map((review, index) => (
              <div key={index} className="review-box-for-intro">
                <div className="author-name-and-stars-rating">
                  <div className="star-rating-intro">
                    {[...Array(totalStars)].map((_, starIndex) => (
                      <span
                        key={starIndex}
                        className={`abc ${calculateStarClass(starIndex)}`}
                      >
                        ★
                      </span>
                    ))}
                  </div>
                  <p className="author-name-rating">
                    Author's name: {review.firstName} {review.lastName}
                  </p>
                  <p>{`"${review.writtenReview}"`}</p>
                </div>
              </div>
            ))}
          </div>
          <div className="valid-rating-grid-left-2">
            <div className="the-course-you-want">
              <h3 className="want-courses">
              Benefits of Using Latest Pinnacle Books' for 2024 Exam:
              </h3>
              <div className="want-courses-para">
                <MdArrowForwardIos />
                <p>Aligned with the Latest Exam Pattern</p>
              </div>
              <div className="want-courses-para">
                <MdArrowForwardIos />
                <p>Comprehensive Coverage</p>
              </div>
              <div className="want-courses-para">
                <MdArrowForwardIos />
                <p>Well-Organized Content</p>
              </div>
              <div className="want-courses-para">
                <MdArrowForwardIos />
                <p>
                Inclusion of Previous Years’ Papers
                </p>
              </div>
              <div className="want-courses-para">
                <MdArrowForwardIos />
                <p>
                Detailed Solutions and Explanations
                </p>
              </div>
              <div className="want-courses-para">
                <MdArrowForwardIos />
                <p>
                Tips and Tricks for Exam Strategy
                </p>
              </div>
              <div className="want-courses-para">
                <MdArrowForwardIos />
                <p>
                Reputation for Quality
                </p>
              </div>
            </div>

            <div className="the-course-you-want">
              <div className="course-title-intro-4">
                <h3>
                  {selectedCardData.length > 0 && selectedCardData[0].title}{" "}
                  {/* {selectedCardData.length > 0 && selectedCardData[0].edition}{" "}
                  edition{" "}
                  {selectedCardData.length > 0 && selectedCardData[0].medium}{" "}
                  medium{" "} */}
                </h3>
              </div>

              <div className="star-rating-product">
                {[...Array(totalStars)].map((_, index) => (
                  <span
                    key={index}
                    className={`abc ${calculateStarClass(index)}`}
                  >
                    ★
                  </span>
                ))}
              </div>
              <div className="cta-button-button">
                <button className="cta-button">
                  {" "}
                  <p className="original">₹{currentOriginalPrice}</p>
                  <p className="discounted"   onClick={() => {
      if (!isLoggedIn) {
        window.location.href = 'https://testportal.ssccglpinnacle.com/login';
      } else {
        // Corrected the reference to the selectedCardData[0]._id
        handleBuyNow(selectedCardData.length > 0 ? selectedCardData[0].id : null);
      }
    }}>
                    ₹{currentDiscountedPrice} Buy now
                  </p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showBillingForm && <BillingForm onClose={closeBillingForm} />}
    </>
  );
}

export default Row3intro;
