// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.purchase-heading-books {
    font-size: 28px;
    color: #333;
    text-align: center;
    margin-top: 20px;
  
    font-family: 'Arial', sans-serif;
  }

  .container-digital-view-all-link-boks {
    max-width: 100%;
    /* padding: 0 15px; */
    /* margin: 0 auto; */
    width: 100%;
    display: inline-block;
    margin-top: 10px;
}
.no-purchases-container {
  background-color: #f8f9fa;
  border: 2px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  margin: 50px auto;
  max-width: 600px;
}

.no-purchases-heading {
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
}

.no-purchases-text {
  font-size: 18px;
  color: #555;
  margin-bottom: 20px;
}

.browse-books-button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.browse-books-button:hover {
  background-color: #0056b3;
}
`, "",{"version":3,"sources":["webpack://./src/PagesBook/PurchaseBook.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,WAAW;IACX,kBAAkB;IAClB,gBAAgB;;IAEhB,gCAAgC;EAClC;;EAEA;IACE,eAAe;IACf,qBAAqB;IACrB,oBAAoB;IACpB,WAAW;IACX,qBAAqB;IACrB,gBAAgB;AACpB;AACA;EACE,yBAAyB;EACzB,sBAAsB;EACtB,kBAAkB;EAClB,aAAa;EACb,kBAAkB;EAClB,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,WAAW;EACX,kBAAkB;EAClB,eAAe;EACf,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,iCAAiC;AACnC;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".purchase-heading-books {\n    font-size: 28px;\n    color: #333;\n    text-align: center;\n    margin-top: 20px;\n  \n    font-family: 'Arial', sans-serif;\n  }\n\n  .container-digital-view-all-link-boks {\n    max-width: 100%;\n    /* padding: 0 15px; */\n    /* margin: 0 auto; */\n    width: 100%;\n    display: inline-block;\n    margin-top: 10px;\n}\n.no-purchases-container {\n  background-color: #f8f9fa;\n  border: 2px solid #ddd;\n  border-radius: 8px;\n  padding: 20px;\n  text-align: center;\n  margin: 50px auto;\n  max-width: 600px;\n}\n\n.no-purchases-heading {\n  font-size: 24px;\n  color: #333;\n  margin-bottom: 10px;\n}\n\n.no-purchases-text {\n  font-size: 18px;\n  color: #555;\n  margin-bottom: 20px;\n}\n\n.browse-books-button {\n  background-color: #007bff;\n  color: #fff;\n  padding: 10px 20px;\n  font-size: 16px;\n  border: none;\n  border-radius: 5px;\n  cursor: pointer;\n  transition: background-color 0.3s;\n}\n\n.browse-books-button:hover {\n  background-color: #0056b3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
