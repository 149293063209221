import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import Select from "react-select";
import './EbookNewForm.css'; // Import your CSS file

const EbookNewForm = () => {
  const [ebooks, setEbooks] = useState([]);
  const [selectedSku, setSelectedSku] = useState('');
  const [formData, setFormData] = useState({
    title: '',
    medium: '',
    sku: '',
    subject: '',
    examCategory: '',
    examName: '',
    mrp: 0,
    pinnacleSP: 0,
    ebookPrintingPrice: 0,
    ebookSellingPrice: 0,
    discount: 0,
    search: '',
    desc: '',
    edition: '',
    author: '',
    publishingDate: '',
    img: '',
    selfImg: '', // Added field for self-image
    subscriptionPrices: [], // Added field for subscription prices
  });
  const [selectedImageFile, setSelectedImageFile] = useState(null); // To hold the selected image file

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // Fetch data from the API
    const fetchData = async () => {
      try {
        const response = await fetch('https://nodei.ssccglpinnacle.com/getlist');
        if (!response.ok) {
          throw new Error('Server response not OK');
        }
        const data = await response.json();
        setEbooks(data);
      } catch (error) {
        console.error('Error fetching ebook data:', error);
      }
    };

    fetchData();
  }, []);

  const handleTitleChange = (selectedSku) => {
    const selectedEbookData = ebooks.find(
      (ebook) =>
        ebook.sku &&
        ebook.sku.trim().toLowerCase() === selectedSku.trim().toLowerCase()
    );

    if (selectedEbookData) {
      setSelectedSku(selectedSku);

      // Populate form fields with selected ebook data
      setFormData({
        title: selectedEbookData.title || '',
        medium: selectedEbookData.Medium || '',
        sku: selectedEbookData.sku || '',
        subject: selectedEbookData.subject || '',
        examCategory: selectedEbookData.examCategory || '',
        examName: selectedEbookData.examName || '',
        mrp: selectedEbookData.mrp || 0,
        pinnacleSP: selectedEbookData.pinnacleSP || 0,
        ebookPrintingPrice: selectedEbookData.ebookPrice || 0,
        ebookSellingPrice: selectedEbookData.ebookSellingPrice || 0,
        discount: selectedEbookData.discount || 0,
        search: selectedEbookData.Search || '',
        desc: selectedEbookData.Desc || '',
        edition: selectedEbookData.edition || '',
        author: selectedEbookData.author || '',
        publishingDate: selectedEbookData.publishingDate || '',
        img: selectedEbookData.img || '',
        selfImg: '',
        subscriptionPrices: selectedEbookData.subscriptionPrices || [],
      });
    } else {
      console.error(`Ebook data not found for SKU: ${selectedSku}`);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Validate subscriptionPrices
    const isValidSubscriptionPrices = formData.subscriptionPrices.every(price => 
      price.duration && price.sellingPrice && price.printingPrice
    );
  
    if (!isValidSubscriptionPrices) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Invalid subscription prices format. Please make sure all fields are filled out.',
      });
      return;
    }
  
    const formDataToSend = new FormData();
    formDataToSend.append('title', formData.title);
    formDataToSend.append('category', formData.examCategory);
    formDataToSend.append('price', formData.mrp);
    formDataToSend.append('details', formData.desc);
    formDataToSend.append('medium', formData.medium);
    formDataToSend.append('subject', formData.subject);
    formDataToSend.append('sku', formData.sku);
    formDataToSend.append('examName', formData.examName);
    formDataToSend.append('pinnacleSP', formData.pinnacleSP);
    formDataToSend.append('search', formData.search);
    formDataToSend.append('edition', formData.edition);
    formDataToSend.append('author', formData.author);
    formDataToSend.append('publishingDate', formData.publishingDate);
    formDataToSend.append('ebookSellingPrice', formData.ebookSellingPrice);
    formDataToSend.append('ebookPrintingPrice', formData.ebookPrintingPrice);
    formDataToSend.append('subscriptionPrices', JSON.stringify(formData.subscriptionPrices));
    if (selectedImageFile) {
      formDataToSend.append('image', selectedImageFile);
    }
  
    try {
      const response = await fetch('https://auth.ssccglpinnacle.com/api/ebooks', {
        method: 'POST',
        body: formDataToSend,
      });
  
      const responseBody = await response.json();
  
      if (!response.ok) {
        throw new Error(responseBody.error || 'Server response not OK');
      }
  
      setFormData({
        title: '',
        medium: '',
        sku: '',
        subject: '',
        examCategory: '',
        examName: '',
        mrp: 0,
        pinnacleSP: 0,
        ebookPrintingPrice: 0,
        ebookSellingPrice: 0,
        discount: 0,
        search: '',
        desc: '',
        edition: '',
        author: '',
        img: '',
        selfImg: '',
        subscriptionPrices: [],
      });
  
      setSelectedImageFile(null);
  
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Ebook data inserted successfully!',
      });
    } catch (error) {
      console.error('Error submitting eBook data:', error);
  
      if (error.message.includes('Ebook with the same SKU already exists')) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Ebook with the same SKU already exists',
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'An error occurred while submitting eBook data. Please try again later.',
        });
      }
    }
  };
  

  const handleImgChange = (e) => {
    const imgUrl = e.target.src;
    const imgName = imgUrl.substring(imgUrl.lastIndexOf('/') + 1);

    setFormData({
      ...formData,
      img: imgName,
    });
  };

  const handleSelfImgChange = (e) => {
    const selfImgUrl = e.target.src;
    const selfImgName = selfImgUrl.substring(selfImgUrl.lastIndexOf('/') + 1);

    setFormData({
      ...formData,
      selfImg: selfImgName,
    });
  };

  const addSubscriptionPrice = () => {
    if (formData.subscriptionPrices.length < 1) {
      setFormData({
        ...formData,
        subscriptionPrices: [...formData.subscriptionPrices, { duration: '12 Months', sellingPrice: 0, printingPrice: 0 }],
      });
    }
  };
  

  const handleSubscriptionPriceChange = (index, field, value) => {
    const updatedSubscriptionPrices = formData.subscriptionPrices.map((price, i) => 
      i === index ? { ...price, [field]: value } : price
    );
    setFormData({
      ...formData,
      subscriptionPrices: updatedSubscriptionPrices,
    });
  };


  return (
    
  

    <>
      <div className="ebook-new-form-container">
        <h2 className="add-ebook-form">Add digital content</h2>
        <form onSubmit={handleSubmit} className="ebook-form-new">
          <div className="form-group">
            <label htmlFor="selectSku" className="label">
              Select SKU:
            </label>
            <Select
              id="selectSku"
              value={{ label: selectedSku, value: selectedSku }} // Use the correct format for value
              onChange={(option) => handleTitleChange(option.value)}
              options={ebooks.map((ebook) => ({
                label: ebook.sku,
                value: ebook.sku,
              }))}
              className="title-select"
            />
          </div>
  
          <div className="form-group">
            <label htmlFor="skuInput" className="label">
              Title:
            </label>
            <input
              type="text"
              id="skuInput"
              value={formData.title}
              readOnly
              className="form-input"
              required
            />
          </div>
  
          <div className="form-group">
            <label htmlFor="mediumInput" className="label">
              Medium:
            </label>
            <input
              type="text"
              id="mediumInput"
              value={formData.medium}
              readOnly
              className="form-input"
              required
            />
          </div>
  
          <div className="form-group">
            <label htmlFor="editionInput" className="label">
              Edition:
            </label>
            <input
              type="text"
              id="editionInput"
              value={formData.edition}
              className="form-input"
              onChange={(e) => setFormData({ ...formData, edition: e.target.value })}
            />
          </div>
  
          <div className="form-group">
            <label htmlFor="subjectsInput" className="label">
              Subjects:
            </label>
            <input
              type="text"
              id="subjectsInput"
              value={formData.subject}
              onChange={(e) => setFormData({ ...formData, subject: e.target.value })}
              className="form-input"
            />
          </div>
  
          <div className="form-group">
            <label htmlFor="examCategoryInput" className="label">
              Exam Category:
            </label>
            <input
              type="text"
              id="examCategoryInput"
              value={formData.examCategory}
              className="form-input"
              onChange={(e) => setFormData({ ...formData, examCategory: e.target.value })}
            />
          </div>
  
          <div className="form-group">
            <label htmlFor="examNameInput" className="label">
              Exam Name:
            </label>
            <input
              type="text"
              id="examNameInput"
              value={formData.examName}
              className="form-input"
              onChange={(e) => setFormData({ ...formData, examName: e.target.value })}
            />
          </div>
  
          <div className="form-group">
            <label htmlFor="mrpInput" className="label">
              Book MRP:
            </label>
            <input
              type="number"
              id="mrpInput"
              value={formData.mrp}
              className="form-input"
              onChange={(e) => setFormData({ ...formData, mrp: e.target.value })}
            />
          </div>
  
          <div className="form-group">
            <label htmlFor="pinnacleSPInput" className="label">
              Book selling price:
            </label>
            <input
              type="number"
              id="pinnacleSPInput"
              value={formData.pinnacleSP}
              className="form-input"
              onChange={(e) => setFormData({ ...formData, pinnacleSP: e.target.value })}
            />
          </div>
  
          {/* <div className="form-group">
            <label htmlFor="ebookPriceInput" className="label">
              Ebook printing price:
            </label>
            <input
              type="number"
              id="ebookPriceInput"
              value={formData.ebookPrintingPrice}
              className="form-input"
              onChange={(e) =>
                setFormData({ ...formData, ebookPrintingPrice: e.target.value })
              }
            />
          </div>
  
          <div className="form-group">
            <label htmlFor="ebookSellingPriceInput" className="label">
              Ebook selling price:
            </label>
            <input
              type="number"
              id="ebookSellingPriceInput"
              value={formData.ebookSellingPrice}
              className="form-input"
              onChange={(e) =>
                setFormData({
                  ...formData,
                  ebookSellingPrice: e.target.value,
                })
              }
            />
          </div> */}
  
          <div className="form-group">
            <label className="label subscription-group-price">Subscription Prices:</label>
            {formData.subscriptionPrices.map((price, index) => (
  <div key={index} className="subscription-price-group">
    <label className="label">Duration:</label>
    <select
      value={price.duration}
      onChange={(e) => handleSubscriptionPriceChange(index, 'duration', e.target.value)}
      className="form-input subscription-duration"
    >
      <option value="">Select Duration</option>
      <option value="12 Months">12 Months</option>
    </select>
    <label htmlFor="ebookPriceInput" className="label">
      Ebook MRP:
    </label>
    <input
      type="number"
      value={price.printingPrice}
      onChange={(e) => handleSubscriptionPriceChange(index, 'printingPrice', e.target.value)}
      className="form-input subscription-price"
      placeholder="Enter Printing Price"
    />
    <label htmlFor="ebookSellingPriceInput" className="label">
      Ebook selling price:
    </label>
    <input
      type="number"
      value={price.sellingPrice}
      onChange={(e) => handleSubscriptionPriceChange(index, 'sellingPrice', e.target.value)}
      className="form-input subscription-price"
      placeholder="Enter Selling Price"
    />
  </div>
))}
<button
  type="button"
  onClick={addSubscriptionPrice}
  className="add-subscription-price-button"
  disabled={formData.subscriptionPrices.length >= 1}
>
  Add Subscription Price
</button>

          </div>
  
          <div className="form-group">
            <label htmlFor="discountInput" className="label">
              Discount: 10%
            </label>
          </div>
  
          <div className="form-group">
            <label htmlFor="searchInput" className="label">
              Search:
            </label>
            <input
              type="text"
              id="searchInput"
              value={formData.search}
              className="form-input"
              onChange={(e) => setFormData({ ...formData, search: e.target.value })}
            />
          </div>
  
          <div className="form-group">
            <label htmlFor="descInput" className="label">
              Desc:
            </label>
            <input
              type="text"
              id="descInput"
              value={formData.desc}
              className="form-input"
              onChange={(e) => setFormData({ ...formData, desc: e.target.value })}
            />
          </div>
  
          <div className="form-group">
            <label htmlFor="authorInput" className="label">
              Author:
            </label>
            <input
              type="text"
              id="authorInput"
              value={formData.author}
              className="form-input"
              onChange={(e) => setFormData({ ...formData, author: e.target.value })}
            />
          </div>
          <div className="form-group">
            <label htmlFor="publishingDate" className="label">
              Date for publishing:
            </label>
            <input
              type="date"
              id="publishingDate"
              value={formData.publishingDate}
              className="form-input"
              onChange={(e) => setFormData({ ...formData, publishingDate: e.target.value })}
            />
          </div>
  
          <div className="form-group">
            <div className="form-group-img">
              <label htmlFor="imgInput" className="label">
                Image:
              </label>
              <div className="photo-of-ebook">
                <img
                  src={`http://13.200.156.92:5000/${formData.img}`}
                  id="pic"
                  alt="foto"
                  onLoad={handleImgChange} // Call handleImgChange when the image is loaded
                />
              </div>
            </div>
          </div>
  
          <div className="form-group">
            <div className="form-group-img">
              <label htmlFor="selfImgInput" className="label">
                Self Image:
              </label>
              <input
                type="file"
                id="selfImgInput"
                className="form-input-new"
                onChange={(e) => setSelectedImageFile(e.target.files[0])}
              />
              {selectedImageFile && (
                <div className="photo-of-self">
                  <img
                    src={URL.createObjectURL(selectedImageFile)}
                    alt="Self"
                  />
                </div>
              )}
            </div>
          </div>
  
          <div className="form-group">
            <div className="bbb">
              <button type="submit" className="submit-ebook-button">
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
  
  
  
};

export default EbookNewForm;
