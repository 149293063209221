import { Row, Col, Button } from "react-bootstrap";
import React, { useEffect, useState } from 'react';
import TypingTimer from './TypingTimer';
import { useNavigate, useParams } from 'react-router-dom';
import parse from "html-react-parser";
import './Typing.css';
import { useCookies } from 'react-cookie';
import { diffWords } from 'diff';

const TypingModule = () => {
    const { testcode, exam, category } = useParams();
    const [message, setMessage] = useState('');
    const [wpm, setWpm] = useState('');
    const [minute, setMinute] = useState('');
    const hoursMinSecs = { hours: 0, minutes: minute, seconds: 0 };
    const [rmTm, setrmTm] = useState();
    const navigate = useNavigate();
    const [paragraph, setParagraph] = useState('');
    const [typing, setTyping] = useState(false);
    const [contentLength, setContentLength] = useState('');
    const [cookies] = useCookies(['token', 'userData']);


    let emailId = '';

    // Extract and parse userData to get email_id
    if (cookies.userData) {
        try {
            let parsedUserData;

            // Check if userData is already an object
            if (typeof cookies.userData === 'string') {
                const decodedUserData = decodeURIComponent(cookies.userData); // Decode the URL-encoded string
                parsedUserData = JSON.parse(decodedUserData); // Parse the JSON string
            } else {
                parsedUserData = cookies.userData; // Assume it's already an object
            }

            emailId = parsedUserData.email_id || ''; // Extract email_id
        } catch (error) {
            console.error('Error processing userData cookie:', error);
        }
    }

     // Log the extracted emailId
     const disableTextSelection = (event) => {
        event.preventDefault();
      };
    
      // Function to prevent copy action
      const preventCopy = (event) => {
        event.preventDefault();
        console.log('Copy action prevented');
      };
    
      useEffect(() => {
        const disableKeyCombinations = (event) => {
            if (event.ctrlKey && event.code === 'KeyP') {
                event.preventDefault();
                console.log('Prevented:', event.code);
            }
        };
    
        const preventRightClick = (event) => {
            event.preventDefault();
            console.log('Right-click is disabled');
        };
    
        document.addEventListener('keydown', disableKeyCombinations, true);
        document.addEventListener('selectstart', disableTextSelection); // Prevent text selection
        document.addEventListener('copy', preventCopy); // Prevent copying
        document.addEventListener('contextmenu', preventRightClick); // Prevent right-click
    
        return () => {
            document.removeEventListener('keydown', disableKeyCombinations, true);
            document.removeEventListener('selectstart', disableTextSelection); // Remove text selection prevention
            document.removeEventListener('copy', preventCopy); // Remove copy prevention
            document.removeEventListener('contextmenu', preventRightClick); // Remove right-click prevention
        };
    }, []);
    
    

    const fetchParagraph = async () => {
        let dt = { paper_code: testcode, exam: exam };
        let state_res = await fetch("https://auth.ssccglpinnacle.com/api/typingParagraph", {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${cookies.token}`
            },
            body: JSON.stringify(dt)
        });

        if (state_res.ok) {
            state_res = await state_res.json();
            setParagraph(state_res.paragraph);
            setContentLength(state_res.paragraph.length);
            setMinute(state_res.time);
        } else {
            console.error("Failed to fetch paragraph", state_res.statusText);
        }
    };

    useEffect(() => {
        const checkAccess = async () => {
            if (!cookies.token) {
                navigate('/');
                return;
            }

            try {
                const response = await fetch('https://auth.ssccglpinnacle.com/api/checkAccessTyping', {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        "Authorization": `Bearer ${cookies.token}`
                    }
                });

                if (response.ok) {
                    const { access } = await response.json();
                    if (access === "access") {
                        const productResponse = await fetch('https://auth.ssccglpinnacle.com/api/checkAccessTypingProduct', {
                            method: 'POST',
                            headers: {
                                "Content-Type": "application/json",
                                "Accept": "application/json",
                                "Authorization": `Bearer ${cookies.token}`
                            },
                            body: JSON.stringify({ product_id: '999' }) // Replace with actual product ID
                        });

                        if (productResponse.ok) {
                            const { access } = await productResponse.json();
                            if (access === "access") {
                                fetchParagraph();
                            } else {
                                navigate('/');
                            }
                        } else {
                            navigate('/');
                        }
                    } else {
                        navigate('/');
                    }
                } else {
                    navigate('/');
                }
            } catch (error) {
                navigate('/');
            }
        };

        checkAccess();
    }, [testcode, exam, cookies.token, navigate]);

    let d = paragraph;

    const handleMessageChange = event => {
        if (!typing) {
            setTyping(true);
        }
        setMessage(event.target.value);
        console.log(event.target.value);
    };

    const rmTimeFun = (rTm) => {
        setrmTm(rTm);
    };

    // const messageSubmit = async () => {
    //     let s = message;

    //     var actualdep = Math.round(s.length);
    //     var totaldep = d.length;

    //     s = s.split(' ');
    //     d = d.split(' ');

    //     let c = 0;
    //     var iac = 0;
    //     var dd = 0;

    //     let scale = [];

    //     for (var i = 0; i < d.length; i++) {
    //         if (s.length > i) {
    //             if (s[i] == d[i]) {
    //                 let start = i;
    //                 let deleteCount = 0;
    //                 scale.splice(start, deleteCount, d[i]);
    //                 c += s[i].length + 1;
    //             } else if (s[i] == '') {
    //                 let start = i;
    //                 let deleteCount = 0;
    //                 s.splice(start, deleteCount, d[i]);
    //                 scale.splice(start, deleteCount, "<span class='wrongword'>" + d[i] + "</span>");
    //             } else {
    //                 var ii = i + 1;
    //                 var iaa = s[i] + s[ii];
    //                 var daa = d[i] + d[ii];
    //                 var daaa = d[i] + d[ii + 1];

    //                 if (iaa == d[i]) {
    //                     var iacc = s[i].length + 1;
    //                     iac = iacc / 2;
    //                     c += iac;
    //                     let start = i;
    //                     let deleteCount = 0;
    //                     scale.splice(start, deleteCount, d[i] + "<span class='wrongword'>" + s[i] + "</span>");
    //                     s.splice(start, 2);
    //                     s.splice(start, deleteCount, d[i]);
    //                 } else if (iaa == daa) {
    //                     var iacc = s[i].length + 1;
    //                     iac = iacc / 2;
    //                     c += iac;
    //                     let start = i;
    //                     let deleteCount = 0;
    //                     scale.splice(start, deleteCount, d[i] + "<span class='wrongword'>" + s[i] + "</span>");
    //                     s.splice(start, deleteCount, d[i]);
    //                 } else if (iaa == daaa) {
    //                     var iacc = s[i].length + 1;
    //                     iac = iacc / 2;
    //                     c += iac;
    //                     let start = i;
    //                     let deleteCount = 0;
    //                     scale.splice(start, deleteCount, d[i] + "<span class='wrongword'>" + s[i] + "</span>");
    //                     s.splice(start, deleteCount, d[i]);
    //                 } else {
    //                     if (i > 0 && i < s.length) {
    //                         var mdaa = d[i - 1];
    //                         if (i > 0 && i < d.length) {
    //                             if (s[i].toLowerCase() == d[i].toLowerCase()) {
    //                                 let start = i;
    //                                 let deleteCount = 0;
    //                                 scale.splice(start, deleteCount, d[i] + "<span class='wrongword'>" + s[i] + "</span>");
    //                                 var iacc = s[i].length + 1;
    //                                 iac = iacc / 2;
    //                                 c += iac;
    //                             } else {
    //                                 if (d[i] != s[i] && d[i + 1] != undefined && d[i + 1].toLowerCase() != s[i].toLowerCase()) {
    //                                     if (d[i] != s[i] && s[i + 1] != undefined && d[i + 1].toLowerCase() == s[i + 1].toLowerCase()) {
    //                                         let start = i;
    //                                         let deleteCount = 0;
    //                                         scale.splice(start, deleteCount, d[i] + "<span class='wrongword'>" + s[i] + "</span>");
    //                                         s.splice(start, 1);
    //                                         s.splice(start, deleteCount, d[i]);
    //                                     } else {
    //                                         let start = i;
    //                                         let deleteCount = 0;
    //                                         scale.splice(start, deleteCount, d[i]);
    //                                         c += d[i].length + 1;
    //                                     }
    //                                 } else {
    //                                     let start = i;
    //                                     let deleteCount = 0;
    //                                     scale.splice(start, deleteCount, "<span class='wrongword'>" + d[i] + "</span>");
    //                                     s.splice(start, deleteCount, d[i]);
    //                                 }
    //                             }
    //                         } else {
    //                             let start = i;
    //                             let deleteCount = 0;
    //                             scale.splice(start, deleteCount, d[i] + "<span class='wrongword'>" + s[i] + "</span>");
    //                             s.splice(start, deleteCount, d[i]);
    //                         }
    //                     } else {
    //                         let start = i;
    //                         let deleteCount = 0;
    //                         scale.splice(start, deleteCount, d[i] + "<span class='wrongword'>" + s[i] + "</span>");
    //                         s.splice(start, deleteCount, d[i]);
    //                     }
    //                 }
    //             }
    //         } else {
    //             let start = i;
    //             let deleteCount = 0;
    //             s.splice(start, deleteCount, d[i]);
    //             scale.splice(start, deleteCount, "<span class='wrongword'>" + d[i] + "</span>");
    //         }
    //     }

    //     if (c > 0) {
    //         dd = c - 1;
    //     } else {
    //         dd = c;
    //     }

    //     var time = 30;
    //     var char = dd;
    //     var word = char / 5;

    //     if (rmTm != undefined) {
    //         var a = rmTm.split(':');
    //         var total_time = '00:' + minute + ':00';
    //         var ttime = total_time.split(':');
    //         var secons = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
    //         var ttsecons = (+ttime[0]) * 60 * 60 + (+ttime[1]) * 60 + (+ttime[2]);
    //         var ttt = ttsecons - secons;
    //         var min = ttt / 60;
    //         console.log(ttt);
    //     }

    //     var wpm = Math.round(word / min);
    //     var accurac = (dd / totaldep) * 100;
    //     var accuracy = Math.round((accurac + Number.EPSILON) * 100) / 100;
    //     var accurcharacter = dd;
    //     var wrongp = 100 - accuracy;
    //     var wrongper = Math.round((wrongp + Number.EPSILON) * 100) / 100;
    //     var wrongcharacter = totaldep - dd;
    //     var netsped = (dd * 60) / ttt;
    //     var netspeed = Math.round(netsped / 5);
    //     var grosssped = (message.length * 60) / ttt;
    //     var grossspeed = Math.round(grosssped / 5);

    //     console.log(ttt);

    //     let paragraph = scale.join(' ');
    //     let typing_performance_result = {
    //         'email_id': emailId,
    //         'paper_code': testcode,
    //         'student_paragraph': message,
    //         'paragraph': paragraph,
    //         'accuracy': accuracy,
    //         'wrong': wrongper,
    //         'grossspeed': grossspeed,
    //         'totaldepres': totaldep,
    //         'accuratedep': accurcharacter,
    //         'wrongdep': wrongcharacter,
    //         'actualdep': dd,
    //         'speed': netspeed,
    //         'time': rmTm
    //     };
    //     let typing_performance_row = await fetch("https://auth.ssccglpinnacle.com/api/typingPerformance", {
    //         method: 'POST',
    //         headers: {
    //             "Content-Type": "application/json",
    //             "Accept": "application/json",
    //             "Authorization": `Bearer ${cookies.token}`
    //         },
    //         body: JSON.stringify(typing_performance_result)
    //     });
    //     typing_performance_row = await typing_performance_row.json();

    //     navigate(`/typingperformance/${accuracy}/${wrongper}/${dd}/${netspeed}/${testcode}/${exam}/${category}`);
    // }

    // const messageSubmit = async () => {
    //     let s = message.trim();  // The student's typed paragraph
    //     let d = paragraph.trim();  // The original paragraph
    
    //     s = s.split(/\s+/);  // Split by one or more spaces
    //     d = d.split(/\s+/);  // Same for the original text
    
    //     let scale = [];  // To store the final comparison output
    //     let correctChars = 0;  // Correct characters count
    //     let wrongChars = 0;  // Wrong characters count
    //     let totalDepressions = d.join(' ').length;  // Total characters in the original paragraph
    //     let correctDepressions = 0;  // Correct depressions count
    //     let i = 0;  // Index for the original paragraph
    //     let j = 0;  // Index for the student's input
    //     let tolerance = 1;  // Allow tolerance for missing words (1 word)
    //     let wrongwordPairs = [];  // Array to store wrongword and corresponding original word
    
    //     // Helper function to calculate similarity between words
    //     const wordSimilarity = (word1, word2) => {
    //         let distance = 0;
    //         let len = Math.max(word1.length, word2.length);
    //         for (let k = 0; k < len; k++) {
    //             if (word1[k] !== word2[k]) distance++;
    //         }
    //         return 1 - (distance / len);  // Return similarity ratio
    //     };
    
    //     // Helper function to compare original word with student words
    //     const findMatchWithinTolerance = (originalWord, startIndex) => {
    //         for (let k = startIndex; k < Math.min(startIndex + 3, s.length); k++) {
    //             const similarity = wordSimilarity(originalWord, s[k]);
    //             if (originalWord === s[k]) {
    //                 return { index: k, type: 'correct' };  // Exact match
    //             } else if (similarity >= 0.45) {  // Adjust similarity threshold to 45%
    //                 return { index: k, type: 'wrongword', similarity };  // Similar enough to be a wrongword
    //             }
    //         }
    //         return { index: -1, type: 'missing' };  // No match found within 3 words
    //     };
    
    //     // Compare word by word in the original paragraph
    //     while (i < d.length || j < s.length) {
    //         const originalWord = d[i] || '';  // Original word from the paragraph
    //         const studentWord = s[j] || '';   // Word typed by the student
    
    //         console.log(`Comparing original word: [${originalWord}] with student's word: [${studentWord}]`);
    
    //         let matchResult = findMatchWithinTolerance(originalWord, j);  // Check for match or similarity within 3 words
    
    //         if (matchResult.index !== -1 && matchResult.type === 'correct') {
    //             // If it's an exact match
    //             console.log(`Correct word found at student index ${matchResult.index}: ${s[matchResult.index]}`);
    //             scale.push(`<span class="correctword">${s[matchResult.index]}</span>`);
    //             correctChars += s[matchResult.index].length + 1;  // Count correct characters
    //             correctDepressions += s[matchResult.index].length + 1;  // Count correct depressions
    //             i++;
    //             j = matchResult.index + 1;  // Move to the next word
    //         } else if (matchResult.index !== -1 && matchResult.type === 'wrongword') {
    //             // If it's a wrongword based on similarity
    //             console.warn(`Word is similar but incorrect: ${s[matchResult.index]} (original: ${originalWord})`);
    //             scale.push(`<span class="wrongword">${s[matchResult.index]}</span>`);
    //             wrongwordPairs.push({ wrong: s[matchResult.index], original: originalWord });  // Store wrongword pair
    //             wrongChars += s[matchResult.index].length + 1;  // Track wrong characters
    //             i++;
    //             j = matchResult.index + 1;  // Move to the next word
    //         } else {
    //             // If no match is found within tolerance and similarity is below threshold, mark as missing
    //             if (tolerance > 0) {
    //                 console.warn(`Word skipped due to tolerance: ${originalWord}`);
    //                 scale.push(`<span class="missingword">${originalWord}</span>`);  // Mark as missingword
    //                 tolerance--;
    //                 i++;  // Move to the next word in the original paragraph
    //             } else {
    //                 // If tolerance is exceeded, mark both original and student words as wrong
    //                 console.warn(`No match found and tolerance exceeded. Marking both as wrong.`);
    //                 if (studentWord && !scale.includes(`<span class="wrongword">${studentWord}</span>`)) {
    //                     console.log(`Marking wrongword: ${studentWord} (original: ${originalWord})`);
    //                     scale.push(`<span class="wrongword">${studentWord}</span>`);
    //                     wrongwordPairs.push({ wrong: studentWord, original: originalWord });  // Store wrongword pair
    //                     wrongChars += studentWord.length + 1;  // Count as wrong
    //                 }
    
    //                 if (originalWord && !scale.includes(`<span class="wrongword">${originalWord}</span>`)) {
    //                     console.log(`Marking as missingword after wrongword: ${originalWord}`);
    //                     scale.push(`<span class="missingword">${originalWord}</span>`);
    //                     wrongChars += originalWord.length + 1;  // Count as wrong
    //                 }
    
    //                 i++;  // Move to the next word in the original paragraph
    //                 j++;  // Move to the next word in the student's input
    //                 tolerance = 1;  // Reset tolerance
    //             }
    //         }
    //     }
    
    //     // Log wrongwordPairs to check the array content
    //     console.log("Final wrongword pairs:", wrongwordPairs);
    
    //     // Remove missingword spans that have corresponding wrongwords
    //     wrongwordPairs.forEach(pair => {
    //         const { original } = pair;
    //         console.log(`Checking if missingword span needs removal for original word: ${original}`);
            
    //         scale = scale.map(span => {
    //             if (span.includes(`<span class="missingword">${original}</span>`)) {
    //                 console.log(`Removing missingword span for: ${original}`);
    //                 return '';  // Remove the missingword span
    //             }
    //             return span;
    //         });
    //     });
    
    //     const finalParagraph = scale.join(' ');  // Combine the final HTML for rendering
    //     console.log("Final rendered paragraph:", finalParagraph);
    
    //     // Time and Speed Calculations (same as before)
    //     if (rmTm !== undefined) {
    //         const a = rmTm.split(':');
    //         const total_time = `00:${minute}:00`;
    //         const ttime = total_time.split(':');
    //         const secondsUsed = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
    //         const totalSeconds = (+ttime[0]) * 60 * 60 + (+ttime[1]) * 60 + (+ttime[2]);
    //         const ttt = totalSeconds - secondsUsed;  // Time taken in seconds
    
    //         const netspeed = Math.round((correctDepressions * 60) / (ttt * 5));
    //         const grossspeed = Math.round((message.length * 60) / (ttt * 5));
    
    //         const accuracy = ((correctChars / totalDepressions) * 100).toFixed(2);
    //         const wrongPercentage = (100 - accuracy).toFixed(2);
    
    //         console.log(`Total correct depressions: ${correctDepressions}`);
    //         console.log(`Total wrong depressions: ${wrongChars}`);
    //         console.log(`Accuracy: ${accuracy}%, Wrong percentage: ${wrongPercentage}%`);
    
    //         // Prepare the result object
    //         let typing_performance_result = {
    //            'email_id': emailId,
    //             'paper_code': testcode,
    //             'student_paragraph': message,
    //             'paragraph': finalParagraph,
    //             'accuracy': accuracy,
    //             'wrong': wrongPercentage,
    //             'grossspeed': grossspeed,
    //             'totaldepres': totalDepressions,
    //             'accuratedep': correctDepressions,
    //             'wrongdep': wrongChars,
    //             'speed': netspeed,
    //             'time': rmTm
    //         };
    
    //         console.log("Typing Performance Result:", typing_performance_result);
    
    //         // Submit the result to the backend
    //         let response = await fetch("https://auth.ssccglpinnacle.com/api/typingPerformance", {
    //             method: 'POST',
    //             headers: {
    //                 "Content-Type": "application/json",
    //                 "Accept": "application/json",
    //                 "Authorization": `Bearer ${cookies.token}`
    //             },
    //             body: JSON.stringify(typing_performance_result)
    //         });
    
    //         if (response.ok) {
    //             let result = await response.json();
    //             console.log("Submission successful:", result);
    //             navigate(`/typingperformance/${accuracy}/${wrongPercentage}/${correctDepressions}/${netspeed}/${testcode}/${exam}/${category}`);
    //         } else {
    //             console.error("Error submitting typing performance");
    //         }
    //     }
    // };

    const messageSubmit = async () => {
        const originalParagraph = paragraph.trim();  // The original paragraph to compare against
        const userInput = message.trim();  // User's input
    
        // Use diffWords to compare the original and user input paragraphs word-by-word
        const diff = diffWords(originalParagraph, userInput);
    
        // Build the comparison result by mapping the diff output to formatted HTML
        const comparisonResult = diff.map(part => {
            const text = part.value;
            if (part.added) {
                // Extra words in user input
                return `<span class="wrongword">${text}</span>`;
            } else if (part.removed) {
                // Missing words in user input
                return `<span class="missingword">${text}</span>`;
            } else {
                // Correct words
                return `<span class="correctword">${text}</span>`;
            }
        }).join(' ');
    
        // Combine the result array into a single string of HTML for rendering and submission
        const finalParagraph = comparisonResult;
    
        let correctChars = 0;  // Count of correct characters
        let wrongChars = 0;  // Count of wrong characters
        const totalDepressions = originalParagraph.length;  // Total characters in the original paragraph
    
        // Count correct and wrong characters
        diff.forEach(part => {
            if (!part.added && !part.removed) {
                // Correct characters
                correctChars += part.value.length;
            } else if (part.added) {
                // Extra (wrong) characters
                wrongChars += part.value.length;
            }
        });
    
        // Time and Speed Calculations
        if (rmTm !== undefined) {
            const timeParts = rmTm.split(':');
            const total_time = `00:${minute}:00`;
            const totalSecondsUsed = (+timeParts[0]) * 3600 + (+timeParts[1]) * 60 + (+timeParts[2]);
            const totalTestSeconds = (+total_time.split(':')[1]) * 60;
            const timeTaken = totalTestSeconds - totalSecondsUsed;  // Time taken in seconds
    
            const netSpeed = Math.round((correctChars * 60) / (timeTaken * 5));  // Net speed in WPM
            const grossSpeed = Math.round((message.length * 60) / (timeTaken * 5));  // Gross speed in WPM
    
            const accuracy = ((correctChars / totalDepressions) * 100).toFixed(2);
            const wrongPercentage = (100 - accuracy).toFixed(2);
    
            console.log(`Total correct depressions: ${correctChars}`);
            console.log(`Total wrong depressions: ${wrongChars}`);
            console.log(`Accuracy: ${accuracy}%, Wrong percentage: ${wrongPercentage}%`);
    
            // Prepare the result object
            const typing_performance_result = {
                'email_id': emailId,
                'paper_code': testcode,
                'student_paragraph': message,
                'paragraph': finalParagraph,  // Formatted comparison result with HTML
                'accuracy': accuracy,
                'wrong': wrongPercentage,
                'grossspeed': grossSpeed,
                'totaldepres': totalDepressions,
                'accuratedep': correctChars,
                'wrongdep': wrongChars,
                'speed': netSpeed,
                'time': rmTm
            };
    
            // Submit the result to the backend
            const response = await fetch("https://auth.ssccglpinnacle.com/api/typingPerformance", {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${cookies.token}`
                },
                body: JSON.stringify(typing_performance_result)  // Convert to JSON string
            });
    
            if (response.ok) {
                const result = await response.json();
                console.log("Submission successful:", result);
                navigate(`/typingperformance/${accuracy}/${wrongPercentage}/${correctChars}/${netSpeed}/${testcode}/${exam}/${category}`);
            } else {
                console.error("Error submitting typing performance");
            }
        }
    };




    if (rmTm == '00:00:00') {
        messageSubmit();
    }

    return (
        <>
            <Row className="tt-fs-r">
                <Col md={12}>
                    <p className ="typing-test-upper">Typing Test 2024</p>
                </Col>
            </Row>

            <Row className="tt-se-r">
                <Col md={6} className="tt-se-rc6">
                    <p className="tt_se-rc6-p">SSC {exam} T2 English</p>
                </Col>
                <Col md={6} className="tt-se-rrc6">
                    <p className="tt-se-rrc6-p">Instructions</p>
                </Col>
            </Row>
            <Row className="tt-th-r">
                <Col className="tt-th-rfdiv">
                    <Button className="tt-th-rfdiv-button1">Group 1</Button>
                    <Button className="tt-th-rfdiv-button2">Group 2</Button>
                    <Button className="tt-th-rfdiv-button3">Group 3</Button>
                </Col>

                <Col className="tt-th-rsdiv">
                    <Col className="tt-th-rsdiv-firstchild">
                        <Col className="tt-th-rsdiv-secondchild">
                            <span className="tt-th-rsdiv-secondchild-span">Time Left : {typing === true ? <TypingTimer hoursMinSecs={hoursMinSecs} rmTimeFun={rmTimeFun} /> : ''} </span>
                        </Col>
                        <Col className="tt-th-rsdiv-border"></Col>
                    </Col>
                    <Col className="tt-th-rs-userdiv"></Col>
                </Col>
            </Row>
            <Row className="tt-fr-r">
                <Col md={12} className="tt-fr-rcol12">
                    <span className="tt-fr-rcol-span1">Keyboard Layout: Inscript</span>
                    <span className="tt-fr-rcol-span2">Language: English</span>
                </Col>
            </Row>
            <Row className="tt-fth-r">
                <Col md={6} className="tt-fth-rcol6">
                    <Row className="tt-subfth-r">
                        <Col md={12} className="tt-subfth-rcol12" readOnly>
                            <Col className="tt-subfth-rcol12-textarea1" readOnly>
                                {d}
                            </Col>
                        </Col>
                        <Col md={12} className="tt-subfth-rcol12">
                        <textarea 
  className="tt-subfth-rcol12-textarea2" 
  value={message} 
  spellCheck="false" 
  onChange={handleMessageChange} 
  maxLength={contentLength}
  data-gramm="false"
   onPaste={(e) => e.preventDefault()}
>
</textarea>
                        </Col>
                    </Row>
                </Col>
                <Col md={1}></Col>
                <Col md={4} className="tt-fth-rcol4">
                    <Col className="tt-fth-rcol4-div1">
                        <p className="tt-fth-rcol4-div1p">English</p>
                    </Col>
                    <Col className="tt-fth-rcol4-div2">
                        <p className="tt-fth-rcol4-div2p">
                            <span className="tt-fth-rcol4-span1">Select Language:</span>
                            <span className="tt-fth-rcol4-span2">English(US)</span>
                            <span className="tt-fth-rcol4-span3">Move</span>
                        </p>
                    </Col>
                    <Col className="tt-fth-rcol4-div-submit">
                        <Button className="tt-fth-rcol4-div-submit-button" onClick={messageSubmit}>Submit</Button>
                    </Col>
                </Col>
                <Col md={1}></Col>
            </Row>
        </>
    );
}

export default TypingModule;
