// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ContactUs_contactContainer__QojTG {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-top:80px;
}

.ContactUs_contactContainer__QojTG h2 {
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
}

.ContactUs_contactContainer__QojTG p {
    font-size: 16px;
    line-height: 1.6;
    color: #555;
}

.ContactUs_errorMessage__8aLsQ {
    color: #d9534f;
    font-weight: bold;
    text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/FooterMain/ContactUs.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,gBAAgB;IAChB,cAAc;IACd,8BAA8B;IAC9B,yBAAyB;IACzB,sBAAsB;IACtB,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,WAAW;IACX,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,WAAW;AACf;;AAEA;IACI,cAAc;IACd,iBAAiB;IACjB,kBAAkB;AACtB","sourcesContent":[".contactContainer {\n    padding: 20px;\n    max-width: 800px;\n    margin: 0 auto;\n    font-family: Arial, sans-serif;\n    background-color: #f9f9f9;\n    border: 1px solid #ddd;\n    border-radius: 8px;\n    margin-top:80px;\n}\n\n.contactContainer h2 {\n    font-size: 24px;\n    color: #333;\n    margin-bottom: 20px;\n    text-align: center;\n}\n\n.contactContainer p {\n    font-size: 16px;\n    line-height: 1.6;\n    color: #555;\n}\n\n.errorMessage {\n    color: #d9534f;\n    font-weight: bold;\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contactContainer": `ContactUs_contactContainer__QojTG`,
	"errorMessage": `ContactUs_errorMessage__8aLsQ`
};
export default ___CSS_LOADER_EXPORT___;
