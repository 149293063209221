import React, { useState } from 'react';
import './AddTypingParagraph.css'; // Ensure this path is correct

const AddTypingParagraph = () => {
  const [formData, setFormData] = useState({
    exam: '',
    type: '',
    date: '',
    paper_code: '',
    title: '',
    paragraph: '',
    time: '',
    status: '1'
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const response = await fetch('https://auth.ssccglpinnacle.com/api/addTypingParagraph', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    });

    if (response.ok) {
      alert('Typing paragraph added successfully');
      setFormData({
        exam: '',
        type: '',
        date: '',
        paper_code: '',
        title: '',
        paragraph: '',
        time: '',
        status: '1'
      });
    } else {
      alert('Failed to add typing paragraph');
    }
  };

  return (
<>
    <h1 className="add-ebook-form">Add Typing content</h1>
    <form onSubmit={handleSubmit} className="typing-paragraph-form">
      <div className="typing-paragraph-form-group">
        <label className="typing-paragraph-label" htmlFor="exam-typing">Exam</label>
        <input id="exam-typing" className="typing-paragraph-input" type="text" name="exam" value={formData.exam} onChange={handleChange} required />
      </div>
      <div className="typing-paragraph-form-group">
        <label className="typing-paragraph-label" htmlFor="type-typing">Type</label>
        <input id="type-typing" className="typing-paragraph-input" type="text" name="type" value={formData.type} onChange={handleChange} required />
      </div>
      <div className="typing-paragraph-form-group">
        <label className="typing-paragraph-label" htmlFor="date-typing">Date</label>
        <input id="date-typing" className="typing-paragraph-input" type="text" name="date" value={formData.date} onChange={handleChange} required />
      </div>
      <div className="typing-paragraph-form-group">
        <label className="typing-paragraph-label" htmlFor="paper-code-typing">Paper Code</label>
        <input id="paper-code-typing" className="typing-paragraph-input" type="text" name="paper_code" value={formData.paper_code} onChange={handleChange} required />
      </div>
      <div className="typing-paragraph-form-group">
        <label className="typing-paragraph-label" htmlFor="title-typing">Title</label>
        <input id="title-typing" className="typing-paragraph-input" type="text" name="title" value={formData.title} onChange={handleChange} required />
      </div>
      <div className="typing-paragraph-form-group">
        <label className="typing-paragraph-label" htmlFor="paragraph-typing">Paragraph</label>
        <textarea id="paragraph-typing" className="typing-paragraph-textarea" name="paragraph" value={formData.paragraph} onChange={handleChange} required />
      </div>
      <div className="typing-paragraph-form-group">
        <label className="typing-paragraph-label" htmlFor="time-typing">Time</label>
        <input id="time-typing" className="typing-paragraph-input" type="number" name="time" value={formData.time} onChange={handleChange} required />
      </div>
      <div className="typing-paragraph-form-group">
        <label className="typing-paragraph-label" htmlFor="status-typing">Status</label>
        <input id="status-typing" className="typing-paragraph-input" type="text" name="status" value={formData.status} onChange={handleChange} required />
      </div>
      <button type="submit" className="typing-paragraph-button">Add Typing Paragraph</button>
    </form>
    </>
  );
};

export default AddTypingParagraph;
