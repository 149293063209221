// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.YT_VideoPlayer_videoContainer__\\+GBMH {
   background-color: rgb(0, 0, 0);
   height: 80vh;
 }
 
 .YT_VideoPlayer_videoContainer__\\+GBMH iframe {
   width: 100%;
   height: 100%;
 }
 
 .YT_VideoPlayer_ytp-chrome-top__rPLWW {
   display: none !important;
 }
 `, "",{"version":3,"sources":["webpack://./src/YOUTUBE VIDEO PLAYER/YT_VideoPlayer.module.css"],"names":[],"mappings":"AAAA;GACG,8BAA8B;GAC9B,YAAY;CACd;;CAEA;GACE,WAAW;GACX,YAAY;CACd;;CAEA;GACE,wBAAwB;CAC1B","sourcesContent":[".videoContainer {\n   background-color: rgb(0, 0, 0);\n   height: 80vh;\n }\n \n .videoContainer iframe {\n   width: 100%;\n   height: 100%;\n }\n \n .ytp-chrome-top {\n   display: none !important;\n }\n "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"videoContainer": `YT_VideoPlayer_videoContainer__+GBMH`,
	"ytp-chrome-top": `YT_VideoPlayer_ytp-chrome-top__rPLWW`
};
export default ___CSS_LOADER_EXPORT___;
