import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import styles from "./CourseWishlist.module.css";
import Swal from "sweetalert2";
import { useAuth } from "../../../Context/AuthContext";
import { useNavigate } from "react-router-dom";
import { AiFillLeftCircle, AiFillRightCircle } from "react-icons/ai";

function CourseWishlist() {
  const [courseDetails, setCourseDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { user, token } = useAuth();
  const userId = user?._id;
  const scrollRef = useRef(null); // Scroll reference

  useEffect(() => {
    const fetchData = async () => {
      if (!userId || !token) {
        setError("User authentication failed. Please log in again.");
        setLoading(false);
        return;
      }

      try {
        const response = await fetch(
          `https://auth.ssccglpinnacle.com/api/wishlist/${userId}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`Failed to fetch wishlist: ${response.statusText}`);
        }

        const data = await response.json();
        console.log("data", data);
        setCourseDetails(data);
        setLoading(false);
      } catch (error) {
        setError("Failed to load wishlist. Please try again later.");
        setLoading(false);
      }
    };

    if (userId && token) {
      fetchData();
    }
  }, [userId, token]);

  if (loading) {
    return (
      <div className={styles.loaderContainer}>
        <img src="/Bubble-Preloader.gif" alt="Loading..." />
      </div>
    );
  }

  const handleButtonClick = () => {
    navigate("/videos");
  };

  // Scroll function for horizontal scrolling
  const scroll = (distance) => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({
        left: distance,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      <div className={styles.cartContainer}>
        {courseDetails.length === 0 ? (
          <button
            className={styles.nocourses}
            onClick={handleButtonClick}
          >
            Browse courses now
          </button>
        ) : (
          <div className={styles["wishlist"]}>
            <div className={styles.forbutton_div}>
              <div className={styles["main-card-wrapper"]}>
                <div className={styles["card-wrapper"]}>
                  {courseDetails.length ? (
                    courseDetails.map((course) => (
                      <div
                        key={course._id}
                        className={styles["card"]}
                        onClick={() =>
                          navigate(`/course/description/${course._id}`)
                        }
                      >
                        <div className={styles.card_img_container}>
                          <div className={styles.card_img}>
                            <img
                              className={styles.imagecard1}
                              src={course.hindiCoverImage}
                              alt="Hindi cover"
                            />
                            <img
                              className={styles.imagecard2}
                              src={course.englishCoverImage}
                              alt="English cover"
                            />
                          </div>
                        </div>
                        <div className={styles["description-section"]}>
                          <div className={styles["title"]}>
                            {course.courseTitle}
                          </div>
                          <div className={styles["gst"]}>18% GST included</div>
                          <div className={styles["price-fprice-div"]}>
                            <div className={styles["price"]}>
                              ₹ {course.price}
                            </div>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <div className={styles["mrp"]}>
                              <s>₹{course.mrp}</s>
                            </div>
                            <div className={styles["savings"]}>
                              Save{" "}
                              {Math.round(
                                ((course.mrp - course.price) / course.mrp) * 100
                              )}
                              %
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className={styles.nocourses}>
                      <p>
                        <span>No courses available in your wishlist</span>
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default CourseWishlist;
