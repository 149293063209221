// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Page_reportAbuse-div__TUf2D {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
}

.Page_reportAbuse-Link__EF6pY {
  background-color: #020062;
  padding: 0.5rem;
  margin: 0.5rem;
  color: white;
  font-weight: 600;
  text-decoration: none;
}

.Page_reportAbuse-Link__EF6pY:hover {
  border-radius: 22px;
}
.Page_footer__KY\\+sl{
  z-index:1000;
  margin-top: 18rem;
}

@media (max-width: 546px) {
  .Page_main-body-course__powz4 {
    width: 100%;
    overflow: hidden;
  }
}
@media (min-width: 547px) and (max-width: 642px) {
  .Page_main-body-course__powz4 {
    width: 100%;
    overflow: hidden;
  }
}

@media (min-width: 643px) and (max-width: 768px) {
  .Page_main-body-course__powz4 {
    width: 100%;
    overflow: hidden;
  }
}

@media (min-width: 769px) and (max-width: 980px) {
  .Page_main-body-course__powz4 {
    width: 100%;
    overflow: hidden;
  }
}
@media (min-width: 981px) and (max-width: 1124px) {
  .Page_main-body-course__powz4 {
    width: 100%;
    /* overflow: hidden; */
  }
}
`, "",{"version":3,"sources":["webpack://./src/component/Page02/Page.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,qBAAqB;AACvB;;AAEA;EACE,yBAAyB;EACzB,eAAe;EACf,cAAc;EACd,YAAY;EACZ,gBAAgB;EAChB,qBAAqB;AACvB;;AAEA;EACE,mBAAmB;AACrB;AACA;EACE,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE;IACE,WAAW;IACX,gBAAgB;EAClB;AACF;AACA;EACE;IACE,WAAW;IACX,gBAAgB;EAClB;AACF;;AAEA;EACE;IACE,WAAW;IACX,gBAAgB;EAClB;AACF;;AAEA;EACE;IACE,WAAW;IACX,gBAAgB;EAClB;AACF;AACA;EACE;IACE,WAAW;IACX,sBAAsB;EACxB;AACF","sourcesContent":[".reportAbuse-div {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: left;\n}\n\n.reportAbuse-Link {\n  background-color: #020062;\n  padding: 0.5rem;\n  margin: 0.5rem;\n  color: white;\n  font-weight: 600;\n  text-decoration: none;\n}\n\n.reportAbuse-Link:hover {\n  border-radius: 22px;\n}\n.footer{\n  z-index:1000;\n  margin-top: 18rem;\n}\n\n@media (max-width: 546px) {\n  .main-body-course {\n    width: 100%;\n    overflow: hidden;\n  }\n}\n@media (min-width: 547px) and (max-width: 642px) {\n  .main-body-course {\n    width: 100%;\n    overflow: hidden;\n  }\n}\n\n@media (min-width: 643px) and (max-width: 768px) {\n  .main-body-course {\n    width: 100%;\n    overflow: hidden;\n  }\n}\n\n@media (min-width: 769px) and (max-width: 980px) {\n  .main-body-course {\n    width: 100%;\n    overflow: hidden;\n  }\n}\n@media (min-width: 981px) and (max-width: 1124px) {\n  .main-body-course {\n    width: 100%;\n    /* overflow: hidden; */\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"reportAbuse-div": `Page_reportAbuse-div__TUf2D`,
	"reportAbuse-Link": `Page_reportAbuse-Link__EF6pY`,
	"footer": `Page_footer__KY+sl`,
	"main-body-course": `Page_main-body-course__powz4`
};
export default ___CSS_LOADER_EXPORT___;
