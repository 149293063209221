import React, { useState } from "react";
import "./StarComponent.css";
import StarRating from "react-svg-star-rating";
import { useAuth } from "../../Context/AuthContext";

const StarComponent = ({ setRating, handleNext, purchasedBookId }) => {
  const [innerRadius, setInnerRadius] = useState(25);
  const [outerRadius, setOuterRadius] = useState(50);
  const { user } = useAuth();

  const handleStarClick = (selectedRating, starIndex) => {
    setRating(selectedRating);
    submitReview(user?._id, purchasedBookId, selectedRating);
  };

  const submitReview = async (userId, bookId, starRating) => {
    try {
      const response = await fetch("https://auth.ssccglpinnacle.com/api/submit-review-book", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId,
          bookId,
          starRating,
        }),
      });

      if (!response.ok) {
        throw new Error(`Failed to submit review. Status: ${response.status}`);
      }

      console.log("Review submitted successfully");
      handleNext(); // Call handleNext after successful review submission
    } catch (error) {
      console.error("Error submitting review:", error);
    }
  };

  return (
    <div className="star-review">
      <div className="Rate-this-item">
        <h5>How would you rate this item?</h5>
      </div>
      <div className="star-container">
        <StarRating
          size={60}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          handleOnClick={handleStarClick}
        />
      </div>
      <div className="dislike-like">
        <p>Dislike it</p>
        <p>Love it!</p>
      </div>
    </div>
  );
};

export default StarComponent;
