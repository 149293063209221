// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TroubleshootingHelp_unordered_list__IsAvz {
  border-bottom: rgba(0, 0, 0, 0.02);
  display: flex;
  justify-content: space-evenly;
  font-size: 14px;
}
.TroubleshootingHelp_header_img__xh2cq {
  width: 8rem;
  font-size: 10px;
  margin-left: 25%;
}
.TroubleshootingHelp_troubleshooting_heading__ezlTG {
  margin: auto;
  text-align: center;
  padding: 1%;
}
`, "",{"version":3,"sources":["webpack://./src/VIDEOPLAYER COMPONENTS/components/VideoPlayerPage/AccountPage/Messages/TroubleshootingHelp.module.css"],"names":[],"mappings":"AAAA;EACE,kCAAkC;EAClC,aAAa;EACb,6BAA6B;EAC7B,eAAe;AACjB;AACA;EACE,WAAW;EACX,eAAe;EACf,gBAAgB;AAClB;AACA;EACE,YAAY;EACZ,kBAAkB;EAClB,WAAW;AACb","sourcesContent":[".unordered_list {\n  border-bottom: rgba(0, 0, 0, 0.02);\n  display: flex;\n  justify-content: space-evenly;\n  font-size: 14px;\n}\n.header_img {\n  width: 8rem;\n  font-size: 10px;\n  margin-left: 25%;\n}\n.troubleshooting_heading {\n  margin: auto;\n  text-align: center;\n  padding: 1%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"unordered_list": `TroubleshootingHelp_unordered_list__IsAvz`,
	"header_img": `TroubleshootingHelp_header_img__xh2cq`,
	"troubleshooting_heading": `TroubleshootingHelp_troubleshooting_heading__ezlTG`
};
export default ___CSS_LOADER_EXPORT___;
