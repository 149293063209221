import React, { useEffect, useState } from 'react';
import { FaArrowRight } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import './Blog2.css'; // Ensure you have this CSS file

const Blog2 = ({ category }) => {
  const [blogs, setBlogs] = useState([]);
  const [scrollCount, setScrollCount] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    fetch('https://auth.ssccglpinnacle.com/api/blogs')
      .then(response => response.json())
      .then(data => {
        const filteredBlogs = data.filter(blog => blog.category === category);
        setBlogs(filteredBlogs);
      })
      .catch(error => console.error('There was an error fetching the blogs!', error));
  }, [category]);

  const handleScrollRight = () => {
    if (scrollCount < Math.ceil(blogs.length / 4) - 1) {
      setScrollCount(scrollCount + 1);
    } else {
      setScrollCount(0);
    }
  };

  const truncateText = (text, maxWords) => {
    const wordsArray = text.split(' ');
    if (wordsArray.length > maxWords) {
      return wordsArray.slice(0, maxWords).join(' ') + '...';
    }
    return text;
  };
  const handleCardClick = (id) => {
    navigate(`/bloglist1/${id}`);
  };

  const handleSeeAllClick = () => {
    navigate(`/blog/${category}`);
  };

  return (
    <>
      <div className='row3-blog'>
        <div className='row3-blog-first'>
          <h2 className='row2h2'>{category} Articles</h2>
          <p className='p-row2p' onClick={handleSeeAllClick}>See all {category} articles</p>
        </div>
        <div className="blog2-cards-wrapper">
          <div className='blog2-cards-blog'>
            <div className="blog2-cards" style={{ transform: `translateX(-${scrollCount * 30}%)` }}>
              {blogs.map(blog => (
                <div className="blog2-card" key={blog._id} onClick={() => handleCardClick(blog._id)}>
                  <h3>{truncateText(blog.heading, 11)}</h3>
                  <p>By {blog.author.name}</p>
                </div>
              ))}
            </div>
            <div className="blog2-scroll-button" onClick={handleScrollRight}>
              <FaArrowRight />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Blog2;
