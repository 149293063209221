import React, { useEffect, useState } from 'react';
import { FaArrowRight } from 'react-icons/fa';
import './Blog2.css'; // Ensure you have this CSS file

const Blog4 = () => {
  
  return (
    <>
    <div className='module-block'>
    <h2 class="module-block-title">Find the perfect module for you</h2>
    <p>Choose from over 150,000 online video courses, ebooks, books, test pass with new additions published every month</p>
    <button className='btnnn-for-block'>Join us</button>
    </div>
    </>
  );
};

export default Blog4;
