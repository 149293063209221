import React, { useState, useEffect } from "react"
import FeedbackPortal from "../Rating Component/FeedBackPortal"
import StarRating from "../Rating Component/StarRating"
import axios from "axios"

const Loading = ({ videoProgress, userId, courseId }) => {
  const [percentage, setPercentage] = useState(0)
  const [showFeedback, setShowFeedback] = useState(false)
  const [feedbackData, setFeedbackData] = useState({ rating: 0, feedback: "" })

  useEffect(() => {
    // Fetch the existing feedback data for the user and course
    const fetchFeedbackData = async () => {
      try {
        const response = await axios.get(`https://auth.ssccglpinnacle.com/api/get-feedback`, {
          params: { userId, courseId }
        });
        if (response.data) {
          setFeedbackData({ rating: response.data.rating, feedback: response.data.feedback });
        } else {
          // No feedback found, do nothing or set default feedback state
          setFeedbackData({ rating: 0, feedback: "" });
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          // Handle 404 (Not Found) specifically
          console.warn("No feedback found for this course and user.");
          setFeedbackData({ rating: 0, feedback: "" });
        } else {
          console.error("Error fetching feedback:", error);
          setFeedbackData({ rating: 0, feedback: "" }); 
        }
      }
    };
    fetchFeedbackData();
  }, [userId, courseId]);

  const handleFeedbackSubmit = (rating, feedback) => {
    setFeedbackData({ rating, feedback })
  }

  const handleOpenFeedback = () => {
    setShowFeedback(true)
  }

  const handleCloseFeedback = () => {
    setShowFeedback(false)
  }

  const containerStyles = {
    height: "5px",
    width: "100%",
    backgroundColor: "#d1d7dc",
    borderRadius: "square",
    alignItems: "center",
    display: "flex",
  }

  const fillerStyles = {
    height: "5px",
    width: `${percentage}%`,
    backgroundColor: "#5624d0",
    borderRadius: "square",
    textAlign: "right",
    display: "block",
  }

  const labelStyles = {
    margin: "0",
    width: "10%",
    position: "absolute",
    fontSize: "12px",
  }

  useEffect(() => {
    setPercentage(videoProgress)
  }, [videoProgress])

  return (
    <>
      <div>
        {/* Progress bar component */}
        <StarRating
          onClick={handleOpenFeedback}
          filledStars={feedbackData.rating}
        />
        <FeedbackPortal
          isOpen={showFeedback}
          onClose={handleCloseFeedback}
          onFeedbackSubmit={handleFeedbackSubmit}
          userId={userId}
          courseId={courseId}
        />
      </div>
    </>
  )
}

export default Loading
