// Cartheader.js
import { BsCart2, BsList } from "react-icons/bs";
import { FaListUl } from "react-icons/fa6";
import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import './Cartheader.css';
import Searchcart from "./Searchcart";
import lightImage  from "../DigitalcontentHeader/images/pinnaclelogo.png";
import { Link, useNavigate } from 'react-router-dom';
import { BsTrophy } from "react-icons/bs";
import { useAuth } from "../../Context/AuthContext";

const Cartheader = () => {
  const [isNav2Visible, setNav2Visible] = useState(true); // Initially visible
  const [isScrolled, setScrolled] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [subtotal, setSubtotal] = useState(0);
  const { isLoggedIn, user } = useAuth();
  const navigate = useNavigate();


  useEffect(() => {
    const userId = user?.id;
    const useremail = user?.email_id;
    const token = Cookies.get('token');

    if (userId) {
      fetch(`https://auth.ssccglpinnacle.com/api/cart-ebook/${useremail}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      })
        .then((response) => response.json())
        .then((data) => {
          setCartItems(data);
        })
        .catch((error) => console.error("Error fetching cart data:", error));
    }
  }, [user]);

  const toggleNav2 = () => {
    setNav2Visible(!isNav2Visible);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;

      if (scrollY > 20) {
        setScrolled(true);
        setNav2Visible(false);
      } else {
        setScrolled(false);
        setNav2Visible(true);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const info = () => {
    navigate("/digital-catalog");
  };
  const info2 = () => {
    navigate("/cart");
   
  };
  const info3 = () => {
    navigate("/videos");
   
  };
  const info4 = () => {
    navigate("/books-catalog");
  };
  const type = () => {
    navigate("/typing");
  };



  return (
    <div className={`cartheader ${isScrolled ? 'scrolled' : ''}`}>
        <div className="nav-icon" onClick={toggleNav2}>
        <FaListUl size={28}/>
      </div>
     <div className="headerimage-wishlist">
           <img src={lightImage} alt="Component Image" />
           </div>
      <div className={`nav2 ${isNav2Visible ? '' : 'nav2-hidden'}`}>
        <div className = "element-of-the-cart-header" onClick={info4}><div className ="books-cart-header">Books</div></div>
        <div className = "element-of-the-cart-header" onClick={info3}><div className ="books-cart-header">Videos</div></div>
        <div className = "element-of-the-cart-header" onClick={info}><div className ="books-cart-header">Digital ebooks</div></div>
        <div className = "element-of-the-cart-header"><div className ="books-cart-header">Test pass</div></div>
        <div className = "element-of-the-cart-header"><div className ="books-cart-header">Railway kit</div></div>
        <div className = "element-of-the-cart-header"onClick={type}><div className ="books-cart-header">Typing</div></div>
      </div>
     <Searchcart/>
     <div className="cart-icon-header-get-started" onClick={info}>
        <BsTrophy className="cart-on-header-get-started" size={28} />
       
        <p className="get-started">Get started</p>
      </div>
      <div className="cart-icon-header-wish"onClick={info2}>
            <BsCart2 className="cart-on-header-wish" size={28} />
            <div className="circle-wish">{cartItems.length}</div>
          </div>
    </div>
  );
};

export default Cartheader;
