import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import styles from './AddExamForm.module.css';
import VideoContentEditor from './VideoContentEditor';

export default function AddExamForm({ examData = null, onSave = null }) {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(examData ? examData.categoryId : '');
  const [examName, setExamName] = useState(examData ? examData.examName : '');
  const [examDescription, setExamDescription] = useState(examData ? examData.examDescription : ''); // New state for exam description

  useEffect(() => {
    axios.get('https://auth.ssccglpinnacle.com/categories')
      .then(response => setCategories(response.data))
      .catch(error => console.error('Error fetching categories:', error));
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (examData) {
        await axios.put(`https://auth.ssccglpinnacle.com/exams/${examData._id}`, {
          categoryId: selectedCategory,
          examName,
          examDescription, // Save exam description
        });
        Swal.fire('Success!', 'Exam updated successfully', 'success');
        if (onSave) onSave();
      } else {
        await axios.post('https://auth.ssccglpinnacle.com/exams/add', {
          categoryId: selectedCategory,
          examName,
          examDescription, // Save exam description
        });
        Swal.fire('Success!', 'Exam added successfully', 'success');
      }

      // Clear form fields after successful submission
      setSelectedCategory('');
      setExamName('');
      setExamDescription('');

    } catch (error) {
      console.error('Error adding/updating exam:', error);
    }
  };

  return (
    <div className={styles['form-container']}>
      <h2 className={styles['addexam-heading']}>{examData ? 'Edit Exam' : 'Add New Exam'}</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label className={styles['addexam-label']}>Category:</label>
          <select value={selectedCategory} onChange={(e) => setSelectedCategory(e.target.value)} className={styles['addexam-select']} required>
            <option value="">Select Category</option>
            {categories.map(category => (
              <option key={category._id} value={category._id}>
                {category.categoryTitle}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label className={styles['addexam-label']}>Exam Name:</label>
          <input type="text" value={examName} onChange={(e) => setExamName(e.target.value)} className={styles['addexam-input']} required />
        </div>

        {/* Add VideoContentEditor for exam description */}
        <div>
          <label className={styles['addexam-label']}>Exam Description:</label>
          <VideoContentEditor
            content={examDescription}
            onContentChange={setExamDescription} // Update state with the new content
          />
        </div>

        <button type="submit" className={styles['addexam-submit-btn']}>{examData ? 'Update Exam' : 'Add Exam'}</button>
      </form>
    </div>
  );
}


// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import styles from './AddExamForm.module.css'; // Import the CSS module
// import Swal from 'sweetalert2';

// export default function AddExamForm({ examData = null, onSave = null }) {
//   const [categories, setCategories] = useState([]);
//   const [selectedCategory, setSelectedCategory] = useState(examData ? examData.categoryId : '');
//   const [examName, setExamName] = useState(examData ? examData.examName : '');
//   const [numberOfPosts, setNumberOfPosts] = useState(examData ? examData.numberOfPosts : 1);
//   const [examDates, setExamDates] = useState(examData ? examData.examDates : '');
//   const [eligibilityCriteria, setEligibilityCriteria] = useState(examData ? examData.eligibilityCriteria : '');
//   const [applicationProcess, setApplicationProcess] = useState(examData ? examData.applicationProcess : '');
//   const [salaryStructure, setSalaryStructure] = useState(examData ? examData.salaryStructure : '');
//   const [preparationTips, setPreparationTips] = useState(examData ? examData.preparationTips : '');
//   const [whyApply, setWhyApply] = useState(examData ? examData.whyApply : '');
//   const [admitCardResult, setAdmitCardResult] = useState(examData ? examData.admitCardResult : '');
//   const [conclusion, setConclusion] = useState(examData ? examData.conclusion : '');

//   useEffect(() => {
//     axios.get('https://auth.ssccglpinnacle.com/categories')
//       .then(response => setCategories(response.data))
//       .catch(error => console.error('Error fetching categories:', error));
//   }, []);

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       if (examData) {
//         await axios.put(`https://auth.ssccglpinnacle.com/exams/${examData._id}`, {
//           categoryId: selectedCategory,
//           examName,
//           numberOfPosts,
//           examDates,
//           eligibilityCriteria,
//           applicationProcess,
//           salaryStructure,
//           preparationTips,
//           whyApply,
//           admitCardResult,
//           conclusion,
//         });
//         Swal.fire('Success!', 'Exam updated successfully', 'success');
//         if (onSave) onSave();
//       } else {
//         await axios.post('https://auth.ssccglpinnacle.com/exams/add', {
//           categoryId: selectedCategory,
//           examName,
//           numberOfPosts,
//           examDates,
//           eligibilityCriteria,
//           applicationProcess,
//           salaryStructure,
//           preparationTips,
//           whyApply,
//           admitCardResult,
//           conclusion,
//         });
//         Swal.fire('Success!', 'Exam added successfully', 'success');
//       }

//       // Clear form fields after successful submission
//       setSelectedCategory('');
//       setExamName('');
//       setNumberOfPosts(1);
//       setExamDates('');
//       setEligibilityCriteria('');
//       setApplicationProcess('');
//       setSalaryStructure('');
//       setPreparationTips('');
//       setWhyApply('');
//       setAdmitCardResult('');
//       setConclusion('');

//     } catch (error) {
//       console.error('Error adding/updating exam:', error);
//     }
//   };

//   return (
//     <div className={styles['form-container']}>
//       <h2 className={styles['addexam-heading']}>{examData ? 'Edit Exam' : 'Add New Exam'}</h2>
//       <form onSubmit={handleSubmit}>
//         <div>
//           <label className={styles['addexam-label']}>Category:</label>
//           <select value={selectedCategory} onChange={(e) => setSelectedCategory(e.target.value)} className={styles['addexam-select']} required>
//             <option value="">Select Category</option>
//             {categories.map(category => (
//               <option key={category._id} value={category._id}>
//                 {category.categoryTitle}
//               </option>
//             ))}
//           </select>
//         </div>

//         <div>
//           <label className={styles['addexam-label']}>Exam Name:</label>
//           <input type="text" value={examName} onChange={(e) => setExamName(e.target.value)} className={styles['addexam-input']} required />
//         </div>

//         <div>
//           <label className={styles['addexam-label']}>Number of Posts:</label>
//           <input
//             type="number"
//             value={numberOfPosts}
//             onChange={(e) => setNumberOfPosts(e.target.value)}
//             className={styles['addexam-input']}
//             min="1"
//             required
//           />
//         </div>

//         <div>
//           <label className={styles['addexam-label']}>Exam Dates:</label>
//           <input type="text" value={examDates} onChange={(e) => setExamDates(e.target.value)} className={styles['addexam-input']} required />
//         </div>

//         <div>
//           <label className={styles['addexam-label']}>Eligibility Criteria:</label>
//           <textarea value={eligibilityCriteria} onChange={(e) => setEligibilityCriteria(e.target.value)} className={styles['addexam-textarea']} required />
//         </div>

//         <div>
//           <label className={styles['addexam-label']}>Application Process:</label>
//           <textarea value={applicationProcess} onChange={(e) => setApplicationProcess(e.target.value)} className={styles['addexam-textarea']} required />
//         </div>

//         <div>
//           <label className={styles['addexam-label']}>Salary Structure:</label>
//           <textarea value={salaryStructure} onChange={(e) => setSalaryStructure(e.target.value)} className={styles['addexam-textarea']} required />
//         </div>

//         <div>
//           <label className={styles['addexam-label']}>Preparation Tips:</label>
//           <textarea value={preparationTips} onChange={(e) => setPreparationTips(e.target.value)} className={styles['addexam-textarea']} required />
//         </div>

//         <div>
//           <label className={styles['addexam-label']}>Why Apply for this Exam:</label>
//           <textarea value={whyApply} onChange={(e) => setWhyApply(e.target.value)} className={styles['addexam-textarea']} required />
//         </div>

//         <div>
//           <label className={styles['addexam-label']}>Admit Card and Result:</label>
//           <textarea value={admitCardResult} onChange={(e) => setAdmitCardResult(e.target.value)} className={styles['addexam-textarea']} required />
//         </div>

//         <div>
//           <label className={styles['addexam-label']}>Conclusion:</label>
//           <textarea value={conclusion} onChange={(e) => setConclusion(e.target.value)} className={styles['addexam-textarea']} required />
//         </div>

//         <button type="submit" className={styles['addexam-submit-btn']}>{examData ? 'Update Exam' : 'Add Exam'}</button>
//       </form>
//     </div>
//   );
// }
