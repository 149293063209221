import React, { useState, useEffect, useMemo } from "react";
import Axios from "axios";
import { useNavigate } from "react-router-dom";
import { MaterialReactTable } from "material-react-table";
import { Button, Modal, Box, Typography, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import "./EditedBlog.css";

function EditedBlog() {
  const [data, setData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(""); 
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedBlog, setSelectedBlog] = useState(null);
  const navigate = useNavigate(); 

  useEffect(() => {
    Axios.get("https://auth.ssccglpinnacle.com/api/blogs").then((response) => {
      setData(response.data);
      const uniqueCategories = [...new Set(response.data.map((blog) => blog.category))];
      setCategories(uniqueCategories);
    });
  }, []);

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handleEditClick = (blog) => {
    setSelectedBlog(blog);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedBlog(null);
  };

  const filteredData = useMemo(() => {
    return selectedCategory
      ? data.filter((blog) => blog.category === selectedCategory)
      : data;
  }, [data, selectedCategory]);

  const columns = useMemo(() => [
    { accessorKey: 'author.name', header: 'Author Name' },
    { accessorKey: 'heading', header: 'Heading' },
    { accessorKey: 'category', header: 'Category' },
    {
      id: 'edit',
      header: 'Edit',
      Cell: ({ row }) => (
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleEditClick(row.original)}
        >
          Edit
        </Button>
      ),
    },
  ], []);

  return (
    <div className="edit-blog-container">
      <h5 className="edit-blog-title">Edit Blog</h5>
      <div className="category-select-container">
        <label htmlFor="categoryDropdown" className="category-label"><strong>Category : - </strong></label>
        <select
          id="categoryDropdown"
          className="category-dropdown"
          value={selectedCategory}
          onChange={handleCategoryChange}
        >
          <option value="">Select a category</option>
          {categories.map((category, index) => (
            <option key={index} value={category}>
              {category}
            </option>
          ))}
        </select>
      </div>

      <div className="blog-table-container">
        {filteredData.length > 0 ? (
          <MaterialReactTable
            columns={columns}
            data={filteredData}
          />
        ) : (
          <p className="no-blogs-message">No blogs available in this category.</p>
        )}
      </div>

      <Modal
        open={isModalOpen}
        onClose={closeModal}
        aria-labelledby="edit-blog-modal-title"
        aria-describedby="edit-blog-modal-description"
      >
        <Box className="modal-box">
          <IconButton
            aria-label="close"
            onClick={closeModal}
            className="modal-close-button"
          >
            <CloseIcon />
          </IconButton>
          <Typography id="edit-blog-modal-title" variant="h6" component="h2">
            Edit Blog
          </Typography>
          {selectedBlog && (
            <div>
              <p><strong>Author:</strong> {selectedBlog.author.name}</p>
              <p><strong>Heading:</strong> {selectedBlog.heading}</p>
              <p><strong>Category:</strong> {selectedBlog.category}</p>
              {/* Add more fields as needed */}
              <Button
                variant="contained"
                color="primary"
                onClick={() => navigate("/blogform", { state: { blogData: selectedBlog } })}
              >
                Edit in Form
              </Button>
            </div>
          )}
        </Box>
      </Modal>
    </div>
  );
}

export default EditedBlog;
