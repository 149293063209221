

import React, { useState, useEffect } from 'react';
import { MaterialReactTable } from 'material-react-table';
import Select from 'react-select';
import PdfViewer from './ChapterEdit/PdfViewer';
import { Button, Modal, Box } from '@mui/material';
import Swal from 'sweetalert2';
import './Multipdf.css';

const MultiPDFUploadForm = () => {
  const [sku, setSku] = useState('');
  const [ebooks, setEbooks] = useState([]);
  const [selectedEbook, setSelectedEbook] = useState(null); // Store selected ebook details
  const [chapters, setChapters] = useState([]);
  const [pdfFiles, setPdfFiles] = useState({}); // Store PDFs by chapter
  const [pdfData, setPdfData] = useState([]); // Store fetched main PDFs
  const [answerPdfData, setAnswerPdfData] = useState([]); // Store fetched answer PDFs
  const [solutionPdfData, setSolutionPdfData] = useState([]); // Store fetched solution PDFs
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false); // Modal state
  const [pdfToShow, setPdfToShow] = useState(null); // Selected PDF to show

  useEffect(() => {
    const fetchEbooks = async () => {
      try {
        const response = await fetch('https://auth.ssccglpinnacle.com/api/ebooks');
        const data = await response.json();
        setEbooks(data);
      } catch (error) {
        console.error('Error fetching ebooks:', error);
      }
    };
    fetchEbooks();
  }, []);

  const handleSkuChange = async (selectedEbook) => {
    setSku(selectedEbook.sku);
    setSelectedEbook(selectedEbook); // Store selected ebook details
    setLoading(true);
    try {
      const response = await fetch(`https://auth.ssccglpinnacle.com/api/chapters-ebook-admin/${selectedEbook._id}`);
      const chaptersData = await response.json();
      setChapters(chaptersData);
      await fetchAllPDFsForChapters(chaptersData); // Fetch all PDFs for the selected SKU's chapters
    } catch (error) {
      console.error('Error fetching chapters:', error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch all PDFs (main, answer, solution) for each chapter
  const fetchAllPDFsForChapters = async (chapters) => {
    try {
      const pdfPromises = chapters.map((chapter) =>
        fetch(`https://auth.ssccglpinnacle.com/api/pdfs-ebook/${chapter._id}`)
      );
      const answerPdfPromises = chapters.map((chapter) =>
        fetch(`https://auth.ssccglpinnacle.com/api/answer-ebook/${chapter._id}`)
      );
      const solutionPdfPromises = chapters.map((chapter) =>
        fetch(`https://auth.ssccglpinnacle.com/api/solution-ebook/${chapter._id}`)
      );

      const pdfResponses = await Promise.all(pdfPromises);
      const answerPdfResponses = await Promise.all(answerPdfPromises);
      const solutionPdfResponses = await Promise.all(solutionPdfPromises);

      const pdfDataArray = await Promise.all(pdfResponses.map((res) => res.json()));
      const answerPdfDataArray = await Promise.all(answerPdfResponses.map((res) => res.json()));
      const solutionPdfDataArray = await Promise.all(solutionPdfResponses.map((res) => res.json()));

      const pdfsWithChapters = pdfDataArray.map((pdfArray, index) => ({
        chapterId: chapters[index]._id,
        pdfs: pdfArray,
      }));

      const answerPdfsWithChapters = answerPdfDataArray.map((pdfArray, index) => ({
        chapterId: chapters[index]._id,
        pdfs: pdfArray,
      }));

      const solutionPdfsWithChapters = solutionPdfDataArray.map((pdfArray, index) => ({
        chapterId: chapters[index]._id,
        pdfs: pdfArray,
      }));

      setPdfData(pdfsWithChapters);
      setAnswerPdfData(answerPdfsWithChapters);
      setSolutionPdfData(solutionPdfsWithChapters);
    } catch (error) {
      console.error('Error fetching PDFs:', error);
    }
  };

  const handleFileChange = (e, chapterId, type) => {
    setPdfFiles((prevFiles) => ({
      ...prevFiles,
      [chapterId]: {
        ...prevFiles[chapterId],
        [type]: e.target.files[0],
      },
    }));
  };

  const handleSubmit = async () => {
    try {
      const formData = new FormData();
      
      // Loop through each chapter and append files to FormData
      chapters.forEach((chapter) => {
        if (pdfFiles[chapter._id]) {
          formData.append('chapterIds[]', chapter._id);

          if (pdfFiles[chapter._id].main) {
            formData.append('mainPdf', pdfFiles[chapter._id].main);
          }
          if (pdfFiles[chapter._id].answer) {
            formData.append('answerPdf', pdfFiles[chapter._id].answer);
          }
          if (pdfFiles[chapter._id].solution) {
            formData.append('solutionPdf', pdfFiles[chapter._id].solution);
          }
        }
      });

      const response = await fetch('https://auth.ssccglpinnacle.com/api/uploadMultipleChaptersPdfs', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        Swal.fire({
          icon: 'success',
          title: 'Upload Successful!',
          text: 'All PDFs have been uploaded successfully.',
        });
        setPdfFiles({}); // Clear the uploaded files
        fetchAllPDFsForChapters(chapters); // Refetch the PDFs to update the list
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Upload Failed',
          text: 'Failed to upload PDFs. Please try again.',
        });
      }
    } catch (error) {
      console.error('Error submitting PDFs:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An unexpected error occurred. Please try again later.',
      });
    }
  };

  const handleOpenModal = (pdfKey) => {
    setPdfToShow([{ s3Key: pdfKey }]); // Set the PDF to show in the modal
    setOpenModal(true); // Open the modal
  };

  const handleCloseModal = () => {
    setOpenModal(false); // Close the modal
    setPdfToShow(null); // Reset the PDF to show
  };

  return (
    <div className="multi-pdf-upload-container">
      <h1 className="multi-pdf-upload-heading">Multi PDF Upload</h1>
      <p className="multi-pdf-upload-instructions">Select and upload all 3 PDFs (Main, Answer, and Solution) for one chapter at a time.</p>
      <Select
        className="select-control"
        value={{ label: sku, value: sku }}
        onChange={(option) => handleSkuChange(ebooks.find((ebook) => ebook.sku === option.value))}
        options={ebooks.slice().reverse().map((ebook) => ({ label: ebook.sku, value: ebook.sku }))}
        placeholder="Select SKU"
      />

      {selectedEbook && ( // Display SKU details
        <div className="pdfupdate-info-card">
          <div className="pdfupdate-info-item">
            <strong>Title:</strong> {selectedEbook.title}
          </div>
          <div className="pdfupdate-info-item">
            <strong>Edition:</strong> {selectedEbook.edition}
          </div>
          <div className="pdfupdate-info-item">
            <strong>Medium:</strong> {selectedEbook.medium}
          </div>
        </div>
      )}

      {sku && (
        <MaterialReactTable
          columns={[
            { accessorKey: 'title', header: 'Chapter Title' },
            {
              accessorKey: 'main',
              header: 'Main PDF',
              Cell: ({ row }) => {
                const chapterId = row.original._id;
                const uploadedPdf = pdfData.find((data) => data.chapterId === chapterId)?.pdfs[0];
                return (
                  <>
                    {uploadedPdf ? (
                      <div
                        className="pdf-link"
                        onClick={() => handleOpenModal(uploadedPdf.s3Key)}
                      >
                        {uploadedPdf.filename}
                      </div>
                    ) : (
                      <input
                        className="file-input"
                        type="file"
                        accept=".pdf"
                        onChange={(e) => handleFileChange(e, chapterId, 'main')}
                      />
                    )}
                  </>
                );
              },
            },
            {
              accessorKey: 'answer',
              header: 'Answer PDF',
              Cell: ({ row }) => {
                const chapterId = row.original._id;
                const uploadedPdf = answerPdfData.find((data) => data.chapterId === chapterId)?.pdfs[0];
                return (
                  <>
                    {uploadedPdf ? (
                      <div
                        className="pdf-link"
                        onClick={() => handleOpenModal(uploadedPdf.s3Key)}
                      >
                        {uploadedPdf.filename}
                      </div>
                    ) : (
                      <input
                        className="file-input"
                        type="file"
                        accept=".pdf"
                        onChange={(e) => handleFileChange(e, chapterId, 'answer')}
                      />
                    )}
                  </>
                );
              },
            },
            {
              accessorKey: 'solution',
              header: 'Solution PDF',
              Cell: ({ row }) => {
                const chapterId = row.original._id;
                const uploadedPdf = solutionPdfData.find((data) => data.chapterId === chapterId)?.pdfs[0];
                return (
                  <>
                    {uploadedPdf ? (
                      <div
                        className="pdf-link"
                        onClick={() => handleOpenModal(uploadedPdf.s3Key)}
                      >
                        {uploadedPdf.filename}
                      </div>
                    ) : (
                      <input
                        className="file-input"
                        type="file"
                        accept=".pdf"
                        onChange={(e) => handleFileChange(e, chapterId, 'solution')}
                      />
                    )}
                  </>
                );
              },
            },
          ]}
          data={chapters}
          enableRowSelection={false}
          enableBottomToolbar={true} // Ensure bottom toolbar is enabled for pagination
          enablePagination={true}
          initialState={{ pagination: { pageSize: 200, pageIndex: 0 } }} 
        />
      )}

      <Button className="upload-button" variant="contained" color="primary" onClick={handleSubmit} disabled={loading}>
        Submit All PDFs
      </Button>

      {/* Modal for PDF Viewer */}
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: 'relative',
            width: '80%',
            height: '80%',
            margin: 'auto',
            marginTop: '5%',
            backgroundColor: 'white',
            padding: '20px',
            overflowY: 'auto',
            maxHeight: '100%',
          }}
        >
          {/* Close button (X) */}
          <button
            onClick={handleCloseModal}
            style={{
              position: 'absolute',
              top: 10,
              right: 10,
              background: 'transparent',
              border: 'none',
              fontSize: '24px',
              cursor: 'pointer',
            }}
          >
            ✖
          </button>

          {/* PDF Viewer Component */}
          {pdfToShow && <PdfViewer pdfData={pdfToShow} />}
        </Box>
      </Modal>
    </div>
  );
};

export default MultiPDFUploadForm;
