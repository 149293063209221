import React, { useState } from 'react';
import './BulkTypingAccessForm.css';

const BulkTypingAccessForm = () => {
  const [emailIds, setEmailIds] = useState('');
  const [productId, setProductId] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');

  const handleEmailInputChange = (event) => {
    setEmailIds(event.target.value);
  };

  const handleProductIdChange = (event) => {
    setProductId(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const emailArray = emailIds
      .split('\n')
      .map((email) => email.trim())
      .filter((email) => email);

    if (emailArray.length > 20000) {
      setError('You can only submit up to 20,000 emails at a time.');
      return;
    }

    try {
      setMessage('Processing... This may take a while for large email lists.');
      const response = await fetch('https://auth.ssccglpinnacle.com/api/process-bulk-typing-access', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ emailIds: emailArray, product_id: productId }),
      });

      const data = await response.json();

      if (response.ok) {
        setMessage('Typing access granted successfully for all emails.');
        setError('');
        setEmailIds('');
        setProductId('');
      } else {
        setError(data.message || 'Error processing typing access.');
        setMessage('');
      }
    } catch (error) {
      setError('Error processing typing access.');
      console.error('Error processing typing access:', error);
    }
  };

  return (
    <div className="bulk-typing-access-form">
      <h2>Student Typing Access Form</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Email IDs (each on a new line, up to 20,000 emails):</label>
          <textarea
            value={emailIds}
            onChange={handleEmailInputChange}
            rows={15}
            required
            placeholder="Enter up to 20,000 email addresses, each on a new line"
          />
        </div>
        <div>
          <label>Product ID:</label>
          <input
            type="text"
            value={productId}
            onChange={handleProductIdChange}
            required
            placeholder="Enter the product ID for typing access"
          />
        </div>
        <button type="submit">Submit</button>
      </form>
      {error && <p className="error">{error}</p>}
      {message && <p className="success">{message}</p>}
    </div>
  );
};

export default BulkTypingAccessForm;
