import React, { useState, useContext } from "react";
import { AiFillCaretDown, AiFillCaretRight, AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { CSSTransition } from "react-transition-group";
import { useNavigate } from "react-router-dom";
import "./sidebar.css";
import { ThemeContext } from "../../Context/ThemeContext";
import LoadingSpinner from "../../LoadingSpinner";

const Sidebar1 = ({ chapterData, cardId }) => {
  const [h2Color, setH2Color] = useState("");
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const { theme } = useContext(ThemeContext);
  const navigate = useNavigate();

  const handleClick2 = () => {
    navigate(`/ebook/${cardId}/digitalPage1`);
  };

  if (!chapterData || chapterData.length === 0) {
    return <LoadingSpinner />;
  }

  const handleClick = () => {
    navigate("/digitalcontent");
    const newColor = h2Color === "white" ? "red" : "white";
    setH2Color(newColor);
  };

  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };

  const chaptersForEbook = chapterData.filter(chapter => chapter.ebook._id === cardId);
  const sortedChapters = chaptersForEbook.sort((a, b) => a.sequence - b.sequence);


  return (
    <div className={`theme2 ${theme === "dark" ? "dark-theme" : "light-theme"}`}>
      <div className="hamburger-menu" onClick={toggleSidebar}>
        {isSidebarVisible ? <AiOutlineClose size={24} /> : <AiOutlineMenu size={24} />}
      </div>
      <div className={`sidebar4 ${isSidebarVisible ? "visible" : ""}`}>
        <div className="for-sidebar1">
          <div
            className="home-for-sidebar"
            style={{ backgroundColor: h2Color }}
            onClick={handleClick}
          >
            Home
          </div>
          <div className="sidebar">
            {sortedChapters.map((chapter, headingIndex) => (
              <div className="heading" key={headingIndex}>
                <div className="m-header">
                  <h3 onClick={handleClick2}>{chapter.title}</h3>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar1;
