import React, { useState, useMemo } from 'react';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import './StudentAccessEbookForm.css'; // Make sure to import the CSS file

const StudentAccessEbookForm = () => {
  const [emailId, setEmailId] = useState('');
  const [ebooks, setEbooks] = useState([]); // Initialize as an empty array
  const [selectedEbooks, setSelectedEbooks] = useState([]);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');

  const handleInputChange = (event) => {
    setEmailId(event.target.value);
  };

  const handleEbookSelection = (event, ebookId) => {
    setSelectedEbooks((prevSelected) =>
      prevSelected.includes(ebookId)
        ? prevSelected.filter((id) => id !== ebookId)
        : [...prevSelected, ebookId]
    );
  };

  const handleFetchEbooks = async () => {
    try {
      const response = await fetch('https://auth.ssccglpinnacle.com/api/ebooksforactive?active=true');
      const data = await response.json();

      if (response.ok) {
        setEbooks(data); // Set the array directly
        setError('');
      } else {
        setError(data.message || 'Error fetching ebooks');
        setEbooks([]); // Clear ebooks in case of an error
      }
    } catch (error) {
      setError('Error fetching ebooks');
      console.error('Error fetching ebooks:', error);
      setEbooks([]); // Clear ebooks in case of an error
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch('https://auth.ssccglpinnacle.com/api/process-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ emailId, selectedEbooks }),
      });

      const data = await response.json();

      if (response.ok) {
        setMessage('Ebook access successfully saved.');
        setError('');
        setEbooks([]);
        setSelectedEbooks([]);
        setEmailId('');
      } else {
        setError(data.message || 'Error processing ebook access.');
        setMessage('');
      }
    } catch (error) {
      setError('Error processing ebook access.');
      console.error('Error processing ebook access:', error);
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'title',
        header: 'Title',
      },
      {
        accessorKey: 'medium',
        header: 'Medium',
      },
      {
        accessorKey: 'edition',
        header: 'Edition',
      },
      {
        accessorKey: 'id',
        header: 'ID',
      },
      {
        id: 'select',
        header: 'Select',
        Cell: ({ row }) => (
          <input
            type="checkbox"
            value={row.original.id}
            checked={selectedEbooks.includes(row.original.id)}
            onChange={(e) => handleEbookSelection(e, row.original.id)}
          />
        ),
      },
    ],
    [selectedEbooks]
  );

  return (
    <> <h2 className="add-ebook-form">Student Access Ebook Form</h2>
    <div className="student-access-ebook">
     
      <form onSubmit={handleSubmit}>
        <div>
          <label>Email ID:</label>
          <input
            type="email"
            value={emailId}
            onChange={handleInputChange}
            required
          />
          <button className= "student-access-ebook-button" type="button" onClick={handleFetchEbooks}>
            Fetch Ebooks
          </button>
        </div>
        {ebooks && ebooks.length > 0 && (
          <MaterialReactTable
            columns={columns}
            data={ebooks}
            enableRowSelection={false}
            enableMultiRowSelection={false}
            getRowId={(row) => row._id}
          />
        )}
        <button classname="student-access-ebook-submit" type="submit">Submit</button>
      </form>
      {error && <p className="error">{error}</p>}
      {message && <p className="success">{message}</p>}
    </div>
    </>
  );
};

export default StudentAccessEbookForm;
