// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QandA_QandA-fullPage__IGrY5{
}

.QandA_QandA-empty-fullPage__fVVJy{
}
.QandA_HEADING__TrbYZ{
    font-size: 21px ;
    font-weight: bold;
    line-height:3rem;
    margin-left: 1rem;
}
.QandA_empty-body-div__4lRIE{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.QandA_qna-image__Jrvzn{
    width:250px;
    height:200px;
    border-radius:50%;
}
.QandA_qna-para__nN6gb{
    line-height: 30px;
}

.QandA_student-Question-div__RSJVX{
    /* border: 1px solid  red; */
    margin: 0.25rem;
    display: flex;
}



.QandA_Student-div__odvL3{
    /* border: 2px solid yellow; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.25rem;
    margin: 0.25rem;
}

.QandA_Question-div__T1xBD{
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 2px solid green; */
    
}

.QandA_Answer-div__-KY4y{
    /* border: 1px solid black; */
    margin: 0.25rem;
    display: flex;
}

.QandA_Student-img-div__-WLex{
    
}
.QandA_Student-img__ir8vn{
    width:50px;
    height:50px;
    padding: 0.5rem;
    border-radius: 50%;
    background-color:#e7f2f8;
}`, "",{"version":3,"sources":["webpack://./src/Profile/QandA.module.css"],"names":[],"mappings":"AAAA;AACA;;AAEA;AACA;AACA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,gBAAgB;IAChB,iBAAiB;AACrB;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;AAC3B;AACA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;AACrB;AACA;IACI,iBAAiB;AACrB;;AAEA;IACI,4BAA4B;IAC5B,eAAe;IACf,aAAa;AACjB;;;;AAIA;IACI,8BAA8B;IAC9B,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,6BAA6B;;AAEjC;;AAEA;IACI,6BAA6B;IAC7B,eAAe;IACf,aAAa;AACjB;;AAEA;;AAEA;AACA;IACI,UAAU;IACV,WAAW;IACX,eAAe;IACf,kBAAkB;IAClB,wBAAwB;AAC5B","sourcesContent":[".QandA-fullPage{\n}\n\n.QandA-empty-fullPage{\n}\n.HEADING{\n    font-size: 21px ;\n    font-weight: bold;\n    line-height:3rem;\n    margin-left: 1rem;\n}\n.empty-body-div{\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n}\n.qna-image{\n    width:250px;\n    height:200px;\n    border-radius:50%;\n}\n.qna-para{\n    line-height: 30px;\n}\n\n.student-Question-div{\n    /* border: 1px solid  red; */\n    margin: 0.25rem;\n    display: flex;\n}\n\n\n\n.Student-div{\n    /* border: 2px solid yellow; */\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    padding: 0.25rem;\n    margin: 0.25rem;\n}\n\n.Question-div{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    /* border: 2px solid green; */\n    \n}\n\n.Answer-div{\n    /* border: 1px solid black; */\n    margin: 0.25rem;\n    display: flex;\n}\n\n.Student-img-div{\n    \n}\n.Student-img{\n    width:50px;\n    height:50px;\n    padding: 0.5rem;\n    border-radius: 50%;\n    background-color:#e7f2f8;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"QandA-fullPage": `QandA_QandA-fullPage__IGrY5`,
	"QandA-empty-fullPage": `QandA_QandA-empty-fullPage__fVVJy`,
	"HEADING": `QandA_HEADING__TrbYZ`,
	"empty-body-div": `QandA_empty-body-div__4lRIE`,
	"qna-image": `QandA_qna-image__Jrvzn`,
	"qna-para": `QandA_qna-para__nN6gb`,
	"student-Question-div": `QandA_student-Question-div__RSJVX`,
	"Student-div": `QandA_Student-div__odvL3`,
	"Question-div": `QandA_Question-div__T1xBD`,
	"Answer-div": `QandA_Answer-div__-KY4y`,
	"Student-img-div": `QandA_Student-img-div__-WLex`,
	"Student-img": `QandA_Student-img__ir8vn`
};
export default ___CSS_LOADER_EXPORT___;
