import React, { useState, useEffect } from 'react';
import Navbar from "./NavbarBook";
import classes from "./Header.module.css";
import pic from "./images/pinnacleWhiteLogo.png";
import SearchBar from "./SearchBarBook";
import Navbar1 from "./Navbar2Book";
import { BsCart2 } from "react-icons/bs";
import { useAuth } from "../../Context/AuthContext";
import "./H.css";
import { useNavigate } from 'react-router-dom';
import { FaRegHeart } from "react-icons/fa";
import Swal from "sweetalert2";
import pic1 from "./images/gmail.svg";

function Header(props) {
  const [cartItems, setCartItems] = useState([]);
  const [wishlistItems, setWishlistItems] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const { isLoggedIn, user } = useAuth();
  const navigate = useNavigate();

  const [isAddingToCart, setIsAddingToCart] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  // console.log(wishlistItems);
  useEffect(() => {
    const userId = user?._id;
    if (userId) {
      fetch(`https://auth.ssccglpinnacle.com/api/cart-book/${userId}`)
        .then((response) => response.json())
        .then((data) => {
          setCartItems(data);
        })
        .catch((error) => console.error("Error fetching cart data:", error));
    }
  }, [user]);

  useEffect(() => {
    const fetchWishlist = async () => {
      try {
        if (!user || !user._id) {
          console.error('User is null or _id is undefined.');
          return;
        }

        const wishlistResponse = await fetch(`https://auth.ssccglpinnacle.com/api/get-wishlist-book/${user._id}`);
        const wishlistData = await wishlistResponse.json();
        const wishlistIds = wishlistData.wishlist;

        const ebookResponse = await fetch('https://auth.ssccglpinnacle.com/api/booksforactive');
        const ebookData = await ebookResponse.json();
        const wishlistItemsData = ebookData.filter(ebook => wishlistIds.includes(ebook._id));

        setWishlistItems(wishlistItemsData);
      } catch (error) {
        console.error('Error fetching wishlist:', error);
      }
    };

    fetchWishlist();
  }, [user]);

  const [isHovered, setIsHovered] = useState(false);
  
  const handleWishlistHover = () => {
    // This function is triggered on hover and sets the dropdown to be open
    setShowDropdown(true);
    setIsHovered(true);
  };
  
  const handleWishlistLeave = () => {
    // This function is triggered when the mouse leaves, closes the dropdown if not clicked
    if (!isClicked) {
      setShowDropdown(false);
      setIsHovered(false);
    }
  };
  
  const handleWishlistClick = () => {
    setShowDropdown(true); // Always set to true when clicked
    setIsClicked((prevIsClicked) => !prevIsClicked);
    setIsHovered(false); // Reset hover state when clicked
  };

  const info = () => {
    navigate('/cart-book');
  };

  const info2 = () => {
    navigate('/wish-list-book');
  };
  const info3 = () => {
    navigate('/books-catalog');
  };

  const handleAddToCart = async (bookId) => {
    try {
      // Check if the user is logged in
      if (!isLoggedIn || !user || !user._id) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Please log in to add items to your cart.",
        });
        return;
      }

      // Update UI to indicate loading
      setIsAddingToCart(true);

      // Send request to server to add to cart
      const response = await fetch(`https://auth.ssccglpinnacle.com/api/add-to-cart-book/${user._id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ bookId }),
      });

      if (response.ok) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Item added to cart successfully!",
        });
        // Optionally, update local state to reflect the change in the UI
      } else {
        const errorMessage = await response.json();
        console.error("Error adding to cart:", errorMessage);

        Swal.fire({
          icon: "error",
          title: "Error",
          text: `Failed to add item to cart. Error: ${errorMessage.error}`,
        });
      }
    } catch (error) {
      console.error("Error adding to cart:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to add item to cart. Please try again.",
      });
    } finally {
      // Reset loading state
      setIsAddingToCart(false);
    }

  };
  
  function truncateText(text, maxWords) {
    const words = text.split(' ');
  
    if (words.length > maxWords) {
      return words.slice(0, maxWords).join(' ') + '...';
    }
  
    return text;
  }

  return (
    <div className={classes.Headercontainer1book}>
      <header className={classes["main-headerBook"]}>
        <div className={classes.headerimagebook}>
          <a className={classes.videocourse} href="/">
            <img src={pic} />  
            <div className={classes["Support-email-book"]}>
            <img className={classes["Support-emailgmail"]} src={pic1} />
            <a href="https://mail.google.com/mail/?view=cm&fs=1&to=support@ssccglpinnacle.com" target="_blank" rel="noopener noreferrer">
  support@ssccglpinnacle.com
</a>
            </div>
          </a>
        </div>

        <SearchBar />

        {isLoggedIn && (
          <div
            className="cart-icon-header-main-book"
            onClick={info}
          >
            <BsCart2 className="cart-on-header-main-book"  />
            <div className="circle-main">{cartItems.length}</div>
          </div>
        )}

        {isLoggedIn && (
          <div
            className="cart-icon-header-main-heart-book"
            // onClick={info2}
            onMouseEnter={() => !isClicked && handleWishlistHover()} // Only trigger hover if not clicked
            onMouseLeave={handleWishlistLeave}
            onClick={handleWishlistClick}
        
          >
            <FaRegHeart className="cart-on-header-main-haeart"  />
            {showDropdown && (
  <div className={`wishlist-dropdown ${showDropdown ? "show" : ""}`}>
    <div className='wish-list-show-dropdown'>
      {wishlistItems && wishlistItems.length > 0 ? (
        wishlistItems.map((item) => (
          <div className='drop-wishlist' key={item._id}>
            <div className='for-drop-wishlist'>
              <div className="for-image-display">
                <div className='image-for-wish-drop'>
                  <img src={`http://13.200.156.92:5000/${item.image}`} alt="Selected" />
                </div>
              </div>
              <div className='for-price-drop-wish'>
                <div className="for-show-title-drop">{truncateText(`${item.title} ${item.edition} edition ${item.medium}`, 10)}</div>
                <div className="for-show-price-drop">₹ {item.bookSellingPrice}</div>
              </div>
            </div>
            <div
              className="add-to-cart-buy-modal-intro-wish"
              onClick={() => handleAddToCart(item._id)}
            >
              Add to cart
            </div>
          </div>
        ))
      ) : (
        <div className='empty-error-message'>
        <div className="wishlist-empty-message">
          Your wishlist is empty.
        </div>
        <div className="explore-empty-message"  onClick={info3}>
          Explore courses
        </div>
        </div>
      )}
      <div className='got-to-modal'>
        <div
          className="go-to-wishlist-modal-intro-wish"
          onClick={info2}
        >
          Go to wishlist
        </div>
      </div>
    </div>
  </div>
)}

          </div>
        )}

        <Navbar isLoggedIn={props.isAuthenticated} onLogout={props.onLogout} />

      </header>
      <Navbar1 />
    </div>
  );
}

export default Header;
