import React, { useState, useEffect } from 'react';
import "./Profile1.css"
import { RiPencilFill } from "react-icons/ri";
import { TiTick } from "react-icons/ti";
import Header from '../../component/DigitalcontentHeader/Header';

function Profile1() {
  const cartCount = 4;
  const [showResetModal, setShowResetModal] = useState(false);
  const [profilePicture, setProfilePicture] = useState(null);
  const [showPhotoOptions, setShowPhotoOptions] = useState(false);
   const [showPencil, setShowPencil] = useState(true);
     const [successMessage, setSuccessMessage] = useState(null);
  const [successMessage1, setSuccessMessage1] = useState(null);
  
    
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  
  const handleResetPasswordClick = () => {
    setShowResetModal(true);
  };
  

  const handleModalClose = () => {
    setShowResetModal(false);
  };

  const handlePencilClick = () => {
    setShowPhotoOptions(!showPhotoOptions);
  };

 
  const handleUploadPhoto = (event) => {
    const selectedFile = event.target.files[0];
    setProfilePicture(URL.createObjectURL(selectedFile));
    setShowPhotoOptions(false);
    setShowPencil(true);
    setSuccessMessage("Uploading profile picture");
    setSuccessMessage1(
"Successfully uploaded profile picture")
  };

 const handleRemovePhoto = () => {
  setProfilePicture(null);
  setShowPhotoOptions(false);

  // Set the first message
  setSuccessMessage("Deleting profile picture");

  // Set the second message immediately after the first
  setSuccessMessage1("Successfully removed profile picture");
};

    useEffect(() => {
    // Clean up success message after 3 seconds
    const timeoutId = setTimeout(() => {
      setSuccessMessage(null);
    }, 3000);

    return () => clearTimeout(timeoutId);
  }, [successMessage]);
    
  return (
    <>
   <Header/>
    <div className = "container-for-user-profile">

    <div className = "col1-profile">
<div className = "My-profile">

    </div>
    </div>
    
    
    
     <div className="col2-profile">
      <h1>My Profile</h1>
      <div className="col2-profile-form">
      {successMessage && (
        <div className="success-message">
        <div className = "for-sm">
        <div className="tick-icon">
                  <TiTick size={26} color="white" />
                </div>
          <p className = "sm">{successMessage}</p>
          </div>
           <p className = "sm1">{successMessage1}</p>
        </div>
      )}
        <div className="circle-for-profile">
          <div className="circle-for-profile1">
            <div className="col-profile">
           
           {profilePicture ? (
           <div className = "profile-pic-for-profile" >
                <div className="profile-picture-container">
                  <img
                    className="profile-picture"
                    src={profilePicture}
                    alt="Profile"
                  />
                </div>
                </div>
              ) : (
          
                <div className="no-profile-picture">
                  Edit profile Picture
                </div>
              )}
              {showPencil && (
                <div className="pencil-icon" onClick={handlePencilClick}>
                  <RiPencilFill className="pencil-icon-1" size={20} />
                </div>
              )}
              {showPhotoOptions && (
                <div className="photo-options">
                  <label htmlFor="uploadInput" className="upload-label">
                    Upload Photo
                  </label>
                  <input
                  type="file"
                  id="uploadInput"
                  accept="image/*"
                  onChange={handleUploadPhoto}
                  style={{ display: 'none' }}
                />
                  <div className="remove-photo" onClick={handleRemovePhoto}>
                    Remove Photo
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="username-for-profile"></div>
        </div>
      </div>
<div className = "col2-profile-form-userName">
        <h1>User Name</h1>
        <p className = "for-account-profile">For Account</p>
        <p className ="first-name">First Name</p>
        <input className = "inpur-first-name"
          type="text"
          id="text"/>
           <p className ="first-name">Last Name</p>
           <input className = "inpur-first-name"
          type="text"
          id="text"/>
        </div>
        <div className='col2-profile-form-login'>
        <h1>Login</h1>
        <p className = "for-account-profile">Your ssccglpinnacle.com Login Credentials</p>
        <p className ="first-name">Email</p>
        <input className = "inpur-first-name"
          type="email"
          id="email"
        />
         <p className ="first-name">Password</p>

         <input className = "inpur-first-name"
            type= 'password'
            id="password"
          />
        </div>
        <p className = "reset-password-profile"  onClick={handleResetPasswordClick}>Reset password</p>
        {showResetModal && (
          <div className="reset-password-modal">
            <div className="modal-content-profile">
              <span className="close-profile" onClick={handleModalClose}>
                &times;
              </span>
              <h2>Reset Password</h2>
              <p>Do you want to reset your password?</p>
              <div className="modal-buttons">
                <button className = "cancel-button"  onClick={handleModalClose}>Cancel</button>
                <button className = "blue-button" onClick={handleModalClose}>Ok</button>
              </div>
            </div>
          </div>
        )}
    
    </div>
    
    
    
    <div className = "col3-profile">
  
    </div>
    </div>
    </>
  )
}

export default Profile1