import React, { useState, useEffect } from 'react';
import PdfViewer from '../PdfViewer';
import A from './DigitalContentPages/A';

const PdfShowing = ({ chapterData, isFullScreen, toggleFullScreen }) => {
  const [pdfData, setPdfData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('http://localhost:6005/api/pdfs');
        const data = await response.json();
        // console.log(data);

        // Filter PDFs based on the current chapter's _id
        const filteredPdfData = data.filter((pdf) => pdf.chapter === chapterData._id);

        setPdfData(filteredPdfData);
      } catch (error) {
        console.error('Error fetching PDF data:', error);
      }
    };

    fetchData();
  }, [chapterData]);

  return (
    <div className="pdf-container">
      {pdfData &&
    pdfData.map((pdf, index) => {
      const cloudFrontUrl = `https://dzdx39zg243ni.cloudfront.net/${pdf.s3Key}`;
      return  <PdfViewer  key={index} url={cloudFrontUrl} />;;
    })}
    </div>
  );
};

export default PdfShowing;
