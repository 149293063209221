import React, { useState, useRef, useEffect  } from "react";
import "./Exercise.css";
import Header from '../../../component/DigitalcontentHeader/Header'; // Corrected path

import { Modal, Button } from "react-bootstrap";
import { CSSTransition } from "react-transition-group";
import Sidebar from "./Sidebarquiz";

const Exercise = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const [currentExercise, setCurrentExercise] = useState(null);
  const [userAnswer, setUserAnswer] = useState("");
  const [showAnswer, setShowAnswer] = useState(false);
  const [isAnswerCorrect, setIsAnswerCorrect] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [completedExercises, setCompletedExercises] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentSubject, setCurrentSubject] = useState(null);
  const exerciseRef = useRef(null);
  const [showAnswerPrev, setShowAnswerPrev] = useState(false);
  const [isAllExercisesCompleted, setIsAllExercisesCompleted] = useState(false);

  const subjects = [
    {
      id: 1,
      name: "Topic 1",
      exercises: [
        {
          id: 1,
          subjectId: 1,
          instructions: "What is 2 + 2 ?",
          answer: "4",
        },
        {
          id: 2,
          subjectId: 1,
          instructions: "What is 5 * 3 ?",
          answer: "15",
        },
      ],
    },
    {
      id: 2,
      name: "Topic 2",
      exercises: [
        {
          id: 1,
          subjectId: 2,
          instructions: "What is the chemical symbol for water ?",
          answer: "H2O",
        },
        {
          id: 2,
          subjectId: 2,
          instructions:
            "How long does it take to go from a new moon to a full moon ?",
          answer: "A fortnight",
        },
      ],
    },
    {
      id: 3,
      name: "Topic 3",
      exercises: [
        {
          id: 1,
          subjectId: 3,
          instructions: "What is the chemical symbol for water ?",
          answer: "H2O",
        },
        {
          id: 2,
          subjectId: 3,
          instructions:
            "How long does it take to go from a new moon to a full moon ?",
          answer: "A fortnight",
        },
      ],
    },
    {
      id: 4,
      name: "Topic 4",
      exercises: [
        {
          id: 1,
          subjectId: 2,
          instructions: "What is the chemical symbol for water ?",
          answer: "H2O",
        },
        {
          id: 4,
          subjectId: 4,
          instructions:
            "How long does it take to go from a new moon to a full moon ?",
          answer: "A fortnight",
        },
      ],
    },
    {
      id: 5,
      name: "Topic 5",
      exercises: [
        {
          id: 1,
          subjectId: 2,
          instructions: "What is the chemical symbol for water ?",
          answer: "H2O",
        },
        {
          id: 5,
          subjectId: 5,
          instructions:
            "How long does it take to go from a new moon to a full moon ?",
          answer: "A fortnight",
        },
      ],
    },
    {
      id: 6,
      name: "Topic 6",
      exercises: [
        {
          id: 1,
          subjectId: 2,
          instructions: "What is the chemical symbol for water ?",
          answer: "H2O",
        },
        {
          id: 6,
          subjectId: 6,
          instructions:
            "How long does it take to go from a new moon to a full moon ?",
          answer: "A fortnight",
        },
      ],
    },
    {
      id: 7,
      name: "Topic 7",
      exercises: [
        {
          id: 1,
          subjectId: 2,
          instructions: "What is the chemical symbol for water ?",
          answer: "H2O",
        },
        {
          id: 7,
          subjectId: 7,
          instructions:
            "How long does it take to go from a new moon to a full moon ?",
          answer: "A fortnight",
        },
      ],
    },
    {
      id: 8,
      name: "Topic 8",
      exercises: [
        {
          id: 1,
          subjectId: 2,
          instructions: "What is the chemical symbol for water ?",
          answer: "H2O",
        },
        {
          id: 8,
          subjectId: 8,
          instructions:
            "How long does it take to go from a new moon to a full moon ?",
          answer: "A fortnight",
        },
      ],
    },
     {
      id: 9,
      name: "Topic 9",
      exercises: [
        {
          id: 1,
          subjectId: 2,
          instructions: "What is the chemical symbol for water ?",
          answer: "H2O",
        },
        {
          id: 9,
          subjectId: 9,
          instructions:
            "How long does it take to go from a new moon to a full moon ?",
          answer: "A fortnight",
        },
      ],
    },
     {
      id: 10,
      name: "Topic 10",
      exercises: [
        {
          id: 1,
          subjectId: 2,
          instructions: "What is the chemical symbol for water ?",
          answer: "H2O",
        },
        {
          id: 10,
          subjectId: 10,
          instructions:
            "How long does it take to go from a new moon to a full moon ?",
          answer: "A fortnight",
        },
      ],
    },
      {
      id: 11,
      name: "Topic 11",
      exercises: [
        {
          id: 1,
          subjectId: 2,
          instructions: "What is the chemical symbol for water ?",
          answer: "H2O",
        },
        {
          id: 11,
          subjectId: 11,
          instructions:
            "How long does it take to go from a new moon to a full moon ?",
          answer: "A fortnight",
        },
      ],
    },
      {
      id: 12,
      name: "Topic 10",
      exercises: [
        {
          id: 1,
          subjectId: 2,
          instructions: "What is the chemical symbol for water ?",
          answer: "H2O",
        },
        {
          id: 12,
          subjectId: 12,
          instructions:
            "How long does it take to go from a new moon to a full moon ?",
          answer: "A fortnight",
        },
      ],
    },
  ];
  
  const [correctAnswersCount, setCorrectAnswersCount] = useState(0);
   const totalExercisesCount = subjects.reduce(
    (count, subject) => count + subject.exercises.length,
    0
  );

   const handleSubjectClick = (subjectId, exerciseId) => {
    const subject = subjects.find((subject) => subject.id === subjectId);
    if (subject) {
      setCurrentSubject(subject);
      if (exerciseId) {
        const exercise = subject.exercises.find(
          (exercise) => exercise.id === exerciseId
        );
        setCurrentExercise(exercise);
        setUserAnswer("");
        setShowAnswer(false);
        setIsAnswerCorrect(true);
      } else {
        setCurrentExercise(null);
        setShowAnswer(false);
        setIsAnswerCorrect(true);
      }
      setShowModal(false);
    }
  };

  const handleChangeAnswer = (event) => {
    setUserAnswer(event.target.value);
  };

  const handleSubmitAnswer = () => {
    const correctAnswer = currentExercise.answer.toLowerCase().trim();
    const userEnteredAnswer = userAnswer.toLowerCase().trim();

    if (
      userEnteredAnswer === correctAnswer &&
      !completedExercises.includes(currentExercise.id)
    ) {
      setIsAnswerCorrect(true);
      setCompletedExercises((completed) => [...completed, currentExercise.id]);

      setCorrectAnswersCount((count) => count + 1);
    } else {
      setIsAnswerCorrect(false);
    }
    setShowAnswer(true);
  };

 // ...existing code...

const handleNextExercise = () => {
  const exercises = currentSubject.exercises;
  const currentIndex = exercises.findIndex(
    (exercise) => exercise.id === currentExercise.id
  );
  const nextIndex = currentIndex + 1;

  if (nextIndex < exercises.length) {
    setCurrentExercise(exercises[nextIndex]);
    setUserAnswer("");
    setShowAnswer(false);
    setIsAnswerCorrect(true);
  } else {
    const currentSubjectIndex = subjects.findIndex(
      (subject) => subject.id === currentSubject.id
    );
    const nextSubjectIndex = currentSubjectIndex + 1;

    if (nextSubjectIndex < subjects.length) {
      const nextSubject = subjects[nextSubjectIndex];
      setCurrentSubject(nextSubject);
      setCurrentExercise(nextSubject.exercises[0]);
      setUserAnswer("");
      setShowAnswer(false);
      setIsAnswerCorrect(true);
    } else {
      // All exercises have been completed
      setCurrentExercise(null);
      setShowAnswer(false);
      setIsAnswerCorrect(true);
      setIsAllExercisesCompleted(true);
    }
  }
};

const handleCloseModal = () => {
  setIsAllExercisesCompleted(false);
};
// ...existing code...



  const handleShowAnswer = () => {
    setShowAnswerPrev((prevShowAnswer) => !prevShowAnswer);
  };

  return (
    <>
      <div className="exercise-page">
        <Sidebar
          subjects={subjects}
          currentSubject={currentSubject}
          handleSubjectClick={handleSubjectClick}
          completedExercises={completedExercises}
          setCompletedExercises={setCompletedExercises}
          totalExercisesCount ={totalExercisesCount}
          correctAnswersCount = {correctAnswersCount}
        />

      
          <div className="exercise-content" ref={exerciseRef}>
            {currentExercise && (
              <div className = "exercise-content_1">
              <div className ="null"></div>
                <h1>Exercise: </h1>
                <div className = "for-correct-answer">
                  <p className = "question-for-exercise">{currentExercise.instructions}</p>
                  {showAnswer ? (
                    <div>
                      {isAnswerCorrect ? (
                        <div className="answer-modal correct">
                          <p className ="for-display-correct">Correct!</p>
                          <p className ="for-display-next">next&gt;</p>
                        </div>
                      ) : (
                        <div className="answer-modal incorrect">
                          <p>Incorrect answer. Try again!</p>
                        </div>
                      )}
                      {isAnswerCorrect ? (
                        <>
                          <h3>Your Answer:</h3>
                          <pre>{userAnswer}</pre>
                        </>
                      ) : (
                        <>
                          <h3>Your Incorrect Answer:</h3>
                          <pre>{userAnswer}</pre>
                          <h3>Correct Answer:</h3>
                          <pre>{currentExercise.answer}</pre>
                        </>
                      )}
                      {!isAnswerCorrect && (
                        <p className="error-message">
                          Incorrect answer. Try again!
                        </p>
                      )}
                      <button onClick={handleNextExercise}>Next Exercise</button>
                    </div>
                  ) : (
                    <div  className ="answer-container">
                    <div className ="text-for_answer">
                      <textarea
                        value={userAnswer}
                        onChange={handleChangeAnswer}
                        rows={1}
                        cols={60}
                        placeholder="Enter your answer here..."
                      ></textarea>
                      <button className ="show-answer" onClick={handleShowAnswer}>
                        {showAnswerPrev ? "Hide Answer" : "Show Answer"}
                      </button>
                      </div>
                      <div className ="for_button_exercise">
                    
                      <button className ="submit-answer" onClick={handleSubmitAnswer}>Submit Answer</button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
       
           {isAllExercisesCompleted && (
        <Modal
          className="answer-complete"
          show={true}
          onHide={() => {}}
        >
          <Modal.Header>
            <Modal.Title>Exercise Complete</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>You have completed all exercises!</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
       
        {showAnswerPrev && (
          <div className="show-answer2">
          <pre> {currentExercise.answer}</pre>
          </div>
        )}
        
      </div>
    </>
  );
};

export default Exercise;
