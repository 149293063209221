import { useLocation, useParams, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { BsCart2 } from "react-icons/bs";
import ProgressRow1 from "./ProgressRow1";
import ProgressRow2 from "./ProgressRow2";
import ProgressRow3 from "./ProgressRow3";
import { useAuth } from "../../../Context/AuthContext";
import Header from "../../../component/DigitalcontentHeader/Header";
import LoadingSpinner from "../../LoadingSpinner";


function Progress() {
  let cartCount = 4;
  const { cardId } = useParams();
  const [chapterData, setChapterData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const { isLoggedIn, user, token } = useAuth(); // Destructure token

  useEffect(() => {
    const checkAccess = async () => {
      try {
        const response = await fetch("https://auth.ssccglpinnacle.com/api/checkAccessProduct", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": `Bearer ${token}`
          },
          body: JSON.stringify({ product_id: cardId })
        });

        if (!response.ok) {
          throw new Error(`Failed to check product access. Status: ${response.status}`);
        }

        const { access } = await response.json();

        if (access !== "access") {
          navigate("/digital-catalog");
          return false;
        }

        return true;
      } catch (error) {
        console.error("Access check failed:", error);
        navigate("/digital-catalog");
        return false;
      }
    };

    const fetchChapterData = async () => {
      try {
        if (!token) {
          navigate('/digital-catalog');
          return;
        }

        const hasAccess = await checkAccess();
        if (!hasAccess) return;

        const response = await fetch(`https://auth.ssccglpinnacle.com/api/chapters-ebook?ebook=${cardId}`, {
          headers: {
            "Authorization": `Bearer ${token}`
          }
        });

        if (!response.ok) {
          if (response.status === 401 || response.status === 403) {
            navigate('/digital-catalog');
            return;
          }
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        setChapterData(data);
      } catch (error) {
        console.error("Error fetching chapter data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (cardId && isLoggedIn) {
      fetchChapterData();
    } else {
      setIsLoading(false);
      navigate('/digital-catalog');
    }
  }, [cardId, isLoggedIn, token, navigate]);

  // Render loading state if data is still being fetched
  if (isLoading) {
    return <><LoadingSpinner/></>;
  }

  const chaptersForEbook = chapterData.filter(
    (chapter) => chapter.ebook._id === cardId
  );

  return (
    <>
      <div className="main-body-container-mylearning">
        <div className="body-for-progress-report">
          <Header />
          <ProgressRow1 cardId={cardId} />
          <ProgressRow2 chaptersForEbook={chaptersForEbook} />
          {/* <ProgressRow3 /> */}
        </div>
      </div>
    </>
  );
}

export default Progress;
