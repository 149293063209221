import React, { useEffect, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import { FaTrash } from "react-icons/fa"; // Import the delete icon
import { MaterialReactTable } from "material-react-table";
import Swal from "sweetalert2";
import "./StudentDetail.css"; // Import your custom CSS
import LoadingSpinner from "../../LoadingSpinner";

const StudentDetail = () => {
  const { studentId } = useParams();
  const [student, setStudent] = useState({});
  const [ebooks, setEbooks] = useState([]);
  const [availableVideos, setAvailableVideos] = useState([]);
  const [selectedVideos, setSelectedVideos] = useState([]); // To track selected videos
  const [ebooksone, setEbooksone] = useState([]);
  const [books, setBooks] = useState([]);
  const [typingData, setTypingData] = useState([]);
  const [selectedEbooks, setSelectedEbooks] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedStudent, setEditedStudent] = useState({});
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [billingDetails, setBillingDetails] = useState([]);
  const [orders, setOrders] = useState([]);
  const [videos, setVideos] = useState([]);
  const [courses, setCourses] = useState([]);
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);

  useEffect(() => {
    const fetchStudentDetails = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `https://auth.ssccglpinnacle.com/api/student/${studentId}`
        );
        const data = await response.json();
        setStudent(data);
        setEditedStudent(data);
      } catch (error) {
        console.error("Error fetching student details:", error);
        Swal.fire("Error", "Error fetching student details", "error");
      } finally {
        setLoading(false);
      }
    };

    fetchStudentDetails();
  }, [studentId]);

  useEffect(() => {
    if (student.email_id) {
      const fetchEbooks = async () => {
        try {
          const response = await fetch(
            `https://auth.ssccglpinnacle.com/api/student/${student.email_id}/ebooks`
          );
          const data = await response.json();
          setEbooks(data);
        } catch (error) {
          console.error("Error fetching ebooks:", error);
          Swal.fire("Error", "Error fetching ebooks", "error");
        }
      };

      const fetchBooks = async () => {
        try {
          const response = await fetch(
            `https://auth.ssccglpinnacle.com/api/purchased-books/${student.email_id}`
          );
          const data = await response.json();
          setBooks(data);
        } catch (error) {
          console.error("Error fetching books:", error);
          Swal.fire("Error", "Error fetching books", "error");
        }
      };

      const fetchTypingData = async () => {
        try {
          const response = await fetch(
            `https://auth.ssccglpinnacle.com/api/student/${student.email_id}/typing`
          );
          const data = await response.json();
          setTypingData(data);
        } catch (error) {
          console.error("Error fetching typing data:", error);
          Swal.fire("Error", "Error fetching typing data", "error");
        }
      };

      const fetchBillingDetails = async () => {
        try {
          const response = await fetch(
            `https://auth.ssccglpinnacle.com/api/student/billing-address-details/${student._id}/${student.id}`
          );
          const data = await response.json();
          setBillingDetails(data);
        } catch (error) {
          console.error("Error fetching billing details:", error);
          Swal.fire("Error", "Error fetching billing details", "error");
        }
      };

      const fetchOrders = async () => {
        try {
          const response = await fetch(
            "https://onlineexam.ssccglpinnacle.com/purchaseorders",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ email_id: student.email_id }),
            }
          );
          const data = await response.json();
          setOrders(data);
        } catch (error) {
          console.error("Error fetching purchase orders:", error);
          Swal.fire("Error", "Error fetching purchase orders", "error");
        }
      };

      const fetchVideos = async () => {
        try {
          const response = await fetch(
            `https://auth.ssccglpinnacle.com/api/purchased_videos/${student._id}`
          );
          const data = await response.json();
          setVideos(data.purchasedVideos);
        } catch (error) {
          console.error("Error fetching videos:", error);
          Swal.fire("Error", "Error fetching videos", "error");
        }
      };

      const fetchCourses = async () => {
        try {
          const response = await fetch(
            "https://auth.ssccglpinnacle.com/api/courses"
          );
          const data = await response.json();
          setCourses(data);
        } catch (error) {
          console.error("Error fetching courses:", error);
          Swal.fire("Error", "Error fetching courses", "error");
        }
      };

      fetchEbooks();
      fetchBooks();
      fetchTypingData();
      fetchBillingDetails();
      fetchOrders();
      fetchVideos();
      fetchCourses();
    }
  }, [student.email_id]);

  // Add similar loading handling to the other fetch functions

  const getCourseTitle = (productId) => {
    const course = courses.find((course) => course._id === productId);
    return course ? course.courseTitle : "Course not found";
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedStudent({ ...editedStudent, [name]: value });
  };

  const handleOpenModal = async () => {
    setIsModalOpen(true);
    try {
      const response = await fetch(
        "https://auth.ssccglpinnacle.com/api/ebooksforactive?active=true"
      );
      const data = await response.json();

      if (response.ok) {
        setEbooksone(data);
        setError("");
      } else {
        setError(data.message || "Error fetching ebooks");
        setEbooksone([]);
      }
    } catch (error) {
      setError("Error fetching ebooks");
      console.error("Error fetching ebooks:", error);
      setEbooksone([]);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedEbooks([]);
  };

  const handleEbookSelection = (event, ebookId) => {
    setSelectedEbooks((prevSelected) =>
      prevSelected.includes(ebookId)
        ? prevSelected.filter((id) => id !== ebookId)
        : [...prevSelected, ebookId]
    );
  };

  const handleAddTyping = async () => {
    // Use SweetAlert to show a confirmation dialog
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to grant typing access to this user?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, give access!",
      cancelButtonText: "No, cancel!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        // If the user confirms, execute the original logic
        try {
          const response = await fetch(
            "https://auth.ssccglpinnacle.com/api/add-typing-access",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                email_id: student.email_id,
                product_id: "999",
              }),
            }
          );

          const data = await response.json();

          if (response.ok) {
            setMessage("Typing product added successfully");
            Swal.fire(
              "Success",
              "Typing product added successfully",
              "success"
            );
            setError("");
          } else {
            setError(data.message || "Error adding typing product");
            setMessage("");
            Swal.fire(
              "Error",
              data.message || "Error adding typing product",
              "error"
            );
          }
        } catch (error) {
          setError("Error adding typing product");
          console.error("Error adding typing product:", error);
          Swal.fire("Error", "Error adding typing product", "error");
        }
      }
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(
        "https://auth.ssccglpinnacle.com/api/process-email",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ emailId: student.email_id, selectedEbooks }),
        }
      );

      const data = await response.json();

      if (response.ok) {
        setMessage("Ebook access successfully saved.");
        Swal.fire("Success", "Ebook access successfully saved", "success");
        setError("");
        handleCloseModal();
      } else {
        setError(data.message || "Error processing ebook access.");
        setMessage("");
        Swal.fire(
          "Error",
          data.message || "Error processing ebook access",
          "error"
        );
      }
    } catch (error) {
      setError("Error processing ebook access.");
      console.error("Error processing ebook access:", error);
      Swal.fire("Error", "Error processing ebook access", "error");
    }
  };

  const columns = useMemo(
    () => [
      {
        id: "select",
        header: "Select",
        Cell: ({ row }) => (
          <input
            type="checkbox"
            value={row.original.id}
            checked={selectedEbooks.includes(row.original.id)}
            onChange={(e) => handleEbookSelection(e, row.original.id)}
          />
        ),
      },
      {
        accessorKey: "title",
        header: "Title",
      },
      {
        accessorKey: "medium",
        header: "Medium",
      },
      {
        accessorKey: "edition",
        header: "Edition",
      },
      {
        accessorKey: "id",
        header: "ID",
      },
    ],
    [selectedEbooks]
  );

  const videoColumns = useMemo(
    () => [
      {
        id: "select",
        header: "Select",
        Cell: ({ row }) => (
          <input
            type="checkbox"
            value={row.original._id}
            checked={selectedVideos.includes(row.original._id)}
            onChange={(e) => handleVideoSelection(e, row.original._id)}
          />
        ),
      },
      {
        accessorKey: "courseTitle", // Assuming the API provides this field
        header: "Title",
      },
      {
        accessorKey: "medium", // If this field is relevant for videos
        header: "Medium",
      },
      {
        accessorKey: "edition", // If this field is relevant for videos
        header: "Edition",
      },
      {
        accessorKey: "_id",
        header: "ID",
      },
    ],
    [selectedVideos]
  );

  const handleEdit = () => {
    setIsEditing(!isEditing);
  };

  const handleSave = async () => {
    try {
      const response = await fetch(
        `https://auth.ssccglpinnacle.com/api/student/${studentId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(editedStudent),
        }
      );

      if (response.ok) {
        setStudent(editedStudent);
        setIsEditing(false);
        setMessage("Student details updated successfully.");
        Swal.fire("Success", "Student details updated successfully", "success");
      } else {
        setError("Failed to update student details.");
        Swal.fire("Error", "Failed to update student details", "error");
      }
    } catch (error) {
      console.error("Error updating student details:", error);
      setError("Error updating student details.");
      Swal.fire("Error", "Error updating student details", "error");
    }
  };

  const handleOpenVideoModal = async () => {
    setIsVideoModalOpen(true); // Assuming you have a modal state
    try {
      const response = await fetch(
        "https://auth.ssccglpinnacle.com/api/courses"
      );
      const data = await response.json();
      setAvailableVideos(data); // Store available videos
    } catch (error) {
      console.error("Error fetching videos:", error);
      Swal.fire("Error", "Error fetching videos", "error");
    }
  };

  const handleVideoSelection = (event, videoId) => {
    setSelectedVideos(
      (prevSelected) =>
        prevSelected.includes(videoId)
          ? prevSelected.filter((id) => id !== videoId) // Remove if already selected
          : [...prevSelected, videoId] // Add if not selected
    );
  };

  const handleSubmitSelectedVideos = async (event) => {
    event.preventDefault(); // Prevent the page reload

    // Debugging to check what is being sent
    console.log("Selected Videos:", selectedVideos);
    console.log("Sending email:", student.email_id);

    try {
      // Making the PUT request to the backend API
      const response = await fetch(
        "https://auth.ssccglpinnacle.com/api/video_purchase",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email_id: student.email_id, // Ensure email_id is correct
            purchasedVideos: selectedVideos.map((videoId) => ({
              productId: videoId, // Add necessary fields like duration and validity if required
              duration: "12 Months",
              validity: true,
              expirationDate: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000), // Example expiration date
            })),
          }),
        }
      );

      const data = await response.json();
      console.log("Response:", data); // Log the API response

      if (response.ok) {
        if (data.status === "already_purchased") {
          // Show message if course is already purchased
          Swal.fire({
            icon: "info",
            title: "Course Already Purchased",
            text: data.message, // Display the message from the API
          });
        } else {
          // Success handling
          setMessage("Videos added successfully.");
          Swal.fire("Success", "Videos added successfully", "success");
          setIsVideoModalOpen(false);
        }
      } else {
        // Handling error responses
        console.error("Error response:", data);
        Swal.fire("Error", data.message || "Error adding videos", "error");
      }
    } catch (error) {
      // Catch any other errors that occur during the request
      console.error("Error adding videos:", error);
      Swal.fire("Error", "Error adding videos", "error");
    }
  };
  const handleCloseVideoModal = () => {
    setIsVideoModalOpen(false);
    setSelectedVideos([]); // Reset selected videos
  };

  const handleDeleteEbook = async (ebookId) => {
    // Show confirmation dialog
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete this ebook access?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel",
    });

    // If user confirms deletion
    if (result.isConfirmed) {
      try {
        const response = await fetch(
          `https://auth.ssccglpinnacle.com/api/delete-ebook-access`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ emailId: student.email_id, ebookId }),
          }
        );

        if (response.ok) {
          // Update UI to remove the deleted ebook
          setEbooks(ebooks.filter((ebook) => ebook._id !== ebookId)); // Assuming you are using `_id` for MongoDB
          Swal.fire(
            "Deleted!",
            "Ebook access has been deleted successfully.",
            "success"
          );
        } else {
          const contentType = response.headers.get("content-type");
          const data =
            contentType && contentType.includes("application/json")
              ? await response.json()
              : { message: "Error deleting ebook access" };
          Swal.fire(
            "Error",
            data.message || "Error deleting ebook access",
            "error"
          );
        }
      } catch (error) {
        Swal.fire("Error", "Error deleting ebook access", "error");
      }
    }
  };

  const handleDeleteTyping = async () => {
    // Use SweetAlert to show a confirmation dialog
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to remove typing access for this user?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, remove access!",
      cancelButtonText: "No, cancel!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        // If the user confirms, execute the deletion logic
        try {
          const response = await fetch(
            "https://auth.ssccglpinnacle.com/api/remove-typing-access",
            {
              method: "DELETE",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                email_id: student.email_id,
                product_id: "999",
              }),
            }
          );

          const data = await response.json();

          if (response.ok) {
            setMessage("Typing product access removed successfully");
            Swal.fire(
              "Success",
              "Typing product access removed successfully",
              "success"
            );
            setError("");
            // Optionally, you can update the UI to reflect the removed product
            setTypingData([]);
          } else {
            setError(data.message || "Error removing typing product");
            setMessage("");
            Swal.fire(
              "Error",
              data.message || "Error removing typing product",
              "error"
            );
          }
        } catch (error) {
          setError("Error removing typing product");
          console.error("Error removing typing product:", error);
          Swal.fire("Error", "Error removing typing product", "error");
        }
      }
    });
  };

  const handleDeleteVideo = async (userId, productId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to remove access for this video?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, remove access!",
      cancelButtonText: "No, cancel!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await fetch(
            `https://auth.ssccglpinnacle.com/api/purchased_videos/${userId}/${productId}`,
            {
              method: "DELETE",
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          const data = await response.json();

          if (response.ok) {
            // Update the state to remove the deleted video from the UI
            setVideos((prevVideos) =>
              prevVideos.filter((video) => video.productId !== productId)
            );
            Swal.fire("Deleted", "Video deleted successfully", "success");
          } else {
            Swal.fire("Error", data.message || "Error deleting video", "error");
          }
        } catch (error) {
          console.error("Error deleting video:", error);
          Swal.fire("Error", "Server error while deleting video", "error");
        }
      }
    });
  };

  return (
    <div className="showing-purchased-product">
      {loading ? (
        <LoadingSpinner /> // You can replace this with a spinner or other loading indicator
      ) : (
        <>
          <h2 className="add-student-correct-form">
            Student Table (Admin can edit here User Name, Email ID, and Mobile
            Number)
          </h2>
          <div className="butto-for-edits-products">
            <button onClick={handleOpenModal} className="add-ebook-button">
              + Add Ebooks
            </button>
            <button onClick={handleAddTyping} className="add-typing-button">
              + Add Typing Product
            </button>
            <button onClick={handleOpenVideoModal} className="add-video-button">
              + Add Videos
            </button>
          </div>
          <div className="student-info-container">
            <div className="student-info-header">
              <div>Student ID</div>
              <div>Name</div>
              <div>Email</div>
              <div>Mobile</div>
              <div>Action</div>
            </div>
            <div className="student-info-row">
              <div>{student?.id}</div>
              <div>
                {isEditing ? (
                  <input
                    type="text"
                    name="full_name"
                    value={editedStudent.full_name || ""}
                    onChange={handleInputChange}
                  />
                ) : (
                  student?.full_name
                )}
              </div>
              <div>
                {isEditing ? (
                  <input
                    type="email"
                    name="email_id"
                    value={editedStudent.email_id || ""}
                    onChange={handleInputChange}
                  />
                ) : (
                  student?.email_id
                )}
              </div>
              <div>
                {isEditing ? (
                  <input
                    type="text"
                    name="mobile_number"
                    value={editedStudent.mobile_number || ""}
                    onChange={handleInputChange}
                  />
                ) : (
                  student?.mobile_number
                )}
              </div>
              <div>
                {isEditing ? (
                  <button onClick={handleSave} className="save-button">
                    Save
                  </button>
                ) : (
                  <button onClick={handleEdit} className="edit-button">
                    Edit
                  </button>
                )}
              </div>
            </div>
          </div>

          <div className="box">
            <h3>Student Address</h3>
            {billingDetails ? (
              <ul>
                <li>
                  <strong>Full Name:</strong> {billingDetails?.fullName}
                </li>
                <li>
                  <strong>Mobile Number:</strong> {billingDetails?.mobileNumber}
                </li>
                <li>
                  <strong>Address:</strong> {billingDetails?.address}
                </li>
                <li>
                  <strong>City:</strong> {billingDetails?.city}
                </li>
                <li>
                  <strong>State:</strong> {billingDetails?.state}
                </li>
                <li>
                  <strong>Pincode:</strong> {billingDetails?.pincode}
                </li>
              </ul>
            ) : (
              <p>No Student address available</p>
            )}
          </div>

          {isModalOpen && (
            <div className="ebook-selector-modal">
              <div className="ebook-selector-modal-content">
                <h2>Select Ebooks</h2>
                <form onSubmit={handleSubmit}>
                  {ebooksone?.length > 0 && (
                    <MaterialReactTable
                      columns={columns}
                      data={ebooksone}
                      enableRowSelection={false}
                      enableMultiRowSelection={false}
                      getRowId={(row) => row._id}
                    />
                  )}
                  {error && <p className="error-message">{error}</p>}
                  {message && <p className="success-message">{message}</p>}
                  <div className="ebook-modal-buttons">
                    <button type="submit" className="save-button">
                      Save
                    </button>
                    <button
                      type="button"
                      onClick={handleCloseModal}
                      className="cancel-button"
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}

          {isVideoModalOpen && (
            <div className="video-selector-modal">
              <div className="video-selector-modal-content">
                <h2>Select Videos</h2>
                <form onSubmit={handleSubmitSelectedVideos}>
                  {availableVideos?.length > 0 && (
                    <MaterialReactTable
                      columns={videoColumns}
                      data={availableVideos}
                      enableRowSelection={false}
                      enableMultiRowSelection={false}
                      getRowId={(row) => row._id}
                    />
                  )}
                  {error && <p className="error-message">{error}</p>}
                  {message && <p className="success-message">{message}</p>}
                  <div className="video-modal-buttons">
                    <button type="submit" className="save-button">
                      Save
                    </button>
                    <button
                      type="button"
                      onClick={handleCloseVideoModal}
                      className="cancel-button"
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}

          <div className="box-container">
            <div className="box-book">
              <h3>Books</h3>
              <div className="for-over-student">
                <ul>
                  {books?.length > 0 ? (
                    books.map((book, index) => (
                      <li key={index}>
                        {book.bookDetails.title} {book.bookDetails.edition}{" "}
                        edition {book.bookDetails.medium} medium
                      </li>
                    ))
                  ) : (
                    <p>No books purchased</p>
                  )}
                </ul>
              </div>
            </div>
            <div className="box-ebook">
              <h3>Ebooks</h3>
              <div className="for-over-student">
                <ul>
                  {ebooks?.length > 0 ? (
                    ebooks.map((ebook, index) => (
                      <li key={index} className="ebook-item">
                        <div className="ebook-info">
                          {ebook.title} {ebook.edition} edition {ebook.medium}{" "}
                          medium
                        </div>
                        <div className="delete-icon-container">
                          <FaTrash
                            className="delete-icon-access"
                            onClick={() => handleDeleteEbook(ebook._id)} // Passing the correct ObjectId (_id) from MongoDB
                          />
                        </div>
                      </li>
                    ))
                  ) : (
                    <p>No ebooks purchased</p>
                  )}
                </ul>
              </div>
            </div>
            <div className="box-videos">
              <h3>Videos</h3>
              <div className="for-over-student">
                <ul>
                  {videos?.length > 0 ? (
                    videos.map((video, index) => (
                      <li key={index} className="video-item">
                        {getCourseTitle(video.productId)}
                        <div className="delete-icon-container">
                          <FaTrash
                            className="delete-icon-access"
                            onClick={() =>
                              handleDeleteVideo(student._id, video.productId)
                            }
                          />
                        </div>
                      </li>
                    ))
                  ) : (
                    <p>No videos purchased</p>
                  )}
                </ul>
              </div>
            </div>
            <div className="box-typing">
              <h3>Typing</h3>
              <div className="for-over-student">
                <ul>
                  {typingData?.length > 0 ? (
                    <li key={typingData[0]?.product_id} className="typing-item">
                      <div className="typing-info">
                        This user has purchased the typing product (Product ID:{" "}
                        {typingData[0]?.product_id}).
                      </div>
                      <div className="delete-icon-container">
                        <FaTrash
                          className="delete-icon-access"
                          onClick={handleDeleteTyping} // Assuming handleDeleteTyping is defined
                        />
                      </div>
                    </li>
                  ) : (
                    <p>No typing product purchased</p>
                  )}
                </ul>
              </div>
            </div>

            <div className="box-test">
              <div className="for-over-student">
                <h3>Test</h3>
                {orders?.length > 0 ? (
                  <ul>
                    {orders.map((order, index) => (
                      <li key={index}>{order.product_title}</li>
                    ))}
                  </ul>
                ) : (
                  <p>No orders found</p>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default StudentDetail;
