import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './AddPDFForm.css'; // Import your CSS file
import Swal from 'sweetalert2';
import AnswerPDFForm from './AnswerPDFForm';
import SolutionPDFForm from './SolutionPDFForm';
import Select from "react-select";
import { useAuth } from '../../Context/AuthContext';

const AddPDFForm = () => {
  const { token } = useAuth();
  const [sku, setSku] = useState('');
  const [chapterId, setChapterId] = useState('');
  const [pdfFile, setPdfFile] = useState(null);
  const [ebooks, setEbooks] = useState([]);
  const [chapters, setChapters] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [showAnswerPDFForm, setShowAnswerPDFForm] = useState(false);
  const [ebookId, setEbookId] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    const fetchEbooks = async () => {
      try {
        const response = await fetch('https://auth.ssccglpinnacle.com/api/ebooks');
        if (response.ok) {
          const ebooksData = await response.json();
          setEbooks(ebooksData);
        } else {
          console.error('Failed to fetch ebooks');
        }
      } catch (error) {
        console.error('Error fetching ebooks:', error);
      }
    };
    fetchEbooks();
  }, []);

  const handleSkuChange = async (selectedEbook) => {
    setEbookId(selectedEbook._id);
    setSku(selectedEbook.sku);
    setChapters([]); // Clear chapters when a new ebook is selected

    try {
      setLoading(true);
      const response = await fetch(`https://auth.ssccglpinnacle.com/api/chapters-ebook-admin/${selectedEbook._id}`);
      if (!response.ok) {
        throw new Error("Error fetching chapter data");
      }
      const data = await response.json();
      setChapters(data);
      console.log('Fetched chapters:', data); // Log the fetched chapters
    } catch (error) {
      console.error('Error fetching chapters:', error);
    } finally {
      setLoading(false);
    }
  };

  const handlePDFChange = (e) => {
    setPdfFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append('pdf', pdfFile);
      formData.append('chapterId', chapterId);

      const response = await fetch('https://auth.ssccglpinnacle.com/api/uploadPdf-ebook', {
        method: 'POST',
        body: formData,
      });

      const responseBody = await response.json();

      if (response.ok) {
        console.log('Upload successful:', responseBody);

        // Reset form state
        setSku('');
        setChapterId('');
        setPdfFile(null);

        // Show success message with SweetAlert
        Swal.fire({
          icon: 'success',
          title: 'Upload Successful!',
          text: 'Your PDF has been uploaded successfully.',
        });

        // Set the upload success state to true
        setUploadSuccess(true);

        // Show AnswerPDFForm
        setShowAnswerPDFForm(true);
      } else if (responseBody.message && responseBody.message.includes('PDF with the same chapterId already exists')) {
        // Show error message for existing PDF using SweetAlert
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'PDF with the same chapterId already exists',
        });

        console.error('PDF with the same chapter already exists');
      } else {
        // Handle other error cases
        console.error('Failed to upload PDF');
        setError('Failed to upload PDF. Please try again.');

        // Show error message with SweetAlert
        Swal.fire({
          icon: 'error',
          title: 'Upload Failed',
          text: 'There was an error uploading the PDF. Please try again.',
        });
      }
    } catch (error) {
      console.error('Error uploading PDF:', error);
      setError('Error uploading PDF. Please try again.');

      // Show error message with SweetAlert
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An unexpected error occurred. Please try again later.',
      });
    }
  };

 

  const handleButtonClick = () => {
    // Set the state to show AnswerPDFForm
    setShowAnswerPDFForm(true);
  };

  return (
    <>
     <div className='chapter-one-pdf'>
     <div className='chapter-one-main-pdf'>
      <h1 className="add-ebook-form">Add PDF</h1>
      <div className="pdf-form-container">
        <form className="pdf-form" onSubmit={handleSubmit}>
          <label htmlFor="sku">Select SKU:</label>
          {/* <Select
            id="sku"
            value={{
              label: sku,
              value: sku,
            }} // Use the correct format for value
            onChange={(option) => {
              setSku(option.value);
              handleSkuChange(
                ebooks.find((ebook) => ebook.sku === option.value)
              );
            }}
            options={ebooks.map((ebook) => ({
              label: ebook.sku,
              value: ebook.sku,
            }))}
            placeholder="Select SKU"
            className="pdf-select"
          /> */}
          <Select
  id="sku"
  value={{
    label: sku,
    value: sku,
  }} // Use the correct format for value
  onChange={(option) => {
    setSku(option.value);
    handleSkuChange(
      ebooks.find((ebook) => ebook.sku === option.value)
    );
  }}
  options={ebooks.slice().reverse().map((ebook) => ({
    label: ebook.sku,
    value: ebook.sku,
  }))}  // Reversing the ebooks array
  placeholder="Select SKU"
  className="pdf-select"
/>

          <br />
          {sku && (
            <>
              <label htmlFor="title">Title:</label>
              <input
                type="text"
                id="title"
                value={ebooks.find((ebook) => ebook.sku === sku)?.title || ''}
                readOnly
                className="pdf-input"
              />
              <br />
              <label htmlFor="edition">Edition:</label>
              <input
                type="text"
                id="edition"
                value={ebooks.find((ebook) => ebook.sku === sku)?.edition || ''}
                readOnly
                className="pdf-input"
              />
              <br />
              <label htmlFor="medium">Medium:</label>
              <input
                type="text"
                id="medium"
                value={ebooks.find((ebook) => ebook.sku === sku)?.medium || ''}
                readOnly
                className="pdf-input"
              />
              <br />
            </>
          )}
          {sku && (
            <>
              <label htmlFor="chapterId">Select Chapter:</label>
              <select
                id="chapterId"
                value={chapterId}
                onChange={(e) => setChapterId(e.target.value)}
                required
                className="pdf-select"
              >
                <option value="" disabled>
                  Select Chapter
                </option>
                {chapters && chapters.map((chapter) => (
                  <option key={chapter._id} value={chapter._id}>
                    {chapter.title}
                  </option>
                ))}
              </select>
              <br />
            </>
          )}
          <label htmlFor="pdfFile">Upload PDF:</label>
          <input
            type="file"
            id="pdfFile"
            onChange={handlePDFChange}
            accept=".pdf"
            required
            className="pdf-input"
          />
          <br />
          {error && <p className="error-message">{error}</p>}
          <button type="submit" className="pdf-submit">
            Submit
          </button>
        </form>
      </div>
      <div className="open-pdf-button">
        {/* <button className="open-pdf-form" onClick={handleButtonClick}>
          Open Answer pdf form
        </button> */}
      </div>
      </div>
       <AnswerPDFForm />
       <SolutionPDFForm />
       </div>
    </>
  );
};

export default AddPDFForm;
