import React, { useState, useEffect, useMemo } from 'react';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import Modal from 'react-modal';
import { Box, Button, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import "./EditEbookForm.css";
import { useNavigate } from "react-router-dom";

function EditEbook() {
  const [loading, setLoading] = useState(false);
  const [ebooks, setEbooks] = useState([]);
  const [error, setError] = useState('');
  const [selectedEbook, setSelectedEbook] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const navigate = useNavigate();

  const closeModal = () => {
    setModalIsOpen(false);
    document.body.style.overflow = '';
  };

  useEffect(() => {
    fetchEbooks();
  }, []);

  async function fetchEbooks() {
    setLoading(true);
    try {
      const response = await fetch('https://auth.ssccglpinnacle.com/api/ebooksforactive?active=true');
      if (!response.ok) {
        throw new Error(`Failed to fetch active ebook data. Status: ${response.status}`);
      }
      const activeEbooksData = await response.json();
      setEbooks(activeEbooksData);
    } catch (error) {
      console.error('Error fetching active ebook data:', error);
      setError('Error fetching active ebook data.');
    } finally {
      setLoading(false);
    }
  }

  const columns = useMemo(
    () => [
      { accessorKey: 'sku', header: 'SKU' },
      { accessorKey: 'title', header: 'Title' },
      { accessorKey: 'edition', header: 'Edition' },
      { accessorKey: 'medium', header: 'Medium' },
      {
        id: 'actions',
        header: 'Actions',
        Cell: ({ row }) => (
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleEditClick(row.original)}
          >
            Edit
          </Button>
        ),
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data: ebooks,
    enableRowActions: true,
  });

  const handleEditClick = (ebook) => {
    setSelectedEbook(ebook);
    setModalIsOpen(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedEbook({ ...selectedEbook, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const formData = new FormData();
  
      // Only append the fields you want to update
      formData.append("title", selectedEbook.title);
      formData.append("category", selectedEbook.category);
      formData.append("price", selectedEbook.price);
      formData.append("ebookSellingPrice", selectedEbook.ebookSellingPrice);
      formData.append("ebookPrintingPrice", selectedEbook.ebookPrintingPrice);
      formData.append("details", selectedEbook.details);
      formData.append("medium", selectedEbook.medium);
      formData.append("search", selectedEbook.search);
      formData.append("edition", selectedEbook.edition);
      formData.append("author", selectedEbook.author);
      formData.append("pinnacleSP", selectedEbook.pinnacleSP);
      formData.append("sku", selectedEbook.sku);
      formData.append("examName", selectedEbook.examName);
      formData.append("subject", selectedEbook.subject);
      formData.append("subscriptionPrices", JSON.stringify(selectedEbook.subscriptionPrices)); // Ensure this is properly formatted
  
      if (selectedEbook.image) {
        formData.append("image", selectedEbook.image);
      }
  
      console.log("Data being sent to the server:");
      for (const [key, value] of formData.entries()) {
        console.log(`${key}: ${value}`);
      }
  
      const response = await fetch(`https://auth.ssccglpinnacle.com/api/ebooks/${selectedEbook._id}`, {
        method: 'PUT',
        body: formData,
      });
  
      if (!response.ok) {
        throw new Error(`Failed to update ebook. Status: ${response.status}`);
      }
  
      const updatedEbook = await response.json();
      console.log('Ebook updated:', updatedEbook);
      closeModal();
      fetchEbooks();
    } catch (error) {
      console.error('Error updating ebook:', error);
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <>
      <MaterialReactTable
        table={table}
        columns={columns}
        data={ebooks}
      />

      {error && <p style={{ color: 'red' }}>{error}</p>}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="Edit Ebook Modal"
        className="modalsforedit"
         overlayClassName="modal-overlay"
      >
        <Box className="custom-modal-box"
        isOpen={modalIsOpen}
        >
          <IconButton
            sx={{ position: 'absolute', top: 8, right: 8 }}
            onClick={() => setModalIsOpen(false)}
          >
            <CloseIcon />
          </IconButton>

          {selectedEbook && (
            <form onSubmit={handleSubmit} className="edit-ebook-form">
              <Typography variant="h5" gutterBottom sx={{ textAlign: 'center' }}>
                Edit Ebook
              </Typography>
              <div className="input-container">
                <label>SKU:</label>
                <input
                  type="text"
                  name="sku"
                  value={selectedEbook.sku || ''}
                  onChange={handleInputChange}
                  className="custom-input"
                />
              </div>
              <div className="input-container">
                <label>Title:</label>
                <input
                  type="text"
                  name="title"
                  value={selectedEbook.title || ''}
                  onChange={handleInputChange}
                  className="custom-input"
                />
              </div>
              <div className="input-container">
                <label>Category:</label>
                <input
                  type="text"
                  name="category"
                  value={selectedEbook.category || ''}
                  onChange={handleInputChange}
                  className="custom-input"
                />
              </div>
              <div className="input-container">
                <label>Exam Name:</label>
                <input
                  type="text"
                  name="examName"
                  value={selectedEbook.examName || ''}
                  onChange={handleInputChange}
                  className="custom-input"
                />
              </div>
              <div className="input-container">
                <label>Subject:</label>
                <input
                  type="text"
                  name="subject"
                  value={selectedEbook.subject || ''}
                  onChange={handleInputChange}
                  className="custom-input"
                />
              </div>
              <div className="input-container">
                <label>Book printing price:</label>
                <input
                  type="text"
                  name="price"
                  value={selectedEbook.price || 0}
                  onChange={handleInputChange}
                  className="custom-input"
                />
              </div>
              <div className="input-container">
                <label>Book selling price:</label>
                <input
                  type="text"
                  name="pinnacleSP"
                  value={selectedEbook.pinnacleSP || ''}
                  onChange={handleInputChange}
                  className="custom-input"
                />
              </div>
              <div className="input-container">
                <label>Subscription Prices (12 Months):</label>
                {selectedEbook.subscriptionPrices?.map((price, index) => (
                  price.duration === "12 Months" && (
                    <div key={index} className="subscription-price">
                      <input
                        type="text"
                        placeholder="Duration"
                        value={price.duration}
                        readOnly
                      />
                      <label>Ebook printing price duration 12 months</label>
                      <input
                        type="number"
                        placeholder="Printing Price"
                        value={price.printingPrice}
                        onChange={(e) => handleSubscriptionPriceChange(index, 'printingPrice', e.target.value)}
                      />
                      <label>Ebook selling price duration 12 months</label>
                      <input
                        type="number"
                        placeholder="Selling Price"
                        value={price.sellingPrice}
                        onChange={(e) => handleSubscriptionPriceChange(index, 'sellingPrice', e.target.value)}
                      />
                    </div>
                  )
                ))}
              </div>
              <div className="input-container">
                <label>Edition:</label>
                <input
                  type="text"
                  name="edition"
                  value={selectedEbook.edition || ''}
                  onChange={handleInputChange}
                  className="custom-input"
                />
              </div>
              <div className="input-container">
                <label>Search:</label>
                <input
                  type="text"
                  name="search"
                  value={selectedEbook.search || ''}
                  onChange={handleInputChange}
                  className="custom-input"
                />
              </div>
              <div className="input-container">
                <label>Details:</label>
                <textarea
                  name="details"
                  value={selectedEbook.details || ''}
                  onChange={handleInputChange}
                  className="custom-input"
                />
              </div>
              <div className="input-container">
                <label>Medium:</label>
                <input
                  type="text"
                  name="medium"
                  value={selectedEbook.medium || ''}
                  onChange={handleInputChange}
                  className="custom-input"
                />
              </div>
              <div className="input-container">
                <label>Author:</label>
                <input
                  type="text"
                  name="author"
                  value={selectedEbook.author || ''}
                  onChange={handleInputChange}
                  className="custom-input"
                />
              </div>
              <div className="input-container">
                <label>Image:</label>
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => setSelectedEbook({ ...selectedEbook, image: e.target.files[0] })}
                  className="custom-input"
                />
              </div>
              
              <div className="modal-footer-ebook">
                <Button type="submit" variant="contained" color="primary">
                  Update Ebook
                </Button>
                <Button variant="contained" color="secondary" onClick={() => setModalIsOpen(false)}>
                  Cancel
                </Button>
              </div>
            </form>
          )}
        </Box>
      </Modal>
    </>
  );

  function handleSubscriptionPriceChange(index, field, value) {
    const newSubscriptionPrices = [...selectedEbook.subscriptionPrices];
    newSubscriptionPrices[index] = {
      ...newSubscriptionPrices[index],
      [field]: value,
    };
    setSelectedEbook({ ...selectedEbook, subscriptionPrices: newSubscriptionPrices });
  }
}

export default EditEbook;
