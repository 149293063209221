import React, { useState } from "react";
import ReactDOM from "react-dom";
import Sidebar from "./Sidebar";
import Page1 from "../../Ebook/DigitalContentPages/Ebook-Page/Page1";

function SidebarHandle() {
  const [selectedChapter, setSelectedChapter] = useState(0);

  function handleChapterSelect(chapterIndex) {
    setSelectedChapter(chapterIndex);
  }

  return (
    <div>
      <Sidebar onChapterSelect={handleChapterSelect} />
      <Page1 selectedChapter={selectedChapter} />
    </div>
  );
}

export default SidebarHandle;
