import React, { useEffect, useState } from 'react';
import { FaArrowRight } from 'react-icons/fa';
 // Ensure you have this CSS file
// import ssc from "../"
import monthly from "../../../Ebook/i/monthly.png"

const NextPageMonthly = () => {
  return (
    <>
             <div className="container-fluid-blog1">
        <div className="blog-row1">
          <div className="content-left1">
            <div className='content-left1-text-block'>
              <h1>Monthly current affairs</h1>
              <p>Stay updated monthly to keep your competitive edge sharp.</p>
            </div>
          </div>
          <div className="content-right1">
            <img src={monthly} alt="Placeholder" />
          </div>
        </div>
      </div>
    </>
  );
};

export default NextPageMonthly;
