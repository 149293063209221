// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
 
 .For-content{
     /*border: solid 1px #b6b4b4;*/
    width: 100%!important;
    display: inline-block;
        padding-top: 1.5%;
 }
 .For-content-books{
    display: flex;
    flex-direction: column;
 }
 

 @media (min-width: 1501px) and (max-width: 1599px) {
    .Making-your-Study h4 {
        font-size: 27px;
    }
    .Offer-row h4 {
        font-size: 26px;
    }
    .Offer-row h5 {
        font-size: 22px;
    }
 }
 

 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 `, "",{"version":3,"sources":["webpack://./src/PagesBook/DigitalContentBody/Row2Digital.css"],"names":[],"mappings":";;CAEC;KACI,6BAA6B;IAC9B,qBAAqB;IACrB,qBAAqB;QACjB,iBAAiB;CACxB;CACA;IACG,aAAa;IACb,sBAAsB;CACzB;;;CAGA;IACG;QACI,eAAe;IACnB;IACA;QACI,eAAe;IACnB;IACA;QACI,eAAe;IACnB;CACH","sourcesContent":["\n \n .For-content{\n     /*border: solid 1px #b6b4b4;*/\n    width: 100%!important;\n    display: inline-block;\n        padding-top: 1.5%;\n }\n .For-content-books{\n    display: flex;\n    flex-direction: column;\n }\n \n\n @media (min-width: 1501px) and (max-width: 1599px) {\n    .Making-your-Study h4 {\n        font-size: 27px;\n    }\n    .Offer-row h4 {\n        font-size: 26px;\n    }\n    .Offer-row h5 {\n        font-size: 22px;\n    }\n }\n \n\n \n \n \n \n \n \n \n \n \n \n \n \n \n \n \n \n \n \n \n \n \n \n \n \n \n \n \n \n \n \n \n \n \n "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
