import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import './BlogForm.css';
import ReviewForm from "../../BlogProject/BlogContainer/ReviewForm";

const BlogForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const blogData = location.state?.blogData || {};

  
  const [author, setAuthor] = useState({
    name: blogData.author?.name || '',
    details: blogData.author?.details || '',
  });

  const [category, setCategory] = useState(blogData.category || '');
  const [heading, setHeading] = useState(blogData.heading || '');
  const [content, setContent] = useState(blogData.content || '');

  const [seo, setSeo] = useState({
    keywords: blogData.seo?.keywords || '',
    description: blogData.seo?.description || '',
    title: blogData.seo?.title || '',
  });

  const [permalink, setPermalink] = useState(blogData.permalink || '');
  const [image, setImage] = useState(null);
  const [imagePreviews, setImagePreviews] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const method = blogData._id ? 'PUT' : 'POST';
      const url = blogData._id
        ? `https://auth.ssccglpinnacle.com/api/blogs/${blogData._id}` 
        : 'https://auth.ssccglpinnacle.com/api/blogs'; 
  
      const formData = new FormData();
      formData.append('authorName', author.name);
      formData.append('authorDetails', author.details);
      formData.append('category', category);
      formData.append('heading', heading);
      formData.append('content', content);
      formData.append('seoKeywords', seo.keywords);
      formData.append('seoDescription', seo.description);
      formData.append('seoTitle', seo.title);
      formData.append('permalink', permalink);
  
      if (image) {
        formData.append('image', image);
      }
  
      const response = await fetch(url, {
        method,
        body: formData,
      });
  
      if (response.ok) {
        Swal.fire('Success', blogData._id ? 'Blog updated successfully!' : 'Blog created successfully!', 'success');
        
        
        setAuthor({ name: '', details: '' });
        setCategory('');
        setHeading('');
        setContent('');
        setSeo({ keywords: '', description: '', title: '' });
        setPermalink('');
        setImage(null);
        setImagePreviews([]);
  
        
        if (blogData._id) {
          navigate(0); 
        }
      } else {
        const errorData = await response.json();
        console.error('Error details:', errorData);
        Swal.fire('Error', `There was an error: ${errorData.message || 'Unknown error occurred'}`, 'error');
      }
    } catch (error) {
      console.error('Fetch error:', error);
      Swal.fire('Error', `There was an error: ${error.message || 'Unknown error occurred'}`, 'error');
    }
  };
  
  const handleContentChange = (value) => {
    setContent(value);
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    setImage(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreviews([...imagePreviews, reader.result]);
    };
    reader.readAsDataURL(file);
  };

  return (
    <>
      <h1 className="add-ebook-form">{blogData._id ? 'Edit Blog' : 'Create Blog'}</h1>
      <form className="unique-blog-form" onSubmit={handleSubmit}>
        <div className="unique-form-group">
          <label className="unique-form-label">Author Name:</label>
          <input
            className="unique-form-input"
            type="text"
            value={author.name}
            onChange={(e) => setAuthor({ ...author, name: e.target.value })}
          />
        </div>
        <div className="unique-form-group">
          <label className="unique-form-label">Author Details:</label>
          <input
            className="unique-form-input"
            type="text"
            value={author.details}
            onChange={(e) => setAuthor({ ...author, details: e.target.value })}
          />
        </div>
        <div className="unique-form-group">
          <label className="unique-form-label">Category:</label>
          <select
            className="unique-form-input"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          >
            <option value="">Select a category</option>
            <option value="SSC">SSC</option>
            <option value="RRB NTPC">RRB NTPC</option>
            <option value="Daily current affairs">Daily current affairs</option>
            <option value="Topic wise current affairs">Topic wise current affairs</option>
            <option value="Last one year current affairs">Last one year current affairs</option>
            <option value="Monthly current affairs">Monthly current affairs</option>
            <option value="Up police">Up police</option>
            <option value="HSSC">HSSC</option>
          </select>
        </div>
        <div className="unique-form-group">
          <label className="unique-form-label">Heading:</label>
          <input
            className="unique-form-input"
            type="text"
            value={heading}
            onChange={(e) => setHeading(e.target.value)}
          />
        </div>
        <div className="unique-form-group">
          <label className="unique-form-label">SEO Keywords:</label>
          <input
            className="unique-form-input"
            type="text"
            value={seo.keywords}
            onChange={(e) => setSeo({ ...seo, keywords: e.target.value })}
          />
        </div>
        <div className="unique-form-group">
          <label className="unique-form-label">SEO Description:</label>
          <input
            className="unique-form-input"
            type="text"
            value={seo.description}
            onChange={(e) => setSeo({ ...seo, description: e.target.value })}
          />
        </div>
        <div className="unique-form-group">
          <label className="unique-form-label">SEO Title:</label>
          <input
            className="unique-form-input"
            type="text"
            value={seo.title}
            onChange={(e) => setSeo({ ...seo, title: e.target.value })}
          />
        </div>
        <div className="unique-form-group">
          <label className="unique-form-label">Permalink:</label>
          <input
            className="unique-form-input"
            type="text"
            value={permalink}
            onChange={(e) => setPermalink(e.target.value)}
          />
        </div>
        <div className="unique-form-group">
          <label className="unique-form-label">Upload a Post Image:</label>
          <input
            className="unique-form-input"
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
          />
        </div>
        <div className="unique-form-group">
          <label className="unique-form-label">Content:</label>
          <ReviewForm
            content={content}
            onContentChange={handleContentChange}
            onImageUpload={handleImageUpload}
            imagePreviews={imagePreviews}
          />
        </div>
        <div className="create-blog">
        <button
  className="unique-form-button"
  type="submit"
  onClick={async (e) => {
    e.preventDefault();
    
    if (blogData._id) {
      await handleSubmit(e); 
      window.location.href = 'https://home.ssccglpinnacle.com/blog'; 
    } else {
      handleSubmit(e);
    }
  }}
>
  {blogData._id ? 'Update Blog' : 'Create Blog'}
</button>



        </div>
      </form>
    </>
  );
};

export default BlogForm;