import React, {useState} from 'react';
import "./Row7intro.css";
import { FaRegThumbsUp } from 'react-icons/fa';
import { MdArrowForwardIos } from 'react-icons/md';
import Recentlylaunch from "./RecentlylaunchBook";

function Row7intro() {
    
return (
    <>
    <div className = "for-intro-row7-image-introproduct-book">
     < Recentlylaunch/>
    </div>
    </>
  );
}

export default Row7intro;
