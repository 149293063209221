import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import classes from "./InstructorDetails.module.css";
import { BsFillStarFill, BsPeopleFill } from "react-icons/bs";
import { FaRegMoneyBillAlt, FaChalkboardTeacher } from "react-icons/fa";

export default function InstructorDetails() {
  const [instructor, setInstructor] = useState(null);
  const { courseId } = useParams();

  useEffect(() => {
    async function fetchInstructorDetails() {
      try {
        const courseResponse = await fetch(
          `https://auth.ssccglpinnacle.com/course/${courseId}`
        );
        const courseData = await courseResponse.json();
        const instructorResponse = await fetch(
          `https://auth.ssccglpinnacle.com/instructors/${courseData.instructorName}`
        );
        const instructorData = await instructorResponse.json();

        if (instructorData.success) {
          setInstructor(instructorData.instructor);
        }
      } catch (error) {
        console.error("Failed to fetch instructor details:", error);
      }
    }

    fetchInstructorDetails();
  }, [courseId]);

  return (
    <div className={classes["FacultyComponent-fullPage"]}>
      {instructor ? (
        <div className={classes.instructorContainer}>
          {/* Instructor Image on the left */}

          {/* Instructor Details on the right */}
          <div className={classes.instructorDetails}>
            <div className={classes["FacultyComponent-image-section"]}>
              <img
                src={instructor.imageUrl}
                alt="Instructor"
                className={classes.circularImage}
              />
            </div>
            <div className={classes.instructorHeader}>
              <h4 className={classes.instructorName}>
                {instructor.instructorName}
              </h4>
              <span className={classes.instructorTitle}>Lead Instructor</span>
              <div className={classes.statItem}>
                <BsFillStarFill className={classes.statIcon} />
                4.7 Instructor Rating
              </div>
            </div>

            {/* Optional Stats Section */}
            <div className={classes.statsSection}>
              {/* <div className={classes.statItem}>
                <BsPeopleFill className={classes.statIcon} />
                {instructor.reviews} Reviews
              </div>
              <div className={classes.statItem}>
                <FaRegMoneyBillAlt className={classes.statIcon} />
                {instructor.students} Students
              </div>
              <div className={classes.statItem}>
                <FaChalkboardTeacher className={classes.statIcon} />
                {instructor.courses} Courses
              </div> */}
            </div>
          </div>

          {/* Description Section */}
          <div
            className={classes.descriptionSection}
            dangerouslySetInnerHTML={{
              __html: instructor.instructorDescription,
            }}
          />
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}
