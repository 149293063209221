// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* FilterSelector.css */

.filter-selector {
    position: absolute;
top: 39px;
    left: 94.5%;/* Initially centered horizontally */
    transform: translateX(-50%) scale(0); /* Initially scaled down */
    transform-origin: top center;
    width: 0;
    height: 0;
    background-color: white;
   
    transition: transform 0.3s ease-in-out, width 0.3s ease-in-out, height 0.3s ease-in-out;
    animation: expandIn 0.3s forwards; /* Add animation */
}

@keyframes expandIn {
  0% {
    transform: translateX(-50%) scale(0);
    width: 0;
    height: 0;
  }
  100% {
    transform: translateX(-50%) scale(1); /* Scale up to 1:1 size */
    width: 250px; /* Adjust width as needed */
    height: 35%; /* Adjust height as needed */
  }
}

.selector-options {
  padding: 20px;
}



.close-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #333;
  color: white;
  border: none;
  cursor: pointer;
}

.close-button:hover {
  background-color: #555;
}
.option {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
      background-color: white;/* Add transition for smooth color change */
}

.option:hover {
  background-color: lightblue; /* Add the light blue color on hover */
}
`, "",{"version":3,"sources":["webpack://./src/Ebook/Info/FilterSelector.css"],"names":[],"mappings":"AAAA,uBAAuB;;AAEvB;IACI,kBAAkB;AACtB,SAAS;IACL,WAAW,CAAC,oCAAoC;IAChD,oCAAoC,EAAE,0BAA0B;IAChE,4BAA4B;IAC5B,QAAQ;IACR,SAAS;IACT,uBAAuB;;IAEvB,uFAAuF;IACvF,iCAAiC,EAAE,kBAAkB;AACzD;;AAEA;EACE;IACE,oCAAoC;IACpC,QAAQ;IACR,SAAS;EACX;EACA;IACE,oCAAoC,EAAE,yBAAyB;IAC/D,YAAY,EAAE,2BAA2B;IACzC,WAAW,EAAE,4BAA4B;EAC3C;AACF;;AAEA;EACE,aAAa;AACf;;;;AAIA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,sBAAsB;EACtB,YAAY;EACZ,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,sBAAsB;AACxB;AACA;EACE,aAAa;EACb,eAAe;EACf,sCAAsC;MAClC,uBAAuB,CAAC,2CAA2C;AACzE;;AAEA;EACE,2BAA2B,EAAE,sCAAsC;AACrE","sourcesContent":["/* FilterSelector.css */\n\n.filter-selector {\n    position: absolute;\ntop: 39px;\n    left: 94.5%;/* Initially centered horizontally */\n    transform: translateX(-50%) scale(0); /* Initially scaled down */\n    transform-origin: top center;\n    width: 0;\n    height: 0;\n    background-color: white;\n   \n    transition: transform 0.3s ease-in-out, width 0.3s ease-in-out, height 0.3s ease-in-out;\n    animation: expandIn 0.3s forwards; /* Add animation */\n}\n\n@keyframes expandIn {\n  0% {\n    transform: translateX(-50%) scale(0);\n    width: 0;\n    height: 0;\n  }\n  100% {\n    transform: translateX(-50%) scale(1); /* Scale up to 1:1 size */\n    width: 250px; /* Adjust width as needed */\n    height: 35%; /* Adjust height as needed */\n  }\n}\n\n.selector-options {\n  padding: 20px;\n}\n\n\n\n.close-button {\n  margin-top: 20px;\n  padding: 10px 20px;\n  background-color: #333;\n  color: white;\n  border: none;\n  cursor: pointer;\n}\n\n.close-button:hover {\n  background-color: #555;\n}\n.option {\n  padding: 10px;\n  cursor: pointer;\n  transition: background-color 0.3s ease;\n      background-color: white;/* Add transition for smooth color change */\n}\n\n.option:hover {\n  background-color: lightblue; /* Add the light blue color on hover */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
