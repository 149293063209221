// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-ebook {
  display: flex;
  flex-direction: column;
  padding-top: 60px; /* Adjust for header height */
}

.admin-ebook__container {
  display: flex;
  transition: margin-left 0.3s ease;
}

.admin-ebook__container.sidebar-hidden {
  margin-left: 0;
}

.admin-ebook__main-content {
  margin-left: 230px; /* Adjusted for sidebar width */
  padding: 20px;
  width: calc(100% - 230px); /* Adjusted for sidebar width */
  transition: margin-left 0.3s ease, width 0.3s ease;
  margin-top: -2rem;
}

.admin-ebook__container.sidebar-hidden .admin-ebook__main-content {
  margin-left: 0;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/Ebook/AdminPanel/AdminEbook.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,iBAAiB,EAAE,6BAA6B;AAClD;;AAEA;EACE,aAAa;EACb,iCAAiC;AACnC;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,kBAAkB,EAAE,+BAA+B;EACnD,aAAa;EACb,yBAAyB,EAAE,+BAA+B;EAC1D,kDAAkD;EAClD,iBAAiB;AACnB;;AAEA;EACE,cAAc;EACd,WAAW;AACb","sourcesContent":[".admin-ebook {\n  display: flex;\n  flex-direction: column;\n  padding-top: 60px; /* Adjust for header height */\n}\n\n.admin-ebook__container {\n  display: flex;\n  transition: margin-left 0.3s ease;\n}\n\n.admin-ebook__container.sidebar-hidden {\n  margin-left: 0;\n}\n\n.admin-ebook__main-content {\n  margin-left: 230px; /* Adjusted for sidebar width */\n  padding: 20px;\n  width: calc(100% - 230px); /* Adjusted for sidebar width */\n  transition: margin-left 0.3s ease, width 0.3s ease;\n  margin-top: -2rem;\n}\n\n.admin-ebook__container.sidebar-hidden .admin-ebook__main-content {\n  margin-left: 0;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
