import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styles from './AboutUs.module.css';
import Header from '../component/HeaderFiles/Header';
import Footer from "./Footer"

const AboutUsPage = () => {
    const [aboutUsContent, setAboutUsContent] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchAboutUsContent = async () => {
            try {
                const response = await axios.get('https://auth.ssccglpinnacle.com/api/footer');
                
                if (response.data && response.data.length > 0) {
                    console.log("Fetched About Us Data:", response.data[0].about_us);
                    setAboutUsContent(response.data[0].about_us || '');
                } else {
                    setError('Failed to load About Us content');
                }
            } catch (error) {
                console.error('Error fetching About Us content:', error);
                setError('An error occurred while fetching About Us content');
            } finally {
                setLoading(false);
            }
        };

        fetchAboutUsContent();
    }, []);

    return (
        <>
        <Header/>
        <div className={styles.aboutUsContainer}>
            {loading && <p>Loading About Us content...</p>}
            {error && <p className={styles.errorMessage}>{error}</p>}
            {!loading && !error && (
                <div dangerouslySetInnerHTML={{ __html: aboutUsContent }} />
            )}
        </div>
        <Footer/>
        </>
    );
};

export default AboutUsPage;
