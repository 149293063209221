import React, { useState, useEffect } from "react";
import axios from "axios";
import Styles from "./HoverWishlist.module.css";
import { FaRegHeart } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useAuth } from "../../../Context/AuthContext";

export default function HoverWishlist() {
  const [WISHLISTDATA, setWISHLISTDATA] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user, token } = useAuth();
  const userId = user?._id;

  useEffect(() => {
    if (!userId) {
      setLoading(false);
      return;
    }

    setLoading(true);
    const fetchWishlistCourses = async () => {
      try {
        const response = await axios.get(`https://auth.ssccglpinnacle.com/api/wishlist/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (Array.isArray(response.data)) {
          setWISHLISTDATA(response.data);
        } else {
          throw new Error("Failed to load wishlist courses.");
        }
      } catch (error) {
        console.error("Error fetching wishlist data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchWishlistCourses();
  }, [userId, token]);

  return (
    <div className={Styles.learning}>
      <div className={Styles.dropdown}>
        <button className={Styles.dropbtn}>
          <FaRegHeart size={30} className={Styles.wishlist_icon}/>
          {WISHLISTDATA.length > 0 && (
            <span className={Styles.badge}>{WISHLISTDATA.length}</span>
          )}
        </button>
        {WISHLISTDATA.length > 0 ? (
          <div className={Styles.dropdown_content}>
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div className={Styles.myLearningContainer}>
                {WISHLISTDATA.map((data, index) => (
                  <div key={index} className={Styles["CartList-ul"]}>
                    <div className={Styles["both-ImageSection-descriptionSection"]}>
                      <div className={Styles["CartList-ImageSection"]}>
                        <img src={data.englishCoverImage} alt="course image" className={Styles["Image"]}/>
                      </div>
                      <div className={Styles["CartList-descriptionSection"]}>
                        <div className={Styles["courseName"]}>{data.courseTitle}</div>
                        <div className={Styles["faculty"]}>{data.instructorName}</div>
                      </div>
                    </div>
                  </div>
                ))}
                <Link to={"/wishlist"} className={Styles["GoToWishlist-btn"]}>Go to Wishlist</Link>
              </div>
            )}
          </div>
        ) : (
          <div className={Styles.dropdown_content}>
            <div className={Styles["CartList-ul"]}>
              <div className={Styles["both-ImageSection-descriptionSection"]}>
                <div className={Styles["CartList-descriptionSection"]}>
                  No items in Wishlist
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}


// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import Styles from "./HoverWishlist.module.css";
// import { FaRegHeart } from "react-icons/fa";
// import { Link } from "react-router-dom";
// import { useAuth } from "../../../Context/AuthContext";

// export default function HoverWishlist() {
//   const [WISHLISTDATA, setWISHLISTDATA] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const { user, token } = useAuth();
//   const userId = user?._id;

//   useEffect(() => {
//     if (!userId) {
//       setLoading(false);
//       return;
//     }

//     setLoading(true);
//     const fetchWishlistCourses = async () => {
//       try {
//         const response = await axios.get(`https://auth.ssccglpinnacle.com/api/wishlist/${userId}`, {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         });
//         if (Array.isArray(response.data)) {
//           setWISHLISTDATA(response.data);
//         } else {
//           throw new Error("Failed to load wishlist courses.");
//         }
//       } catch (error) {
//         console.error("Error fetching wishlist data:", error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchWishlistCourses();
//   }, [userId, token]);

//   return (
//     <div className={Styles.learning}>
//       <div className={Styles.dropdown}>
//         <button className={Styles.dropbtn}>
//           <FaRegHeart size={30} className={Styles.wishlist_icon}/>
//         </button>
//         {WISHLISTDATA.length > 0 ? (
//           <div className={Styles.dropdown_content}>
//             {loading ? (
//               <p>Loading...</p>
//             ) : (
//               <div className={Styles.myLearningContainer}>
//                 {WISHLISTDATA.map((data, index) => (
//                   <div key={index} className={Styles["CartList-ul"]}>
//                     <div className={Styles["both-ImageSection-descriptionSection"]}>
//                       <div className={Styles["CartList-ImageSection"]}>
//                         <img src={data.englishCoverImage} alt="course image" className={Styles["Image"]}/>
//                       </div>
//                       <div className={Styles["CartList-descriptionSection"]}>
//                         <div className={Styles["courseName"]}>{data.courseTitle}</div>
//                         <div className={Styles["faculty"]}>{data.instructorName}</div>
//                       </div>
//                     </div>
//                   </div>
//                 ))}
//                 <Link to={"/wishlist"} className={Styles["GoToWishlist-btn"]}>Go to Wishlist</Link>
//               </div>
//             )}
//           </div>
//         ) : (
//           <div className={Styles.dropdown_content}>
//             <div className={Styles["CartList-ul"]}>
//                     <div className={Styles["both-ImageSection-descriptionSection"]}>
//                       <div className={Styles["CartList-ImageSection"]}>
//                       </div>
//                       <div className={Styles["CartList-descriptionSection"]}>
//                       No items in Wishlist
//                       </div>
//                     </div>
//                   </div>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// }


// import React, { useState, useEffect } from "react";
// import axios from 'axios';
// import Styles from "./HoverWishlist.module.css";
// import { FaRegHeart } from "react-icons/fa";
// import { Link } from "react-router-dom";

// export default function Hoverwishlist({ userId }) {
//   const [WISHLISTDATA, setWISHLISTDATA] = useState([]);
//   const [loading, setLoading] = useState(false);

//   useEffect(() => {
//     if (!userId) {
//       setLoading(false);
//       return;
//     }

//     setLoading(true);
//     const fetchWishlistCourses = async () => {
//       try {
//         const response = await axios.get(`https://auth.ssccglpinnacle.com/wishlist/${userId}`);
//         if (Array.isArray(response.data)) {
//           setWISHLISTDATA(response.data);
//         } else {
//           throw new Error("Failed to load wishlist courses.");
//         }
//       } catch (error) {
//         console.error("Error fetching wishlist data:", error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchWishlistCourses();
//   }, [userId]);

//   return (
//     <div className={Styles.learning}>
//       <div className={Styles.dropdown}>
//         <button className={Styles.dropbtn}>
//           <FaRegHeart size={30}/>
//         </button>
//         {WISHLISTDATA.length > 0 ? (
//           <div className={Styles.dropdown_content}>
//             {loading ? <p>Loading...</p> : (
//               <div className={Styles.myLearningContainer}>
//                 {WISHLISTDATA.map((data, index) => (
//                   <div key={index} className={Styles["CartList-ul"]}>
//                     <div className={Styles["both-ImageSection-descriptionSection"]}>
//                       <div className={Styles["CartList-ImageSection"]}>
//                         <img src={data.englishCoverImage} alt="course image" className={Styles["Image"]} />
//                       </div>
//                       <div className={Styles["CartList-descriptionSection"]}>
//                         <div className={Styles["courseName"]}>{data.courseTitle}</div>
//                         <div className={Styles["faculty"]}>{data.instructorName}</div>
//                       </div>
//                     </div>
//                   </div>
//                 ))}
//                 <Link to={"/videos/wishlist"} className={Styles["GoToCart-btn"]}>Go to Wishlist</Link>
//               </div>
//             )}
//           </div>
//         ) : (
//           <div className={Styles.dropdown_content}>
//             <p>No items in Wishlist</p>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// }