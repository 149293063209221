// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .noteEditor {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 15px;
    margin-top: 20px; 
  }
  
  .toolbar {
    border-bottom: 1px solid #ddd;
    margin-bottom: 10px;
    padding-bottom: 5px;
  }
  
  .toolbar button {
    background-color: #f5f5f5;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    font-size: 14px;
    margin-right: 5px;
    padding: 5px 10px;
    outline: none; 
  }
  
  .toolbar button:hover, button:focus {
    background-color: #e6e6e6;
  }
  
  .editorCustomStyle {
    cursor: text;
    min-height: 140px; 
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-top: 5px;
  }
  
  .linkInputContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  
  .linkInput {
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 8px;
    flex-grow: 1;
    margin-right: 10px;
  }
  
  .confirmLinkButton {
    background-color: #4285f4;
    color: white;
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .confirmLinkButton:hover {
    background-color: #357ae8;
  }
  
  .saveButton {
    padding: 10px 20px;
    font-size: 1rem;
    color: white;
    background-color: #4CAF50; 
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .saveButton:hover {
    background-color: #45a049;
  }
  
  .saveButton:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  
   */`, "",{"version":3,"sources":["webpack://./src/VIDEOPLAYER COMPONENTS/components/VideoPlayerPage/Navigations Components/NotesEditor/NoteEditor.module.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;IAsFI","sourcesContent":["/* .noteEditor {\n    background-color: #fff;\n    border: 1px solid #ddd;\n    border-radius: 4px;\n    padding: 15px;\n    margin-top: 20px; \n  }\n  \n  .toolbar {\n    border-bottom: 1px solid #ddd;\n    margin-bottom: 10px;\n    padding-bottom: 5px;\n  }\n  \n  .toolbar button {\n    background-color: #f5f5f5;\n    border: none;\n    border-radius: 3px;\n    cursor: pointer;\n    font-size: 14px;\n    margin-right: 5px;\n    padding: 5px 10px;\n    outline: none; \n  }\n  \n  .toolbar button:hover, button:focus {\n    background-color: #e6e6e6;\n  }\n  \n  .editorCustomStyle {\n    cursor: text;\n    min-height: 140px; \n    padding: 10px;\n    border: 1px solid #ddd;\n    border-radius: 4px;\n    margin-top: 5px;\n  }\n  \n  .linkInputContainer {\n    display: flex;\n    justify-content: space-between;\n    margin-bottom: 10px;\n  }\n  \n  .linkInput {\n    border: 1px solid #ddd;\n    border-radius: 4px;\n    padding: 8px;\n    flex-grow: 1;\n    margin-right: 10px;\n  }\n  \n  .confirmLinkButton {\n    background-color: #4285f4;\n    color: white;\n    padding: 8px 12px;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n  }\n  \n  .confirmLinkButton:hover {\n    background-color: #357ae8;\n  }\n  \n  .saveButton {\n    padding: 10px 20px;\n    font-size: 1rem;\n    color: white;\n    background-color: #4CAF50; \n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n    transition: background-color 0.3s ease;\n  }\n  \n  .saveButton:hover {\n    background-color: #45a049;\n  }\n  \n  .saveButton:disabled {\n    background-color: #ccc;\n    cursor: not-allowed;\n  }\n  \n  \n   */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
