import React, { useRef, useEffect, useState } from "react";
import styles from "./sscCategory.module.css";
import { AiFillLeftCircle, AiFillRightCircle } from "react-icons/ai";
import gsap from "gsap";
import { useNavigate } from "react-router-dom";
import NavbarVC from "./NavBarVC";
import { FaAnglesDown,FaAnglesUp } from "react-icons/fa6";

export default function DelhiPoliceCategory() {
  const navigate = useNavigate();
  const [selectedCategory, setSelectedCategory] = useState("Delhi Police");
  const [course, setCourse] = useState([]);
  const scrollRef = useRef(null);
  const courseRef = useRef(null);

  useEffect(() => {
    fetch("https://auth.ssccglpinnacle.com/api/videoCourses/DelhiPolice")
      .then((response) => response.json())
      .then((data) => {
        setCourse(Array.isArray(data) ? data : []);
      })
      .catch((error) => console.error("Error fetching courses:", error));
  }, []);

  const scroll = (shift) => {
    const targetScroll = scrollRef.current.scrollLeft + shift;
    gsap.to(scrollRef.current, {
      scrollLeft: targetScroll,
      duration: 0.6,
      ease: "power2.inOut",
    });
  };

  const scrollToCourses = () => {
    courseRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const scrollToBottom = () => {
    window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
  };

  const categories = ["Delhi Police"];

  const categoryHandler = (category) => {
    setSelectedCategory(category);
  };

  return (
    <>
      <NavbarVC />
      <div className={styles["ssc-category-div"]}>
        <div className={styles["EM-header"]}>
          <div className={styles["EM-header-container01"]}>
            <img
              src="https://d3m4h509gttb94.cloudfront.net/Image/DelhiPoliceLogo.png"
              alt="Delhi Police Logo"
              className={styles["EM-image"]}
            />
          </div>
          <div className={styles["EM-header-container02"]}>
            <div className={styles["EM-header-heading"]}>
              {selectedCategory}
            </div>
            <button
              onClick={scrollToCourses}
              className={styles["allCourses-scrollButton"]}
            >
              Show All Delhi Police Courses
            </button>
            <button onClick={scrollToBottom} className={styles["allCourses-scrollButton"]}>
             < FaAnglesDown /> 
            </button>
            <div className={styles["EM-header-para"]}>
              Detailed examination and recruitment process for selected
              category: {selectedCategory}.
            </div>
          </div>
        </div>

        <div className={styles["EM-body"]}>
          <div className={styles["EM-body-container1"]}>
            {categories.map((cat) => (
              <div key={cat} className={styles["EM-body-buttons"]}>
                <button
                  className={styles["EM-header-indi-butBtn"]}
                  onClick={() => categoryHandler(cat)}
                >
                  {cat}
                </button>
              </div>
            ))}
          </div>
          <div className={styles["EM-body-container2"]} ref={courseRef}>
            {selectedCategory === "Delhi Police" && (
              <div>
                <h1 className={styles["EM-header-heading"]}>
                  Delhi Police Head Constable Exam Overview
                </h1>

                <section className={styles["dpPoliceSection"]}>
                  <h2 className={styles["EM-header-heading"]}>
                    Eligibility Criteria
                  </h2>
                  <div className={styles["EM-header-para"]}>
                    <ul>
                      <li>
                        Nationality: Only Indian citizens are eligible to apply.
                      </li>
                      <li>
                        Educational Qualification: Candidates must have passed
                        10+2 (Class 12th) from a recognized board with Maths and
                        Science as subjects, or hold a National Trade
                        Certificate (NTC) in Mechanic-cum-Operator Electronic
                        Communication System.
                      </li>
                      <li>
                        Age Limit: The minimum age is 18 years, and the maximum
                        age is 27 years (as of 01.01.2024). Age relaxations are
                        applicable for SC/ST, OBC, and other categories as per
                        government norms.
                      </li>
                      <li>
                        Skills Required: Typing speed of 30 words per minute in
                        English or 25 words per minute in Hindi, and basic
                        computer skills including word processing, formatting,
                        and use of MS Office tools.
                      </li>
                    </ul>
                  </div>
                </section>

                <section className={styles["dpPoliceSection"]}>
                  <h2 className={styles["EM-header-heading"]}>
                    Selection Process
                  </h2>
                  <div className={styles["EM-header-para"]}>
                    <ul>
                      <li>
                        Computer-Based Examination (CBT): Objective-type
                        questions with a total of 100 marks.
                      </li>
                      <li>
                        Physical Endurance & Measurement Test (PE&MT):
                        Candidates must meet specific physical standards and
                        pass endurance tests.
                      </li>
                      <li>
                        Typing Test on Computer: Tests candidates on their
                        typing speed and accuracy.
                      </li>
                      <li>
                        Computer (Formatting) Test: Assesses proficiency in MS
                        Word, MS PowerPoint, and MS Excel.
                      </li>
                    </ul>
                  </div>
                </section>

                <section className={styles["dpPoliceSection"]}>
                  <h2 className={styles["EM-header-heading"]}>Exam Pattern</h2>
                  <div className={styles["EM-header-para"]}>
                    <ul>
                      <li>Mode of Exam: The exam is conducted online (CBT).</li>
                      <li>
                        Type of Questions: The exam consists of multiple-choice
                        questions (MCQs).
                      </li>
                      <li>Total Marks: The exam is scored out of 100 marks.</li>
                      <li>
                        Negative Marking: There is a penalty of 0.5 marks for
                        each incorrect answer.
                      </li>
                      <li>
                        Duration: The total duration of the exam is 90 minutes.
                      </li>
                    </ul>
                  </div>
                </section>

                <section className={styles["dpPoliceSection"]}>
                  <h2 className={styles["EM-header-heading"]}>Syllabus</h2>
                  <div className={styles["EM-header-para"]}>
                    <ul>
                      <li>
                        General Awareness: Includes topics like current events,
                        general polity, Indian Constitution, and scientific
                        research.
                      </li>
                      <li>
                        English Language: Focuses on grammar, vocabulary,
                        comprehension, and writing skills.
                      </li>
                      <li>
                        Quantitative Aptitude: Covers arithmetic, algebra,
                        geometry, trigonometry, statistics, and data
                        interpretation.
                      </li>
                      <li>
                        General Intelligence: Tests logical reasoning, pattern
                        recognition, and problem-solving skills.
                      </li>
                      <li>
                        Computer Fundamentals: Basics of computers, internet, MS
                        Office tools, and general IT knowledge.
                      </li>
                    </ul>
                  </div>
                </section>

                <section className={styles["dpPoliceSection"]}>
                  <h2 className={styles["EM-header-heading"]}>
                    Preparation Tips
                  </h2>
                  <div className={styles["EM-header-para"]}>
                    <ul>
                      <li>
                        Understand the exam pattern and syllabus thoroughly.
                      </li>
                      <li>
                        Regularly practice previous years' question papers and
                        mock tests to improve time management and identify weak
                        areas.
                      </li>
                      <li>
                        Focus on building physical fitness to clear the PE&MT
                        standards.
                      </li>
                      <li>
                        Stay updated with current affairs and regularly revise
                        key topics in general knowledge and numerical ability.
                      </li>
                    </ul>
                  </div>
                </section>

                <section className={styles["dpPoliceSection"]}>
                  <h2 className={styles["EM-header-heading"]}>
                    Admit Card and Result
                  </h2>
                  <div className={styles["EM-header-para"]}>
                    <ul>
                      <li>
                        Admit Card: Issued online by SSC, containing details
                        about the exam center, date, and time.
                      </li>
                      <li>
                        Result: Results are declared on the official website
                        after the examination. Successful candidates proceed to
                        the next stages of the selection process.
                      </li>
                    </ul>
                  </div>
                </section>

                <section className={styles["dpPoliceSection"]}>
                  <h2 className={styles["EM-header-heading"]}>
                    Career Prospects
                  </h2>
                  <div className={styles["EM-header-para"]}>
                    <ul>
                      <li>
                        Selected candidates are appointed as Head Constables in
                        the Delhi Police with a salary ranging from INR 25,500
                        to INR 81,100, along with various allowances.
                      </li>
                      <li>
                        Career growth opportunities include promotions to higher
                        ranks based on experience, performance, and additional
                        qualifications.
                      </li>
                    </ul>
                  </div>
                </section>
              </div>
            )}
          </div>
        </div>

        <div className={styles["related-course"]}>
          <button
            onClick={() => scroll(-270)}
            className={styles["sliderButton-left"]}
          >
            <AiFillLeftCircle size={30} />
          </button>
          <div className={styles["Course_Heading"]}>
            Courses to get you started
          </div>
          <div className={styles["scroll-container"]} ref={scrollRef}>
            <div className={styles["card-wrapper"]}>
            {course.map(course => (
                  <div key={course._id} className={styles['card']} onClick={() => navigate(`/course/description/${course._id}`)}>
                    <div className={styles['Image-section']}>
                      <img className={styles.imagecard} src={course.hindiCoverImage} alt="Hindi Cover" />
                      <img className={styles.imagecard} src={course.englishCoverImage} alt="English Cover" />
                    </div>
                    <div className={styles['description-section']}>
                      <div className={styles['title']}>{course.courseTitle}</div>
                  
                      {/* <div className={styles['teacher']}>Mr./Ms. {course.instructorName}</div> */}
                      <div className={styles['gst']}>18% GST included</div>
                      <div className={styles["price-fprice-div"]}>
                    
                    <div  className={styles["price"]}>₹ {course.price}</div>&nbsp;&nbsp;&nbsp;&nbsp;
                      <div className={styles["mrp"]}><s>₹{course.mrp}</s></div>
                      <div className={styles["savings"]}>
                          Save{" "}
                          {Math.round(
                            ((course.mrp - course.price) / course.mrp) * 100
                          )}
                          %
                        </div>
                    </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      <div className={styles["scroll-buttons"]}><button onClick={scrollToTop} className={styles["allCourses-scrollButton"]}><FaAnglesUp/></button></div>
    </>
  );
}