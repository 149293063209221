// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VideoPlayerNavBar_videoNavbar__sDDoB {
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #2D2F31;
  padding: 5px 10px;
  overflow-x: auto;
  white-space: nowrap;
  background-color: #f3ecec; 
}

.VideoPlayerNavBar_navlinks__eWh9G {
  color: #2D2F31;
  padding: 5px 10px; 
  text-decoration: none;
  display: flex;
  align-items: center;
  margin-right: 10px; 
}

.VideoPlayerNavBar_navlinks__eWh9G:last-of-type {
  margin-right: 0;
}

.VideoPlayerNavBar_navlinks__eWh9G.VideoPlayerNavBar_active__L2yMu {
  border-bottom: 2px solid #2D2F31; 
  /* text-decoration: underline;  */
}

@media (max-width: 768px) {
  .VideoPlayerNavBar_videoNavbar__sDDoB {
    justify-content: start; 
  }
  
  .VideoPlayerNavBar_navlinks__eWh9G {
    padding: 10px 8px; 
  }
}

@media (max-width: 480px) {
  .VideoPlayerNavBar_navlinks__eWh9G {
    padding: 5px; 
  }
}

.VideoPlayerNavBar_content__EAQmX {
  padding: 20px;
  max-width: 800px;
  margin: auto;
}

.VideoPlayerNavBar_sidebar__GkdF3 {
  width: 250px; 

}
`, "",{"version":3,"sources":["webpack://./src/layouts/VideoPlayerNavBar.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,iBAAiB;EACjB,cAAc;EACd,iBAAiB;EACjB,gBAAgB;EAChB,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE,cAAc;EACd,iBAAiB;EACjB,qBAAqB;EACrB,aAAa;EACb,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,gCAAgC;EAChC,iCAAiC;AACnC;;AAEA;EACE;IACE,sBAAsB;EACxB;;EAEA;IACE,iBAAiB;EACnB;AACF;;AAEA;EACE;IACE,YAAY;EACd;AACF;;AAEA;EACE,aAAa;EACb,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,YAAY;;AAEd","sourcesContent":[".videoNavbar {\n  display: flex;\n  align-items: center;\n  font-weight: bold;\n  color: #2D2F31;\n  padding: 5px 10px;\n  overflow-x: auto;\n  white-space: nowrap;\n  background-color: #f3ecec; \n}\n\n.navlinks {\n  color: #2D2F31;\n  padding: 5px 10px; \n  text-decoration: none;\n  display: flex;\n  align-items: center;\n  margin-right: 10px; \n}\n\n.navlinks:last-of-type {\n  margin-right: 0;\n}\n\n.navlinks.active {\n  border-bottom: 2px solid #2D2F31; \n  /* text-decoration: underline;  */\n}\n\n@media (max-width: 768px) {\n  .videoNavbar {\n    justify-content: start; \n  }\n  \n  .navlinks {\n    padding: 10px 8px; \n  }\n}\n\n@media (max-width: 480px) {\n  .navlinks {\n    padding: 5px; \n  }\n}\n\n.content {\n  padding: 20px;\n  max-width: 800px;\n  margin: auto;\n}\n\n.sidebar {\n  width: 250px; \n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"videoNavbar": `VideoPlayerNavBar_videoNavbar__sDDoB`,
	"navlinks": `VideoPlayerNavBar_navlinks__eWh9G`,
	"active": `VideoPlayerNavBar_active__L2yMu`,
	"content": `VideoPlayerNavBar_content__EAQmX`,
	"sidebar": `VideoPlayerNavBar_sidebar__GkdF3`
};
export default ___CSS_LOADER_EXPORT___;
