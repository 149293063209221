import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./SearchBar.module.css";
import { FaSearch } from "react-icons/fa";

function SearchBar() {
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  const handleSearch = (event) => {
    event.preventDefault();

    if (searchTerm.trim()) {
      navigate(`/videoSearch?query=${encodeURIComponent(searchTerm)}`);
    }
  };

  return (
    <form onSubmit={handleSearch} className={styles["search_form"]}>
      <input
        type="text"
        name="product-search"
        id="product-search"
        placeholder="Search for courses..."
        value={searchTerm}
        className={styles["search_container"]}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
    </form>
  );
}

export default SearchBar;


// import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
// import styles from "./SearchBar.module.css";
// import { FaSearch } from "react-icons/fa";

// function SearchBar() {
//   const [searchTerm, setSearchTerm] = useState("");
//   const navigate = useNavigate();

//   const handleSearch = async (event) => {
//     event.preventDefault();

//     if (searchTerm.trim()) {
//       navigate(`/videoSearch?query=${encodeURIComponent(searchTerm)}`);
//     }
//   };

//   return (
//     <form onSubmit={handleSearch} className={styles["search_form"]}>
//       <input
//         type="text"
//         name="product-search"
//         id="product-search"
//         placeholder="Search for courses..."
//         value={searchTerm}
//         className={styles["search_container"]}
//         onChange={(e) => setSearchTerm(e.target.value)}
//       />
//     </form>
//   );
// }

// export default SearchBar;



// import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
// import styles from "./SearchBar.module.css";
// import { FaSearch } from "react-icons/fa";

// function SearchBar() {
//   const [searchTerm, setSearchTerm] = useState("");
//   const navigate = useNavigate();

//   const handleSearch = async (event) => {
//     event.preventDefault();

//     if (searchTerm.trim()) {
//       // Navigate to the search results page with the search term as a query parameter
//       navigate(`/videoSearch?query=${encodeURIComponent(searchTerm)}`);
//     }
//   };

//   return (
//     <form onSubmit={handleSearch} className={styles["search_form"]}>
//       <input
//         type="text"
//         name="product-search"
//         id="product-search"
//         placeholder="Search for courses..."
//         value={searchTerm}
//         className={styles["search_container"]}
//         onChange={(e) => setSearchTerm(e.target.value)}
//       />
//       {/* <button type="submit" className={styles["search_button"]}>
//         <FaSearch />
//       </button> */}
//     </form>
//   );
// }

// export default SearchBar;
// import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
// import styles from "./SearchBar.module.css";
// import { FaSearch } from "react-icons/fa";

// function SearchBar() {
//   const [searchTerm, setSearchTerm] = useState("");
//   const navigate = useNavigate();

//   const handleSearch = (event) => {
//     event.preventDefault();
//     // console.log("Search submitted: ", searchTerm);
//     const keywordMap = {
//       SSC: "/videos/category/SSC",
//       Railway: "/videos/category/Railway",
//       "Delhi Police": "/videos/category/DelhiPolice",
//       UPPolice: "/videos/category/UpPolice",
//       CTET: "/videos/category/Ctet",
//       EBOOKS: "/digital-catalog",
//       TYPING: "/typing",
//       BOOKS: "/books-catalog",
//     };

//     const searchTermLower = searchTerm.toLowerCase();
//     let matched = false;

//     for (let keyword in keywordMap) {
//       if (searchTermLower.includes(keyword.toLowerCase())) {
//         // console.log("Navigating to: ", keywordMap[keyword]);
//         navigate(keywordMap[keyword]);
//         matched = true;
//         break;
//       }
//     }

//     if (!matched) {
//       navigate("/");
//     }
//   };

//   return (
//     <form onSubmit={handleSearch}>
//       <input
//         type="text"
//         name="product-search"
//         id="product-search"
//         placeholder="Search For Anything"
//         value={searchTerm}
//         className={styles["search_container"]}
//         onChange={(e) => setSearchTerm(e.target.value)}
//       />
//     </form>
//   );
// }

// export default SearchBar;
