import { Row, Col, Button } from "react-bootstrap";
import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import parse from "html-react-parser";
import './Typing.css';
import { useCookies } from 'react-cookie';
import { useAuth } from "../Context/AuthContext";

const TypingPerformance = () => { 
    const { accuracy, wrongper, actualdep, speed, testcode, exam, category } = useParams();
    const [paragraph, setParagraph] = useState('');
    const [wrongdep, setWrongdep] = useState('');
    const [grosspeed, setGrossSpeed] = useState('');
    const [wpm, setWpm] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const [cookies, setCookie, removeCookie] = useCookies(['token', 'userData']); 
    const { isLoggedIn, user,logout } = useAuth();
    let tracc = Math.round(accuracy * 3.6);
    let trwro = Math.round(wrongper * 3.6);    
    let tract = Math.round(actualdep * 3.6);  
    let trspe = Math.round(speed * 3.6);
  

   


    let emailId = '';

    // Extract and parse userData to get email_id
    if (cookies.userData) {
        try {
            let parsedUserData;

            // Check if userData is already an object
            if (typeof cookies.userData === 'string') {
                const decodedUserData = decodeURIComponent(cookies.userData); // Decode the URL-encoded string
                parsedUserData = JSON.parse(decodedUserData); // Parse the JSON string
            } else {
                parsedUserData = cookies.userData; // Assume it's already an object
            }

            emailId = parsedUserData.email_id || ''; // Extract email_id
        } catch (error) {
            console.error('Error processing userData cookie:', error);
        }
    }

     // Log the extracted emailId

    
    let colr, testresult;

console.log("wrongper:", wrongper, "error:", error); // Debugging line to check the values

if (wrongper < error) { 
    colr = '#1cff1c';             
    testresult = 'Pass';     
} else {            
    colr = '#ff7a7a';   
    testresult = 'Fail';
}

    useEffect(() => {
        const fetchPerformanceStatus = async () => {
            if (!cookies.token) {
                console.log("Token not found in cookies");
                navigate('/');
                return;
            }
      
            try {
                const response = await fetch('https://auth.ssccglpinnacle.com/api/checkAccessTyping', {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        "Authorization": `Bearer ${cookies.token}`
                    }
                });
      
                if (response.ok) {
                    const { access } = await response.json();
                    if (access === "access") {
                        const productResponse = await fetch('https://auth.ssccglpinnacle.com/api/checkAccessTypingProduct', {
                            method: 'POST',
                            headers: {
                                "Content-Type": "application/json",
                                "Accept": "application/json",
                                "Authorization": `Bearer ${cookies.token}`
                            },
                            body: JSON.stringify({ product_id: '999' }) // Replace with actual product ID
                        });
      
                        if (productResponse.ok) {
                            const { access } = await productResponse.json();
                            if (access === "access") {
                                let dt = { 'paper_code': testcode, 'email_id': emailId, 'exam': exam, 'category': category };
                                console.log("Request data:", dt); // Log the request data
                                let state_res = await fetch("https://auth.ssccglpinnacle.com/api/typingPerformanceStatusTest", {
                                    method: 'POST',
                                    headers: {
                                        "Content-Type": "application/json",
                                        "Accept": "application/json",
                                        "Authorization": `Bearer ${cookies.token}`
                                    },
                                    body: JSON.stringify(dt)
                                });
              
                                console.log("Response status:", state_res.status); // Log response status
                                console.log("Response status text:", state_res.statusText); // Log response status text
              
                                if (state_res.ok) {
                                    state_res = await state_res.json();
                                    setParagraph(parse(state_res.paragraph));
                                    setGrossSpeed(state_res.grossspeed);
                                    setWrongdep(state_res.wrongdep);
                                    setWpm(state_res.wpm);
                                    const errorValue = state_res.error < 0 ? 0 : state_res.error;
                                    setError(errorValue);
                                } else {
                                    console.error("Failed to fetch performance status", state_res.statusText);
                                }
                            } else {
                                navigate('/');
                            }
                        } else {
                            navigate('/');
                        }
                    } else {
                        navigate('/');
                    }
                } else {
                    navigate('/');
                }
            } catch (error) {
                navigate('/');
            }
        };
      
        fetchPerformanceStatus();
      }, [testcode, emailId, exam, category, cookies.token, navigate]);

    const startTest = () => {
        navigate(`/typingparagraph/${exam}/${category}`);
    };

    

    return (             
        <>                 
            <Row className="tp-fr tp-fr1">
                <Col md={12} className="tp-fr-col12">
                {user && user.email_id ? (
                    <p className="tp-fr-col12p">{user.email_id}</p>
                ) : (
                    <p className="tp-fr-col12p">Loading user data...</p>
                )}
                    <Button className="logout-button-last" onClick={logout}>Logout</Button>
                    <p className="tp-fr-col12p1">
                        <span className="tp-fr-col12p1-span1">SSC TYPING RESULT : </span>
                        {testresult === 'Pass' ? 
                            <span className="tp-fr-col12p1-span2" style={{color: '#1cff1c'}}>Pass</span> : 
                            <span className="tp-fr-col12p1-span2" style={{color: '#ff7a7a'}}>TRY AGAIN</span>
                        } 
                    </p>
                </Col>
            </Row>   
            <Row className="tp-fr2">               
                <Col md={2}>   
                    <Col className="c100 coloracuracy p90 blue">          
                        <span>{accuracy}%</span>    
                        <Col className="slice">
                            <Col className="bar" style={{transform: 'rotate(360deg)'}}></Col>
                            <Col className="fill"></Col>            
                        </Col>  
                    </Col>
                    <h3 className="text-align-center">Accuracy</h3>
                </Col>      
                <Col md={2}>         
                    <Col className="c100 colorspeed p90 blue">
                        <span>{speed}</span>
                        <Col className="slice">
                            <Col className="bar" style={{transform: 'rotate(360deg)'}}></Col>
                            <Col className="fill"></Col>
                        </Col>          
                    </Col>
                    <h3 className="text-align-center">Net Speed (WPM)</h3>
                </Col>
                <Col md={2}>
                    <Col className="c100 colorspeed p90 blue">
                        <span>{grosspeed}</span>
                        <Col className="slice">
                            <Col className="bar" style={{transform: 'rotate(360deg)'}}></Col>
                            <Col className="fill"></Col>
                        </Col>             
                    </Col>
                    <h3 className="text-align-center">Gross Speed (WPM)</h3>
                </Col>
                <Col md={2}>
                    <Col className="c100 colorspeed p90 blue">
                        <span>{wrongdep}</span>   
                        <Col className="slice">
                            <Col className="bar" style={{transform: 'rotate(360deg)'}}></Col>
                            <Col className="fill"></Col>
                        </Col>          
                    </Col>
                    <h3 className="text-align-center">Wrong (Depressions)</h3>
                </Col>
                <Col md={2}>
                    <Col className="c100 colorwrong p90 blue">            
                        <span>{wrongper}%</span>
                        <Col className="slice">
                            <Col className="bar" style={{transform: 'rotate(360deg)'}}></Col>
                            <Col className="fill"></Col>
                        </Col>                
                    </Col>
                    <h3 className="text-align-center">Error</h3>
                </Col>
                <Col md={2}>     
                    <Col className="c100 coloractual p90 blue">         
                        <span>{actualdep}</span>
                        <Col className="slice">
                            <Col className="bar" style={{transform: 'rotate(360deg)'}}></Col>
                            <Col className="fill"></Col>
                        </Col>
                    </Col>
                    <h3 className="text-align-center">Actual Depressions</h3>
                </Col>  
            </Row>
            <Row className="tp-fr3">      
                <Col md={12} className="tp-paragrah-col">       
                    {paragraph}
                </Col>
            </Row>
           
            <div className="dashboard-container">
            <p className="typing-notes-text">
                </p>
</div>

            

            <div className="dashboard-container">
    <div className="my-submit">       
        <button className="dashboard-submit-button" onClick={startTest}>Back to Dashboard</button>
    </div>
</div>
<div className="dashboard-container">
<div className="typing-notes-content">
    <strong style={{ color: 'black' }}>
    ** Notes:-&gt; Your Typed Paragraph: Typing Mistakes
        <strong style={{ color: 'red' }}> THE </strong>
        <strong style={{ color: 'green' }}>hte</strong>, And 
    </strong>
    <strong style={{ color: 'black' }}> Skipped are shown in </strong>
    <strong style={{ color: 'red' }}>red</strong>.
    <strong style={{ color: 'red' }}> Words or lines skipped are shown in red.</strong>
    <strong style={{ color: 'black' }}> Additional and wrong typed are shown in </strong>
    <strong style={{ color: 'green' }}>green.</strong>
    <strong style={{ color: 'green' }}> Words or lines Additional typed are shown in green.</strong>
</div>

</div>


        </>
    );
};

export default TypingPerformance;
