import React, { useState, useEffect } from "react";
import axios from "axios";
import Styles from "./HoverCart.module.css";
import { BsCart3 } from "react-icons/bs";
import { Link } from "react-router-dom";
import { useAuth } from "../../../Context/AuthContext";

export default function HoverCart() {
  const [CARTDATA, setCARTDATA] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user, token } = useAuth();
  const userId = user?._id;

  useEffect(() => {
    if (!userId) {
      setLoading(false);
      return;
    }
    setLoading(true);
    const fetchCartCourses = async () => {
      try {
        const response = await axios.get(
          `https://auth.ssccglpinnacle.com/api/cart/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (Array.isArray(response.data)) {
          setCARTDATA(response.data.filter((item) => item));
        } else {
          throw new Error("Failed to load cart courses.");
        }
      } catch (error) {
        // console.error("Error fetching cart data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCartCourses();
  }, [userId, token]);

  return (
    <div className={Styles.learning}>
      <div className={Styles.dropdown}>
        <button className={Styles.dropbtn}>
          <BsCart3 size={30} className={Styles.cart_icon} />
          {CARTDATA.length > 0 && (
            <span className={Styles.badge}>{CARTDATA.length}</span>
          )}
        </button>
        <div className={Styles.dropdown_content}>
          {loading ? (
            <p>Loading...</p>
          ) : CARTDATA.length > 0 ? (
            <div className={Styles.myLearningContainer}>
              {CARTDATA.map(
                (data, index) =>
                  data && (
                    <div key={index} className={Styles["CartList-ul"]}>
                      <div
                        className={
                          Styles["both-ImageSection-descriptionSection"]
                        }
                      >
                        <div className={Styles["CartList-ImageSection"]}>
                          {data.englishCoverImage ? (
                            <img
                              src={data.englishCoverImage}
                              alt="course image"
                              className={Styles["Image"]}
                            />
                          ) : (
                            <p>No image available</p>
                          )}
                        </div>
                        <div className={Styles["CartList-descriptionSection"]}>
                          <div className={Styles["courseName"]}>
                            {data.courseTitle || "Title not available"}
                          </div>
                          <div className={Styles["faculty"]}>
                            {data.instructorName || "Instructor not available"}
                          </div>
                          <div className={Styles["coursePrice"]}>
                            ₹{data.price?.toFixed(2) || "Price not available"}
                          </div>
                        </div>
                      </div>
                    </div>
                  )
              )}
              <Link to={"/cartPage"} className={Styles["GoToCart-btn"]}>
                Go to Cart
              </Link>
            </div>
          ) : (
            <div className={Styles.dropdown_content}>
              <div className={Styles["CartList-ul"]}>
                <div className={Styles["both-ImageSection-descriptionSection"]}>
                  <div className={Styles["CartList-descriptionSection"]}>
                    No items in Cart
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
