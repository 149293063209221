// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NavBar_EM-NavBar__1BHf0 {
    display: flex;
    align-items: center;
    background-color: #4189B6;
    padding: 10px 20px;
    color: white;
  }
  
  .NavBar_EM-navLinks__oABhW {
    margin-right: 10px;
    display: flex;
    align-items: center;
  }
  
  .NavBar_EM-navLink__4oaJW {
    text-decoration: none;
    color: #fff;
    font-size: 16px;
    padding: 5px 10px;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .NavBar_EM-navLink__4oaJW:hover, .NavBar_EM-navLink__4oaJW:focus {
    background-color: #555;
    color: #f0f0f0;
  }

  @media  (max-width: 546px) {
    .NavBar_EM-NavBar__1BHf0 {
    display: flex;
    align-items: center;
    background-color: #1a1a1a;
    padding: 10px 20px;
    color: white;
    /* overflow-x: auto; */
  }
}
.NavBar_Video-home-button__nJcEq{
  color: white;
  font-weight: 600;
}

  `, "",{"version":3,"sources":["webpack://./src/VcCategory/NavBar.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,yBAAyB;IACzB,kBAAkB;IAClB,YAAY;EACd;;EAEA;IACE,kBAAkB;IAClB,aAAa;IACb,mBAAmB;EACrB;;EAEA;IACE,qBAAqB;IACrB,WAAW;IACX,eAAe;IACf,iBAAiB;IACjB,kBAAkB;IAClB,6CAA6C;EAC/C;;EAEA;IACE,sBAAsB;IACtB,cAAc;EAChB;;EAEA;IACE;IACA,aAAa;IACb,mBAAmB;IACnB,yBAAyB;IACzB,kBAAkB;IAClB,YAAY;IACZ,sBAAsB;EACxB;AACF;AACA;EACE,YAAY;EACZ,gBAAgB;AAClB","sourcesContent":[".EM-NavBar {\n    display: flex;\n    align-items: center;\n    background-color: #4189B6;\n    padding: 10px 20px;\n    color: white;\n  }\n  \n  .EM-navLinks {\n    margin-right: 10px;\n    display: flex;\n    align-items: center;\n  }\n  \n  .EM-navLink {\n    text-decoration: none;\n    color: #fff;\n    font-size: 16px;\n    padding: 5px 10px;\n    border-radius: 5px;\n    transition: background-color 0.3s, color 0.3s;\n  }\n  \n  .EM-navLink:hover, .EM-navLink:focus {\n    background-color: #555;\n    color: #f0f0f0;\n  }\n\n  @media  (max-width: 546px) {\n    .EM-NavBar {\n    display: flex;\n    align-items: center;\n    background-color: #1a1a1a;\n    padding: 10px 20px;\n    color: white;\n    /* overflow-x: auto; */\n  }\n}\n.Video-home-button{\n  color: white;\n  font-weight: 600;\n}\n\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"EM-NavBar": `NavBar_EM-NavBar__1BHf0`,
	"EM-navLinks": `NavBar_EM-navLinks__oABhW`,
	"EM-navLink": `NavBar_EM-navLink__4oaJW`,
	"Video-home-button": `NavBar_Video-home-button__nJcEq`
};
export default ___CSS_LOADER_EXPORT___;
