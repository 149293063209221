import React from "react";
import pic1 from "../../Ebook/i/typing1.png";
import pic2 from "../../Ebook/i/typing2.png";
import pic3 from "../../Ebook/i/typing3.png";
import pic4 from "../../Ebook/i/typing4.png";
import pic5 from "../../Ebook/i/typing5.png";
import pic6 from "../../Ebook/i/typing6.png";
import pic7 from "../../Ebook/i/typing7.png";
import pic8 from "../../Ebook/i/typing8.png";
import pic9 from "../../Ebook/i/typing9.png";
import "./Row4.css";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { useState, useRef } from "react";
import { Link } from 'react-router-dom';
import gsap from "gsap";

function Rowtyping() {


  //Slide click
  let scrl = useRef(null);
  const [scrollX, setscrollX] = useState(10);
  const [scrolEnd, setscrolEnd] = useState(false);

  const slide = (shiftMultiplier) => {
    const cardWidth = scrl.current.querySelector('.card-for-test').offsetWidth; // Width of one card
    const shift = cardWidth * 5 * shiftMultiplier; // Calculate the scroll distance for 4 cards
    let targetScroll = scrl.current.scrollLeft + shift;
    
    // Calculate the maximum scrollable position
    const maxScroll = scrl.current.scrollWidth - scrl.current.offsetWidth;
    
    // Clamp the target scroll to not exceed the maximum
    if (targetScroll > maxScroll) {
      targetScroll = maxScroll;
    }
    
    if (targetScroll < 0) {
      targetScroll = 0;
    }
    
    // Apply GSAP animation to scroll to the target position smoothly
    gsap.to(scrl.current, {
      scrollLeft: targetScroll,
      duration: 0.6,
      ease: "power2.inOut",
      onComplete: () => {
        setscrollX(scrl.current.scrollLeft);
    
        // Update the state to determine if the scroll has reached the end
        if (scrl.current.scrollLeft >= maxScroll) {
          setscrolEnd(true);
        } else {
          setscrolEnd(false);
        }
      },
    });
  };
  
  const scrollCheck = () => {
    setscrollX(scrl.current.scrollLeft);
  
    // Check if the scroll has reached the end
    if (Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <= scrl.current.offsetWidth) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };

  return (
    <>
      <section className="section-suggest-test">
        <div className="container-fluid-test">
          <div className="foruppersection">
            <div className="col-md-8 section-suggest-test-col-8">
              <p className="col-md-8 section-suggest-test-p">
                Suggested for You: Typing
              </p>
            </div>
          </div>
          <div className="container-for-button">
            {scrollX !== 5 && (
              <button className="prev" onClick={() => slide(-1)}>
              <AiOutlineLeft color="black" fontSize="1.5em" />
            </button>
            )}

            <div className="forcontainertest">
              <ul className="cards-for-test" ref={scrl} onScroll={scrollCheck}>
                <li className="card-for-test">
                  <div>
                    <div className="card-title-image1">
                      <img
                        className="card-title1"
                        src="https://images.indianexpress.com/2018/12/SSClogo759.jpg"
                      />
                      <span className="p-04 subscribe-batch">
                        <img src="https://ssccglpinnacle.com/myaccount/images/trending-icon.png" />
                        &nbsp;1.5K Students
                      </span>
                    </div>
                    <div className="card-content1">
                      <img className="typing-pic" src={pic1} alt="SSC" />
                    </div>

                  
                      <div className="text-center">
                      <Link to={`/typing`}><button className="btn btn-sm button-for-test">
                          View Typing
                        </button></Link>
                      </div>
                  
                  </div>
                </li>
                <li className="card card-for-test">
                  <div>
                    <div className="card-title-image1">
                      <img
                        className="card-title1"
                        src="https://images.indianexpress.com/2018/12/SSClogo759.jpg"
                      />
                      <span className="p-04 subscribe-batch">
                        <img src="https://ssccglpinnacle.com/myaccount/images/trending-icon.png" />
                        &nbsp;1.5K Students
                      </span>
                    </div>
                    <div className="card-content1">
                      <img className="typing-pic" src={pic2} alt="SSC" />
                    </div>

                   
                      <div className="text-center">
                      <Link to={`/typing`}><button className="btn btn-sm button-for-test">
                          View Typing
                        </button></Link>
                      </div>
                   
                  </div>
                </li>

                <li className="card card-for-test">
                  <div>
                    <div className="card-title-image1">
                      <img
                        className="card-title1"
                        src="https://images.indianexpress.com/2018/12/SSClogo759.jpg"
                      />
                      <span className="p-04 subscribe-batch">
                        <img src="https://ssccglpinnacle.com/myaccount/images/trending-icon.png" />
                        &nbsp;1.5K Students
                      </span>
                    </div>
                    <div className="card-content1">
                      <img className="typing-pic" src={pic3} alt="SSC" />
                    </div>

                    
                      <div className="text-center">
                      <Link to={`/typing`}><button className="btn btn-sm button-for-test">
                          View Typing
                        </button></Link>
                      </div>
                   
                  </div>
                </li>

                <li className="card card-for-test">
                  <div>
                    <div className="card-title-image1">
                      <img
                        className="card-title1"
                        src="https://images.indianexpress.com/2018/12/SSClogo759.jpg"
                      />
                      <span className="p-04 subscribe-batch">
                        <img src="https://ssccglpinnacle.com/myaccount/images/trending-icon.png" />
                        &nbsp;1.5K Students
                      </span>
                    </div>
                    <div className="card-content1">
                      <img className="typing-pic" src={pic4} alt="SSC" />
                    </div>

                   
                      <div className="text-center">
                      <Link to={`/typing`}><button className="btn btn-sm button-for-test">
                          View Typing
                        </button></Link>
                      </div>
                 
                  </div>
                </li>

                <li className="card card-for-test">
                  <div>
                    <div className="card-title-image1">
                      <img
                        className="card-title1"
                        src="https://images.indianexpress.com/2018/12/SSClogo759.jpg"
                      />
                      <span className="p-04 subscribe-batch">
                        <img src="https://ssccglpinnacle.com/myaccount/images/trending-icon.png" />
                        &nbsp;1.5K Students
                      </span>
                    </div>
                    <div className="card-content1">
                      <img className="typing-pic" src={pic5} alt="SSC" />
                    </div>

                   
                      <div className="text-center">
                      <Link to={`/typing`}><button className="btn btn-sm button-for-test">
                          View Typing
                        </button></Link>
                      </div>
                 
                  </div>
                </li>

                <li className="card card-for-test">
                  <div>
                    <div className="card-title-image1">
                      <img
                        className="card-title1"
                        src="https://images.indianexpress.com/2018/12/SSClogo759.jpg"
                      />
                      <span className="p-04 subscribe-batch">
                        <img src="https://ssccglpinnacle.com/myaccount/images/trending-icon.png" />
                        &nbsp;1.5K Students
                      </span>
                    </div>
                    <div className="card-content1">
                      <img className="typing-pic" src={pic6} alt="SSC" />
                    </div>

                    
                      <div className="text-center">
                      <Link to={`/typing`}><button className="btn btn-sm button-for-test">
                          View Typing
                        </button></Link>
                      </div>
                 
                  </div>
                </li>
              </ul>
            </div>
            {!scrolEnd && (
             <button className="next" onClick={() => slide(1)}>
             <AiOutlineRight color="black" fontSize="1.5em" />
           </button>
            )}
          </div>
        </div>
      </section>
    </>
  );
}

export default Rowtyping;
