import React, { useState, useEffect, useRef } from "react";
import "./Nav2.css";
import { FaChevronUp, FaChevronDown } from "react-icons/fa";
import { useAuth } from "../../Context/AuthContext";
import { Link, useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';


const Nav2 = () => {
  const { user } = useAuth();
  const [cookies] = useCookies(['token']);
  const [isStudentEnrollDropdownOpen, setIsStudentEnrollDropdownOpen] = useState(false);
  const [isNotesDropdownOpen, setIsNotesDropdownOpen] = useState(false);
  const [isEbooksDropdownOpen, setIsEbooksDropdownOpen] = useState(false);
  const [purchaseHistory, setPurchaseHistory] = useState([]);
  const [ebookDetails, setEbookDetails] = useState([]);
  const navigate = useNavigate();
  const studentEnrollDropdownRef = useRef(null);
  const notesDropdownRef = useRef(null);

  const fetchData = async (url, options = {}) => {
    try {
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error(`Failed to fetch data. Status: ${response.status}`);
      }
      return response.json();
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };

  useEffect(() => {
    const fetchEbooks = async () => {
      try {
        const ebookData = await fetchData("https://auth.ssccglpinnacle.com/api/ebooksforactive?active=true");
        setEbookDetails(ebookData);
        // console.log("Fetched ebook data:", ebookData);
      } catch (error) {
        console.error('Error fetching ebooks:', error);
      }
    };
    fetchEbooks();
  }, []);



  useEffect(() => {
    if (user && user._id) {
      const fetchPurchaseHistory = async () => {
        try {
          const purchaseHistoryData = await fetchData(`https://auth.ssccglpinnacle.com/api/purchase-history-ebook/${user.email_id}`, {
            headers: {
              "Authorization": `Bearer ${cookies.token}`
            }
          });
          setPurchaseHistory(purchaseHistoryData.ebooksPurchased); // Update this line to directly set the purchased ebooks
          // console.log("Fetched purchase history:", purchaseHistoryData);
        } catch (error) {
          console.error('Error fetching purchase history:', error);
        }
      };
      fetchPurchaseHistory();
    }
  }, [user, cookies.token]);

  const handleClickOutside = (event) => {
    if (studentEnrollDropdownRef.current && !studentEnrollDropdownRef.current.contains(event.target)) {
      setIsStudentEnrollDropdownOpen(false);
    }
    if (notesDropdownRef.current && !notesDropdownRef.current.contains(event.target)) {
      setIsNotesDropdownOpen(false);
    }
    setIsEbooksDropdownOpen(false);
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const toggleStudentEnrollDropdown = () => {
    setIsStudentEnrollDropdownOpen(prevState => !prevState);
    setIsNotesDropdownOpen(false);
    setIsEbooksDropdownOpen(false);
  };

  const toggleNotesDropdown = () => {
    setIsNotesDropdownOpen(prevState => !prevState);
    setIsStudentEnrollDropdownOpen(false);
    setIsEbooksDropdownOpen(false);
  };

  const toggleEbooksDropdown = () => {
    setIsEbooksDropdownOpen(prevState => !prevState);
    setIsStudentEnrollDropdownOpen(false);
    setIsNotesDropdownOpen(false);
  };

  const studentEnrollDropdownIcon = isStudentEnrollDropdownOpen ? (
    <FaChevronUp />
  ) : (
    <FaChevronDown />
  );

  const notesDropdownIcon = isNotesDropdownOpen ? (
    <FaChevronUp />
  ) : (
    <FaChevronDown />
  );

  const ebooksDropdownIcon = isEbooksDropdownOpen ? (
    <FaChevronUp />
  ) : (
    <FaChevronDown />
  );

  const notesData = [
    { label: "Note 1", url: "#" },
    { label: "Note 2", url: "#" },
    { label: "Note 3", url: "#" },
  ];

  const studentEnrollData = [
    { label: "Option 1", url: "#" },
    { label: "Option 2", url: "#" },
    { label: "Option 3", url: "#" },
    { label: "Option 4", url: "#" },
    { label: "Option 5", url: "#" },
  ];

  const ebooksData = [
    { label: "Ebook 1", url: "#" },
    { label: "Ebook 2", url: "#" },
    { label: "Ebook 3", url: "#" },
  ];

  // const handleClick2 = () => {
  //   navigate('/profile');
  // };

  // const handleClick3 = () => {
  //   navigate('/cart');
  // };

  const handleEbookClick = (ebookId) => {
    navigate(`/ebook/${ebookId}/digitalMainPage`);
  };

  return (
    <nav className="header-nav2">
      <ul className="dropdown-ul">
        <li className="list-dropdown">
          <div className="home-for-ebook">
            My learning
          </div>
        </li>
        <li className={`dropdown ${isStudentEnrollDropdownOpen ? "open" : ""}`} ref={studentEnrollDropdownRef}>
          <a
            className="nav-element-name"
            href="#"
            onClick={toggleStudentEnrollDropdown}
          >
            Student enroll content {studentEnrollDropdownIcon}
          </a>
          <div className="anchor">
          {isStudentEnrollDropdownOpen && (
            <ul className="dropdown-menu3">
              {purchaseHistory.length > 0 ? (
                purchaseHistory.map((item, index) => (
                  <li className="list-dropdown-student" key={index}>
                    <a className="ab" onClick={() => handleEbookClick(item._id)}>{item.title} {item.medium} Medium {item.edition} Edition</a>
                  </li>
                ))
              ) : (
                <li className="list-dropdown-student">
                  <a className="ab">No purchased eBooks</a>
                </li>
              )}
            </ul>
          )}
          </div>
        </li>
        
        <li className={`dropdown ${isNotesDropdownOpen ? "open" : ""}`} ref={notesDropdownRef}>
          <a className="nav-element-name" href="#" onClick={toggleNotesDropdown}>
            Notes {notesDropdownIcon}
          </a>
          {isNotesDropdownOpen && (
            <ul className="dropdown-menu3">
              {notesData.map((item, index) => (
                <li className="list-dropdown" key={index}>
                  <a className="ab2" href={item.url}>{item.label}</a>
                </li>
              ))}
            </ul>
          )}
        </li>
      </ul>
      <Link className="video-digial"  to="/videos">
      <div className="Videos-user">
        Videos
      </div></Link>
      {/* <div className="profile" onClick={handleClick2}>
        Profile
      </div> */}
      <div className="Billing" >
        Billing
      </div>
    </nav>
  );
};

export default Nav2;
