import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./TransactionModal.module.css";
import { useAuth } from "../../Context/AuthContext";

const TransactionModal = () => {
  const [transactionData, setTransactionData] = useState(null);
  const [countdown, setCountdown] = useState(8); // 8-second countdown
  const [loading, setLoading] = useState(true); // Loading state
  const navigate = useNavigate();
  const { orderId } = useParams(); // Extract orderId from URL
  const { isLoggedIn, user } = useAuth();
  const userId = user?._id; // Safely access userId from user object

  console.log("USER", user);
  console.log("USER_ID", userId);

  const closeModal = () => {
    navigate(-1);
  };

  const redirectToMyLearning = () => {
    navigate(`/videos/mylearning`);
  };

  useEffect(() => {
    if (orderId) {
      fetch(`https://auth.ssccglpinnacle.com/api/transaction/${orderId}`)
        .then((response) => response.json())
        .then((data) => {
          setTransactionData(data);
          setLoading(false); // Stop loading after data is fetched
        })
        .catch((error) => {
          console.error("Error fetching transaction:", error);
          setLoading(false); // Stop loading even if there's an error
        });
    }
  }, [orderId]);

  useEffect(() => {
    if (transactionData?.paymentStatus === "SUCCESS") {
      const timer = setInterval(() => {
        setCountdown((prev) => {
          if (prev === 1) {
            clearInterval(timer);
            redirectToMyLearning(); // Redirect to My Learning when countdown ends
          }
          return prev - 1;
        });
      }, 1000); // 1-second interval

      return () => clearInterval(timer); // Cleanup on unmount
    }
  }, [transactionData]);

  if (loading) {
    return (
      <div className={styles.modalOverlay}>
        <div className={styles.modalContent}>
          <h2>Loading transaction details...</h2>
        </div>
      </div>
    );
  }

  if (!orderId) return null;

  const paymentStatus = transactionData?.paymentStatus;
  const redirectToHome = () => {
    navigate(`/videos/cart`);
  };

  // Determine what to display based on payment status
  const isPaymentSuccessful = paymentStatus === "SUCCESS";
  const isPaymentPending = paymentStatus === "Pending";
  const isPaymentFailed = paymentStatus === "Failed";

  return (
    <div className={styles.modalOverlay} onClick={closeModal}>
      <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
        <div className={styles.iconWrapper}>
          <div
            className={
              isPaymentSuccessful ? styles.successIcon : styles.failedIcon // Use failed icon for pending or failed status
            }
          >
            <span className={styles.checkMark}>
              {isPaymentSuccessful ? "✓" : "✗"}
            </span>
          </div>
        </div>
        <h2 className={styles.statusText}>
          {isPaymentSuccessful
            ? "Payment Successful"
            : isPaymentPending
            ? "Payment Pending"
            : isPaymentFailed
            ? "Payment Failed"
            : ""}
        </h2>
        {transactionData && isPaymentSuccessful ? (
          <>
            <p className={styles.amount}>₹{transactionData.amountPaid}/-</p>
            <p className={styles.accountInfo}>
              Reference ID: {transactionData.referenceId}
            </p>
            <p className={styles.redirectMessage}>
              Redirecting to My Learning in {countdown} seconds...
            </p>
          </>
        ) : (
          <p className={styles.failedMessage}>
            {isPaymentPending
              ? "Your payment is pending. Please try again."
              : "Payment failed. Please try again."}
          </p>
        )}
        {isPaymentSuccessful && (
          <button
            className={styles.redirectButton}
            onClick={redirectToMyLearning}
          >
            Go to My Learning
          </button>
        )}
        {!isPaymentSuccessful && (
          <button className={styles.redirectButton} onClick={redirectToHome}>
            Back
          </button>
        )}
      </div>
    </div>
  );
};

export default TransactionModal;
