import React, { useState, useEffect } from "react";
import './BillingForm.css';
import { useAuth } from "../../Context/AuthContext";
import Swal from "sweetalert2";

function BillingForm({ onClose, initialData, onSave }) {
  const { user } = useAuth();

  const [formData, setFormData] = useState({
    fullName: initialData?.fullName || '',
    mobileNumber: initialData?.mobileNumber || '',
    email: initialData?.email || '',
    address: initialData?.address || '',
    state: initialData?.state || '',
    city: initialData?.city || '',
    pincode: initialData?.pincode || '',
  });

  useEffect(() => {
    if (user?.mobile_number) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        mobileNumber: user.mobile_number,
      }));
    }
  }, [user?.mobile_number]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const billingData = {
      ...formData,
      userId: user.id,
      userObjectId: user._id,
    };

    try {
      const response = await fetch('https://auth.ssccglpinnacle.com/api/bookBilling', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(billingData),
      });

      if (response.ok) {
        const savedBillingInfo = await response.json();
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Billing information submitted successfully!',
        });
        if (onSave) {
          onSave(savedBillingInfo);
        }
        onClose();
      } else {
        const errorResponse = await response.json();
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: `Failed to submit billing information: ${errorResponse.message}`,
        });
        console.error('Failed to submit form data', errorResponse);
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `Error submitting billing information: ${error.message}`,
      });
      console.error('Error submitting form data:', error);
    }
  };

  const statesOfIndia = [
    'Andhra Pradesh', 'Arunachal Pradesh', 'Assam', 'Bihar', 'Chhattisgarh',
    'Goa', 'Gujarat', 'Haryana', 'Himachal Pradesh', 'Jharkhand', 'Karnataka',
    'Kerala', 'Madhya Pradesh', 'Maharashtra', 'Manipur', 'Meghalaya', 'Mizoram',
    'Nagaland', 'Odisha', 'Punjab', 'Rajasthan', 'Sikkim', 'Tamil Nadu', 'Telangana',
    'Tripura', 'Uttar Pradesh', 'Uttarakhand', 'West Bengal', 'Andaman and Nicobar Islands',
    'Chandigarh', 'Dadra and Nagar Haveli and Daman and Diu', 'Lakshadweep', 'Delhi',
    'Puducherry', 'Ladakh', 'Jammu and Kashmir'
  ];

  return (
    <div className="billing-modal">
      <div className="billing-modal-content">
        <span className="close" onClick={onClose}>
          &times;
        </span>
        <h2>Enter Billing Information</h2>
        <form onSubmit={handleSubmit}>
          <label>
            Full Name:
            <input type="text" name="fullName" value={formData.fullName} onChange={handleChange} required />
          </label>
          <label>
            Mobile Number:
            <input 
              type="text" 
              name="mobileNumber" 
              value={formData.mobileNumber} 
              onChange={handleChange} 
              readOnly={Boolean(user?.mobile_number)} // Prevent editing if user.mobile_number exists
              required 
            />
          </label>
          <label>
            E-mail:
            <input type="text" name="email" value={formData.email} onChange={handleChange} required />
          </label>
          <label>
            Address:
            <input type="text" name="address" value={formData.address} onChange={handleChange} required />
          </label>
          <label>
            Residence Of State:
            <select name="state" value={formData.state} onChange={handleChange} required>
              <option value="">Select State</option>
              {statesOfIndia.map(state => (
                <option key={state} value={state}>{state}</option>
              ))}
            </select>
          </label>
          <label>
            City:
            <input type="text" name="city" value={formData.city} onChange={handleChange} required />
          </label>
          <label>
            Pincode:
            <input type="text" name="pincode" value={formData.pincode} onChange={handleChange} required />
          </label>
          <button type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
}

export default BillingForm;
