import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import BlogHeader from '../Header';
import LoadingSpinner from '../../../Ebook/LoadingSpinner';


const SearchResults = () => {
  const { query } = useParams();
  const navigate = useNavigate();
  const [blogs, setBlogs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetch(`https://auth.ssccglpinnacle.com/api/blogs-new?search=${query}`)
      .then(response => response.json())
      .then(data => {
        setBlogs(data);
        setIsLoading(false);
      })
      .catch(error => {
        console.error('There was an error fetching the blogs!', error);
        setIsLoading(false);
      });
  }, [query]);

  return (
    <div className="search-results-container">
      <BlogHeader />
      <div className="search-results">
        <h2>Search Results for: {query}</h2>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <div className="blog-cards">
            {blogs.length > 0 ? (
              blogs.map(blog => (
                <div className="blog-card" key={blog._id} onClick={() => navigate(`/bloglist1/${blog._id}`)}>
                  <h4>{blog.category}</h4>
                  <h3>{blog.heading}</h3>
                  <div className="authorname"><p>{blog.author.name}</p></div>
                </div>
              ))
            ) : (
              <p>No results found for "{query}". Please try a different search term.</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchResults;
