// import React, { useState, useEffect, useRef } from "react";
// import classes from "./MainContent.module.css";
// import { FaPlay, FaEllipsisV, FaShareAlt, FaPlus, FaStar, FaArchive } from "react-icons/fa";
// import { IoIosShareAlt, IoLogoFacebook, IoLogoTwitter, IoIosMail, IoLogoWhatsapp, IoMdClose } from "react-icons/io";
// import { Link, useParams, useNavigate } from "react-router-dom";
// import Card from "../Card/Card";
// import Loading from "../ProgressBar/ProgressBar";
// import axios from "axios";
// import Cookies from "js-cookie";
// import CreateListModal from "../../../../YOUTUBE VIDEO PLAYER/YT_Navigation Components/CreateListModal"; // Import the modal component

// function MainContent() {
//   const { userId } = useParams();
//   const [courseDetails, setCourseDetails] = useState([]);
//   const [error, setError] = useState(null);
//   const [isLoading, setIsLoading] = useState(true);
//   const [activeMenu, setActiveMenu] = useState(null);
//   const [isShareModalOpen, setShareModalOpen] = useState(false);
//   const menuRef = useRef(null);
//   const navigate = useNavigate();
//   const [courseTitle, setCourseTitle] = useState("");
//   const [courseId, setCourseId] = useState();
//   const [showCreateListModal, setShowCreateListModal] = useState(false);
//   const [selectedCourse, setSelectedCourse] = useState(null);
//   const [userLists, setUserLists] = useState([]);

//   useEffect(() => {
//     const fetchPurchasedCourses = async () => {
//       try {
//         const token = Cookies.get("token");
//         if (!token) {
//           throw new Error("Authentication token is missing. Please log in.");
//         }

//         if (!userId) {
//           throw new Error("User ID not found in URL parameters.");
//         }

//         const response = await axios.get(
//           `https://auth.ssccglpinnacle.com/api/user_purchased_videos/${userId}`,
//           {
//             headers: {
//               Authorization: `Bearer ${token}`,
//             },
//           }
//         );

//         if (response.data && Array.isArray(response.data.purchasedVideos) && response.data.purchasedVideos.length > 0) {
//           const purchasedCourseIds = response.data.purchasedVideos.map((course) => course.productId);

//           const courseDetailsPromises = purchasedCourseIds.map(async (courseId) => {
//             const courseResponse = await axios.get(
//               `https://auth.ssccglpinnacle.com/course/${courseId}`,
//               {
//                 headers: {
//                   Authorization: `Bearer ${token}`,
//                 },
//               }
//             );
//             return courseResponse.data;
//           });

//           const courses = await Promise.all(courseDetailsPromises);
//           setCourseDetails(courses);
//         } else {
//           setCourseDetails([]);
//         }

//         setIsLoading(false);
//       } catch (error) {
//         console.error("Error fetching purchased courses:", error);
//         setError(error.message || "Error fetching purchased courses. Please try again.");
//         setIsLoading(false);
//       }
//     };

//     fetchPurchasedCourses();
//   }, [userId]);

//   const handleShareClick = (courseId, courseTitle) => {
//     setCourseTitle(courseTitle);
//     setShareModalOpen(true);

//   };

//   const closeShareModal = () => {
//     setShareModalOpen(false);
//   };

//   const handleCopyClick = (shareUrl) => {
//     navigator.clipboard.writeText(shareUrl);
//     alert("Link copied to clipboard");
//   };

//   const handleCreateList = (course) => {
//     setSelectedCourse(course);
//     setShowCreateListModal(true);
//     setActiveMenu(null)
//   };

//   const handleListCreate = (newList) => {
//     console.log("New list created:", newList);
//     setShowCreateListModal(false);
//     setActiveMenu(null)
//   };

//   const archiveCourse = async (course) => {
//     try {
//       const response = await axios.post("https://auth.ssccglpinnacle.com/api/archive", {
//         courseId: course._id || course.id,
//         courseTitle: course.courseTitle,
//         hindiCoverImage: course.hindiCoverImage,
//         englishCoverImage: course.englishCoverImage,
//         instructorName: course.instructorName,
//         userId
//       });

//       setCourseDetails((prevCourses) =>
//         prevCourses.filter((c) => c._id !== course._id)
//       );
//       setActiveMenu(null)
//       // alert('Course archived successfully');
//     } catch (error) {
//       console.error("Failed to archive course:", error);
//       // alert("Error archiving course. Please try again.");
//     }
//   };

//   const shareOnWhatsApp = (shareText) => {
//     const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(shareText)}`;
//     window.open(whatsappUrl, "_blank");
//   };

//   const shareOnTelegram = (shareText) => {
//     const twitterUrl = `https://t.me/share/url?url={home.ssccglpinnacle.com}&text=${encodeURIComponent(shareText)}`;
//     window.open(twitterUrl, "_blank");
//   };

//   const shareOnFacebook = (shareUrl, shareText) => {
//     const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}&quote=${encodeURIComponent(shareText)}`;
//     window.open(facebookUrl, "_blank");
//   };
//   const shareOnGmail = (shareText) => {
//     const emailSubject = "Check out this course!";
//     const emailBody = encodeURIComponent(shareText);
//     const gmailUrl = `mailto:?subject=${emailSubject}&body=${emailBody}`;
//     window.open(gmailUrl, "_blank");
//   };

//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (menuRef.current && !menuRef.current.contains(event.target)) {
//         setActiveMenu(null);
//       }
//     };

//     document.addEventListener("mousedown", handleClickOutside);
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, []);

//   const toggleMenu = (index) => {
//     setActiveMenu(activeMenu === index ? null : index);
//   };

//   if (error) {
//     return <div>Error: {error}</div>;
//   }

//   if (isLoading) {
//     return (
//       <div className={classes.loaderContainer}>
//         <img src="/Bubble-Preloader.gif" alt="Loading..." />
//       </div>
//     );
//   }

//   if (courseDetails.length === 0) {
//     return (
//       <div className={classes.emptyContainer}>
//         <h2>
//           Start learning from <img src="/Pinnacle_colored_logo.svg" className={classes.logo} /> courses today.
//         </h2>
//         <p>
//           When you purchase a course, it will appear here. <Link to="/videos">Browse now.</Link>
//         </p>
//       </div>
//     );
//   }

//   return (
//     <div className={classes.card_container}>
//       {courseDetails.map((course, index) => {
//         const courseId = course._id || course.id;
//         const shareUrl = `https://home.ssccglpinnacle.com/course/description/${courseId}`;
//         const shareText = `Check out this course I'm taking on ${course.courseTitle}! ${shareUrl}`;

//         return (
//           <Card key={courseId} className={classes.card}>
//             <div className={classes.card_main}>
//               <div className={classes.three_dots} onClick={() => toggleMenu(index)}>
//                 <FaEllipsisV />
//               </div>
//               {activeMenu === index && (
//                 <div className={classes.options_menu} ref={menuRef}>
//                   <div className={classes.menu_header}>Lists</div>
//                   <div className={classes.menu_item1}>You have no list</div>

//                   <div onClick={() => handleShareClick(courseId, course.courseTitle)} className={classes.menu_item}>
//                     <FaShareAlt /> Share
//                   </div>

//                   {isShareModalOpen && (
//                     <div
//                       className={classes.shareModalOverlay}
//                       onClick={(e) => {
//                         if (e.target.classList.contains(classes.shareModalOverlay)) {
//                           closeShareModal();
//                         }
//                       }}
//                     >
//                       <div className={classes.shareModal}>
//                         <div className={classes.shareModalHeader}>
//                           <h2>Share this course</h2>
//                           <button onClick={closeShareModal} className={classes.closeButton}>
//                             <IoMdClose size={20} />
//                           </button>
//                         </div>
//                         <div className={classes.urlCopy}>
//                           <input type="text" value={shareUrl} readOnly className={classes.shareInput} />
//                           <button onClick={() => handleCopyClick(shareUrl)} className={classes.copyButton}>
//                             Copy
//                           </button>
//                         </div>
//                         <div className={classes.socialMediaIcons}>
//                           <button className={classes.iconButton1} onClick={() => shareOnFacebook(shareUrl, shareText)}>
//                             <img src="/facebook.svg" className={classes.mediaIcons} size={15}/>
//                           </button>
//                           <button className={classes.iconButton1} onClick={() => shareOnTelegram(shareText)}>
//                             <img src="/telegram.svg" className={classes.mediaIcons} size={15} />
//                           </button>
//                           <button className={classes.iconButton1} onClick={() => shareOnWhatsApp(shareText)}>
//                             <img src="/whatsapp.svg" className={classes.mediaIcons} size={15} />
//                           </button>
//                           <button className={classes.iconButton1}onClick={() => shareOnGmail(shareText)}>
//                             <img src="/mail.svg" className={classes.mediaIcons} size={15}/>
//                           </button>
//                         </div>
//                       </div>
//                     </div>
//                   )}

//                   <div className={classes.menu_item} onClick={() => handleCreateList(course)}>
//                     <FaPlus /> Create New List
//                   </div>
//                   {/* <div className={classes.menu_item} onClick={() => console.log("Favorite clicked")}>
//                     <FaStar /> Favorite
//                   </div> */}
//                   <div className={classes.menu_item} onClick={() => archiveCourse(course)}>
//                     <FaArchive /> Archive
//                   </div>
//                 </div>
//               )}
//               <div className={classes.card_img_container}>
//                 <div className={classes.card_img}>
//                   <img src={course.hindiCoverImage} alt={course.courseTitle} width="95" height="95" />
//                   <img src={course.englishCoverImage} alt={course.courseTitle} width="95" height="95" />
//                   <div onClick={() => navigate(`/youtube_learning/${courseId}`)} className={classes.play_icon}>
//                     <FaPlay size={30} />
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className={classes.heading}>
//               <Link to={`/youtube_learning/${courseId}`} className={classes.title}>
//                 {course.courseTitle}
//               </Link>
//               <div className={classes.instructor}>{course.instructorName}</div>
//             </div>
//             <Loading userId={userId} courseId={courseId} />
//           </Card>
//         );
//       })}

//       {showCreateListModal && selectedCourse && (
//         <CreateListModal
//           userId={userId}
//           course={selectedCourse}
//           onClose={() => setShowCreateListModal(false)}
//           onListCreate={handleListCreate}
//         />
//       )}
//     </div>
//   );
// }

// export default MainContent;
import React, { useState, useEffect, useRef } from "react";
import classes from "./MainContent.module.css";
import {
  FaPlay,
  FaEllipsisV,
  FaShareAlt,
  FaPlus,
  FaArchive,
} from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { Link, useParams, useNavigate } from "react-router-dom";
import Card from "../Card/Card";
import Loading from "../ProgressBar/ProgressBar";
import axios from "axios";
import Cookies from "js-cookie";
import CreateListModal from "../../../../YOUTUBE VIDEO PLAYER/YT_Navigation Components/CreateListModal";
import { useAuth } from "../../../../Context/AuthContext";

function MainContent() {
  // const { userId } = useParams();
  const { isLoggedIn, user } = useAuth();
  const userId = user?._id;
  const emailId = user?.email_id;
  const [courseDetails, setCourseDetails] = useState([]);
  const [userLists, setUserLists] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [activeMenu, setActiveMenu] = useState(null);
  const [isShareModalOpen, setShareModalOpen] = useState(false);
  const menuRef = useRef(null);
  const createModalRef = useRef(null);
  const navigate = useNavigate();
  const [showCreateListModal, setShowCreateListModal] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);

  // useEffect(() => {
  //   const fetchUserLists = async () => {
  //     try {
  //       const token = Cookies.get("token");
  //       if (!token)
  //         throw new Error("Authentication token is missing. Please log in.");

  //       // Fetch purchased courses
  //       const courseResponse = await axios.get(
  //         `https://auth.ssccglpinnacle.com/api/user_purchased_videos/${userId}`,
  //         { headers: { Authorization: `Bearer ${token}` } }
  //       );

  //       if (
  //         courseResponse.data &&
  //         Array.isArray(courseResponse.data.purchasedVideos)
  //       ) {
  //         // Ensure unique courses based on productId
  //         const uniqueCoursesMap = new Map();
  //         courseResponse.data.purchasedVideos.forEach((course) => {
  //           if (!uniqueCoursesMap.has(course.productId)) {
  //             uniqueCoursesMap.set(course.productId, course);
  //           }
  //         });
  //         const uniquePurchasedCourses = Array.from(uniqueCoursesMap.values());

  //         const courseDetailsPromises = uniquePurchasedCourses.map(
  //           async (course) => {
  //             const courseData = await axios.get(
  //               `https://auth.ssccglpinnacle.com/course/${course.productId}`,
  //               { headers: { Authorization: `Bearer ${token}` } }
  //             );
  //             return courseData.data;
  //           }
  //         );

  //         const courses = await Promise.all(courseDetailsPromises);
  //         setCourseDetails(courses);
  //       }
  useEffect(() => {
    const fetchUserLists = async () => {
      try {
        const token = Cookies.get("token");
        if (!token)
          throw new Error("Authentication token is missing. Please log in.");

        // Fetch purchased courses using emailId
        const courseResponse = await axios.get(
          `https://auth.ssccglpinnacle.com/api//user_purchased_videos_email?emailId=${emailId}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );

        if (
          courseResponse.data &&
          Array.isArray(courseResponse.data.purchasedVideos)
        ) {
          // Ensure unique courses based on productId
          const uniqueCoursesMap = new Map();
          courseResponse.data.purchasedVideos.forEach((course) => {
            if (!uniqueCoursesMap.has(course.productId)) {
              uniqueCoursesMap.set(course.productId, course);
            }
          });
          const uniquePurchasedCourses = Array.from(uniqueCoursesMap.values());

          const courseDetailsPromises = uniquePurchasedCourses.map(
            async (course) => {
              const courseData = await axios.get(
                `https://auth.ssccglpinnacle.com/course/${course.productId}`,
                { headers: { Authorization: `Bearer ${token}` } }
              );
              return courseData.data;
            }
          );

          const courses = await Promise.all(courseDetailsPromises);
          setCourseDetails(courses);
        }

        // Fetch user-created lists
        const listResponse = await axios.get(
          `https://auth.ssccglpinnacle.com/api/userCourseList/${userId}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        setUserLists(listResponse.data);

        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error.message || "Error fetching data. Please try again.");
        setIsLoading(false);
      }
    };

    fetchUserLists();
  }, [userId]);

  const handleCreateList = (course) => {
    setSelectedCourse(course);
    setShowCreateListModal(true);
    setActiveMenu(null);
  };

  const handleListCreate = (newList) => {
    setUserLists((prevLists) => [...prevLists, newList]);
    setShowCreateListModal(false);
  };

  const handleShareClick = (courseId, courseTitle) => {
    setShareModalOpen(true);
  };

  const closeShareModal = () => {
    setShareModalOpen(false);
  };

  const toggleMenu = (index) => {
    setActiveMenu(activeMenu === index ? null : index);
  };

  // Function to add a course to a list
  const addToList = async (listId, course) => {
    try {
      const courseData = {
        courseId: course._id || course.id,
        courseTitle: course.courseTitle,
        hindiCoverImage: course.hindiCoverImage,
        englishCoverImage: course.englishCoverImage,
        instructor: course.instructorName,
        averageRating: course.averageRating,
      };

      await axios.post(
        `https://auth.ssccglpinnacle.com/api/userCourseList/add-course`,
        { listId, course: courseData },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );

      setActiveMenu(null); // Close the menu after adding to the list
    } catch (error) {
      console.error("Failed to add course to the list:", error);
    }
  };

  // Function to copy the share URL to clipboard
  const handleCopyClick = (shareUrl) => {
    navigator.clipboard.writeText(shareUrl);
    alert("Link copied to clipboard");
  };

  // Function to share on Facebook
  const shareOnFacebook = (shareUrl, shareText) => {
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      shareUrl
    )}&quote=${encodeURIComponent(shareText)}`;
    window.open(facebookUrl, "_blank");
  };

  // Function to share on Telegram
  const shareOnTelegram = (shareText) => {
    const telegramUrl = `https://t.me/share/url?url=https://ssccglpinnacle.com&text=${encodeURIComponent(
      shareText
    )}`;
    window.open(telegramUrl, "_blank");
  };

  // Function to share on WhatsApp
  const shareOnWhatsApp = (shareText) => {
    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      shareText
    )}`;
    window.open(whatsappUrl, "_blank");
  };

  // Function to share via Gmail
  const shareOnGmail = (shareText) => {
    const emailSubject = "Check out this course!";
    const emailBody = encodeURIComponent(shareText);
    const gmailUrl = `mailto:?subject=${emailSubject}&body=${emailBody}`;
    window.open(gmailUrl, "_blank");
  };

  // Function to archive a course
  const archiveCourse = async (course) => {
    try {
      await axios.post("https://auth.ssccglpinnacle.com/api/archive", {
        courseId: course._id || course.id,
        courseTitle: course.courseTitle,
        hindiCoverImage: course.hindiCoverImage,
        englishCoverImage: course.englishCoverImage,
        instructorName: course.instructorName,
        userId,
      });

      setCourseDetails((prevCourses) =>
        prevCourses.filter((c) => c._id !== course._id)
      );
      setActiveMenu(null);
    } catch (error) {
      console.error("Failed to archive course:", error);
    }
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (isLoading) {
    return (
      <div className={classes.loaderContainer}>
        <img src="/Bubble-Preloader.gif" alt="Loading..." />
      </div>
    );
  }

  if (courseDetails.length === 0) {
    return (
      <div className={classes.emptyContainer}>
        <h2>
          Start learning from{" "}
          <img src="/Pinnacle_colored_logo.svg" className={classes.logo} />{" "}
          courses today.
        </h2>
        <p>
          When you purchase a course, it will appear here.{" "}
          <Link to="/videos">Browse now.</Link>
        </p>
      </div>
    );
  }

  return (
    <div className={classes.card_container}>
      {courseDetails.map((course, index) => {
        const courseId = course._id || course.id;
        const shareUrl = `https://ssccglpinnacle.com/course/description/${courseId}`;
        const shareText = `Check out this course I'm taking on ${course.courseTitle}! ${shareUrl}`;

        return (
          <Card key={courseId} className={classes.card}>
            <div className={classes.card_main}>
              <div
                className={classes.three_dots}
                onClick={() => toggleMenu(index)}
              >
                <FaEllipsisV />
              </div>
              {activeMenu === index && (
                <div className={classes.options_menu} ref={menuRef}>
                  <div className={classes.menu_header}>Lists</div>

                  {/* Display user-created lists */}
                  {userLists.length === 0 ? (
                    <div className={classes.menu_item1}>You have no list</div>
                  ) : (
                    userLists.map((list) => (
                      <div
                        key={list._id}
                        className={classes.menu_item}
                        onClick={() => addToList(list._id, course)}
                      >
                        {list.listTitle}
                      </div>
                    ))
                  )}

                  <div
                    onClick={() =>
                      handleShareClick(courseId, course.courseTitle)
                    }
                    className={classes.menu_share_item}
                  >
                    <FaShareAlt className={classes.menu_share_icon} /> Share
                  </div>

                  {isShareModalOpen && (
                    <div
                      className={classes.shareModalOverlay}
                      onClick={(e) => {
                        if (
                          e.target.classList.contains(classes.shareModalOverlay)
                        ) {
                          closeShareModal();
                        }
                      }}
                    >
                      <div className={classes.shareModal}>
                        <div className={classes.shareModalHeader}>
                          <h2>Share this course</h2>
                          <button
                            onClick={closeShareModal}
                            className={classes.closeButton}
                          >
                            <IoMdClose size={20} />
                          </button>
                        </div>
                        <div className={classes.urlCopy}>
                          <input
                            type="text"
                            value={shareUrl}
                            readOnly
                            className={classes.shareInput}
                          />
                          <button
                            onClick={() => handleCopyClick(shareUrl)}
                            className={classes.copyButton}
                          >
                            Copy
                          </button>
                        </div>
                        <div className={classes.socialMediaIcons}>
                          <button
                            className={classes.iconButton1}
                            onClick={() => shareOnFacebook(shareUrl, shareText)}
                          >
                            <img
                              src="/facebook.svg"
                              className={classes.mediaIcons}
                              size={15}
                            />
                          </button>
                          <button
                            className={classes.iconButton1}
                            onClick={() => shareOnTelegram(shareText)}
                          >
                            <img
                              src="/telegram.svg"
                              className={classes.mediaIcons}
                              size={15}
                            />
                          </button>
                          <button
                            className={classes.iconButton1}
                            onClick={() => shareOnWhatsApp(shareText)}
                          >
                            <img
                              src="/whatsapp.svg"
                              className={classes.mediaIcons}
                              size={15}
                            />
                          </button>
                          <button
                            className={classes.iconButton1}
                            onClick={() => shareOnGmail(shareText)}
                          >
                            <img
                              src="/mail.svg"
                              className={classes.mediaIcons}
                              size={15}
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  )}

                  <div
                    className={classes.menu_item}
                    onClick={() => handleCreateList(course)}
                  >
                    <FaPlus /> Create New List
                  </div>
                  <div
                    className={classes.menu_item}
                    onClick={() => archiveCourse(course)}
                  >
                    <FaArchive /> Archive
                  </div>
                </div>
              )}
              <div className={classes.card_img_container}>
                <div className={classes.card_img}>
                  <img
                    src={course.hindiCoverImage}
                    alt={course.courseTitle}
                    width="95"
                    height="95"
                  />
                  <img
                    src={course.englishCoverImage}
                    alt={course.courseTitle}
                    width="95"
                    height="95"
                  />
                  <div
                    onClick={() => navigate(`/youtube_learning/${courseId}`)}
                    className={classes.play_icon}
                  >
                    <FaPlay size={30} />
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.heading}>
              <Link
                to={`/youtube_learning/${courseId}`}
                className={classes.title}
              >
                {course.courseTitle}
              </Link>
              <div className={classes.instructor}>{course.instructorName}</div>
            </div>
            <Loading userId={userId} courseId={courseId} />
          </Card>
        );
      })}

      {showCreateListModal && selectedCourse && (
        <CreateListModal
          userId={userId}
          course={selectedCourse}
          onClose={() => setShowCreateListModal(false)}
          onListCreate={handleListCreate}
          ref={createModalRef}
        />
      )}
    </div>
  );
}

export default MainContent;
