import React from 'react';

const CustomStarRating = ({ rating }) => {
  const stars = [];
  const maxRating = 5;

  for (let i = 1; i <= maxRating; i++) {
    const starClass = i <= rating ? 'star filled' : 'star empty';
    stars.push(<span key={i} className={starClass}>&#9733;</span>);
  }

  return <div className="custom-star-rating">{stars}</div>;
};

export default CustomStarRating;
