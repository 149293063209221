import React, { useEffect, useState } from 'react';
import { FaSearch, FaTimes } from 'react-icons/fa';
import pic from "../../Ebook/i/pinnacle.png";
import pic2 from "../../Ebook/i/imagebook.png";
import { useNavigate } from 'react-router-dom';
import './Blog1.css'; // Ensure you have this CSS file
import BlogHeader from './Header';
import LoadingSpinner from '../../Ebook/LoadingSpinner';


 // Import the LoadingSpinner component

const Blog1 = () => {
  const [blogs, setBlogs] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Add loading state
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState(''); // Add state for search input
  const navigate = useNavigate();



  
  useEffect(() => {
    fetch('https://auth.ssccglpinnacle.com/api/blogs')
      .then(response => response.json())
      .then(data => {
        setBlogs(data);
        setIsLoading(false); // Set loading to false after data is fetched
      })
      .catch(error => {
        console.error('There was an error fetching the blogs!', error);
        setIsLoading(false); // Set loading to false even if there's an error
      });
  }, []);

  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
  };

  const handleCardClick = (id) => {
    navigate(`/bloglist1/${id}`);
  };

  const handleSeeAllClick = (category) => {
    navigate(`/blog/${category}`);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSearchKeyDown = (event) => {
    if (event.key === 'Enter') {
      navigate(`/search/${searchQuery}`);
    }
  };

  return (
    <div className="blog-container">   
      <BlogHeader />
      <div className="container-fluid-blog">
        <div className="blog-row">
          <div className="content-left">
            <div className='content-left-text-block'>
              <h1>Where possibilities begin</h1>
              <p>We’re a leading marketplace platform for learning and teaching online. Explore some of our most popular content and learn something new.</p>
            </div>
          </div>
          <div className="content-right">
            <img src={pic2} alt="Placeholder"/>
          </div>
        </div>
      </div>
      <div className="category-row">
        <div className={`search-container ${isSearchOpen ? 'open' : ''}`}>
          <input
            type="text"
            placeholder="Search for blog articles and topics"
            className={isSearchOpen ? 'open' : ''}
            value={searchQuery}
            onChange={handleSearchChange}
            onKeyDown={handleSearchKeyDown}
          />
          {isSearchOpen ? <FaTimes onClick={toggleSearch} /> : <FaSearch onClick={toggleSearch} />}
        </div>
        <div className="categories">
          <div onClick={() => handleSeeAllClick('SSC')}>SSC</div>
          <div onClick={() => handleSeeAllClick('RRB NTPC')}>RRB NTPC</div>
          <div onClick={() => handleSeeAllClick('Daily current affairs')}>Daily current affairs</div>
          <div onClick={() => handleSeeAllClick('Topic wise current affairs')}>Topic wise current affairs</div>
          <div onClick={() => handleSeeAllClick('Last one year current affairs')}>Last one year current affairs</div>
          <div onClick={() => handleSeeAllClick('Monthly current affairs')}>Monthly current affairs</div>
          <div onClick={() => handleSeeAllClick('Up police')}>Up police</div>
          <div onClick={() => handleSeeAllClick('HSSC')}>HSSC</div>
        </div>
      </div>

      <div className='row2-blog'>
        <h2 className='row2h2'>Popular Articles</h2>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <div className="blog-cards">
            {blogs.slice(0, 3).map(blog => (
              <div className="blog-card" key={blog._id} onClick={() => handleCardClick(blog._id)}>
                <h4>{blog.category}</h4>
                <h3>{blog.heading}</h3>
                <div className='margin-blog'></div>
                <div className='margin-blog'></div>
                <div className="authorname"><p>{blog.author.name}</p></div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Blog1;
