// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.description_description-fullpage__X1S6s {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.description_description-heading__6pK1V {
  font-size: 21px;
  line-height: 40px;
  font-weight: bold;
}

.description_description-content__1xuu\\+ {
  font-size: 14px;
  padding: 8px;
  /* line-height: 35px; */
}



/* **************************** started media query *******************  */

@media (max-width: 546px) {
  .description_description-fullpage__X1S6s {
    margin-top: -1.5rem;
    margin-bottom: 1.5rem;
  }
  .description_description-content__1xuu\\+ ol {
    padding-left: 0% !important;
  }
}

@media (min-width: 547px) and (max-width: 642px) {
  .description_description-fullpage__X1S6s {
    margin-top: -1.5rem;
    margin-bottom: 1.5rem;
  }
  .description_description-content__1xuu\\+ ol {
    padding-left: 0% !important;
  }
}

@media (min-width: 360px) and (max-width: 767px) {
  .description_description-fullpage__X1S6s {
    margin-top: -2rem;
    margin-bottom: 1.5rem;
  }

  .description_description-content__1xuu\\+ {
    font-size: 16px;
    /* line-height: 35px; */
  }
  .description_description-content__1xuu\\+ ol {
    padding-left: 0% !important;
  }
}

@media (min-width: 768px) and (max-width: 1080px) {
    .description_description-content__1xuu\\+{
        font-size: 14px;
        line-height: inherit;
        padding: 8px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/component/Page02/description.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,YAAY;EACZ,uBAAuB;AACzB;;;;AAIA,0EAA0E;;AAE1E;EACE;IACE,mBAAmB;IACnB,qBAAqB;EACvB;EACA;IACE,2BAA2B;EAC7B;AACF;;AAEA;EACE;IACE,mBAAmB;IACnB,qBAAqB;EACvB;EACA;IACE,2BAA2B;EAC7B;AACF;;AAEA;EACE;IACE,iBAAiB;IACjB,qBAAqB;EACvB;;EAEA;IACE,eAAe;IACf,uBAAuB;EACzB;EACA;IACE,2BAA2B;EAC7B;AACF;;AAEA;IACI;QACI,eAAe;QACf,oBAAoB;QACpB,YAAY;IAChB;AACJ","sourcesContent":[".description-fullpage {\n  margin-top: 1.5rem;\n  margin-bottom: 1.5rem;\n}\n\n.description-heading {\n  font-size: 21px;\n  line-height: 40px;\n  font-weight: bold;\n}\n\n.description-content {\n  font-size: 14px;\n  padding: 8px;\n  /* line-height: 35px; */\n}\n\n\n\n/* **************************** started media query *******************  */\n\n@media (max-width: 546px) {\n  .description-fullpage {\n    margin-top: -1.5rem;\n    margin-bottom: 1.5rem;\n  }\n  .description-content ol {\n    padding-left: 0% !important;\n  }\n}\n\n@media (min-width: 547px) and (max-width: 642px) {\n  .description-fullpage {\n    margin-top: -1.5rem;\n    margin-bottom: 1.5rem;\n  }\n  .description-content ol {\n    padding-left: 0% !important;\n  }\n}\n\n@media (min-width: 360px) and (max-width: 767px) {\n  .description-fullpage {\n    margin-top: -2rem;\n    margin-bottom: 1.5rem;\n  }\n\n  .description-content {\n    font-size: 16px;\n    /* line-height: 35px; */\n  }\n  .description-content ol {\n    padding-left: 0% !important;\n  }\n}\n\n@media (min-width: 768px) and (max-width: 1080px) {\n    .description-content{\n        font-size: 14px;\n        line-height: inherit;\n        padding: 8px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"description-fullpage": `description_description-fullpage__X1S6s`,
	"description-heading": `description_description-heading__6pK1V`,
	"description-content": `description_description-content__1xuu+`
};
export default ___CSS_LOADER_EXPORT___;
