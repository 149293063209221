import React, { useState, useEffect } from 'react';
import { useAuth } from "../Context/AuthContext";
import { useCart } from "../Context/CartContext";

import "./Wishlist.css";
import Rateab from "../componentbook/StarBook/Rateab";
import { BsCart2 } from "react-icons/bs";
import Offer from "./OfferBook";
import { BsArrowRight } from 'react-icons/bs';
import pic from "../iBook/book.jpg";
import { AiFillStar } from "react-icons/ai";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Cartheader from '../componentbook/CartHeaderBook/CartheaderBook';


function WishlistBook() {
  const [wishlistItems, setWishlistItems] = useState([]);
  const [hoveredId, setHoveredId] = useState(null);
  const { user } = useAuth();
  const [StarData, setStarData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchWishlist = async () => {
      try {
        if (!user || !user._id) {
          console.error('User is null or _id is undefined.');
          return;
        }

        // Fetch wishlist data for the user
        const wishlistResponse = await fetch(`https://auth.ssccglpinnacle.com/api/get-wishlist-book/${user._id}`);
        const wishlistData = await wishlistResponse.json();
        const wishlistIds = wishlistData.wishlist;

        // Fetch ebook data for the items in the wishlist
        const ebookResponse = await fetch('https://auth.ssccglpinnacle.com/api/booksforactive');
        const ebookData = await ebookResponse.json();
        const wishlistItemsData = ebookData.filter(ebook => wishlistIds.includes(ebook._id));

        // Set the wishlist items in the state
        setWishlistItems(wishlistItemsData);
      } catch (error) {
        console.error('Error fetching wishlist:', error);
      }
    };
   
    fetchWishlist();
  }, [user]);


  const getRatingForCard = (cardId) => {
    const card = wishlistItems.find((card) => card._id === cardId);
  
    if (!card || !card.rating || card.rating.length === 0) {
      return 0;
    }
  
    return calculateAverageRating(card.rating);
  };
  
  const calculateAverageRating = (ratings) => {
    const totalRating = ratings.reduce((sum, rating) => sum + rating.starRate, 0);
    return totalRating / ratings.length;
  };
  console.log(wishlistItems)

  const handleMouseOver = (_id) => {
    setHoveredId(_id);
  };

  const handleMouseOut = () => {
    setHoveredId(null);
  };

  const info = (cardId) => {
    navigate(`/intro-book/${cardId}`);
  };

  return (
    <>
      {/* <div className="Header-for-cart">
        <Offer />
        <div className="cart-icon-header">
          <BsCart2 className="cart-on-header" size={28} />
          <div className="circle">{wishlistItems.length}</div>
        </div>
      </div> */}
       <Offer />
      <Cartheader/>
      <div className="my-learning-row3-wishlist">
        <div className="inprogress-header-new-wishlist">
          <h2>Wishlist</h2>
         
        </div>

        <div className="card-for-progress-info-wishlist">
          {wishlistItems.map((item) => (
            <div className="custom-card-wishlist digital-content-card-progress-wishlist" key={item._id}>
              <div className="class-for-img-progress-new-wishlist">
                <div className="classes-for-pro-img-new-wishlist">
                  <img className="card-image-progress-new-wishlist" src={`http://13.200.156.92:5000/${item.image}`} alt={item.title} />
                </div>
              </div>
              <div className="card-content-progress-wishlist">
                <div className="title-progress-report-wishlist">
                  <h6>{item.title} {item.edition} edition {item.medium} medium</h6>
                </div>
                <div className="lesson-completed-wishlist">

                <div className="ebook-information-progress-wishlist">
                <div
                        className="Rating"
                        onMouseOver={() => handleMouseOver(item._id)}
                        onMouseOut={handleMouseOut}
                      >
                        <div className="Star-Rating-wishlist">{getRatingForCard(item._id).toFixed(1)}
                          {Array.from({ length: 1 }).map((_, index) => (
                            <AiFillStar className= "Star-Rating-1"
                            />
                          ))}
                        </div>
                        <div className="Total-Rating">({item.rating.length})</div>
                      </div>
                      <div className="StarRating-wishlist">
                      {hoveredId === item._id &&  <Rateab cardData = {item} />}
                    </div>
                </div>

<div className = "price-wishlist">
                  <p>₹</p><p className="printing-price">{item.bookPrintingPrice}</p>
          <p className="selling-price">{item.bookSellingPrice}</p>
                </div>
              </div>
                <div className="read-more-process-wishlist">
                  <p className="read-more-para-wishlist"  onClick={() => info(item._id)}>Read more</p>
                  <BsArrowRight className="arrow-process-wishlist" size={18} color="black" />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default WishlistBook;
