const historyQuizData = [
  {
    question: "Who was the first president of the United States?",
    options: [
      "George Washington",
      "Thomas Jefferson",
      "Abraham Lincoln",
      "John Adams"
    ],
    answerIndex: 0
  },
  {
    question: "In which year did World War II end?",
    options: ["1942", "1945", "1948", "1950"],
    answerIndex: 1
  },
  {
    question: "Which civilization built the Great Wall of China?",
    options: ["Mayans", "Romans", "Egyptians", "Chinese"],
    answerIndex: 3
  },
  {
    question:
      "Which war was fought between the North and South regions of the United States?",
    options: ["World War I", "Vietnam War", "Civil War", "Korean War"],
    answerIndex: 2
  },
  {
    question: "Who wrote the Declaration of Independence?",
    options: [
      "George Washington",
      "Thomas Jefferson",
      "Benjamin Franklin",
      "John Adams"
    ],
    answerIndex: 1
  }
];

export default historyQuizData;
