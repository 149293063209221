import { Row, Col } from "react-bootstrap";
import { useParams, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import './Typing.css';
import { useCookies } from 'react-cookie';
import { useAuth } from "../Context/AuthContext";


const TypingParagraph = () => {
    const { exam, category } = useParams();
    const navigate = useNavigate();
    const [Paragraph, setParagraph] = useState([]);
    const [cookies] = useCookies(['token']);
    const { isLoggedIn, user } = useAuth();

    const startTyping = (e) => {
        let test_code = e.target.id;
        navigate(`/typingmodule/${test_code}/${exam}/${category}`);
    };

    useEffect(() => {
        const fetchParagraphs = async () => {
            let dt = { exam: exam };
            try {
                console.log("Cookies before fetchParagraphs:", cookies);
                let state_res = await fetch("https://auth.ssccglpinnacle.com/api/typingParagraphs", {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        "Authorization": `Bearer ${cookies.token}`
                    },
                    body: JSON.stringify(dt)
                });

                if (state_res.ok) {
                    state_res = await state_res.json();
                    if (Array.isArray(state_res)) {
                        setParagraph(state_res);
                    } else {
                        console.error("Response is not an array", state_res);
                    }
                } else {
                    console.error("Failed to fetch paragraphs", state_res.statusText);
                }
            } catch (error) {
                console.error("Error fetching paragraphs", error);
            }
        };

        const checkProductAccess = async () => {
            try {
                const response = await fetch("https://auth.ssccglpinnacle.com/api/checkAccessTypingProduct", {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        "Authorization": `Bearer ${cookies.token}`
                    },
                    body: JSON.stringify({ product_id: '999' }) // Replace with actual product ID
                });

                if (response.ok) {
                    const { access } = await response.json();
                    if (access === "access") {
                        fetchParagraphs();
                    } else {
                        navigate('/');
                    }
                } else {
                    console.error("Failed to check product access", response.statusText);
                    navigate('/');
                }
            } catch (error) {
                console.error("Error checking product access", error);
                navigate('/');
            }
        };

        if (cookies.token) {
            checkProductAccess();
        } else {
            console.error("Token not found in cookies");
            navigate('/');
        }
    }, [exam, cookies.token, navigate]);

    return (
        <>
            <Row className="tp-fr">
                <Col md={12} className="tp-fr-col12">
                {user && user.email_id ? (
                    <p className="tp-fr-col12p">{user.email_id}</p>
                ) : (
                    <p className="tp-fr-col12p">Loading user data...</p>
                )}
                </Col>
            </Row>
            <Row>
                {Array.isArray(Paragraph) && Paragraph.map((para, p) => (
                    <Col md={12} key={p} id={para.paper_code} className="tpara-sr-col12" onClick={startTyping}>
                        <Col>
                            <p className="tpara-sr-col12-subcol-p1" id={para.paper_code}>{para.paper_code}</p>
                            <p className="tpara-sr-col12-subcol-p3" id={para.paper_code}>{para.date}</p>
                            <p className="tpara-sr-col12-subcol-p4" id={para.paper_code}>{para.type}</p>
                            <p className="tpara-sr-col12-subcol-p2" id={para.paper_code}>Start Typing</p>
                        </Col>
                        <h5 className="tpara-sr-col12-h5" id={para.paper_code}>{para.paragraph}</h5>
                    </Col>
                ))}
            </Row>
        </>
    );
};

export default TypingParagraph;
