import React from 'react';
import styles from './YT_PlayerNavbar.module.css';
import { MdOutlineSearch } from 'react-icons/md';

const YTPlayerNavbar = ({ setActiveComponent }) => {
   return (
      <div className={styles.videoNavbar}>
         <button onClick={() => setActiveComponent('searchbar')}><MdOutlineSearch classname={styles.searchIcon} size={25} /></button>
         <button onClick={() => setActiveComponent('overview')}>Overview</button>
         <button onClick={() => setActiveComponent('notes')}>Notes</button>
         <button onClick={() => setActiveComponent('announcement')}>Announcement</button>
         <button onClick={() => setActiveComponent('reviews')}>Reviews</button>
         <hr/>
      </div>
   );
};
export default YTPlayerNavbar;