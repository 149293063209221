import React from "react";
import classes from "./Navbar.module.css";
import SearchBar from "./SearchBarBook";
import user from "./images/user.svg";

import { BiUserCircle } from 'react-icons/bi';
import google from "./images/google.svg";


function Navbar() {
  return (
    <>
     <nav className={classes.nav}>
     <a href="https://play.google.com/store/apps/details?id=com.ssccgl.pinnacle.testportal"><img src={google} /> </a>

      {/* <p className ={
        classes.getiton}> GET IT ON </p>
      <p className ={
        classes.google
      }>Google play</p>      
      <a href="/"><img src={user} /></a> */}
    </nav>
    </>
  );
}

export default Navbar;

// {props.isLoggedIn && ()}  {props.isLoggedIn && ()} {props.isLoggedIn && ()}