// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bulk-typing-access-form {
  max-width: 80%;
  margin: 20px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  
  .bulk-typing-access-form h2 {
    text-align: center;
  }
  
  .bulk-typing-access-form textarea,
  .bulk-typing-access-form input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
  }

  .bulk-typing-access-form textarea{
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    height: 15rem;
  }
  
  .bulk-typing-access-form button {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .bulk-typing-access-form button:hover {
    background-color: #45a049;
  }
  
  .error {
    color: red;
    text-align: center;
  }
  
  .success {
    color: green;
    text-align: center;
  }
  `, "",{"version":3,"sources":["webpack://./src/Ebook/Forms/BulkTypingAccessForm.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,iBAAiB;IACf,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,yBAAyB;EAC3B;;EAEA;IACE,kBAAkB;EACpB;;EAEA;;IAEE,WAAW;IACX,aAAa;IACb,cAAc;EAChB;;EAEA;IACE,WAAW;IACX,aAAa;IACb,cAAc;IACd,aAAa;EACf;;EAEA;IACE,cAAc;IACd,WAAW;IACX,aAAa;IACb,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,UAAU;IACV,kBAAkB;EACpB;;EAEA;IACE,YAAY;IACZ,kBAAkB;EACpB","sourcesContent":[".bulk-typing-access-form {\n  max-width: 80%;\n  margin: 20px auto;\n    padding: 20px;\n    border: 1px solid #ccc;\n    border-radius: 8px;\n    background-color: #f9f9f9;\n  }\n  \n  .bulk-typing-access-form h2 {\n    text-align: center;\n  }\n  \n  .bulk-typing-access-form textarea,\n  .bulk-typing-access-form input {\n    width: 100%;\n    padding: 10px;\n    margin: 10px 0;\n  }\n\n  .bulk-typing-access-form textarea{\n    width: 100%;\n    padding: 10px;\n    margin: 10px 0;\n    height: 15rem;\n  }\n  \n  .bulk-typing-access-form button {\n    display: block;\n    width: 100%;\n    padding: 10px;\n    background-color: #4caf50;\n    color: white;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n  }\n  \n  .bulk-typing-access-form button:hover {\n    background-color: #45a049;\n  }\n  \n  .error {\n    color: red;\n    text-align: center;\n  }\n  \n  .success {\n    color: green;\n    text-align: center;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
