import React from 'react'
import "./Searchcart.css";
import { FaSearch } from "react-icons/fa";


function Searchcart() {

    return (
        <div className='container1-cart '>
            <div className='input-wrap-cart'>
               
               <input
                    type="text"
                    name="product-search"
                    id="product-search-cart"
                    placeholder="Search for products..."
                />
                <div className='searchicon-cart'>
                 < FaSearch className = "serch-icon-cart" color="white" fontSize="1.2em" opacity="1em" background="#008B8B" /> </div> {/* <i
                    className="fas fa-times"
                ></i> */}
            </div>
        </div>
    );
}

export default Searchcart;
