// UserFormComponent.js
import React from "react";
import "./UserFormComponent.css";
import { MdArrowBack } from "react-icons/md";
import { useAuth } from "../../../Context/AuthContext";
import Swal from "sweetalert2";

const UserFormComponent = ({
  userData,
  setUserData,
  handleBack,
  handleSubmit,
  purchasedEbookId,
  handleCloseModal, // Receive the callback prop
}) => {
  const { user } = useAuth();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };
  const submitUserDetails = async () => {
    const userId = user?._id;
    try {
      const response = await fetch("https://auth.ssccglpinnacle.com/api/submit-user-details-ebook", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId,
          purchasedEbookId,
          firstName: userData.firstName,
          lastName: userData.lastName,
          email: userData.email,
        }),
      });
  
      if (!response.ok) {
        throw new Error(`Failed to submit user details. Status: ${response.status}`);
      }
  
      // Use swal for success message
      await Swal.fire({
        icon: "success",
        title: "Success!",
        text: "You review submitted successfully!",
      });
  
      handleCloseModal(); // Close the modal after successful submission
    } catch (error) {
      console.error("Error submitting user details:", error);
      // Use swal for error message
      await Swal.fire({
        icon: "error",
        title: "Error!",
        text: "Failed to submit user details. Please try again.",
      });
    }
  };

  return (
    <div className="form-div">
      <div className="about-you">
        <h6>About you</h6>
      </div>
      <div className="for-input-review-container">
        <div className="about-you-inputs">
          <div className="names-inputs">
            <div className="for-first-name-review">
              <p>First name</p>
              <input
                type="text"
                name="firstName"
                value={userData.firstName}
                onChange={handleChange}
                placeholder="First Name"
                className="input-field-firstname-review"
              />
            </div>

            <div className="for-last-name-review">
              <p>Last name</p>
              <input
                type="text"
                name="lastName"
                value={userData.lastName}
                onChange={handleChange}
                placeholder="Last Name"
                className="input-field-lastname-review"
              />
            </div>
          </div>

          <div className="for-email-review">
            <p>Email</p>
            <input
              type="email"
              name="email"
              value={userData.email}
              onChange={handleChange}
              placeholder="Email"
              className="input-field-email-review"
            />
          </div>
        </div>
      </div>

      <div className="term-and-condition-review">
        By submitting, I acknowledge the{' '}
        <a href="#" className="terms-link">
          Terms of Service
        </a>{' '}
        and{' '}
        <a href="#" className="privacy-link">
          Privacy Policy
        </a>{' '}
        and that my review will be publicly posted and shared online.
      </div>

      <div className="button-in-review-for-back-user">
        <button className="button-review-back" onClick={handleBack}>
          <MdArrowBack className="right-arrow-back" size={20} />
          Back
        </button>

        <button onClick={submitUserDetails} className="button-review-Submit">
          Done
        </button>
      </div>
    </div>
  );
};

export default UserFormComponent;
