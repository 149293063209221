// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AddTopicForm_topic-form__UzQFb {
  max-width: 600px;
  margin: auto;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.AddTopicForm_formTitle__pBp7z {
  margin-bottom: 30px;
  text-align: center;
  color: #333;
}

.AddTopicForm_topic-form__UzQFb label {
  display: block;
  margin-top: 20px;
  margin-bottom: 10px;
  color: #333;
  font-size: 1rem;
}

.AddTopicForm_inputField__\\+Tsfm,
.AddTopicForm_selectField__jmEo8 {
  width: 100%;
  padding: 12px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 0.9rem;
}

.AddTopicForm_submitButton__dGNx0 {
  width: 100%;
  padding: 12px;
  margin-top: 20px;
  background-color: #0056b3;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  
}

.AddTopicForm_submitButton__dGNx0:hover {
  background-color: #004494;
}

.AddTopicForm_topic__j\\+7Sy {
  border-top: 1px solid #eee;
  padding-top: 20px;
}

@media (max-width: 768px) {
  .AddTopicForm_formContainer__tpxRV {
    margin: 20px;
    padding: 15px;
  }

  .AddTopicForm_inputField__\\+Tsfm,
  .AddTopicForm_selectField__jmEo8 {
    padding: 10px;
  }

  .AddTopicForm_submitButton__dGNx0 {
    padding: 10px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/VideoCourse_AdminPanel/AddTopicForm.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,YAAY;EACZ,aAAa;EACb,yBAAyB;EACzB,kBAAkB;EAClB,wCAAwC;AAC1C;;AAEA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,mBAAmB;EACnB,WAAW;EACX,eAAe;AACjB;;AAEA;;EAEE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,sBAAsB;EACtB,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,gBAAgB;EAChB,yBAAyB;EACzB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,eAAe;;AAEjB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,0BAA0B;EAC1B,iBAAiB;AACnB;;AAEA;EACE;IACE,YAAY;IACZ,aAAa;EACf;;EAEA;;IAEE,aAAa;EACf;;EAEA;IACE,aAAa;EACf;AACF","sourcesContent":[".topic-form {\n  max-width: 600px;\n  margin: auto;\n  padding: 20px;\n  background-color: #f5f5f5;\n  border-radius: 8px;\n  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);\n}\n\n.formTitle {\n  margin-bottom: 30px;\n  text-align: center;\n  color: #333;\n}\n\n.topic-form label {\n  display: block;\n  margin-top: 20px;\n  margin-bottom: 10px;\n  color: #333;\n  font-size: 1rem;\n}\n\n.inputField,\n.selectField {\n  width: 100%;\n  padding: 12px;\n  margin-bottom: 20px;\n  border: 1px solid #ccc;\n  border-radius: 4px;\n  font-size: 0.9rem;\n}\n\n.submitButton {\n  width: 100%;\n  padding: 12px;\n  margin-top: 20px;\n  background-color: #0056b3;\n  color: #fff;\n  border: none;\n  border-radius: 4px;\n  font-size: 1rem;\n  cursor: pointer;\n  \n}\n\n.submitButton:hover {\n  background-color: #004494;\n}\n\n.topic {\n  border-top: 1px solid #eee;\n  padding-top: 20px;\n}\n\n@media (max-width: 768px) {\n  .formContainer {\n    margin: 20px;\n    padding: 15px;\n  }\n\n  .inputField,\n  .selectField {\n    padding: 10px;\n  }\n\n  .submitButton {\n    padding: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"topic-form": `AddTopicForm_topic-form__UzQFb`,
	"formTitle": `AddTopicForm_formTitle__pBp7z`,
	"inputField": `AddTopicForm_inputField__+Tsfm`,
	"selectField": `AddTopicForm_selectField__jmEo8`,
	"submitButton": `AddTopicForm_submitButton__dGNx0`,
	"topic": `AddTopicForm_topic__j+7Sy`,
	"formContainer": `AddTopicForm_formContainer__tpxRV`
};
export default ___CSS_LOADER_EXPORT___;
