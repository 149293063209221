// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PrivacySetting_PrivacySettings-fullPage__9ocYx{
    width: 65rem;
    height: 20rem;
}

.PrivacySetting_PrivacySettings-checkbox1__bhd0J{
    display: flex;
    height: 4rem;
    align-items: center;
    gap: 1rem;
    
}
.PrivacySetting_checkbox-ps__nNB3o{
    color: white;
    background-color: #020062;
}

.PrivacySetting_PrivacySettings-checkbox2__Uqexe{
    display: flex;
    height: 4rem;
    align-items: center;
    gap: 1rem;
}
.PrivacySetting_save-btn__6n6JX{
    width: 10%;
    height: 2.5rem;
    border: none;
    font-size: 14px;
    font-weight: bold;
    background-color: #020062;
    color: white;
}`, "",{"version":3,"sources":["webpack://./src/Profile/PrivacySetting.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,mBAAmB;IACnB,SAAS;;AAEb;AACA;IACI,YAAY;IACZ,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,mBAAmB;IACnB,SAAS;AACb;AACA;IACI,UAAU;IACV,cAAc;IACd,YAAY;IACZ,eAAe;IACf,iBAAiB;IACjB,yBAAyB;IACzB,YAAY;AAChB","sourcesContent":[".PrivacySettings-fullPage{\n    width: 65rem;\n    height: 20rem;\n}\n\n.PrivacySettings-checkbox1{\n    display: flex;\n    height: 4rem;\n    align-items: center;\n    gap: 1rem;\n    \n}\n.checkbox-ps{\n    color: white;\n    background-color: #020062;\n}\n\n.PrivacySettings-checkbox2{\n    display: flex;\n    height: 4rem;\n    align-items: center;\n    gap: 1rem;\n}\n.save-btn{\n    width: 10%;\n    height: 2.5rem;\n    border: none;\n    font-size: 14px;\n    font-weight: bold;\n    background-color: #020062;\n    color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PrivacySettings-fullPage": `PrivacySetting_PrivacySettings-fullPage__9ocYx`,
	"PrivacySettings-checkbox1": `PrivacySetting_PrivacySettings-checkbox1__bhd0J`,
	"checkbox-ps": `PrivacySetting_checkbox-ps__nNB3o`,
	"PrivacySettings-checkbox2": `PrivacySetting_PrivacySettings-checkbox2__Uqexe`,
	"save-btn": `PrivacySetting_save-btn__6n6JX`
};
export default ___CSS_LOADER_EXPORT___;
