// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TypingMyOrders_container__qJLSp {
    padding: 1.5rem;
    margin-bottom: 25rem;
  }
  
  .TypingMyOrders_header__PeHrW {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .TypingMyOrders_button_link__Y2t5S, .TypingMyOrders_button_books__hXx3F {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 5px 10px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    display: inline-block;
    margin: 5px 5px;
    text-align: center;
  }
  
  .TypingMyOrders_button_link__Y2t5S a, .TypingMyOrders_button_books__hXx3F a {
    color: white;
    text-decoration: none;
    display: block;
    width: 100%;
    height: 100%;
  }
  
  .TypingMyOrders_message__HJ-Rx {
    font-size: 16px;
    color: gray;
  }
  
  .TypingMyOrders_table__bltby {
    width: 100%;
    margin-top: 5px;
  }
  
  .TypingMyOrders_productTitle__1qWpv {
    cursor: pointer;
    color: blue;
  }
  .TypingMyOrders_main_content__OSfEH{
    margin-bottom: 10rem;
  }

  /* Responsive adjustments for small screens */
@media (max-width: 768px) {
  .TypingMyOrders_button_link__Y2t5S, .TypingMyOrders_button_books__hXx3F {
    padding: 4px 8px;
    font-size: 14px;
    width: 100%; /* Full width buttons for smaller screens */
  }
}

@media (max-width: 480px) {
  .TypingMyOrders_button_link__Y2t5S, .TypingMyOrders_button_books__hXx3F {
    padding: 3px 6px;
    font-size: 12px;
  }
}`, "",{"version":3,"sources":["webpack://./src/Typing/TypingMyOrders.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,oBAAoB;EACtB;;EAEA;IACE,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;EACrB;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,iBAAiB;IACjB,eAAe;IACf,kBAAkB;IAClB,eAAe;IACf,qBAAqB;IACrB,eAAe;IACf,kBAAkB;EACpB;;EAEA;IACE,YAAY;IACZ,qBAAqB;IACrB,cAAc;IACd,WAAW;IACX,YAAY;EACd;;EAEA;IACE,eAAe;IACf,WAAW;EACb;;EAEA;IACE,WAAW;IACX,eAAe;EACjB;;EAEA;IACE,eAAe;IACf,WAAW;EACb;EACA;IACE,oBAAoB;EACtB;;EAEA,6CAA6C;AAC/C;EACE;IACE,gBAAgB;IAChB,eAAe;IACf,WAAW,EAAE,2CAA2C;EAC1D;AACF;;AAEA;EACE;IACE,gBAAgB;IAChB,eAAe;EACjB;AACF","sourcesContent":[".container {\n    padding: 1.5rem;\n    margin-bottom: 25rem;\n  }\n  \n  .header {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n  }\n  \n  .button_link, .button_books {\n    background-color: #007bff;\n    color: white;\n    border: none;\n    padding: 5px 10px;\n    font-size: 16px;\n    border-radius: 5px;\n    cursor: pointer;\n    display: inline-block;\n    margin: 5px 5px;\n    text-align: center;\n  }\n  \n  .button_link a, .button_books a {\n    color: white;\n    text-decoration: none;\n    display: block;\n    width: 100%;\n    height: 100%;\n  }\n  \n  .message {\n    font-size: 16px;\n    color: gray;\n  }\n  \n  .table {\n    width: 100%;\n    margin-top: 5px;\n  }\n  \n  .productTitle {\n    cursor: pointer;\n    color: blue;\n  }\n  .main_content{\n    margin-bottom: 10rem;\n  }\n\n  /* Responsive adjustments for small screens */\n@media (max-width: 768px) {\n  .button_link, .button_books {\n    padding: 4px 8px;\n    font-size: 14px;\n    width: 100%; /* Full width buttons for smaller screens */\n  }\n}\n\n@media (max-width: 480px) {\n  .button_link, .button_books {\n    padding: 3px 6px;\n    font-size: 12px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `TypingMyOrders_container__qJLSp`,
	"header": `TypingMyOrders_header__PeHrW`,
	"button_link": `TypingMyOrders_button_link__Y2t5S`,
	"button_books": `TypingMyOrders_button_books__hXx3F`,
	"message": `TypingMyOrders_message__HJ-Rx`,
	"table": `TypingMyOrders_table__bltby`,
	"productTitle": `TypingMyOrders_productTitle__1qWpv`,
	"main_content": `TypingMyOrders_main_content__OSfEH`
};
export default ___CSS_LOADER_EXPORT___;
