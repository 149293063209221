// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar-for-quiz{
          width: 260px;
    height: 534px;
    overflow-y: scroll;
    scroll-behavior: smooth!important;
    position: fixed;

}
.sidebar-for-quiz h2 {
    font-size: 25px;
    padding-left: 16px;
    margin: -4px 0 4px 0;
    /* width: 211px; */
    font-weight: 400;
    /* height: 3rem; */
    padding-top: 10px;
    padding-bottom: 10px;
}
.li-for-quiz{
        
}
.sidebar-for-quiz li{
padding-left: 19px;
    height: 2.5rem;
    /* margin-top: 11px; */
  
    padding-top: 5px;
    padding-bottom: 10px;
    cursor: pointer;
}
.sidebar-for-quiz ul{
           padding-left: 0rem!important;
    width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/Ebook/DigitalContentPages/Quiz/Sidebarquiz.css"],"names":[],"mappings":"AAAA;UACU,YAAY;IAClB,aAAa;IACb,kBAAkB;IAClB,iCAAiC;IACjC,eAAe;;AAEnB;AACA;IACI,eAAe;IACf,kBAAkB;IAClB,oBAAoB;IACpB,kBAAkB;IAClB,gBAAgB;IAChB,kBAAkB;IAClB,iBAAiB;IACjB,oBAAoB;AACxB;AACA;;AAEA;AACA;AACA,kBAAkB;IACd,cAAc;IACd,sBAAsB;;IAEtB,gBAAgB;IAChB,oBAAoB;IACpB,eAAe;AACnB;AACA;WACW,4BAA4B;IACnC,WAAW;AACf","sourcesContent":[".sidebar-for-quiz{\n          width: 260px;\n    height: 534px;\n    overflow-y: scroll;\n    scroll-behavior: smooth!important;\n    position: fixed;\n\n}\n.sidebar-for-quiz h2 {\n    font-size: 25px;\n    padding-left: 16px;\n    margin: -4px 0 4px 0;\n    /* width: 211px; */\n    font-weight: 400;\n    /* height: 3rem; */\n    padding-top: 10px;\n    padding-bottom: 10px;\n}\n.li-for-quiz{\n        \n}\n.sidebar-for-quiz li{\npadding-left: 19px;\n    height: 2.5rem;\n    /* margin-top: 11px; */\n  \n    padding-top: 5px;\n    padding-bottom: 10px;\n    cursor: pointer;\n}\n.sidebar-for-quiz ul{\n           padding-left: 0rem!important;\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
