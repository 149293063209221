import React, { useState } from "react";
import classes from "./Categories.module.css";

export default function Categories({ onCategorySelect, onSubjectSelect }) {
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubject, setSelectedSubject] = useState([]);

  const handleCategoryClick = (category) => {
    if (selectedCategory === category) {
      setSelectedCategory("");
      onCategorySelect("");
    } else {
      setSelectedCategory(category);
      onCategorySelect(category);
    }
  };

  const handleSubjectChange = (subject) => {
    setSelectedSubject((prev) =>
      prev.includes(subject)
        ? prev.filter((item) => item !== subject)
        : [...prev, subject]
    );
    onSubjectSelect((prev) =>
      prev.includes(subject)
        ? prev.filter((item) => item !== subject)
        : [...prev, subject]
    );
  };
  

  // const handleLanguageChange = (language) => {
  //   setSelectedLanguage((prev) =>
  //     prev.includes(language)
  //       ? prev.filter((item) => item !== language)
  //       : [...prev, language]
  //   );
  // };

  const isChecked = (category) => selectedCategory === category;

  const getCategoryContent = () => {
    switch (selectedCategory) {
      case "SSC":
        return (
          <>
            <div className={classes.categories_inputDiv}>
              <div className={classes.Category_content_row1}>
                 SSC CGL
              </div>
            </div>
            <div className={classes.categories_inputDiv}>
              <div className={classes.Category_content_row1}>
                 SSC CHSL
              </div>
            </div>
            <div className={classes.categories_inputDiv}>
              <div className={classes.Category_content_row1}>
                 SSC MTS
              </div>
            </div>
            <div className={classes.categories_inputDiv}>
              <div className={classes.Category_content_row1}>
                 SSC Stenographers
              </div>
            </div>
            <div className={classes.categories_inputDiv}>
              <div className={classes.Category_content_row1}>
                 SSC CPO
              </div>
            </div>
            <div className={classes.categories_inputDiv}>
              <div className={classes.Category_content_row1}>
                 SSC JE
              </div>
            </div>
            <div className={classes.categories_inputDiv}>
              <div className={classes.Category_content_row1}>
                 SSC JHT
              </div>
            </div>
          </>
        );
      case "Railway":
        return (
          <>
            <div className={classes.categories_inputDiv}>
              <div className={classes.Category_content_row1}>
                 RRB NTPC
              </div>
            </div>
            <div className={classes.categories_inputDiv}>
              <div className={classes.Category_content_row1}>
                 RRB JE
              </div>
            </div>
            <div className={classes.categories_inputDiv}>
              <div className={classes.Category_content_row1}>
                 RPF SI
              </div>
            </div>
            <div className={classes.categories_inputDiv}>
              <div className={classes.Category_content_row1}>
                 RPF Constable
              </div>
            </div>
            <div className={classes.categories_inputDiv}>
              <div className={classes.Category_content_row1}>
                 RRB Junior Stenographer
              </div>
            </div>
            <div className={classes.categories_inputDiv}>
              <div className={classes.Category_content_row1}>
                 RRB JE CE
              </div>
            </div>
            <div className={classes.categories_inputDiv}>
              <div className={classes.Category_content_row1}>
                 RRB JE EE
              </div>
            </div>
            <div className={classes.categories_inputDiv}>
              <div className={classes.Category_content_row1}>
                 RRB JE ME
              </div>
            </div>
            <div className={classes.categories_inputDiv}>
              <div className={classes.Category_content_row1}>
                 RRB Junior Translator
              </div>
            </div>
          </>
        );
      case "Delhi Police":
        return (
          <>
            <div className={classes.categories_inputDiv}>
              <div className={classes.Category_content_row1}> Delhi Police
              </div>
            </div>
          </>
        );
      case "UP Police":
        return (
          <>
            <div className={classes.categories_inputDiv}>
              <div className={classes.Category_content_row1}> UP Police
              </div>
            </div>
          </>
        );
      case "CTET":
        return (
          <>
            <div className={classes.categories_inputDiv}>
              <div className={classes.Category_content_row1}> CTET</div>
            </div>
          </>
        );
      default:
        return <div>No exams category is selected.</div>;
    }
  };
  return (
    <div className={classes.categories_fullPage}>
      {/* Categories */}
      <div className={classes.Category}>
        <div className={classes["Category-heading"]}>Category</div>
        <div className={classes.Category_content}>
          <div className={classes.categories_inputDiv}>
            <div
              className={classes.Category_content_row1}
              onClick={() => handleCategoryClick("SSC")}
            >
              <input type="checkbox" checked={isChecked("SSC")} readOnly /> SSC
            </div>
          </div>
          <div className={classes.categories_inputDiv}>
            <div
              className={classes.Category_content_row1}
              onClick={() => handleCategoryClick("Railway")}
            >
              <input type="checkbox" checked={isChecked("Railway")} readOnly />{" "}
              Railway
            </div>
          </div>
          <div className={classes.categories_inputDiv}>
            <div
              className={classes.Category_content_row1}
              onClick={() => handleCategoryClick("Delhi Police")}
            >
              <input
                type="checkbox"
                checked={isChecked("Delhi Police")}
                readOnly
              />{" "}
              Delhi Police
            </div>
          </div>

          <div className={classes.categories_inputDiv}>
            <div
              className={classes.Category_content_row1}
              onClick={() => handleCategoryClick("UP Police")}
            >
              <input
                type="checkbox"
                checked={isChecked("UP Police")}
                readOnly
              />
              UP Police
            </div>
          </div>
          <div className={classes.categories_inputDiv}>
            <div
              className={classes.Category_content_row1}
              onClick={() => handleCategoryClick("CTET")}
            >
              <input type="checkbox" checked={isChecked("CTET")} readOnly />
              CTET
            </div>
          </div>
        </div>
      </div>

      {/* Exam Content */}
      <div className={classes["Category-heading"]}>Exam Name</div>
      <div className={classes.Category_content}>{getCategoryContent()}</div>
      {/* Subject Section */}
      <div className={classes["Category-heading"]}>Subject</div>
      <div className={classes.Category_content}>
      <div className={classes.Category_content_row1}>select one at a time</div>
        {[
          "Maths",
          "English",
          "Reasoning",
          "General studies",
          "Current Affairs",
        ].map((subject) => (
          <div className={classes.categories_inputDiv} key={subject}>

            <input
              type="checkbox"
              checked={selectedSubject.includes(subject)}
              onChange={() => handleSubjectChange(subject)}
            />
            {subject}
          </div>
        ))}
      </div>
    </div>
  );
}
