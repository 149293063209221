// import React, { useState, useEffect } from "react";
// import { List } from "react-movable";
// import Swal from "sweetalert2";
// import "./ReorderEbooks.css";

// function ReorderEbooks() {
//   const [ebookList, setEbookList] = useState([]);
//   const [draggingIndex, setDraggingIndex] = useState(null); // State to track the dragging index

//   useEffect(() => {
//     fetch("https://auth.ssccglpinnacle.com/api/ebooksforactive")
//       .then((response) => response.json())
//       .then((data) => {
//         const sortedData = data.sort((a, b) => a.order - b.order);
//         setEbookList(sortedData);
//       })
//       .catch((error) => console.error("Error fetching ebooks:", error));
//   }, []);

//   const handleSaveOrder = async () => {
//     const reorderedEbooks = ebookList.map((ebook, index) => ({
//       _id: ebook._id,
//       order: index + 1,
//     }));

//     try {
//       const response = await fetch("https://auth.ssccglpinnacle.com/api/reorder-ebooks", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(reorderedEbooks),
//       });

//       if (response.ok) {
//         Swal.fire("Success", "Order updated successfully!", "success");
//       } else {
//         Swal.fire("Error", "Failed to update order.", "error");
//       }
//     } catch (error) {
//       Swal.fire("Error", "Failed to update order.", "error");
//     }
//   };

//   const swapItems = (array, index1, index2) => {
//     const newArray = [...array];
//     const temp = newArray[index1];
//     newArray[index1] = newArray[index2];
//     newArray[index2] = temp;
//     return newArray;
//   };

//   return (
//     <div className="reorder-ebooks-container">
//       <h2>Reorder eBooks</h2>
//       <List
//         values={ebookList}
//         onChange={({ oldIndex, newIndex }) => {
//           const newEbookList = swapItems(ebookList, oldIndex, newIndex);
//           setEbookList(newEbookList);
//         }}
//         renderList={({ children, props }) => (
//           <div {...props} className="ebooks-grid">
//             {children}
//           </div>
//         )}
//         renderItem={({ value, props, index, isPlaceholder }) => (
//           <div
//             {...props}
//             key={value._id}
//             className={`ebook-card-reorder ${isPlaceholder ? "is-replaceable" : ""}`}
//             onDragEnter={() => setDraggingIndex(value._id)}
//             onDragLeave={() => setDraggingIndex(null)}
//           >
//             <div className="order-indicator">{index + 1}</div> {/* Order Indicator */}
//             <img src={value.image} alt={value.title} className="ebook-image" />
//             <p>{value.title}</p>
//             <p>Medium: {value.medium}</p>
//             <p>Edition: {value.edition}</p>
//             <p>SKU: {value.sku}</p>
//             {value.subscriptionPrices && value.subscriptionPrices.length > 0 && (
//               <div>
//                 <p>Printing Price: ₹{value.subscriptionPrices[0].printingPrice}</p>
//                 <p>Selling Price: ₹{value.subscriptionPrices[0].sellingPrice}</p>
//               </div>
//             )}
//           </div>
//         )}
//       />
//       <button onClick={handleSaveOrder} className="save-order-button">
//         Save Order
//       </button>
//     </div>
//   );
// }

// export default ReorderEbooks;


import React, { useState, useEffect } from "react";
import { List } from "react-movable";
import Swal from "sweetalert2";
import "./ReorderEbooks.css";

function ReorderEbooks() {
  const [ebookList, setEbookList] = useState([]);
  const [hoveredIndex, setHoveredIndex] = useState(null); // Track the hovered index

  useEffect(() => {
    fetch("https://auth.ssccglpinnacle.com/api/ebooksforactive")
      .then((response) => response.json())
      .then((data) => {
        const sortedData = data.sort((a, b) => a.order - b.order);
        setEbookList(sortedData);
      })
      .catch((error) => console.error("Error fetching ebooks:", error));
  }, []);

  const handleSaveOrder = async () => {
    const reorderedEbooks = ebookList.map((ebook, index) => ({
      _id: ebook._id,
      order: index + 1,
    }));

    try {
      const response = await fetch("https://auth.ssccglpinnacle.com/api/reorder-ebooks", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(reorderedEbooks),
      });

      if (response.ok) {
        Swal.fire("Success", "Order updated successfully!", "success");
      } else {
        Swal.fire("Error", "Failed to update order.", "error");
      }
    } catch (error) {
      Swal.fire("Error", "Failed to update order.", "error");
    }
  };

  const swapItems = (array, index1, index2) => {
    const newArray = [...array];
    const temp = newArray[index1];
    newArray[index1] = newArray[index2];
    newArray[index2] = temp;
    return newArray;
  };

  return (
    <div className="reorder-ebooks-container">
      <h2>Reorder eBooks</h2>
      <List
        values={ebookList}
        onChange={({ oldIndex, newIndex }) => {
          const newEbookList = swapItems(ebookList, oldIndex, newIndex);
          setEbookList(newEbookList);
        }}
        renderList={({ children, props }) => (
          <div {...props} className="ebooks-grid">
            {children}
          </div>
        )}
        renderItem={({ value, props, index }) => (
          <div
            {...props}
            key={value._id}
            className={`ebook-card-reorder ${hoveredIndex === index ? "is-replaceable" : ""}`}
            onDragEnter={() => setHoveredIndex(index)}
            onDragLeave={() => setHoveredIndex(null)}
            onDrop={() => setHoveredIndex(null)}  // Reset after drop
          >
            <div className="order-indicator">{index + 1}</div> {/* Order Indicator */}
            <img src={value.image} alt={value.title} className="ebook-image" />
            <p>{value.title}</p>
            <p>Medium: {value.medium}</p>
            <p>Edition: {value.edition}</p>
            <p>SKU: {value.sku}</p>
            {value.subscriptionPrices && value.subscriptionPrices.length > 0 && (
              <div>
                <p>Printing Price: ₹{value.subscriptionPrices[0].printingPrice}</p>
                <p>Selling Price: ₹{value.subscriptionPrices[0].sellingPrice}</p>
              </div>
            )}
          </div>
        )}
      />
      <button onClick={handleSaveOrder} className="save-order-button">
        Save Order
      </button>
    </div>
  );
}

export default ReorderEbooks;
