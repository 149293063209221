import React, { useState, useEffect } from "react";
import "./SidebarEbook.css";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const SidebarEbook = ({ setComponent }) => {
  const [openGroups, setOpenGroups] = useState(() => {
    const savedGroups = localStorage.getItem("openGroups");
    return savedGroups ? JSON.parse(savedGroups) : {};
  });

  const toggleGroup = (group) => {
    setOpenGroups((prevOpenGroups) => {
      const newGroups = {
        ...prevOpenGroups,
        [group]: !prevOpenGroups[group],
      };
      localStorage.setItem("openGroups", JSON.stringify(newGroups));
      return newGroups;
    });
  };

  return (
    <div className="sidebar-ebook">
      <nav className="sidebar-ebook__nav">

      <div className="sidebar-ebook__group">
          <h3
            className={`sidebar-ebook__group-title ${
              openGroups["Student"] ? "open" : ""
            }`}
            onClick={() => toggleGroup("Student")}
          >
            Student {openGroups["Student"] ? <FaChevronUp /> : <FaChevronDown />}
          </h3>
          {openGroups["Student"] && (
            <div className="sidebar-ebook__subitems">
              <button className="sidebar-ebook__link" onClick={() => setComponent('StudentTable')}>Student Table </button>
              
            </div>
          )}
        </div>




        <div className="sidebar-ebook__group">
          <h3
            className={`sidebar-ebook__group-title ${
              openGroups["digital-content"] ? "open" : ""
            }`}
            onClick={() => toggleGroup("digital-content")}
          >
            Ebook{" "}
            {openGroups["digital-content"] ? (
              <FaChevronUp />
            ) : (
              <FaChevronDown />
            )}
          </h3>
          {openGroups["digital-content"] && (
            <div className="sidebar-ebook__subitems">
              <button
                className="sidebar-ebook__link"
                onClick={() => setComponent("EbookNewForm")}
              >
                Add Digital Content
              </button>
              <button
                className="sidebar-ebook__link"
                onClick={() => setComponent("EditEbook")}
              >
                Edit Digital Content
              </button>
              <button
                className="sidebar-ebook__link"
                onClick={() => setComponent("EbookList")}
              >
                Edit Ebook Status
              </button>
              <button
                className="sidebar-ebook__link"
                onClick={() => setComponent("NewStudentAccessForm")}
              >
                Student access ebook
              </button>
              <button
                className="sidebar-ebook__link"
                onClick={() => setComponent("ReorderEbooks")}
              >
               Ebook Reorder
              </button>
            </div>
          )}
        </div>
        <div className="sidebar-ebook__group">
          <h3
            className={`sidebar-ebook__group-title ${
              openGroups["chapter-management"] ? "open" : ""
            }`}
            onClick={() => toggleGroup("chapter-management")}
          >
            Ebook Chapter{" "}
            {openGroups["chapter-management"] ? (
              <FaChevronUp />
            ) : (
              <FaChevronDown />
            )}
          </h3>
          {openGroups["chapter-management"] && (
            <div className="sidebar-ebook__subitems">
              <button
                className="sidebar-ebook__link"
                onClick={() => setComponent("ChapterForm")}
              >
                Add Chapter
              </button>
              <button
                className="sidebar-ebook__link"
                onClick={() => setComponent("ChapterEdit")}
              >
                Edit Chapter
              </button>
              <button
                className="sidebar-ebook__link"
                onClick={() => setComponent("AddPDFForm")}
              >
                Add Chapter PDF
              </button>
              <button
                className="sidebar-ebook__link"
                onClick={() => setComponent("MultiPDFUploadForm")}
              >
                Add chapter multiple PDF
              </button>
              <button
                className="sidebar-ebook__link"
                onClick={() => setComponent("UpdatePDFForm")}
              >
                Edit Chapter PDF
              </button>
              <button
                className="sidebar-ebook__link"
                onClick={() => setComponent("ChapterEditWithPDFManagement")}
              >
                Pdf management
              </button>
            </div>
          )}
        </div>

        <div className="sidebar-ebook__group">
          <h3
            className={`sidebar-ebook__group-title ${
              openGroups["Book"] ? "open" : ""
            }`}
            onClick={() => toggleGroup("Book")}
          >
            Book {openGroups["Book"] ? <FaChevronUp /> : <FaChevronDown />}
          </h3>
          {openGroups["Book"] && (
            <div className="sidebar-ebook__subitems">
              <button className="sidebar-ebook__link" onClick={() => setComponent('BookNewForm')}>Add Book </button>
              <button className="sidebar-ebook__link" onClick={() => setComponent('EditBook')}>Edit Book</button>
              <button className="sidebar-ebook__link" onClick={() => setComponent('BookList')}>Book Status</button>
              <button className="sidebar-ebook__link" onClick={() => setComponent('ReorderBooks')}>Reorder Book</button>
            </div>
          )}
        </div>

        <div className="sidebar-ebook__group">
          <h3
            className={`sidebar-ebook__group-title ${
              openGroups["Blog"] ? "open" : ""
            }`}
            onClick={() => toggleGroup("Blog")}
          >
            Blog {openGroups["Blog"] ? <FaChevronUp /> : <FaChevronDown />}
          </h3>
          {openGroups["Blog"] && (
            <div className="sidebar-ebook__subitems">
              <button
                className="sidebar-ebook__link"
                onClick={() => setComponent("BlogForm")}
              >
                Add Blog{" "}
              </button>
              <button
                className="sidebar-ebook__link"
                onClick={() => setComponent("EditedBlog")}
              >
                Edit Blog
              </button>
            </div>
          )}
        </div>

        <div className="sidebar-ebook__group">
          <h3
            className={`sidebar-ebook__group-title ${
              openGroups["Typing"] ? "open" : ""
            }`}
            onClick={() => toggleGroup("Typing")}
          >
            Typing {openGroups["Typing"] ? <FaChevronUp /> : <FaChevronDown />}
          </h3>
          {openGroups["Typing"] && (
            <div className="sidebar-ebook__subitems">
              <button
                className="sidebar-ebook__link"
                onClick={() => setComponent("AddTypingParagraph")}
              >
                Add Typing{" "}
              </button>
              <button
                className="sidebar-ebook__link"
                onClick={() => setComponent("EditTypingParagraph")}
              >
                Edit Typing
              </button>
              <button
                className="sidebar-ebook__link"
                onClick={() => setComponent("BulkTypingAccessForm")}
              >
                Student Access Typing
              </button>
            </div>
          )}
        </div>
        <div className="sidebar-ebook__group">
          <h3
            className={`sidebar-ebook__group-title ${
              openGroups["VideoCourses"] ? "open" : ""
            }`}
            onClick={() => toggleGroup("VideoCourses")}
          >
            Video Courses{" "}
            {openGroups["VideoCourses"] ? <FaChevronUp /> : <FaChevronDown />}
          </h3>
          {openGroups["VideoCourses"] && (
            <div className="sidebar-ebook__subitems">
              <button
                className="sidebar-ebook__link"
                onClick={() => setComponent("AddCourseForm")}
              >
                Add Video Course
              </button>
              <button
                className="sidebar-ebook__link"
                onClick={() => setComponent("ManageCourseList")}
              >
                Manage Video Course
              </button>
              <button
                className="sidebar-ebook__link"
                onClick={() => setComponent("AddPdfForm")}
              >
                Add PDF
              </button>
              <button
                className="sidebar-ebook__link"
                onClick={() => setComponent("ManagePDFs")}
              >
                Manage PDF
              </button>
              <button
                className="sidebar-ebook__link"
                onClick={() => setComponent("AddCourseChapters")}
              >
                Add Course Content
              </button>
              <button
                className="sidebar-ebook__link"
                onClick={() => setComponent("ManageChapter")}
              >
                Manage Course Content
              </button>
              <button
                className="sidebar-ebook__link"
                onClick={() => setComponent("AddInstructorForm")}
              >
                Add Instructor
              </button>
              <button
                className="sidebar-ebook__link"
                onClick={() => setComponent("ManageInstructorListing")}
              >
                Manage Instructor
              </button>
              <button
                className="sidebar-ebook__link"
                onClick={() => setComponent("AddAnnouncementForm")}
              >
                Add Announcements
              </button>
              <button
                className="sidebar-ebook__link"
                onClick={() => setComponent("AnnouncementList")}
              >
                Manage Announcement
              </button>
              <button
                className="sidebar-ebook__link"
                onClick={() => setComponent("Category")}
              >
                Add/Edit Category
              </button>
              <button
                className="sidebar-ebook__link"
                onClick={() => setComponent("Add Exam")}
              >
                Add Category Exam
              </button>
              <button
                className="sidebar-ebook__link"
                onClick={() => setComponent("Exam List")}
              >
                Manage Category Exam 
              </button>
              <button
                className="sidebar-ebook__link"
                onClick={() => setComponent("Banner")}
              >
               Video Banner 
              </button>
              <button
                className="sidebar-ebook__link"
                onClick={() => setComponent("BannerList")}
              >
               Manage Video Banner 
              </button>
              <button
                className="sidebar-ebook__link"
                onClick={() => setComponent("VideoCourseAccess")}
              >
                Provide Access
              </button>
            </div>
          )}
        </div>
      </nav>
    </div>
  );
};

export default SidebarEbook;
