import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { AiFillPlusCircle, AiFillEdit, AiFillDelete } from "react-icons/ai";
import styles from "./NoteEditor.module.css";
import { useAuth } from "../../../Context/AuthContext";

const NoteEditor = ({ chapterTitle, videoTitle }) => {
  const { courseId } = useParams();
  const { user } = useAuth();
  const [editorVisible, setEditorVisible] = useState(false);
  const [currentNote, setCurrentNote] = useState(null);
  const [text, setText] = useState("");
  const [loading, setLoading] = useState(false);
  const [notes, setNotes] = useState([]);
  const [sort, setSort] = useState("recent");

  const fetchNotes = async () => {
    try {
      const response = await fetch(
        `https://auth.ssccglpinnacle.com/api/notes?userId=${user._id}&courseId=${courseId}`
      );
      const data = await response.json();
      if (Array.isArray(data)) {
        setNotes(data);
      } else {
        setNotes([]);
      }
    } catch (error) {
      console.error("Error fetching notes:", error);
      setNotes([]);
    }
  };

  useEffect(() => {
    fetchNotes();
  }, [courseId, user._id]);

  const handleSaveNote = async () => {
    setLoading(true);

    const noteData = {
      userId: user._id,
      courseId,
      text,
    };

    try {
      const response = await fetch(
        "https://auth.ssccglpinnacle.com/api/notes",
        {
          method: currentNote ? "PUT" : "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ ...noteData, _id: currentNote?._id }),
        }
      );

      if (response.ok) {
        setText("");
        setEditorVisible(false);
        setCurrentNote(null);
        fetchNotes(); // Refresh notes
      } else {
        const errorData = await response.json();
        console.error("Failed to save note", errorData.message);
      }
    } catch (error) {
      console.error("Error", error.message);
    }

    setLoading(false);
  };

  const handleSortChange = (sort) => {
    setSort(sort);
  };

  const handleDeleteNote = async (noteId) => {
    try {
      const response = await fetch(
        `https://auth.ssccglpinnacle.com/api/notes/${noteId}`,
        {
          method: "DELETE",
        }
      );

      if (response.ok) {
        console.log("Note deleted successfully");
        fetchNotes(); // Refresh notes
      } else {
        console.error("Failed to delete note");
      }
    } catch (error) {
      console.error("Error deleting note:", error);
    }
  };

  const handleEditNote = (note) => {
    setCurrentNote(note);
    setText(note.text);
    setEditorVisible(true);
  };

  const sortedNotes = Array.isArray(notes)
    ? notes.sort((a, b) => {
        if (sort === "recent") {
          return new Date(b.createdAt) - new Date(a.createdAt);
        }
        return new Date(a.createdAt) - new Date(b.createdAt);
      })
    : [];

  return (
    <div className={styles.noteEditorContainer}>
      <div className={styles.addNoteContainer}>
        {!editorVisible && (
          <div
            className={styles.createNote}
            onClick={() => {
              setCurrentNote(null);
              setText("");
              setEditorVisible(true);
            }}
          >
            <input
              type="text"
              placeholder="Create a new note"
              readOnly
              className={styles.createNoteInput}
            />
            <button
              className={styles.addNoteButton}
              onClick={() => {
                setCurrentNote(null);
                setText("");
                setEditorVisible(true);
              }}
            >
              <AiFillPlusCircle className={styles.icon} />
            </button>
          </div>
        )}
        {editorVisible && (
          <div className={styles.noteEditor}>
            <ReactQuill
              value={text}
              onChange={setText}
              className={styles.editorCustomStyle}
            />
            <button
              className={styles.saveButton}
              onClick={handleSaveNote}
              disabled={loading}
            >
              {loading
                ? "Saving..."
                : currentNote
                ? "Update Note"
                : "Save Note"}
            </button>
            <button
              className={styles.cancelButton}
              onClick={() => setEditorVisible(false)}
            >
              Cancel
            </button>
          </div>
        )}
      </div>
      <div className={styles.filterSection}>
        <div className={styles.filterCourseContainer}>
          <select className={styles.dropdown}>
            <option value="allcourse">AllCourses</option>
            <option value="currentLecture">Current Lecture</option>
          </select>
        </div>
        <div className={styles.filterSortContainer}>
          <select
            onChange={(e) => handleSortChange(e.target.value)}
            className={styles.dropdown}
          >
            <option value="recent">Sort by most recent</option>
            <option value="oldest">Sort by oldest</option>
          </select>
        </div>
      </div>
      <div className={styles.notesContainer}>
        {sortedNotes.map((note) => (
          <div key={note._id} className={styles.note}>
            <div className={styles.noteHeader}>
              {/* <strong>{note.chapterName || 'Chapter Name'}</strong> */}
              <span className={styles.noteActions}>
                <AiFillEdit
                  className={styles.editIcon}
                  onClick={() => handleEditNote(note)}
                />
                <AiFillDelete
                  className={styles.deleteIcon}
                  onClick={() => handleDeleteNote(note._id)}
                />
              </span>
            </div>
            {/* <div className={styles.noteVideoTitle}>{note.videoTitle || 'Video Title'}</div> */}
            <div dangerouslySetInnerHTML={{ __html: note.text }} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default NoteEditor;
