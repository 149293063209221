import React, { useState, forwardRef } from "react";
import axios from "axios";
import classes from "./CreateListModal.module.css";

// Forwarding ref to be used in the parent component
const CreateListModal = forwardRef(({ userId, course, onClose, onListCreate }, ref) => {
  const [listTitle, setListTitle] = useState("");
  const [description, setDescription] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const newList = {
        userId,
        listTitle,
        description,
        courses: [
          {
            courseId: course._id || course.id, 
          },
        ],
      };

      // Send the new list to the backend
      const response = await axios.post(
        "https://auth.ssccglpinnacle.com/api/create-list",
        newList
      );

      if (response.status === 201) {
        onListCreate(response.data.newList);
        onClose(); 
      }
    } catch (error) {
      console.error("Failed to create list:", error);
    }
  };

  return (
    <div className={classes.modalOverlay}>
      <div className={classes.modal} ref={ref}>
        <h2>Create New List</h2>
        <form onSubmit={handleSubmit}>
          <div className={classes.formGroup}>
            <label>List Title</label>
            <input
              type="text"
              value={listTitle}
              onChange={(e) => setListTitle(e.target.value)}
              required
            />
          </div>
          <div className={classes.formGroup}>
            <label>Description</label>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            ></textarea>
          </div>
          <div className={classes.buttonContainer}>
            <button
              type="button"
              className={classes.cancelButton}
              onClick={onClose}
            >
              Cancel
            </button>
            <button type="submit" className={classes.createButton}>
              Create
            </button>
          </div>
        </form>
      </div>
    </div>
  );
});

export default CreateListModal;
