// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Register.css */

.container-for-register {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f5f5f5;
}

.register-form-admin {
  background: #ffffff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
  text-align: center;
}

.register-form-admin h2 {
  margin-bottom: 20px;
  color: #333;
  font-size: 24px;
  font-weight: bold;
}

.register-form-admin input {
  width: 100%;
  padding: 12px 20px;
  margin: 10px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

.register-form-admin input:focus {
  border-color: #007bff;
  outline: none;
}

.register-form-admin button {
  width: 100%;
  background-color: #007bff;
  color: white;
  padding: 14px 20px;
  margin: 10px 0;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}

.register-form-admin button:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .register-form-admin {
    padding: 20px;
  }
}

@media (max-width: 480px) {
  .register-form-admin {
    padding: 15px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Ebook/login/Register.css"],"names":[],"mappings":"AAAA,iBAAiB;;AAEjB;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,iBAAiB;EACjB,yBAAyB;AAC3B;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,wCAAwC;EACxC,gBAAgB;EAChB,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;EACnB,WAAW;EACX,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,cAAc;EACd,qBAAqB;EACrB,sBAAsB;EACtB,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;EACE,qBAAqB;EACrB,aAAa;AACf;;AAEA;EACE,WAAW;EACX,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;EAClB,cAAc;EACd,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE;IACE,aAAa;EACf;AACF;;AAEA;EACE;IACE,aAAa;EACf;AACF","sourcesContent":["/* Register.css */\n\n.container-for-register {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  min-height: 100vh;\n  background-color: #f5f5f5;\n}\n\n.register-form-admin {\n  background: #ffffff;\n  padding: 40px;\n  border-radius: 10px;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n  max-width: 400px;\n  width: 100%;\n  text-align: center;\n}\n\n.register-form-admin h2 {\n  margin-bottom: 20px;\n  color: #333;\n  font-size: 24px;\n  font-weight: bold;\n}\n\n.register-form-admin input {\n  width: 100%;\n  padding: 12px 20px;\n  margin: 10px 0;\n  display: inline-block;\n  border: 1px solid #ccc;\n  border-radius: 5px;\n  box-sizing: border-box;\n}\n\n.register-form-admin input:focus {\n  border-color: #007bff;\n  outline: none;\n}\n\n.register-form-admin button {\n  width: 100%;\n  background-color: #007bff;\n  color: white;\n  padding: 14px 20px;\n  margin: 10px 0;\n  border: none;\n  border-radius: 5px;\n  cursor: pointer;\n  font-size: 16px;\n  font-weight: bold;\n}\n\n.register-form-admin button:hover {\n  background-color: #0056b3;\n}\n\n@media (max-width: 768px) {\n  .register-form-admin {\n    padding: 20px;\n  }\n}\n\n@media (max-width: 480px) {\n  .register-form-admin {\n    padding: 15px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
