// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pdf-viewer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%!important;
}
.pdf-viewer{
  width: 100%!important;
  height: 100%;
}
@media print {
  body {
    display: none !important;
  }
}


.pdf-document {
  width: 100%;
  height: 100%;
  /* overflow: auto; */
  border: 1px solid #ccc;
}

.message-for-pdf {
  font-size: 18px;
  color: #555;
}
.pdf-embed::part(download-button),
.pdf-embed::part(print-button) {
  display: none;
}


.pdf-embed::-webkit-scrollbar { width: 0 !important }


.ndfHFb-c4YZDc-i5oIFb.ndfHFb-c4YZDc-e1YmVc .ndfHFb-c4YZDc-Wrql6b-AeOLfc-b0t70b .ndfHFb-c4YZDc-GSQQnc-LgbsSe {
  margin-left: 0;
  display: none!important;
}

.no-answer-message {
  font-size: 18px;
  color: #ff6b6b; /* Light red color for attention */
  text-align: center;
  margin-top: 20px;
  background-color: #ffecec; /* Light background to match the text color */
  padding: 15px;
  border-radius: 8px;
  border: 1px solid #ff6b6b;
  font-weight: bold;
  width: 80%;
  margin: 20px auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
`, "",{"version":3,"sources":["webpack://./src/Ebook/DigitalContentPages/NewPdfShow1.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,sBAAsB;EACtB,YAAY;EACZ,qBAAqB;AACvB;AACA;EACE,qBAAqB;EACrB,YAAY;AACd;AACA;EACE;IACE,wBAAwB;EAC1B;AACF;;;AAGA;EACE,WAAW;EACX,YAAY;EACZ,oBAAoB;EACpB,sBAAsB;AACxB;;AAEA;EACE,eAAe;EACf,WAAW;AACb;AACA;;EAEE,aAAa;AACf;;;AAGA,gCAAgC,oBAAoB;;;AAGpD;EACE,cAAc;EACd,uBAAuB;AACzB;;AAEA;EACE,eAAe;EACf,cAAc,EAAE,kCAAkC;EAClD,kBAAkB;EAClB,gBAAgB;EAChB,yBAAyB,EAAE,6CAA6C;EACxE,aAAa;EACb,kBAAkB;EAClB,yBAAyB;EACzB,iBAAiB;EACjB,UAAU;EACV,iBAAiB;EACjB,wCAAwC;AAC1C","sourcesContent":[".pdf-viewer-container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  height: 100%;\n  width: 100%!important;\n}\n.pdf-viewer{\n  width: 100%!important;\n  height: 100%;\n}\n@media print {\n  body {\n    display: none !important;\n  }\n}\n\n\n.pdf-document {\n  width: 100%;\n  height: 100%;\n  /* overflow: auto; */\n  border: 1px solid #ccc;\n}\n\n.message-for-pdf {\n  font-size: 18px;\n  color: #555;\n}\n.pdf-embed::part(download-button),\n.pdf-embed::part(print-button) {\n  display: none;\n}\n\n\n.pdf-embed::-webkit-scrollbar { width: 0 !important }\n\n\n.ndfHFb-c4YZDc-i5oIFb.ndfHFb-c4YZDc-e1YmVc .ndfHFb-c4YZDc-Wrql6b-AeOLfc-b0t70b .ndfHFb-c4YZDc-GSQQnc-LgbsSe {\n  margin-left: 0;\n  display: none!important;\n}\n\n.no-answer-message {\n  font-size: 18px;\n  color: #ff6b6b; /* Light red color for attention */\n  text-align: center;\n  margin-top: 20px;\n  background-color: #ffecec; /* Light background to match the text color */\n  padding: 15px;\n  border-radius: 8px;\n  border: 1px solid #ff6b6b;\n  font-weight: bold;\n  width: 80%;\n  margin: 20px auto;\n  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
