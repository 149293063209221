// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Footer_Footer_fullPage__84usb{
    padding: 2%;
    background-color: black;
    height:125px;
    display: flex;
    color: white;
    align-items: center;
    justify-content: center;

}
.Footer_para__MUSCD{
    width: 20%;
    font-size: 12px;
    line-height: 28px;

}
.Footer_language_btn__hEbWn{
    font-size: 11px;
    background-color: transparent;
    color: white;
    border: none;
    margin-bottom: 5em;
}
.Footer_language-icon__5CSGP{
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/component/HomePage01/Footer/Footer.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,uBAAuB;IACvB,YAAY;IACZ,aAAa;IACb,YAAY;IACZ,mBAAmB;IACnB,uBAAuB;;AAE3B;AACA;IACI,UAAU;IACV,eAAe;IACf,iBAAiB;;AAErB;AACA;IACI,eAAe;IACf,6BAA6B;IAC7B,YAAY;IACZ,YAAY;IACZ,kBAAkB;AACtB;AACA;EACE,YAAY;AACd","sourcesContent":[".Footer_fullPage{\n    padding: 2%;\n    background-color: black;\n    height:125px;\n    display: flex;\n    color: white;\n    align-items: center;\n    justify-content: center;\n\n}\n.para{\n    width: 20%;\n    font-size: 12px;\n    line-height: 28px;\n\n}\n.language_btn{\n    font-size: 11px;\n    background-color: transparent;\n    color: white;\n    border: none;\n    margin-bottom: 5em;\n}\n.language-icon{\n  color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Footer_fullPage": `Footer_Footer_fullPage__84usb`,
	"para": `Footer_para__MUSCD`,
	"language_btn": `Footer_language_btn__hEbWn`,
	"language-icon": `Footer_language-icon__5CSGP`
};
export default ___CSS_LOADER_EXPORT___;
