// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StudentUpdateForm_container__h8DNQ {
    max-width: 400px;
    margin: 0 auto;
}

.StudentUpdateForm_form__gsylg {
    display: flex;
    flex-direction: column;
}

.StudentUpdateForm_formGroup__Z2qHk {
    margin-bottom: 1rem;
}

.StudentUpdateForm_input__d8Tqi {
    width: 100%;
    padding: 0.5rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.StudentUpdateForm_submitBtn__VnQzv {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.StudentUpdateForm_submitBtn__VnQzv:hover {
    background-color: #0056b3;
}
`, "",{"version":3,"sources":["webpack://./src/VideoCourse_AdminPanel/studentFrom/StudentUpdateForm.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,eAAe;IACf,eAAe;IACf,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,oBAAoB;IACpB,eAAe;IACf,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".container {\n    max-width: 400px;\n    margin: 0 auto;\n}\n\n.form {\n    display: flex;\n    flex-direction: column;\n}\n\n.formGroup {\n    margin-bottom: 1rem;\n}\n\n.input {\n    width: 100%;\n    padding: 0.5rem;\n    font-size: 1rem;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n}\n\n.submitBtn {\n    padding: 0.5rem 1rem;\n    font-size: 1rem;\n    background-color: #007bff;\n    color: #fff;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n}\n\n.submitBtn:hover {\n    background-color: #0056b3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `StudentUpdateForm_container__h8DNQ`,
	"form": `StudentUpdateForm_form__gsylg`,
	"formGroup": `StudentUpdateForm_formGroup__Z2qHk`,
	"input": `StudentUpdateForm_input__d8Tqi`,
	"submitBtn": `StudentUpdateForm_submitBtn__VnQzv`
};
export default ___CSS_LOADER_EXPORT___;
