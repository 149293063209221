// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AddCourseChapters_container__UJdvm {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  padding: 20px;
  margin: auto;
}
.AddCourseChapters_heading__12cUd {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
  font-size: 26px;
  font-weight: 800;
  text-align: center;
  margin: 33px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  background-color: #ffff0066;
  padding: 10px;
  /* font-family: cursive; */
}

.AddCourseChapters_pdfUpload__ljpyH,
.AddCourseChapters_addChapter__E\\+jz8,
.AddCourseChapters_addTopic__lY2nj {
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

@media (max-width: 1024px) {
  .AddCourseChapters_container__UJdvm {
    grid-template-columns: 1fr 1fr;
  }

  .AddCourseChapters_addTopic__lY2nj {
    grid-column: 1 / -1;
  }
}

@media (max-width: 768px) {
  .AddCourseChapters_container__UJdvm {
    grid-template-columns: 1fr;
  }
}
`, "",{"version":3,"sources":["webpack://./src/VideoCourse_AdminPanel/AddCourseChapters.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,qCAAqC;EACrC,SAAS;EACT,aAAa;EACb,YAAY;AACd;AACA;EACE,kBAAkB;EAClB,eAAe;EACf,mBAAmB;EACnB,eAAe;EACf,gBAAgB;EAChB,kBAAkB;EAClB,YAAY;EACZ,mFAAmF;EACnF,2BAA2B;EAC3B,aAAa;EACb,0BAA0B;AAC5B;;AAEA;;;EAGE,sBAAsB;EACtB,aAAa;EACb,kBAAkB;EAClB,uBAAuB;EACvB,wCAAwC;AAC1C;;AAEA;EACE;IACE,8BAA8B;EAChC;;EAEA;IACE,mBAAmB;EACrB;AACF;;AAEA;EACE;IACE,0BAA0B;EAC5B;AACF","sourcesContent":[".container {\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  gap: 20px;\n  padding: 20px;\n  margin: auto;\n}\n.heading {\n  text-align: center;\n  font-size: 24px;\n  margin-bottom: 20px;\n  font-size: 26px;\n  font-weight: 800;\n  text-align: center;\n  margin: 33px;\n  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;\n  background-color: #ffff0066;\n  padding: 10px;\n  /* font-family: cursive; */\n}\n\n.pdfUpload,\n.addChapter,\n.addTopic {\n  border: 1px solid #ccc;\n  padding: 20px;\n  border-radius: 8px;\n  background-color: white;\n  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);\n}\n\n@media (max-width: 1024px) {\n  .container {\n    grid-template-columns: 1fr 1fr;\n  }\n\n  .addTopic {\n    grid-column: 1 / -1;\n  }\n}\n\n@media (max-width: 768px) {\n  .container {\n    grid-template-columns: 1fr;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `AddCourseChapters_container__UJdvm`,
	"heading": `AddCourseChapters_heading__12cUd`,
	"pdfUpload": `AddCourseChapters_pdfUpload__ljpyH`,
	"addChapter": `AddCourseChapters_addChapter__E+jz8`,
	"addTopic": `AddCourseChapters_addTopic__lY2nj`
};
export default ___CSS_LOADER_EXPORT___;
