import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./Row3intro.css";
import { FaRegThumbsUp } from "react-icons/fa";
import { MdArrowForwardIos } from "react-icons/md";
import { useCookies } from "react-cookie";
import Swal from "sweetalert2";
import { useAuth } from "../../Context/AuthContext";

function Row3intro({
  currentOriginalPrice,
  currentDiscountedPrice,
  selectedCardData,
}) {
  let rating = 5;
  let totalStars = 5;
  const [StarData, setStarData] = useState([]);
  const [totalReviews, setTotalReviews] = useState(0);
  const { cardId } = useParams();
  const [cookies] = useCookies(["token", "email_id"]);
  const { isLoggedIn, user } = useAuth();
  const [isAddingToCart, setIsAddingToCart] = useState(false);

  useEffect(() => {
    const fetchReviews = async (cardId) => {
      try {
        const response = await fetch(
          `https://auth.ssccglpinnacle.com/api/reviews-ebook/${cardId}`
        );
        if (!response.ok) {
          throw new Error(
            `Failed to fetch reviews. Status: ${response.status}`
          );
        }
        const data = await response.json();
        setStarData(data.ratings);

        const reviewsLength = data.ratings ? data.ratings.length : 0;
        setTotalReviews(reviewsLength);
      } catch (error) {
        console.error("Error fetching reviews:", error);
      }
    };

    fetchReviews(cardId);
  }, [cardId]);

  const handleAddToCart = async (ebookId) => {
    try {
      if (!isLoggedIn || !user || !user._id) {
        Swal.fire({
          icon: "info", // You can change this to 'info', 'success', 'warning', etc.
          title: "Note",
          text: "Please log in to add items to your cart.",
        });
        return;
      }

      setIsAddingToCart(true);

      const response = await fetch(`https://auth.ssccglpinnacle.com/api/add-to-cart-ebook/${user.email_id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${cookies.token}`
        },
        body: JSON.stringify({ ebookId }),
      });

      if (response.ok) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Item added to cart successfully!",
        });
      } else {
        const errorMessage = await response.json();
        console.error("Error adding to cart:", errorMessage);

        Swal.fire({
          icon: "error",
          title: "Error",
          text: `Failed to add item to cart. Error: ${errorMessage.error}`,
        });
      }
    } catch (error) {
      console.error("Error adding to cart:", error);
      Swal.fire({
        icon: "info", // You can change this to 'info', 'success', 'warning', etc.
        title: "Note",
        text: "Failed to add item to cart. Please try again.",
      });
    } finally {
      setIsAddingToCart(false);
    }
  };

  const calculateStarClass = (starIndex) => {
    if (starIndex + 1 <= rating) {
      return "filled";
    } else if (starIndex < rating) {
      return "partially-filled";
    } else {
      return "";
    }
  };

  return (
    <>
      <div className="for-intro-row3-image-introproduct">
      <h2 className="enrolled">
      Explore Pinnacle's Diverse Learning Platforms
    </h2>
        <div className="valid-rating-grid">
          <div className="valid-rating-grid-left">
          {StarData.filter((review) => review.starRate === 5).slice(0, 2).map((review, index) => (
              <div key={index} className="review-box-for-intro">
                <div className="author-name-and-stars-rating">
                  <div className="star-rating-intro">
                    {[...Array(totalStars)].map((_, starIndex) => (
                      <span
                        key={starIndex}
                        className={`abc ${calculateStarClass(starIndex)}`}
                      >
                        ★
                      </span>
                    ))}
                  </div>
                  <p className="author-name-rating">
                    Author's name: {review.firstName} {review.lastName}
                  </p>
                  <p>{`"${review.writtenReview}"`}</p>
                </div>
              </div>
            ))}
          </div>
          <div className="valid-rating-grid-left-2">
          <div className="the-course-you-want">
              <h3 className="want-courses">
              Benefits of Using Latest Pinnacle Ebooks' for 2024 Exam:
              </h3>
              <div className="want-courses-para">
                <MdArrowForwardIos />
                <p>Aligned with the Latest Exam Pattern</p>
              </div>
              <div className="want-courses-para">
                <MdArrowForwardIos />
                <p>Comprehensive Coverage</p>
              </div>
              <div className="want-courses-para">
                <MdArrowForwardIos />
                <p>Well-Organized Content</p>
              </div>
              <div className="want-courses-para">
                <MdArrowForwardIos />
                <p>
                Inclusion of Previous Years’ Papers
                </p>
              </div>
              <div className="want-courses-para">
                <MdArrowForwardIos />
                <p>
                Detailed Solutions and Explanations
                </p>
              </div>
              <div className="want-courses-para">
                <MdArrowForwardIos />
                <p>
                Tips and Tricks for Exam Strategy
                </p>
              </div>
              <div className="want-courses-para">
                <MdArrowForwardIos />
                <p>
                Reputation for Quality
                </p>
              </div>
            </div>

            <div className="the-course-you-want">
              <div className="course-title-intro-4">
                <h3>
                  {selectedCardData.length > 0 && selectedCardData[0].title}{" "}
                  {selectedCardData.length > 0 && selectedCardData[0].edition}{" "}
                  edition{" "}
                  {selectedCardData.length > 0 && selectedCardData[0].medium}{" "}
                  medium{" "}
                </h3>
              </div>

              <div className="star-rating-product">
                {[...Array(totalStars)].map((_, index) => (
                  <span
                    key={index}
                    className={`abc ${calculateStarClass(index)}`}
                  >
                    ★
                  </span>
                ))}
              </div>
              <div className="cta-button-button">
                <button className="cta-button">
                  {" "}
                  <p className="original">₹{currentOriginalPrice}</p>
                  <p className="discounted" onClick={() => handleAddToCart(cardId)}>
                    ₹{currentDiscountedPrice} Enroll now
                  </p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Row3intro;
