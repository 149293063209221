import React from 'react';
import "./HomePage.css";
import { useNavigate } from 'react-router-dom';
import Header from "../../../component/DigitalcontentHeader/Header";
import Sidebar from "../../../component/SidebarDigital/Sidebar";
import SidebarHandle from "../../../component/SidebarDigital/SidebarHandle";
import { useEffect } from 'react';

function HomePage() {
     const navigate = useNavigate();
      const handleClick2 = () => {
      navigate('/quiz');
  };
    useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
    <Header/>
  <Sidebar  />
    <div className="quiz-body">
     <div className = "home-row2">
  </div>
    <h1>HTML Quiz</h1>
    <div className="w3-clear nextprev">
<a className="w3-left w3-btn" href="">❮ Previous</a>
<a className="w3-right w3-btn" href="">Next ❯</a>
</div>
<hr></hr>
<p className="intro">You can test your HTML skills with Quiz.</p>
  <hr></hr>
  <h2 className = "test-for-quiz">The Test</h2>
  <div className ="content">
        <p className = "para1">The test contains 40 questions and there is no time
limit.&nbsp;</p>
        <p>
          Test your knowledge of HTML with our interactive quiz. Answer multiple-choice questions to
          assess your understanding of HTML elements, attributes, and more.
        </p>
        <h2>Count Your Score</h2>
        <p>You will get 1 point for
each correct answer. At the end of the Quiz, your total score will be displayed.
Maximum score is 40 points.</p>
        
        <div className = "btn-for-quiz">
        <h2>Start the Quiz</h2>
        <p> Good luck!</p>
        <div onClick={handleClick2} className="start-button">
          Start Quiz
        </div>
        </div>
        </div>
         <div className="pinn-note pinn-panel">
  <p>If you don't know HTML, we suggest that you read our HTML Tutorial from scratch.</p>
</div>
  
  
  <div className="w3-clear-button nextprev-test">

<h5>track progress</h5>

</div>
      </div>

    </>
  );
}

export default HomePage;
