import React from "react";
import "./ProgressBar.css";

const ProgressBar = (props) => {
  const { bgcolor, completed } = props;

  const containerStyles = {
    height: 10,
    width: "100%",
    backgroundColor: "white",
    borderRadius: 50,
    border: "1px solid black",
  };

  const fillerStyles = {
    height: "100%",
    width: `${completed}%`,
    backgroundColor: bgcolor,
    borderRadius: 50, // You can set it to "inherit" if needed
    transition: "width 0.2s", // Optional: Add a smooth transition
  };



  return (
      <>
      <div className = "for-show-percent"><span className ="S">{`${completed}%`}</span></div>
    <div style={containerStyles}>
      <div style={fillerStyles}>
        
      </div>
    </div>
    </>
  );
};

export default ProgressBar;
