import React, { useState, useRef } from "react";
import { CSSTransition } from "react-transition-group";
import { IoCloseSharp, IoMenuSharp } from "react-icons/io5";
import { AiFillHome } from "react-icons/ai";
import { RiCheckFill } from "react-icons/ri"; // Import the tick mark icon
import "./Sidebarquiz.css";
import "./Exercise.css";
import { useNavigate } from 'react-router-dom';

const Sidebar = ({
  subjects,
  currentSubject,
  handleSubjectClick,
  completedExercises,
  totalExercisesCount,
  correctAnswersCount
}) => {
  const [activeSubjectId, setActiveSubjectId] = useState(null);
  const [sidebarVisible, setSidebarVisible] = useState(true);
  const dropdownRef = useRef(null);

  const toggleExercises = (subjectId) => {
    setActiveSubjectId((prevSubjectId) =>
      prevSubjectId === subjectId ? null : subjectId
    );
  };
   const navigate = useNavigate();
      const handleClick5 = () => {
      navigate('/digitalcontent');
  };
  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  return (
    <>
      <div className="sidebar-body">
        <div className="start">
          {sidebarVisible && 
          <>
         <div className="home-icon-exercise" onClick={handleClick5}><AiFillHome/></div>
         <div className ="calculate">Completed {correctAnswersCount} of {totalExercisesCount} exercises</div>
           </>
          }
          <div className="sidebar-toggle" onClick={toggleSidebar}>
           
            {sidebarVisible ? (
              <IoCloseSharp className="menu-icon" />
            ) : (
              <IoMenuSharp className="menu-icon" />
            )}
          </div>
        </div>
        <div className={`sidebarquiz ${sidebarVisible ? "" : "hidden"}`}>
          <ul className="ul-exercise">
            {subjects.map((subject) => (
              <li className="li-exercise" key={subject.id}>
                <button onClick={() => toggleExercises(subject.id)}>
                  {subject.name}
                </button>
                <CSSTransition
                  in={activeSubjectId === subject.id}
                  timeout={300}
                  classNames="exercise-dropdown"
                  unmountOnExit
                  nodeRef={dropdownRef}
                >
                  <ul className="exercise-list" ref={dropdownRef}>
                    {subject.exercises.map((exercise) => (
                      <li key={exercise.id}>
                        <button
                          className={
                            completedExercises.includes(exercise.id)
                              ? "completed"
                              : ""
                          }
                          onClick={() =>
                            handleSubjectClick(subject.id, exercise.id)
                          }
                        >
                          <span className="exercise-title">
                            Exercise {exercise.id}
                          </span>
                          {completedExercises.includes(exercise.id) && (
                            <span className="tick-mark green">
                              <RiCheckFill />
                            </span>
                          )}
                        </button>
                      </li>
                    ))}
                  </ul>
                </CSSTransition>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
