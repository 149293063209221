import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import Swal from 'sweetalert2';
import styles from './Category.module.css';

const CategoryForm = () => {
  const initialCategoryData = {
    categoryTitle: '',
    categoryDescription: '',
    categoryFullForm: '',
    categoryImage: null, 
    categoryOrder: 1,   
  };

  const [categoryData, setCategoryData] = useState(initialCategoryData);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false); 
  const [editingCategoryId, setEditingCategoryId] = useState(null);
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setCategoryData({ ...categoryData, [name]: value });
  };
  const fetchCategories = async () => {
    try {
      const response = await axios.get('https://auth.ssccglpinnacle.com/categories');
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: (acceptedFiles) => setCategoryData({ ...categoryData, categoryImage: acceptedFiles[0] }),
  });
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();
    formData.append('categoryTitle', categoryData.categoryTitle);
    formData.append('categoryDescription', categoryData.categoryDescription);
    formData.append('categoryFullForm', categoryData.categoryFullForm);
    if (isEditing) {
      formData.append('categoryOrder', categoryData.categoryOrder);
    }

    if (categoryData.categoryImage) {
      formData.append('categoryImage', categoryData.categoryImage, categoryData.categoryImage.name);
    }

    try {
      if (isEditing) {
        await axios.put(`https://auth.ssccglpinnacle.com/${editingCategoryId}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        Swal.fire('Success', 'Category updated successfully!', 'success');
      } else {
        await axios.post('https://auth.ssccglpinnacle.com/categories', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        Swal.fire('Success', 'Category added successfully!', 'success');
      }
      fetchCategories();
      setCategoryData(initialCategoryData); 
      setIsEditing(false); 
      setEditingCategoryId(null); 
    } catch (error) {
      console.error('Error adding/updating category:', error);
      Swal.fire('Error', 'Failed to save category', 'error');
    } finally {
      setLoading(false);
    }
  };
  const handleDelete = async (categoryId) => {
    try {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      });

      if (result.isConfirmed) {
        await axios.delete(`https://auth.ssccglpinnacle.com/categories/${categoryId}`);
        Swal.fire('Deleted!', 'Category has been deleted.', 'success');
        fetchCategories(); // Refetch categories
      }
    } catch (error) {
      console.error('Error deleting category:', error);
      Swal.fire('Error', 'Failed to delete category', 'error');
    }
  };

  // Handle category edit (For simplicity, reload the form with current category data)
  const handleEdit = (category) => {
    setCategoryData({
      categoryTitle: category.categoryTitle,
      categoryDescription: category.categoryDescription,
      categoryFullForm: category.categoryFullForm,
      categoryImage: null, // For simplicity, let user upload new image
      categoryOrder: category.categoryOrder, // Add the category order
    });
    setIsEditing(true); // Set the form to edit mode
    setEditingCategoryId(category._id); // Store the category ID being edited
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  return (
    <>
      <h2 className={styles.heading}>{isEditing ? 'Update Category' : 'Add Category'}</h2>
      <div className={styles.container}>
        <form className={styles.formContainer} onSubmit={handleSubmit}>
          <label>
            Category Title:
            <input
              type="text"
              name="categoryTitle"
              value={categoryData.categoryTitle}
              onChange={handleChange}
              required
            />
          </label>

          <label>
            Category Description:
            <textarea
              name="categoryDescription"
              value={categoryData.categoryDescription}
              className={styles["vc-category-textarea"]}
              onChange={handleChange}
              required
            />
          </label>

          <label>
            Category Full Form:
            <input
              type="text"
              name="categoryFullForm"
              value={categoryData.categoryFullForm}
              onChange={handleChange}
            />
          </label>

          {isEditing && (
            <label>
              Category Order:
              <input
                type="number"
                name="categoryOrder"
                value={categoryData.categoryOrder}
                onChange={handleChange}
                required
              />
            </label>
          )}

          <label>Category Image:</label>
          <div {...getRootProps()} className={styles.dropzone}>
            <input {...getInputProps()} />
            <p>Drag 'n' drop an image, or click to select one</p>
          </div>
          {categoryData.categoryImage && (
            <img
              className={styles.previewImage}
              src={URL.createObjectURL(categoryData.categoryImage)}
              alt="Category"
              style={{ width: '200px', marginTop: '10px' }}
            />
          )}

          <button type="submit" className={styles.submitButton} disabled={loading}>
            {loading ? 'Uploading...' : isEditing ? 'Update' : 'Submit'}
          </button>
        </form>

        <div className={styles.tableContainer}>
          <h2>Categories</h2>
          <table>
            <thead>
              <tr>
                <th>Serial Number</th>
                <th>Title</th>
                <th>Description</th>
                <th>Full Form</th>
                <th>Order</th>
                <th>Image</th>
                <th>Actions</th>
                <th>Timestamp</th>
              </tr>
            </thead>
            <tbody>
              {categories.map((category, index) => (
                <tr key={category._id}>
                  <td>{index + 1}</td>
                  <td>{category.categoryTitle}</td>
                  <td>{category.categoryDescription}</td>
                  <td>{category.categoryFullForm}</td>
                  <td>{category.categoryOrder}</td>
                  <td>
                    {category.categoryImage ? (
                      <img
                        src={category.categoryImage}
                        alt={category.categoryTitle}
                        style={{ width: '50px', height: '50px' }}
                      />
                    ) : (
                      'No image'
                    )}
                  </td>
                  <td>
                    <button onClick={() => handleEdit(category)} className={styles["edit-btn"]}>Edit</button>
                    <button onClick={() => handleDelete(category._id)} className={styles["delete-btn"]}>Delete</button>
                  </td>
                  <td>{new Date(category.created_at).toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default CategoryForm;



// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { useDropzone } from 'react-dropzone';
// import Swal from 'sweetalert2'; // Import SweetAlert
// import styles from './Category.module.css';

// const CategoryForm = () => {
//   const initialCategoryData = {
//     categoryTitle: '',
//     categoryDescription: '',
//     categoryFullForm: '',
//     categoryImage: null, // For file upload
//   };

//   const [categoryData, setCategoryData] = useState(initialCategoryData);
//   const [categories, setCategories] = useState([]);
//   const [loading, setLoading] = useState(false);

//   // Function to handle input changes
//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setCategoryData({ ...categoryData, [name]: value });
//   };

//   // Fetch categories
//   const fetchCategories = async () => {
//     try {
//       const response = await axios.get('http://localhost:5000/categories');
//       setCategories(response.data);
//     } catch (error) {
//       console.error('Error fetching categories:', error);
//     }
//   };

//   // Dropzone for handling image upload
//   const { getRootProps, getInputProps } = useDropzone({
//     accept: 'image/*',
//     onDrop: (acceptedFiles) => setCategoryData({ ...categoryData, categoryImage: acceptedFiles[0] }),
//   });

//   // Handle form submission
//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setLoading(true);

//     const formData = new FormData();
//     formData.append('categoryTitle', categoryData.categoryTitle);
//     formData.append('categoryDescription', categoryData.categoryDescription);
//     formData.append('categoryFullForm', categoryData.categoryFullForm);
//     if (categoryData.categoryImage) {
//       formData.append('categoryImage', categoryData.categoryImage, categoryData.categoryImage.name);
//     }

//     try {
//       await axios.post('http://localhost:5000/categories', formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//         },
//       });
//       Swal.fire('Success', 'Category added successfully!', 'success');
//       fetchCategories(); // Refetch categories
//       setCategoryData(initialCategoryData); // Reset form
//     } catch (error) {
//       console.error('Error adding category:', error);
//       Swal.fire('Error', 'Failed to add category', 'error');
//     } finally {
//       setLoading(false);
//     }
//   };

//   // Handle category deletion
//   const handleDelete = async (categoryId) => {
//     try {
//       const result = await Swal.fire({
//         title: 'Are you sure?',
//         text: "You won't be able to revert this!",
//         icon: 'warning',
//         showCancelButton: true,
//         confirmButtonColor: '#3085d6',
//         cancelButtonColor: '#d33',
//         confirmButtonText: 'Yes, delete it!',
//       });

//       if (result.isConfirmed) {
//         await axios.delete(`http://localhost:5000/categories/${categoryId}`);
//         Swal.fire('Deleted!', 'Category has been deleted.', 'success');
//         fetchCategories(); // Refetch categories
//       }
//     } catch (error) {
//       console.error('Error deleting category:', error);
//       Swal.fire('Error', 'Failed to delete category', 'error');
//     }
//   };

//   // Handle category edit (For simplicity, reload the form with current category data)
//   const handleEdit = (category) => {
//     setCategoryData({
//       categoryTitle: category.categoryTitle,
//       categoryDescription: category.categoryDescription,
//       categoryFullForm: category.categoryFullForm,
//       categoryImage: null, // For simplicity, let user upload new image
//     });
//   };

//   useEffect(() => {
//     fetchCategories();
//   }, []);

//   return (
//     <>
//       <h2 className={styles.heading}>Add Category</h2>
//       <div className={styles.container}>
//         <form className={styles.formContainer} onSubmit={handleSubmit}>
//           <label>
//             Category Title:
//             <input
//               type="text"
//               name="categoryTitle"
//               value={categoryData.categoryTitle}
//               onChange={handleChange}
//               required
//             />
//           </label>

//           <label>
//             Category Description:
//             <textarea
//               name="categoryDescription"
//               value={categoryData.categoryDescription}
//               onChange={handleChange}
//               required
//             />
//           </label>

//           <label>
//             Category Full Form:
//             <input
//               type="text"
//               name="categoryFullForm"
//               value={categoryData.categoryFullForm}
//               onChange={handleChange}
//             />
//           </label>

//           <label>Category Image:</label>
//           <div {...getRootProps()} className={styles.dropzone}>
//             <input {...getInputProps()} />
//             <p>Drag 'n' drop an image, or click to select one</p>
//           </div>
//           {categoryData.categoryImage && (
//             <img
//               className={styles.previewImage}
//               src={URL.createObjectURL(categoryData.categoryImage)}
//               alt="Category"
//               style={{ width: '200px', marginTop: '10px' }}
//             />
//           )}

//           <button type="submit" className={styles.submitButton} disabled={loading}>
//             {loading ? 'Uploading...' : 'Submit'}
//           </button>
//         </form>

//         <div className={styles.tableContainer}>
//           <h2>Categories</h2>
//           <table>
//             <thead>
//               <tr>
//                 <th>Serial Number</th>
//                 <th>Title</th>
//                 <th>Description</th>
//                 <th>Full Form</th>
//                 <th>Actions</th>
//                 <th>Timestamp</th>
//               </tr>
//             </thead>
//             <tbody>
//               {categories.map((category, index) => (
//                 <tr key={category._id}>
//                   <td>{index + 1}</td>
//                   <td>{category.categoryTitle}</td>
//                   <td>{category.categoryDescription}</td>
//                   <td>{category.categoryFullForm}</td>
//                   <td>
//                     <button onClick={() => handleEdit(category)}>Edit</button>
//                     <button onClick={() => handleDelete(category._id)}>Delete</button>
//                   </td>
//                   <td>{new Date(category.created_at).toLocaleString()}</td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </div>
//       </div>
//     </>
//   );
// };

// export default CategoryForm;



// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import styles from './Category.module.css';

// const CategoryForm = () => {
//   const [categoryTitle, setCategoryTitle] = useState('');
//   const [categories, setCategories] = useState([]);
//   const [loading, setLoading] = useState(false);

//   const isValidDate = (dateString) => {
//     const date = new Date(dateString);
//     return !isNaN(date.getTime());
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     try {
//       setLoading(true);

//       await axios.post('https://auth.ssccglpinnacle.com/categories', {
//         categoryTitle,
//       });

//       fetchCategories();
//       setCategoryTitle('');
//     } catch (error) {
//       console.error('Error adding category:', error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const fetchCategories = async () => {
//     try {
//       const response = await axios.get('https://auth.ssccglpinnacle.com/categories');
//       setCategories(response.data);
//     } catch (error) {
//       console.error('Error fetching categories:', error);
//     }
//   };

//   useEffect(() => {
//     fetchCategories();
//   }, []);

//   return (
//     <>
//     <h2 className={styles.heading}>Add Category</h2>
//      <div className={styles.container}>
//       <form className={styles.formContainer} onSubmit={handleSubmit}>
//         <label>
//           Category Title :
//           <input
//             type="text"
//             value={categoryTitle}
//             onChange={(e) => setCategoryTitle(e.target.value)}
//             required
//           />
//         </label>
//         <br />
//         <button type="submit" className={styles.submitButton} disabled={loading}>
//           {loading ? 'Uploading...' : 'Submit'}
//         </button>
//       </form>

//       <div className={styles.tableContainer}>
//         <h2>Categories</h2>
//         <table>
//           <thead>
//             <tr>
//               <th>Serial Number</th>
//               <th>Title</th>
//               <th>Timestamp</th>
//             </tr>
//           </thead>
//           <tbody>
//             {categories.map((category, index) => (
//               <tr key={category._id}>
//                 <td>{index + 1}</td>
//                 <td>{category.categoryTitle}</td>
//                 <td>{new Date(category.created_at).toLocaleString()}</td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>
//     </div>
//     </>
   
//   );
// };

// export default CategoryForm;
