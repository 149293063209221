// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AddChapterForm_formContainer__euLZG {
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 20px;
  /* width: 90%; */
  /* max-width: 600px; */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: #f5f5f5;
}

.AddChapterForm_formTitle__PRUJ3 {
  font-size: 24px;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

.AddChapterForm_formContainer__euLZG label {
  margin: 10px 0 5px;
  color: #555;
}

.AddChapterForm_formContainer__euLZG input[type="text"],
.AddChapterForm_formContainer__euLZG select {
  width: 100%;
  padding: 8px;
  margin: 5px 0 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.AddChapterForm_formContainer__euLZG button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.AddChapterForm_formContainer__euLZG button:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .AddChapterForm_formContainer__euLZG {
    width: 100%;
    box-shadow: none;
  }

  .AddChapterForm_formTitle__PRUJ3 {
    font-size: 20px;
  }

  .AddChapterForm_formContainer__euLZG label {
    margin-top: 15px;
  }

  .AddChapterForm_formContainer__euLZG button {
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/VideoCourse_AdminPanel/AddChapterForm.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,aAAa;EACb,gBAAgB;EAChB,sBAAsB;EACtB,uCAAuC;EACvC,kBAAkB;EAClB,yBAAyB;AAC3B;;AAEA;EACE,eAAe;EACf,WAAW;EACX,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,WAAW;AACb;;AAEA;;EAEE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,sCAAsC;AACxC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE;IACE,WAAW;IACX,gBAAgB;EAClB;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,WAAW;EACb;AACF","sourcesContent":[".formContainer {\n  display: flex;\n  flex-direction: column;\n  margin: auto;\n  padding: 20px;\n  /* width: 90%; */\n  /* max-width: 600px; */\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n  border-radius: 8px;\n  background-color: #f5f5f5;\n}\n\n.formTitle {\n  font-size: 24px;\n  color: #333;\n  text-align: center;\n  margin-bottom: 20px;\n}\n\n.formContainer label {\n  margin: 10px 0 5px;\n  color: #555;\n}\n\n.formContainer input[type=\"text\"],\n.formContainer select {\n  width: 100%;\n  padding: 8px;\n  margin: 5px 0 20px;\n  border: 1px solid #ccc;\n  border-radius: 4px;\n}\n\n.formContainer button {\n  padding: 10px 20px;\n  background-color: #007bff;\n  color: white;\n  border: none;\n  border-radius: 5px;\n  cursor: pointer;\n  transition: background-color 0.3s ease;\n}\n\n.formContainer button:hover {\n  background-color: #0056b3;\n}\n\n@media (max-width: 768px) {\n  .formContainer {\n    width: 100%;\n    box-shadow: none;\n  }\n\n  .formTitle {\n    font-size: 20px;\n  }\n\n  .formContainer label {\n    margin-top: 15px;\n  }\n\n  .formContainer button {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formContainer": `AddChapterForm_formContainer__euLZG`,
	"formTitle": `AddChapterForm_formTitle__PRUJ3`
};
export default ___CSS_LOADER_EXPORT___;
