import React, { useState, useEffect } from "react";
import classes from "./YT_Sidebar.module.css";
import Skeleton from "react-loading-skeleton";
import { MdOutlineOndemandVideo } from "react-icons/md";
import { PiFilePdfDuotone } from "react-icons/pi";

const YTSidebar = React.memo(
  ({
    onVideoSelect,
    currentVideoId,
    courseId,
    watchedVideos,
    onPdfView,
    viewedPdfs,
    setFirstVideoId,
  }) => {
    const [chapters, setChapters] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [expandedChapterIds, setExpandedChapterIds] = useState({});

    const apiKey = "YOUR_API_KEY";

    useEffect(() => {
      const fetchChapters = async () => {
        setIsLoading(true);
        try {
          const response = await fetch(
            `https://auth.ssccglpinnacle.com/api/youtubeChapters/course/${courseId}`
          );
          const initialData = await response.json();

          const updatedChapters = await Promise.all(
            initialData.map(async (chapter) => {
              // const durationsInSeconds = await Promise.all(
              //   chapter.topics.map(async (topic) => {
              //     const videoId = extractVideoID(topic.videoYoutubeLink);
              //     if (!videoId) {
              //       console.error("Invalid video ID found", topic.videoYoutubeLink);
              //       return 0;
              //     }
              // const duration = await fetchVideoDuration(videoId);
              // topic.duration = convertSecondsToReadable(
              //   convertDurationToSeconds(duration)
              // );
              // return convertDurationToSeconds(duration);
              //   })
              // );
              // chapter.totalDuration = sumDurations(durationsInSeconds);
              return chapter;
            })
          );

          setChapters(updatedChapters);

          // If chapters exist, set the first video ID
          if (
            updatedChapters.length > 0 &&
            updatedChapters[0].topics.length > 0
          ) {
            const firstVideoId = extractVideoID(
              updatedChapters[0].topics[0].videoYoutubeLink
            );
            setFirstVideoId(firstVideoId); // Set the first video ID in the parent component
            // if (!currentVideoId) {
            //   onVideoSelect(firstVideoId); // Automatically select the first video if no current video is selected
            // }
          }
        } catch (error) {
          console.error("Failed to fetch or process chapters", error);
          setError(error.message);
        }
        setIsLoading(false);
      };

      fetchChapters();
    }, [courseId]);

    useEffect(() => {
      if (currentVideoId) {
        const chapterId = findChapterByVideoId(currentVideoId);
        if (chapterId) {
          setExpandedChapterIds((prev) => ({
            ...prev,
            [chapterId]: true,
          }));
        }
      }
    }, [currentVideoId, chapters]);

    const findChapterByVideoId = (videoId) => {
      for (const chapter of chapters) {
        for (const topic of chapter.topics) {
          if (extractVideoID(topic.videoYoutubeLink) === videoId) {
            return chapter._id;
          }
        }
      }
      return null;
    };

    // const fetchVideoDuration = async (videoId) => {
    //   if (!videoId) return "Unknown duration";
    //   try {
    //     const url = `https://www.googleapis.com/youtube/v3/videos?id=${videoId}&key=${apiKey}&part=contentDetails`;
    //     const response = await fetch(url);
    //     const data = await response.json();
    //     if (data.items && data.items.length > 0) {
    //       return data.items[0].contentDetails.duration;
    //     } else {
    //       console.error("No duration data available for video ID:", videoId);
    //       return "";
    //     }
    //   } catch (error) {
    //     console.error("Failed to fetch video duration:", error);
    //     return "";
    //   }
    // };

    // const convertDurationToSeconds = (duration) => {
    //   const regex = /PT(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?/;
    //   const matches = duration.match(regex);
    //   if (!matches) {
    //     console.error("Invalid duration format:", duration);
    //     return 0;
    //   }
    //   const hours = parseInt(matches[1] || "0", 10) * 3600;
    //   const minutes = parseInt(matches[2] || "0", 10) * 60;
    //   const seconds = parseInt(matches[3] || "0", 10);
    //   return hours + minutes + seconds;
    // };

    // const sumDurations = (secondsArray) => {
    //   const totalSeconds = secondsArray.reduce(
    //     (total, seconds) => total + seconds,
    //     0
    //   );
    //   return convertSecondsToReadable(totalSeconds);
    // };

    // const convertSecondsToReadable = (totalSeconds) => {
    //   const hours = Math.floor(totalSeconds / 3600);
    //   const minutes = Math.floor((totalSeconds % 3600) / 60);
    //   const seconds = totalSeconds % 60;
    //   return `${hours > 0 ? `${hours} hr ` : ""}${
    //     minutes > 0 ? `${minutes} min ` : ""
    //   }${seconds > 0 ? `${seconds} sec` : "0 sec"}`.trim();
    // };

    // const handleSelectPdf = (pdfId) => {
    //   onPdfView(pdfId);
    //   const url = new URL(`/view-pdf/${pdfId}`, window.location.origin);
    //   window.open(url.href, "_blank");
    // };
    //to prevent video play
    const handleSelectPdf = (pdfId, event) => {
      event.stopPropagation(); // Prevents the event from propagating to parent elements
      onPdfView(pdfId);
      const url = new URL(`/classnotes/${pdfId}`, window.location.origin);
      window.open(url.href, "_blank");
    };

    const extractVideoID = (url) => {
      const regExp = /^.*(youtu.be\/|v\/|e\/|u\/\w+\/|embed\/|v=)([^#&?]*).*/;
      const match = url.match(regExp);
      return match && match[2].length === 11 ? match[2] : null;
    };

    let topicCounter = 0;

    const toggleChapter = (id) => {
      setExpandedChapterIds((prev) => ({
        ...prev,
        [id]: !prev[id],
      }));
    };

    if (isLoading) {
      return (
        <div className={classes.sidebar}>
          <h2 className={`${classes.courseHeading} ${classes.skeletonTitle}`}>
            <Skeleton width="85%" />
          </h2>
          <ul>
            {[1, 2, 3, 4, 5, 6].map((n) => (
              <li key={n} className={classes.skeletonItem} height={80}>
                <Skeleton
                  height={50}
                  width="90%"
                  padding="10px"
                  className={classes.skeletonRow}
                />
              </li>
            ))}
          </ul>
        </div>
      );
    }

    if (error) {
      return <div className={classes.error}>Error: {error}</div>;
    }

    return (
      <div className={classes.sidebar}>
        <h2 className={classes.courseHeading}>Course Content</h2>
        <div className={classes.scrollableContent}>
          {chapters.map((chapter) => {
            const watchedCount = chapter.topics.filter(
              (topic) => watchedVideos[extractVideoID(topic.videoYoutubeLink)]
            ).length;
            return (
              <div key={chapter._id}>
                <div
                  className={classes.chapterHeader}
                  onClick={() => toggleChapter(chapter._id)}
                >
                  <div className={classes.chapterRow1}>
                    <h3
                      className={`${classes.chapterTitle} ${
                        expandedChapterIds[chapter._id] ? classes.expanded : ""
                      }`}
                    >
                      {chapter.chapterTitle}
                      <span className={`${classes.dropDown}`}>
                        {expandedChapterIds[chapter._id] ? "▲" : "▼"}
                      </span>
                      <div className={classes.chapterRow2}>
                        <span>
                          Number of topics: {chapter.topics.length}
                          {/* {chapter.totalDuration} */}
                        </span>
                      </div>
                    </h3>
                  </div>
                </div>
                {expandedChapterIds[chapter._id] && (
                  <ul className={classes.chapterList}>
                    {chapter.topics.map((topic) => {
                      topicCounter++;
                      const videoId = extractVideoID(topic.videoYoutubeLink);
                      const pdfViewed = viewedPdfs[topic.selectedPdf];
                      return (
                        <li
                          key={topic._id}
                          className={`${classes.topicItem} ${
                            currentVideoId === videoId ? classes.activeLink : ""
                          }`}
                          onClick={() => {
                            const chapterId = findChapterByVideoId(videoId);
                            setExpandedChapterIds((prev) => ({
                              ...prev,
                              [chapterId]: true,
                            }));
                            onVideoSelect(videoId);
                          }}
                        >
                          <div className={classes.row1}>
                            {/* <input
                            type="checkbox"
                            checked={watchedVideos[videoId] || false}
                            readOnly
                            className={classes.checkbox}
                          /> */}
                            <MdOutlineOndemandVideo
                              className={classes.videoIcon}
                              size={25}
                            />
                            <span>
                              {topicCounter}. {topic.videoTitle}
                            </span>
                          </div>
                          <div className={classes.row2}>
                            {/* <MdOutlineOndemandVideo
                            className={classes.videoIcon}
                            size={35}
                          /> */}
                            {/* <span>{topic.duration || "Loading..."}</span> */}
                            {/* <span
                            className={`${classes.viewPdf} ${
                              pdfViewed ? classes.viewedPdf : ""
                            }`}
                            onClick={() => handleSelectPdf(topic.selectedPdf)}
                            style={{
                              backgroundColor: pdfViewed ? "#B0C4DE" : "",
                            }}
                          >
                            <PiFilePdfDuotone size={15} />
                            {pdfViewed ? "Viewed Classnotes" : "View Classnotes"}
                          </span> */}
                            <span
                              className={`${classes.viewPdf} ${
                                pdfViewed ? classes.viewedPdf : ""
                              }`}
                              onClick={(event) =>
                                handleSelectPdf(topic.selectedPdf, event)
                              }
                              style={{
                                backgroundColor: pdfViewed ? "#B0C4DE" : "",
                              }}
                            >
                              <PiFilePdfDuotone size={15} />
                              {pdfViewed
                                ? "Viewed Classnotes"
                                : "View Classnotes"}
                            </span>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
);

export default YTSidebar;
