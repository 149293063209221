import React, { useContext } from "react";
import "./ReviewFormComponent.css";
import { MdArrowBack } from "react-icons/md";
import { useLocation, useParams, Navigate, useNavigate } from "react-router-dom";
import { useAuth } from "../../Context/AuthContext";


const ReviewFormComponent = ({
  rating,
  review,
  setReview,
  handleNext,
  handleBack,
  purchasedBookId
}) => {
  const { user } = useAuth();

  const submitReview = async () => {
    try {
      const response = await fetch("https://auth.ssccglpinnacle.com/api/submit-written-review-book", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: user?._id,
          bookId: purchasedBookId,
          writtenReview: review,
        }),
      });
  
      if (!response.ok) {
        throw new Error(`Failed to submit written review. Status: ${response.status}`);
      }
  
      console.log("Written review submitted successfully");
      
      handleNext(); // Call handleNext after successful written review submission
    } catch (error) {
      console.error("Error submitting written review:", error);
    }
  };
  

  return (
    <div className="form-div">
      <div className="tell-us-more">
        <h6>Tell us more!</h6>
      </div>
      <div className="text-area-review">
        <textarea
          className="text-review"
          value={review}
          onChange={(e) => setReview(e.target.value)}
          placeholder="Share your experience"
          rows="4"
        ></textarea>
      </div>

      <div className="button-in-review-for-back">
        <button
          className="button-review-back"
          onClick={handleBack}
        >
          <MdArrowBack className="right-arrow-back" size={20} />
          Back
        </button>
        <button
          className="button-review-next"
          onClick={submitReview}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default ReviewFormComponent;
