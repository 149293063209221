// import React, { useState, useEffect } from "react";
// import { useAuth } from "../Context/AuthContext";
// import "./Intro.css";
// import { useParams, useNavigate } from "react-router-dom";
// import Row2intro from "./Row2introBook";
// import Row3intro from "./Row3introBook";
// import Row4intro from "./Row4introBook";
// import ShareModal from "./ShareModalBook";
// import { GiCheckMark } from "react-icons/gi";
// import RatingReview from "./RatingReviewBook";
// import Row7intro from "./Row7introBook";
// import Swal from "sweetalert2";
// import Footer from "../componentbook/FooterBook/FooterBook";
// import Header from "../componentbook/HeaderFilesBook/HeaderBook";
// import LoadingSpinner from "../LoadingSpinner";
// import Zoom from 'react-medium-image-zoom';
// import 'react-medium-image-zoom/dist/styles.css';
// import BillingForm from "../PagesBook/DigitalContentBody/BillingForm";

// function IntroBook({ onClose }) {
//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);
//   const [showBillingForm, setShowBillingForm] = useState(false);
//   const [loading, setLoading] = useState(true);
//   const { isLoggedIn, user } = useAuth();
//   const [selectedImage, setSelectedImage] = useState(null);
//   const [cardData, setCardData] = useState([]);
//   const [selectedCardData, setSelectedCardData] = useState([]);
//   const { cardId } = useParams();
//   const navigate = useNavigate();
//   const [billingInfo, setBillingInfo] = useState(null);

//   const calculateDiscount = (BookSellingPrice, BookPrintingPrice) => {
//     const savingAmount = BookPrintingPrice - BookSellingPrice;
//     const discountPercentage = (savingAmount / BookPrintingPrice) * 100;
//     return { savingAmount, discountPercentage };
//   };

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         setLoading(true);

//         const ebookResponse = await fetch("https://auth.ssccglpinnacle.com/api/booksforactive?active=true");
//         if (!ebookResponse.ok) {
//           throw new Error(`Failed to fetch ebooks. Status: ${ebookResponse.status}`);
//         }

//         const ebookData = await ebookResponse.json();
//         setCardData(ebookData);

//         const filteredCardData = ebookData.filter(item => item._id === cardId);
//         setSelectedCardData(filteredCardData);

//         // Set the first image as the default selected image
//         if (filteredCardData.length > 0 && filteredCardData[0].image1) {
//           setSelectedImage(filteredCardData[0].image1);
//         }

//         setLoading(false);
//       } catch (error) {
//         console.error("Error fetching data:", error);
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, [cardId]);

//   const handleBuyNow = async (bookId) => {

//     console.log(bookId)
//     if (!isLoggedIn) {
//       Swal.fire({
//         icon: "error",
//         title: "Error",
//         text: "Please log in to make a purchase.",
//       });
//       return;
//     }

//     try {
//       const response = await fetch(`https://auth.ssccglpinnacle.com/api/bookBilling/${user._id}`);
//       if (response.ok) {
//         const data = await response.json();
//         if (data) {
//           // Billing information exists, navigate to VerifyAddress component
//           navigate(`/verify-address/${bookId}`);
//         } else {
//           // Billing information does not exist, show the billing form modal
//           setShowBillingForm(true);
//           document.body.style.overflow = "hidden"; // Stop background scrolling
//         }
//       } else {
//         // Handle case where fetching billing information fails
//         console.error('Failed to fetch billing information');
//         setShowBillingForm(true);
//         document.body.style.overflow = "hidden"; // Stop background scrolling
//       }
//     } catch (error) {
//       console.error('Error fetching billing information:', error);
//       setShowBillingForm(true);
//       document.body.style.overflow = "hidden"; // Stop background scrolling
//     }
//   };

//   console.log("mydata",selectedCardData)

//   const { savingAmount, discountPercentage } = selectedCardData.length > 0 
//     ? calculateDiscount(selectedCardData[0].BookSellingPrice, selectedCardData[0].BookPrintingPrice)
//     : { savingAmount: 0, discountPercentage: 0 };

//   const currentOriginalPrice = selectedCardData.length > 0 ? selectedCardData[0].BookPrintingPrice : 0;
//   const currentDiscountedPrice = selectedCardData.length > 0 ? selectedCardData[0].BookSellingPrice : 0;

//   const handleThumbnailClick = (image) => {
//     setSelectedImage(image);
//   };

//   if (loading) {
//     return <LoadingSpinner />;
//   }


//   const closeBillingForm = () => {
//     setShowBillingForm(false);
//     document.body.style.overflow = ""; // Enable background scrolling
//   };
//   return (
//     <>
//       <div className="container-body-intro">
//         <Header />
//         <div className="container-body-for-intro">
//           <div className="for-intro-row1-image-introproduct">
//             <div className="row1-for-intro">
//               <div className="for-image-intro">
//                 <div className="image-container">
//                   <div className="image-container-book-show">
//                   {selectedImage ? (
//                     <Zoom>
//                       <img
//                         className="image-contaner-book-image"
//                         src={selectedImage}
//                         alt="Selected"
//                         style={{
//                           width: '100%', // Ensures the image uses the container width
//                           height: 'auto', // Maintains the aspect ratio
//                           maxHeight: '100%', // Prevents overflow
//                           maxWidth: '100%',
//                         }}
//                       />
//                     </Zoom>
//                   ) : (
//                     <div className="placeholder">Select an image</div>
//                   )}
//                 </div></div>
//                 <div className="thumbnails">
//                   {selectedCardData.length > 0 &&
//                     [selectedCardData[0].image1, selectedCardData[0].image2, selectedCardData[0].image3, selectedCardData[0].image4, selectedCardData[0].image5]
//                       .filter(image => image)
//                       .map((image, index) => (
//                         <img
//                           key={index}
//                           src={image}
//                           alt={`Thumbnail ${index + 1}`}
//                           className="thumbnail"
//                           onClick={() => handleThumbnailClick(image)}
//                         />
//                       ))}
//                 </div>
//               </div>
//             </div>
//             <div className="row2-for-intro">
//               <div className="name-of-the-product">
//                 <h2>
//                   {selectedCardData.length > 0 && selectedCardData[0].title}{" "}
//                   {selectedCardData.length > 0 && selectedCardData[0].edition}{" "}
//                   edition{" "}
//                   {selectedCardData.length > 0 && selectedCardData[0].medium}{" "}
//                   medium
//                 </h2>
//               </div>
//               <div className="price-details-intro">
//                 <div className="price-for-billing">
//                   <span className="price-for-billing__selling-price">
//                     ₹{selectedCardData.length > 0 && selectedCardData[0].BookSellingPrice} incl. GST
//                   </span>
//                   <div className="price-for-billing__details">
//                     <span className="price-for-billing__mrp">
//                       M.R.P.: <del>₹{selectedCardData.length > 0 && selectedCardData[0].BookPrintingPrice}</del>
//                     </span>
//                     <span className="price-for-billing__saving-amount">
//                       Save: ₹{savingAmount.toFixed(2)} ({discountPercentage.toFixed(0)}%)
//                     </span>
//                   </div>
//                 </div>
//                 <div className="Value-deal">
//                   <p>
//                     VALUE DEAL! Save &nbsp;
//                     <span className="money">₹{savingAmount.toFixed(2)}</span>
//                   </p>
//                 </div>
//                 <div className="share-product">
//                   <ShareModal />
//                 </div>
//               </div>
//               <div className="intro-of-the-product">
//                 <p className="intro-1">
//                   {selectedCardData.length > 0 && selectedCardData[0].para1}
//                 </p>
//                 <div className="intro-2">
//                   <GiCheckMark className="tick" />
//                   {selectedCardData.length > 0 && selectedCardData[0].para2}
//                 </div>
//                 <div className="intro-2">
//                   <GiCheckMark className="tick" />
//                   {selectedCardData.length > 0 && selectedCardData[0].para3}
//                 </div>
//                 <div className="intro-2">
//                   <GiCheckMark className="tick" />
//                   {selectedCardData.length > 0 && selectedCardData[0].para4}
//                 </div>
//               </div>
//               <div className="buy-it-now-from-buy-model-intro">
//   <button id="buyButton-intro" 
//     onClick={() => {
//       if (!isLoggedIn) {
//         window.location.href = 'https://testportal.ssccglpinnacle.com/login';
//       } else {
//         // Corrected the reference to the selectedCardData[0]._id
//         handleBuyNow(selectedCardData.length > 0 ? selectedCardData[0].id : null);
//       }
//     }}
//   >Buy It Now</button>
// </div>
//               <div className="show-preview-pdf-book">
//                 <div className="preview-book-pdf-title">Preview</div>
//                 <div className="show preview">
//                   <div className="pdf-showpreview">
//                     <div className="pdf1-preview">
//                       {selectedCardData.length > 0 && selectedCardData[0].pdf1 && (
//                         <a
//                           href={selectedCardData[0].pdf1}
//                           target="_blank"
//                           rel="noopener noreferrer"
//                         >
//                           Preview 1
//                         </a>
//                       )}
//                     </div>
//                     <div className="pdf2-preview">
//                       {selectedCardData.length > 0 && selectedCardData[0].pdf2 && (
//                         <a
//                           href={selectedCardData[0].pdf2}
//                           target="_blank"
//                           rel="noopener noreferrer"
//                         >
//                           Preview 2
//                         </a>
//                       )}
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <Row2intro />
//         </div>
//         <Row3intro
//           currentOriginalPrice={currentOriginalPrice}
//           currentDiscountedPrice={currentDiscountedPrice}
//           selectedCardData={selectedCardData}
//         />
//         <Row4intro />
//         <RatingReview />
//         <Row7intro />
//         <Footer />
//       </div>
//       {showBillingForm && <BillingForm onClose={closeBillingForm} />}
//     </>
//   );
// }

// export default IntroBook;

import React, { useState, useEffect } from "react";
import { useAuth } from "../Context/AuthContext";
import "./Introo.css";
import { useParams, useNavigate } from "react-router-dom";
import Row2intro from "./Row2introBook";
import Row3intro from "./Row3introBook";
import Row4intro from "./Row4introBook";
import ShareModal from "./ShareModalBook";
import { GiCheckMark } from "react-icons/gi";
import RatingReview from "./RatingReviewBook";
import Row7intro from "./Row7introBook";
import Swal from "sweetalert2";
import Footer from "../componentbook/FooterBook/FooterBook";
import Header from "../componentbook/HeaderFilesBook/HeaderBook";
import LoadingSpinner from "../LoadingSpinner";
import ReactImageMagnify from 'react-image-magnify';
import BillingForm from "../PagesBook/DigitalContentBody/BillingForm";

function IntroBook({ onClose }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const [showBillingForm, setShowBillingForm] = useState(false);
  const [loading, setLoading] = useState(true);
  const { isLoggedIn, user } = useAuth();
  const [selectedImage, setSelectedImage] = useState(null);
  const [cardData, setCardData] = useState([]);
  const [selectedCardData, setSelectedCardData] = useState([]);
  const { cardId } = useParams();
  const navigate = useNavigate();
  const [billingInfo, setBillingInfo] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const ebookResponse = await fetch("https://auth.ssccglpinnacle.com/api/booksforactive?active=true");
        if (!ebookResponse.ok) {
          throw new Error(`Failed to fetch ebooks. Status: ${ebookResponse.status}`);
        }

        const ebookData = await ebookResponse.json();
        setCardData(ebookData);

        const filteredCardData = ebookData.filter(item => item._id === cardId);
        setSelectedCardData(filteredCardData);

        // Set the first image as the default selected image
        if (filteredCardData.length > 0 && filteredCardData[0].image1) {
          setSelectedImage(filteredCardData[0].image1);
        }

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [cardId]);

  const calculateDiscount = (BookSellingPrice, BookPrintingPrice) => {
    const savingAmount = BookPrintingPrice - BookSellingPrice;
    const discountPercentage = (savingAmount / BookPrintingPrice) * 100;
    return { savingAmount, discountPercentage };
  };

  const handleBuyNow = async (bookId) => {
    console.log(bookId)
    if (!isLoggedIn) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please log in to make a purchase.",
      });
      return;
    }

    try {
      const response = await fetch(`https://auth.ssccglpinnacle.com/api/bookBilling/${user._id}`);
      if (response.ok) {
        const data = await response.json();
        if (data) {
          // Billing information exists, navigate to VerifyAddress component
          navigate(`/verify-address/${bookId}`);
        } else {
          // Billing information does not exist, show the billing form modal
          setShowBillingForm(true);
          document.body.style.overflow = "hidden"; // Stop background scrolling
        }
      } else {
        // Handle case where fetching billing information fails
        console.error('Failed to fetch billing information');
        setShowBillingForm(true);
        document.body.style.overflow = "hidden"; // Stop background scrolling
      }
    } catch (error) {
      console.error('Error fetching billing information:', error);
      setShowBillingForm(true);
      document.body.style.overflow = "hidden"; // Stop background scrolling
    }
  };

  const { savingAmount, discountPercentage } = selectedCardData.length > 0 
    ? calculateDiscount(selectedCardData[0].BookSellingPrice, selectedCardData[0].BookPrintingPrice)
    : { savingAmount: 0, discountPercentage: 0 };

  const currentOriginalPrice = selectedCardData.length > 0 ? selectedCardData[0].BookPrintingPrice : 0;
  const currentDiscountedPrice = selectedCardData.length > 0 ? selectedCardData[0].BookSellingPrice : 0;

  const handleThumbnailClick = (image) => {
    setSelectedImage(image);
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  const closeBillingForm = () => {
    setShowBillingForm(false);
    document.body.style.overflow = ""; // Enable background scrolling
  };

  return (
    <>
      <div className="container-body-intro">
        <Header />
        <div className="container-body-for-intro">
          <div className="for-intro-row1-image-introproduct">
            <div className="row1-for-intro">
              <div className="for-image-intro">
                <div className="image-container">
                  <div className="image-container-book-show">
                    {selectedImage ? (
                      <ReactImageMagnify {...{
                        smallImage: {
                          alt: 'Selected',
                          isFluidWidth: true,
                          src: selectedImage
                        },
                        largeImage: {
                          src: selectedImage,
                          width: 1200,
                          height: 1800
                        },
                        enlargedImageContainerDimensions: {
                          width: '250%',
                          height: '200%'
                        }
                      }} />
                    ) : (
                      <div className="placeholder">Select an image</div>
                    )}
                  </div>
                </div>
                <div className="thumbnails">
                  {selectedCardData.length > 0 &&
                    [selectedCardData[0].image1, selectedCardData[0].image2, selectedCardData[0].image3, selectedCardData[0].image4, selectedCardData[0].image5]
                      .filter(image => image)
                      .map((image, index) => (
                        <img
                          key={index}
                          src={image}
                          alt={`Thumbnail ${index + 1}`}
                          className="thumbnail"
                          onClick={() => handleThumbnailClick(image)}
                        />
                      ))}
                </div>
              </div>
            </div>
            <div className="row2-for-intro">
              <div className="name-of-the-product">
                <h2>
                  {selectedCardData.length > 0 && selectedCardData[0].title}{" "}
                  {/* {selectedCardData.length > 0 && selectedCardData[0].edition}{" "}
                  edition{" "}
                  {selectedCardData.length > 0 && selectedCardData[0].medium}{" "}
                  medium */}
                </h2>
              </div>
              <div className="price-details-intro">
                <div className="price-for-billing">
                  <span className="price-for-billing__selling-price">
                    ₹{selectedCardData.length > 0 && selectedCardData[0].BookSellingPrice} incl. GST
                  </span>
                  <div className="price-for-billing__details">
                    <span className="price-for-billing__mrp">
                      M.R.P.: <del>₹{selectedCardData.length > 0 && selectedCardData[0].BookPrintingPrice}</del>
                    </span>
                    <span className="price-for-billing__saving-amount">
                      Save: ₹{savingAmount.toFixed(2)} ({discountPercentage.toFixed(0)}%)
                    </span>
                  </div>
                </div>
                <div className="Value-deal">
                  <p>
                    VALUE DEAL! Save &nbsp;
                    <span className="money">₹{savingAmount.toFixed(2)}</span>
                  </p>
                </div>
                <div className="share-product">
                  <ShareModal />
                </div>
              </div>
              <div className="Add-ISBN">
              <p className="intro-1-isbn">ISBN: {selectedCardData.length > 0 && selectedCardData[0].ISBN}

              </p>
              <p className="intro-1-isbn">
              Pages: {selectedCardData.length > 0 && selectedCardData[0].pages}
              </p>
              </div>
              <div className="intro-of-the-product">
                <p className="intro-1">
                  {selectedCardData.length > 0 && selectedCardData[0].para1}
                </p>
                <div className="intro-2">
                  <GiCheckMark className="tick" />
                  {selectedCardData.length > 0 && selectedCardData[0].para2}
                </div>
                <div className="intro-2">
                  <GiCheckMark className="tick" />
                  {selectedCardData.length > 0 && selectedCardData[0].para3}
                </div>
                <div className="intro-2">
                  <GiCheckMark className="tick" />
                  {selectedCardData.length > 0 && selectedCardData[0].para4}
                </div>
              </div>
              <div className="buy-it-now-from-buy-model-intro">
                <button id="buyButton-intro" 
                  onClick={() => {
                    if (!isLoggedIn) {
                      window.location.href = 'https://testportal.ssccglpinnacle.com/login';
                    } else {
                      handleBuyNow(selectedCardData.length > 0 ? selectedCardData[0].id : null);
                    }
                  }}
                >
                  Buy It Now
                </button>
              </div>
              <div className="show-preview-pdf-book">
                <div className="preview-book-pdf-title">Preview</div>
                <div className="show preview">
                  <div className="pdf-showpreview">
                    <div className="pdf1-preview">
                      {selectedCardData.length > 0 && selectedCardData[0].pdf1 && (
                        <a
                          href={selectedCardData[0].pdf1}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Preview 1
                        </a>
                      )}
                    </div>
                    <div className="pdf2-preview">
                      {selectedCardData.length > 0 && selectedCardData[0].pdf2 && (
                        <a
                          href={selectedCardData[0].pdf2}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Preview 2
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Row2intro />
        </div>
        <Row3intro
          currentOriginalPrice={currentOriginalPrice}
          currentDiscountedPrice={currentDiscountedPrice}
          selectedCardData={selectedCardData}
        />
        <Row4intro />
        <RatingReview />
        <Row7intro />
        <Footer />
      </div>
      {showBillingForm && <BillingForm onClose={closeBillingForm} />}
    </>
  );
}

export default IntroBook;







