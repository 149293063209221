import React from "react";
import styles from "./Details.module.css";
import { MdLanguage } from "react-icons/md";
import { BsStarHalf } from "react-icons/bs";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";

function Details({
  courseTitle,
  courseDetails,
  teacherName,
  rating,
  totalRatingStudent,
}) {
  const getStars = () => {
    // Ensure rating is a valid number between 0 and 5
    const safeRating = Math.max(0, Math.min(rating || 0, 5));
    const fullStars = Math.floor(safeRating);
    const halfStars = safeRating % 1 !== 0 ? 1 : 0;
    const emptyStars = 5 - fullStars - halfStars;

    return (
      <>
        {[...Array(fullStars)].map((_, index) => (
          <AiFillStar key={index} className={styles.ratingStar} size={18} />
        ))}
        {halfStars === 1 && (
          <BsStarHalf className={styles.ratingStar} size={18} />
        )}
        {[...Array(emptyStars)].map((_, index) => (
          <AiOutlineStar key={index} className={styles.ratingStar} size={18} />
        ))}
      </>
    );
  };

  return (
    <div className={styles["Details-fullpage"]}>
      <div>
        <div className={styles["heading"]}>{courseTitle}</div>
        <div className={styles["details_description"]}>{courseDetails}</div>
        <div className={styles["created"]}>
          Created by: <span>Pinnacle civil services</span>
        </div>
        <div className={styles["bestSeler-div"]}>
          <div>
            <button className={styles["bestSeler-btn"]}>Best Seller</button>
          </div>
          <div className={styles["rate"]}>
            {getStars()}&nbsp;&nbsp;{rating.toFixed(1)} ratings
            <span className={styles["Students"]}>
              &nbsp;&nbsp;{totalRatingStudent} reviews
            </span>
          </div>
        </div>
        <div className={styles["language-caption-div"]}>
          <div className={styles["language-icon"]}>
            <MdLanguage size={30} />
          </div>
          <div>Hinglish</div>
        </div>
      </div>
    </div>
  );
}

export default Details;


// import React from "react";
// import styles from "./Details.module.css";
// import { MdLanguage } from "react-icons/md";
// import { BsStarHalf } from "react-icons/bs";
// import { AiFillStar, AiOutlineStar } from "react-icons/ai";

// function Details({
//   courseTitle,
//   courseDetails,
//   teacherName,
//   rating,
//   totalRatingStudent,
// }) {
//   const getStars = () => {
//     // Ensure rating is a valid number between 0 and 5
//     const safeRating = Math.max(0, Math.min(rating || 0, 5));
//     const fullStars = Math.floor(safeRating);
//     const halfStars = safeRating % 1 !== 0 ? 1 : 0;
//     const emptyStars = 5 - fullStars - halfStars;

//     return (
//       <>
//         {[...Array(fullStars)].map((_, index) => (
//           <AiFillStar key={index} className={styles.ratingStar} size={18} />
//         ))}
//         {halfStars === 1 && (
//           <BsStarHalf className={styles.ratingStar} size={18} />
//         )}
//         {[...Array(emptyStars)].map((_, index) => (
//           <AiOutlineStar key={index} className={styles.ratingStar} size={18} />
//         ))}
//       </>
//     );
//   };

//   return (
//     <div className={styles["Details-fullpage"]}>
//       <div>
//         <div className={styles["heading"]}>{courseTitle}</div>
//         <div className={styles["details_description"]}>{courseDetails}</div>
//         <div className={styles["created"]}>
//           Created by: <span>Pinnacle civil services</span>
//         </div>
//         <div className={styles["bestSeler-div"]}>
//           <div>
//             <button className={styles["bestSeler-btn"]}>Best Seller</button>
//           </div>
//           <div className={styles["rate"]}>
//             {getStars()}&nbsp;&nbsp;{rating} ratings
//             <span className={styles["Students"]}>
//               {" "}
//               &nbsp;&nbsp;{totalRatingStudent} reviews
//             </span>
//           </div>
//         </div>
//         <div className={styles["language-caption-div"]}>
//           <div className={styles["language-icon"]}>
//             <MdLanguage size={30} />
//           </div>
//           <div>Hinglish</div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Details;
