import React, { useState, useEffect } from "react";
import { useAuth } from "../Context/AuthContext";
import { useNavigate } from "react-router-dom";
import Header from "../componentbook/HeaderFilesBook/HeaderBook";
import "./DigitalContentBody/AllEbooks/AllEbook.css";
import "./PurchaseBook.css";
import LoadingSpinner from "../Ebook/LoadingSpinner";

function PurchaseBook() {
  const [purchaseHistory, setPurchaseHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const { isLoggedIn, user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPurchasedBooks = async () => {
      try {
        if (isLoggedIn && user && user.email_id) {
          const response = await fetch(
            `https://auth.ssccglpinnacle.com/api/purchased-books/${user.email_id}`
          );
          if (!response.ok) {
            throw new Error(`Failed to fetch purchased books. Status: ${response.status}`);
          }

          const purchasedBooks = await response.json();
          setPurchaseHistory(purchasedBooks);
        }
      } catch (error) {
        console.error("Error fetching purchased books:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPurchasedBooks();
  }, [isLoggedIn, user]);

  const handleBookClick = (bookId) => {
    navigate(`/intro-book/${bookId}`);
  };

  if (loading) {
    return <div><LoadingSpinner/></div>;
  }

  return (
    <>
      <Header />
      <h2 className="purchase-heading-books">
        Your purchased books{user?.full_name ? `, ${user.full_name}` : ""}
      </h2>
      <div className="container-digital-view-all-link-boks">
        {purchaseHistory.length === 0 ? (
          <div className="no-purchases-container">
            <h3 className="no-purchases-heading">You don't have any purchased books yet.</h3>
            <p className="no-purchases-text">Browse our collection and find your next great read!</p>
            <button 
              className="browse-books-button" 
              onClick={() => navigate("/books-catalog")}
            >
              Browse Books
            </button>
          </div>
        ) : (
          <ul className="cards-digital-view-all-link">
            {purchaseHistory.map((purchase) => {
              const { bookDetails } = purchase;
              if (!bookDetails) return null;

              return (
                <li className="card-digital-view-all-link" key={purchase._id}>
                  <div className="upper-card-digital-view-all-link" onClick={() => handleBookClick(bookDetails.id)}>
                    <div className="card-content-digital-view-all-link">
                      <div className="for-image-size-view-all-link">
                        <div className="for-giving-margin"></div>
                        <img
                          className="card-title-digital-view-all-link"
                          src={bookDetails.image1}
                          alt={bookDetails.title}
                        />
                      </div>
                    </div>

                    <div className="card-link-wrapper-digital-view-all-link">
                      <p>{bookDetails.title} </p>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </>
  );
}

export default PurchaseBook;
