// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SignUpPage_signup-fullpage__bTQm2 {
    max-width: 400px;
    margin: auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
  }
  
  .SignUpPage_signup-heading__9y0Pc {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .SignUpPage_email-div__gVzWh {
    margin-bottom: 15px;
  }
  
  .SignUpPage_email-heading__ObohR {
    font-size: 14px;
    margin-bottom: 8px;
  }
  
  .SignUpPage_email-inputbox__hhiUO {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
  
  .SignUpPage_signup-btn__Fp-bf {
    background-color:#020062;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .SignUpPage_signup-btn__Fp-bf:hover {
    background-color: #020062;
  }
  
  .SignUpPage_tandc-para__zha7z,
  .SignUpPage_login-para__P\\+Mfy {
    font-size: 12px;
    margin-top: 15px;
  }
  
  `, "",{"version":3,"sources":["webpack://./src/component/HomePage01/SignUpPage/SignUpPage.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,sBAAsB;EACxB;;EAEA;IACE,eAAe;IACf,iBAAiB;IACjB,mBAAmB;EACrB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,kBAAkB;EACpB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,wBAAwB;IACxB,WAAW;IACX,aAAa;IACb,YAAY;IACZ,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;;IAEE,eAAe;IACf,gBAAgB;EAClB","sourcesContent":[".signup-fullpage {\n    max-width: 400px;\n    margin: auto;\n    padding: 20px;\n    border: 1px solid #ccc;\n    border-radius: 5px;\n    background-color: #fff;\n  }\n  \n  .signup-heading {\n    font-size: 18px;\n    font-weight: bold;\n    margin-bottom: 20px;\n  }\n  \n  .email-div {\n    margin-bottom: 15px;\n  }\n  \n  .email-heading {\n    font-size: 14px;\n    margin-bottom: 8px;\n  }\n  \n  .email-inputbox {\n    width: 100%;\n    padding: 8px;\n    border: 1px solid #ccc;\n    border-radius: 3px;\n  }\n  \n  .signup-btn {\n    background-color:#020062;\n    color: #fff;\n    padding: 10px;\n    border: none;\n    border-radius: 3px;\n    cursor: pointer;\n  }\n  \n  .signup-btn:hover {\n    background-color: #020062;\n  }\n  \n  .tandc-para,\n  .login-para {\n    font-size: 12px;\n    margin-top: 15px;\n  }\n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"signup-fullpage": `SignUpPage_signup-fullpage__bTQm2`,
	"signup-heading": `SignUpPage_signup-heading__9y0Pc`,
	"email-div": `SignUpPage_email-div__gVzWh`,
	"email-heading": `SignUpPage_email-heading__ObohR`,
	"email-inputbox": `SignUpPage_email-inputbox__hhiUO`,
	"signup-btn": `SignUpPage_signup-btn__Fp-bf`,
	"tandc-para": `SignUpPage_tandc-para__zha7z`,
	"login-para": `SignUpPage_login-para__P+Mfy`
};
export default ___CSS_LOADER_EXPORT___;
