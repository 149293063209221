import React, { useRef, useEffect, useState } from 'react';
import styles from './sscCategory.module.css';
import { AiFillLeftCircle, AiFillRightCircle} from 'react-icons/ai';
import gsap from 'gsap';
import { useNavigate } from 'react-router-dom';
import NavbarVC from './NavBarVC';
import {TbPoint} from "react-icons/tb";
import { FaAnglesDown,FaAnglesUp } from "react-icons/fa6";

export default function UpPoliceCategory() {
  const navigate = useNavigate();
  const [selectedCategory, setSelectedCategory] = useState('UP Police');
  const [course, setCourse] = useState([]);
  const scrollRef = useRef(null);
  const courseRef = useRef(null);

  useEffect(() => {
    fetch("https://auth.ssccglpinnacle.com/api/videoCourses/UP POLICE")
      .then(response => response.json())
      .then(data => {
        setCourse(Array.isArray(data) ? data : []);
      })
      .catch(error => console.error("Error fetching courses:", error));
  }, []);

  const scroll = (shift) => {
    const targetScroll = scrollRef.current.scrollLeft + shift;
    gsap.to(scrollRef.current, { scrollLeft: targetScroll, duration: 0.6, ease: 'power2.inOut' });
  };
  const scrollToCourses = () => {
    courseRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const scrollToBottom = () => {
    window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
  };

  const categoryHandler = (category) => {
    setSelectedCategory(category);
  };

  return (
    <>
      <NavbarVC />
      <div className={styles["EM-header"]}>
        <div className={styles["EM-header-container01"]}>
          <img src='https://d3m4h509gttb94.cloudfront.net/Image/UpPoliceLogo1.png' alt="UP Police Logo" className={styles["EM-image"]} />
        </div>
        <div className={styles["EM-header-container02"]}>
          <div className={styles["EM-header-heading"]}>UP Police</div>
          <button onClick={scrollToCourses} className={styles["allCourses-scrollButton"]}>
              Show All UP Police Courses
            </button>
            <button onClick={scrollToBottom} className={styles["allCourses-scrollButton"]}>
             < FaAnglesDown /> 
            </button>
          <div className={styles["EM-header-para"]}>
          The UP Police Constable Exam is a recruitment examination conducted by the Uttar Pradesh Police Recruitment and Promotion Board (UPPRPB) to select eligible candidates for the position of Constable in the Uttar Pradesh Police Department. This exam is highly competitive and attracts thousands of aspirants from across the state, aiming to join the police force and serve the community. Here is an overview of the UP Police Constable Exam:
          </div>
        </div>
      </div>

      <div className={styles["EM-body"]}>
        <div className={styles["EM-body-container1"]}>
          {['UP Police'].map(cat => (
            <div key={cat} className={styles["EM-body-buttons"]}>
              <button
                className={styles["EM-header-indi-butBtn"]}
                onClick={() => categoryHandler(cat)}
              >
                {cat.toUpperCase()}
              </button>
            </div>
          ))}
        </div>
        <div className={styles["EM-body-container2"]}>

          {/* RRB NTPC */}
          
          {selectedCategory === 'UP Police' && 
          
          <div className={styles.upPoliceContainer}>
            <div className={styles["EM-header-heading"]}>UP Police Constable Exam Overview</div>
          
          <section className={styles.upPoliceSection}>
            <div className={styles["EM-header-heading"]}>Purpose of the Exam</div>
            <div className={styles["EM-header-para"]}>
              The UP Police Constable Exam is designed to assess the suitability of candidates for the role of constable in the Uttar Pradesh Police. The exam evaluates a candidate's knowledge, skills, physical fitness, and mental aptitude required for policing duties.
            </div>
          </section>
    
          <section className={styles.upPoliceSection}>
            <div className={styles["EM-header-heading"]}>Eligibility Criteria</div>
            <ul className={styles.upPoliceList}>
              <li>Educational Qualification: Candidates must have passed the 12th standard (Intermediate) or equivalent examination from a recognized board.</li>
              <li>Age Limit: For male candidates in the unreserved category, the age limit is 18 to 25 years, and for female candidates, it is 18 to 28 years. Age relaxation is provided for candidates from reserved categories (SC/ST/OBC).</li>
              <li>Nationality: Only Indian citizens are eligible to apply.</li>
            </ul>
          </section>
    
          <section className={styles.upPoliceSection}>
            <div className={styles["EM-header-heading"]}>Selection Process</div>
            <ul className={styles.upPoliceList}>
              <li>Written Examination: An objective-type exam that tests candidates on subjects such as General Hindi, General Knowledge, Numerical & Mental Ability, and Mental Aptitude/Intelligence/Reasoning.</li>
              <li>Physical Measurement Test (PMT): Candidates must meet specific physical standards related to height, chest measurement, and weight as per their category and gender.</li>
              <li>Physical Efficiency Test (PET): This test assesses the physical endurance and fitness of candidates through activities such as running, long jump, and other physical tasks.</li>
              <li>Document Verification: Verification of all essential documents such as educational certificates, identity proof, caste certificates (if applicable), etc.</li>
              <li>Medical Examination: A thorough medical examination ensures candidates meet the health and fitness standards required for the role.</li>
            </ul>
          </section>
    
          <section className={styles.upPoliceSection}>
            <div className={styles["EM-header-heading"]}>Exam Pattern</div>
            <ul className={styles.upPoliceList}>
              <li>Mode of Exam: The written exam is conducted offline (OMR-based).</li>
              <li>Type of Questions: The exam consists of multiple-choice questions (MCQs).</li>
              <li>Number of Questions: The exam has 150 questions.</li>
              <li>Total Marks: The exam is scored out of 300 marks.</li>
              <li>Negative Marking: There is a penalty of ½ mark for each incorrect answer.</li>
              <li>Duration: The total duration of the exam is 2 hours.</li>
            </ul>
          </section>
    
          <section className={styles.upPoliceSection}>
            <div className={styles["EM-header-heading"]}>Syllabus</div>
            <ul className={styles.upPoliceList}>
              <li>General Hindi: Questions to test proficiency in the Hindi language, including grammar, vocabulary, and comprehension.</li>
              <li>General Knowledge: Topics related to Indian history, geography, polity, economy, current affairs, and UP-specific information such as culture, administration, and police system.</li>
              <li>Numerical & Mental Ability: Covers arithmetic topics such as number systems, simplification, fractions, ratios, percentages, profit and loss, etc.</li>
              <li>Mental Aptitude/Intelligence/Reasoning: Tests logical reasoning, analytical skills, decision-making, problem-solving abilities, and mental toughness.</li>
            </ul>
          </section>
    
          <section className={styles.upPoliceSection}>
            <div className={styles["EM-header-heading"]}>Preparation Tips</div>
            <ul className={styles.upPoliceList}>
              <li>Understand the exam pattern and syllabus thoroughly.</li>
              <li>Regularly practice previous years' question papers and mock tests to improve time management and identify weak areas.</li>
              <li>Focus on building physical fitness to clear the PET standards.</li>
              <li>Stay updated with current affairs and regularly revise key topics in general knowledge and numerical ability.</li>
            </ul>
          </section>
    
          <section className={styles.upPoliceSection}>
            <div className={styles["EM-header-heading"]}>Admit Card and Result</div>
            <ul className={styles.upPoliceList}>
              <li>Admit Card: Issued online by UPPRPB, the admit card contains details about the exam center, date, and time. Candidates must carry a printed copy of the admit card and a valid ID proof to the exam center.</li>
              <li>Result: Results are declared on the official website after the examination. Successful candidates proceed to the next stages of the selection process.</li>
            </ul>
          </section>
    
          <section className={styles.upPoliceSection}>
            <div className={styles["EM-header-heading"]}>Career Prospects</div>
            <ul className={styles.upPoliceList}>
              <li>Selected candidates are appointed as constables in the Uttar Pradesh Police and can expect a starting salary in accordance with the 7th Pay Commission, along with various allowances.</li>
              <li>Career growth opportunities include promotions to higher ranks such as Head Constable, Assistant Sub-Inspector, Sub-Inspector, and Inspector, based on experience, performance, and additional qualifications.</li>
            </ul>
          </section>
        </div>
          }
          {/* RRB JE */}
          {selectedCategory === 'RRB JE' && <div>
            <div className={styles["EM-header-heading"]}>POSTS UNDER RRB JE </div>
            <div className={styles["EM-header-para"]}>1.Junior Engineer (JE)</div>
            <div className={styles["EM-header-para"]}>2.Junior Engineer (IT)</div>
            <div className={styles["EM-header-para"]}>3.Depot Materials Superintendent (DMS)</div>
            <div className={styles["EM-header-para"]}>4.Chemical & Metallurgical Assistant (CMA)</div>
            <div className={styles["EM-header-heading"]}>ELIGIBILITY CRITERIA FOR RRB JE EXAM </div>
            <div className={styles["EM-header-para"]}><TbPoint/>Minimum age required is 18.</div>
            <div className={styles["EM-header-para"]}><TbPoint/>You must be an engineering student.</div>
            <div className={styles["EM-header-para"]}><TbPoint/>You have to clear the medical examination conducted by RRB.</div>
            <div className={styles["EM-table"]}>
              <table>
                <thead>
                  <tr>
                    <th>RRB JE SYLLABUS</th>
                  </tr>

                </thead>
                <tbody>
                  <tr className={styles}>
                    <td>RRB CBT1</td>
                    <tr><td> &nbsp;&nbsp;MATHEMATICS&nbsp;&nbsp;</td></tr>
                    <tr><td>&nbsp;&nbsp;GENERAL SCIENCE &nbsp;&nbsp;</td></tr>
                    <tr><td>&nbsp;&nbsp;REASONING &nbsp;&nbsp;</td></tr>
                    <tr><td>&nbsp;&nbsp;GENERAL AWARENESS &nbsp;&nbsp;</td></tr>
                  </tr>
                 <hr/>
                 <tr className={styles}>
                    <td>RRB CBT2</td>
                    <tr><td> &nbsp;&nbsp;GENERAL AWARENESS&nbsp;&nbsp;</td></tr>
                    <tr><td>&nbsp;&nbsp;BASICS OF COMPUTER &nbsp;&nbsp;</td></tr>
                    <tr><td>&nbsp;&nbsp;PHYSICS AND CHEMISTRY &nbsp;&nbsp;</td></tr>
                    <tr><td>&nbsp;&nbsp;BASICS OF ENVIRONMENTAL POLLUTION &nbsp;&nbsp;</td></tr>
                    <tr><td>&nbsp;&nbsp;TECHNICAL DISCIPLINE &nbsp;&nbsp;</td></tr>

                  </tr>

                </tbody>
                </table>
              
            </div>
            </div>
          }

         {/* RPF Constable */}
          {selectedCategory === 'RPF Constable' && <div className={styles["RPF-section"]}>RRB RPF SECTION
          <div className={styles["EM-header-heading"]}>ELIGIBILITY CRITERIA FOR RRB RPF EXAM</div>
          <div>
            <table>
              <thead>
                <tr>
                  <th>Post</th>
                  <th>Educational Qualification</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>RPF Constable (Executive)</th>
                  <td>SSLC/Matric from a recognized Board.</td>
                </tr>
                <tr>
                  <th>RPF Sub Inspector (Executive)</th>
                  <td>Graduate from a recognized University</td>
                </tr>
              </tbody>
            </table>
          </div>
          </div>}
          
          {selectedCategory === 'sten' && <div>SSC Stenographers content here</div>}
          {selectedCategory === 'cpo' && <div>SSC CPO related content here</div>}
          {selectedCategory === 'je' && <div>SSC JE related content here</div>}
          {selectedCategory === 'jht' && <div>SSC JHT related content here</div>}
        </div>
      </div>




      <div className={styles["mostPopular-div"]}>
        <button onClick={() => scroll(-270)} className={styles["sliderButton-left"]}>
          <AiFillLeftCircle size={30} />
        </button>
        <div className={styles["Course_Heading"]}>All UP Police Courses</div>
        <div className={styles["scroll-container"]} ref={scrollRef}>
        <div className={styles["card-wrapper"]} ref={courseRef}>
          <div className={styles["card-wrapper"]}>
            {course.map(data => (
              <div key={data.id} className={styles["card"]} onClick={() => navigate("/CourseDescription/" + data._id)}>
                <div className={styles["Upcomming-course-fullPage"]}>
                  <div className={styles["Image-section"]}>
                    <img className={styles.imagecard}
                      src={data.hindiCoverImage}
                      alt=""
                      width="90"
                      height="90"
                    />
                    <img className={styles.imagecard}
                      src={data.englishCoverImage}
                      alt=""
                      width="90"
                      height="90"
                    />
                  </div>
                  <div className={styles["description-section"]}>
                    <div className={styles["title"]}>{data.courseTitle}</div>
                    <div className={styles["teacher"]}>Mr./Ms. {data.instructorName}</div>
                    <div className={styles["try-for-free"]}>
                      <div className={styles["Rating"]}>
                        {/* <div className={styles["Star-Rating"]}>
                          {data.rating}
                          <div className={styles["For-Star-div"]}>
                            <AiFillStar
                              className={styles["Star-Rating-1"]}
                              color="white"
                              fontSize="1em"
                            />
                          </div>
                        </div> */}
                        {/* <div className={styles["Total-Rating"]}>(128)</div> */}
                      </div>
                    </div>
                    <div className={styles["price-fprice-div"]}>
                      &#8377;{data.price}&nbsp;&nbsp;&nbsp;&nbsp;<s>&#8377;{data.mrp}</s>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          </div>
        </div>
      </div>
      <div className={styles["scroll-buttons"]}><button onClick={scrollToTop} className={styles["allCourses-scrollButton"]}><FaAnglesUp/></button></div>

    </>
  );
}