// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Privacy_PrivacySettings-fullPage__Ck5D4 {
  width: 65rem;
  height: 20rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  margin-left: 3%;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  margin-top: 9%;
}
.Privacy_PrivacySettings-checkbox1__BMdoa {
  display: flex;
  height: 4rem;
  align-items: center;
  gap: 1rem;
  padding-left: 2%;
}
.Privacy_checkbox-ps__0mtnz {
  color: white;
  background-color: #020062;
}
.Privacy_PrivacySettings-checkbox2__DZjmU {
  display: flex;
  height: 4rem;
  align-items: center;
  gap: 1rem;
  padding-left: 2%;
}
.Privacy_save-btn__z7asI {
  width: 10%;
  height: 2.5rem;
  border: none;
  font-size: 14px;
  font-weight: bold;
  background-color: #020062;
  color: white;
  margin: 2%;
}
`, "",{"version":3,"sources":["webpack://./src/VIDEOPLAYER COMPONENTS/components/VideoPlayerPage/AccountPage/Privacy.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb;0CACwC;EACxC,eAAe;EACf;0CACwC;EACxC,cAAc;AAChB;AACA;EACE,aAAa;EACb,YAAY;EACZ,mBAAmB;EACnB,SAAS;EACT,gBAAgB;AAClB;AACA;EACE,YAAY;EACZ,yBAAyB;AAC3B;AACA;EACE,aAAa;EACb,YAAY;EACZ,mBAAmB;EACnB,SAAS;EACT,gBAAgB;AAClB;AACA;EACE,UAAU;EACV,cAAc;EACd,YAAY;EACZ,eAAe;EACf,iBAAiB;EACjB,yBAAyB;EACzB,YAAY;EACZ,UAAU;AACZ","sourcesContent":[".PrivacySettings-fullPage {\n  width: 65rem;\n  height: 20rem;\n  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,\n    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;\n  margin-left: 3%;\n  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,\n    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;\n  margin-top: 9%;\n}\n.PrivacySettings-checkbox1 {\n  display: flex;\n  height: 4rem;\n  align-items: center;\n  gap: 1rem;\n  padding-left: 2%;\n}\n.checkbox-ps {\n  color: white;\n  background-color: #020062;\n}\n.PrivacySettings-checkbox2 {\n  display: flex;\n  height: 4rem;\n  align-items: center;\n  gap: 1rem;\n  padding-left: 2%;\n}\n.save-btn {\n  width: 10%;\n  height: 2.5rem;\n  border: none;\n  font-size: 14px;\n  font-weight: bold;\n  background-color: #020062;\n  color: white;\n  margin: 2%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PrivacySettings-fullPage": `Privacy_PrivacySettings-fullPage__Ck5D4`,
	"PrivacySettings-checkbox1": `Privacy_PrivacySettings-checkbox1__BMdoa`,
	"checkbox-ps": `Privacy_checkbox-ps__0mtnz`,
	"PrivacySettings-checkbox2": `Privacy_PrivacySettings-checkbox2__DZjmU`,
	"save-btn": `Privacy_save-btn__z7asI`
};
export default ___CSS_LOADER_EXPORT___;
