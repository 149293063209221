// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Announcements_announce_container__NhYHO {
  margin-top: 2%;
  width: 70%;
  margin-left: 3%;
}
.Announcements_ins_image__fWSpM {
  width: 4rem;
}
.Announcements_instructor_image__lxjHp {
  margin-top: 5px;
  width: 3rem;
  height: 4rem;
  border-radius: 50%;
}
.Announcements_announce_section__KPOh- {
  display: flex;
}
.Announcements_heading__YN79N {
  margin-top: 2%;
  font-size: 14px;
  font-weight: 500;
}
.Announcements_heading1__SNpD0 {
  font-size: 14px;
  margin-bottom: 5%;
}
.Announcements_ins_name__8p3q4 {
  font-weight: 600;
}
.Announcements_ins__Rf9J3 {
  font-size: 14px;
}
.Announcements_ins_section_para__YEAfA {
  margin-bottom: 2%;
}
.Announcements_ins_sections__y6edi {
  font-size: 14px;
}
.Announcements_ins_details__7tILs {
  margin-top: 2%;
}
.Announcements_ins_section_para__YEAfA a {
  color: #007bff;
}
`, "",{"version":3,"sources":["webpack://./src/VIDEOPLAYER COMPONENTS/components/VideoPlayerPage/Navigations Components/Announcements/Announcements.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,UAAU;EACV,eAAe;AACjB;AACA;EACE,WAAW;AACb;AACA;EACE,eAAe;EACf,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB;AACA;EACE,aAAa;AACf;AACA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;AAClB;AACA;EACE,eAAe;EACf,iBAAiB;AACnB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,eAAe;AACjB;AACA;EACE,iBAAiB;AACnB;AACA;EACE,eAAe;AACjB;AACA;EACE,cAAc;AAChB;AACA;EACE,cAAc;AAChB","sourcesContent":[".announce_container {\n  margin-top: 2%;\n  width: 70%;\n  margin-left: 3%;\n}\n.ins_image {\n  width: 4rem;\n}\n.instructor_image {\n  margin-top: 5px;\n  width: 3rem;\n  height: 4rem;\n  border-radius: 50%;\n}\n.announce_section {\n  display: flex;\n}\n.heading {\n  margin-top: 2%;\n  font-size: 14px;\n  font-weight: 500;\n}\n.heading1 {\n  font-size: 14px;\n  margin-bottom: 5%;\n}\n.ins_name {\n  font-weight: 600;\n}\n.ins {\n  font-size: 14px;\n}\n.ins_section_para {\n  margin-bottom: 2%;\n}\n.ins_sections {\n  font-size: 14px;\n}\n.ins_details {\n  margin-top: 2%;\n}\n.ins_section_para a {\n  color: #007bff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"announce_container": `Announcements_announce_container__NhYHO`,
	"ins_image": `Announcements_ins_image__fWSpM`,
	"instructor_image": `Announcements_instructor_image__lxjHp`,
	"announce_section": `Announcements_announce_section__KPOh-`,
	"heading": `Announcements_heading__YN79N`,
	"heading1": `Announcements_heading1__SNpD0`,
	"ins_name": `Announcements_ins_name__8p3q4`,
	"ins": `Announcements_ins__Rf9J3`,
	"ins_section_para": `Announcements_ins_section_para__YEAfA`,
	"ins_sections": `Announcements_ins_sections__y6edi`,
	"ins_details": `Announcements_ins_details__7tILs`
};
export default ___CSS_LOADER_EXPORT___;
