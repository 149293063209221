import React from "react";
import "../component/Footer/Footer.css";
import Col from "react-bootstrap/Col";
import facebook from "./footerimg/facebook.svg";
import gmail from "./footerimg/gmail.svg";
import insta from "./footerimg/insta.svg";
import telegram from "./footerimg/telegram.svg";
import twitter from "./footerimg/twitter.svg";
import youtube from "./footerimg/youtube.svg";
import play from "./footerimg/play.svg";
import whatsapp from "./footerimg/whatsapp.svg";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
      <div className="site-footer">
        <div className="row">
          <Col className="footer-container" xs={6} md={3}>
            <h5>
              {/* <a href="/">About Us</a> */}
              <Link to="/aboutus">About Us</Link>
            </h5>
            <ul className="footer-links">
              <li>
            <Link to="/privacyPolicy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/footer/Terms-and-Condition">Terms &amp; Conditions</Link>
              </li>
              <li>
                <Link to="/footer/Refund-Policy">Refund Policy</Link>
              </li>
              <li>
              <Link to="/footer/Contact-Us">Contact Us</Link>
              </li>
              <li>
                <img className="gmail-img" src={gmail} />
                <a href="mailto:support@ssccglpinnacle.com">support@ssccglpinnacle.com</a>
              </li>
            </ul>
          </Col>

          <Col className="footer-container" xs={6} md={3}>
            <h5>Explore</h5>
            <ul className="footer-links">
            <li>
                <a href="https://testportal.ssccglpinnacle.com">Test Portal</a>
              </li>
            <li>
                <Link to="/typingexamselection"> Typing</Link>
              </li>
              <li>
                <Link to="/books-catalog"> Books</Link>
              </li>
              <li>
                <Link to="/videos"> Videos</Link>
              </li>
              <li>
                <Link to="/digital-catalog">E-Books</Link>
              </li>
              <li>
                <Link to="/blog">Blogs</Link>
              </li>
            </ul>
          </Col>

          <Col className="footer-container" xs={6} md={3}>
            <h5 className="connect">Connect</h5>
            <ul className="footer-links">
            <li>
                <img className="telegram-img" src={youtube} />
                <a href="https://www.youtube.com/channel/UCn3aOx3FoeRUzVhOHL5U5Mw" target="_blank" rel="noopener noreferrer">YouTube</a>
              </li>
              <li>
                <img className="telegram-img" src={telegram} />
                <a href="https://t.me/ssccglpinnacleonline" target="_blank" rel="noopener noreferrer">Telegram Channel</a>
              </li>
              <li>
                <img className="whatsapp-img" src={whatsapp} />
                <a href="https://www.whatsapp.com/channel/0029VaFZ5QTC6ZvcTFgbMe2J" target="_blank" rel="noopener noreferrer">Whatsapp</a>
              </li>
              {/* <li>
                <img className="facebook-img" src={facebook} />
                <a href="/">Facebook</a>
              </li> */}
              {/* <li>
                <img className="insta-img" src={insta} />
                <a href="/">Instagram</a>
              </li> */}
              {/* <li>
                <img className="twitter-img" src={twitter} />
                <a href="/">Twitter</a>
              </li> */}
              <li>
                <img className="play-img" src={play} />
                <a
                href="https://play.google.com/store/apps/details?id=com.ssccgl.pinnacle.testportal"
                target="_blank"
                rel="noopener noreferrer"
              >
                Download App
              </a>
              </li>
            </ul>
          </Col>
        </div>
      </div>
    </>
  );
}

export default Footer;

// import React, { useEffect } from "react";
// import { useNavigate } from "react-router";
// import { BiRightArrowAlt } from "react-icons/bi";
// import { TfiYoutube } from "react-icons/tfi";
// import { BsTelegram } from "react-icons/bs";
// import styles from "./Footer.module.css";

// const Footer = () => {
//   const navigate = useNavigate();

//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);

//   return (
//     <footer className={styles.footer}>
//       <div className={styles.footerColumns}>
//         {/* <div className={styles.footerColumn}>
//           <div className={styles.footerRow}>
//             <div className={styles["individual-div"]}>
//               <span onClick={() => navigate("/aboutus")} className={styles['aTag']}>About Us</span>
//             </div>
//           </div>
//           <div className={styles.footerRow}>
//             <div className={styles["individual-div"]}>
//               <a href="https://play.google.com/store/apps/details?id=com.ssccgl.pinnacle.testportal" target="_blank" className={styles['aTag']}>Download App</a>
//             </div>
//           </div>
//           <div className={styles.footerRow}>
//             <div className={styles["individual-div"]}>
//               <span onClick={() => navigate("/footer/Contact-Us")} className={styles['aTag']}>Contact Us</span>
//             </div>
//           </div>
//         </div> */}

//         <div className={styles.footerColumn}>
//         <div className={styles.footerRow}>
//             <div className={styles["individual-div"]}>
//               <span onClick={() => navigate("/aboutus")} className={styles['aTag']}>About Us</span>
//             </div>
//           </div>
//           <div className={styles.footerRow}>
//             <div className={styles["individual-div"]}>
//               <span onClick={() => navigate("/privacyPolicy")} className={styles['aTag']}>Privacy Policy</span>
//             </div>
//           </div>
//           <div className={styles.footerRow}>
//             <div className={styles["individual-div"]}>
//               <span onClick={() => navigate("/footer/Terms-and-Condition")} className={styles['aTag']}>Terms &amp; Conditions</span>
//             </div>
//           </div>

//           <div className={styles.footerRow}>
//             <div className={styles["individual-div"]}>
//               <span onClick={() => navigate("/footer/Refund-Policy")} className={styles['aTag']}>Refund Policy</span>
//             </div>
//           </div>
//           <div className={styles.footerRow}>
//             <div className={styles["individual-div"]}>
//               <span onClick={() => navigate("/footer/Contact-Us")} className={styles['aTag']}>Contact Us</span>
//             </div>
//           </div>
//           <div className={styles.footerRow}>
//             <div className={styles["individual-div"]}>
//               {/* <a href="https://play.google.com/store/apps/details?id=com.ssccgl.pinnacle.testportal" target="_blank" className={styles['aTag']}>Support Id</a> */}
//               <a href="mailto:support@ssccglpinnacle.com" target="_blank" className={styles['aTag']} >support@ssccglpinnacle.com</a>
//             </div>
//           </div>
//         </div>

//         <div className={styles.footerColumn}>
//           <div className={styles.footerRow}>
//             <div className={styles["individual-div"]}>
//               <a href="https://testportal.ssccglpinnacle.com/" target="_blank" className={styles['aTag']}>Test Portal</a>
//             </div>
//           </div>
//           <div className={styles.footerRow}>
//             <div className={styles["individual-div"]}>
//               <span onClick={() => navigate("/typingexamselection")} className={styles['aTag']}>Typing</span>
//             </div>
//           </div>
//           <div className={styles.footerRow}>
//             <div className={styles["individual-div"]}>
//               <span onClick={() => navigate("/books-catalog")} className={styles['aTag']}>Books</span>
//             </div>
//           </div>
//           <div className={styles.footerRow}>
//             <div className={styles["individual-div"]}>
//               <span onClick={() => navigate("/videos")} className={styles['aTag']}>Videos</span>
//             </div>
//           </div>
//           <div className={styles.footerRow}>
//             <div className={styles["individual-div"]}>
//               <span onClick={() => navigate("/digital-catalog")} className={styles['aTag']}> E-Books</span>
//             </div>
//           </div>
//           <div className={styles.footerRow}>
//             <div className={styles["individual-div"]}>
//               <span onClick={() => navigate("/blog")} className={styles['aTag']}> Blogs</span>
//             </div>
//           </div>
//         </div>

//         <div className={styles.footerColumn}>
          
//           <div className={styles.footerRow}>
//             <div className={styles["individual-div"]}>
//               <a href="https://www.youtube.com/channel/UCn3aOx3FoeRUzVhOHL5U5Mw" target="_blank" className={styles['aTag']}>YouTube Channel</a>
//             </div>
//           </div>
          
//           <div className={styles.footerRow}>
//             <div className={styles["individual-div"]}>
//               <a href="https://t.me/ssccglpinnacleonline" target="_blank" className={styles['aTag']}>Telegram Channel</a>
//             </div>
//           </div>
//           <div className={styles.footerRow}>
//             <div className={styles["individual-div"]}>
//               <a href="https://t.me/ssccglpinnacleonline" target="_blank" className={styles['aTag']}>Telegram Channel</a>
//             </div>
//           </div>
//           <div className={styles.footerRow}>
//             <div className={styles["individual-div"]}>
//               <a href="https://t.me/ssccglpinnacleonline" target="_blank" className={styles['aTag']}>Telegram Channel</a>
//             </div>
//           </div>
//         </div>
//       </div>

//       <div className={styles.footerCopyright}>
//         <BiRightArrowAlt className={styles.footerCopyrightIcon} size={14} />
//         <span>&copy; 2024 Pinnacle. All rights reserved.</span>
//       </div>
//     </footer>
//   );
// };

// export default Footer;


// import React, { useEffect } from "react";
// import styles from "./Footer.module.css";
// import { BiGlobe, BiRightArrowAlt } from "react-icons/bi";
// import { TfiYoutube } from "react-icons/tfi";
// import { BsTelegram } from "react-icons/bs";
// import { useNavigate } from "react-router";

// const Footer = () => {
//   const navigate = useNavigate();
//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);
//   return (
//     <footer className={styles.footer}>
//       <div className={styles.footerColumns}>
//         <div className={styles.footerColumn}>
//           <div
//             className={styles.footerRow}
//             // onClick={() => navigate("/aboutus")}
//           >
           
//             <div className={styles["individual-div"]}>
//             <a
//                 href="https://testportal.ssccglpinnacle.com/aboutus"
//                 target="_blank"
//                 rel="noopener noreferrer"
//                 // className={classes["aTag"]}
//               >
//                 About Us
//               </a>
//             </div>
//             {/* <div>
              // <a
              //   href="https://play.google.com/store/apps/details?id=com.ssccgl.pinnacle.testportal"
              //   target="_blank"
              //   rel="noopener noreferrer"
              //   // className={classes["aTag"]}
              // >
              //   Download App
              // </a>
//             </div> */}
//           </div>
//           <div
//             className={styles.footerRow}
//           >
//             <div className={styles["individual-div"]}><a href="https://play.google.com/store/apps/details?id=com.ssccgl.pinnacle.testportal" target="blank" className={styles['aTag']}>Download App</a></div>
//           </div>
//           <div
//             className={styles.footerRow}
//             // onClick={() => navigate("/contectus")}
//           >
//             <div className={styles["individual-div"]}><a href="https://testportal.ssccglpinnacle.com/contactus" target="blank" className={styles['aTag']}>Contact Us</a></div>
//           </div>
//         </div>

//         <div className={styles.footerColumn}>
//         <div
//   className={styles.footerRow}
//   // onClick={() => window.open("https://ssccglpinnacle.com/footer/Terms-and-Conditions", "_blank")}
// >
//   <div className={styles["individual-div"]}>
//   <a href="https://testportal.ssccglpinnacle.com/termandcondition" target="blank" className={styles['aTag']}>Terms &amp; Conditions</a>    
//   </div>
// </div>
//           <div
//             className={styles.footerRow}
//             onClick={() => navigate("/privacyPolicy")}
//           >
//             <div className={styles["individual-div"]} >
//             <a href="https://testportal.ssccglpinnacle.com/privacypolicy" target="blank" className={styles['aTag']}>Privacy Policy</a>
//             </div>
//           </div>
//           <div
//             className={styles.footerRow}
//             // onClick={() => navigate("/refundPolicy")}
//           >
//             <div className={styles["individual-div"]}>
//             <a href="https://testportal.ssccglpinnacle.com/refundPolicy" target="blank" className={styles['aTag']}>Refund Policy</a>
//             </div>
//           </div>
//         </div>

//         <div className={styles.footerColumn}>
//           <div className={styles.footerRow}>
//             <div className={styles["individual-div"]}><a href="https://ssccglpinnacle.com/books-catalog" target="blank" className={styles['aTag']}>Printed Books</a></div>
//           </div>
//           <div className={styles.footerRow}>
          
//             <div className={styles["individual-div"]}><a href="https://ssccglpinnacle.com/books-catalog" target="blank" className={styles['aTag']}>Digital Books</a></div>
//           </div>
//           <div className={styles.footerRow}>
//             <div className={styles["individual-div"]}><a href="https://testportal.ssccglpinnacle.com/" target="blank" className={styles['aTag']}>Test Portal</a></div>
//           </div>
//         </div>

//         <div className={styles.footerColumn}>
//           <TfiYoutube className={styles.youtubeicon} size={25} />
//           <div className={styles.footerRow}>
//             <div className={styles["individual-div"]}><a href="https://www.youtube.com/channel/UCn3aOx3FoeRUzVhOHL5U5Mw" target="blank" className={styles['aTag']}>YouTube Channel</a></div>
//           </div>
//           <BsTelegram className={styles.telegramicon} size={25} />
//           <div className={styles.footerRow}>
//             <div className={styles["individual-div"]}><a href="https://t.me/ssccglpinnacleonline" target="blank" className={styles['aTag']}>Telegram Channel</a></div>
//           </div>
//         </div>
//         <div className={styles.footerColumnlast}>
//           <BiGlobe className={styles.footerGlobeIcon} size={25} />
//           <div className={styles.footerLanguage}>English</div>
//         </div>
//       </div>

//       <div className={styles.footerCopyright}>
//         <BiRightArrowAlt className={styles.footerCopyrightIcon} size={14} />
//         <span>&copy; 2024 Pinnacle. All rights reserved.</span>
//       </div>
//     </footer>
//   );
// };

// export default Footer;
