import React, { useState, useEffect,useRef } from "react";

import Rate from "../../componentbook/StarBook/RateBook";
import { useAuth } from "../../Context/AuthContext";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useProductContext } from "../../Context/ProductContext";
import Swal from "sweetalert2";
import BuyNow from "../../BuyNowBook/BuyNowBook";
import "./Row3Digital.css";
import { AiOutlineHeart, AiFillHeart } from "react-icons/ai";
import amazon from "../../iBook/amazon.svg";
import flipkart from "../../iBook/flipkart.svg";
import pinnacle from "../../iBook/pinnaclelogo.png";
import { FcApproval } from "react-icons/fc";
import BillingForm from "./BillingForm";
import "./Row4Digital.css";
import { AiOutlineLeft, AiOutlineRight, AiFillStar } from "react-icons/ai";
import pic from '../../iBook/book.jpg';
import gsap from "gsap";

function Row4Digital({ filteredEbookData }) {

  const [cardData, setCardData] = useState([]);
  const { isLoggedIn, user } = useAuth();
  const [hoveredId, setHoveredId] = useState(null);
  const navigate = useNavigate();
  const [showBillingForm, setShowBillingForm] = useState(false);
  let scrl = useRef(null);
  const [scrollX, setscrollX] = useState(10);
  const [scrolEnd, setscrolEnd] = useState(false);

  const slide = (shiftMultiplier) => {
    const cardWidth = scrl.current.querySelector('.card-digital-ebook').offsetWidth; // Width of one card
    const shift = cardWidth * 5 * shiftMultiplier; // Calculate the scroll distance for 4 cards
    let targetScroll = scrl.current.scrollLeft + shift;
    
    // Calculate the maximum scrollable position
    const maxScroll = scrl.current.scrollWidth - scrl.current.offsetWidth;
    
    // Clamp the target scroll to not exceed the maximum
    if (targetScroll > maxScroll) {
      targetScroll = maxScroll;
    }
    
    if (targetScroll < 0) {
      targetScroll = 0;
    }
    
    // Apply GSAP animation to scroll to the target position smoothly
    gsap.to(scrl.current, {
      scrollLeft: targetScroll,
      duration: 0.6,
      ease: "power2.inOut",
      onComplete: () => {
        setscrollX(scrl.current.scrollLeft);
    
        // Update the state to determine if the scroll has reached the end
        if (scrl.current.scrollLeft >= maxScroll) {
          setscrolEnd(true);
        } else {
          setscrolEnd(false);
        }
      },
    });
  };
  
  
  
  
  
  const scrollCheck = () => {
    setscrollX(scrl.current.scrollLeft);
  
    // Check if the scroll has reached the end
    if (Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <= scrl.current.offsetWidth) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };

  useEffect(() => {
    const fetchEbooksData = async () => {
      try {
        const ebookResponse = await fetch(
          "https://auth.ssccglpinnacle.com/api/booksforactive?active=true"
        );
        if (!ebookResponse.ok) {
          throw new Error(
            `Failed to fetch ebooks. Status: ${ebookResponse.status}`
          );
        }
        const ebookData = await ebookResponse.json();
        if (filteredEbookData && filteredEbookData.length > 0) {
          setCardData(filteredEbookData);
        } else {
          setCardData(ebookData);
        }
      } catch (error) {
        console.error("Error fetching ebook data:", error);
      }
    };

    fetchEbooksData();
  }, [filteredEbookData]);

  const info = (cardId) => {
    navigate(`/intro-book/${cardId}`);
  };

  const getRatingForCard = (cardId) => {
    const card = cardData.find((card) => card._id === cardId);

    if (!card || !card.rating || card.rating.length === 0) {
      return 0;
    }

    return calculateAverageRating(card.rating);
  };

  const calculateAverageRating = (ratings) => {
    const totalRating = ratings.reduce(
      (sum, rating) => sum + rating.starRate,
      0
    );
    return totalRating / ratings.length;
  };

  const handleBuyNow = async (bookId) => {
    if (!isLoggedIn) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please log in to make a purchase.",
      });
      return;
    }

    try {
      const response = await fetch(
        `https://auth.ssccglpinnacle.com/api/bookBilling/${user._id}`
      );
      if (response.ok) {
        const data = await response.json();
        if (data) {
          // Billing information exists, navigate to VerifyAddress component
          navigate(`/verify-address/${bookId}`);
        } else {
          // Billing information does not exist, show the billing form modal
          setShowBillingForm(true);
          document.body.style.overflow = "hidden"; // Stop background scrolling
        }
      } else {
        // Handle case where fetching billing information fails
        console.error("Failed to fetch billing information");
        setShowBillingForm(true);
        document.body.style.overflow = "hidden"; // Stop background scrolling
      }
    } catch (error) {
      console.error("Error fetching billing information:", error);
      setShowBillingForm(true);
      document.body.style.overflow = "hidden"; // Stop background scrolling
    }
  };
  
  const calculateDiscount = (BookSellingPrice, BookPrintingPrice) => {
    const savingAmount = BookPrintingPrice - BookSellingPrice;
    const discountPercentage = (savingAmount / BookPrintingPrice) * 100;
    return { savingAmount, discountPercentage };
  };
  const closeBillingForm = () => {
    setShowBillingForm(false);
    document.body.style.overflow = ""; // Enable background scrolling
  };

  return (
    <>
      <div className="section-suggest-book">
        <div className="container-fluid-book-recent">
          <div className="foruppersectionebook">
            <div className="col-md-8 section-suggest-book-col-8">
              <p className="col-md-8 section-suggest-book-p">Most Popular Book</p>
            </div>
            <div className="col-md-4 section-suggest-book-col-4">
              <div className="book-viewall-button">
                <div className="View-all-digital-ebook"> <a href="">View All </a></div>
              </div>
            </div>
          </div>
          <div className="container-for-button">
            {scrollX !== 5 && (
              <button className="prev" onClick={() => slide(-1)}>
              <AiOutlineLeft color="black" fontSize="1.5em" />
            </button>
            )}
            <div className="container-digital-ebook">
              <ul className="cards-digital-ebook" ref={scrl} onScroll={scrollCheck}>
             
              {cardData && cardData.length > 0 ? (
  cardData.map((card) => {
    const { savingAmount, discountPercentage } = calculateDiscount(
      card.BookSellingPrice,
      card.BookPrintingPrice
    );
    return (
      <li key={card._id} className="card-digital-ebook">
        <div className="upper-card-digital">
          <div className="card-content-digital-ebook">
            <div
              className="for-image-size"
              onClick={() => info(card._id)}
            >
              <img
                className="card-title-digital-ebook"
                src={card.image1}
                alt={card.title}
              />
            </div>
          </div>

          <div className="card-link-wrapper-digital-ebook">
            {/* <p>
              {card.title} {card.medium} medium {card.edition} edition
            </p> */}
               <p>{card.title} </p>
          </div>

          <div className="buttons-for-buying-products">
            <div className="button-for-everything">
              <div className="try-fot-free-books-only">
              <div className="try-for-free">
                <div
                  className="Rating"
                  onMouseOver={() => setHoveredId(card._id)}
                  onMouseOut={() => setHoveredId(null)}
                >
                  <div className="Star-Rating-row-5">
                    {getRatingForCard(card._id).toFixed(1)}
                    <div className="For-Star-div">
                      <AiFillStar className="Star-Rating-1" />
                    </div>
                  </div>
                  <div className="Total-Rating">
                    ({card.rating.length})
                  </div>
                </div>
              </div>
              <div className="StarRating">
                {hoveredId === card._id && (
                  <Rate cardData={card} />
                )}
              </div></div>
              <div className="price-for-billing">
  <span className="price-for-billing__selling-price">
    ₹ {card.BookSellingPrice} incl. GST
  </span>
  <div className="price-for-billing__details">
    <span className="price-for-billing__mrp">
      M.R.P.: <del>₹ {card.BookPrintingPrice}</del>
    </span>
    <span className="price-for-billing__saving-amount">
      Save: ₹ {savingAmount.toFixed(2)} ({discountPercentage.toFixed(0)}%)
    </span>
  </div>
</div>
            </div>
            <div className="buttons-for-important">
              <div className="button-for-try-view-amazon-link">
                <div className="amazon-link-row3">
                  <img
                    src={amazon}
                    className="amazon-link-row3-videoicon"
                  />
                </div>
                <div className="amazon-link-button">
                  <a
                    className="buynow-for-user1-row4"
                    href={card.amazonLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Buy Now
                  </a>
                </div>
              </div>
              <div className="button-for-try-view-flipkart-link">
                <div className="flipkart-link-row3">
                  <img
                    src={flipkart}
                    className="flipkart-link-row3-videoicon"
                  />
                </div>
                <div className="amazon-link-button">
                  <a
                    className="buynow-for-user1-row4"
                    href={card.flipkartLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Buy Now
                  </a>
                </div>
              </div>
              <div className="button-for-try-view-pinnacle-link">
                <div className="pinnacle-link-row3">
                  <img
                    src={pinnacle}
                    className="pinnacle-link-row3-videoicon"
                  />
                </div>
                <button
                  className="buynow-for-user1-row4"
                  onClick={() => {
                    if (!isLoggedIn) {
                      window.location.href = 'https://testportal.ssccglpinnacle.com/login';
                    } else {
                      handleBuyNow(card.id);
                    }
                  }}
                >
                  Buy Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </li>
     );
    })
  ) : (
    <p>No data available</p> // Display a message or a placeholder when there is no data
  )}
              </ul>
            </div>
            {!scrolEnd && (
               <button className="next" onClick={() => slide(1)}>
               <AiOutlineRight color="black" fontSize="1.5em" />
             </button>
            )}
          </div>
        </div>
      </div>
      {showBillingForm && <BillingForm onClose={closeBillingForm} />}
    </>
  );
}

export default Row4Digital;
