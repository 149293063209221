// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LoginPage_login-fullpage__kLsJE {
    width: 400px;
    margin: auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    margin-top: 50px;
  }
  
  .LoginPage_login-heading__\\+PVrM {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }
  
  .LoginPage_login-form__N4JIH {
    display: flex;
    flex-direction: column;
  }
  
  .LoginPage_email-div__\\+J0kS {
    margin-bottom: 15px;
  }
  
  .LoginPage_email-heading__NG5iw {
    font-size: 1rem;
    margin-bottom: 5px;
  }
  
  .LoginPage_email-inputbox__F0\\+9m {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .LoginPage_error-message__9r2v5 {
    color: red;
    margin-top: 10px;
  }
  
  .LoginPage_login-btn__FEKsr {
    background-color: #020062;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .LoginPage_signup-para__t\\+SLZ {
    margin-top: 15px;
    font-size: 0.9rem;
  }
  
  .LoginPage_signup-para__t\\+SLZ a {
    color: #020062;
    text-decoration: none;
  }
  
  .LoginPage_signup-para__t\\+SLZ a:hover {
    text-decoration: underline;
  }
  `, "",{"version":3,"sources":["webpack://./src/component/HomePage01/LogIn/LoginPage.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,gBAAgB;EAClB;;EAEA;IACE,iBAAiB;IACjB,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,sBAAsB;EACxB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,kBAAkB;EACpB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,UAAU;IACV,gBAAgB;EAClB;;EAEA;IACE,yBAAyB;IACzB,WAAW;IACX,aAAa;IACb,YAAY;IACZ,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,gBAAgB;IAChB,iBAAiB;EACnB;;EAEA;IACE,cAAc;IACd,qBAAqB;EACvB;;EAEA;IACE,0BAA0B;EAC5B","sourcesContent":[".login-fullpage {\n    width: 400px;\n    margin: auto;\n    padding: 20px;\n    border: 1px solid #ccc;\n    border-radius: 8px;\n    margin-top: 50px;\n  }\n  \n  .login-heading {\n    font-size: 1.5rem;\n    margin-bottom: 20px;\n  }\n  \n  .login-form {\n    display: flex;\n    flex-direction: column;\n  }\n  \n  .email-div {\n    margin-bottom: 15px;\n  }\n  \n  .email-heading {\n    font-size: 1rem;\n    margin-bottom: 5px;\n  }\n  \n  .email-inputbox {\n    width: 100%;\n    padding: 8px;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n  }\n  \n  .error-message {\n    color: red;\n    margin-top: 10px;\n  }\n  \n  .login-btn {\n    background-color: #020062;\n    color: #fff;\n    padding: 10px;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n  }\n  \n  .signup-para {\n    margin-top: 15px;\n    font-size: 0.9rem;\n  }\n  \n  .signup-para a {\n    color: #020062;\n    text-decoration: none;\n  }\n  \n  .signup-para a:hover {\n    text-decoration: underline;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"login-fullpage": `LoginPage_login-fullpage__kLsJE`,
	"login-heading": `LoginPage_login-heading__+PVrM`,
	"login-form": `LoginPage_login-form__N4JIH`,
	"email-div": `LoginPage_email-div__+J0kS`,
	"email-heading": `LoginPage_email-heading__NG5iw`,
	"email-inputbox": `LoginPage_email-inputbox__F0+9m`,
	"error-message": `LoginPage_error-message__9r2v5`,
	"login-btn": `LoginPage_login-btn__FEKsr`,
	"signup-para": `LoginPage_signup-para__t+SLZ`
};
export default ___CSS_LOADER_EXPORT___;
