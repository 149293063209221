const mathQuizData = [
  {
    question: "What is 2 + 2?",
    options: ["3", "4", "5", "6"],
    answerIndex: 1
  },
  {
    question: "What is the square root of 16?",
    options: ["2", "4", "6", "8"],
    answerIndex: 1
  }
  // Add more math questions...
];

export default mathQuizData;
