// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ComposeMessages_compose_container__nLzFV {
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  padding: 1%;
  margin: 1%;
  width: 42%;
  height: 26rem;
}
.ComposeMessages_compose_heading__vC\\+So {
  font-weight: 800;
  width: 7%;
  font-size: 20px;
}
.ComposeMessages_heading_container__tv5yc {
  line-height: 2rem;
}
.ComposeMessages_option_container__whubN {
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  width: 75%;
  padding: 1%;
  margin-bottom: 2%;
}
.ComposeMessages_help_btn__RB8I\\+ {
  background-color: black;
  padding: 1.5%;
  margin-top: 3%;
}
.ComposeMessages_compose_container__nLzFV {
  position: absolute;
  top: 4%;
  left: 38%;
}
.ComposeMessages_compose_heading__vC\\+So {
  font-size: 16px;
}
.ComposeMessages_username__7l3j8 {
  margin-left: 2%;
}
.ComposeMessages_option_container1__Jw3Hw {
  font-size: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/VIDEOPLAYER COMPONENTS/components/VideoPlayerPage/AccountPage/ComposeMessages.module.css"],"names":[],"mappings":"AAAA;EACE;0CACwC;EACxC,WAAW;EACX,UAAU;EACV,UAAU;EACV,aAAa;AACf;AACA;EACE,gBAAgB;EAChB,SAAS;EACT,eAAe;AACjB;AACA;EACE,iBAAiB;AACnB;AACA;EACE;0CACwC;EACxC,UAAU;EACV,WAAW;EACX,iBAAiB;AACnB;AACA;EACE,uBAAuB;EACvB,aAAa;EACb,cAAc;AAChB;AACA;EACE,kBAAkB;EAClB,OAAO;EACP,SAAS;AACX;AACA;EACE,eAAe;AACjB;AACA;EACE,eAAe;AACjB;AACA;EACE,eAAe;AACjB","sourcesContent":[".compose_container {\n  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,\n    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;\n  padding: 1%;\n  margin: 1%;\n  width: 42%;\n  height: 26rem;\n}\n.compose_heading {\n  font-weight: 800;\n  width: 7%;\n  font-size: 20px;\n}\n.heading_container {\n  line-height: 2rem;\n}\n.option_container {\n  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,\n    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;\n  width: 75%;\n  padding: 1%;\n  margin-bottom: 2%;\n}\n.help_btn {\n  background-color: black;\n  padding: 1.5%;\n  margin-top: 3%;\n}\n.compose_container {\n  position: absolute;\n  top: 4%;\n  left: 38%;\n}\n.compose_heading {\n  font-size: 16px;\n}\n.username {\n  margin-left: 2%;\n}\n.option_container1 {\n  font-size: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"compose_container": `ComposeMessages_compose_container__nLzFV`,
	"compose_heading": `ComposeMessages_compose_heading__vC+So`,
	"heading_container": `ComposeMessages_heading_container__tv5yc`,
	"option_container": `ComposeMessages_option_container__whubN`,
	"help_btn": `ComposeMessages_help_btn__RB8I+`,
	"username": `ComposeMessages_username__7l3j8`,
	"option_container1": `ComposeMessages_option_container1__Jw3Hw`
};
export default ___CSS_LOADER_EXPORT___;
