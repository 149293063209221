import React, { useEffect, useState } from 'react';
import { FaArrowRight } from 'react-icons/fa';
 // Ensure you have this CSS file
// import ssc from "../"
import topic from "../../../Ebook/i/Topic.png"

const NextPageTopic = () => {
  return (
    <>
     <div className="container-fluid-blog1">
        <div className="blog-row1">
          <div className="content-left1">
            <div className='content-left1-text-block'>
              <h1>Topic wise current affairs</h1>
              <p>Master each topic of current affairs and excel in your examinations.</p>
            </div>
          </div>
          <div className="content-right1">
            <img src={topic} alt="Placeholder" />
          </div>
        </div>
      </div>
    </>
  );
};

export default NextPageTopic;
