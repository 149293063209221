import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import YTVideoPlayer from "../YT_VideoPlayer";
import styles from "./YT_Learning.module.css";
import YTPlayerNavbar from "./YT_PlayerNavbar";
import Overview from "../YT_Navigation Components/CourseOverview";
import Notes from "../YT_Navigation Components/NotesEditor/NoteEditor";
import Announcement from "../YT_Navigation Components/Announcements/Announcements";
import Reviews from "../YT_Navigation Components/Review/Review";
import YTPlayerHeader from "./YT_PlayerHeader";
import YTSidebar from "../YT_Sidebar";
import { useAuth } from "../../Context/AuthContext";

const YTLearning = () => {
  const { isLoggedIn, user } = useAuth();
  const navigate = useNavigate();
  const { courseId } = useParams();
  const [selectedVideoId, setSelectedVideoId] = useState(null);
  const [activeComponent, setActiveComponent] = useState("overview");
  const [watchedVideos, setWatchedVideos] = useState({});
  const [viewedPdfs, setViewedPdfs] = useState({});
  const [firstVideoId, setFirstVideoId] = useState(null); // State to hold the first video ID

  // Load user progress when the component mounts
  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/");
    } else if (user) {
      fetch(
        `https://auth.ssccglpinnacle.com/api/video_user_progress/${user._id}/${courseId}`
      )
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw new Error("No progress found");
        })
        .then((data) => {
          setWatchedVideos(data.watchedVideos || {});
          setViewedPdfs(data.viewedPdfs || {});
          if (data.lastPlayedVideo) {
            setSelectedVideoId(data.lastPlayedVideo);
          } else if (firstVideoId) {
            setSelectedVideoId(firstVideoId);
            saveProgress(firstVideoId);
          }
        })
        .catch((error) => {
          console.error("Error fetching user progress:", error);
          if (firstVideoId) {
            setSelectedVideoId(firstVideoId);
            saveProgress(firstVideoId);
          }
        });
    }
  }, [isLoggedIn, user, courseId, navigate, firstVideoId]);

  const handleVideoSelect = useCallback(
    (videoId) => {
      if (selectedVideoId !== videoId) {
        setSelectedVideoId(videoId);
        saveProgress(videoId);
      }
    },
    [selectedVideoId, courseId]
  );

  const handlePdfView = useCallback(
    (pdfId) => {
      setViewedPdfs((prev) => {
        const updatedViewedPdfs = { ...prev, [pdfId]: true };
        saveProgress(selectedVideoId);
        return updatedViewedPdfs;
      });
    },
    [selectedVideoId, courseId]
  );

  const markVideoAsWatched = useCallback(
    (videoId) => {
      setWatchedVideos((prev) => {
        const updatedWatchedVideos = { ...prev, [videoId]: true };
        saveProgress(videoId);
        return updatedWatchedVideos;
      });
    },
    [courseId]
  );

  const saveProgress = useCallback(
    (lastPlayedVideo) => {
      const progressData = {
        userId: user._id,
        courseId: courseId,
        watchedVideos: Object.keys(watchedVideos),
        viewedPdfs: Object.keys(viewedPdfs),
        lastPlayedVideo,
      };

      fetch("https://auth.ssccglpinnacle.com/api/video_user_progress", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(progressData),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to save progress");
          }
        })
        .catch((error) => console.error("Error saving progress:", error));
    },
    [user, courseId, watchedVideos, viewedPdfs]
  );

  return (
    <>
      <div className={styles.layoutContainer}>
        <div className={styles.videoHeader}>
          <YTPlayerHeader courseId={courseId} />
        </div>
        <div className={styles.contentContainer}>
          <div className={styles.videoContainer}>
            <YTVideoPlayer
              videoId={selectedVideoId}
              onVideoWatched={markVideoAsWatched}
            />
            <YTPlayerNavbar setActiveComponent={setActiveComponent} />
            {activeComponent === "overview" && <Overview />}
            {activeComponent === "notes" && <Notes courseId={courseId} />}
            {activeComponent === "announcement" && (
              <Announcement courseId={courseId} />
            )}
            {activeComponent === "reviews" && <Reviews />}
          </div>
          <div className={styles.sidebarContainer}>
            <YTSidebar
              onVideoSelect={handleVideoSelect}
              currentVideoId={selectedVideoId}
              courseId={courseId}
              watchedVideos={watchedVideos}
              onPdfView={handlePdfView}
              viewedPdfs={viewedPdfs}
              setFirstVideoId={setFirstVideoId}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default YTLearning;
