// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.typing-paragraph-form {
  max-width: 85%;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f9f9f9;
}

.typing-paragraph-form-group {
  margin-bottom: 15px;
}

.typing-paragraph-label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.typing-paragraph-input,
.typing-paragraph-textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

.typing-paragraph-textarea {
  resize: vertical;
  height: 100px;
}

.typing-paragraph-button {
  display: inline-block;
  padding: 10px 20px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.typing-paragraph-button:hover {
  background-color: #0056b3;
}

.typing-paragraph-input:focus,
.typing-paragraph-textarea:focus {
  border-color: #66afe9;
  outline: none;
  box-shadow: 0 0 5px rgba(102, 175, 233, 0.6);
}
`, "",{"version":3,"sources":["webpack://./src/Ebook/Forms/AddTypingParagraph.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,cAAc;EACd,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;;EAEE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;EAChB,aAAa;AACf;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,WAAW;EACX,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;;EAEE,qBAAqB;EACrB,aAAa;EACb,4CAA4C;AAC9C","sourcesContent":[".typing-paragraph-form {\n  max-width: 85%;\n  margin: 0 auto;\n  padding: 20px;\n  border: 1px solid #ccc;\n  border-radius: 10px;\n  background-color: #f9f9f9;\n}\n\n.typing-paragraph-form-group {\n  margin-bottom: 15px;\n}\n\n.typing-paragraph-label {\n  display: block;\n  margin-bottom: 5px;\n  font-weight: bold;\n}\n\n.typing-paragraph-input,\n.typing-paragraph-textarea {\n  width: 100%;\n  padding: 10px;\n  border: 1px solid #ccc;\n  border-radius: 5px;\n  box-sizing: border-box;\n}\n\n.typing-paragraph-textarea {\n  resize: vertical;\n  height: 100px;\n}\n\n.typing-paragraph-button {\n  display: inline-block;\n  padding: 10px 20px;\n  color: #fff;\n  background-color: #007bff;\n  border: none;\n  border-radius: 5px;\n  cursor: pointer;\n  font-size: 16px;\n}\n\n.typing-paragraph-button:hover {\n  background-color: #0056b3;\n}\n\n.typing-paragraph-input:focus,\n.typing-paragraph-textarea:focus {\n  border-color: #66afe9;\n  outline: none;\n  box-shadow: 0 0 5px rgba(102, 175, 233, 0.6);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
