// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StarRating_star-rating__WrqPV {
  display: flex;
  width: 49%;
  margin-left: 57%;
  align-items: center;
  justify-content: center;
}

.StarRating_star__nyHFE {
  cursor: pointer;
}

.StarRating_star__nyHFE > svg {
  transition: color 0.2s;
}

.StarRating_star__nyHFE.StarRating_active__ZL9MF > svg {
  color: #f1c40f; /* Color for the active (filled) star */
}

.StarRating_rating-text__xYdge {
  font-size: 16px;
  margin-top: 10px;
  color: #333;
}
.StarRating_rating_para__OoNPZ {
  font-size: 12px;
  text-align: center;
  font-weight: 600;
  width: 27%;
  margin-left: 73%;
}
`, "",{"version":3,"sources":["webpack://./src/VIDEOPLAYER COMPONENTS/components/BodyContent/Rating Component/StarRating.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,UAAU;EACV,gBAAgB;EAChB,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,cAAc,EAAE,uCAAuC;AACzD;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,WAAW;AACb;AACA;EACE,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,UAAU;EACV,gBAAgB;AAClB","sourcesContent":[".star-rating {\n  display: flex;\n  width: 49%;\n  margin-left: 57%;\n  align-items: center;\n  justify-content: center;\n}\n\n.star {\n  cursor: pointer;\n}\n\n.star > svg {\n  transition: color 0.2s;\n}\n\n.star.active > svg {\n  color: #f1c40f; /* Color for the active (filled) star */\n}\n\n.rating-text {\n  font-size: 16px;\n  margin-top: 10px;\n  color: #333;\n}\n.rating_para {\n  font-size: 12px;\n  text-align: center;\n  font-weight: 600;\n  width: 27%;\n  margin-left: 73%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"star-rating": `StarRating_star-rating__WrqPV`,
	"star": `StarRating_star__nyHFE`,
	"active": `StarRating_active__ZL9MF`,
	"rating-text": `StarRating_rating-text__xYdge`,
	"rating_para": `StarRating_rating_para__OoNPZ`
};
export default ___CSS_LOADER_EXPORT___;
