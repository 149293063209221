import React from "react";
import "./Rate.css";
import { MdStarRate } from "react-icons/md";
import Progressbar from "./Progressbar";

const Rate = ({
  averageRating,
  totalRatings,
  ratingCounts = [0, 0, 0, 0, 0],
}) => {
  // Ensure ratingCounts is an array of counts for 1-star to 5-star ratings
  const [count1, count2, count3, count4, count5] = ratingCounts;

  const totalAverage =
    1 * count1 + 2 * count2 + 3 * count3 + 4 * count4 + 5 * count5;

  const wholeCount = count1 + count2 + count3 + count4 + count5;
  const calculatedRate = wholeCount > 0 ? totalAverage / wholeCount : 0;

  return (
    <>
      <div className="giverating">Give Your Feedback</div>
      <div className="a">
        <div className="container-for-rating">
          <div className="cards-for-star-rating">
            <div className="card-content-for-star-rating">
              <h2>{calculatedRate.toFixed(1)}</h2>
              <div className="For-Star">
                <MdStarRate className="Star-Rating-average" fontSize="1.5em" />
              </div>
            </div>
            <div className="Total-rating">({wholeCount}) Ratings</div>
          </div>
          <div className="cards-for-star-rating-2">
            <Progressbar
              C1={count1}
              C2={count2}
              C3={count3}
              C4={count4}
              C5={count5}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Rate;
