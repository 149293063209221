// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* App.css */

.message-for-hover {
  display: none;
  position: absolute;
  background-color: #333;
  color: #fff;
    width: 215px;
    padding: 5px 10px 5px 10px;
    border-radius: 5px;
    z-index: 1;
    transform: translateY(10%);
  font-size: 13px;
  text-align: left;
  line-height: 1.5;
}

.box:hover .message-for-hover {
  display: block;
}
/* App.css */

/* ... Your existing styles ... */

/* Add a small tail to the message box */
.box:hover .message-for-hover::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #333; /* You can adjust the color as needed */
  top: -10px; /* Adjust the top position to align the tail with the box */
  left: 50%; /* Position it in the center horizontally */
  transform: translateX(-50%);
  z-index: 2; /* Ensure it's on top of the box */
}
.box-content-for-message p{
  margin-bottom: 0px!important;
}
.points-calculate{
  margin-bottom: 0px!important;
      font-size: 17px;
    line-height: 1.5;
    font-weight: 700;
}

.overall-learn{
  margin-bottom: 0px!important;
      font-size: 13px;
    line-height: 1;
}

`, "",{"version":3,"sources":["webpack://./src/Ebook/User/HoverBox.css"],"names":[],"mappings":"AAAA,YAAY;;AAEZ;EACE,aAAa;EACb,kBAAkB;EAClB,sBAAsB;EACtB,WAAW;IACT,YAAY;IACZ,0BAA0B;IAC1B,kBAAkB;IAClB,UAAU;IACV,0BAA0B;EAC5B,eAAe;EACf,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,cAAc;AAChB;AACA,YAAY;;AAEZ,iCAAiC;;AAEjC,wCAAwC;AACxC;EACE,WAAW;EACX,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,mCAAmC;EACnC,oCAAoC;EACpC,8BAA8B,EAAE,uCAAuC;EACvE,UAAU,EAAE,2DAA2D;EACvE,SAAS,EAAE,2CAA2C;EACtD,2BAA2B;EAC3B,UAAU,EAAE,kCAAkC;AAChD;AACA;EACE,4BAA4B;AAC9B;AACA;EACE,4BAA4B;MACxB,eAAe;IACjB,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;EACE,4BAA4B;MACxB,eAAe;IACjB,cAAc;AAClB","sourcesContent":["/* App.css */\n\n.message-for-hover {\n  display: none;\n  position: absolute;\n  background-color: #333;\n  color: #fff;\n    width: 215px;\n    padding: 5px 10px 5px 10px;\n    border-radius: 5px;\n    z-index: 1;\n    transform: translateY(10%);\n  font-size: 13px;\n  text-align: left;\n  line-height: 1.5;\n}\n\n.box:hover .message-for-hover {\n  display: block;\n}\n/* App.css */\n\n/* ... Your existing styles ... */\n\n/* Add a small tail to the message box */\n.box:hover .message-for-hover::before {\n  content: \"\";\n  position: absolute;\n  width: 0;\n  height: 0;\n  border-left: 10px solid transparent;\n  border-right: 10px solid transparent;\n  border-bottom: 10px solid #333; /* You can adjust the color as needed */\n  top: -10px; /* Adjust the top position to align the tail with the box */\n  left: 50%; /* Position it in the center horizontally */\n  transform: translateX(-50%);\n  z-index: 2; /* Ensure it's on top of the box */\n}\n.box-content-for-message p{\n  margin-bottom: 0px!important;\n}\n.points-calculate{\n  margin-bottom: 0px!important;\n      font-size: 17px;\n    line-height: 1.5;\n    font-weight: 700;\n}\n\n.overall-learn{\n  margin-bottom: 0px!important;\n      font-size: 13px;\n    line-height: 1;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
