// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ApplyCoupon_ApplyCoupon-fullPage__uEQKc{
    margin-top: 0.5rem;
    display: flex;
}
.ApplyCoupon_inputBox__YCTBW{
    height:1.5rem;
    padding: 0.5rem;
    font-size: 14px;

}
.ApplyCoupon_apply-btn__WiYsl{
    background-color: #0271b4;
    color: white;
    width: 4rem;
    font-size: 14px;
    border:none;

}`, "",{"version":3,"sources":["webpack://./src/component/Page02/ApplyCoupon.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;AACjB;AACA;IACI,aAAa;IACb,eAAe;IACf,eAAe;;AAEnB;AACA;IACI,yBAAyB;IACzB,YAAY;IACZ,WAAW;IACX,eAAe;IACf,WAAW;;AAEf","sourcesContent":[".ApplyCoupon-fullPage{\n    margin-top: 0.5rem;\n    display: flex;\n}\n.inputBox{\n    height:1.5rem;\n    padding: 0.5rem;\n    font-size: 14px;\n\n}\n.apply-btn{\n    background-color: #0271b4;\n    color: white;\n    width: 4rem;\n    font-size: 14px;\n    border:none;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ApplyCoupon-fullPage": `ApplyCoupon_ApplyCoupon-fullPage__uEQKc`,
	"inputBox": `ApplyCoupon_inputBox__YCTBW`,
	"apply-btn": `ApplyCoupon_apply-btn__WiYsl`
};
export default ___CSS_LOADER_EXPORT___;
