import React, { useEffect, useState } from 'react';
import { FaArrowRight } from 'react-icons/fa';
 // Ensure you have this CSS file
// import ssc from "../"
import HSSC from "../../../Ebook/i/HSSC.png"

const NextPageHSSC = () => {
  return (
    <>
<div className="container-fluid-blog1">
        <div className="blog-row1">
          <div className="content-left1">
            <div className='content-left1-text-block'>
              <h1>HSSC</h1>
              <p>Excel in HSSC exams with comprehensive preparation and determination.</p>
            </div>
          </div>
          <div className="content-right1">
            <img src={HSSC} alt="Placeholder" />
          </div>
        </div>
      </div>
    </>
  );
};

export default NextPageHSSC;
