import React, { useEffect, useRef } from 'react';

const PdfViewer = ({ pdfData }) => {
  const adobeClientId = '1eecd01598a645868bca17fd53a00611';
  const adobeDivRefs = useRef([]); // Refs to target the divs for PDFs

  useEffect(() => {
    if (pdfData.length > 0 && window.AdobeDC && window.AdobeDC.View) {
      pdfData.forEach((pdf, index) => {
        const adobeDCView = new window.AdobeDC.View({
          clientId: adobeClientId,
          divId: `adobe-dc-view-${index}`, // Unique div ID for each PDF
        });

        const cloudFrontUrl = `https://dzdx39zg243ni.cloudfront.net/${pdf.s3Key}`; // Dynamically construct the URL

        // Preview file using Adobe Embed API
        adobeDCView.previewFile({
          content: {
            location: {
              url: cloudFrontUrl,
            },
          },
          metaData: {
            fileName: pdf.title || `document-${index + 1}.pdf`,
          },
        }, {
          embedMode: 'IN_LINE',
          showDownloadPDF: false,
          showPrintPDF: false,
        });

        adobeDivRefs.current[index] = document.getElementById(`adobe-dc-view-${index}`);
      });
    }
  }, [pdfData, adobeClientId]);

  return (
    <>
      {pdfData.length > 0 ? (
        pdfData.map((pdf, index) => (
          <div key={index} style={{ marginBottom: '20px' }}>
            <div id={`adobe-dc-view-${index}`} style={{ height: '600px' }}></div> {/* Adobe PDF viewer div */}
          </div>
        ))
      ) : (
        <p>No PDFs available for this chapter.</p>
      )}
    </>
  );
};

export default PdfViewer;
