

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { FaTrash } from 'react-icons/fa'; // Import trash icon
import './NotesContainer.css';

const NotesContainer = () => {
  const { chapterId, chapterTitle, cardId, email } = useParams(); // Retrieve all params from the route
  const [notes, setNotes] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    // Fetch notes data
    if (chapterId && chapterTitle && cardId && email) {
      fetch(`https://auth.ssccglpinnacle.com/api/notes-ebook?email=${email}&chapterId=${chapterId}&cardId=${cardId}`)
        .then(response => response.json())
        .then(data => {
          console.log("Fetched Notes:", data);
          setNotes(data);
        })
        .catch(error => console.error('Error fetching notes:', error));
    }
  }, [chapterId, chapterTitle, cardId, email]);

  const downloadPDF = async () => {
    const pdf = new jsPDF();
    let yPosition = 10;

    pdf.setFontSize(16);
    pdf.text(`Notes for ${chapterTitle}`, 10, yPosition);
    yPosition += 10;

    for (const note of notes) {
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = note.notesContent;
      document.body.appendChild(tempDiv);

      const canvas = await html2canvas(tempDiv, { backgroundColor: null });
      const imgData = canvas.toDataURL('image/png');

      const imgWidth = 250; // Width in PDF
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      if (yPosition + imgHeight > pdf.internal.pageSize.height - 10) {
        pdf.addPage();
        yPosition = 10;
      }
      pdf.addImage(imgData, 'PNG', 10, yPosition, imgWidth, imgHeight);
      yPosition += imgHeight + 10;

      document.body.removeChild(tempDiv);
    }

    pdf.save(`notes_${chapterTitle}.pdf`);
  };

  const handleNext = () => setCurrentPage((prevPage) => (prevPage + 1) % notes.length);
  const handlePrevious = () => setCurrentPage((prevPage) => (prevPage - 1 + notes.length) % notes.length);

  // Delete the currently displayed note
  const deleteCurrentNote = async () => {
    const noteToDelete = notes[currentPage];
    if (noteToDelete) {
      try {
        const response = await fetch(`https://auth.ssccglpinnacle.com/api/delete-note/${noteToDelete._id}`, { method: 'DELETE' });
        if (response.ok) {
          // Update notes and adjust currentPage index
          const updatedNotes = notes.filter((_, index) => index !== currentPage);
          setNotes(updatedNotes);
          setCurrentPage((prevPage) => Math.max(0, prevPage - 1));
        } else {
          console.error("Failed to delete the note.");
        }
      } catch (error) {
        console.error("Error deleting note:", error);
      }
    }
  };

  return (
    <div className="notes-container-opennotes">
      <h2 className="notes-header-opennotes">Notes for {chapterTitle}</h2>
      <div className="top-controls-opennotes">
        <button className="download-btn-opennotes" onClick={downloadPDF}>Download Notes as PDF</button>
        <button className="delete-btn-opennotes" onClick={deleteCurrentNote} disabled={notes.length === 0}>
          <FaTrash /> Delete Note
        </button>
        <div className="navigation-buttons-opennotes">
          <button onClick={handlePrevious} disabled={notes.length <= 1}>◀ Previous</button>
          <span className="page-indicator-opennotes">Page {currentPage + 1} of {notes.length}</span>
          <button onClick={handleNext} disabled={notes.length <= 1}>Next ▶</button>
        </div>
      </div>
      
      {notes.length > 0 ? (
        <div className="slider-opennotes">
          <div className="note-card-opennotes">
            <div className="note-content-opennotes" 
                 dangerouslySetInnerHTML={{ __html: notes[currentPage].notesContent }} />
          </div>
        </div>
      ) : (
        <p className="no-notes-opennotes">No notes found for this chapter and ebook.</p>
      )}
    </div>
  );
};

export default NotesContainer;
