import React, { useState, useEffect } from 'react';
import styles from './LearningTools.module.css';
import Modal from 'react-modal';

Modal.setAppElement('#root');

export default function LearningTools({ userId }) {
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({
    courseTitle: '',
    frequency: '',
    duration: '',
    time: '',
    reminder: '',
  });

  const [purchasedCourses, setPurchasedCourses] = useState([]);
  const [availableCourses, setAvailableCourses] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Fetch purchased courses based on userId
  useEffect(() => {
    const fetchPurchasedCourses = async () => {
      try {
        const response = await fetch(`https://auth.ssccglpinnacle.com/api/purchased_videos/${userId}`);
        const data = await response.json();
        setPurchasedCourses(data.purchasedVideos);
      } catch (error) {
        console.error('Error fetching purchased courses:', error);
      }
    };

    fetchPurchasedCourses();
  }, [userId]);

  // Fetch course details for each productId
  useEffect(() => {
    if (purchasedCourses.length > 0) {
      const fetchCourseDetails = async () => {
        try {
          const courseDetailsPromises = purchasedCourses.map((course) =>
            fetch(`https://auth.ssccglpinnacle.com/course/${course.productId}`)
              .then((response) => response.json())
          );

          const courses = await Promise.all(courseDetailsPromises);
          setAvailableCourses(courses);
        } catch (error) {
          console.error('Error fetching course details:', error);
        }
      };

      fetchCourseDetails();
    }
  }, [purchasedCourses]);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentStep(1);
    setFormData({
      courseTitle: '',
      frequency: '',
      duration: '',
      time: '',
      reminder: '',
    });
  };

  // Function to handle going to the next step
  const goToNextStep = () => {
    if (currentStep === 1) {
      // Move to Step 2 (no validation needed here, but you can add it)
      setCurrentStep((prevStep) => prevStep + 1); 
    } else if (currentStep === 2 && isFormValid()) {
      // Move to Step 3 (after validation in Step 2)
      setCurrentStep((prevStep) => prevStep + 1);
    } else {
      alert('Please fill out all fields.');
    }
  };

  // Function to handle going to the previous step
  const goToPreviousStep = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  // Validation function to check required fields
  const isFormValid = () => {
    const { frequency, duration, time, reminder } = formData;
    return frequency && duration && time && reminder;
  };

  // Render content for each step
  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <>
            <h2>Create an event</h2>
            <div className={styles.step}>Step 1 of 3</div>
            <label>Name</label>
            <input
              type="text"
              placeholder="Time to learn!"
              className={styles.modalInput}
              value={formData.courseTitle} 
              onChange={(e) => setFormData({ ...formData, courseTitle: e.target.value })}
            />

            {/* Course Selection */}
            <label>Course (Optional)</label>
            <div className={styles.courseList}>
              {availableCourses.length > 0 ? (
                availableCourses.map((course, index) => (
                  <label key={index}>
                    <input
                      type="radio"
                      name="course"
                      value={course.courseTitle}
                      onChange={(e) => setFormData({ ...formData, courseTitle: e.target.value })}
                    />
                    {course.courseTitle}
                  </label>
                ))
              ) : (
                <p>No courses available</p>
              )}
            </div>

            {/* Search Field */}
            {/* <label>Search</label>
            <input
              type="text"
              placeholder="Search"
              className={styles.modalInput}
              value={formData.courseTitle}
              onChange={(e) => setFormData({ ...formData, courseTitle: e.target.value })}
            /> */}

            {/* Navigation Buttons
            <div className={styles.modalFooter}>
              <button className={styles.nextButton} onClick={goToNextStep}>
                Next
              </button>
            </div> */}
          </>
        );
      case 2:
        return (
          <>
            <h2>Create an event</h2>
            <div className={styles.step}>Step 2 of 3</div>

            {/* Frequency Selection */}
            <label>Frequency</label>
            <div className={styles.buttonGroup}>
              <button
                className={formData.frequency === 'Daily' ? styles.active : ''}
                onClick={() => setFormData({ ...formData, frequency: 'Daily' })}
              >
                Daily
              </button>
              <button
                className={formData.frequency === 'Weekly' ? styles.active : ''}
                onClick={() => setFormData({ ...formData, frequency: 'Weekly' })}
              >
                Weekly
              </button>
              <button
                className={formData.frequency === 'Monthly' ? styles.active : ''}
                onClick={() => setFormData({ ...formData, frequency: 'Monthly' })}
              >
                Monthly
              </button>
            </div>

            {/* Duration Selection */}
            <label>Duration</label>
            <div className={styles.buttonGroup}>
              <button
                className={formData.duration === '10 min' ? styles.active : ''}
                onClick={() => setFormData({ ...formData, duration: '10 min' })}
              >
                10 min
              </button>
              <button
                className={formData.duration === '30 min' ? styles.active : ''}
                onClick={() => setFormData({ ...formData, duration: '30 min' })}
              >
                30 min
              </button>
              <button
                className={formData.duration === '1 hr' ? styles.active : ''}
                onClick={() => setFormData({ ...formData, duration: '1 hr' })}
              >
                1 hr
              </button>
            </div>

            {/* Time Selection */}
            <label>Time</label>
            <input
              type="time"
              className={styles.modalInput}
              value={formData.time}
              onChange={(e) => setFormData({ ...formData, time: e.target.value })}
            />

            {/* Reminder Selection */}
            <label>Reminder</label>
            <div className={styles.reminderGroup}>
              <select
                value={formData.reminder}
                onChange={(e) => setFormData({ ...formData, reminder: e.target.value })}
              >
                <option value="">Select Reminder</option>
                <option value="Notification">Notification</option>
                <option value="Email">Email</option>
              </select>
            </div>

            {/* Navigation Buttons */}
            {/* <div className={styles.modalFooter}>
              <button className={styles.previousButton} onClick={goToPreviousStep}>
                Previous
              </button>
              <button className={styles.nextButton} onClick={goToNextStep}>
                Next
              </button>
            </div> */}
          </>
        );
      case 3:
        return (
          <>
            <h2>Create an event</h2>
            <div className={styles.step}>Step 3 of 3</div>

            <div className={styles.summary}>
              <div>
                <span>{formData.frequency}</span>
                <span>Reminder notification 30min before</span>
                <span>{formData.duration} at {formData.time}</span>
              </div>
              <div>Not yet added to your calendar</div>
            </div>

            {/* Navigation Buttons */}
            {/* <div className={styles.modalFooter}>
              <button className={styles.previousButton} onClick={goToPreviousStep}>
                Previous
              </button>
              <button className={styles.finishButton} onClick={closeModal}>
                Finish
              </button>
            </div> */}
          </>
        );
      default:
        return null;
  }
  };

  return (
    <div className={styles.learningTools}>
      <h1 className={styles.heading}>Learning reminders</h1>

      <div className={styles.section}>
        <h2>Calendar events</h2>
        <p>
          Learning a little each day adds up. Research shows that students who make learning a habit are more likely to reach their goals. Set time aside to learn and get reminders using your learning scheduler.
        </p>
        <button className={styles.scheduleButton} onClick={openModal}>
          Schedule learning time +
        </button>
      </div>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        className={styles.modalContent}
        overlayClassName={styles.modalOverlay}
      >
        <div className={styles.modalHeader}>{renderStepContent()}</div>
        <div className={styles.modalFooter}>
          {currentStep > 1 && (
            <button className={styles.previousButton} onClick={goToPreviousStep}>
              Previous
            </button>
          )}
          {currentStep < 3 && (
            <button className={styles.nextButton} onClick={goToNextStep}>
              Next
            </button>
          )}
          {currentStep === 3 && (
            <button className={styles.finishButton} onClick={closeModal}>
              Done
            </button>
          )}
        </div>
        <button className={styles.closeButton} onClick={closeModal}>
          X
        </button>
      </Modal>
    </div>
  );
}
