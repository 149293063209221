import React, { useEffect, useState } from 'react';
import { FaSearch, FaTimes } from 'react-icons/fa';
import pic from "../../Ebook/i/pinnacle.png";
import pic2 from "../../Ebook/i/imagebook.png";
import './Blog1.css'; // Ensure you have this CSS file
import { Link, useNavigate } from "react-router-dom";


const BlogHeader = () => {
  const navigate = useNavigate();
  const home = () => {
    navigate("/");
  };

  const blog = () => {
    navigate("/blog");
  };
  return (
    <>
     <header className="blog-header">
        <div className='nav-logo-wrapper-pinncale'>
          <div className='nav-logo-img'>
            <div className="blog-img-logo"  onClick={home}>
              <img className="logo-blog" src={pic} alt="Pinnacle Logo" />
            </div>
          </div>
          <div className='for-blog-header'>
            <p className='blog-para-header'onClick={blog}>Blog</p>
            <div className="header-buttons">
              <button className="btnnnn business-btn">Pinnacle Business</button>
              <button className="btnnnn courses-brod-btn">Browse Pinnacle Courses</button>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default BlogHeader;
