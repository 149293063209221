// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BookBuyButton_buyNowButton__C-PCl {
  background-color: #0a0a0a;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  transition: background-color 0.3s ease;
}

/* .buyNowButton:hover {
   background-color: #333333;
 }  */
`, "",{"version":3,"sources":["webpack://./src/PagesBook/DigitalContentBody/BookBuyButton.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,cAAc;EACd,kBAAkB;EAClB,YAAY;EACZ,eAAe;EACf,eAAe;EACf,kBAAkB;EAClB,qBAAqB;EACrB,qBAAqB;EACrB,sCAAsC;AACxC;;AAEA;;KAEK","sourcesContent":[".buyNowButton {\n  background-color: #0a0a0a;\n  color: #ffffff;\n  padding: 10px 20px;\n  border: none;\n  cursor: pointer;\n  font-size: 16px;\n  text-align: center;\n  text-decoration: none;\n  display: inline-block;\n  transition: background-color 0.3s ease;\n}\n\n/* .buyNowButton:hover {\n   background-color: #333333;\n }  */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buyNowButton": `BookBuyButton_buyNowButton__C-PCl`
};
export default ___CSS_LOADER_EXPORT___;
