import React, { useState, useEffect } from "react";
import { List } from "react-movable";
import Swal from "sweetalert2";
import "./ReorderBooks.css"; // Create a similar CSS file for books

function ReorderBooks() {
  const [bookList, setBookList] = useState([]);
  const [hoveredIndex, setHoveredIndex] = useState(null); // Track the hovered index

  useEffect(() => {
    fetch("https://auth.ssccglpinnacle.com/api/booksforactive") // Adjust the API endpoint to fetch books
      .then((response) => response.json())
      .then((data) => {
        const sortedData = data.sort((a, b) => a.order - b.order);
        setBookList(sortedData);
      })
      .catch((error) => console.error("Error fetching books:", error));
  }, []);

  const handleSaveOrder = async () => {
    const reorderedBooks = bookList.map((book, index) => ({
      _id: book._id,
      order: index + 1,
    }));

    try {
      const response = await fetch("https://auth.ssccglpinnacle.com/api/reorder-books", { // Adjust the API endpoint for reordering books
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(reorderedBooks),
      });

      if (response.ok) {
        Swal.fire("Success", "Order updated successfully!", "success");
      } else {
        Swal.fire("Error", "Failed to update order.", "error");
      }
    } catch (error) {
      Swal.fire("Error", "Failed to update order.", "error");
    }
  };

  const swapItems = (array, index1, index2) => {
    const newArray = [...array];
    const temp = newArray[index1];
    newArray[index1] = newArray[index2];
    newArray[index2] = temp;
    return newArray;
  };

  return (
    <div className="reorder-books-container">
      <h2>Reorder Books</h2>
      <List
        values={bookList}
        onChange={({ oldIndex, newIndex }) => {
          const newBookList = swapItems(bookList, oldIndex, newIndex);
          setBookList(newBookList);
        }}
        renderList={({ children, props }) => (
          <div {...props} className="books-grid">
            {children}
          </div>
        )}
        renderItem={({ value, props, index }) => (
          <div
            {...props}
            key={value._id}
            className={`book-card-reorder ${hoveredIndex === index ? "is-replaceable" : ""}`}
            onDragEnter={() => setHoveredIndex(index)}
            onDragLeave={() => setHoveredIndex(null)}
            onDrop={() => setHoveredIndex(null)}  // Reset after drop
          >
            <div className="order-indicator">{index + 1}</div> {/* Order Indicator */}
            <img src={value.image1} alt={value.title} className="book-image" /> {/* Adjusted to use book images */}
            <p>{value.title}</p>
            <p>Medium: {value.medium}</p>
            <p>Edition: {value.edition}</p>
            <p>SKU: {value.sku}</p>
            {value.BookSellingPrice && value.BookPrintingPrice && (
              <div>
                <p>Printing Price: ₹{value.BookPrintingPrice}</p>
                <p>Selling Price: ₹{value.BookSellingPrice}</p>
              </div>
            )}
          </div>
        )}
      />
      <button onClick={handleSaveOrder} className="save-order-button">
        Save Order
      </button>
    </div>
  );
}

export default ReorderBooks;
