// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Languages_Languages-fullPage__IMC-1 {
    width:80%;
    margin: auto;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    padding: 1rem;
    
}
.Languages_all-button-div__aEd8w{
    display: flex;
    flex-direction: column;
    text-align: center;
}
.Languages_Languages-Heading__HTclw{
    line-height:2.5rem;
    font-size: 16px;
    font-weight: 600;
}

.Languages_button-div__LihZV{
    text-align: center;

}

.Languages_btn__pqprY{
    width: 7rem;
    height: 2rem;
    background-color: transparent;
    font-size: 14px;
    text-align: center;
    border:none

}

.Languages_btn__pqprY:hover{
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    color: blueviolet;
    cursor: pointer;

}`, "",{"version":3,"sources":["webpack://./src/component/HomePage01/Language/Languages.module.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,YAAY;IACZ,kBAAkB;IAClB,qBAAqB;IACrB,aAAa;;AAEjB;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,kBAAkB;AACtB;AACA;IACI,kBAAkB;IAClB,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;;AAEtB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,6BAA6B;IAC7B,eAAe;IACf,kBAAkB;IAClB;;AAEJ;;AAEA;IACI,uFAAuF;IACvF,iBAAiB;IACjB,eAAe;;AAEnB","sourcesContent":[".Languages-fullPage {\n    width:80%;\n    margin: auto;\n    margin-top: 0.5rem;\n    margin-bottom: 0.5rem;\n    padding: 1rem;\n    \n}\n.all-button-div{\n    display: flex;\n    flex-direction: column;\n    text-align: center;\n}\n.Languages-Heading{\n    line-height:2.5rem;\n    font-size: 16px;\n    font-weight: 600;\n}\n\n.button-div{\n    text-align: center;\n\n}\n\n.btn{\n    width: 7rem;\n    height: 2rem;\n    background-color: transparent;\n    font-size: 14px;\n    text-align: center;\n    border:none\n\n}\n\n.btn:hover{\n    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;\n    color: blueviolet;\n    cursor: pointer;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Languages-fullPage": `Languages_Languages-fullPage__IMC-1`,
	"all-button-div": `Languages_all-button-div__aEd8w`,
	"Languages-Heading": `Languages_Languages-Heading__HTclw`,
	"button-div": `Languages_button-div__LihZV`,
	"btn": `Languages_btn__pqprY`
};
export default ___CSS_LOADER_EXPORT___;
