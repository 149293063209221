import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { MdEdit, MdDelete } from "react-icons/md";
import { FaPlay, FaEllipsisV } from "react-icons/fa";
import { FiMinusCircle } from "react-icons/fi";
import { useNavigate, Link } from "react-router-dom";
import styles from "./MyList.module.css";
import EditListModal from "./EditListModal";
import Card from "../BodyContent/Card/Card";
import Loading from "../BodyContent/ProgressBar/ProgressBar";

function MyListContent({ userId }) {
  const [myLists, setMyLists] = useState([]);
  const [activeMenu, setActiveMenu] = useState({}); 
  const [isLoading, setIsLoading] = useState(true);
  const [selectedList, setSelectedList] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [listToDelete, setListToDelete] = useState(null);
  const navigate = useNavigate();
  const menuRef = useRef(null);
  const deleteModalRef = useRef(null);

  useEffect(() => {
    const fetchMyLists = async () => {
      try {
        const response = await axios.get(
          `https://auth.ssccglpinnacle.com/api/userCourseList/${userId}`
        );
        setMyLists(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error("Failed to fetch lists:", error);
      }
    };
    fetchMyLists();
  }, [userId]);

  const handleDelete = async () => {
    try {
      if (!listToDelete) return;
      await axios.delete(
        `https://auth.ssccglpinnacle.com/api/userCourseList/${listToDelete._id}`
      );
      setMyLists(myLists.filter((list) => list._id !== listToDelete._id));
      setShowDeleteModal(false);
      setListToDelete(null);
    } catch (error) {
      console.error("Failed to delete list:", error);
    }
  };

  const openDeleteModal = (list) => {
    setListToDelete(list);
    setShowDeleteModal(true);
  };

  const handleEdit = (list) => {
    setSelectedList(list);
    setShowEditModal(true);
  };

  const handlePlay = (courseId) => {
    navigate(`/youtube_learning/${courseId}`);
  };

  const handleEditSubmit = (updatedList) => {
    const updatedLists = myLists.map((list) =>
      list._id === updatedList._id ? updatedList : list
    );
    setMyLists(updatedLists);
    setShowEditModal(false);
  };

  const toggleMenu = (listId, courseId) => {
    // Safely toggle the activeMenu for a specific list and course
    setActiveMenu((prevActiveMenu) => ({
      ...prevActiveMenu,
      [`${listId}-${courseId}`]: !prevActiveMenu[`${listId}-${courseId}`],
    }));
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setActiveMenu({}); // Close all menus
    }
     // Close the delete modal if clicked outside of it
     if (deleteModalRef.current && !deleteModalRef.current.contains(event.target)) {
      setShowDeleteModal(false);
    }
  };

  const handleDeleteCourse = async (listId, courseId) => {
    try {
      await axios.delete(
        `https://auth.ssccglpinnacle.com/api/userCourseList/${listId}/course/${courseId}`
      );

      setMyLists((prevLists) =>
        prevLists.map((list) =>
          list._id === listId
            ? {
                ...list,
                courses: list.courses.filter(
                  (course) => course.courseId !== courseId
                ),
              }
            : list
        )
      );
    } catch (error) {
      console.error("Failed to remove course from list:", error);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (isLoading) {
    return (
      <div className={styles.loaderContainer}>
        <img src="/Bubble-Preloader.gif" alt="Loading..." />
      </div>
    );
  }

  return (
    <div className={styles.listContainer}>
      {myLists.length > 0 ? (
        myLists.map((list, index) => (
          <div key={list._id} className={styles.listCard}>
            <div className={styles.listHeader}>
              <div className={styles.optionsMenu} ref={menuRef}>
                {list.listTitle}
                <div className={styles.actionBtn}>
                  <button onClick={() => handleEdit(list)}>
                    <MdEdit />
                  </button>
                  <button onClick={() => openDeleteModal(list)}>
                    <MdDelete />
                  </button>
                </div>
              </div>
            </div>
            <div className={styles.courseDesc}>{list.description}</div>
            <div className={styles.courseList}>
              {list.courses && list.courses.length > 0 ? (
                list.courses.map((course, courseIndex) => (
                  <Card key={course.courseId} className={styles.card}>
                    <div className={styles.cardMain}>
                      <div
                        className={styles.three_dots}
                        onClick={() => toggleMenu(list._id, course.courseId)}
                      >
                        <FaEllipsisV />
                      </div>
                      {activeMenu[`${list._id}-${course.courseId}`] && (
                        <div className={styles.options_menu} ref={menuRef}>
                          <div
                            className={styles.menu_item}
                            onClick={() =>
                              handleDeleteCourse(list._id, course.courseId)
                            }
                          >
                            <FiMinusCircle /> Remove course from this list
                          </div>
                        </div>
                      )}
                    </div>
                    <div className={styles.card_img_container}>
                      <div className={styles.card_img}>
                        <img
                          src={course.hindiCoverImage}
                          alt={course.courseTitle}
                          width="95"
                          height="95"
                        />
                        <img
                          src={course.englishCoverImage}
                          alt={course.courseTitle}
                          width="95"
                          height="95"
                        />
                        <div
                          onClick={() =>
                            navigate(`/youtube_learning/${course.courseId}`)
                          }
                          className={styles.play_icon}
                        >
                          <FaPlay size={30} />
                        </div>
                      </div>
                    </div>
                    <div className={styles.heading}>
                      <Link
                        to={`/youtube_learning/${course.courseId}`}
                        className={styles.title}
                      >
                        {course.courseTitle}
                      </Link>
                      <div className={styles.instructor}>
                        {course.instructorName}
                      </div>
                    </div>
                    <Loading userId={userId} courseId={course.courseId} />
                  </Card>
                ))
              ) : (
                <div className={styles.nocourses}>No courses in this list.</div>
              )}
            </div>
          </div>
        ))
      ) : (
        <div className={styles.nocourses}>
          <h3>Organize and access your courses faster!</h3>
          <p>
            <span>
              <Link to={`/mylearning/${userId}`}>
                Go to the All Courses tab{" "}
              </Link>
              to create a list.
            </span>
          </p>
        </div>
      )}

      {showEditModal && selectedList && (
        <EditListModal
          list={selectedList}
          onClose={() => setShowEditModal(false)}
          onEditSubmit={handleEditSubmit}
        />
      )}

      {showDeleteModal && (
        <div className={styles.modalOverlay}>
          <div className={styles.modal} ref={deleteModalRef}>
            <h2>Please confirm</h2>
            <p>
              Are you sure you want to delete your list? You will still be able
              to access your courses.
            </p>
            <div className={styles.buttonContainer}>
              <button
                className={styles.cancelButton}
                onClick={() => setShowDeleteModal(false)}
              >
                Cancel
              </button>
              <button className={styles.confirmButton} onClick={handleDelete}>
                OK
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default MyListContent;
