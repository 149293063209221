import React, { useState, useEffect, useRef } from "react";
import styles from "./sscCategory.module.css";
import { Link } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";
import { TbPoint } from "react-icons/tb";
import NavbarVC from "./NavBarVC";
import { AiFillStar } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { AiFillLeftCircle, AiFillRightCircle } from "react-icons/ai";
import { FaAnglesDown,FaAnglesUp } from "react-icons/fa6";
export default function RailwayCategory() {
  const [ntpc, setNtpc] = useState(true);
  const [rrbJE, setrrbJE] = useState(false);
  const [rpf, setRpf] = useState(false);
  const [sten, setSten] = useState(false);
  const [cpo, setCpo] = useState(false);
  const [je, setJe] = useState(false);
  const [jht, setJht] = useState(false);

  const ntpcHandler = () => {
    setNtpc(true);
    setrrbJE(false);
    setRpf(false);
    setCpo(false);
    setJe(false);
    setJht(false);
    setSten(false);
  };
  const RRBJEHandler = () => {
    setNtpc(false);
    setrrbJE(true);
    setRpf(false);
    setCpo(false);
    setJe(false);
    setJht(false);
    setSten(false);
  };
  const rpfHandler = () => {
    setNtpc(false);
    setrrbJE(false);
    setRpf(true);
    setCpo(false);
    setJe(false);
    setJht(false);
    setSten(false);
  };
  const stenHandler = () => {
    setNtpc(false);
    setrrbJE(false);
    setRpf(false);
    setCpo(false);
    setJe(false);
    setJht(false);
    setSten(true);
  };
  const cpoHandler = () => {
    setNtpc(false);

    setrrbJE(false);
    setRpf(false);
    setCpo(true);
    setJe(false);
    setJht(false);
    setSten(false);
  };
  const jeHandler = () => {
    setNtpc(false);
    setrrbJE(false);
    setRpf(false);
    setCpo(false);
    setJe(true);
    setJht(false);
    setSten(false);
  };
  const jhtHandler = () => {
    setNtpc(false);
    setrrbJE(false);
    setRpf(false);
    setCpo(false);
    setJe(false);
    setJht(true);
    setSten(false);
  };
  // copy from most popular course
  const navigate = useNavigate();
  const [isHovering2, setIsHovering2] = useState(false);
  // const [comments, setComments] = useState([])
  const [course, setCourse] = useState();
  const scrollRef = useRef(null);
  const courseRef = useRef(null);

  useEffect(() => {
    fetch("https://auth.ssccglpinnacle.com/api/videoCourses/CTET")
      .then((response) => response.json())
      .then((data) => {
        console.log("Fetched data:", data);
        if (Array.isArray(data)) {
          setCourse(data);
        } else {
          console.error("Data is not an array:", data);
          setCourse([]);
        }
      })
      .catch((error) => console.error("Error fetching products:", error));
  }, []);

  const scrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({
        left: -270,
        behavior: "smooth",
      });
    }
  };

  const scrollToCourses = () => {
    courseRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const scrollToBottom = () => {
    window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
  };
  // *********************
  return (
    <>
      <NavbarVC />
      <div className={styles["EM-header"]}>
        <div className={styles["EM-header-container01"]}>
          <img
            src="https://d3m4h509gttb94.cloudfront.net/Image/ctet-logo1.png"
            alt="sscLogo"
            className={styles["EM-image"]}
          ></img>
        </div>
        <div className={styles["EM-header-container02"]}>
          <div className={styles["EM-header-heading"]}>
            CTET (Central Teacher Eligibility Test){" "}
          </div>
          <button
            onClick={scrollToCourses}
            className={styles["allCourses-scrollButton"]}
          >
            All CTET Courses
          </button>
          <button onClick={scrollToBottom} className={styles["allCourses-scrollButton"]}>
             < FaAnglesDown /> 
            </button>
          <div className={styles["EM-header-para"]}>
            The CTET (Central Teacher Eligibility Test) is a crucial national
            exam in India, conducted by CBSE, designed to certify the aptitude
            of individuals aspiring to teach classes 1 through 8 in various
            government schools. The exam comprises two distinct papers; Paper I
            for those aiming to teach classes 1-5 and Paper II for classes 6-8,
            focusing on subjects like Child Development, Languages, and
            Mathematics. A CTET Certificate, valid for seven years, is awarded
            to successful candidates, serving as a standard qualification for
            teaching positions. A video course tailored for CTET preparation can
            greatly benefit students by providing comprehensive content reviews,
            effective strategies, and practice questions that mirror the exam
            format, enhancing their chances of success.
          </div>
        </div>
      </div>

      <div className={styles["EM-body"]}>
        <div className={styles["EM-body-container1"]}>
          <div className={styles["EM-body-buttons"]}>
            <button
              className={styles["EM-header-indi-butBtn"]}
              onClick={ntpcHandler}
            >
              {" "}
              Child Development and Pedagogy
            </button>
          </div>
          <div className={styles["EM-body-buttons"]}>
            <button
              className={styles["EM-header-indi-butBtn"]}
              onClick={RRBJEHandler}
            >
              Mathematics
            </button>
          </div>
          <div className={styles["EM-body-buttons"]}>
            <button
              className={styles["EM-header-indi-butBtn"]}
              onClick={rpfHandler}
            >
              Environmental Studies
            </button>
          </div>
          <div className={styles["EM-body-buttons"]}>
            <button
              className={styles["EM-header-indi-butBtn"]}
              onClick={stenHandler}
            >
              Hindi
            </button>
          </div>
          <div className={styles["EM-body-buttons"]}>
            <button
              className={styles["EM-header-indi-butBtn"]}
              onClick={cpoHandler}
            >
              English
            </button>
          </div>
          <div className={styles["EM-body-buttons"]}>
            <button
              className={styles["EM-header-indi-butBtn"]}
              onClick={jeHandler}
            >
              SSC JE(Junior Engineer)
            </button>
          </div>
          <div className={styles["EM-body-buttons"]}>
            <button
              className={styles["EM-header-indi-butBtn"]}
              onClick={jhtHandler}
            >
              SSC Junior Hindi Translator
            </button>
          </div>
        </div>
        <div className={styles["EM-body-container2"]}>
          {/* ntpc section */}
          {ntpc && (
            <div className={styles["ntpc-section"]}>
              <table class="table-ctet" border="1">
                <thead>
                  <tr>
                    <th>
                      Child Development (Primary School Child) – 15 Questions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      Concept of development and its relationship with learning
                    </td>
                  </tr>
                  <tr>
                    <td>Principles of the Development of Children</td>
                  </tr>
                  <tr>
                    <td>Influence of Heredity & Environment</td>
                  </tr>
                  <tr>
                    <td>
                      Socialization processes: Social world & children (Teacher,
                      Parents, Peers)
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Piaget, Kohlberg, and Vygotsky: constructs and critical
                      perspectives
                    </td>
                  </tr>
                  <tr>
                    <td>Concepts of child-centred and progressive education</td>
                  </tr>
                  <tr>
                    <td>
                      Critical Perspective of the Construct of Intelligence
                    </td>
                  </tr>
                  <tr>
                    <td>Multi-Dimensional Intelligence</td>
                  </tr>
                  <tr>
                    <td>Language & Thought</td>
                  </tr>
                  <tr>
                    <td>
                      Gender as a social construct; gender roles, gender bias
                      and educational practice
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Individual differences among learners, understanding
                      differences based on diversity of language, caste, gender,
                      community, religion, etc.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      The distinction between Assessment for learning and
                      assessment of learning;
                    </td>
                  </tr>
                  <tr>
                    <td>
                      School-Based Assessment, Continuous & Comprehensive
                      Evaluation: perspective and Practice
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Formulating appropriate questions for assessing the
                      readiness levels of learners; enhancing learning and
                      critical thinking in the classroom and assessing learner
                      achievement.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
          {/* RRB JE Section  */}
          {rrbJE && (
            <div>
              <div className={styles["EM-header-heading"]}>
                POSTS UNDER RRB JE{" "}
              </div>
              <div className={styles["EM-header-para"]}>
                1.Junior Engineer (JE)
              </div>
              <div className={styles["EM-header-para"]}>
                2.Junior Engineer (IT)
              </div>
              <div className={styles["EM-header-para"]}>
                3.Depot Materials Superintendent (DMS)
              </div>
              <div className={styles["EM-header-para"]}>
                4.Chemical & Metallurgical Assistant (CMA)
              </div>
              <div className={styles["EM-header-heading"]}>
                ELIGIBILITY CRITERIA FOR RRB JE EXAM{" "}
              </div>
              <div className={styles["EM-header-para"]}>
                <TbPoint />
                Minimum age required is 18.
              </div>
              <div className={styles["EM-header-para"]}>
                <TbPoint />
                You must be an engineering student.
              </div>
              <div className={styles["EM-header-para"]}>
                <TbPoint />
                You have to clear the medical examination conducted by RRB.
              </div>
              <div className={styles["EM-table"]}>
                <table>
                  <thead>
                    <tr>
                      <th>RRB JE SYLLABUS</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className={styles}>
                      <td>RRB CBT1</td>
                      <tr>
                        <td> &nbsp;&nbsp;MATHEMATICS&nbsp;&nbsp;</td>
                      </tr>
                      <tr>
                        <td>&nbsp;&nbsp;GENERAL SCIENCE &nbsp;&nbsp;</td>
                      </tr>
                      <tr>
                        <td>&nbsp;&nbsp;REASONING &nbsp;&nbsp;</td>
                      </tr>
                      <tr>
                        <td>&nbsp;&nbsp;GENERAL AWARENESS &nbsp;&nbsp;</td>
                      </tr>
                    </tr>
                    <hr />
                    <tr className={styles}>
                      <td>RRB CBT2</td>
                      <tr>
                        <td> &nbsp;&nbsp;GENERAL AWARENESS&nbsp;&nbsp;</td>
                      </tr>
                      <tr>
                        <td>&nbsp;&nbsp;BASICS OF COMPUTER &nbsp;&nbsp;</td>
                      </tr>
                      <tr>
                        <td>&nbsp;&nbsp;PHYSICS AND CHEMISTRY &nbsp;&nbsp;</td>
                      </tr>
                      <tr>
                        <td>
                          &nbsp;&nbsp;BASICS OF ENVIRONMENTAL POLLUTION
                          &nbsp;&nbsp;
                        </td>
                      </tr>
                      <tr>
                        <td>&nbsp;&nbsp;TECHNICAL DISCIPLINE &nbsp;&nbsp;</td>
                      </tr>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          )}

          {/*   RRB RPF SECTION */}
          {rpf && (
            <div className={styles["RPF-section"]}>
              RRB RPF SECTION
              <div className={styles["EM-header-heading"]}>
                ELIGIBILITY CRITERIA FOR RRB RPF EXAM
              </div>
              <div>
                <table>
                  <thead>
                    <tr>
                      <th>Post</th>
                      <th>Educational Qualification</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>RPF Constable (Executive)</th>
                      <td>SSLC/Matric from a recognized Board.</td>
                    </tr>
                    <tr>
                      <th>RPF Sub Inspector (Executive)</th>
                      <td>Graduate from a recognized University</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          )}
          {/* cgl handler */}
          {sten && <div>sten Handler</div>}
          {/* junior Engineer handler */}
          {je && <div>junior Engineer Handler</div>}
          {/* junior hindi Translator handler */}
          {jht && <div>junior hindi Translator Handler</div>}
          {/* cpo handler */}
          {cpo && <div>cpo Handler</div>}
        </div>
      </div>
      {/* **********copy from most popular course******** */}
      <div className={styles["mostPopular-div"]}>
        <button onClick={scrollLeft} className={styles["sliderButton-left"]}>
          <AiFillLeftCircle size={30} />
        </button>
        <div className={styles["Course_Heading"]}>
          Courses to get you started
        </div>
        <div className={styles["scroll-container"]} ref={scrollRef}>
          <div className={styles["card-wrapper"]} ref={courseRef}>
          <div className={styles["card-wrapper"]}>
            {/* $$$$$$$$$$$$$ */}
            {course &&
              course.map((data, index) => (
                <div
                  key={data.id}
                  className={styles["card"]}
                  onClick={() => navigate("/course/description/" + data._id)}
                >
                  <div className={styles["Upcomming-course-fullPage"]}>
                    <div className={styles["Image-section"]}>
                      <img
                        className={styles["Image-section-img1"]}
                        src={data.hindiCoverImage}
                        alt=""
                        width="90"
                        height="90"
                      ></img>
                      <img
                        className={styles["Image-section-img2"]}
                        src={data.englishCoverImage}
                        alt=""
                        width="90"
                        height="90"
                      ></img>
                    </div>
                    <div className={styles["description-section"]}>
                      <div className={styles["title"]}>{data.courseTitle}</div>
                      <div className={styles["teacher"]}>
                        Mr./Ms.{data.teacherName}
                      </div>
                      <div className={styles["try-for-free"]}>
                        <div className={styles["Rating"]}>
                          <div className={styles["Star-Rating"]}>
                            {data.rating}
                            <div className={styles["For-Star-div"]}>
                              <AiFillStar
                                className={styles["Star-Rating-1"]}
                                color="white"
                                fontSize="1em"
                              />
                            </div>
                          </div>
                          <div className={styles["Total-Rating"]}>(128)</div>
                        </div>
                      </div>
                      <div className={styles["StarRating"]}>
                        {/* {isHovering2 && <Star/>} */}
                      </div>
                      <div className={styles["price-fprice-div"]}>
                        <div>&#8377;{data.price}</div>
                        <div>
                          &nbsp;&nbsp;&nbsp;&nbsp;<s>&#8377;{data.mrp}</s>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}

            {/* ############# */}
          </div>
          </div>
        </div>
      </div>
      {/* ******************************************** */}
      <div className={styles["scroll-buttons"]}><button onClick={scrollToTop} className={styles["allCourses-scrollButton"]}><FaAnglesUp/></button></div>
    </>
  );
}
