// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ReviewCard.css */

.app-for {
text-align: center;
    /* margin: 20px; */
    display: inline-block;
    width: 100%;
}

.review-card {
    border: 1px solid #ddd;
    padding: 10px;
    margin: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: white;
    width: 47%;
    display: inline-block;
    height: 13.5rem;
}

.contain{
     display: flex;
    flex-direction: column;
    text-align: left;
    padding-left: 23px;
  
}
.contain p{
    margin-bottom: 0px!important; 
    font-family: monospace;
  
}

.user-review-name{
     font-size: 14px;
    font-weight: 900;
    color: black;
        margin-bottom: 8px;
   
 
}

.user-review-date{
      color: #767676;
    font-size: 11px;
    padding: 5px 0 0;
    height: 20px;
          margin-bottom: 8px;
}

.user-review-star-rate{
  
margin-bottom: 8px;
}


.user-review-course{

    margin-bottom: 8px;
}

.user-given-reviews  {
    margin-bottom: 8px;
          font-size: 14px;
    line-height: 19px;
    white-space: pre-wrap;
}
.hide-course-rating{
  font-size: 10px;
}

.show-more-button{
       background-color: white;
    color: black;
    font-size: 12px;
}


`, "",{"version":3,"sources":["webpack://./src/Ebook/Info/WriteReview/ReviewCard.css"],"names":[],"mappings":"AAAA,mBAAmB;;AAEnB;AACA,kBAAkB;IACd,kBAAkB;IAClB,qBAAqB;IACrB,WAAW;AACf;;AAEA;IACI,sBAAsB;IACtB,aAAa;IACb,YAAY;IACZ,kBAAkB;IAClB,wCAAwC;IACxC,uBAAuB;IACvB,UAAU;IACV,qBAAqB;IACrB,eAAe;AACnB;;AAEA;KACK,aAAa;IACd,sBAAsB;IACtB,gBAAgB;IAChB,kBAAkB;;AAEtB;AACA;IACI,4BAA4B;IAC5B,sBAAsB;;AAE1B;;AAEA;KACK,eAAe;IAChB,gBAAgB;IAChB,YAAY;QACR,kBAAkB;;;AAG1B;;AAEA;MACM,cAAc;IAChB,eAAe;IACf,gBAAgB;IAChB,YAAY;UACN,kBAAkB;AAC5B;;AAEA;;AAEA,kBAAkB;AAClB;;;AAGA;;IAEI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;UACZ,eAAe;IACrB,iBAAiB;IACjB,qBAAqB;AACzB;AACA;EACE,eAAe;AACjB;;AAEA;OACO,uBAAuB;IAC1B,YAAY;IACZ,eAAe;AACnB","sourcesContent":["/* ReviewCard.css */\n\n.app-for {\ntext-align: center;\n    /* margin: 20px; */\n    display: inline-block;\n    width: 100%;\n}\n\n.review-card {\n    border: 1px solid #ddd;\n    padding: 10px;\n    margin: 10px;\n    border-radius: 5px;\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n    background-color: white;\n    width: 47%;\n    display: inline-block;\n    height: 13.5rem;\n}\n\n.contain{\n     display: flex;\n    flex-direction: column;\n    text-align: left;\n    padding-left: 23px;\n  \n}\n.contain p{\n    margin-bottom: 0px!important; \n    font-family: monospace;\n  \n}\n\n.user-review-name{\n     font-size: 14px;\n    font-weight: 900;\n    color: black;\n        margin-bottom: 8px;\n   \n \n}\n\n.user-review-date{\n      color: #767676;\n    font-size: 11px;\n    padding: 5px 0 0;\n    height: 20px;\n          margin-bottom: 8px;\n}\n\n.user-review-star-rate{\n  \nmargin-bottom: 8px;\n}\n\n\n.user-review-course{\n\n    margin-bottom: 8px;\n}\n\n.user-given-reviews  {\n    margin-bottom: 8px;\n          font-size: 14px;\n    line-height: 19px;\n    white-space: pre-wrap;\n}\n.hide-course-rating{\n  font-size: 10px;\n}\n\n.show-more-button{\n       background-color: white;\n    color: black;\n    font-size: 12px;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
