import React, { useState, useEffect } from "react";
import "./Mylearning.css";
import { BsCart2 } from "react-icons/bs";
import HalfChart from './HalfChart';
import HoverBox from "./HoverBox";
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useAuth } from "../../Context/AuthContext";
import LoadingSpinner from "../LoadingSpinner";



function Mylearning({ cardId }) {
  const cartCount = 4;
  const navigate = useNavigate();
  const progress = 75; // Set your current progress value
  const maxValue = 100;
  const { isLoggedIn, user, token } = useAuth();
  const [purchaseHistory, setPurchaseHistory] = useState([]);
  const [ebookData, setEbookData] = useState(null);
  const [completedChaptersCount, setCompletedChaptersCount] = useState(0);
  const [ebooksList, setEbooksList] = useState([]);
  const [cookies] = useCookies(['token']);
  const [loading, setLoading] = useState(true);
  const [totalpurchasedChaptersCount, setpurchasedTotalChaptersCount] = useState(0);
  const [chaptersData, setChaptersData] = useState([]);

 

  
  

  useEffect(() => {
    const checkAccess = async () => {
      const token = cookies.token;
      if (!token) {
        navigate('/digital-catalog');
        return;
      }
  
      try {
        const response = await fetch('https://auth.ssccglpinnacle.com/api/checkAccess', {
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": `Bearer ${token}`
          }
        });
  
        if (response.ok) {
          const { access } = await response.json();
          if (access !== "access") {
            navigate('/digital-catalog');
          }
        } else {
          console.error("Access check error:", await response.json());
          navigate('/digital-catalog');
        }
      } catch (error) {
        console.error("Access check error:", error);
        navigate('/digital-catalog');
      }
    };

    

    const fetchData = async () => {
      await checkAccess();
    
      let isMounted = true; 
    
      try {
        if (isLoggedIn && user) {
          const userId = user.id;
          const useremail = user.email_id;
          const myid = user._id;
          setLoading(true);
    
          const token = cookies.token;
    
          const [ebookRes, chaptersRes, purchaseHistoryRes] = await Promise.all([
            cardId
              ? fetch(`https://auth.ssccglpinnacle.com/api/ebookss/${cardId}`)
              : fetch('https://auth.ssccglpinnacle.com/api/ebooks'),
            fetch('https://auth.ssccglpinnacle.com/api/chapters-ebook', {
              headers: {
                "Authorization": `Bearer ${token}`
              }
            }),
            fetch(`https://auth.ssccglpinnacle.com/api/purchase-history-ebook/${useremail}`, {
              headers: {
                "Authorization": `Bearer ${token}`
              }
            })
          ]);
    
          if (!ebookRes.ok || !chaptersRes.ok || !purchaseHistoryRes.ok) {
            throw new Error('Network response was not ok');
          }
    
          const [ebookData, chaptersData, purchaseHistoryData] = await Promise.all([
            ebookRes.json(),
            chaptersRes.json(),
            purchaseHistoryRes.json()
          ]);
    
          const purchasedEbookIds = purchaseHistoryData.ebooksPurchased.map(ebook => ebook._id);
          const purchasedChapters = chaptersData.filter(chapter => 
            purchasedEbookIds.includes(chapter.ebook._id)
          );

          
          setpurchasedTotalChaptersCount(purchasedChapters)
    
          // Count completed chapters
          const countCompletedChapters = purchasedChapters.reduce((count, chapter) => {
            const isChapterCompleted = chapter.completed.some(
              (comp) => comp.user === myid && comp.userCompleted === 'complete'
            );
            return isChapterCompleted ? count + 1 : count;
          }, 0);
    
          // Calculate total chapters
          // const totalChapters = chaptersData.length; // Total chapters is the length of the chapters data
    
          if (isMounted) {
            if (cardId) {
              setEbookData(ebookData);
            } else {
              setEbooksList(ebookData);
            }
            setChaptersData(chaptersData);
            setPurchaseHistory(purchaseHistoryData.ebooksPurchased);
            setCompletedChaptersCount(countCompletedChapters);
            // Set total chapters count
            setLoading(false);
          }
        } else {
          console.error("User is not logged in or user data is unavailable.");
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    
      return () => {
        isMounted = false;
      };
    };
    
    fetchData();
  }, [cardId, navigate, cookies.token, user, isLoggedIn]);

  if (loading) {
    return <><LoadingSpinner/></>;
  }
  
  
  
  
  
  

  const info2 = () => {
    navigate('/digital-catalog');
  };

  return (
    <>
      <div className="my-learning-row1">
        <div className="my-learning-heading">
          <h2 className="h2-for">My learning</h2>

          <div className="my-learning-message">
            <div className="my-learning-message-row1">
              <h2> Hi, </h2>

              <p className="text-for-my-learning-message">
                Welcome to the new 
                "My learning" here at Pinnacle. 
                This will be your hub to all the digital contents we offer and 
                your learning progress.
              </p>
              <p>We hope you will continue to learn with us.</p>

              <div className="button-for-my-learning-message">
                <button type="button" className="btn-dark">
                  {ebookData ? ebookData.title : (ebooksList.length > 0 ? ebooksList[0].title : 'Default Title')}
                </button>
                <button type="button" className="btn-light" onClick={info2}>Browse digital content</button>
              </div>
            </div>  

            <div className="my-learning-message-row2">
              <div className="score-card-row">
                <div className="score-card">
                  <div className="score-card-row1">
                    <p>Good job!</p>
                  </div>
                  <div className="score-card-row2">
                    <p>Your score</p>
                  </div>
                  <div className="score-card-row3">
                    <div className="pie-chart">
                    <HalfChart completedChaptersCount={completedChaptersCount} totalpurchasedChaptersCount={totalpurchasedChaptersCount}/>
                    </div>
                    <div className="Total-point">
                      <h2>{completedChaptersCount}</h2>
                      <p>points</p>
                    </div>
                  </div>
                  <div className="score-card-row4">
                    <p className="score-for-learning">Score factors</p>
                    <div className="score-card-four-row">
                      <HoverBox
                        message="Earn points by reading lessons in tutorials"
                        boxClass="score-card-for-lesson"
                        pointsClass={completedChaptersCount}
                        learnClass="lessons read"
                      />
                      <HoverBox
                        message="Earn stars by completing lessons, exercises, and quizzes with full scores for learning"
                        boxClass="score-card-for-star"
                        pointsClass="0"
                        learnClass="stars collected"
                      />
                      <HoverBox
                        message="Earn points by answering correctly on the quizzes"
                        boxClass="score-card-for-quiz"
                        pointsClass="0"
                        learnClass="quiz points"
                      />
                      <HoverBox
                        message="Earn points by completing exercises"
                        boxClass="score-card-for-exercise"
                        pointsClass="0"
                        learnClass="exercise points"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>  
        </div>
      </div>
    </>
  );
}

export default Mylearning;


