// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProfilePicture_ProfilePicture-fullPage__O5w1T{
    width: 65rem;
   
}
.ProfilePicture_preview-div__Ixxcz{
    height: 8rem;
    align-items: center;
    justify-items: center;
}

.ProfilePicture_preview-image__AqlvS{
    height: 150px;
}
.ProfilePicture_input-div__ts4OI{
    height: 2rem;
    width:42.5%;
    border: 1px solid black;

}

.ProfilePicture_file-input__tmV7h{
    width: 100%;
    height:2rem;

}

.ProfilePicture_Preview__lVNJs{
    border: 1px solid black;
    width: 40%;
    height: 10rem;
    padding: 1rem;
    margin-bottom: 1rem;
}
.ProfilePicture_preview-heading__Fw9Dh{
    font-size: 16px;
    font-weight: bold;
    line-height: 40px;
}
.ProfilePicture_save-btn__8EzhU{
    width: 20%;
    height: 2.5rem;
    border: none;
    font-size: 14px;
    font-weight: bold;
    background-color: #020062;
    color: white;
    margin-top: 1rem;
    margin-bottom: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/Profile/ProfilePicture.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;;AAEhB;AACA;IACI,YAAY;IACZ,mBAAmB;IACnB,qBAAqB;AACzB;;AAEA;IACI,aAAa;AACjB;AACA;IACI,YAAY;IACZ,WAAW;IACX,uBAAuB;;AAE3B;;AAEA;IACI,WAAW;IACX,WAAW;;AAEf;;AAEA;IACI,uBAAuB;IACvB,UAAU;IACV,aAAa;IACb,aAAa;IACb,mBAAmB;AACvB;AACA;IACI,eAAe;IACf,iBAAiB;IACjB,iBAAiB;AACrB;AACA;IACI,UAAU;IACV,cAAc;IACd,YAAY;IACZ,eAAe;IACf,iBAAiB;IACjB,yBAAyB;IACzB,YAAY;IACZ,gBAAgB;IAChB,mBAAmB;AACvB","sourcesContent":[".ProfilePicture-fullPage{\n    width: 65rem;\n   \n}\n.preview-div{\n    height: 8rem;\n    align-items: center;\n    justify-items: center;\n}\n\n.preview-image{\n    height: 150px;\n}\n.input-div{\n    height: 2rem;\n    width:42.5%;\n    border: 1px solid black;\n\n}\n\n.file-input{\n    width: 100%;\n    height:2rem;\n\n}\n\n.Preview{\n    border: 1px solid black;\n    width: 40%;\n    height: 10rem;\n    padding: 1rem;\n    margin-bottom: 1rem;\n}\n.preview-heading{\n    font-size: 16px;\n    font-weight: bold;\n    line-height: 40px;\n}\n.save-btn{\n    width: 20%;\n    height: 2.5rem;\n    border: none;\n    font-size: 14px;\n    font-weight: bold;\n    background-color: #020062;\n    color: white;\n    margin-top: 1rem;\n    margin-bottom: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ProfilePicture-fullPage": `ProfilePicture_ProfilePicture-fullPage__O5w1T`,
	"preview-div": `ProfilePicture_preview-div__Ixxcz`,
	"preview-image": `ProfilePicture_preview-image__AqlvS`,
	"input-div": `ProfilePicture_input-div__ts4OI`,
	"file-input": `ProfilePicture_file-input__tmV7h`,
	"Preview": `ProfilePicture_Preview__lVNJs`,
	"preview-heading": `ProfilePicture_preview-heading__Fw9Dh`,
	"save-btn": `ProfilePicture_save-btn__8EzhU`
};
export default ___CSS_LOADER_EXPORT___;
