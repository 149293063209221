// DataTransferContext.js
import React, { createContext, useState } from "react";

const DataTransferContext = createContext();

export const DataTransferProvider = ({ children }) => {
  const [data, setData] = useState(false);

  return (
    <DataTransferContext.Provider value={{ data, setData }}>
      {children}
    </DataTransferContext.Provider>
  );
};

export default DataTransferContext;
