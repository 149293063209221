import React, { useState, useEffect } from "react";
import styles from "./UpcomingCourse.module.css";
import { AiFillLeftCircle, AiFillRightCircle } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

export default function RecLunchedCourse({ category, subject }) {
  const navigate = useNavigate();
  const [courses, setCourses] = useState([]);
  const [visibleCourses, setVisibleCourses] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(true);

  const coursesPerPage = 4;

  useEffect(() => {
    // Construct query parameters based on category and subject
    const queryParams = [];
    if (category) queryParams.push(`category=${category}`);
    if (subject && subject.length > 0)
      queryParams.push(`subject=${subject.join(",")}`);

    const queryString = queryParams.length ? `?${queryParams.join("&")}` : "";

    // Build the API URL
    const url = `https://auth.ssccglpinnacle.com/rc/courses${queryString}`;

    setLoading(true);

    // Fetch data from the API
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        setCourses(Array.isArray(data) ? data : []);
        setLoading(false);
        setCurrentPage(0); // Reset to first page whenever data is fetched
      })
      .catch((error) => {
        console.error("Error fetching courses:", error);
        setLoading(false);
      });
  }, [category, subject]);

  // Handle pagination to display a limited number of courses
  useEffect(() => {
    const startIndex = currentPage * coursesPerPage;
    const endIndex = startIndex + coursesPerPage;
    setVisibleCourses(courses.slice(startIndex, endIndex));
  }, [currentPage, courses]);

  // Scroll through the courses (pagination)
  const handleScroll = (direction) => {
    const maxPages = Math.ceil(courses.length / coursesPerPage) - 1;
    if (direction === "next" && currentPage < maxPages) {
      setCurrentPage(currentPage + 1);
    } else if (direction === "prev" && currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Navigate to view all courses
  const handleViewAll = () => {
    navigate("/view-all-courses", { state: { category, subject } });
  };

  const isFirstPage = currentPage === 0;
  const isLastPage = (currentPage + 1) * coursesPerPage >= courses.length;

  return (
    <div className={styles["UpcomingCourse-firstDiv"]}>
      <div className={styles.forbutton_div}>
        <button
          onClick={() => handleScroll("prev")}
          disabled={isFirstPage}
          className={`${styles["sliderButton-left"]} ${
            isFirstPage ? styles.disabled : ""
          }`}
        >
          <AiFillLeftCircle size={30} />
        </button>

        {/* <div className={styles.Course_Heading}>
          Recently Launched Courses {category ? `for ${category}` : ''}
        </div> */}

        <div className={styles.Course_Heading}>
          Recently Launched Courses
          {category ? ` for ${category}` : ""}
          {subject && subject.length > 0 ? ` - ${subject.join(", ")}` : ""}
          <div className={styles.viewAllButtonDiv}>
            <button onClick={handleViewAll} className={styles.viewAllButton}>
              VIEW ALL
            </button>
          </div>
        </div>

        <div className={styles["main-card-wrapper"]}>
          <div className={styles["scroll-container"]}>
            <div className={styles["card-wrapper"]}>
              {loading ? (
                <div>
                  <img
                    src="https://d3m4h509gttb94.cloudfront.net/Image/Bubble-Preloader.gif"
                    alt="Loading..."
                  />
                </div>
              ) : (
                visibleCourses.map((course) => (
                  <div
                    key={course._id}
                    className={styles["card"]}
                    onClick={() =>
                      navigate(`/course/description/${course._id}`)
                    }
                  >
                    <div className={styles["Image-section"]}>
                      <img
                        className={styles.imagecard}
                        src={course.hindiCoverImage}
                        alt="Hindi Cover"
                      />
                      <img
                        className={styles.imagecard}
                        src={course.englishCoverImage}
                        alt="English Cover"
                      />
                    </div>
                    <div className={styles["description-section"]}>
                      <div className={styles["title"]}>
                        {course.courseTitle}
                      </div>
                      <div className={styles["gst"]}>18% GST included</div>
                      <div className={styles["price-fprice-div"]}>
                        <div className={styles["price"]}>₹ {course.price}</div>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <div className={styles["mrp"]}>
                          <s>₹{course.mrp}</s>
                        </div>
                        <div className={styles["savings"]}>
                          Save
                          {Math.round(
                            ((course.mrp - course.price) / course.mrp) * 100
                          )}
                          %
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>

        <button
          onClick={() => handleScroll("next")}
          disabled={isLastPage}
          className={`${styles["sliderButton-right"]} ${
            isLastPage ? styles.disabled : ""
          }`}
        >
          <AiFillRightCircle size={30} />
        </button>
      </div>
    </div>
  );
}
