// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Communication_Commmunication-fullPage__AE9sJ{
    display: flex;
    margin: 1rem;
}

.Communication_Commmunication-container1__GVqH5{
    width: 10%;
    height: 25.25rem;
    padding: 1rem;
    display: flex;
}

.Communication_container1-box1__cwGzF{
}
.Communication_container1-box2__0MQOb{
    padding:1rem;

}
.Communication_heading__8TEJZ{
    font-size: 16px;
    line-height: 40px;
}
.Communication_heading__8TEJZ:hover{
    font-weight: bold;
    cursor: pointer;
}

.Communication_Commmunication-container2__0JorU{
    width: 90%;
    padding: 1rem;
}

.Communication_container2-box1__OG6kZ{}
.Communication_container2-box2__99qsD{
    padding: 0.5rem;
}


.Communication_Profile-header__o53wx{
    display: flex;
    justify-content:right;
    gap:1.5rem;
    padding: 0.5rem;
}

.Communication_Profile-small-image__3FOMJ{
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    background-color:black;
    color: white;
    text-align: center;
    justify-content: center;
}
.Communication_Profile-small-text__SvYpu{
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/Profile/Communication.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;AAChB;;AAEA;IACI,UAAU;IACV,gBAAgB;IAChB,aAAa;IACb,aAAa;AACjB;;AAEA;AACA;AACA;IACI,YAAY;;AAEhB;AACA;IACI,eAAe;IACf,iBAAiB;AACrB;AACA;IACI,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,UAAU;IACV,aAAa;AACjB;;AAEA,sCAAiB;AACjB;IACI,eAAe;AACnB;;;AAGA;IACI,aAAa;IACb,qBAAqB;IACrB,UAAU;IACV,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,cAAc;IACd,kBAAkB;IAClB,sBAAsB;IACtB,YAAY;IACZ,kBAAkB;IAClB,uBAAuB;AAC3B;AACA;IACI,kBAAkB;AACtB","sourcesContent":[".Commmunication-fullPage{\n    display: flex;\n    margin: 1rem;\n}\n\n.Commmunication-container1{\n    width: 10%;\n    height: 25.25rem;\n    padding: 1rem;\n    display: flex;\n}\n\n.container1-box1{\n}\n.container1-box2{\n    padding:1rem;\n\n}\n.heading{\n    font-size: 16px;\n    line-height: 40px;\n}\n.heading:hover{\n    font-weight: bold;\n    cursor: pointer;\n}\n\n.Commmunication-container2{\n    width: 90%;\n    padding: 1rem;\n}\n\n.container2-box1{}\n.container2-box2{\n    padding: 0.5rem;\n}\n\n\n.Profile-header{\n    display: flex;\n    justify-content:right;\n    gap:1.5rem;\n    padding: 0.5rem;\n}\n\n.Profile-small-image{\n    width: 2.5rem;\n    height: 2.5rem;\n    border-radius: 50%;\n    background-color:black;\n    color: white;\n    text-align: center;\n    justify-content: center;\n}\n.Profile-small-text{\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Commmunication-fullPage": `Communication_Commmunication-fullPage__AE9sJ`,
	"Commmunication-container1": `Communication_Commmunication-container1__GVqH5`,
	"container1-box1": `Communication_container1-box1__cwGzF`,
	"container1-box2": `Communication_container1-box2__0MQOb`,
	"heading": `Communication_heading__8TEJZ`,
	"Commmunication-container2": `Communication_Commmunication-container2__0JorU`,
	"container2-box1": `Communication_container2-box1__OG6kZ`,
	"container2-box2": `Communication_container2-box2__99qsD`,
	"Profile-header": `Communication_Profile-header__o53wx`,
	"Profile-small-image": `Communication_Profile-small-image__3FOMJ`,
	"Profile-small-text": `Communication_Profile-small-text__SvYpu`
};
export default ___CSS_LOADER_EXPORT___;
