import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './AnnouncementList.module.css';

const AnnouncementList = () => {
  const [courses, setCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState('');
  const [announcements, setAnnouncements] = useState([]);
  const [editingAnnouncement, setEditingAnnouncement] = useState(null);

  useEffect(() => {
    // Fetch all courses
    axios.get('https://auth.ssccglpinnacle.com/api/courses')
      .then(response => setCourses(response.data))
      .catch(error => console.error('Error fetching courses:', error));
  }, []);

  useEffect(() => {
    if (selectedCourse) {
      // Fetch announcements for the selected course
      axios.get(`https://auth.ssccglpinnacle.com/api/announcements/${selectedCourse}`)
        .then(response => setAnnouncements(response.data.announcements))
        .catch(error => console.error('Error fetching announcements:', error));
    }
  }, [selectedCourse]);

  const handleStatusChange = async (announcementId, isActive) => {
    try {
      await axios.put(`https://auth.ssccglpinnacle.com/api/announcements/${announcementId}`, { isActive });
      // Update the announcements list with the new status
      setAnnouncements(announcements.map(announcement => 
        announcement._id === announcementId ? { ...announcement, isActive } : announcement
      ));
    } catch (error) {
      console.error('Error updating announcement status:', error);
    }
  };

  const handleDeleteAnnouncement = async (announcementId) => {
    try {
      await axios.delete(`https://auth.ssccglpinnacle.com/api/announcements/${announcementId}`);
      setAnnouncements(announcements.filter(announcement => announcement._id !== announcementId));
    } catch (error) {
      console.error('Error deleting announcement:', error);
    }
  };

  const handleEditAnnouncement = (announcement) => {
    setEditingAnnouncement(announcement);
  };

  return (
    <>
     <h2 className={styles.heading}>Announcement Listing</h2>
    <div className={styles.announcementListContainer}>
     
      <div className={styles.formGroup}>
        <label htmlFor="course">Select Course</label>
        <select
          id="course"
          value={selectedCourse}
          onChange={(e) => setSelectedCourse(e.target.value)}
        >
          <option value="">Select a course</option>
          {courses.map(course => (
            <option key={course._id} value={course._id}>{course.courseTitle}</option>
          ))}
        </select>
      </div>

      <div className={styles.announcements}>
        {announcements.map(announcement => (
          <div key={announcement._id} className={styles.announcement}>
            <h3>{announcement.title}</h3>
            <div dangerouslySetInnerHTML={{ __html: announcement.content }} />
            <div className={styles.announcementDetails}>
              <img src={announcement.instructorImage} alt={announcement.instructorName} className={styles.instructorImage} />
              <div>{announcement.instructorName}</div>
              <div>
                <label>Status:</label>
                <select
                  value={announcement.isActive}
                  onChange={(e) => handleStatusChange(announcement._id, e.target.value === 'true')}
                >
                  <option value="true">Active</option>
                  <option value="false">Inactive</option>
                </select>
              </div>
              <button 
                className={styles.editButton}
                onClick={() => handleEditAnnouncement(announcement)}
              >
                Edit
              </button>
              <button 
                className={styles.deleteButton}
                onClick={() => handleDeleteAnnouncement(announcement._id)}
              >
                Delete
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
    </>
  );
};

export default AnnouncementList;



// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import styles from './AnnouncementList.module.css';

// const AnnouncementList = () => {
//   const [courses, setCourses] = useState([]);
//   const [selectedCourse, setSelectedCourse] = useState('');
//   const [announcements, setAnnouncements] = useState([]);

//   useEffect(() => {
//     // Fetch all courses
//     axios.get('https://auth.ssccglpinnacle.com/api/courses')
//       .then(response => setCourses(response.data))
//       .catch(error => console.error('Error fetching courses:', error));
//   }, []);

//   useEffect(() => {
//     if (selectedCourse) {
//       // Fetch announcements for the selected course
//       axios.get(`https://auth.ssccglpinnacle.com/api/announcements/${selectedCourse}`)
//         .then(response => setAnnouncements(response.data.announcements))
//         .catch(error => console.error('Error fetching announcements:', error));
//     }
//   }, [selectedCourse]);

//   const handleStatusChange = async (announcementId, isActive) => {
//     try {
//       await axios.put(`https://auth.ssccglpinnacle.com/api/announcements/${announcementId}`, { isActive });
//       // Update the announcements list with the new status
//       setAnnouncements(announcements.map(announcement => 
//         announcement._id === announcementId ? { ...announcement, isActive } : announcement
//       ));
//     } catch (error) {
//       console.error('Error updating announcement status:', error);
//     }
//   };

//   return (
//     <div className={styles.announcementListContainer}>
//       <h2>Announcements</h2>
//       <div className={styles.formGroup}>
//         <label htmlFor="course">Select Course</label>
//         <select
//           id="course"
//           value={selectedCourse}
//           onChange={(e) => setSelectedCourse(e.target.value)}
//         >
//           <option value="">Select a course</option>
//           {courses.map(course => (
//             <option key={course._id} value={course._id}>{course.courseTitle}</option>
//           ))}
//         </select>
//       </div>

//       <div className={styles.announcements}>
//         {announcements.map(announcement => (
//           <div key={announcement._id} className={styles.announcement}>
//             <h3>{announcement.title}</h3>
//             <div dangerouslySetInnerHTML={{ __html: announcement.content }} />
//             <div className={styles.announcementDetails}>
//               <img src={announcement.instructorImage} alt={announcement.instructorName} className={styles.instructorImage} />
//               <div>{announcement.instructorName}</div>
//               <div>
//                 <label>Status:</label>
//                 <select
//                   value={announcement.isActive}
//                   onChange={(e) => handleStatusChange(announcement._id, e.target.value === 'true')}
//                 >
//                   <option value="true">Active</option>
//                   <option value="false">Inactive</option>
//                 </select>
//               </div>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default AnnouncementList;
