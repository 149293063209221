import React, { useEffect, useState } from "react";
import "./Rate.css";
import { MdStarRate } from "react-icons/md";
import Progressbar from "./Progressbar";

const Rateab = ({ cardData }) => {
  const [averageRating, setAverageRating] = useState(0);
  const [ratingsCount, setRatingsCount] = useState({});
  


  useEffect(() => {

    if (!cardData || !cardData.rating) {
      // Handle the case when cardData or cardData.rating is undefined
      return;
    }
    // Calculate ratings counts dynamically
    const counts = {};
    cardData.rating.forEach((rating) => {
      if (counts[`count${rating.starRate}`]) {
        counts[`count${rating.starRate}`]++;
      } else {
        counts[`count${rating.starRate}`] = 1;
      }
    });

    setRatingsCount(counts);

    // Calculate average rating
    const totalRating = cardData.rating.reduce((sum, rating) => sum + rating.starRate, 0);
    const average = cardData.rating.length > 0 ? totalRating / cardData.rating.length : 0;
    setAverageRating(average);
  }, [cardData.rating]);

  return (
    <div className="forStar">
      <div className="a">
        <div className="container-for-rating">
          <div className="cards-for-star-rating">
            <div className="card-content-for-star-rating">
              <h2>{averageRating.toFixed(1)}</h2>
              <div className="For-Star">
                <MdStarRate className="Star-Rating-average" fontSize="1.5em" />
              </div>
            </div>
            <div className="Total-rating">({cardData.rating ? cardData.rating.length : 0}) Ratings</div>
          </div>
          <div className="cards-for-star-rating-2">
            <Progressbar
              C1={ratingsCount.count1 || 0}
              C2={ratingsCount.count2 || 0}
              C3={ratingsCount.count3 || 0}
              C4={ratingsCount.count4 || 0}
              C5={ratingsCount.count5 || 0}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rateab;
