// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Announcements_announce_container__07OVg {
  width: 100%;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.Announcements_announce_section__VgA9X {
  display: flex;
  margin-bottom: 20px;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.Announcements_ins_image__DMSxQ {
  margin-right: 15px;
}

.Announcements_instructor_image__BLV5u {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.Announcements_ins_details__\\+ZGpW {
  flex: 1 1;
}

.Announcements_ins_name__YLw5T {
  font-weight: bold;
  margin-bottom: 5px;
}

.Announcements_ins__EAWC\\+ {
  color: #888;
  font-size: 14px;
  margin-bottom: 10px;
}

.Announcements_heading__H6xfU {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
}

.Announcements_content__5hU\\+c {
  font-size: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/YOUTUBE VIDEO PLAYER/YT_Navigation Components/Announcements/Announcements.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,yBAAyB;EACzB,kBAAkB;EAClB,uCAAuC;AACzC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,aAAa;EACb,6BAA6B;AAC/B;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,SAAO;AACT;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".announce_container {\n  width: 100%;\n  padding: 20px;\n  background-color: #f9f9f9;\n  border-radius: 8px;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n}\n\n.announce_section {\n  display: flex;\n  margin-bottom: 20px;\n  padding: 10px;\n  border-bottom: 1px solid #ddd;\n}\n\n.ins_image {\n  margin-right: 15px;\n}\n\n.instructor_image {\n  width: 50px;\n  height: 50px;\n  border-radius: 50%;\n}\n\n.ins_details {\n  flex: 1;\n}\n\n.ins_name {\n  font-weight: bold;\n  margin-bottom: 5px;\n}\n\n.ins {\n  color: #888;\n  font-size: 14px;\n  margin-bottom: 10px;\n}\n\n.heading {\n  font-size: 18px;\n  font-weight: bold;\n  margin-bottom: 5px;\n}\n\n.content {\n  font-size: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"announce_container": `Announcements_announce_container__07OVg`,
	"announce_section": `Announcements_announce_section__VgA9X`,
	"ins_image": `Announcements_ins_image__DMSxQ`,
	"instructor_image": `Announcements_instructor_image__BLV5u`,
	"ins_details": `Announcements_ins_details__+ZGpW`,
	"ins_name": `Announcements_ins_name__YLw5T`,
	"ins": `Announcements_ins__EAWC+`,
	"heading": `Announcements_heading__H6xfU`,
	"content": `Announcements_content__5hU+c`
};
export default ___CSS_LOADER_EXPORT___;
