import React, { useState, useEffect } from 'react';
import './ChapterForm.css';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import Select from "react-select";

const ChapterForm = () => {
  const [ebookId, setEbookId] = useState('');
  const [ebookData, setEbookData] = useState([]);
  const [chapterTitle, setChapterTitle] = useState('');
  const [chapterSequence, setChapterSequence] = useState('');
  const [sku, setSku] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedEbook, setSelectedEbook] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchEbookData = async () => {
      try {
        const response = await fetch('https://auth.ssccglpinnacle.com/api/ebooks');

        if (response.ok) {
          const ebooks = await response.json();
          setEbookData(ebooks);
        } else {
          console.error('Failed to fetch eBook data');
        }
      } catch (error) {
        console.error('Error fetching eBook data:', error);
      }
    };

    fetchEbookData();
  }, []);

  const handleSkuChange = (selectedSku) => {
    const selectedEbook = ebookData.find(ebook => ebook.sku === selectedSku);

    if (selectedEbook) {
      setSku(selectedSku);
      setSelectedEbook(selectedEbook);
      setChapterTitle(chapterTitle); // Keep chapter title unchanged
      setChapterSequence(chapterSequence);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    setLoading(true);
  
    const requestData = {
      ebookId: selectedEbook._id,
      title: chapterTitle,
      sequence: chapterSequence,
    };
  
    try {
      const response = await fetch('https://auth.ssccglpinnacle.com/api/chapters-ebook', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      });
  
      const responseBody = await response.json();
  
      if (response.ok) {
        // Show success message using SweetAlert2
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Chapter data inserted successfully!',
        });
  
        console.log('Chapter data inserted successfully');
        setEbookId('');
        setChapterTitle('');
        setChapterSequence('');
        setSelectedEbook(null);
      } else if (responseBody.error && responseBody.error.includes('Chapter title is required')) {
        // Show error message for required chapter title using SweetAlert2
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Chapter title is required',
        });
  
        console.error('Chapter title is required');
      } else if (responseBody.error && responseBody.error.includes('Chapter title already exists')) {
        // Show error message for existing chapter title using SweetAlert2
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Chapter title already exists',
        });
  
        console.error('Chapter title already exists');
      } else if (responseBody.error && responseBody.error.includes('Chapter with the same sequence number already exists')) {
        // Show error message for existing sequence number using SweetAlert2
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Chapter with the same sequence number already exists for this ebook',
        });
  
        console.error('Chapter with the same sequence number already exists');
      } else {
        // Show a generic error message using SweetAlert2
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'An error occurred while inserting chapter data. Please try again later.',
        });
  
        console.error('Error inserting chapter data');
      }
    } catch (error) {
      // Show a generic error message using SweetAlert2
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred while inserting chapter data. Please try again later.',
      });
  
      console.error('Error inserting chapter data:', error);
    } finally {
      setLoading(false);
    }
  };
  
  
  
  return (
    <>
    
      <h1 className="add-ebook-form">Add chapter data</h1>
      <div className="chapter-form">
        <form onSubmit={handleSubmit}>
          <br />
         
          <label htmlFor="sku" className="label">
            SKU:
          </label>
          <Select
  id="sku"
  value={{ label: sku, value: sku }}  // Use the correct format for value
  onChange={(option) => {
    setSku(option.value);
    handleSkuChange(option.value);
  }}
  options={ebookData.slice().reverse().map((ebook) => ({
    label: ebook.sku,
    value: ebook.sku,
  }))}  // Reverse the ebookData array
  placeholder="Select SKU"
  className="input"
/>

          <br />

          {selectedEbook && (
            <>
              <label className="label">Ebook Title:</label>
              <input
                type="text"
                value={selectedEbook.title}
                readOnly
                className="input"
              />
              <br/>
              <label className="label">Edition:</label>
              <input
                type="text"
                value={selectedEbook.edition}
                readOnly
                className="input"
              />
              <br/>
              <label className="label">Medium:</label>
              <input
                type="text"
                value={selectedEbook.medium}
                readOnly
                className="input"
              />
              <br />
              <label htmlFor="chapterTitle" className="label">
                Chapter Title:
              </label>
              <input
                type="text"
                id="chapterTitle"
                value={chapterTitle}
                onChange={(e) => setChapterTitle(e.target.value)}
                required
                className="input"
              />

<br />
              <label htmlFor="chapterTitle" className="label">
                Chapter Sequence:
              </label>
              <input
                type="text"
                id="chapterTitle"
                value={chapterSequence}
                onChange={(e) => setChapterSequence(e.target.value)}
                className="input"
              />
              <br />
              <br />
              <button type="submit" className="submit-button">
                Submit
              </button>
            </>
          )}
        </form>
      </div>
    </>
  );
};

export default ChapterForm;
