import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Star from "./Star";
import Rate from "./Rate";
import { IoMdSearch } from "react-icons/io";
import "./styles.css";

const Review = () => {
  const { courseId } = useParams();
  const [courseData, setCourseData] = useState(null); // Initialize with null
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCourseData = async () => {
      try {
        const response = await fetch(
          `https://auth.ssccglpinnacle.com/course/${courseId}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch course data");
        }
        const data = await response.json();
        setCourseData(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCourseData();
  }, [courseId]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  // Ensure courseData is available before accessing its properties
  if (!courseData) return <div>No course data available</div>;

  // Function to create a simulated ratingCounts array
  const createRatingCounts = (averageRating, totalRatings) => {
    if (!averageRating || !totalRatings) return [0, 0, 0, 0, 0]; // Default to an empty array if inputs are invalid

    const counts = [0, 0, 0, 0, 0];

    // Total number of full stars represented by the average rating
    let fullStars = Math.floor(averageRating);
    let remainder = averageRating - fullStars;

    // Distribute the ratings up to full stars
    for (let i = 0; i < fullStars; i++) {
      counts[i] = Math.floor(totalRatings / (5 - i));
    }

    // Allocate remaining ratings proportionally
    if (remainder > 0) {
      counts[fullStars] = Math.ceil(
        (totalRatings * remainder) / (5 - fullStars)
      );
    }

    // Calculate any remaining ratings to distribute
    const distributedTotal = counts.reduce((sum, count) => sum + count, 0);
    let remaining = totalRatings - distributedTotal;

    // Distribute remaining ratings starting from the highest rating
    for (let i = 4; i >= 0 && remaining > 0; i--) {
      counts[i] += 1;
      remaining -= 1;
    }

    return counts;
  };

  const ratingCounts = createRatingCounts(
    courseData?.averageRating,
    courseData?.totalRatings
  ) || [0, 0, 0, 0, 0];

  return (
    <div className="rfpage">
      <div className="heding">
        Student feedback for {courseData.courseTitle}
      </div>
      {/* <Star averageRating={courseData.averageRating} totalRatings={courseData.totalRatings} /> */}
      <Rate
        averageRating={courseData.averageRating}
        totalRatings={courseData.totalRatings}
        ratingCounts={ratingCounts}
      />
      <div className="reviewHeading">Review</div>
      <div className="filterrationgHeading">Filter Ratings</div>
      <div>
        <input className="filter-search" type="text" />
        <button className="searchBtn">
          <IoMdSearch size={20} />
        </button>
        <select defaultValue="All Ratings" className="rating-filter">
          <option>All Ratings</option>
          <option>Five Stars</option>
          <option>Four Stars</option>
          <option>Three Stars</option>
          <option>Two Stars</option>
          <option>One Star</option>
        </select>
      </div>
    </div>
  );
};

export default Review;
