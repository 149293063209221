// ProductContext.js
import React, { createContext, useContext, useState } from 'react';

const ProductContext = createContext();

export const ProductProvider = ({ children }) => {
  const [productData, setProductData] = useState({});

  const saveProductDataToServer = async (data) => {
    try {
      // Save data to the server
      const response = await fetch('http://13.200.156.92:6005/api/productDatass', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error(`Failed to save data to the server. Status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error saving data to the server:', error);
    }
  };

  const setProductContext = (data) => {
    setProductData(data);
    // Save data to the server when it is updated
    saveProductDataToServer(data);
  };
  
  return (
    <ProductContext.Provider value={{ productData, setProductContext }}>
      {children}
    </ProductContext.Provider>
  );
};

export const useProductContext = () => {
  const context = useContext(ProductContext);
  if (!context) {
    throw new Error('useProductContext must be used within a ProductProvider');
  }
  return context;
};
