// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `header {
}
.SignUpHelp_unordered_list__fjZBo {
  border-bottom: rgba(0, 0, 0, 0.02);
  display: flex;
  justify-content: space-evenly;
  font-size: 14px;
}
.SignUpHelp_header_img__JKGPg {
  width: 8rem;
  font-size: 10px;
  margin-left: 25%;
}
.SignUpHelp_signup_heading__XKq3H {
  font-weight: bolder;
  padding-top: 1%;
  margin-left: 1%;
}
.SignUpHelp_signin_btn__8zPKM {
  color: white;
  background-color: black;
  padding: 2%;
  width: 3rem;
  margin-top: 1%;
}
.SignUpHelp_signup_container__52kNt {
  display: flex;
  margin: 2%;
  background-color: rgb(159, 212, 254, 0.5);
}
.SignUpHelp_signup_content1__xkc7w,
.SignUpHelp_signup_content2__IFaIR,
.SignUpHelp_signup_content3__btOHC {
  margin: 3% 0% 0% 3%;
}
.SignUpHelp_signup_heading__XKq3H,
.SignUpHelp_signup_heading2__LWMJj,
.SignUpHelp_signup_heading3__y7aGw,
.SignUpHelp_signup_heading4__dfi8N {
  margin-bottom: 1%;
}
.SignUpHelp_signup_main_container__Lhcy8 {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  width: 90%;
  margin: 5%;
  padding: 2%;
}
.SignUpHelp_signup_content2__IFaIR {
  padding-bottom: 8%;
}
.SignUpHelp_signup_main_container__Lhcy8 li {
  list-style-type: lower-alpha;
}
`, "",{"version":3,"sources":["webpack://./src/VIDEOPLAYER COMPONENTS/components/VideoPlayerPage/AccountPage/Messages/SignUpHelp.module.css"],"names":[],"mappings":"AAAA;AACA;AACA;EACE,kCAAkC;EAClC,aAAa;EACb,6BAA6B;EAC7B,eAAe;AACjB;AACA;EACE,WAAW;EACX,eAAe;EACf,gBAAgB;AAClB;AACA;EACE,mBAAmB;EACnB,eAAe;EACf,eAAe;AACjB;AACA;EACE,YAAY;EACZ,uBAAuB;EACvB,WAAW;EACX,WAAW;EACX,cAAc;AAChB;AACA;EACE,aAAa;EACb,UAAU;EACV,yCAAyC;AAC3C;AACA;;;EAGE,mBAAmB;AACrB;AACA;;;;EAIE,iBAAiB;AACnB;AACA;EACE;wCACsC;EACtC,UAAU;EACV,UAAU;EACV,WAAW;AACb;AACA;EACE,kBAAkB;AACpB;AACA;EACE,4BAA4B;AAC9B","sourcesContent":["header {\n}\n.unordered_list {\n  border-bottom: rgba(0, 0, 0, 0.02);\n  display: flex;\n  justify-content: space-evenly;\n  font-size: 14px;\n}\n.header_img {\n  width: 8rem;\n  font-size: 10px;\n  margin-left: 25%;\n}\n.signup_heading {\n  font-weight: bolder;\n  padding-top: 1%;\n  margin-left: 1%;\n}\n.signin_btn {\n  color: white;\n  background-color: black;\n  padding: 2%;\n  width: 3rem;\n  margin-top: 1%;\n}\n.signup_container {\n  display: flex;\n  margin: 2%;\n  background-color: rgb(159, 212, 254, 0.5);\n}\n.signup_content1,\n.signup_content2,\n.signup_content3 {\n  margin: 3% 0% 0% 3%;\n}\n.signup_heading,\n.signup_heading2,\n.signup_heading3,\n.signup_heading4 {\n  margin-bottom: 1%;\n}\n.signup_main_container {\n  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,\n    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;\n  width: 90%;\n  margin: 5%;\n  padding: 2%;\n}\n.signup_content2 {\n  padding-bottom: 8%;\n}\n.signup_main_container li {\n  list-style-type: lower-alpha;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"unordered_list": `SignUpHelp_unordered_list__fjZBo`,
	"header_img": `SignUpHelp_header_img__JKGPg`,
	"signup_heading": `SignUpHelp_signup_heading__XKq3H`,
	"signin_btn": `SignUpHelp_signin_btn__8zPKM`,
	"signup_container": `SignUpHelp_signup_container__52kNt`,
	"signup_content1": `SignUpHelp_signup_content1__xkc7w`,
	"signup_content2": `SignUpHelp_signup_content2__IFaIR`,
	"signup_content3": `SignUpHelp_signup_content3__btOHC`,
	"signup_heading2": `SignUpHelp_signup_heading2__LWMJj`,
	"signup_heading3": `SignUpHelp_signup_heading3__y7aGw`,
	"signup_heading4": `SignUpHelp_signup_heading4__dfi8N`,
	"signup_main_container": `SignUpHelp_signup_main_container__Lhcy8`
};
export default ___CSS_LOADER_EXPORT___;
