const scienceQuizData = [
  {
    question: "What is the largest planet in our solar system?",
    options: ["Mars", "Saturn", "Jupiter", "Neptune"],
    answerIndex: 2
  },
  {
    question:
      "What is the process by which plants convert sunlight into energy?",
    options: [
      "Photosynthesis",
      "Respiration",
      "Transpiration",
      "Fertilization"
    ],
    answerIndex: 0
  },
  {
    question: "Which organ in the human body is responsible for pumping blood?",
    options: ["Liver", "Lungs", "Heart", "Kidneys"],
    answerIndex: 2
  },
  {
    question: "What is the unit of electric current?",
    options: ["Volt", "Watt", "Ampere", "Ohm"],
    answerIndex: 2
  },
  {
    question: "Which of the following is a renewable source of energy?",
    options: ["Coal", "Natural Gas", "Solar", "Nuclear"],
    answerIndex: 2
  }
];

export default scienceQuizData;
