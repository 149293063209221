import React, { useState, useEffect } from 'react';
import axios from 'axios';
import VideoContentEditor from './VideoContentEditor'; 
import styles from './AddAnnouncement.module.css';

const AddAnnouncementForm = () => {
  const [courses, setCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState('');
  const [instructor, setInstructor] = useState({});
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [loading, setLoading] = useState(false);
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    // Fetch all courses
    axios.get('https://auth.ssccglpinnacle.com/api/courses')
      .then(response => setCourses(response.data))
      .catch(error => console.error('Error fetching courses:', error));
  }, []);

  useEffect(() => {
    if (selectedCourse) {
      // Fetch instructor details based on selected course
      const course = courses.find(c => c._id === selectedCourse);
      if (course) {
        axios.get(`https://auth.ssccglpinnacle.com/instructors/${encodeURIComponent(course.instructorName)}`)
          .then(response => setInstructor(response.data.instructor))
          .catch(error => console.error('Error fetching instructor:', error));
      }
    }
  }, [selectedCourse, courses]);

  const handlePostAnnouncement = async () => {
    setLoading(true);
    try {
      await axios.post('https://auth.ssccglpinnacle.com/api/announcements', {
        courseId: selectedCourse,
        title,
        content,
        instructorName: instructor.instructorName,
        instructorImage: instructor.imageUrl,
        postedBy: instructor.instructorName, 
        isActive,
      });
      alert('Announcement posted successfully!');
    } catch (error) {
      console.error('Error posting announcement:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
    <h2 className={styles.heading}>Post an Announcement</h2>
    <div className={styles.announcementFormContainer}>
      {/* <h2>Post an Announcement</h2> */}
      <div className={styles.formGroup}>
        <label htmlFor="course">Select Course</label>
        <select
          id="course"
          value={selectedCourse}
          onChange={(e) => setSelectedCourse(e.target.value)}
        >
          <option value="">Select a course</option>
          {courses.map(course => (
            <option key={course._id} value={course._id}>{course.courseTitle}</option>
          ))}
        </select>
      </div>
      
      {instructor && instructor.instructorName && (
        <div className={styles.instructorDetails}>
          <img src={instructor.imageUrl} alt={instructor.instructorName} className={styles.instructorImage} />
          <div>{instructor.instructorName}</div>
        </div>
      )}

      <div className={styles.formGroup}>
        <label htmlFor="title">Title</label>
        <input
          type="text"
          id="title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </div>
      <div className={styles.formGroup}>
        <label htmlFor="content">Content</label>
        <VideoContentEditor content={content} onContentChange={setContent} />
      </div>
      <div className={styles.formGroup}>
        <label htmlFor="status">Status</label>
        <select
          id="status"
          value={isActive}
          onChange={(e) => setIsActive(e.target.value === 'true')}
        >
          <option value="true">Active</option>
          <option value="false">Inactive</option>
        </select>
      </div>
     
      <button className={styles.postButton} onClick={handlePostAnnouncement}>Post Announcement</button>
    </div>
    </>
  );
};

export default AddAnnouncementForm;
