import React, { useEffect, useState } from 'react';
import { FaArrowRight } from 'react-icons/fa';
 // Ensure you have this CSS file
// import ssc from "../"
import UP from "../../../Ebook/i/Up.png"

const NextPageUp = () => {
  return (
    <>
              <div className="container-fluid-blog1">
        <div className="blog-row1">
          <div className="content-left1">
            <div className='content-left1-text-block'>
              <h1>Up police</h1>
              <p>Prepare thoroughly for UP Police exams and serve with honor and pride.</p>
            </div>
          </div>
          <div className="content-right1">
            <img src={UP} alt="Placeholder" />
          </div>
        </div>
      </div>
    </>
  );
};

export default NextPageUp;
