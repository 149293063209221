// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .container1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0;
  width: 294%;
  position: relative;
  margin-left: 5%;
}
.input {
  background: #fcf6f6;
}
#input-label {
  display: none;
}

.input-wrap {
  padding: 5px 1rem 5px 1rem;
  border-radius: 2rem;
  background: #f1e9e9;
  box-sizing: border-box;
  width: 100%;
  border: 1px solid black;
  display: flex;
  align-items: center;
}

.input_wrap i {
  font-size: 1rem;
}

#product-search {
  font-size: 0.9rem;
  background: #f1e9e9 !important;
  color: black;
  border: none;
  outline: none;
  padding: 10px;
  border-radius: 2rem;
  width: 95%;
  margin-left: 13px;
}

#product-search::placeholder {
  color: rgb(44, 43, 43);
  opacity: 0.7;
  text-align: left;
  background: #f1e9e9;
}

.fa-times:hover {
  cursor: pointer;
} */
`, "",{"version":3,"sources":["webpack://./src/VIDEOPLAYER COMPONENTS/components/VideoHeader/SearchBar.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;GAqDG","sourcesContent":["/* .container1 {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  margin: 0;\n  width: 294%;\n  position: relative;\n  margin-left: 5%;\n}\n.input {\n  background: #fcf6f6;\n}\n#input-label {\n  display: none;\n}\n\n.input-wrap {\n  padding: 5px 1rem 5px 1rem;\n  border-radius: 2rem;\n  background: #f1e9e9;\n  box-sizing: border-box;\n  width: 100%;\n  border: 1px solid black;\n  display: flex;\n  align-items: center;\n}\n\n.input_wrap i {\n  font-size: 1rem;\n}\n\n#product-search {\n  font-size: 0.9rem;\n  background: #f1e9e9 !important;\n  color: black;\n  border: none;\n  outline: none;\n  padding: 10px;\n  border-radius: 2rem;\n  width: 95%;\n  margin-left: 13px;\n}\n\n#product-search::placeholder {\n  color: rgb(44, 43, 43);\n  opacity: 0.7;\n  text-align: left;\n  background: #f1e9e9;\n}\n\n.fa-times:hover {\n  cursor: pointer;\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
