// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UpdateChapterOrder_container__tDq8f {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    background-color: #f9f9f9;
    border-radius: 8px;
  }
  
  .UpdateChapterOrder_heading__MLeQS {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .UpdateChapterOrder_chapterList__5crov {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .UpdateChapterOrder_chapterItem__xQEKv {
    padding: 10px;
    margin-bottom: 8px;
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: grab;
    display: flex;
    justify-content: space-between;
  }
  
  .UpdateChapterOrder_chapterItem__xQEKv.UpdateChapterOrder_dragging__Vd4Zn {
    background-color: #e0e0e0;
    cursor: grabbing;
  }
  
  .UpdateChapterOrder_noChapters__C0jGR {
    text-align: center;
    color: red;
    font-size: 18px;
  }
  
  .UpdateChapterOrder_saveButton__dexBL {
    display: block;
    margin: 20px auto;
    background-color: #4caf50;
    color: white;
    padding: 10px 20px;
    border-radius: 4px;
  }
  
  .UpdateChapterOrder_saveButton__dexBL:hover {
    background-color: #45a049;
  }

  .UpdateChapterOrder_heading__MLeQS {
    font-size: 28px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
    color: #333;
    text-transform: capitalize; /* Optional: Makes sure the title has capitalized words */
  }
  
  `, "",{"version":3,"sources":["webpack://./src/VideoCourse_AdminPanel/UpdateChapterOrder.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,gBAAgB;IAChB,cAAc;IACd,yBAAyB;IACzB,kBAAkB;EACpB;;EAEA;IACE,eAAe;IACf,iBAAiB;IACjB,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,gBAAgB;IAChB,UAAU;IACV,SAAS;EACX;;EAEA;IACE,aAAa;IACb,kBAAkB;IAClB,yBAAyB;IACzB,sBAAsB;IACtB,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,8BAA8B;EAChC;;EAEA;IACE,yBAAyB;IACzB,gBAAgB;EAClB;;EAEA;IACE,kBAAkB;IAClB,UAAU;IACV,eAAe;EACjB;;EAEA;IACE,cAAc;IACd,iBAAiB;IACjB,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;EACpB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,eAAe;IACf,iBAAiB;IACjB,kBAAkB;IAClB,mBAAmB;IACnB,WAAW;IACX,0BAA0B,EAAE,yDAAyD;EACvF","sourcesContent":[".container {\n    padding: 20px;\n    max-width: 800px;\n    margin: 0 auto;\n    background-color: #f9f9f9;\n    border-radius: 8px;\n  }\n  \n  .heading {\n    font-size: 24px;\n    font-weight: bold;\n    text-align: center;\n    margin-bottom: 20px;\n  }\n  \n  .chapterList {\n    list-style: none;\n    padding: 0;\n    margin: 0;\n  }\n  \n  .chapterItem {\n    padding: 10px;\n    margin-bottom: 8px;\n    background-color: #ffffff;\n    border: 1px solid #ddd;\n    border-radius: 4px;\n    cursor: grab;\n    display: flex;\n    justify-content: space-between;\n  }\n  \n  .chapterItem.dragging {\n    background-color: #e0e0e0;\n    cursor: grabbing;\n  }\n  \n  .noChapters {\n    text-align: center;\n    color: red;\n    font-size: 18px;\n  }\n  \n  .saveButton {\n    display: block;\n    margin: 20px auto;\n    background-color: #4caf50;\n    color: white;\n    padding: 10px 20px;\n    border-radius: 4px;\n  }\n  \n  .saveButton:hover {\n    background-color: #45a049;\n  }\n\n  .heading {\n    font-size: 28px;\n    font-weight: bold;\n    text-align: center;\n    margin-bottom: 20px;\n    color: #333;\n    text-transform: capitalize; /* Optional: Makes sure the title has capitalized words */\n  }\n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `UpdateChapterOrder_container__tDq8f`,
	"heading": `UpdateChapterOrder_heading__MLeQS`,
	"chapterList": `UpdateChapterOrder_chapterList__5crov`,
	"chapterItem": `UpdateChapterOrder_chapterItem__xQEKv`,
	"dragging": `UpdateChapterOrder_dragging__Vd4Zn`,
	"noChapters": `UpdateChapterOrder_noChapters__C0jGR`,
	"saveButton": `UpdateChapterOrder_saveButton__dexBL`
};
export default ___CSS_LOADER_EXPORT___;
