import React from 'react'
import "./Footer.css"
import Col from 'react-bootstrap/Col';
import facebook from "./footerimg/facebook.svg";
import gmail from "./footerimg/gmail.svg";
import insta from "./footerimg/insta.svg";
import telegram from "./footerimg/telegram.svg";
import twitter from "./footerimg/twitter.svg";
import youtube from "./footerimg/youtube.svg";
import play from "./footerimg/play.svg";
import whatsapp from "./footerimg/whatsapp.svg";

function Footer() {
  return (
    <>
<div className="site-footer">     
        <div className="row fotter-contain">
            <Col  className = "footer-container" xs={6}  md={3}>
            <h5><a href="/">About Us</a></h5>
            <ul className="footer-links">
              <li><a href="https://ssccglpinnacle.com/footer/Terms-and-Conditions">Term and Conditions</a></li>
              <li><a href="/">Privacy Policy</a></li>
              <li><a href="/">Return Policy</a></li>
               <li><a href="/">Contact Us</a></li>
               <li><img className = "gmail-img" src={gmail}/><a href="/">support@ssccglpinnacle.com</a></li>
            </ul>
          </Col>

           <Col  className = "footer-container" xs={6}  md={3}>
            <h5>Explore</h5>
            <ul className="footer-links">
              <li><a href="/">Book</a></li>
              <li><a href="/">E-book</a></li>
              <li><a href="/">Test Pass</a></li>
              <li><a href="/">Courses</a></li>
              <li><a href="/">Blog</a></li>
              <li><a href="/">Upoming books</a></li>
            </ul>
          </Col>
          
          
          <Col  className = "footer-container" xs={6}  md={3}>
            <h5 className = "connect">Connect</h5>
            <ul className="footer-links">
              <li><img className = "telegram-img" src={telegram}/><a href="/">Telegram</a></li>
              <li><img className = "whatsapp-img" src={whatsapp}/><a href="/">Whatsapp</a></li>
              <li><img className = "facebook-img" src={facebook}/><a href="/">Facebook</a></li>
              <li><img className = "insta-img" src={insta}/><a href="/">Instagram</a></li>
              <li><img className = "twitter-img" src={twitter}/><a href="/">Twitter</a></li>
              <li><img className = "play-img" src={play}/><a href="/">Download App</a></li>
            </ul>
           </Col>
          </div>
        
 
</div>
</>
  )
}

export default Footer