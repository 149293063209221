import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import styles from "./CourseOverview.module.css";
import InstructorDetails from "./InstructorDetails";
import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";
import axios from "axios";

const CourseOverview = () => {
  const [courseData, setCourseData] = useState({});
  const [chapters, setChapters] = useState([]);
  const [visibleChapters, setVisibleChapters] = useState(10);
  const [activeIndex, setActiveIndex] = useState(null);
  const [error, setError] = useState(null);

  const { courseId } = useParams();

  useEffect(() => {
    const fetchCourseAndChapterData = async () => {
      const courseUrl = `https://auth.ssccglpinnacle.com/courses/${courseId}`;
      const chaptersUrl = `https://auth.ssccglpinnacle.com/api/youtubeChapters/course/${courseId}`;

      try {
        const [courseResponse, chaptersResponse] = await Promise.all([
          axios.get(courseUrl),
          axios.get(chaptersUrl),
        ]);

        setCourseData(courseResponse.data);
        setChapters(chaptersResponse.data);
      } catch (error) {
        console.error("Failed to fetch data:", error);
        setError("Failed to fetch course or chapters data");
      }
    };

    fetchCourseAndChapterData();
  }, [courseId]);

  // Toggle chapter visibility
  const handleClick = useCallback((index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  }, []);

  const handleShowMore = () => setVisibleChapters(chapters.length);
  const handleHide = () => {
    setVisibleChapters(10);
    setActiveIndex(null);
  };

  const totalTopics = chapters.reduce((total, chapter) => {
    return total + (chapter.topics?.length || 0);
  }, 0);

  return (
    <div className={styles.overview}>
      <div className={styles.overview_heading_container}>
        <span className={styles.overview_heading}>About this course</span>
        <div className={styles.descriptionContent}>
          {courseData.shortDescription ? (
            <div
              dangerouslySetInnerHTML={{ __html: courseData.shortDescription }}
            ></div>
          ) : (
            <div>Loading...</div>
          )}
        </div>
      </div>
      <hr />
      <div className={styles.courseInfo}>
        <div className={styles.courseData}>
          <span className={styles.overview_subheading}>Course Status:</span>
        </div>
        <div className={styles.courseData1}>
          <ul>
            <li>Language: Hinglish</li>
            <li>Captions: Yes</li>
          </ul>
        </div>
        <div className={styles.courseData2}>
          <ul>
            <li>Lectures: {totalTopics > 0 ? totalTopics : "NA"}</li>
          </ul>
        </div>
      </div>
      <div className={styles.newSection}>
        <span>Access:</span>
        <ul>
          <li>Available on Pinnacle exam preparation mobile app</li>
          <li>Laptop</li>
          <li>Desktop</li>
          <li>TV</li>
        </ul>
      </div>
      <div className={styles.descriptionSection}>
        <span>Description:</span>
        <div className={styles.description_data}>
          <span>
            There are {chapters.length > 0 ? chapters.length : "NA"} chapters
            given below in this course. In starting of the chapter concepts are
            explained, after that varieties questions are explained, after that
            practice questions are explained of the book.
          </span>
          <div
            className={styles["description-content"]}
            dangerouslySetInnerHTML={{ __html: courseData.longDescription }}
          ></div>
          <div className={styles.wrapper}>
            {chapters.slice(0, visibleChapters).map((chapter, index) => (
              <div key={chapter._id} className={styles.chapterItem}>
                <button
                  className={styles.accordionTitle}
                  onClick={() => handleClick(index)}
                >
                  <span>{chapter.chapterTitle}</span>
                  {activeIndex === index ? (
                    <AiOutlineUp className={styles.arrowIcon} />
                  ) : (
                    <AiOutlineDown className={styles.arrowIcon} />
                  )}
                </button>
                {activeIndex === index && (
                  <div className={styles.accordionContent}>
                    {chapter.topics.map((topic) => (
                      <div key={topic._id} className={styles.videoTitle}>
                        <span>{topic.videoTitle}</span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}

            {/* Show More and Hide buttons */}
            {visibleChapters < chapters.length && (
              <button onClick={handleShowMore} className={styles.seeMoreButton}>
                See More
              </button>
            )}
            {visibleChapters > 10 && visibleChapters >= chapters.length && (
              <button onClick={handleHide} className={styles.seeMoreButton}>
                Hide
              </button>
            )}
          </div>
        </div>
      </div>
      <div className={styles.instructorSection}>
        <InstructorDetails />
      </div>
    </div>
  );
};

export default React.memo(CourseOverview);
