import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { MaterialReactTable } from "material-react-table";
import {
  Button,
  Modal,
  Box,
  Typography,
  IconButton,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./ManageCourseList.module.css";

const ManageCourseList = () => {
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);
  const [totalCourses, setTotalCourses] = useState(0);
  const [showStatusTable, setShowStatusTable] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [quillEditorState, setQuillEditorState] = useState("");
  const [imageHinPreview, setHinImagePreview] = useState(null);
  const [imageEngPreview, setEngImagePreview] = useState(null);

  useEffect(() => {
    fetchCourses();
  }, [currentPage]);

  const fetchCourses = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        "https://auth.ssccglpinnacle.com/api/courses"
      );
      if (Array.isArray(response.data)) {
        setCourses(response.data);
        setTotalCourses(response.data.length);
      } else {
        throw new Error("Unexpected response format");
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching courses:", error.message || error);
      setError("Could not fetch courses. Please try again later.");
      setLoading(false);
    }
  };

  const toggleCourseStatus = async (courseId, currentStatus) => {
    try {
      const newStatus = !currentStatus;
      await axios.put(
        `https://auth.ssccglpinnacle.com/api/edit-course/${courseId}/status`,
        { isActive: newStatus }
      );

      // Update the specific course's status in local state
      setCourses((prevCourses) =>
        prevCourses.map((course) =>
          course._id === courseId ? { ...course, isActive: newStatus } : course
        )
      );

      Swal.fire({
        icon: "success",
        title: "Success!",
        text: `Course ${newStatus ? "activated" : "deactivated"} successfully!`,
      });
    } catch (error) {
      console.error("Error updating course status:", error);
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "There was an error updating the course status. Please try again.",
      });
    }
  };

  const handleEditClick = (course) => {
    setSelectedCourse(course);
    setQuillEditorState(course.longDescription);
    setHinImagePreview(course.hindiCoverImage || null);
    setEngImagePreview(course.englishCoverImage || null);
    setShowModal(true);
  };

  const handleSave = async () => {
    try {
      const formData = new FormData();
      formData.append("courseTitle", selectedCourse.courseTitle);
      formData.append("shortDescription", selectedCourse.shortDescription);
      formData.append("longDescription", quillEditorState);
      formData.append("category", selectedCourse.category);
      formData.append("instructorName", selectedCourse.instructorName);
      formData.append("price", selectedCourse.price);
      formData.append("mrp", selectedCourse.mrp);
      formData.append("SEOTitle", selectedCourse.SEOTitle);
      formData.append("SEOKeyword", selectedCourse.SEOKeyword);
      formData.append("SEOPermalink", selectedCourse.SEOPermalink);
      formData.append("SEODescription", selectedCourse.SEODescription);
      formData.append("hindiCoverImage", selectedCourse.hindiCoverImage);
      formData.append("englishCoverImage", selectedCourse.englishCoverImage);

      await axios.put(
        `https://auth.ssccglpinnacle.com/api/edit-courses/${selectedCourse._id}`,
        formData
      );

      // Update the edited course details in local state
      setCourses((prevCourses) =>
        prevCourses.map((course) =>
          course._id === selectedCourse._id
            ? { ...selectedCourse, longDescription: quillEditorState }
            : course
        )
      );

      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Course updated successfully!",
      });

      setShowModal(false);
      setSelectedCourse(null);
      setHinImagePreview(null);
      setEngImagePreview(null);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "There was an error saving the course. Please try again.",
      });
      console.error("Error saving course:", error);
    }
  };

  return (
    <>
      <h2 className={styles.heading}>Manage Video Course</h2>
      <div className={styles.courseListContainer}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>{/* Search or filter options can go here */}</Grid>
          <Grid item>
            <Button
              variant="contained"
              onClick={() => setShowStatusTable(!showStatusTable)}
            >
              {showStatusTable ? "Hide Course Status" : "Check Course Status"}
            </Button>
          </Grid>
        </Grid>

        {loading && <Typography>Loading...</Typography>}
        {error && <Typography color="error">{error}</Typography>}

        <Typography variant="h6" style={{ marginTop: "20px" }}>
          Total Video Courses: {totalCourses}
        </Typography>

        {/* Main Table for Courses */}
        <MaterialReactTable
          columns={[
            { accessorKey: "serialNumber", header: "S.No.", size: 20 },
            { accessorKey: "courseTitle", header: "Title", size: 300 },
            { accessorKey: "category", header: "Category", size: 100 },
            { accessorKey: "price", header: "Price", size: 50 },
            { accessorKey: "mrp", header: "MRP", size: 50 },
            {
              accessorKey: "isActive",
              header: "Status",
              Cell: ({ cell }) => (
                <Typography color={cell.getValue() ? "green" : "red"}>
                  {cell.getValue() ? "Active" : "Inactive"}
                </Typography>
              ),
            },
            {
              header: "Action",
              Cell: ({ row }) => (
                <Button
                  variant="contained"
                  color={row.original.isActive ? "secondary" : "primary"}
                  onClick={() =>
                    toggleCourseStatus(row.original._id, row.original.isActive)
                  }
                >
                  {row.original.isActive ? "Deactivate" : "Activate"}
                </Button>
              ),
            },
          ]}
          data={courses.map((course, index) => ({
            ...course,
            serialNumber: index + 1 + (currentPage - 1) * pageSize,
          }))}
          pagination={{
            pageCount: Math.ceil(totalCourses / pageSize),
            currentPage,
            onPageChange: setCurrentPage,
          }}
        />

        {/* Modal for Editing Course */}
        <Modal open={showModal} onClose={() => setShowModal(false)}>
          <Box
            className={styles.modalContent}
            sx={{
              maxHeight: "90vh",
              overflowY: "auto",
            }}
          >
            <IconButton
              className={styles.close}
              onClick={() => setShowModal(false)}
            >
              <CloseIcon />
            </IconButton>
            <Typography
              variant="h6"
              sx={{
                textAlign: "center",
                backgroundColor: "#3f51b5",
                color: "#fff",
                padding: "10px",
                borderRadius: "5px",
              }}
            >
              Edit Course
            </Typography>
            {/* Form fields and save button */}
            <Button
              className={styles.submitButton}
              onClick={handleSave}
              variant="contained"
            >
              Update Course
            </Button>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default ManageCourseList;
