import React, { createContext, useState } from "react";

export const ThemeContext = createContext(); // Create the context

export const ThemeProvider = ({ children }) => {
  
  const [theme, setTheme] = useState("light");

  const toggleTheme = (selectedTheme) => {
    setTheme(selectedTheme);
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
