import React from 'react'
import  "./Row2Digital.css";
import Row3Digital from "./Row3DigitalBook";


function Row2Digital({ filteredEbookData }) {
    
return (
<>
<div className ="For-content-books">
<h2 className="purchase-heading-books">
  Printed books
</h2>
<div className ="For-content ">
  <Row3Digital filteredEbookData={filteredEbookData}/>
</div>
</div>



    </>
  )
}

export default Row2Digital;
