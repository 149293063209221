import React, { useState, useRef, useEffect } from 'react';
import MyComponent from "./WriteReview/MyComponent";
import ReviewCard from "./WriteReview/ReviewCard";
import StarRating1 from "../../component/Star/StarRating1";

import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import StarRating from "react-svg-star-rating";
import FilterSelector from "./FilterSelector"; // Import the FilterSelector component

// Import the filter icon as an image
import filterIcon from "./a.png";
import "./RatingReview.css"; // Import your CSS file
import { useParams } from 'react-router-dom';

function RatingReview() {
  const [hoveredId, setHoveredId] = useState(null);
  const [showStar, setShowStar] = useState(false);
  const [innerRadius, setInnerRadius] = useState(25);
  const [outerRadius, setOuterRadius] = useState(50);
  const [showFilter, setShowFilter] = useState(false);
  const [filterOption, setFilterOption] = useState('Newest');
  const filterRef = useRef(null);
  const [StarData, setStarData] = useState([]);
  const { cardId } = useParams();
  const [totalReviews, setTotalReviews] = useState(0);


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        // Clicked outside the filter component
        setShowFilter(false);
      }
    };
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const fetchReviews = async (cardId) => {
      try {
        const response = await fetch(`https://auth.ssccglpinnacle.com/api/reviews/${cardId}`);
        if (!response.ok) {
          throw new Error(`Failed to fetch reviews. Status: ${response.status}`);
        }
        const data = await response.json();
        setStarData(data.ratings);
        
      
        const reviewsLength = data.ratings ? data.ratings.length : 0;
        setTotalReviews(reviewsLength);
      } catch (error) {
        console.error('Error fetching reviews:', error);
      }
    };

    fetchReviews(cardId);
  }, [cardId]);





  const handleClickTotalRating = () => {
    setShowStar(!showStar);
  };

  const handleFilterChange = (option) => {
    setFilterOption(option);
  };

  const handleClickFilterIcon = (event) => {
    event.stopPropagation();
    setShowFilter(!showFilter);
  };

  const handleFilterClick = (event) => {
    event.stopPropagation();
  };



  return (
    <div className="for-intro-row6-image-introproduct">
      <div className="container-for-star-rating-and-review">
      <div className="Star-Rating-review">
        
        <StarRating
          size={25}
          isReadOnly
          initialRating={5}
        />

          <div className="Total-Rating-review" onClick={handleClickTotalRating}>
            {showStar ? (
              <>
                <span>{totalReviews} Reviews</span> <IoIosArrowUp />{" "}
              </>
            ) : (
              <>
                <span>{totalReviews} Reviews</span> <IoIosArrowDown />{" "}
              </>
            )}
          </div>
          <div className="StarRating-review">{showStar && <StarRating1 StarData = {StarData} />}</div>
        </div>
        <div className="for-filter">
          <MyComponent />
          <div
            className="Filter-icon"
            onClick={handleClickFilterIcon}
            style={{ cursor: "pointer" }}
          >
            <img src={filterIcon} alt="Filter Icon" />
          </div>
          {showFilter && (
            <div ref={filterRef} onClick={handleFilterClick}>
              <FilterSelector isOpen={showFilter} onClose={() => setShowFilter(false)} onFilterChange={handleFilterChange} />
            </div>
          )}
        </div>
      </div>
      <div className="container-for-star-rating-and-review-1">
        <ReviewCard filterOption={filterOption} />
      </div>
    </div>
  );
}

export default RatingReview;
