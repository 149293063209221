// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chapter-form {
  width: 90%;
    margin: 0 auto;
    padding: 20px;
    background-color: #f2f2f2;
    border: 1px solid #dbc8c8;
    border-radius: 4px;
    text-align: center;
    margin-bottom: 31px;

  
}

.form-title {
  text-align: center;
  color: #333;
}

.label {
  font-weight: bold;
  color: #101010;
  font-size: 19px;
  margin-bottom: 16px;
}

.input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  color: #333;
  text-align: left;
}

.submit-button {

  width: 40%;
  text-align: center;
  padding: 12px;
  margin-top: 20px;
  background-color: #006ce7;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #45a049;
}

.submit-button:active {
  background-color: #3e8e41;
}
`, "",{"version":3,"sources":["webpack://./src/Ebook/Forms/ChapterForm/ChapterForm.css"],"names":[],"mappings":"AAAA;EACE,UAAU;IACR,cAAc;IACd,aAAa;IACb,yBAAyB;IACzB,yBAAyB;IACzB,kBAAkB;IAClB,kBAAkB;IAClB,mBAAmB;;;AAGvB;;AAEA;EACE,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,iBAAiB;EACjB,cAAc;EACd,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,sBAAsB;EACtB,kBAAkB;EAClB,sBAAsB;EACtB,WAAW;EACX,gBAAgB;AAClB;;AAEA;;EAEE,UAAU;EACV,kBAAkB;EAClB,aAAa;EACb,gBAAgB;EAChB,yBAAyB;EACzB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".chapter-form {\n  width: 90%;\n    margin: 0 auto;\n    padding: 20px;\n    background-color: #f2f2f2;\n    border: 1px solid #dbc8c8;\n    border-radius: 4px;\n    text-align: center;\n    margin-bottom: 31px;\n\n  \n}\n\n.form-title {\n  text-align: center;\n  color: #333;\n}\n\n.label {\n  font-weight: bold;\n  color: #101010;\n  font-size: 19px;\n  margin-bottom: 16px;\n}\n\n.input {\n  width: 100%;\n  padding: 8px;\n  border: 1px solid #ccc;\n  border-radius: 4px;\n  background-color: #fff;\n  color: #333;\n  text-align: left;\n}\n\n.submit-button {\n\n  width: 40%;\n  text-align: center;\n  padding: 12px;\n  margin-top: 20px;\n  background-color: #006ce7;\n  color: #fff;\n  border: none;\n  border-radius: 4px;\n  font-size: 16px;\n  cursor: pointer;\n}\n\n.submit-button:hover {\n  background-color: #45a049;\n}\n\n.submit-button:active {\n  background-color: #3e8e41;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
