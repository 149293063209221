import React, { useEffect, useState } from "react";
import { FiFlag } from "react-icons/fi";
import classes from "./Announcements.module.css";

const Announcements = ({ courseId }) => {
  const [announcements, setAnnouncements] = useState([]);

  useEffect(() => {
    const fetchAnnouncements = async () => {
      try {
        const response = await fetch(
          `https://auth.ssccglpinnacle.com/api/announcements/${courseId}`
        );
        const data = await response.json();
        if (data.success) {
          setAnnouncements(data.announcements);
        } else {
          setAnnouncements([]);
        }
      } catch (error) {
        console.error("Error fetching announcements:", error);
      }
    };

    fetchAnnouncements();
  }, [courseId]);

  return (
    <div className={classes.announce_container}>
      {announcements.map((announcement) => (
        <div key={announcement._id} className={classes.announce_section}>
          <div className={classes.ins_image}>
            <img
              alt="instructor"
              className={classes.instructor_image}
              src={announcement.instructorImage || ""}
            />
          </div>

          <div className={classes.ins_details}>
            <div className={classes.ins_name}>
              {announcement.instructorName}
            </div>
            <div className={classes.ins}>
              posted an announcement.{" "}
              {new Date(announcement.createdAt).toLocaleString()}{" "}
              <FiFlag size={15} />
            </div>
            <div className={classes.heading}>{announcement.title}</div>
            <div
              className={classes.content}
              dangerouslySetInnerHTML={{ __html: announcement.content }}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default Announcements;
