// SearchBar.js

import React, { useState } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import './SearchBar1.css';

const SearchBar1 = ({ onSearch }) => {
  const [searchQuery, setSearchQuery] = useState('');

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSearch = () => {
    // Pass the searchQuery to the parent component or perform a search operation
    onSearch(searchQuery);
  };

  return (
    <div className="SearchBar">
      <div className="search-container1">
        <input
          type="text"
          placeholder="Search..."
          value={searchQuery}
          onChange={handleInputChange}
        />
        <button onClick={handleSearch}>
          <AiOutlineSearch fontSize="20px"/> {/* React Icons search icon */}
        </button>
      </div>
    </div>
  );
};

export default SearchBar1;
